/** @format */

import React from "react";
import Header from "./Header";
import HeroSection from "./Hero";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Doctors from "./Doctors";
import "./styles.css";

function MainComponent() {
	return (
		<div className="App">
			{/* <Header /> */}
			<HeroSection />
			<AboutUs />
			{/* <Services /> */}
			<Doctors />
			{/* Add more components as needed */}
		</div>
	);
}

export default MainComponent;
