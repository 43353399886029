import { createSlice } from "@reduxjs/toolkit";

const getAllServicesReducer = createSlice({
  name: "getAllServices",
  initialState: {},
  reducers: {
    getAllServicesRequest: (state) => {
      state.loading = true;
    },
    getAllServicesSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.getAllServices = action.payload;
      state.error = false;
      state.success = true;
    },
    getAllServicesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllServicesReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getAllServicesRequest,
  getAllServicesSuccess,
  getAllServicesFailure,
  getAllServicesReset,
} = getAllServicesReducer.actions;
export default getAllServicesReducer.reducer;
