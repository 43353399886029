/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createLabTechnician = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createLabTechnicianStart: (state) => {
			state.loading = true;
		},
		createLabTechnicianSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		createLabTechnicianFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createLabTechnicianReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createLabTechnicianStart,
	createLabTechnicianSuccess,
	createLabTechnicianFailure,
	createLabTechnicianReset,
} = createLabTechnician.actions;
export default createLabTechnician.reducer;
