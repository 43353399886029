/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getDepartmentandTestStatistics = createSlice({
	name: 'getDepartmentandTestStatistics',
	initialState: {},
	reducers: {
		getDepartmentandTestStatisticsRequest: (state) => {
			state.loading = true;
		},
		getDepartmentandTestStatisticsSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.getDepartmentandTeststatistics = action.payload;
			state.error = false;
			state.success = true;
		},
		getDepartmentandTestStatisticsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getDepartmentandTestStatisticsReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getDepartmentandTestStatisticsRequest,
	getDepartmentandTestStatisticsSuccess,
	getDepartmentandTestStatisticsFailure,
	getDepartmentandTestStatisticsReset,
} = getDepartmentandTestStatistics.actions;
export default getDepartmentandTestStatistics.reducer;
