/** @format */

import { createSlice } from "@reduxjs/toolkit";

const editAdminReducer = createSlice({
	name: "editAdmin",
	initialState: {},
	reducers: {
		editAdminRequest: (state) => {
			state.loading = true;
		},
		editAdminSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.error = false;
			state.success = true;
		},
		editAdminFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editAdminReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	editAdminRequest,
	editAdminSuccess,
	editAdminFailure,
	editAdminReset,
} = editAdminReducer.actions;
export default editAdminReducer.reducer;
