/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {
	updateUserProfileByAdmin, //
	//
} from "../../redux/userApiCalls";
import { Container, Row, Col } from "react-bootstrap";
import {
	getUserDataByCategory, //
	adminCreateBooking, //
} from "../../redux/adminRedux/adminApiCalls";
import Message from "../../Components/Message";
// import {bookingListScreen} from "../redux/userApiCalls";
import { getUserDataByCategoryReset } from "../../redux/adminRedux/Patient_Management/getUserDataByCategory";
import { userUpdateProfileReset } from "../../redux/userUpdate";

import { allMessagesStart } from "../../redux/allMessages";
import {
	getLabDepts,
	createReferralDoctor,
} from "../../redux/adminRedux/adminApiCalls";
import { getSpecialities } from "../../redux/userApiCalls";
import {
	billStart,
	billSuccess,
	billFailure,
	billReset,
} from "../../redux/adminRedux/Bill_Management/bill";
import { getUserDataById } from "../../redux/prescriptionRedux/prescriptionApiCalls";
import { addLabTestsToLabDeptForReferralDoctor } from "../../redux/receptionRedux/receptionApiCalls";

const DynamicTestRow = ({
	labDeptValues,
	index,
	values,
	handleChange,
	handleDelete,
	activeTests,
}) => {
	const dispatch = useDispatch();
	const [deptName, setDeptName] = useState("Choose Department");
	const [deptId, setDeptId] = useState("");
	const [testName, setTestName] = useState("Choose Test");
	const [testPrice, setTestPrice] = useState("");
	const [deptTestNames, setdeptTestNames] = useState([]);
	console.log(JSON.stringify(labDeptValues) + "line66");
	let activetestsarr = [];
	useEffect(() => {
		handleChange(`docDesc-${index}`, "");
	}, []);
	for (let i = 0; i < activeTests?.length; i++) {
		activetestsarr.push(activeTests[i]?.testName);
	}
	const handleDeptSelect = (eventKey) => {
		setDeptName(eventKey?.split("@@")[0]);
		setDeptId(eventKey?.split("@@")[1]);
		setTestName("Choose Test");
		setTestPrice("");
		setdeptTestNames(
			labDeptValues?.data?.data[eventKey.split("@@")[2]].testDetails
		);
		handleChange(`deptName-${index}`, eventKey?.split("@@")[0]);
		handleChange(`deptId-${index}`, eventKey?.split("@@")[1]);
	};
	const handleTestSelect = (eventKey) => {
		if (activetestsarr?.includes(eventKey?.split("@@")[0])) {
			alert("Selected Test is already in progress.");
		} else {
			let keys = Object.keys(values);
			let testKeys = [];
			keys?.map((key, index) => {
				if (key?.includes("testName")) {
					testKeys?.push(key);
				}
			});
			let isTestContains = false;
			testKeys?.map((key, index) => {
				if (values[key] === eventKey?.split("@@")[0]) {
					isTestContains = true;
				}
			});
			if (isTestContains) {
				alert(eventKey?.split("@@")[0] + " is already selected");
			} else {
				setTestName(eventKey?.split("@@")[0]);
				setTestPrice(eventKey?.split("@@")[1]);
				handleChange(`testName-${index}`, eventKey?.split("@@")[0]);
				handleChange(`testPrice-${index}`, eventKey?.split("@@")[1]);
				handleChange(`testIdx-${index}`, eventKey?.split("@@")[2]);
				handleChange(`referralAmt-${index}`, eventKey?.split("@@")[3]);
			}
		}
	};

	const { getTestNameall } = useSelector((state) => state.getTestNamesOfReport);
	console.log(getTestNameall);
	return (
		<>
			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col>
						<Col md='12' className='text-center'>
							<h6>Department:</h6>{" "}
						</Col>
						<Col md='12' className='text-center'>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={deptName}
								onSelect={handleDeptSelect}>
								<div className='dropScroll'>
									{labDeptValues?.data?.data?.length === 0 ? (
										<Dropdown.Item>
											{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
										</Dropdown.Item>
									) : (
										labDeptValues?.data?.data?.map(
											(dept, index) =>
												dept.deptName !== "hospital" &&
												dept.status !== 0 && (
													<Dropdown.Item
														eventKey={
															dept.deptName + "@@" + dept._id + "@@" + index
														}>
														{dept.deptName}
													</Dropdown.Item>
												)
										)
									)}
								</div>
							</DropdownButton>
						</Col>
					</Col>
					<Col>
						<Col md='12' className='text-center'>
							<h6>Test Name:</h6>
						</Col>
						<Col md='12' className='text-center'>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={testName}
								onSelect={handleTestSelect}>
								<div className='dropScroll'>
									{deptTestNames?.length === 0 ? (
										<Dropdown.Item>
											{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
										</Dropdown.Item>
									) : (
										deptTestNames?.map(
											(test, index) =>
												test.status !== 0 && (
													<Dropdown.Item
														eventKey={
															test.testName +
															"@@" +
															test.testPrice +
															"@@" +
															index +
															"@@" +
															0
														}>
														{test.testName}
													</Dropdown.Item>
												)
										)
									)}
								</div>
							</DropdownButton>
						</Col>
					</Col>
					<Col>
						<Col md='12' className='text-center'>
							<h6>Test Price:</h6>
						</Col>
						<Col md='12' className='text-center'>
							<div>{testPrice === "" ? "-" : testPrice}</div>
						</Col>
					</Col>
					<Col>
						<Col md='12' className='text-center'>
							<h6>Description:</h6>
						</Col>
						<Col md='12' className='text-center'>
							<textarea
								className='form-control '
								rows={2}
								onChange={(e) =>
									handleChange(`docDesc-${index}`, e.target.value)
								}></textarea>
						</Col>
					</Col>
					<Col>
						<Col md='12' className='text-center'>
							<button
								className='btn btn-danger'
								onClick={() => handleDelete(index)}>
								<i className='fa fa-trash fa-2x'></i>
							</button>
						</Col>
					</Col>
				</Row>
			</Container>
			{/* <div className='col-md-2'>
				<h6 className='col-md-12'>Department:</h6>
				<DropdownButton
					className='col-md-3 '
					key='down-centered'
					id={`dropdown-button-drop-down-centered`}
					drop='down-centered'
					variant='success'
					title={deptName}
					onSelect={handleDeptSelect}>
					<div className='dropButton'>
						{labDeptValues?.data?.data?.length === 0 ? (
							<Dropdown.Item>
								{}
							</Dropdown.Item>
						) : (
							labDeptValues?.data?.data?.map(
								(dept, index) =>
									dept.deptName !== 'hospital' &&
									dept.status !== 0 && (
										<Dropdown.Item
											eventKey={dept.deptName + '@@' + dept._id + '@@' + index}>
											{dept.deptName}
										</Dropdown.Item>
									)
							)
						)}
					</div>
				</DropdownButton>
			</div>
			<div className='col-md-2 offset-md-1'>
				<h6 className='col-md-12'>Test Name:</h6>
				<DropdownButton
					className='col-md-3 '
					key='down-centered'
					id={`dropdown-button-drop-down-centered`}
					drop='down-centered'
					variant='success'
					title={testName}
					onSelect={handleTestSelect}>
					{deptTestNames?.length === 0 ? (
						<Dropdown.Item>
							{}
						</Dropdown.Item>
					) : (
						deptTestNames?.map(
							(test, index) =>
								test.status !== 0 && (
									<Dropdown.Item
										eventKey={
											test.testName +
											'@@' +
											test.testPrice +
											'@@' +
											index +
											'@@' +
											test.referralAmount
										}>
										{test.testName}
									</Dropdown.Item>
								)
						)
					)}
				</DropdownButton>
			</div>
			<div className='col-md-2'>
				<h6 className='col-md-12 header-center'>Test Price:</h6>
				<div className='col-md-12 header-center'>
					{testPrice === '' ? '-' : testPrice}
				</div>
			</div>
			<div className='col-md-4'>
				<h6 className='col-md-12'>Description:</h6>
				<textarea
					className='form-control col-md-10'
					rows={2}
					onChange={(e) =>
						handleChange(`docDesc-${index}`, e.target.value)
					}></textarea>
			</div>
			<div className='col-md-1'>
				<button
					className='m-2 btn btn-danger'
					onClick={() => handleDelete(index)}>
					<i className='fa fa-trash fa-2x'></i>
				</button>
			</div> */}
		</>
	);
};

const OnSpotLabBookingScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const [valuesTest, setValuesTest] = useState([]);
	const { adminInfo } = useSelector((state) => state.adminLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	//success and failure messages
	const data1 = useSelector((state) => state.getUserdataByCategory);
	const userData = data1.userData;
	const { error, loading, success } = data1;
	let users_array = [];
	console.log(error);

	const adduserinfo = useSelector((state) => state.userUpdate);
	const adduserinfoerror = adduserinfo.error;
	const adduserinfosuccess = adduserinfo.success;
	const adduserinfoloading = adduserinfo.loading;

	const [bloodGroup, setBloodGroup] = useState("Select a blood group");
	const handleBloodGroup = (eventKey) => {
		setBloodGroup(eventKey);
	};
	const [lgShow, setLgShow] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [mno, setMno] = useState("");
	const handleSearchPatient = (e) => {
		e.preventDefault();
		setUserOption("Select a user");
		setUserIdx("");
		setClick(0);
		setName("");
		setGender("");
		setdob("");
		users_array = [];
		setDocName("");
		setDocAddress("");
		setDocGender("");
		setDocMno("");
		setDocEmail("");
		setShow(false);
		setTotalAmount(0);
		setTotalHospitalAmount(0);
		setTotalReferralAmount(0);
		setTotalHospitalAmountStatic(0);
		setTotalReferralAmountStatic(0);
		setAmountAfterDiscount(0);
		setValuesTest([]);
		setModeOfPayment("Cash");
		setDiscount(0);
		setDiscountReason("");
		setRefDiscount(0);
		setRefDiscountReason("");
		setFinalTestValues([]);
		setDeptArray([]);
		setDoctorOption("Choose Doctor");
		setDoctorId("");
		dispatch(getUserDataByCategory(mno));
		setRowsTest([]);
		handleAddRowTest();
		setAmountPaidCash("");
		setAmountPaidOnline("");
	};

	const [click, setClick] = useState(0);
	console.log(userData);
	const blood_groups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-", "NA"];
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [dob, setdob] = useState(new Date());

	const addUserOnSubmit = (e) => {
		e.preventDefault();
		console.log(name + " " + gender + " " + dob + " " + mno);
		var letters = /^[A-Za-z\s]+$/;
		if (name === "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (gender === "") {
			alert("Please select gender, gender cannot be empty");
		} else if (dob === "") {
			alert("Please select dob, dob cannot be empty");
		} else if (new Date(dob) >= new Date(new Date())) {
			alert("Please select proper dob, dob should not exceed todays date");
		} else {
			if (bloodGroup == "Select a blood group") {
				dispatch(
					updateUserProfileByAdmin(name.trim(), gender, dob, mno, "NA", "")
				);
			} else {
				dispatch(
					updateUserProfileByAdmin(
						name.trim(),
						gender,
						dob,
						mno,
						bloodGroup,
						""
					)
				);
			}
			setName("");
			setGender("");
			setdob("");
			setClick(1);
			setBloodGroup("Select a blood group");
			setUserOption("Select a user");
			setDocName("");
			setDocAddress("");
			setDocGender("");
			setDocMno("");
			setDocEmail("");
			setShow(false);
			setTotalAmount(0);
			setValuesTest([]);
			setTotalHospitalAmount(0);
			setTotalReferralAmount(0);
			setTotalHospitalAmountStatic(0);
			setTotalReferralAmountStatic(0);
			setAmountAfterDiscount(0);
			setModeOfPayment("Cash");
			setDiscount(0);
			setDiscountReason("");
			setRefDiscount(0);
			setRefDiscountReason("");
			setFinalTestValues([]);
			setDeptArray([]);
			setDoctorOption("Choose Doctor");
			setDoctorId("");
			setRowsTest([]);
			handleAddRowTest();
			setAmountPaidCash("");
			setAmountPaidOnline("");
		}
	};

	users_array.push({
		name: userData?.data?.data?.name,
		id: userData?.data?.data?.bookingId,
	});
	for (
		let i = 0;
		i < userData?.data?.data?.registered_patients_array?.length;
		i++
	) {
		users_array.push({
			name: userData?.data?.data?.registered_patients_array[i]?.name,
			id: userData?.data?.data?.registered_patients_array[i]?.bookingId,
		});
	}
	console.log(users_array);
	const [userOption, setUserOption] = useState("Select a user");
	const [userIdx, setUserIdx] = useState("");

	const [docName, setDocName] = useState("");
	const [docAddress, setDocAddress] = useState("");
	const [docGender, setDocGender] = useState("");
	const [docMno, setDocMno] = useState("");
	const [docEmail, setDocEmail] = useState("");

	const submitDoctorHandler = (e) => {
		e.preventDefault();
		if (docMno?.length > 10) {
			alert("Mobile number cannot be more than 10 numbers");
		} else {
			dispatch(
				createReferralDoctor(
					docName,
					Number(docMno),
					docEmail,
					docGender,
					docAddress
				)
			);
		}
		// dispatch(getSpecialities());

		setClick(1);
		setName("");
		setGender("");
		setdob("");
		setRowsTest([]);
		handleAddRowTest();
		setDocName("");
		setDocAddress("");
		setDocGender("");
		setDocMno("");
		setDocEmail("");
		setShow(false);
		setTotalAmount(0);
		setTotalHospitalAmount(0);
		setValuesTest([]);
		setTotalReferralAmount(0);
		setTotalHospitalAmountStatic(0);
		setTotalReferralAmountStatic(0);
		setAmountAfterDiscount(0);
		setModeOfPayment("Cash");
		setDiscount(0);
		setDiscountReason("");
		setRefDiscount(0);
		setRefDiscountReason("");
		setFinalTestValues([]);
		setDeptArray([]);
		setDoctorOption("Choose Doctor");
		setDoctorId("");
		setAmountPaidCash("");
		setAmountPaidOnline("");
	};

	const ReferralDoctorInfo = useSelector((state) => state.createReferralDoc);
	const referralDoctorInfo = ReferralDoctorInfo?.referralDoctorInfo;
	const referralDoctorLoading = ReferralDoctorInfo?.loading;

	useEffect(() => {
		dispatch(getSpecialities());
	}, [referralDoctorLoading]);

	let flag = false;

	const handleUserSelect = (eventKey) => {
		setUserOption(eventKey?.split("@@")[0]);
		setUserIdx(eventKey?.split("@@")[1]);

		setClick(1);
		setName("");
		setGender("");
		setdob("");
		setRowsTest([]);
		handleAddRowTest();
		setDocName("");
		setDocAddress("");
		setDocGender("");
		setDocMno("");
		setDocEmail("");
		setShow(false);
		setTotalAmount(0);
		setTotalHospitalAmount(0);
		setTotalReferralAmount(0);
		setTotalHospitalAmountStatic(0);
		setTotalReferralAmountStatic(0);
		setValuesTest([]);
		setAmountAfterDiscount(0);
		setModeOfPayment("Cash");
		setDiscount(0);
		setDiscountReason("");
		setRefDiscount(0);
		setRefDiscountReason("");
		setFinalTestValues([]);
		setDeptArray([]);
		setDoctorOption("Choose Doctor");
		setDoctorId("");
		setAmountPaidCash("");
		setAmountPaidOnline("");
	};

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	const [totalAmount, setTotalAmount] = useState(0);
	const [totalHospitalAmount, setTotalHospitalAmount] = useState(0);
	const [totalReferralAmount, setTotalReferralAmount] = useState(0);
	const [totalHospitalAmountStatic, setTotalHospitalAmountStatic] = useState(0);
	const [totalReferralAmountStatic, setTotalReferralAmountStatic] = useState(0);
	const [amountAfterDiscount, setAmountAfterDiscount] = useState(0);
	const [modeOfPayment, setModeOfPayment] = useState("Cash");

	const [discount, setDiscount] = useState(0);
	const [discountReason, setDiscountReason] = useState("");

	const [refDiscount, setRefDiscount] = useState(0);
	const [refDiscountReason, setRefDiscountReason] = useState("");

	const [finalTestValues, setFinalTestValues] = useState([]);
	const [deptArray, setDeptArray] = useState([]);

	const submitHandler = async () => {
		if (userOption === "Select a user") {
			alert("User needs to be selected first, Please select user");
		} else if (doctorOption === "Choose Doctor") {
			alert("Please select doctor");
		} else {
			console.log(JSON.stringify(valuesTest));
			let extractedValuesTest = [];
			let deptArr = [];
			// Iterate over the keys
			Object.keys(valuesTest).forEach((key) => {
				// Extract the ID by removing trailing numbers
				let id = key.replace(/-\d+$/, "");

				// Check if the key corresponds to a relevant property
				if (key.includes("deptId")) {
					let index = key.split("-")[1];
					let deptId = valuesTest[`deptId-${index}`];
					let deptName = valuesTest[`deptName-${index}`];
					let testName = valuesTest[`testName-${index}`];
					let testIdx = valuesTest[`testIdx-${index}`];
					let testPrice = valuesTest[`testPrice-${index}`];
					let docDesc = valuesTest[`docDesc-${index}`];
					let referralAmt = valuesTest[`referralAmt-${index}`];

					// Construct the object and push it into extractedValuesTest array
					extractedValuesTest.push({
						deptId: deptId,
						deptName: deptName,
						testName: testName,
						testIdx: Number(testIdx),
						testPrice: Number(testPrice),
						description: docDesc,
						referralAmount: referralAmt ? Number(referralAmt) : 0,
						referralPaidAmount: referralAmt ? Number(referralAmt) : 0,
						paidAmount:
							Number(testPrice) - referralAmt ? Number(referralAmt) : 0,
						discount: {
							discountAmount: 0,
							discountReason: "",
						},
						referraldiscount: {
							discountAmount: 0,
							discountReason: "",
						},
					});

					deptArr.push(deptId);
				}
			});

			setDeptArray(deptArr);
			console.log(extractedValuesTest);
			setFinalTestValues(extractedValuesTest);
			setShow(true);
			let totamt = 0;
			for (let i = 0; i < extractedValuesTest?.length; i++) {
				totamt += parseInt(extractedValuesTest[i]?.testPrice);
			}
			let totrefamt = 0;
			// for (let i = 0; i < extractedValuesTest?.length; i++) {
			//   totrefamt += parseInt(extractedValuesTest[i]?.referralAmount);
			// }
			setTotalAmount(totamt);
			setTotalReferralAmount(totrefamt);
			setAmountAfterDiscount(parseFloat(totamt)?.toFixed(2));
			setTotalHospitalAmount(totamt - totrefamt);
			setTotalReferralAmountStatic(totrefamt);
			setTotalHospitalAmountStatic(totamt - totrefamt);
			setClick(1);
			setName("");
			setGender("");
			setdob("");
			setDiscount(0);
			setRefDiscount(0);
			setDocName("");
			setDocAddress("");
			setDocGender("");
			setDocMno("");
			setDocEmail("");
			setAmountPaidCash("");
			setAmountPaidOnline("");
		}
	};
	let finalteststemp = [...finalTestValues];
	const handleDiscountChange = (event, user) => {
		if (event.target.value !== 0 && event.target.value !== "") {
			if (event?.target?.value > 100) {
				alert("Discount cannot be greater than 100%");
			} else if (event?.target?.value < 0) {
				alert("Discount cannot be less than 0%");
			} else {
				if (user === "hospital") {
					setDiscount(event.target.value);
					const discountPercentage = parseFloat(event.target.value);
					console.log(discountPercentage);

					let testsamounts = [];

					for (let i = 0; i < finalTestValues?.length; i++) {
						testsamounts?.push(finalTestValues[i]?.testPrice);
					}

					let discamount = [];

					for (let i = 0; i < testsamounts?.length; i++) {
						let disc = parseFloat(
							testsamounts[i] * discountPercentage * 0.01
						).toFixed(2);
						discamount.push(disc);
						// testsamounts.push(testsamounts[i] - disc);
					}
					for (let i = 0; i < discamount?.length; i++) {
						if (finalteststemp[i]) {
							finalteststemp[i].discount.discountAmount = Number(discamount[i]);
						}
					}

					let finalamounts = [];

					for (let i = 0; i < testsamounts?.length; i++) {
						finalamounts.push(testsamounts[i] - discamount[i]);
					}

					for (let i = 0; i < finalteststemp.length; i++) {
						if (finalteststemp[i]) {
							// Check if the element is not undefined or null
							finalteststemp[i].paidAmount = finalamounts[i];
						}
					}

					// setSelectedItemsAfterPrice(finalamounts);
					// setSelectedItemsPriceDiscounts(discamount);

					let totalamo = 0;

					for (let i = 0; i < finalamounts?.length; i++) {
						totalamo += finalamounts[i];
					}

					setAmountAfterDiscount(
						parseFloat(totalamo + totalReferralAmount)?.toFixed(2)
					);
					setTotalHospitalAmount(totalamo);
				} else {
					setRefDiscount(event.target.value);
					const discountPercentageRef = parseFloat(event.target.value);
					console.log(discountPercentageRef);

					let testsamountsRef = [];

					for (let i = 0; i < finalTestValues?.length; i++) {
						testsamountsRef?.push(finalTestValues[i]?.referralAmount);
					}

					let discamountRef = [];

					for (let i = 0; i < testsamountsRef?.length; i++) {
						let disc = parseFloat(
							testsamountsRef[i] * discountPercentageRef * 0.01
						).toFixed(2);
						discamountRef.push(disc);
						// testsamountsRef.push(testsamountsRef[i] - disc);
					}

					for (let i = 0; i < discamountRef?.length; i++) {
						if (finalteststemp[i]) {
							finalteststemp[i].referraldiscount.discountAmount =
								discamountRef[i];
						}
					}

					let finalamountsRef = [];

					for (let i = 0; i < testsamountsRef?.length; i++) {
						finalamountsRef.push(testsamountsRef[i] - discamountRef[i]);
					}

					for (let i = 0; i < finalteststemp.length; i++) {
						if (finalteststemp[i]) {
							// Check if the element is not undefined or null
							finalteststemp[i].referralPaidAmount = finalamountsRef[i];
						}
					}

					// setSelectedItemsAfterPrice(finalamountsRef);
					// setSelectedItemsPriceDiscounts(discamountRef);

					let totalamoRef = 0;

					for (let i = 0; i < finalamountsRef?.length; i++) {
						totalamoRef += finalamountsRef[i];
					}

					setAmountAfterDiscount(
						parseFloat(totalamoRef + totalHospitalAmount)?.toFixed(2)
					);
					setTotalReferralAmount(totalamoRef);
				}
			}
			setFinalTestValues(finalteststemp);
		} else {
			setAmountAfterDiscount(parseFloat(totalAmount)?.toFixed(2));
			// if (user === "hospital") {
			// 	setDiscount(event.target.value);
			// } else {
			// 	setRefDiscount(event.target.value);
			// }
		}
		setAmountPaidCash("");
		setAmountPaidOnline("");
	};

	useEffect(() => {
		//dispatch(getSpecialities());
		if (mno !== "") dispatch(getUserDataByCategory(mno));
		// if(mno !== "") {
		//   dispatch(getUserDataByCategory(mno));
		// }
	}, [history, dispatch, click, adduserinfosuccess, adduserinfoerror]);

	const [doctorOption, setDoctorOption] = useState("Choose Doctor");
	const [doctorId, setDoctorId] = useState("");

	const handleDoctorSelect = (eventKey) => {
		setDoctorOption(eventKey?.split("@@")[0]);
		setDoctorId(eventKey?.split("@@")[1]);

		setClick(1);
		setName("");
		setGender("");
		setdob("");
		setValuesTest([]);
		setDocName("");
		setDocAddress("");
		setDocGender("");
		setDocMno("");
		setDocEmail("");
		setShow(false);
		setTotalAmount(0);
		setTotalHospitalAmount(0);
		setTotalReferralAmount(0);
		setTotalHospitalAmountStatic(0);
		setTotalReferralAmountStatic(0);
		setAmountAfterDiscount(0);
		setModeOfPayment("Cash");
		setDiscount(0);
		setDiscountReason("");
		setRefDiscount(0);
		setRefDiscountReason("");
		setFinalTestValues([]);
		setDeptArray([]);

		setRowsTest([]);
		handleAddRowTest();
		setAmountPaidCash("");
		setAmountPaidOnline("");
	};

	const { getlabDeptAll } = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	const [rowsTest, setRowsTest] = useState([]);

	console.log(rowsTest);
	console.log(valuesTest);
	const handleChangeTest = (name, value) => {
		// const { name, value } = event.target;
		setValuesTest((prevValues) => ({ ...prevValues, [name]: value }));
	};

	const handleAddRowTest = () => {
		setRowsTest((prevRows) => [...prevRows, Date.now()]);
	};

	const handleDeleteRowTest = (index) => {
		console.log("OKKKK");
		const updatedValues = { ...valuesTest };
		Object.keys(updatedValues)
			.filter((key) => key.includes(index))
			.forEach((key) => delete updatedValues[key]);

		setValuesTest(updatedValues);

		setRowsTest((prevRows) =>
			prevRows.filter((rowIndex) => rowIndex !== index)
		);
	};

	useEffect(() => {
		handleAddRowTest();
		dispatch(getLabDepts());
		dispatch(getSpecialities());
	}, []);

	const { getSpecialityAll } = useSelector((state) => state.getSpeciality);
	console.log(getSpecialityAll);

	const addLabTestsToLabDeptForReferraldoc = useSelector(
		(state) => state.AddLabTestsToLabDeptForReferralDoc
	);

	useEffect(() => {
		if (mno !== "") dispatch(getUserDataByCategory(mno));
	}, [addLabTestsToLabDeptForReferraldoc?.loading]);

	let [amountPaidCash, setAmountPaidCash] = useState("");
	let [amountPaidOnline, setAmountPaidOnline] = useState("");

	const handleAmounts = (amount, type) => {
		if (amount > amountAfterDiscount) {
			alert(`Amount cannot exceed ${amountAfterDiscount}`);
		} else {
			if (type === "cash") {
				setAmountPaidOnline(0);
				setAmountPaidCash(Number(amount));
				setAmountPaidOnline(Number(amountAfterDiscount) - Number(amount));
			} else {
				setAmountPaidCash(0);
				setAmountPaidOnline(Number(amount));
				setAmountPaidCash(Number(amountAfterDiscount) - Number(amount));
			}
		}
	};

	amountPaidOnline = amountPaidOnline === "" ? 0 : amountPaidOnline;
	amountPaidCash = amountPaidCash === "" ? 0 : amountPaidCash;

	const billIdData = useSelector(
		(state) => state.AddLabTestsToLabDeptForReferralDoc
	);

	const userdata1 = useSelector((state) => state.getUserDataById);
	const userdata = userdata1.userdata;
	const userdataerror = userdata1.error;
	const userdatasuccess = userdata1.success;
	const userdataloading = userdata1.loading;

	// useEffect(() => {
	// 	dispatch(billReset());
	// 	debugger;
	// 	if (
	// 		billIdData?.addLabTestsToLabDeptForReferraldoc?.data?.billId !== undefined
	// 	) {
	// 		const billUserDetails = {
	// 			billId: billIdData?.addLabTestsToLabDeptForReferraldoc?.data?.billId,
	// 			userdata: userdata,
	// 		};
	// 		dispatch(billReset());
	// 		dispatch(billStart());
	// 		dispatch(billSuccess(billUserDetails));
	// 		window.open('/bill', '_blank');
	// 	}
	// }, [billIdData?.loading]);

	const handleCompletePayment = () => {
		if (modeOfPayment === "") {
			alert("Select Mode Of Payment");
		} else if (
			Number(amountPaidOnline) + Number(amountPaidCash) >
			Number(amountAfterDiscount)
		) {
			alert(`Amount cannot exceed ${amountAfterDiscount}`);
		} else if (amountPaidOnline + amountPaidCash < amountAfterDiscount) {
			alert(`Amount cannot be less than ${amountAfterDiscount}`);
		} else {
			for (let i = 0; i < finalTestValues?.length; i++) {
				if (finalTestValues[i]) {
					finalTestValues[i].discount.discountReason = discountReason;
					finalTestValues[i].referraldiscount.discountReason = "";
				}
			}
			console.log(
				userData?.data?.data?._id,
				mno,
				userOption,
				Number(userIdx),
				modeOfPayment,
				deptArray,
				finalTestValues,
				doctorId,
				doctorOption
			);

			const totalAmount =
				finalTestValues?.reduce((total, test) => {
					const amount = parseFloat(test?.testPrice);
					return total + (isNaN(amount) ? 0 : amount);
				}, 0) || 0;

			const AmountPaid =
				finalTestValues?.reduce((total, test) => {
					const paidAmount = parseFloat(test?.paidAmount);
					const referralAmount = 0;
					return (
						total +
						(isNaN(paidAmount) ? 0 : paidAmount) +
						(isNaN(referralAmount) ? 0 : referralAmount)
					);
				}, 0) || 0;

			const discountAmount =
				finalTestValues?.reduce((total, test) => {
					const discount = parseFloat(test?.discount?.discountAmount);
					const referralDiscount = 0;
					return (
						total +
						(isNaN(discount) ? 0 : discount) +
						(isNaN(referralDiscount) ? 0 : referralDiscount)
					);
				}, 0) || 0;

			const referralPaidAmount =
				finalTestValues?.reduce((total, test) => {
					const amount = 0;
					return total + (isNaN(amount) ? 0 : amount);
				}, 0) || 0;

			const hospitalPaidAmount =
				finalTestValues?.reduce((total, test) => {
					const amount = parseFloat(test?.paidAmount);
					return total + (isNaN(amount) ? 0 : amount);
				}, 0) || 0;

			let labDetails = [];
			finalTestValues?.map((test, index) => {
				labDetails?.push({
					labDeptId: test?.deptId,
					labDeptName: test?.deptName,
					test: {
						name: test?.testName,
						amount: test?.testPrice,
					},
				});
			});

			// let referralAmountPaidCash =
			// 	(Number(referralPaidAmount) * Number(amountPaidCash)) /
			// 	Number(totalAmount);
			// let referralAmountPaidOnline =
			// 	(Number(referralPaidAmount) * Number(amountPaidOnline)) /
			// 	Number(totalAmount);

			// let labAmountPaidCash =
			// 	(hospitalPaidAmount * Number(amountPaidCash)) / Number(totalAmount);
			// let labAmountPaidOnline =
			// 	(hospitalPaidAmount * Number(amountPaidOnline)) / Number(totalAmount);

			let totalCash = amountPaidCash;
			let totalOnline = amountPaidOnline;
			let referralPayment = 0;
			let labPayment = 0;

			let referralAmountPaidCash = 0;
			let referralAmountPaidOnline = 0;
			let labAmountPaidCash = 0;
			let labAmountPaidOnline = 0;

			// Pay referrals using cash first
			// if (totalCash >= referralPayment) {
			//   referralAmountPaidCash = referralPayment;
			//   totalCash -= referralPayment;
			// } else {
			//   referralAmountPaidCash = totalCash;
			//   referralAmountPaidOnline = referralPayment - totalCash;
			//   totalCash = 0;
			//   totalOnline -= referralAmountPaidOnline;
			// }

			// Pay lab fees using the remaining cash and then online
			if (totalCash >= labPayment) {
				labAmountPaidCash = labPayment;
				totalCash -= labPayment;
			} else {
				labAmountPaidCash = totalCash;
				labAmountPaidOnline = labPayment - totalCash;
				totalCash = 0;
				totalOnline -= labAmountPaidOnline;
			}

			let bill = {
				AmountToBePaid: totalAmount,
				AmountPaid: AmountPaid,
				AmountDue: 0,
				invoiceNumber:
					"INV" + getCurrentDateIST()?.split("-")?.join("") + mno + userIdx,
				prescription_charges: {},
				inventory_charges: {},
				lab_charges: {
					AmountToBePaid: totalAmount,
					AmountDue: 0,
					discount: {
						discountAmount: discountAmount,
						discountReason: discountReason,
					},
					labDetails: labDetails,
				},
				billDate: getCurrentDateIST(),
				billCreatedBy: adminInfo
					? adminInfo?.user?.name
					: receptionInfo
					? receptionInfo?.data?.user?.name
					: "",
				amountPaidOnline: amountPaidOnline,
				amountPaidCash: amountPaidCash,
			};
			debugger;
			console.log(bill);
			dispatch(
				addLabTestsToLabDeptForReferralDoctor(
					userData?.data?.data?._id,
					mno,
					userOption,
					Number(userIdx),
					modeOfPayment,
					deptArray,
					finalTestValues,
					doctorId,
					doctorOption,
					bill,
					0,
					0,
					labAmountPaidCash,
					labAmountPaidOnline
				)
			);
			dispatch(getUserDataById(userData?.data?.data?._id, name, userIdx));
			setUserOption("Select a user");
			setUserIdx("");
			setClick(0);
			setName("");
			setGender("");
			setdob("");
			users_array = [];
			setDocName("");
			setDocAddress("");
			setDocGender("");
			setDocMno("");
			setDocEmail("");
			setShow(false);
			setTotalAmount(0);
			setValuesTest([]);
			setTotalHospitalAmount(0);
			setTotalReferralAmount(0);
			setTotalHospitalAmountStatic(0);
			setTotalReferralAmountStatic(0);
			setAmountAfterDiscount(0);
			setModeOfPayment("Cash");
			setDiscount(0);
			setDiscountReason("");
			setRefDiscount(0);
			setRefDiscountReason("");
			setFinalTestValues([]);
			setDeptArray([]);
			setDoctorOption("Choose Doctor");
			setDoctorId("");
			setRowsTest([]);
			handleAddRowTest();
		}
	};

	console.log(
		userIdx === "0"
			? userData?.data?.data?.activeTests
			: userData?.data?.data?.registered_patients_array[Number(userIdx) - 1]
					?.activeTests
	);

	/////////////////////////////////Alert handling////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const [showPopup, setShowPopup] = useState(false);

	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log("error");
		dispatch(getUserDataByCategoryReset());
	};
	const [showPopup1, setShowPopup1] = useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log("error");
		dispatch(userUpdateProfileReset());
	};

	useEffect(() => {
		if (adduserinfosuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(userUpdateProfileReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [adduserinfosuccess, dispatch]);

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	return (
		<>
			<Meta />
			<div className='body'>
				{error && (
					<Message variant='danger'>
						<button
							type='button'
							className='close'
							data-dismiss='alert'
							aria-label='Close'
							onClick={toggleShowInfoPopup}>
							<span aria-hidden='true'>&times;</span>
						</button>
						{error}
					</Message>
				)}
				{loading && <Loader />}

				{adduserinfoerror && (
					<Message variant='danger'>
						<button
							type='button'
							className='close'
							data-dismiss='alert'
							aria-label='Close'
							onClick={toggleShowInfoPopup1}>
							<span aria-hidden='true'>&times;</span>
						</button>
						{adduserinfoerror}
					</Message>
				)}
				{adduserinfoloading && <Loader />}

				<h1 className='header-center'>Book Lab Test</h1>
				<form className='row form-group' onSubmit={handleSearchPatient}>
					<Container className='header-center mt-5'>
						<Row className='justify-content-center'>
							<Col md='auto' className='text-center'>
								<h6>
									Enter Patient's Mobile Number{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</h6>
							</Col>
							<Col md='auto' className='text-center'>
								<input
									className='form-control '
									type='text'
									placeholder='9999999999'
									id='mobilenumber'
									required='required'
									onChange={(e) => setMno(e.target.value)}
									minlength='10'
									maxlength='10'
									pattern='^[0-9]*$'
								/>
							</Col>
							<Col md='auto' className='text-center'>
								<Button type='submit' id='search' className='btn btn-primary'>
									Search
								</Button>
							</Col>
						</Row>
					</Container>
				</form>
				<Container className='header-center mt-5 mb-5 card col-md-10'>
					<Row className='mt-4 mb-4'>
						<Col md='auto' className='text-center mt-1'></Col>
						<Col md='auto' className='text-center mt-1'>
							<h6>
								Select a user{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={userOption}
								onSelect={handleUserSelect}>
								{error === "No patient exists with that mobile number" ? (
									<Dropdown.Item></Dropdown.Item>
								) : (
									users_array.map((users, index) => (
										<Dropdown.Item eventKey={users.name + "@@" + index}>
											{users.name}
										</Dropdown.Item>
									))
								)}
							</DropdownButton>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							{users_array.length >= 6 ? (
								<Button disabled className='btn btn-danger'>
									Users limit reached
								</Button>
							) : (
								<Button
									className='btn btn-secondary'
									onClick={() => setLgShow(true)}>
									Add User
								</Button>
							)}
						</Col>
					</Row>
					<Modal
						size='lg'
						show={lgShow}
						onHide={() => setLgShow(false)}
						aria-labelledby='example-modal-sizes-title-lg'>
						<Modal.Header closeButton>
							<Modal.Title id='example-modal-sizes-title-lg'>
								Add New User
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<form className='form-group' onSubmit={addUserOnSubmit}>
								<div className='row mt-3'>
									<label className='col-3 col-md-3' htmlFor='name'>
										<b>Name </b>{" "}
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
									<input
										className='form-control col-8 col-md-8'
										id='name'
										type='name'
										required='required'
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</div>
								<div className='row mt-3'>
									<label htmlFor='gender' className='col-4 col-md-3'>
										<b>Gender </b>{" "}
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
									<div className='row'>
										<b>
											<Form.Check
												onChange={() => {
													setGender("Male");
												}}
												checked={gender === "Male"}
												className='form-check col-5 col-md-4'
												inline
												label='Male'
												type='radio'
												id={`inline-radio-1`}
											/>
											<Form.Check
												onChange={() => {
													setGender("Female");
												}}
												checked={gender === "Female"}
												inline
												label='Female'
												className='form-check col-5 col-md-4'
												type='radio'
												id={`inline-radio-2`}
											/>
										</b>
									</div>
								</div>
								<div className='row mt-3'>
									<label htmlFor='date' className='col-md-3'>
										<b>Date of Birth </b>{" "}
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
									<input
										type='date'
										className='col-md-4 form-control'
										selected={dob}
										value={dob}
										onChange={(e) => setdob(e.target.value)}
										max={today}
									/>
								</div>
								<div className='row mt-3'>
									<label for='bloodgroup' className='col-md-3'>
										<b>Blood Group:</b>
									</label>
									<DropdownButton
										className='col-md-1 btn'
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={bloodGroup}
										onSelect={handleBloodGroup}>
										{blood_groups.map((blood) => (
											<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
										))}
									</DropdownButton>
								</div>
								<div>
									<Button
										className='offset-5 offset-md-6 mt-5 btn'
										type='submit'
										onClick={() => setLgShow(false)}>
										Add
									</Button>
								</div>
							</form>
						</Modal.Body>
					</Modal>
				</Container>
				<Container className='header-center mt-5 mb-5 card col-md-10'>
					<Row className='mt-4 mb-4'>
						<Col md='auto' className='text-center mt-1'></Col>
						<Col md='auto' className='text-center mt-1'>
							<h6>
								Choose Doctor{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={doctorOption}
								onSelect={handleDoctorSelect}>
								{" "}
								{getSpecialityAll?.data?.data?.map(
									(speciality, index) =>
										speciality?.specialization === "referral" &&
										speciality?.doctorsArray?.map((doctor, index) => (
											<Dropdown.Item
												eventKey={doctor?.name + "@@" + doctor?.doctorsinfo}>
												{doctor?.name}
											</Dropdown.Item>
										))
								)}
							</DropdownButton>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							{adminInfo && (
								<Button
									className='btn btn-secondary'
									onClick={() => setModalShow(true)}>
									Add Referral Doctor
								</Button>
							)}
						</Col>
					</Row>
				</Container>
				<Modal
					size='lg'
					show={modalShow}
					onHide={() => setModalShow(false)}
					aria-labelledby='example-modal-sizes-title-lg'>
					<Modal.Header closeButton>
						<Modal.Title id='example-modal-sizes-title-lg'>
							Add New Referral Doctor
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form className='form-group' onSubmit={submitDoctorHandler}>
							<div className='row mt-3'>
								<label className='col-3 col-md-3' htmlFor='name'>
									<b>Name </b>{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</label>
								<input
									className='form-control col-8 col-md-8'
									id='name'
									type='name'
									required='required'
									value={docName}
									onChange={(e) => setDocName(e.target.value)}
								/>
							</div>
							<div className='row mt-3'>
								<label className='col-3 col-md-3' htmlFor='mno'>
									<b>Mobile Number </b>{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</label>
								<input
									className='form-control col-8 col-md-8'
									id='mno'
									type='mno'
									required='required'
									value={docMno}
									onChange={(e) => setDocMno(e.target.value)}
								/>
							</div>
							<div className='row mt-3'>
								<label htmlFor='gender' className='col-4 col-md-3'>
									<b>Gender </b>{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</label>
								<div className='row'>
									<b>
										<Form.Check
											onChange={() => {
												setDocGender("Male");
											}}
											checked={docGender === "Male"}
											className='form-check col-5 col-md-4'
											inline
											label='Male'
											type='radio'
											id={`inline-radio-1`}
										/>
										<Form.Check
											onChange={() => {
												setDocGender("Female");
											}}
											checked={docGender === "Female"}
											inline
											label='Female'
											className='form-check col-5 col-md-4'
											type='radio'
											id={`inline-radio-2`}
										/>
									</b>
								</div>
							</div>
							<div className='row mt-3'>
								<label className='col-3 col-md-3' htmlFor='email'>
									<b>Email </b>{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</label>
								<input
									className='form-control col-8 col-md-8'
									id='email'
									type='email'
									required='required'
									value={docEmail}
									onChange={(e) => setDocEmail(e.target.value)}
								/>
							</div>
							<div className='row mt-3'>
								<label className='col-3 col-md-3' htmlFor='address'>
									<b>Address </b>{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</label>
								<input
									className='form-control col-8 col-md-8'
									id='address'
									type='address'
									required='required'
									value={docAddress}
									onChange={(e) => setDocAddress(e.target.value)}
								/>
							</div>
							<div>
								<Button
									className='offset-5 offset-md-6 mt-5 btn'
									type='submit'
									onClick={() => setModalShow(false)}>
									Add
								</Button>
							</div>
						</form>
					</Modal.Body>
				</Modal>
				<Container className='header-center mt-5 mb-5 card col-md-10'>
					<Row className='mt-4 mb-4'>
						<Col md='auto' className='text-center mt-1'></Col>
						<Col md='auto' className='text-center mt-1'>
							<h6>
								Add Lab Tests{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</h6>
						</Col>
					</Row>
					<Row className='mb-4'>
						{rowsTest.map((rowIndexTest) => (
							<DynamicTestRow
								labDeptValues={getlabDeptAll}
								key={rowIndexTest}
								index={rowIndexTest}
								values={valuesTest}
								handleChange={handleChangeTest}
								handleDelete={handleDeleteRowTest}
								activeTests={
									userIdx === "0"
										? userData?.data?.data?.activeTests
										: userData?.data?.data?.registered_patients_array[
												Number(userIdx) - 1
										  ]?.activeTests
								}
							/>
						))}
					</Row>
					<Row className='justify-content-center mb-4'>
						<Col md='auto' className='text-center mt-1'>
							<button className='btn btn-secondary' onClick={handleAddRowTest}>
								+ Add more tests
							</button>
						</Col>
					</Row>
				</Container>
				<Container className='header-center mt-5'>
					<Row className='justify-content-center'>
						<Col md='auto' className='text-center'>
							<Button className='btn-secondary' onClick={submitHandler}>
								Book Tests
							</Button>
						</Col>
					</Row>
				</Container>
			</div>
			<Modal show={show} onHide={handleClose} size='xl'>
				<Modal.Header closeButton>
					<Modal.Title>Update Payment Status</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='row mt-2'>
						<div className='col-12 cardHeading'>
							<div className='row mt-2'>
								<div className='col-4 col-md-3 cardHeading'>Amount:</div>
								<input
									type='text'
									className='form-control col-8'
									value={`Rs ${totalHospitalAmountStatic}`}
									disabled
								/>
							</div>
							<div className='row mt-2'>
								<div className='col-4 col-md-3 cardHeading'>Discount (%):</div>
								<input
									type='number'
									className='col-8 form-control'
									min={0}
									max={100}
									value={discount}
									onChange={(event) => handleDiscountChange(event, "hospital")}
								/>
							</div>
							<div className='row mt-2'>
								<div className='col-4 col-md-3 cardHeading'>
									Discount Reason:
								</div>
								<input
									type='text'
									className='col-8 form-control'
									value={discountReason}
									onChange={(e) => setDiscountReason(e.target.value)}
								/>
							</div>
							<div className='row mt-2'>
								<div className='col-4 col-md-3 cardHeading'>
									Amount After Discount:
								</div>

								<input
									type='text'
									className='form-control col-8'
									value={`Rs ${totalHospitalAmount}`}
									disabled
								/>
							</div>
						</div>
					</div>

					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>Total Cash:</div>
						<input
							type='number'
							className='col-8 form-control'
							placeholder='Amount Paid Cash'
							value={amountPaidCash}
							onChange={(e) => handleAmounts(e.target.value, "cash")}
						/>
					</div>
					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>Total Online:</div>{" "}
						<input
							type='number'
							className='col-8 form-control'
							placeholder='Amount Paid Online'
							value={amountPaidOnline}
							onChange={(e) => handleAmounts(e.target.value, "online")}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='primary' onClick={handleCompletePayment}>
						Complete Payment
					</Button>
					<Button variant='secondary' onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default OnSpotLabBookingScreens;
