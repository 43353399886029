/** @format */
import Message from "../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import { useNavigate } from "react-router-dom";
import ViewPrescription from "../../Components/ViewPrescription";

const ViewPrescriptionDoctorScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	const [data, setData] = useState(null);

	// useEffect(() => {
	// 	const handleBeforeUnload = (event) => {
	// 		// Display alert when user tries to refresh the page
	// 		event.preventDefault();
	// 		event.returnValue = '';
	// 		alert('Are you sure you want to refresh?');
	// 	};

	// 	window.addEventListener('beforeunload', handleBeforeUnload);

	// 	return () => {
	// 		window.removeEventListener('beforeunload', handleBeforeUnload);
	// 	};
	// }, []);

	useEffect(() => {
		console.log("Component mounted, adding event listener");
		const handleMessage = (event) => {
			// Ensure that the event is coming from the expected source
			if (
				event.origin !== window.location.origin ||
				event.source !== window.opener
			) {
				return;
			}

			setData(event.data);
		};

		window.addEventListener("message", handleMessage);

		// return () => {
		// 	console.log('Component unmounted, removing event listener');
		// 	window.removeEventListener('message', handleMessage);
		// };
	}, []);

	console.log("Data:", data); // Log the current state of data for debugging
	return (
		<>
			<Meta />
			<ViewPrescription
				uid={data?.id}
				age={data?.age}
				gender={data?.gender}
				bgrg={data?.bgrp}
				activeTests={data?.activeTests}
				name={data?.name}
				index={data?.index}
				userId={data?.userId}
			/>
		</>
	);
};

export default ViewPrescriptionDoctorScreen;
