/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getHospitalStatisticsForParticularYear = createSlice({
	name: 'getHospitalStatisticsForParticularYear',
	initialState: {},
	reducers: {
		getHospitalStatisticsForParticularYearRequest: (state) => {
			state.loading = true;
		},
		getHospitalStatisticsForParticularYearSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.getHospitalStatisticsForParticularYear = action.payload;
			state.error = false;
			state.success = true;
		},
		getHospitalStatisticsForParticularYearFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getHospitalStatisticsForParticularYearReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getHospitalStatisticsForParticularYearRequest,
	getHospitalStatisticsForParticularYearSuccess,
	getHospitalStatisticsForParticularYearFailure,
	getHospitalStatisticsForParticularYearReset,
} = getHospitalStatisticsForParticularYear.actions;
export default getHospitalStatisticsForParticularYear.reducer;
