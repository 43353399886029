/** @format */

import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Table from "react-bootstrap/Table";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Message from "../../../Components/Message";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import axios from "axios";
import { SERVER_URL } from "../../../App";
import Button from "react-bootstrap/Button";
import { createLabDept } from "../../../redux/adminRedux/adminApiCalls";

const AddLabDeptScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	// Add Lab Dept
	const [labDeptName, setLabDeptName] = useState("");
	const submitLabDeptHandler = (e) => {
		e.preventDefault();
		dispatch(createLabDept(labDeptName));
		setLabDeptName("");
	};

	const today = new Date().toISOString().split("T")[0];
	return (
		<>
			<Meta />
			<div>
				<h1 className="header-center">Add Lab Department </h1>
				<Form
					className="form-group row mt-5"
					onSubmit={submitLabDeptHandler}
					noValidate="true"
				>
					<Container className="header-center mt-5">
						<Row className="justify-content-center">
							<Col md="auto" className="text-center">
								<label htmlFor="name">
									<b>Lab Dept Name </b>
									<span className="text-danger">
										<b>*</b>
									</span>
								</label>
							</Col>
							<Col md="auto" className="text-center">
								<input
									className="form-control"
									id="labDept"
									type="text"
									required="required"
									value={labDeptName}
									onChange={(e) => setLabDeptName(e.target.value)}
								/>
							</Col>
							<Col md="auto" className="text-center">
								<button type="submit" className="btn btn-success">
									Submit
								</button>
							</Col>
						</Row>
					</Container>
				</Form>
			</div>
		</>
	);
};
export default AddLabDeptScreen;
