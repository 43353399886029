/** @format */

import { createSlice } from '@reduxjs/toolkit';

const todaysLabPatientsStatistics = createSlice({
	name: 'todaysLabPatientsStatistics',
	initialState: {},
	reducers: {
		todaysLabPatientsStatisticsRequest: (state) => {
			state.loading = true;
		},
		todaysLabPatientsStatisticsSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.todaysLabPatientsStatistic = action.payload;
			state.error = false;
			state.success = true;
		},
		todaysLabPatientsStatisticsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		todaysLabPatientsStatisticsReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	todaysLabPatientsStatisticsRequest,
	todaysLabPatientsStatisticsSuccess,
	todaysLabPatientsStatisticsFailure,
	todaysLabPatientsStatisticsReset,
} = todaysLabPatientsStatistics.actions;
export default todaysLabPatientsStatistics.reducer;
