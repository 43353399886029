/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getSuppliers = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getSuppliersStart: (state) => {
			state.loading = true;
		},
		getSuppliersSuccess: (state, action) => {
			state.loading = false;
			state.getsuppliers = action.payload;
			state.error = false;
			state.success = true;
		},
		getSuppliersFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getSuppliersReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getSuppliersStart,
	getSuppliersSuccess,
	getSuppliersFailure,
	getSuppliersReset,
} = getSuppliers.actions;
export default getSuppliers.reducer;
