/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getAllLabDepartmentStatisticsForParticularYear = createSlice({
	name: 'getAllLabDepartmentStatisticsForParticularYear',
	initialState: {},
	reducers: {
		getAllLabDepartmentStatisticsForParticularYearRequest: (state) => {
			state.loading = true;
		},
		getAllLabDepartmentStatisticsForParticularYearSuccess: (state, action) => {
			console.log('gotit13');
			state.loading = false;
			state.getAllLabDepartmentStatisticsForParticularyear = action.payload;
			state.error = false;
			state.success = true;
		},
		getAllLabDepartmentStatisticsForParticularYearFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getAllLabDepartmentStatisticsForParticularYearReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getAllLabDepartmentStatisticsForParticularYearRequest,
	getAllLabDepartmentStatisticsForParticularYearSuccess,
	getAllLabDepartmentStatisticsForParticularYearFailure,
	getAllLabDepartmentStatisticsForParticularYearReset,
} = getAllLabDepartmentStatisticsForParticularYear.actions;
export default getAllLabDepartmentStatisticsForParticularYear.reducer;
