/** @format */

import { createSlice } from '@reduxjs/toolkit';

const editHistoryTemplateInSpeciality = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		editHistoryTemplateInSpecialityStart: (state) => {
			state.loading = true;
		},
		editHistoryTemplateInSpecialitySuccess: (state, action) => {
			state.loading = false;
			state.editHistoryTemplateInSpeciality = action.payload;
			state.error = false;
			state.success = true;
		},
		editHistoryTemplateInSpecialityFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editHistoryTemplateInSpecialityReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editHistoryTemplateInSpecialityStart,
	editHistoryTemplateInSpecialitySuccess,
	editHistoryTemplateInSpecialityFailure,
	editHistoryTemplateInSpecialityReset,
} = editHistoryTemplateInSpeciality.actions;
export default editHistoryTemplateInSpeciality.reducer;
