/** @format */

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Image } from 'react-bootstrap';
import Loader from './Loader';
import { ResponsiveBar } from '@nivo/bar';

const BarChart = ({ data }) => {
	return (
		<div className='header-center' style={{ width: '100%', height: '500px' }}>
			<ResponsiveBar
				data={data}
				keys={['Amount']}
				indexBy='category'
				margin={{ top: 50, right: 130, bottom: 50, left: 100 }}
				padding={0.3}
				layout='horizontal'
				colors={{ scheme: 'category10' }}
				animate={true} // Enable animation
				motionStiffness={90} // Controls the stiffness of the animation
				motionDamping={15} // Controls the damping effect of the animation
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'Amount',
					legendPosition: 'middle',
					legendOffset: 32,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 0,
					tickRotation: 0,
					legend: 'Date',
					legendPosition: 'middle',
					legendOffset: -80,
				}}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 120,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 100,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 20,
						effects: [
							{
								on: 'hover',
								style: {
									itemOpacity: 1,
								},
							},
						],
					},
				]}
			/>
		</div>
	);
};

export default BarChart;
