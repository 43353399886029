/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getPrescriptionTemplateReducer = createSlice({
	name: 'IssueTitles',
	initialState: {},
	reducers: {
		getPrescriptionTemplateRequest: (state) => {
			state.loading = true;
		},
		getPrescriptionTemplateSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.getPrescriptionTemplate = action.payload;
			state.error = false;
			state.success = true;
		},
		getPrescriptionTemplateFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getPrescriptionTemplateReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getPrescriptionTemplateRequest,
	getPrescriptionTemplateSuccess,
	getPrescriptionTemplateFailure,
	getPrescriptionTemplateReset,
} = getPrescriptionTemplateReducer.actions;
export default getPrescriptionTemplateReducer.reducer;
