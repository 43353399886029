/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
// import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout1 } from "../redux/userApiCalls";
import { logout2 } from "../redux/adminRedux/adminApiCalls";
import { logout3 } from "../redux/doctorRedux/doctorApiCalls";
import { logout4 } from "../redux/receptionRedux/receptionApiCalls";
import { logout5 } from "../redux/labRedux/labTechApiCalls";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const Header = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const userLogin = useSelector((state) => state.userLogin);
	const adminLogin = useSelector((state) => state.adminLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const { labTechInfo } = useSelector((state) => state.labTechLogin);
	const { loading, userInfo } = userLogin;
	const { error, adminInfo } = adminLogin;
	const [allDau, setAllDau] = useState([]);

	let carray = [];
	// console.log(user.userInfo === null ? "yes" : "no");
	const handleThisLogin = (carray) => {
		setAllDau(carray);
	};
	console.log(allDau);
	// console.log(JSON.stringify(receptionInfo));
	console.log("line 19");
	useEffect(() => {
		if (doctorInfo?.data?.user?.name) {
			carray = [
				{
					name: "My Appointments",
					link: "/doctor/patientstobevisited",
					dropdownItems: [],
					icon: "fa fa-clipboard",
				},
				{
					name: "Leaves Management",
					// link: "/doctor/createleaves",
					dropdownItems: [
						{
							name: "Create Leaves",
							link: "/doctor/leavesManagement/createleaves",
							icon: "fa fa-plane", // Updated icon
						},
						{
							name: "Delete Leaves",
							link: "/doctor/leavesManagement/deleteleaves",
							icon: "fa fa-plane-slash", // Updated icon
						},
					],
					icon: "fa fa-plane",
				},
				{
					name: "Slots Management",
					// link: "/doctor/blockslots",
					icon: "far fa-clock", // Updated icon
					dropdownItems: [
						{
							name: "Block Slots",
							link: "/doctor/SlotManagement/blockslots",
							icon: "fas fa-lock", // Updated icon
						},
						{
							name: "UnBlock Slots",
							link: "/doctor/SlotManagement/unblockslots",
							icon: "fas fa-unlock", // Updated icon
						},
						{
							name: "Edit My Slots",
							link: "/doctor/SlotManagement/editMySlots",
							icon: "far fa-edit", // Updated icon
						},
						{
							name: "Edit Booking Period",
							link: "/doctor/SlotManagement/editBookingPeriod",
							icon: "far fa-calendar-alt", // Updated icon
						},
					],
				},
				{
					name: "Medicine Templates",
					dropdownItems: [
						{
							name: "Add Medicine Template",
							link: "/doctor/templateManagement/addTemplates",
							icon: "fa fa-file-alt",
						},
						{
							name: "Edit Medicine Template",
							link: "/doctor/templateManagement/editTemplates",
							icon: "fa fa-edit",
						},
						{
							name: "Delete Medicine Template",
							link: "/doctor/templateManagement/deleteTemplates",
							icon: "fa fa-trash",
						},
					],
					icon: "fa fa-pills",
				},
				{
					name: "Precription Templates",
					dropdownItems: [
						{
							name: "Add Precription Template",
							link: "/doctor/prescritpioncreator",
							icon: "fa fa-file-alt",
						},
						{
							name: "Edit Precription Template",
							link: "/doctor/prescriptioneditor",
							icon: "fa fa-edit",
						},
					],
					icon: "fas fa-prescription",
				},
				{
					name: "History Templates",
					dropdownItems: [
						{
							name: "Add History Template",
							link: "/doctor/historycreator",
							icon: "fa fa-file-alt",
						},
						{
							name: "Edit History Template",
							link: "/doctor/historyeditor",
							icon: "fa fa-edit",
						},
					],
					icon: "fa fa-history",
				},
				{
					name: "Search Patients",
					link: "/doctor/searchPatients",
					dropdownItems: [],
					icon: "fa fa-search",
				},
				{
					name: "Patient Management",
					// link: '/admin/addSpeciality',
					dropdownItems: [
						{
							name: "Register Patient",
							link: "/patientManagement/registerPatient",
							icon: "fa fa-user-plus",
						},
						{
							name: "Edit Patient Info",
							link: "/patientManagement/addnewuser",
							icon: "fa fa-user",
						},
					],
					icon: "fa fa-user",
				},
				{
					name: "My Profile",
					link: "/doctor/myprofile",
					dropdownItems: [],
					icon: "fa fa-user",
				},
			];
			handleThisLogin(carray);
		} else if (adminInfo?.user?.isAdmin) {
			carray = [
				{
					name: "Appointments",
					link: "/admin/patientstobevisited",
					dropdownItems: [],
					icon: "fa fa-clipboard",
				},
				{
					name: "Book Appointments",
					link: "/admin/bookappointment",
					dropdownItems: [],
					icon: "fa fa-calendar-plus",
				},
				{
					name: "Book Lab Test",
					link: "/admin/labbooking",
					dropdownItems: [],
					icon: "fa fa-calendar-plus",
				},
				{
					name: "Sell Inventory Items",
					link: "/sellpharmacy",
					dropdownItems: [],
					icon: "fa fa-tags",
				},
				{
					name: "Ip billing",
					link: "/admittedPatients",
					dropdownItems: [],
					icon: "fas fa-bed",
				},
				{
					name: "Add Speciality",
					link: "/admin/addSpeciality",
					dropdownItems: [],
					icon: "fa fa-file-alt",
				},
				{
					name: "Doctor Management",

					dropdownItems: [
						{
							name: "Add Doctor",
							link: "/admin/addDoctor",
							icon: "fa fa-user-md",
						},
						{
							name: "Add Referral Doctor",
							link: "/admin/addreferraldoctor",
							icon: "fa fa-user-md",
						},
						{
							name: "Doctor Leaves",
							link: "/admin/doctorleaves",
							icon: "fa fa-user-md",
						},
						{
							name: "Delete Doctor",
							link: "/admin/deleteDoctor",
							icon: "fa fa-user-md",
						},
					],
					icon: "fa fa-user-md",
				},
				{
					name: "Reception Management",

					dropdownItems: [
						{
							name: "Add Reception",
							link: "/admin/ReceptionManagement/addReception",
							icon: "fa fa-user-nurse",
						},
						{
							name: "Delete Reception",
							link: "/admin/ReceptionManagement/deleteReception",
							icon: "fa fa-user-nurse",
						},
					],
					icon: "fa fa-user-nurse",
				},
				{
					name: "Lab Dept Management",

					dropdownItems: [
						{
							name: "Add Lab Dept",
							link: "/admin/labDeptManagement/addLabDept",
							icon: "fa fa-hospital-user",
						},
						{
							name: "Delete Lab Dept",
							link: "/admin/labDeptManagement/deleteLabDept",
							icon: "fa fa-hospital-user",
						},
					],
					icon: "fa fa-hospital-user",
				},
				{
					name: "Lab Test Management",

					dropdownItems: [
						{
							name: "Add Lab Test",
							link: "/admin/labTestManagement/addLabTest",
							icon: "fa fa-hospital-user",
						},
						{
							name: "Edit Lab Test",
							link: "/admin/labTestManagement/editLabTest",
							icon: "fa fa-hospital-user",
						},
						{
							name: "Delete Lab Test",
							link: "/admin/labTestManagement/deleteLabTest",
							icon: "fa fa-hospital-user",
						},
					],
					icon: "fa fa-hospital-user",
				},
				// {
				// 	name: "Lab Tech Management",

				// 	dropdownItems: [
				// 		{
				// 			name: "Add Lab Tech",
				// 			link: "/admin/labTechManagement/addLabTech",
				// 			icon: "fa fa-hospital-user",
				// 		},
				// 		{
				// 			name: "Edit Lab Tech",
				// 			link: "/admin/labTechManagement/editLabTech",
				// 			icon: "fa fa-hospital-user",
				// 		},
				// 		{
				// 			name: "Delete Lab Tech",
				// 			link: "/admin/labTechManagement/deleteLabTech",
				// 			icon: "fa fa-hospital-user",
				// 		},
				// 	],
				// 	icon: "fa fa-hospital-user",
				// },
				{
					name: "Patient Management",

					dropdownItems: [
						{
							name: "Register Patient",
							link: "/patientManagement/registerPatient",
							icon: "fa fa-user-plus",
						},
						{
							name: "Edit Patient Info",
							link: "/patientManagement/addnewuser",
							icon: "fa fa-user",
						},
					],
					icon: "fa fa-user",
				},
				{
					name: "Inventory Management",
					dropdownItems: [
						{
							name: "Inventory Dashboard",
							link: "/admin/pharmacydashboard",
							icon: "fa fa-chart-line",
						},
						{
							name: "Category Management",
							link: "/admin/pharmacycategories",
							icon: "fa fa-plus",
						},
						{
							name: "View Inventory",
							link: "/admin/pharmacyaddinventory",
							icon: "fa fa-boxes",
						},
						{
							name: "Purchase Inventory",
							link: "/admin/pharmacypurchaseinventory",
							icon: "fa fa-cart-plus",
						},
						{
							name: "Supplier Management",
							link: "/admin/suppliermanagement",
							icon: "fa fa-user-tag",
						},
					],
					icon: "fas fa-warehouse",
				},
				{
					name: "Feedbacks",
					link: "/admin/feedbacks",
					dropdownItems: [],
					icon: "fa fa-comment",
				},
				{
					name: "Search Patients",
					link: "/admin/searchPatients",
					dropdownItems: [],
					icon: "fa fa-search",
				},
				{
					name: "Revenue Management",

					dropdownItems: [
						{
							name: "Inventory Revenue",
							link: "/admin/AllPharmacySales",
							icon: "fa fa-boxes",
						},
						{
							name: "OP Revenue",
							link: "/admin/hospitalstatistics",
							icon: "fa fa-user-md",
						},
						{
							name: "Lab Revenue",
							link: "/admin/labstatistics",
							icon: "fa fa-flask",
						},
					],
					icon: "fa fa-chart-pie",
				},
			];
			handleThisLogin(carray);
		} else if (userInfo?.data?.user?.name) {
			carray = [
				{
					name: "About Us",
					link: "/",
					dropdownItems: [],
					icon: "fa fa-info-circle",
				},
				{
					name: "My Appointments",
					link: "/viewbookings",
					dropdownItems: [],
					icon: "fa fa-clipboard",
				},
				{
					name: "Book Appointments",
					link: "/booking",
					dropdownItems: [],
					icon: "fa fa-calendar-plus",
				},
				{
					name: "Feedbacks",
					link: "/feedbacks",
					dropdownItems: [],
					icon: "fa fa-comment",
				},
				{
					name: "My Profile",
					link: "/users",
					dropdownItems: [],
					icon: "fa fa-user",
				},
			];
			handleThisLogin(carray);
		} else if (receptionInfo?.data?.user?.name) {
			carray = [
				{
					name: "Appointments",
					link: "/reception/patientstobevisited",
					dropdownItems: [],
					icon: "fa fa-clipboard",
				},
				{
					name: "Book Appointments",
					link: "/reception/booking",
					dropdownItems: [],
					icon: "fa fa-calendar-plus",
				},
				{
					name: "Book Lab Test",
					link: "/reception/labbooking",
					dropdownItems: [],
					icon: "fa fa-calendar-plus",
				},
				{
					name: "Sell Inventory Items",
					link: "/sellpharmacy",
					dropdownItems: [],
					icon: "fa fa-tags",
				},
				{
					name: "Ip billing",
					link: "/admittedPatients",
					dropdownItems: [],
					icon: "fas fa-bed",
				},
				{
					name: "Scheduled Appointments",
					link: "/reception/scheduledAppointments",
					dropdownItems: [],
					icon: "fa fa-clock",
				},
				{
					name: "Search Patients",
					link: "/reception/searchPatient",
					dropdownItems: [],
					icon: "fa fa-search",
				},
				{
					name: "Blocked Slots",
					link: "/reception/BlockedSlots",
					dropdownItems: [],
					icon: "fa fa-ban",
				},
				{
					name: "Patient Management",
					// link: '/admin/addSpeciality',
					dropdownItems: [
						{
							name: "Register Patient",
							link: "/patientManagement/registerPatient",
							icon: "fa fa-user-plus",
						},
						{
							name: "Edit Patient Info",
							link: "/patientManagement/addnewuser",
							icon: "fa fa-user",
						},
					],
					icon: "fa fa-user",
				},
				{
					name: "My Profile",
					link: "/reception/myprofile",
					dropdownItems: [],
					icon: "fa fa-user",
				},
			];
			handleThisLogin(carray);
		} else if (labTechInfo?.data?.user?.name) {
			carray = [
				{
					name: "Report Templates",
					// link: "/labTech/createTest",
					dropdownItems: [
						{
							name: "Add Template",
							link: "/labTech/createTest",
							icon: "fa fa-file-alt",
						},
						{
							name: "Edit Template",
							link: "/labTech/editTest",
							icon: "fa fa-file-alt",
						},
						{
							name: "Delete Template",
							link: "/labTech/deleteTest",
							icon: "fa fa-file-alt",
						},
					],
					icon: "fa fa-file-alt",
				},
				{
					name: "Patients In Queue",
					link: "/labTech/patientsinqueue",
					dropdownItems: [],
					icon: "fa fa-user",
				},
			];
			handleThisLogin(carray);
		}
	}, [
		userInfo?.data?.user?.name,
		adminInfo?.user?.isAdmin,
		doctorInfo?.data?.user?.name,
		receptionInfo?.data?.user?.name,
		labTechInfo?.data?.user?.name,
	]);

	const logoutHandler = (typeOfUser) => {
		if (typeOfUser === "user") {
			dispatch(logout1());
		} else if (typeOfUser === "admin") {
			dispatch(logout2());
		} else if (typeOfUser === "doctor") {
			dispatch(logout3());
		} else if (typeOfUser === "reception") {
			dispatch(logout4());
		} else if (typeOfUser === "labTech") {
			dispatch(logout5());
		}
	};

	console.log(userInfo);
	return (
		<div>
			<div className="d-none d-lg-block">
				<Navbar expand="lg" className="navbar-bg">
					<Navbar.Brand href="/" className="offset-md-1">
						<img
							alt="No Img"
							src="/logo3.png"
							width="60"
							height="60"
							className="d-inline-block align-top"
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ml-auto">
							{(userInfo?.data?.user?.name ||
								adminInfo?.user?.isAdmin ||
								labTechInfo?.data?.user?.name ||
								receptionInfo?.data?.user?.name ||
								doctorInfo?.data?.user?.name) && (
								<NavDropdown
									id="nav-dropdown-dark-example"
									title={
										<>
											<i
												className="mr-2 fas fa-user-circle"
												style={{ fontSize: "125%" }}
											>
												{" "}
											</i>
											{adminInfo?.user?.name !== undefined &&
												"ADMIN - " + adminInfo?.user?.name}
											{receptionInfo?.data?.user?.name !== undefined &&
												"RECEPTION - " + receptionInfo?.data?.user?.name}
											{doctorInfo?.data?.user?.name !== undefined &&
												"DOCTOR - " + doctorInfo?.data?.user?.name}
											{labTechInfo?.data?.user?.name !== undefined &&
												"LAB TECH - " + labTechInfo?.data?.user?.name}
											{userInfo?.data?.user?.name !== undefined &&
												userInfo?.data?.user?.name}
										</>
									}
									menuVariant="dark"
									className="profile-name"
								>
									{doctorInfo?.data?.user?.name && (
										<NavDropdown.Item href="/doctor/myprofile">
											My Profile
										</NavDropdown.Item>
									)}
									{adminInfo?.user?.name && (
										<NavDropdown.Item href="/admin/myprofile">
											My Profile
										</NavDropdown.Item>
									)}
									{labTechInfo?.data?.user?.name && (
										<NavDropdown.Item href="/labTech/myprofile">
											My Profile
										</NavDropdown.Item>
									)}
									{receptionInfo?.data?.user?.name && (
										<NavDropdown.Item href="/reception/myprofile">
											My Profile
										</NavDropdown.Item>
									)}
									{doctorInfo?.data?.user?.name && (
										<NavDropdown.Item href="/doctor/updatepassword">
											Update Password
										</NavDropdown.Item>
									)}
									{labTechInfo?.data?.user?.name && (
										<NavDropdown.Item href="/labTech/updatepassword">
											Update Password
										</NavDropdown.Item>
									)}
									{adminInfo?.user?.name && (
										<NavDropdown.Item href="/admin/updatepassword">
											Update Password
										</NavDropdown.Item>
									)}
									{receptionInfo?.data?.user?.name && (
										<NavDropdown.Item href="/reception/updatepassword">
											Update Password
										</NavDropdown.Item>
									)}
									{doctorInfo?.data?.user?.name ? (
										<NavDropdown.Item
											href="/"
											className="bg-danger text-light"
											onClick={() => logoutHandler("doctor")}
										>
											<i className="fa fa-sign-out"></i>
											{"  "}Logout
										</NavDropdown.Item>
									) : (
										<></>
									)}
									{receptionInfo?.data?.user?.name ? (
										<NavDropdown.Item
											href="/"
											onClick={() => logoutHandler("reception")}
											className="bg-danger text-light"
										>
											<i className="fa fa-sign-out"></i>
											{"  "}Logout
										</NavDropdown.Item>
									) : (
										<></>
									)}
									{adminInfo?.user?.username ? (
										<NavDropdown.Item
											href="/"
											onClick={() => logoutHandler("reception")}
											className="bg-danger text-light"
										>
											<i className="fa fa-sign-out"></i>
											{"  "}Logout
										</NavDropdown.Item>
									) : (
										<></>
									)}
									{labTechInfo?.data?.user?.name ? (
										<NavDropdown.Item
											href="/"
											onClick={() => logoutHandler("reception")}
											className="bg-danger text-light"
										>
											<i className="fa fa-sign-out"></i>
											{"  "}Logout
										</NavDropdown.Item>
									) : (
										<></>
									)}
									{userInfo?.data?.user?.name ? (
										<NavDropdown.Item
											href="/"
											onClick={() => logoutHandler("user")}
											className="bg-danger text-light"
										>
											<i className="fa fa-sign-out"></i>
											{"  "}Logout
										</NavDropdown.Item>
									) : (
										<></>
									)}
								</NavDropdown>
							)}
							{!userInfo?.data?.user?.name &&
							!doctorInfo?.data?.user?.name &&
							!receptionInfo?.data?.user?.name &&
							!labTechInfo?.data?.user?.name &&
							!adminInfo?.user?.username &&
							!window.location.href.includes("login") ? (
								<a
									className="btn btn-success"
									role="button"
									href="/admin/login"
								>
									<i className="fa fa-sign-in"></i>
									Login
								</a>
							) : (
								<div></div>
							)}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
			<div className="d-block d-lg-none">
				<Navbar expand="lg" className="navbar-bg">
					<Navbar.Brand href="/" className="offset-md-1">
						<img
							alt="No Img"
							src="/logo3.png"
							width="60"
							height="60"
							className="d-inline-block align-top"
						/>
					</Navbar.Brand>
					{!window.location.href.includes("login") && (
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
					)}
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ml-auto profile-name">
							{allDau?.map((items, index) => (
								<>
									{items?.dropdownItems?.length !== 0 ? (
										<>
											<NavDropdown
												title={
													<>
														<i className={`${items.icon} mr-1`}></i>{" "}
														{items.name}{" "}
													</>
												}
												id="basic-nav-dropdown"
												className="profile-name"
											>
												{items?.dropdownItems?.map(
													(dropdownItem, dropdownIndex) =>
														dropdownItem &&
														dropdownItem.link &&
														dropdownItem.icon &&
														dropdownItem.name && (
															<NavDropdown.Item href={dropdownItem?.link}>
																<>
																	{" "}
																	<i
																		className={`${dropdownItem?.icon} mr-1`}
																	></i>{" "}
																	{dropdownItem?.name}
																</>
															</NavDropdown.Item>
														)
												)}
											</NavDropdown>
										</>
									) : (
										<Nav.Item>
											<Nav.Link href={items.link} className="profile-name">
												<i className={items.icon}></i> {items.name}
											</Nav.Link>
										</Nav.Item>
									)}
								</>
							))}

							{doctorInfo?.data?.user?.name && (
								<Nav.Link className="profile-name">
									{doctorInfo?.data?.user?.name}
								</Nav.Link>
							)}
							{receptionInfo?.data?.user?.name && (
								<Nav.Link className="profile-name">
									{receptionInfo?.data?.user?.name}
								</Nav.Link>
							)}
							{doctorInfo?.data?.user?.name ? (
								<a
									className="btn btn-danger"
									role="button"
									href="/admin/login"
									onClick={() => logoutHandler("doctor")}
								>
									<i class="fa fa-sign-out-alt"></i>
									{"  "}Logout
								</a>
							) : (
								<div></div>
							)}
							{userInfo?.data?.user?.name ? (
								<a
									className="btn btn-danger"
									role="button"
									href="/admin/login"
									onClick={() => logoutHandler("user")}
								>
									<i class="fa fa-sign-out-alt"></i>
									{"  "}Logout
								</a>
							) : (
								<div></div>
							)}
							{adminInfo?.user?.isAdmin ? (
								<a
									className="btn btn-danger"
									role="button"
									href="/admin/login"
									onClick={() => logoutHandler("admin")}
								>
									<i class="fa fa-sign-out-alt"></i>
									{"  "}Logout
								</a>
							) : (
								<div></div>
							)}
							{receptionInfo?.data?.user?.name ? (
								<a
									className="btn btn-danger"
									role="button"
									href="/admin/login"
									onClick={() => logoutHandler("reception")}
								>
									<i class="fa fa-sign-out-alt"></i>
									{"  "}Logout
								</a>
							) : (
								<div></div>
							)}
							{labTechInfo?.data?.user?.name ? (
								<a
									className="btn btn-danger"
									role="button"
									href="/admin/login"
									onClick={() => logoutHandler("reception")}
								>
									<i class="fa fa-sign-out-alt"></i>
									{"  "}Logout
								</a>
							) : (
								<div></div>
							)}
							{!userInfo?.data?.user?.name &&
							!adminInfo?.user?.isAdmin &&
							!doctorInfo?.data?.user?.name &&
							!receptionInfo?.data?.user?.name &&
							!labTechInfo?.data?.user?.name &&
							!window.location.href.includes("login") ? (
								<a
									className="btn btn-success"
									role="button"
									href="/admin/login"
								>
									<i className="fa fa-sign-in"></i>
									Login
								</a>
							) : (
								<div></div>
							)}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
		</div>
	);
};

export default Header;
