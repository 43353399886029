/** @format */

import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Table from "react-bootstrap/Table";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Message from "../../../Components/Message";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import axios from "axios";
import { SERVER_URL } from "../../../App";
import Button from "react-bootstrap/Button";
import {
	getLabDepts,
	createLabTest,
} from "../../../redux/adminRedux/adminApiCalls";

const AddLabTestScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	// Add Lab Test Details

	const { getlabDeptAll } = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	const { loading } = useSelector((state) => state.createLabTest);

	useEffect(() => {
		dispatch(getLabDepts());
	}, []);

	useEffect(() => {
		dispatch(getLabDepts());
	}, [loading]);

	const [deptNameTestDetails, setDeptNameTestDetails] =
		useState("Choose Department");
	const [testNameTestDetails, setTestNameTestDetails] = useState("");
	const [testReferralAmount, setTestReferralAmount] = useState(0);
	const [testPriceTestDetails, setTestPriceTestDetails] = useState("");
	const [testDescTestDetails, setTestDescTestDetails] = useState("");
	const [deptIdTestDetails, setDeptIdTestDetails] = useState("");
	const [newTestIdx, setnewTestIdx] = useState(-1);
	const handleDeptSelectTestDetails = (eventKey) => {
		setDeptNameTestDetails(eventKey.split("@@")[0]);
		setDeptIdTestDetails(eventKey.split("@@")[1]);
		setnewTestIdx(parseInt(eventKey.split("@@")[2]));
	};

	const handleSubmitTestDetails = () => {
		dispatch(
			createLabTest(
				deptIdTestDetails,
				testNameTestDetails,
				testPriceTestDetails,
				testReferralAmount,
				testDescTestDetails,
				newTestIdx
			)
		);
		setDeptNameTestDetails("Choose Department");
		setTestNameTestDetails("");
		setTestPriceTestDetails("");
		setTestReferralAmount("");
		setTestDescTestDetails("");
		setDeptIdTestDetails("");
		setnewTestIdx(-1);
	};

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	return (
		<>
			<Meta />

			<div>
				<h1 className='header-center'>Add Lab Test </h1>
				<div className='row col-md-8 offset-md-3 form-group row mt-5'>
					<label className='form-label col-md-3' htmlFor='speciality'>
						<b>Lab Department: </b>
						<span className='text-danger'>
							<b>*</b>
						</span>
					</label>
					<DropdownButton
						className='col-md-4'
						key='down-centered'
						id={`dropdown-button-drop-down-centered`}
						drop='down-centered'
						variant='secondary'
						title={deptNameTestDetails}
						onSelect={handleDeptSelectTestDetails}>
						{getlabDeptAll?.data?.data?.length === 0 ? (
							<Dropdown.Item>
								{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
							</Dropdown.Item>
						) : (
							getlabDeptAll?.data?.data?.map(
								(dept, index) =>
									dept?.deptName !== "hospital" &&
									dept?.status !== 0 && (
										<Dropdown.Item
											eventKey={
												dept.deptName +
												"@@" +
												dept._id +
												"@@" +
												dept.testDetails.length
											}>
											{dept.deptName}
										</Dropdown.Item>
									)
							)
						)}
					</DropdownButton>
				</div>
				<div className='row col-md-8 offset-md-3 form-group row'>
					<label className='form-label col-md-3' htmlFor='speciality'>
						<b>Test Name: </b>
						<span className='text-danger'>
							<b>*</b>
						</span>
					</label>
					<input
						className='form-control col-md-6'
						type='text'
						placeholder='Enter Test Name'
						required='required'
						value={testNameTestDetails}
						onChange={(e) => {
							setTestNameTestDetails(e.target.value);
						}}
					/>
				</div>
				<div className='row col-md-8 offset-md-3 form-group row'>
					<label className='form-label col-md-3' htmlFor='speciality'>
						<b>Test Price: </b>
						<span className='text-danger'>
							<b>*</b>
						</span>
					</label>
					<input
						className='form-control col-md-6'
						type='number'
						placeholder='Enter Test Price'
						required='required'
						value={testPriceTestDetails}
						onChange={(e) => {
							setTestPriceTestDetails(e.target.value);
						}}
					/>
				</div>
				{/* <div className="row col-md-8 offset-md-3 form-group row">
					<label className="form-label col-md-3" htmlFor="speciality">
						<b>Referral amount for test: </b>
						<span className="text-danger">
							<b>*</b>
						</span>
					</label>
					<input
						className="form-control col-md-6"
						type="number"
						placeholder="Enter Referral Amount"
						required="required"
						value={testReferralAmount}
						onChange={(e) => {
							setTestReferralAmount(e.target.value);
						}}
					/>
				</div> */}
				<div className='row col-md-8 offset-md-3 form-group row'>
					<label className='form-label col-md-3' htmlFor='speciality'>
						<b>Test Description: </b>
					</label>
					<input
						className='form-control col-md-6'
						type='text'
						placeholder='Enter Test Description'
						required='required'
						value={testDescTestDetails}
						onChange={(e) => {
							setTestDescTestDetails(e.target.value);
						}}
					/>
				</div>
				<div className='col-md-2 offset-md-6 form-group'>
					<button
						className='btn btn-success mt-3'
						onClick={handleSubmitTestDetails}>
						Submit
					</button>
				</div>
			</div>
		</>
	);
};
export default AddLabTestScreen;
