/** @format */

import Message from "../../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Pagination from "react-bootstrap/Pagination";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import {
	createLeave,
	deleteLeave,
	getLeave,
	blockSlot,
	unBlockSlot,
} from "../../../redux/doctorRedux/doctorApiCalls";
import { getTimeslots } from "../../../redux/userApiCalls";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../../redux/allMessages";

import { createLeaveReset } from "../../../redux/doctorRedux/Leave_Management/createLeave";
import { deleteLeavesReset } from "../../../redux/doctorRedux/Leave_Management/deleteLeave";
import { getLeavesReset } from "../../../redux/doctorRedux/Leave_Management/getLeaves";
import { blockSlotReset } from "../../../redux/doctorRedux/Slot_Management/blockSlots";
import { unBlockSlotReset } from "../../../redux/doctorRedux/Slot_Management/unBlockSlots";
import { TimeSlotGetReset } from "../../../redux/getTimeSlots";
import Table from "react-bootstrap/Table";

const DoctorDeleteLeavesScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const dt = new Date();
	var date1 = dt.getMonth() + 1 + "/" + dt.getDate() + "/" + dt.getFullYear();

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const returnDefaultDateFormat =
		dt.getFullYear() + "-" + dt.getMonth() + 1 + "-" + dt.getDate();

	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { doctorInfo } = doctorLogin;
	// success and failure Message
	const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
	const getTimeSlotssuccess = getTimeSlotsData.success;
	const getTimeSlotserror = getTimeSlotsData.error;
	const getTimeSlotsloading = getTimeSlotsData.loading;

	const { getLeaves, success, error, loading } = useSelector(
		(state) => state.getLeaves
	);

	const deleteLeaveData = useSelector((state) => state.deleteLeaves);
	const deleteLeavesuccess = deleteLeaveData.success;
	const deleteLeaveerror = deleteLeaveData.error;
	const deleteLeaveloading = deleteLeaveData.loading;

	//Delete Leaves

	const [items, setItems] = useState([]);
	useEffect(() => {
		dispatch(getLeave(doctorInfo?.data?.user?._id));
		setItems(getLeaves?.data?.data);
	}, []);
	console.log(items);
	const [selectedItems, setSelectedItems] = useState([]);

	const toggleItemSelection = (item) => {
		const newSelectedItems = selectedItems.includes(item)
			? selectedItems.filter((i) => i !== item)
			: [...selectedItems, item];
		setSelectedItems(newSelectedItems);
	};

	const deleteSelected = () => {
		let formattedSelectedItems = [];
		selectedItems?.map((date1, index) => {
			let newDate = Number(
				date1?.split("-")[2] + date1?.split("-")[1] + date1?.split("-")[0]
			);
			formattedSelectedItems?.push(newDate);
		});
		console.log(selectedItems + "line112");
		dispatch(deleteLeave(doctorInfo?.data?.user?._id, formattedSelectedItems));
		setSelectedItems([]);
	};
	useEffect(() => {
		dispatch(getLeave(doctorInfo?.data?.user?._id));
	}, []);

	//ALerts//////////////////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);

	const [loaderClass, setLoaderClass] = useState(false);
	useEffect(() => {
		if (deleteLeavesuccess && !timerRunning) {
			setTimerRunning(true);
			setLoaderClass(true);
			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(deleteLeavesReset());

				setTimerRunning(false);
				setLoaderClass(false);
				window.location.reload();
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [deleteLeavesuccess, dispatch]);

	// useEffect(() => {
	//   if (deleteLeavesuccess) {
	//     dispatch(deleteLeavesReset());
	//   }
	// }, [deleteLeavesuccess, dispatch]);

	return (
		<>
			<Meta />

			<div>
				<Container className="header-center mt-5">
					<h4 className="header-center">Cancel Leaves</h4>
					<Row className="justify-content-end mb-3">
						<button className="btn btn-primary" onClick={deleteSelected}>
							Delete Selected
						</button>
					</Row>
					{loading || loaderClass ? (
						<Loader />
					) : (
						<Row className="justify-content-center">
							<Table striped bordered hover className="custom-table col-md-8">
								<thead>
									<tr>
										<th>Select</th>
										<th>Date</th>
										<th>Reason</th>
									</tr>
								</thead>
								<tbody>
									{getLeaves?.data?.data?.map(({ date, reason }) => {
										date = date?.toString();
										let datearr = date.split("");
										date =
											datearr[6] +
											datearr[7] +
											"-" +
											datearr[4] +
											datearr[5] +
											"-" +
											datearr[0] +
											datearr[1] +
											datearr[2] +
											datearr[3];
										return (
											<tr onClick={() => toggleItemSelection(date)}>
												<td>{selectedItems.includes(date) ? "✅" : ""}</td>
												<td>{date}</td>
												<td>{reason}</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</Row>
					)}
				</Container>
			</div>
		</>
	);
};

export default DoctorDeleteLeavesScreen;
