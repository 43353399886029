/** @format */

import { createSlice } from "@reduxjs/toolkit";

const addScheduledLabTestsToLabDept = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		addScheduledLabTestsToLabDeptStart: (state) => {
			state.loading = true;
		},
		addScheduledLabTestsToLabDeptSuccess: (state, action) => {
			state.loading = false;
			state.addScheduledLabTestsToLabDept = action.payload;
			state.error = false;
			state.success = true;
		},
		addScheduledLabTestsToLabDeptFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		addScheduledLabTestsToLabDeptReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	addScheduledLabTestsToLabDeptStart,
	addScheduledLabTestsToLabDeptSuccess,
	addScheduledLabTestsToLabDeptFailure,
	addScheduledLabTestsToLabDeptReset,
} = addScheduledLabTestsToLabDept.actions;
export default addScheduledLabTestsToLabDept.reducer;
