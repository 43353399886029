/** @format */
import React from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Col, Row, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { getPrescription } from "../redux/prescriptionRedux/prescriptionApiCalls";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import HomeScreens from "../Screens/HomeScreen";
import { getPrescriptionReset } from "../redux/prescriptionRedux/getPrescription";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../redux/allMessages";
import Loader from "./Loader";
import Message from "./Message";
import Table from "react-bootstrap/Table";
import { getUserDataById } from "../redux/prescriptionRedux/prescriptionApiCalls";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
import {
	startPrescriptionToStartHistoryRequest,
	startPrescriptionToStartHistorySuccess,
	startPrescriptionToStartHistoryFailure,
	startPrescriptionToStartHistoryReset,
} from "../redux/UINavigationRedux/StartPrescriptionToStartHistory";

const ViewPrescriptionComponent = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();

	const [prescriptionData, setPrescriptionData] = useState(null);

	useEffect(() => {
		// Get query parameters from the URL
		const params = new URLSearchParams(window.location.search);
		const billItemsParam = params.get("prescriptionItems");

		if (billItemsParam) {
			try {
				// Parse and decode the historyItems from the query parameter
				const parsedPrescriptionItems = JSON.parse(
					decodeURIComponent(billItemsParam)
				);
				console.log(parsedPrescriptionItems);
				setPrescriptionData(parsedPrescriptionItems?.prescriptionData);
				dispatch(
					getPrescription(parsedPrescriptionItems?.prescriptionData?.id)
				);
				dispatch(
					getUserDataById(
						parsedPrescriptionItems?.prescriptionData?.userId,
						parsedPrescriptionItems?.prescriptionData.name,
						parsedPrescriptionItems?.prescriptionData?.index
					)
				);
			} catch (e) {
				console.error("Failed to parse history items", e);
			}
		}
	}, []);

	//success and failure
	const getPrescriptionData = useSelector((state) => state.getPrescription);
	const userdata = getPrescriptionData.userdata;
	const success = getPrescriptionData.success;
	const loadingpres = getPrescriptionData.loading;
	const error = getPrescriptionData.error;

	const presData = useSelector((state) => state.getPrescription);
	console.log(presData);

	//ALerts
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const [selectedSections, setSelectedSections] = useState([]);

	const handleSectionChange = (e) => {
		const { name, checked } = e.target;
		if (checked) {
			setSelectedSections([...selectedSections, name]);
		} else {
			setSelectedSections(
				selectedSections.filter((section) => section !== name)
			);
		}
	};

	const handlePrint = () => {
		const printContent = document.getElementById("print-section").innerHTML; // Capture the HTML to be printed

		const newWindow = window.open("", "_blank"); // Open a new window
		newWindow.document.write(`
    <html>
    <head>
        <title>Print Prescription</title>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"> <!-- Include Bootstrap CSS -->
        <style>
            @media print {
                body {
                    margin: 0; /* Remove default margin */
                    padding: 20px;
                    font-family: Arial, sans-serif;
                    color: #333;
                }
                .page {
                    border: 5px solid #007bff; /* Thick border around the page */
                    padding: 20px;
                }
                .header {
                    display: flex; /* Flexbox for side-by-side layout */
                    align-items: center; /* Center items vertically */
                    justify-content: center; /* Center items horizontally */
                    margin-bottom: 20px; /* Space below the header */
                }
                .header img {
                    width: 100px; /* Logo width */
                    margin-right: 20px; /* Space between logo and contact info */
                }
                .contact-info {
                    text-align: left; /* Align contact info text to the left */
                }
                .contact-info h1 {
                    margin: 0; /* Remove default margin */
                    font-size: 34px; /* Clinic Name */
                }
                .contact-info h6 {
                    margin: 5px 0; /* Margin for subtitle or address */
                    font-size: 16px; /* Subtitle font size */
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-top: 20px; /* Space above table */
                }
                th, td {
                    border: 1px solid #dee2e6; /* Light border color */
                    padding: 10px;
                    text-align: left;
                }
                th {
                    background-color: #007bff; /* Header background */
                    color: #fff; /* Header text color */
                }
                .custom-table {
                    margin-bottom: 20px; /* Add margin below each table */
                }
				.table-margin {
					margin-right: 20px;
				}
				.cardHeading {
					font-weight: bold;
				}
				.section {
					margin-top: 20px;
				}
				.medication-definition-box {
                    display: flex;
                    gap: 10px;
                    margin-top: 10px;
                    margin-right: 5px;
                }
				.medication-definition {
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    display: inline-block;
                    margin-right: 5px;
                }
                .morning { background-color: #5cb85c; color: #fff; }
                .afternoon { background-color: #5bc0de; color: #fff; }
                .night { background-color: #d9534f; color: #fff; }
                .before-food { background-color: #f0ad4e; color: #fff; }
                .after-food { background-color: #f0ad4e; color: #fff; }
            }
        </style>
    </head>
	
    <body>
        <div class="page">
            <div class="header">
                <img src="/logo3.png" alt="Clinic Logo"> <!-- Add your clinic logo here -->
                <div class="contact-info">
                    <h1>Dr. K. Anjal Reddy Memorial Hospital</h1>
                    <h6>Nethaji road, Kamareddy, Telangana 503111</h6>
                    <h6>Phone: +91 8688044440 </h6>
                </div>
            </div>
            <div>
                <!-- Patient Details Section -->
				<h4 class='patient-name mt-3'>Doctor Name: ${
					presData?.getPrescription?.data?.data?.doctor?.doctorName
				}</h4>
                <h4 class='patient-name mt-3'>Patient Name: ${
									presData?.getPrescription?.data?.data?.username
								}</h4>
								

                <table class='table table-bordered custom-table'>
                    <tbody>
                        <tr>
                            <td><b>Date:</b> ${presData?.getPrescription?.data?.data?.bookedDate.slice(
															0,
															10
														)}</td>
                            <td><b>Age:</b> ${prescriptionData?.age}</td>
                            <td><b>Gender:</b> ${prescriptionData?.gender}</td>
                            <td><b>Blood Group:</b> ${
															prescriptionData?.bgrg
																? prescriptionData?.bgrg
																: "NA"
														}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- Vitals Section -->
                <table class='table table-bordered custom-table'>
                    <tbody>
                        ${presData?.getPrescription?.data?.data?.vitals
													?.reduce((rows, vital, index) => {
														if (index % 3 === 0) {
															rows.push([]);
														}
														rows[rows.length - 1].push(`
                                <td><span class='cardHeading'>${vital?.vitalName}: </span>${vital?.vitalValue}</td>
                            `);
														return rows;
													}, [])
													.map(
														(row, rowIndex) => `
                            <tr>${row.join("")}
                                ${
																	row.length < 3
																		? "<td></td>".repeat(3 - row.length)
																		: ""
																}
                            </tr>
                        `
													)
													.join("")}
                    </tbody>
                </table>

                <!-- Title Section -->
                <div class='card mt-4'>
                    <div class='row mt-4 mb-2 section'>
                        <div class='col-md-4'><b>Title:</b></div>
                        <div class='col-md-auto'>${
													presData?.getPrescription?.data?.data?.title
												}</div>
                    </div>
                </div>
            </div>
			 ${printContent}
        </div>
    </body>
    </html>
    `);

		newWindow.document.close();
		newWindow.print(); // Trigger the print dialog
	};

	const renderTemplatesForPrint = () => {
		const templates =
			presData?.getPrescription?.data?.data?.prescriptionData || [];
		const templateNames =
			presData?.getPrescription?.data?.data?.templateName || [];

		// Filter templates based on their type
		const otherTemplates = templates
			.map((template, index) => {
				const currentTemplateName = templateNames[index];
				const isSelected = selectedSections.includes(String(index));

				if (
					!isSelected ||
					currentTemplateName === "Medication" ||
					currentTemplateName === "Lab Tests"
				) {
					return null;
				}

				const filteredTemplate = template.filter(
					(item) => item.type === "table" || item.value !== ""
				);

				if (filteredTemplate.length === 0) return null;

				return (
					<div key={index} className="mb-4">
						<h4 className="mt-4 mb-2">{currentTemplateName}</h4>
						<Table bordered striped hover>
							<tbody>
								{filteredTemplate.map((item, idx) => {
									if (item.type === "header" || item.type === "paragraph") {
										return (
											<tr key={idx}>
												<td colSpan={4} className="font-weight-bold">
													{item.label}
												</td>
											</tr>
										);
									} else if (item?.type === "table") {
										return (
											<React.Fragment key={idx}>
												<tr>
													{[...Array(item?.cols)].map((_, colIndex) => (
														<td
															key={`header-${colIndex}`}
															className="text-center"
														>
															<b>{item?.cellData[`0-${colIndex}`] || ""}</b>
														</td>
													))}
												</tr>
												{[...Array(item?.rows)].map((_, rowIndex) => (
													<tr key={`row-${rowIndex}`}>
														{[...Array(item?.cols)].map((_, colIndex) => (
															<td
																key={`cell-${rowIndex}-${colIndex}`}
																className="text-center"
															>
																{item?.cellData[
																	`${rowIndex + 1}-${colIndex}`
																] || ""}
															</td>
														))}
													</tr>
												))}
											</React.Fragment>
										);
									}
									return (
										<React.Fragment key={idx}>
											{idx % 2 === 0 && (
												<tr>
													<td className="font-weight-bold">
														{filteredTemplate[idx]?.label}
													</td>
													<td>
														{Array.isArray(filteredTemplate[idx]?.value)
															? filteredTemplate[idx]?.value.join(", ")
															: filteredTemplate[idx]?.value}
													</td>
													{filteredTemplate[idx + 1] ? (
														<>
															<td className="font-weight-bold">
																{filteredTemplate[idx + 1]?.label}
															</td>
															<td>
																{Array.isArray(filteredTemplate[idx + 1]?.value)
																	? filteredTemplate[idx + 1]?.value.join(", ")
																	: filteredTemplate[idx + 1]?.value}
															</td>
														</>
													) : null}
												</tr>
											)}
										</React.Fragment>
									);
								})}
							</tbody>
						</Table>
					</div>
				);
			})
			.filter(Boolean);

		// Check if "Lab Tests" is in selectedSections
		const labTestsTemplate = selectedSections.includes("Lab Tests") ? (
			<div>
				<h4 className="mt-4 mb-2">Reports</h4>
				<Table bordered className="custom-table">
					<tbody>
						<tr>
							<td>
								<b>S.No</b>
							</td>
							<td>
								<b>Department Name</b>
							</td>
							<td>
								<b>Test Name</b>
							</td>
							<td>
								<b>Report Date</b>
							</td>
							<td>
								<b>Status</b>
							</td>
						</tr>
						{presData?.getPrescription?.data?.data?.doctor?.lab?.map(
							(test, idx) => (
								<tr key={idx}>
									<td>{idx + 1}</td>
									<td>{test?.deptName}</td>
									<td>{test?.testName}</td>
									<td></td>
									<td>
										{userdatainfo?.data?.data?.activeTests?.some(
											(acttest) => acttest?.testName === test?.testName
										) ? (
											userdatainfo?.data?.data?.activeTests.find(
												(acttest) => acttest?.testName === test?.testName
											).status === "scheduled" ? (
												"Payment not done"
											) : (
												userdatainfo?.data?.data?.activeTests.find(
													(acttest) => acttest?.testName === test?.testName
												).status
											)
										) : (
											<button
												className="btn btn-success"
												onClick={() => viewReportHandler(test?.reportId)}
											>
												View Report
											</button>
										)}
									</td>
								</tr>
							)
						)}
					</tbody>
				</Table>
			</div>
		) : null;

		// Check if "Medication" is in selectedSections
		const medicationTemplate = selectedSections.includes("Medication") ? (
			<div>
				<h4 className="mt-4 mb-2">Medication</h4>
				<Table bordered className="custom-table">
					<tbody>
						<tr>
							<td>
								<b>S.No</b>
							</td>
							<td>
								<b>Medicine</b>
							</td>
							<td>
								<b>Frequency</b>
							</td>
							<td>
								<b>Duration</b>
							</td>
						</tr>
						{presData?.getPrescription?.data?.data?.doctor?.medicines?.map(
							(medicine, idx) =>
								medicine?.name && medicine?.name !== "" ? (
									<tr key={idx}>
										<td>{idx + 1}</td>
										<td>{medicine?.name}</td>
										<td>
											{medicine?.dosage?.includes("O@@") ? (
												medicine?.dosage?.split("O@@")[1]
											) : (
												<>
													{medicine?.dosage?.includes("A/F") ? (
														<span className="medication-definition after-food">
															AF
														</span>
													) : (
														<span className="medication-definition before-food">
															BF
														</span>
													)}
													{medicine?.dosage?.split(",").includes("M") && (
														<span className="medication-definition morning">
															M
														</span>
													)}
													{medicine?.dosage?.split(",").includes("A") && (
														<span className="medication-definition afternoon">
															AN
														</span>
													)}
													{medicine?.dosage?.split(",").includes("N") && (
														<span className="medication-definition night">
															N
														</span>
													)}{" "}
													{medicine?.duration?.split(" ")[2]}
												</>
											)}
										</td>
										<td>
											{medicine?.duration?.split(" ")[0]}{" "}
											{medicine?.duration.charAt(0) === "1"
												? medicine?.duration.split(" ")[1].replace("s", "")
												: medicine?.duration.split(" ")[1]}
										</td>
									</tr>
								) : null
						)}
					</tbody>
				</Table>
			</div>
		) : null;

		// Return templates in the desired order
		return [...otherTemplates, labTestsTemplate, medicationTemplate].filter(
			Boolean
		);
	};

	const viewReportHandler = (id) => {
		const data = {
			id: id,
			age: prescriptionData?.age,
			gender: prescriptionData?.gender,
		};
		let url = "/viewreport";

		const newWindow = window.open(url, "_blank");
		newWindow.onload = () => {
			// Pass both data and unique identifier to the new window
			newWindow.postMessage(data, window.location.origin);
		};
	};

	const userdata1 = useSelector((state) => state.getUserDataById);
	const userdatainfo = userdata1.userdata;
	const userdataerror = userdata1.error;
	const userdatasuccess = userdata1.success;
	const userdataloading = userdata1.loading;

	console.log(userdatainfo);

	console.log(presData);

	const backButton = () => {
		history(-1);
	};

	const [flag, setFlag] = useState(true);

	const createHistory = () => {
		const data = {
			id: prescriptionData?.userId,
			name: prescriptionData?.name,
			date: "",
			specialityName:
				presData?.getPrescription?.data?.data?.speciality?.specialityName,
			speciality:
				presData?.getPrescription?.data?.data?.speciality?.specialityId,
			bookingId: "",
			activeTests: "",
			userIdx: presData?.getPrescription?.data?.data?.userIdx,
		};
		console.log(data);
		dispatch(startPrescriptionToStartHistoryReset());
		dispatch(startPrescriptionToStartHistoryRequest());
		dispatch(startPrescriptionToStartHistorySuccess(data));
		window.open("/history", "_blank");
	};

	return (
		<div>
			{loadingpres && <Loader />}
			<Container>
				<div className="row justify-content-end mt-3 mb-3">
					<Col md="auto" className="mt-1">
						<button className="btn btn-success" onClick={handlePrint}>
							Print
						</button>
					</Col>
					<Col md="auto" className="mt-1">
						<button className="btn btn-success" onClick={createHistory}>
							View History
						</button>
					</Col>
				</div>
			</Container>

			<div className="mt-5">
				<div id={"default"}>
					<div className="mt-3 mb-3">
						<div className="headi offset-md-1 d-flex justify-content-between mt-3">
							<img src="/logo3.png" alt="logo" width="100" height="100" />
							<h6 className="text-muted">
								Dr. K. Anjal Reddy Memorial Hospital
								<br />
								Phone: +91 8688044440
								{/* <br />
							Email :  */}
								<br />
								Nethaji road, <br />
								Kamareddy, <br />
								Telangana 503111
							</h6>
						</div>
					</div>
					<h4 className="patient-name mt-3">
						Patient Name: {presData?.getPrescription?.data?.data?.username}
					</h4>

					<Container className="mt-4">
						<Table bordered className="custom-table">
							<tbody>
								<tr>
									<td>
										<b>Date:</b>{" "}
										{presData?.getPrescription?.data?.data?.bookedDate.slice(
											0,
											10
										)}
									</td>
									<td>
										<b>Age:</b> {prescriptionData?.age}
									</td>
									<td>
										<b>Gender:</b> {prescriptionData?.gender}
									</td>
									<td>
										<b>Blood Group:</b>{" "}
										{prescriptionData?.bgrg ? prescriptionData?.bgrg : "NA"}
									</td>
								</tr>
							</tbody>
						</Table>
					</Container>

					<Container className="mt-4">
						<Table bordered className="custom-table">
							<tbody>
								{presData?.getPrescription?.data?.data?.vitals
									?.reduce((rows, vital, index) => {
										// Determine if a new row should be started
										if (index % 3 === 0) {
											rows.push([]);
										}
										// Add the current vital to the last row
										rows[rows.length - 1].push(
											<td key={index}>
												<span className="cardHeading">
													{vital?.vitalName}:{" "}
												</span>
												{vital?.vitalValue}
											</td>
										);
										return rows;
									}, [])
									.map((row, rowIndex) => (
										<tr key={rowIndex}>
											{row}
											{/* Fill empty cells if this row has less than 3 items */}
											{row.length < 3 &&
												Array(3 - row.length).fill(
													<td key={`empty-${rowIndex}-${row.length}`} />
												)}
										</tr>
									))}
							</tbody>
						</Table>
					</Container>

					<Container className="card mt-4">
						<div className="row mt-4 mb-2 section">
							<div className="col-md-4">Title:</div>
						</div>
						<Row className="mb-4 mt-2">
							<Col md="auto"></Col>
							<Col md="auto">
								{presData?.getPrescription?.data?.data?.title}
							</Col>
						</Row>
					</Container>
				</div>
				<div>
					{presData?.getPrescription?.data?.data?.prescriptionData.map(
						(template, templateIndex) => {
							const currentTemplateName =
								presData?.getPrescription?.data?.data?.templateName[
									templateIndex
								];

							if (
								currentTemplateName === "Medication" ||
								currentTemplateName === "Lab Tests"
							) {
								return null;
							}

							const filteredTemplate = template.filter(
								(item) => item.type === "table" || item.value !== ""
							);

							if (filteredTemplate.length === 0) {
								return null;
							}

							return (
								<div key={templateIndex}>
									<Container className="card mt-4" id={templateIndex}>
										<div className="row justify-content-between mt-4 mb-2 section">
											<div className="col-md-4">{currentTemplateName}</div>
											<Form.Check
												key={templateIndex}
												type="checkbox"
												label={
													<span
														style={{
															backgroundColor: "yellow",
															padding: "0 5px",
														}}
														className="mr-5"
													>
														Print
													</span>
												}
												name={String(templateIndex)}
												checked={selectedSections.includes(
													String(templateIndex)
												)}
												onChange={handleSectionChange}
											/>
										</div>

										<Table bordered>
											<tbody>
												{filteredTemplate.map((item, index) => {
													if (
														item.type === "header" ||
														item.type === "paragraph"
													) {
														return (
															<tr key={index}>
																<td colSpan={4}>
																	<strong>{item.label}</strong>
																</td>
															</tr>
														);
													} else if (item?.type === "table") {
														return (
															<React.Fragment key={index}>
																{/* Render the table header */}
																<tr>
																	{[...Array(item?.cols)].map((_, colIndex) => (
																		<th
																			key={`header-${colIndex}`}
																			className="text-center"
																		>
																			{item?.cellData[`0-${colIndex}`] || ""}
																		</th>
																	))}
																</tr>

																{/* Render each row of data */}
																{[...Array(item?.rows)].map((_, rowIndex) => (
																	<tr key={`row-${rowIndex}`}>
																		{[...Array(item?.cols)].map(
																			(_, colIndex) => (
																				<td
																					key={`cell-${rowIndex}-${colIndex}`}
																					className="text-center"
																				>
																					{item?.cellData[
																						`${rowIndex + 1}-${colIndex}`
																					] || ""}
																				</td>
																			)
																		)}
																	</tr>
																))}
															</React.Fragment>
														);
													}

													return (
														<React.Fragment key={index}>
															{index % 2 === 0 && (
																<tr>
																	<td>
																		<strong>
																			{filteredTemplate[index]?.label}
																		</strong>
																	</td>
																	<td>
																		{Array.isArray(
																			filteredTemplate[index]?.value
																		)
																			? filteredTemplate[index]?.value.join(
																					", "
																			  )
																			: filteredTemplate[index]?.value}
																	</td>
																	{filteredTemplate[index + 1] ? (
																		<>
																			<td>
																				<strong>
																					{filteredTemplate[index + 1]?.label}
																				</strong>
																			</td>
																			<td>
																				{Array.isArray(
																					filteredTemplate[index + 1]?.value
																				)
																					? filteredTemplate[
																							index + 1
																					  ]?.value.join(", ")
																					: filteredTemplate[index + 1]?.value}
																			</td>
																		</>
																	) : null}
																</tr>
															)}
														</React.Fragment>
													);
												})}
											</tbody>
										</Table>
									</Container>
								</div>
							);
						}
					)}

					{presData?.getPrescription?.data?.data?.prescriptionData?.map(
						(page, index) => {
							const templateName =
								presData?.getPrescription?.data?.data?.templateName[index];

							if (templateName === "Medication") {
								const medicines =
									presData?.getPrescription?.data?.data?.doctor?.medicines ||
									[];

								// Check if there are any medicines to display
								if (
									medicines.some(
										(medicine) => medicine?.name && medicine?.name !== ""
									)
								) {
									return (
										<Container className="card mt-4" key={index} id={index}>
											<div className="row justify-content-between mt-4 mb-2 section">
												<div className="col-md-4">Medication</div>
												<Form.Check
													key={"Medication"}
													type="checkbox"
													label={
														<span
															style={{
																backgroundColor: "yellow",
																padding: "0 5px",
															}}
														>
															Print
														</span>
													}
													name={"Medication"}
													checked={selectedSections.includes(
														String("Medication")
													)}
													onChange={handleSectionChange}
													className="mr-5"
												/>
											</div>
											<Table bordered className="custom-table">
												<thead>
													<tr>
														<th>S.No</th>
														<th>Medicine</th>
														<th>Frequency</th>
														<th>Duration</th>
													</tr>
												</thead>
												<tbody>
													{medicines.map((medicine, idx) =>
														medicine?.name && medicine?.name !== "" ? (
															<tr key={idx}>
																<td>{idx + 1}</td>
																<td>{medicine?.name}</td>
																<td>
																	{medicine?.dosage?.includes("O@@") ? (
																		medicine?.dosage?.split("O@@")[1]
																	) : (
																		<>
																			{medicine?.dosage?.includes("A/F") ? (
																				<span className="medication-definition after-food">
																					AF
																				</span>
																			) : (
																				<span className="medication-definition before-food">
																					BF
																				</span>
																			)}
																			{medicine?.dosage
																				?.split(",")
																				.includes("M") && (
																				<span className="medication-definition morning">
																					M
																				</span>
																			)}
																			{medicine?.dosage
																				?.split(",")
																				.includes("A") && (
																				<span className="medication-definition afternoon">
																					AN
																				</span>
																			)}
																			{medicine?.dosage
																				?.split(",")
																				.includes("N") && (
																				<span className="medication-definition night">
																					N
																				</span>
																			)}
																			{medicine?.duration?.split(" ")[2]}
																		</>
																	)}
																</td>
																<td>
																	{medicine?.duration?.split(" ")[0]}{" "}
																	{medicine?.duration.charAt(0) === "1"
																		? medicine?.duration
																				?.split(" ")[1]
																				.replace("s", "")
																		: medicine?.duration?.split(" ")[1]}
																</td>
															</tr>
														) : null
													)}
												</tbody>
											</Table>
											<div className="row mt-4 mb-2 section">
												<div className="col-md-12">
													<div className="medication-definition-box">
														<span className="medication-definition before-food">
															BF
														</span>
														<span className="mt-1">Before Food</span>
														<span className="medication-definition after-food">
															AF
														</span>
														<span className="mt-1">After Food</span>
														<span className="medication-definition morning">
															M
														</span>
														<span className="mt-1">Morning</span>
														<span className="medication-definition afternoon">
															AN
														</span>
														<span className="mt-1">After Noon</span>
														<span className="medication-definition night">
															N
														</span>
														<span className="mt-1">Night</span>
													</div>
												</div>
											</div>
										</Container>
									);
								}
							} else if (templateName === "Lab Tests") {
								const tests =
									presData?.getPrescription?.data?.data?.doctor?.lab || [];

								// Check if there are any lab tests to display
								if (tests.length > 0) {
									return (
										<Container className="card mt-4" key={index} id={index}>
											<div className="row justify-content-between mt-4 mb-2 section">
												<div className="col-md-4">Reports</div>
												<Form.Check
													key={"Lab Tests"}
													type="checkbox"
													label={
														<span
															style={{
																backgroundColor: "yellow",
																padding: "0 5px",
															}}
														>
															Print
														</span>
													}
													name={"Lab Tests"}
													checked={selectedSections.includes(
														String("Lab Tests")
													)}
													onChange={handleSectionChange}
													className="mr-5"
												/>
											</div>
											<Table bordered className="custom-table">
												<thead>
													<tr>
														<th>S.No</th>
														<th>Department Name</th>
														<th>Test Name</th>
														<th>Report Date</th>
														<th>Status</th>
													</tr>
												</thead>
												<tbody>
													{tests.map((test, idx) => (
														<tr key={idx}>
															<td>{idx + 1}</td>
															<td>{test?.deptName}</td>
															<td>{test?.testName}</td>
															<td></td>
															<td>
																{userdatainfo?.data?.data?.activeTests?.some(
																	(acttest) =>
																		acttest?.testName === test?.testName
																) ? (
																	userdatainfo?.data?.data?.activeTests.find(
																		(acttest) =>
																			acttest?.testName === test?.testName
																	).status === "scheduled" ? (
																		"Payment not done"
																	) : (
																		userdatainfo?.data?.data?.activeTests.find(
																			(acttest) =>
																				acttest?.testName === test?.testName
																		).status
																	)
																) : (
																	<button
																		className="btn btn-success"
																		onClick={() =>
																			viewReportHandler(test?.reportId)
																		}
																	>
																		View Report
																	</button>
																)}
															</td>
														</tr>
													))}
												</tbody>
											</Table>
										</Container>
									);
								}
							}
						}
					)}

					<div id="print-section" style={{ display: "none" }}>
						{renderTemplatesForPrint()}
					</div>

					<Container>
						<div className="row justify-content-center mt-3 mb-3">
							<Col md="auto" className="mt-1">
								<button className="btn btn-success" onClick={handlePrint}>
									Print
								</button>
							</Col>
						</div>
					</Container>
				</div>
			</div>
		</div>
	);
};

export default ViewPrescriptionComponent;
