/** @format */

import { createSlice } from "@reduxjs/toolkit";

const inventoryInfoReducer = createSlice({
	name: "inventoryInfo",
	initialState: {},
	reducers: {
		inventoryInfoRequest: (state) => {
			state.loading = true;
		},
		inventoryInfoSuccess: (state, action) => {
			console.log("gotit");
			state.inventoryInfo = action.payload;
			state.loading = false;
			state.error = false;
			state.success = true;
		},
		inventoryInfoFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		inventoryInfoReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	inventoryInfoRequest,
	inventoryInfoSuccess,
	inventoryInfoFailure,
	inventoryInfoReset,
} = inventoryInfoReducer.actions;
export default inventoryInfoReducer.reducer;
