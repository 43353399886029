/** @format */
import Message from "./Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import Meta from "./Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { getSpecialities } from "../redux/userApiCalls";
import { getDoctorVisitedPatientsforParticularDate } from "../redux/adminRedux/adminApiCalls";
import { Table } from "react-bootstrap";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { paymentStatusvisitedPatients } from "../redux/doctorRedux/doctorApiCalls";
import { getUserDataById } from "../redux/prescriptionRedux/prescriptionApiCalls";

//Date picker

const PatientsVisitedScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const [visitedDate, setVisitedDate] = useState("");
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const { adminInfo } = useSelector((state) => state.adminLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const { loading, error, success } = useSelector(
		(state) => state.paymentStatusvisitedPatients
	);

	const [DoctorFromSpecialityArray, setDoctorFromSpecialityArray] = useState(
		[]
	);
	const getSpecialityAllData = useSelector((state) => state.getSpeciality);
	const getSpecialityAllsuccess = getSpecialityAllData.success;
	const getSpecialityAllerror = getSpecialityAllData.error;
	const getSpecialityAllloading = getSpecialityAllData.loading;

	const paymentStatusData = useSelector((state) => state.paymentStatus);
	const paymentStatusDatasuccess = paymentStatusData.success;
	const paymentStatusDataerror = paymentStatusData.error;
	const paymentStatusDataloading = paymentStatusData.loading;

	const { getSpecialityAll } = useSelector((state) => state.getSpeciality);

	console.log(getSpecialityAll);

	useEffect(() => {
		doctorInfo === undefined && dispatch(getSpecialities());
	}, []);
	// useEffect(() => {
	// 	handleDateSelect1();
	// 	searchForVisited();
	// }, [doctorInfo]);
	useEffect(() => {
		if (doctorInfo !== undefined) {
			let todayDate = getCurrentDateIST();
			console.log(todayDate + "line56");
			handleDateSelect1(todayDate);
			searchForVisited(true, todayDate);
		}
	}, [doctorInfo]);

	let allSpecialities = [];
	let allDoctors = [];
	if (getSpecialityAll) {
		console.log(getSpecialityAll);
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}
	console.log(allSpecialities);

	//Retrieving Doctors Data from Specialities

	//Speciality dropdown
	const [specialityOption, setSpecialityOption] = useState(() => {
		const testData = doctorInfo;
		return testData !== undefined
			? testData?.user?.speciality[0]
			: "Choose Speciality";
	});

	const [specialityId, setSpecialityId] = useState(() => {
		const testData = doctorInfo;
		return testData !== undefined ? testData?.data?.user?.specialityId[0] : "";
	});

	const handleSpecialitySelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		setSpecialityOption(arr[0]);
		setSpecialityId(arr[1]);
		setDoctorOption("Choose Doctor");
		setDateSelected(false);
		setSearchClicked(false);
		setDoctorFromSpecialityArray(
			getSpecialityAll.data.data[arr[2]].doctorsArray
		);
		console.log(getSpecialityAll.data.data[arr[2]].doctorsArray);
		console.log();
	};

	//Doctor dropdown
	const [doctorOption, setDoctorOption] = useState(() => {
		const testData = doctorInfo;
		return testData !== undefined
			? testData?.data?.user?.name
			: "Choose Doctor";
	});
	const [doctorId, setDoctorId] = useState(() => {
		const testData = doctorInfo;
		return testData !== undefined ? testData?.data?.user?._id : "";
	});
	const [dateSelected, setDateSelected] = useState(false);

	const handleDoctorSelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		setDoctorId(arr[1]);
		setDoctorOption(arr[0]);
		setDateSelected(false);
		setSearchClicked(false);
	};

	let initialAmount;

	const [dateOptionNumeric, setDateOptionNumeric] = useState("");
	console.log(typeof dateOptionNumeric);

	const isToday = (inputDate) => {
		var today1 = new Date(); // Get today1's date
		var input = new Date(inputDate); // Convert the input date string to a Date object

		// Check if the input date matches today1's date
		return (
			input.getDate() === today1.getDate() &&
			input.getMonth() === today1.getMonth() &&
			input.getFullYear() === today1.getFullYear()
		);
	};

	const [todayOrNot, setTodayOrNot] = useState(false);

	const [searchClicked, setSearchClicked] = useState(false);
	const handleDateSelect1 = (eventKey) => {
		console.log(eventKey + "line146");
		setVisitedDate(eventKey);
		console.log(visitedDate);
		setSearchClicked(false);
		var result = isToday(eventKey);
		setTodayOrNot(result);
	};

	let dateModified = 0;

	const searchForVisited = async (flag, todaydate) => {
		console.log(flag, "line161");
		if (specialityOption == "Choose Speciality") {
			alert("Speciality needs to be selected first, Please select Speciality");
		} else if (doctorOption == "Choose Doctor") {
			alert("Doctor needs to be selected first, Please select Doctor");
		} else if (visitedDate == "" && !flag) {
			alert("Please select the date");
		} else {
			console.log(visitedDate, "line165");
			const tempArr1 = visitedDate.split("-");
			const dateModified1 = tempArr1[0] + "" + tempArr1[1] + "" + tempArr1[2];
			const todayDateModified = todaydate !== "" && todaydate.split("-");
			const todaydateNumber =
				todayDateModified[0] +
				"" +
				todayDateModified[1] +
				"" +
				todayDateModified[2];
			setSearchClicked(true);
			console.log(doctorId + "line251");
			dispatch(
				getDoctorVisitedPatientsforParticularDate(
					doctorId,
					todaydate === "" ? parseInt(dateModified1) : todaydateNumber
				)
			);
		}
	};

	useEffect(() => {
		const tempArr1 = visitedDate.split("-");
		const dateModified1 = tempArr1[0] + "" + tempArr1[1] + "" + tempArr1[2];
		doctorOption !== "Choose Doctor" &&
			visitedDate !== "" &&
			dispatch(
				getDoctorVisitedPatientsforParticularDate(
					doctorId,
					parseInt(dateModified1)
				)
			);
	}, [loading]);
	const getVisiPat = useSelector(
		(state) => state.getDoctorVisitedPatientsforParticularDate
	);
	console.log(getVisiPat.getDoctorVisitedPatientsforParticularDate);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [bookingId_payemnt, setbookingId_payemnt] = useState("");
	const [discount, setDiscount] = useState("");
	const [discountReason, setDiscountReason] = useState("");
	const [modeOfPayment, setModeOfPayment] = useState("Cash");
	const [opConsultationFeedata, setopConsultationFeedata] = useState(0);
	let [amountPaidCash, setAmountPaidCash] = useState(0);
	let [amountPaidOnline, setAmountPaidOnline] = useState(0);
	const [additionalCharges, setAdditionalCharges] = useState([]);
	const [totalAmount, setTotalAmount] = useState(0);
	const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState(0);
	const [userIdx, setUserIdx] = useState("");
	const [userId, setUserId] = useState("");
	const [prescriptionId, setPrescriptionId] = useState("");
	const [specialityIdData, setspecialityIdData] = useState("");
	const [mno, setMno] = useState("");
	const handleUpdatePaymentStatus = (
		userId,
		prescriptionId,
		userIdx,
		opConsultationFee,
		specialityId,
		mno,
		name
	) => {
		setShow(true);
		// setbookingId_payemnt(bookingId);
		setTotalAmount(opConsultationFee);
		setTotalAmountAfterDiscount(opConsultationFee);
		setopConsultationFeedata(opConsultationFee);
		setDiscount(0);
		setDiscountReason("");
		setAdditionalCharges([]);
		// AddAdditionalCharge();
		setspecialityIdData(specialityId);
		setPrescriptionId(prescriptionId);
		setUserIdx(userIdx);
		setAmountPaidCash(0);
		setAmountPaidOnline(0);
		setUserId(userId);
		setMno(mno);
		dispatch(getUserDataById(userId, name, userIdx));
	};

	const AddAdditionalCharge = () => {
		let addncharges = [...additionalCharges];
		addncharges?.push({
			additional_charge_key: "",
			additional_charge_amount: 0,
		});
		setAdditionalCharges(addncharges);
		setAmountPaidOnline(0);
		setAmountPaidCash(0);
	};

	const editAdditionalChargeKey = (index, additional_charge_key) => {
		let addncharges = additionalCharges?.map((addncharge, i) => {
			if (index === i) {
				return { ...addncharge, additional_charge_key: additional_charge_key };
			} else {
				return addncharge;
			}
		});
		setAdditionalCharges(addncharges);
		setAmountPaidOnline(0);
		setAmountPaidCash(0);
	};

	const editAdditionalChargeAmount = (index, additional_charge_amount) => {
		let addncharges = additionalCharges?.map((addncharge, i) => {
			if (index === i) {
				return {
					...addncharge,
					additional_charge_amount: additional_charge_amount,
				};
			} else {
				return addncharge;
			}
		});
		setAdditionalCharges(addncharges);

		const additionalChargesTotal = addncharges?.reduce((total, addncharge) => {
			return total + (Number(addncharge?.additional_charge_amount) || 0); // Using || 0 to handle undefined or null values
		}, 0);

		setTotalAmount(
			Number(opConsultationFeedata) + Number(additionalChargesTotal)
		);
		setTotalAmountAfterDiscount(
			Number(opConsultationFeedata) + Number(additionalChargesTotal)
		);
		setAmountPaidOnline(0);
		setAmountPaidCash(0);
	};

	const deleteAdditionalCharge = (index) => {
		let addncharges = [...additionalCharges];
		addncharges?.splice(index, 1);
		setAdditionalCharges(addncharges);

		const additionalChargesTotal = addncharges?.reduce((total, addncharge) => {
			return total + (Number(addncharge?.additional_charge_amount) || 0); // Using || 0 to handle undefined or null values
		}, 0);

		setTotalAmount(
			Number(opConsultationFeedata) + Number(additionalChargesTotal)
		);
		setTotalAmountAfterDiscount(
			Number(opConsultationFeedata) + Number(additionalChargesTotal)
		);
		setAmountPaidOnline(0);
		setAmountPaidCash(0);
	};

	const handleAmounts = (amount, type) => {
		if (amount > totalAmountAfterDiscount) {
			alert(`Amount cannot exceed ${totalAmountAfterDiscount}`);
		} else {
			if (type === "cash") {
				setAmountPaidOnline(0);
				setAmountPaidCash(Number(amount));
				setAmountPaidOnline(Number(totalAmountAfterDiscount) - Number(amount));
			} else {
				setAmountPaidCash(0);
				setAmountPaidOnline(Number(amount));
				setAmountPaidCash(Number(totalAmountAfterDiscount) - Number(amount));
			}
		}
	};

	// Function to handle change of discount percentage
	const handleDiscountChange = (event) => {
		if (event?.target?.value > 100) {
			alert("Discount cannot be greater than 100%");
		} else if (event?.target?.value < 0) {
			alert("Discount cannot be less than 0%");
		} else {
			setDiscount(event?.target?.value);
			let discountpercent = event?.target?.value;
			let AmountAfterDiscount =
				totalAmount -
				parseFloat(totalAmount * discountpercent * 0.01).toFixed(2);
			setTotalAmountAfterDiscount(AmountAfterDiscount);
			setAmountPaidOnline(0);
			setAmountPaidCash(0);
		}
		setAmountPaidOnline(0);
		setAmountPaidCash(0);
	};

	const handleSubmit = () => {
		// Log all input values

		console.log("Discount:", discount);
		console.log("Discount Reason:", discountReason);
		console.log("Mode of Payment:", modeOfPayment);
		console.log(additionalCharges);
		const additionalChargesTotal = additionalCharges?.reduce(
			(total, addncharge) => {
				return total + (addncharge?.additional_charge_amount || 0); // Using || 0 to handle undefined or null values
			},
			0
		);
		let bill = {
			AmountToBePaid: totalAmount,
			AmountPaid: Number(amountPaidCash) + Number(amountPaidOnline),
			AmountDue: 0,
			invoiceNumber:
				"INV" + getCurrentDateIST()?.split("-")?.join("") + mno + userIdx,
			prescription_charges: {
				AmountToBePaid: totalAmount,
				AmountDue: 0,
				discount: {
					discountAmount:
						Number(totalAmount) - Number(totalAmountAfterDiscount),
					discountReason: discountReason,
				},
				doctorId: doctorId,
				doctorName: doctorOption,
				prescriptionAmount: opConsultationFeedata,
				additionalCharges: additionalCharges,
			},
			inventory_charges: {},
			lab_charges: {},
			billDate: getCurrentDateIST(),
			billCreatedBy: adminInfo
				? adminInfo?.user?.name
				: receptionInfo
				? receptionInfo?.data?.user?.name
				: "",
			amountPaidOnline: amountPaidOnline,
			amountPaidCash: amountPaidCash,
			prescriptionAmountPaid: parseFloat(
				opConsultationFeedata - opConsultationFeedata * discount * 0.01
			).toFixed(2),
			prescriptionAmountPaidCash:
				parseFloat(amountPaidCash).toFixed(2) >=
				parseFloat(
					opConsultationFeedata - opConsultationFeedata * discount * 0.01
				).toFixed(2)
					? parseFloat(
							opConsultationFeedata - opConsultationFeedata * discount * 0.01
					  ).toFixed(2)
					: parseFloat(amountPaidCash).toFixed(2),
			prescriptionAmountPaidOnline:
				parseFloat(amountPaidOnline).toFixed(2) >=
				parseFloat(
					opConsultationFeedata -
						opConsultationFeedata * discount * 0.01 -
						amountPaidCash
				).toFixed(2)
					? parseFloat(
							opConsultationFeedata -
								opConsultationFeedata * discount * 0.01 -
								amountPaidCash
					  ).toFixed(2)
					: parseFloat(amountPaidOnline).toFixed(2),
		};

		console.log(bill);
		amountPaidOnline = amountPaidOnline === "" ? 0 : amountPaidOnline;
		amountPaidCash = amountPaidCash === "" ? 0 : amountPaidCash;
		if (
			Number(amountPaidOnline) + Number(amountPaidCash) >
			Number(totalAmountAfterDiscount)
		) {
			alert(`Amount cannot exceed ${totalAmountAfterDiscount}`);
		} else if (amountPaidOnline + amountPaidCash < totalAmountAfterDiscount) {
			alert(`Amount cannot be less than ${totalAmountAfterDiscount}`);
		} else if (
			additionalCharges.some(
				(item) =>
					item.additional_charge_key === "" ||
					item.additional_charge_amount === 0
			)
		) {
			alert("Fill all the additional charges");
		} else {
			dispatch(
				paymentStatusvisitedPatients(
					totalAmount - totalAmountAfterDiscount,
					discountReason,
					totalAmount,
					userId,
					"Cash",
					doctorInfo === undefined ? doctorId : doctorInfo?.data.user?._id,
					prescriptionId,
					userIdx,
					specialityIdData,
					bill
				)
			);

			setDiscount(0);
			setModeOfPayment("");
			setDiscountReason("");
			setAdditionalCharges([]);
			// Close the modal
			handleClose();
			setAmountPaidOnline(0);
			setAmountPaidCash(0);
			setTotalAmountAfterDiscount(0);
		}
	};

	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	const exportToExcel = async () => {
		let tempObj =
			getVisiPat?.getDoctorVisitedPatientsforParticularDate?.data?.data
				?.visited_patients;
		console.log(tempObj);
		let tempArr = [];
		for (let i = 0; i < tempObj.length; i++) {
			const obj = {
				"Patient Name": tempObj[i].name,
				"Slot ": tempObj[i].slot,
				"Mobile No": tempObj[i].mobilenumber,
				"Amount Paid": tempObj[i].billDetails.paidAmount,
				// "Paid Through": tempObj[i].modeOfPayment,
			};
			tempArr.push(obj);
		}
		console.log(tempArr + "dataaaaaa");

		const ws = XLSX.utils.json_to_sheet(tempArr);

		// Set column widths (example: set the width of the first column to 20)
		ws["!cols"] = [
			{ wch: 20 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 20 },
		];

		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(
			data,
			doctorInfo === undefined
				? receptionInfo?.data?.user?.doctorName
				: doctorInfo?.data?.user?.name + "_" + visitedDate
		);
		console.log("saved");
	};

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	const userdata1 = useSelector((state) => state.getUserDataById);
	const userdata = userdata1.userdata;

	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	const viewBill = (id, userIdx, name, userId) => {
		const userdata = {
			name: name,
			userId: userId,
			userIdx: userIdx,
		};
		const billUserDetails = {
			billId: id,
			userdata: userdata,
		};
		const queryParams = new URLSearchParams();

		// Convert historyItems to a JSON string and encode it for the URL
		queryParams.append(
			"billItems",
			encodeURIComponent(JSON.stringify(billUserDetails))
		);

		// Open the new tab with the query parameters
		window.open(`/bill?${queryParams.toString()}`, "_blank");
	};

	return (
		<div className='pageSize'>
			<Meta />

			{loading && <Loader />}
			{doctorInfo && (
				<Breadcrumb>
					<Breadcrumb.Item href='/doctor/patientstobevisited'>
						Patients to be visited
					</Breadcrumb.Item>
					<Breadcrumb.Item href='/doctor/visitedpatients'>
						Visited patients
					</Breadcrumb.Item>
				</Breadcrumb>
			)}
			{adminInfo && (
				<Breadcrumb>
					<Breadcrumb.Item href='/admin/patientstobevisited'>
						Patients to be visited
					</Breadcrumb.Item>
					<Breadcrumb.Item href='/admin/visitedpatients'>
						Visited patients
					</Breadcrumb.Item>
				</Breadcrumb>
			)}
			{receptionInfo && (
				<Breadcrumb>
					<Breadcrumb.Item href='/reception/patientstobevisited'>
						Patients to be visited
					</Breadcrumb.Item>
					<Breadcrumb.Item href='/reception/visitedpatients'>
						Visited patients
					</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<div>
				<h1 className='header-center'>Visited Patients</h1>
				{doctorInfo === undefined ? (
					<Container className='header-center mt-5 mb-4'>
						<Row className='justify-content-center'>
							<Col md='auto' className='text-center mt-1'>
								<h6>
									Select the filters{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</h6>
							</Col>
							<Col md='auto' className='text-center mt-1'>
								<DropdownButton
									key='down-centered'
									id={`dropdown-button-drop-down-centered`}
									drop='down-centered'
									variant='success'
									title={specialityOption}
									onSelect={handleSpecialitySelect}>
									{allSpecialities.map(
										(specialities, index) =>
											specialities.specialization !== "referral" &&
											specialities.specialization !== "Consultant Doctor" && (
												<Dropdown.Item
													eventKey={
														specialities.specialization +
														"/" +
														specialities._id +
														"/" +
														index
													}>
													{specialities.specialization}
												</Dropdown.Item>
											)
									)}
								</DropdownButton>
							</Col>
							<Col md='auto' className='text-center mt-1'>
								<DropdownButton
									key='down-centered'
									id={`dropdown-button-drop-down-centered`}
									drop='down-centered'
									variant='success'
									title={doctorOption}
									onSelect={handleDoctorSelect}>
									{DoctorFromSpecialityArray.map((doctors, index) => (
										<Dropdown.Item
											eventKey={doctors.name + "/" + doctors.doctorsinfo}>
											{doctors.name}
										</Dropdown.Item>
									))}
								</DropdownButton>
							</Col>
							<Col md='auto' className='text-center mt-1'>
								<input
									type='date'
									className='form-control'
									value={visitedDate}
									onChange={(e) => handleDateSelect1(e.target.value)}
									max={today}
								/>
							</Col>
							<Col md='auto' className='text-center mt-1'>
								<Button
									id='searchButton1'
									className='btn btn-primary'
									onClick={() => searchForVisited(false, "")}>
									Search
								</Button>
							</Col>
						</Row>
					</Container>
				) : (
					<Container className='header-center mt-5'>
						<Row className='justify-content-center'>
							<Col md='auto' className='text-center'>
								<input
									type='date'
									className='form-control'
									onChange={(e) => handleDateSelect1(e.target.value)}
									value={visitedDate}
									max={today}
								/>
							</Col>
							<Col md='auto' className='text-center'>
								<Button
									id='searchButton1'
									className='btn-primary'
									onClick={() => searchForVisited(false, "")}>
									Search
								</Button>
							</Col>
						</Row>
					</Container>
				)}
				{searchClicked && (
					<Container className='header-center mb-2'>
						<Row className='justify-content-end'>
							<Col md='auto' className='text-center'>
								<button
									className='btn btn-success'
									onClick={(e) => exportToExcel()}>
									<i className='fa fa-download'></i>&nbsp;Download
								</button>
							</Col>
						</Row>
					</Container>
				)}

				<Container>
					{visitedDate !== "" && searchClicked && (
						<Table striped bordered hover className='custom-table'>
							<thead>
								<tr>
									<th scope='col'>Patient No</th>
									<th scope='col'>Patient Name</th>
									<th scope='col'>Slot</th>
									<th scope='col'>Patient Mobile Number</th>
									{todayOrNot && <th scope='col'>Action</th>}
								</tr>
							</thead>

							{getVisiPat?.getDoctorVisitedPatientsforParticularDate?.data?.data?.visited_patients?.map(
								(
									{
										name,
										mobilenumber,
										prescriptionId,
										slot,
										billDetails,
										userId,
										userIdx,
										opConsultationFee,
										specialityId,
									},
									index
								) => (
									<tbody>
										<tr>
											<td>{index + 1}</td>
											<td>{name}</td>
											<td>{slot}</td>
											<td>{mobilenumber}</td>
											{todayOrNot && (
												<td>
													{billDetails?.billId === "" ? (
														<button
															className='btn btn-success'
															onClick={() =>
																handleUpdatePaymentStatus(
																	userId,
																	prescriptionId,
																	userIdx,
																	opConsultationFee,
																	specialityId,
																	mobilenumber,
																	name
																)
															}>
															<i className='	fa fa-credit-card'></i> Payment
														</button>
													) : (
														<div>
															<button
																className='btn btn-success'
																onClick={() =>
																	viewBill(
																		billDetails?.billId,
																		userIdx,
																		name,
																		userId
																	)
																}>
																<i class='fas fa-file-invoice-dollar'></i> View
																Bill
															</button>
														</div>
													)}
												</td>
											)}
										</tr>
									</tbody>
								)
							)}
						</Table>
					)}
				</Container>
				<Modal show={show} onHide={handleClose} size='lg'>
					<Modal.Header closeButton>
						<Modal.Title>Update Payment Status</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>
								Consultation Fee:
							</div>
							<input
								type='text'
								className='form-control col-8'
								value={`Rs ${opConsultationFeedata}`}
								disabled
							/>
						</div>

						{additionalCharges?.length > 0 && (
							<div className='row mt-2'>
								<div className='col-4 col-md-3 cardHeading'>
									Additional Charges:{" "}
								</div>
							</div>
						)}
						{additionalCharges?.map((addncharge, index) => (
							<div className='row mt-2'>
								<input
									type='text'
									className='offset-md-1 col-md-5 col-6 form-control'
									placeholder='Enter Name'
									value={addncharge?.additional_charge_key}
									onChange={(e) =>
										editAdditionalChargeKey(index, e.target.value)
									}
								/>
								<input
									type='number'
									className='offset-md-1 col-md-3 col-6 form-control'
									placeholder='Enter Amount'
									value={addncharge?.additional_charge_amount}
									onChange={(e) =>
										editAdditionalChargeAmount(index, e.target.value)
									}
								/>
								<button
									className='btn btn-danger ml-3'
									onClick={() => deleteAdditionalCharge(index)}>
									<i className='fa fa-trash'></i>
								</button>
							</div>
						))}
						<div className='row mt-2 d-flex justify-content-center'>
							<button
								className='btn btn-secondary'
								onClick={AddAdditionalCharge}>
								Add Additional Charges
							</button>
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Discount (%):</div>
							<input
								type='number'
								className='col-8 form-control'
								value={discount}
								onChange={handleDiscountChange}
							/>
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Discount Reason:</div>
							<input
								type='text'
								className='col-8 form-control'
								value={discountReason}
								onChange={(e) => setDiscountReason(e.target.value)}
							/>
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Total Amount:</div>
							<input
								type='text'
								className='form-control col-8'
								value={`Rs ${totalAmountAfterDiscount}`}
								disabled
							/>
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Total Cash:</div>
							<input
								type='number'
								className='col-3 form-control'
								placeholder='Amount Paid Cash'
								value={amountPaidCash}
								onChange={(e) => handleAmounts(e.target.value, "cash")}
							/>
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Total Online:</div>
							<input
								type='number'
								className='col-3 form-control'
								placeholder='Amount Paid Online'
								value={amountPaidOnline}
								onChange={(e) => handleAmounts(e.target.value, "online")}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='primary' onClick={handleSubmit}>
							Mark as Paid
						</Button>
						<Button variant='secondary' onClick={handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</div>
	);
};

export default PatientsVisitedScreen;
