/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getPurchaseById = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getPurchaseByIdStart: (state) => {
			state.loading = true;
		},
		getPurchaseByIdSuccess: (state, action) => {
			state.loading = false;
			state.getPurchaseByid = action.payload;
			state.error = false;
			state.success = true;
		},
		getPurchaseByIdFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getPurchaseByIdReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getPurchaseByIdStart,
	getPurchaseByIdSuccess,
	getPurchaseByIdFailure,
	getPurchaseByIdReset,
} = getPurchaseById.actions;
export default getPurchaseById.reducer;
