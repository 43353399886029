/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getHospitalandSpecialityStatistics = createSlice({
	name: 'getHospitalandSpecialityStatistics',
	initialState: {},
	reducers: {
		getHospitalandSpecialityStatisticsRequest: (state) => {
			state.loading = true;
		},
		getHospitalandSpecialityStatisticsSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.getHospitalandSpecialitystatistics = action.payload;
			state.error = false;
			state.success = true;
		},
		getHospitalandSpecialityStatisticsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getHospitalandSpecialityStatisticsReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getHospitalandSpecialityStatisticsRequest,
	getHospitalandSpecialityStatisticsSuccess,
	getHospitalandSpecialityStatisticsFailure,
	getHospitalandSpecialityStatisticsReset,
} = getHospitalandSpecialityStatistics.actions;
export default getHospitalandSpecialityStatistics.reducer;
