import { createSlice } from "@reduxjs/toolkit";

const getAllAdmittedPatientsReducer = createSlice({
  name: "getAllAdmittedPatients",
  initialState: {},
  reducers: {
    getAllAdmittedPatientsRequest: (state) => {
      state.loading = true;
    },
    getAllAdmittedPatientsSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.getAllAdmittedPatients = action.payload;
      state.error = false;
      state.success = true;
    },
    getAllAdmittedPatientsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllAdmittedPatientsReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getAllAdmittedPatientsRequest,
  getAllAdmittedPatientsSuccess,
  getAllAdmittedPatientsFailure,
  getAllAdmittedPatientsReset,
} = getAllAdmittedPatientsReducer.actions;
export default getAllAdmittedPatientsReducer.reducer;
