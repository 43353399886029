/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getPastCategoryRevenues = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		getPastCategoryRevenuesStart: (state) => {
			state.loading = true;
		},
		getPastCategoryRevenuesSuccess: (state, action) => {
			state.loading = false;
			state.getPastCategoryRevenues = action.payload;
			state.error = false;
			state.success = true;
		},
		getPastCategoryRevenuesFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getPastCategoryRevenuesReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getPastCategoryRevenuesStart,
	getPastCategoryRevenuesSuccess,
	getPastCategoryRevenuesFailure,
	getPastCategoryRevenuesReset,
} = getPastCategoryRevenues.actions;
export default getPastCategoryRevenues.reducer;
