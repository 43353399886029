import { createSlice } from "@reduxjs/toolkit";

const getDoctorfromSpecialityReducer = createSlice({
  name: "getDoctorfromSpeciality",
  initialState: {},
  reducers: {
    getDoctorfromSpecialityRequest: (state) => {
      state.loading = true;
    },
    getDoctorfromSpecialitySuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.getDoctorfromSpeciality = action.payload;
      state.error = false;
      state.success = true;
    },
    getDoctorfromSpecialityFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getDoctorfromSpecialityReset: (state) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  getDoctorfromSpecialityRequest,
  getDoctorfromSpecialitySuccess,
  getDoctorfromSpecialityFailure,
  getDoctorfromSpecialityReset,
} = getDoctorfromSpecialityReducer.actions;
export default getDoctorfromSpecialityReducer.reducer;
