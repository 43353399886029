/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import SearchPatient from "../../Components/SearchPatients";
import {
  getPatientsInBlockedSlot,
  rescheduleAppointmentForPatientinBlockedSlot,
  getBlockedSlotsDates,
} from "../../redux/receptionRedux/receptionApiCalls";
import { getTimeslots } from "../../redux/userApiCalls";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const BlockedSlots = () => {
  const dispatch = useDispatch();
  // Accordian Collapse
  const [open, setOpen] = useState([]);
  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((key) => key !== id));
    } else {
      setOpen([...open, id]);
    }
  };
  const [lgShow, setLgShow] = useState(false);
  const [selectDate, setSelectDate] = useState("Choose Date");
  const [selectDateNumeric, setSelectDateNumeric] = useState("Choose Date");
  const [selectDateIdx, setSelectDateIdx] = useState("Choose Date");
  const [selectedOrNot, setSelectedOrNot] = useState(false);
  const [intialSlot, setIntialSlot] = useState("");
  const handleSelection = (eventKey) => {
    setSelectDate(eventKey?.split("@@")[0]);
    setSelectedOrNot(false);
    setSelectDateNumeric(eventKey?.split("@@")[1]);
    setSelectDateIdx(eventKey?.split("@@")[2]);
  };

  const { getBlockedPatientsDate } = useSelector(
    (state) => state.getBlockedSlotsdates
  );
  console.log(getBlockedPatientsDate);
  const days = [];
  const formattedDates = getBlockedPatientsDate?.data?.data?.map((entry) => {
    const dateString = entry?.date?.toString();

    // Extract year, month, and day
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = ("0" + dateString.slice(6, 8)).slice(-2);

    // Create the formatted date string

    days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
  });

  const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
  console.log(getTimeSlotsData);
  const [shiftDays, setShiftDays] = useState([]);
  const [dateTimeSlots, setDateTimeSlots] = useState([]);
  useEffect(() => {
    var days1 = [];
    const doctorAppointmentDates =
      getTimeSlotsData?.allTimeSlots?.appointmentDates;
    const formattedDates1 = doctorAppointmentDates?.map((entry) => {
      const dateString = entry.date.toString();

      // Extract year, month, and day
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6);
      const day = ("0" + dateString.slice(6, 8)).slice(-2);

      // Create the formatted date string

      days1.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
    });
    setShiftDays(days1);
    console.log(days1);
  }, [getTimeSlotsData?.loading]);

  //write the getBlockedSlotsDates days call here to get all the dates on which slots are blocked by the doctor

  useEffect(() => {
    dispatch(getBlockedSlotsDates());
  }, []);

  const searchForBlocked = () => {
    let selectdate =
      selectDate?.split("-")[2].trim() +
      "" +
      selectDate?.split("-")[1].trim() +
      "" +
      selectDate?.split("-")[0].trim();
    console.log(selectdate);
    if (selectDate !== "Choose Date") {
      dispatch(getPatientsInBlockedSlot(selectdate));
      setSelectedOrNot(true);
    }
  };

  const { getBlockedPatients } = useSelector(
    (state) => state.getPatientsInBlockedSlots
  );
  console.log(getBlockedPatients);
  // const { allTimeSlots } = useSelector((state) => state.getTimeSlots);
  // let timeslot = [];
  // let isEmpty = true;
  // for (let i = 0; i < allTimeSlots?.length; i++) {
  // 	isEmpty = allTimeSlots[i].isEmpty;
  // 	timeslot.push({
  // 		isEmpty: isEmpty,
  // 		timeSlot: allTimeSlots[i].timeSlot,
  // 		curCount: allTimeSlots[i].curCount,
  // 		maxCount: allTimeSlots[i].maxCount,
  // 	});
  // }
  // console.log(timeslot);

  const [curCount, setCurCount] = useState("");
  const handleTimeSlot = (timeslot, curcount) => {
    setChangeSlotOption(timeslot);
    setCurCount(curcount);
  };

  const [shiftDate, setShiftDate] = useState("Choose Date");
  const [shiftDateNumeric, setShiftDateNumeric] = useState("Choose Date");
  const [shiftDateIdx, setShiftDateIdx] = useState("Choose Date");
  const handleDateSelection = (eventKey, docId) => {
    setShiftDate(eventKey?.split("@@")[0]);
    setDateTimeSlots(
      getTimeSlotsData?.allTimeSlots?.appointmentDates[eventKey?.split("@@")[1]]
        .timeSlots
    );
    setShiftDateNumeric(eventKey?.split("@@")[2]);
    setShiftDateIdx(eventKey?.split("@@")[3]);
  };
  const [selectedPatientToShift, setSelectedPatientToShift] = useState("");
  const [selectedPatientBooingId, setSelectedPatientBooingId] = useState();
  const [selectedPatientDoctorId, setSelectedPatientDoctorId] = useState("");
  const [username, setUsername] = useState("");
  const openModel = (userId, doctorId, slot, bookingId, username) => {
    setSelectedPatientToShift(userId);
    setSelectedPatientBooingId(bookingId);
    setSelectedPatientDoctorId(doctorId);
    setLgShow(true);
    setShiftDate("Choose Date");
    setChangeSlotOption("");
    dispatch(getTimeslots(doctorId));
    setIntialSlot(slot);
    setUsername(username);
  };
  const week = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const [changeSlotOption, setChangeSlotOption] = useState("");
  const handleReschedule = async (
    userId,
    bookingId,
    timeslot,
    doctorId,
    timeSlots
  ) => {
    const tempArr = shiftDate.split("/");
    const dateModified = tempArr[2] + "/" + tempArr[1] + "/" + tempArr[0];
    const dt = new Date(dateModified);
    var day = dt.getDay();

    console.log(
      selectedPatientToShift,
      selectedPatientBooingId,
      shiftDateNumeric,
      Number(shiftDateIdx),
      timeslot,
      curCount,
      "1",
      selectedPatientDoctorId,
      selectDateNumeric,
      Number(selectDateIdx),
      intialSlot
    );
    await dispatch(
      rescheduleAppointmentForPatientinBlockedSlot(
        selectedPatientToShift,
        selectedPatientBooingId,
        shiftDateNumeric,
        Number(shiftDateIdx),
        timeslot,
        curCount,
        "1",
        selectedPatientDoctorId,
        selectDateNumeric,
        Number(selectDateIdx),
        intialSlot,
        username
      )
    );
    setLgShow(false);
    let selectdate =
      selectDate?.split("-")[2].trim() +
      "" +
      selectDate?.split("-")[1].trim() +
      "" +
      selectDate?.split("-")[0].trim();
    console.log(selectdate);
    if (selectDate !== "Choose Date") {
      dispatch(getPatientsInBlockedSlot(selectdate));
      setSelectedOrNot(true);
    }
  };
  return (
    <>
      <Meta />
      <h1 className="header-center">Blocked Slots </h1>
      <Container className="header-center mt-5">
        <Row className="justify-content-center">
          <Col md="auto" className="text-center">
            <h6>
              Select the filters{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>{" "}
          </Col>
          <Col md="auto" className="text-center">
            <DropdownButton
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={selectDate}
              onSelect={handleSelection}
            >
              <div className="dropScroll">
                {days.map((day, index) => (
                  <Dropdown.Item
                    eventKey={day?.currDay + "@@" + day?.key + "@@" + index}
                  >
                    {day.currDay}
                  </Dropdown.Item>
                ))}
              </div>
            </DropdownButton>
          </Col>
          <Col md="auto" className="text-center">
            <Button
              id="searchButton1"
              className="btn btn-primary"
              onClick={searchForBlocked}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        {selectedOrNot && (
          <div className="scheduled-table">
            {getBlockedPatients?.data?.data?.patients_in_blockslot?.length >
            0 ? (
              getBlockedPatients?.data?.data?.patients_in_blockslot?.map(
                ({ blockedTimeSlots, doctorId, doctorName }) => (
                  <div>
                    {blockedTimeSlots?.length > 0 && (
                      <div className="header-center card m-2">
                        <h6 className="header-center">Doctor: {doctorName}</h6>
                      </div>
                    )}
                    {blockedTimeSlots?.map(
                      ({ blockedPatientsInTimeSlots, timeSlots }, index) => (
                        <Accordion open={open} toggle={toggle} className="mt-2">
                          <AccordionItem>
                            <AccordionHeader
                              targetId={timeSlots + "-" + doctorName}
                            >
                              {timeSlots}
                            </AccordionHeader>
                            <AccordionBody
                              accordionId={timeSlots + "-" + doctorName}
                            >
                              <Table
                                striped
                                bordered
                                hover
                                className="custom-table"
                              >
                                <thead>
                                  <tr>
                                    <th>S.no</th>
                                    <th>Patient name</th>
                                    <th>Mobile number</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {blockedPatientsInTimeSlots?.map(
                                    (
                                      { bookingId, name, user, userPhone },
                                      index
                                    ) => (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{name}</td>
                                        <td>{userPhone}</td>
                                        <td>
                                          <Button
                                            className="btn btn-primary"
                                            onClick={() =>
                                              openModel(
                                                user,
                                                doctorId,
                                                timeSlots,
                                                bookingId,
                                                name
                                              )
                                            }
                                          >
                                            <i className="fa fa-arrow-up"></i>
                                          </Button>
                                          <Modal
                                            size="xl"
                                            show={lgShow}
                                            onHide={() => setLgShow(false)}
                                            aria-labelledby="example-modal-sizes-title-xl"
                                          >
                                            <Modal.Header closeButton>
                                              <Modal.Title id="example-modal-sizes-title-xl">
                                                Select Slot
                                              </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                              <div className="row offset-md-4">
                                                <DropdownButton
                                                  className="col-2 btn"
                                                  key="down-centered"
                                                  id={`dropdown-button-drop-down-centered`}
                                                  drop="down-centered"
                                                  variant="success"
                                                  title={shiftDate}
                                                  onSelect={(eventKey) =>
                                                    handleDateSelection(
                                                      eventKey,
                                                      doctorId
                                                    )
                                                  }
                                                >
                                                  <div className="dropScroll">
                                                    {shiftDays.map(
                                                      (day, index) => (
                                                        <Dropdown.Item
                                                          eventKey={
                                                            day?.currDay +
                                                            "@@" +
                                                            index +
                                                            "@@" +
                                                            day?.key +
                                                            "@@" +
                                                            index
                                                          }
                                                        >
                                                          {day.currDay}
                                                        </Dropdown.Item>
                                                      )
                                                    )}
                                                  </div>
                                                </DropdownButton>
                                              </div>
                                              <div className="row offset-md-2">
                                                {shiftDate !== "Choose Date" &&
                                                  dateTimeSlots?.map(
                                                    (timeslots, index) =>
                                                      !timeslots.isEmpty ||
                                                      timeslots.isBlocked ? (
                                                        <Button
                                                          className="btn btn-danger col-md-3 col-10 m-1"
                                                          disabled
                                                        >
                                                          <b>
                                                            {timeslots.timeSlot}{" "}
                                                            No slots avaliable
                                                          </b>
                                                        </Button>
                                                      ) : (
                                                        <button
                                                          className={
                                                            changeSlotOption ===
                                                            timeslots.timeSlot
                                                              ? "btn btn-success col-md-3 col-10 m-1"
                                                              : "btn btn-outline-success col-md-3 col-10 m-1"
                                                          }
                                                          onClick={() =>
                                                            handleTimeSlot(
                                                              timeslots.timeSlot,
                                                              timeslots.curCount
                                                            )
                                                          }
                                                        >
                                                          <b>{`${
                                                            timeslots.timeSlot
                                                          } available slots: ${
                                                            timeslots.maxAppointmentsperhour -
                                                            timeslots.curCount
                                                          } `}</b>
                                                        </button>
                                                      )
                                                  )}
                                              </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                              <Button
                                                onClick={() =>
                                                  handleReschedule(
                                                    user,
                                                    bookingId,
                                                    changeSlotOption,
                                                    doctorId,
                                                    timeSlots
                                                  )
                                                }
                                                className="btn btn-primary"
                                              >
                                                Submit
                                              </Button>
                                            </Modal.Footer>
                                          </Modal>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </AccordionBody>
                          </AccordionItem>
                        </Accordion>
                      )
                    )}
                  </div>
                )
              )
            ) : (
              <h6 className="header-center text-danger">
                No appointments are blocked for the selected date.
              </h6>
            )}
          </div>
        )}
      </Container>
    </>
  );
};

export default BlockedSlots;
