/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Accordion, Table } from "react-bootstrap";
import { Container, Col, Row } from "react-bootstrap";
import { getPatientsInQueueForLabTechnician } from "../../redux/labRedux/labTechApiCalls";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Meta from "../../Components/Meta";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
	getTestTemplate,
	createLabReport,
} from "../../redux/labRedux/labTechApiCalls";
import { editTestNotesForPatientsInQueue } from "../../redux/labRedux/labTechApiCalls";
import { getUserDataById } from "../../redux/prescriptionRedux/prescriptionApiCalls";

const DynamicRow = ({ index, values, handleChange, handleDelete }) => {
	const dispatch = useDispatch();
	console.log(values);
	console.log("1");
	return (
		<div className="row mb-3">
			<div className="col-md-9 offset-md-1">
				<textarea
					className="form-control col-md-10"
					rows={2}
					value={values[index]}
					onChange={(e) => handleChange(`labNotes-${index}`, e.target.value)}
				></textarea>
			</div>
			<div className="col-md-1">
				<button
					className="m-2 btn btn-danger"
					onClick={() => handleDelete(index)}
				>
					<i className="fa fa-trash fa-2x"></i>
				</button>
			</div>
		</div>
	);
};

const CreateReportScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();

	const { labTechInfo } = useSelector((state) => state.labTechLogin);
	console.log(labTechInfo);

	useEffect(() => {
		dispatch(
			getTestTemplate(location?.state?.deptId, location?.state?.testIdx)
		);
		dispatch(
			getPatientsInQueueForLabTechnician(labTechInfo?.data?.user?.deptIdArray)
		);
	}, []);

	const { getTestTemplateOfRep } = useSelector(
		(state) => state.getTemplateNamesOfReport
	);
	const { getPatientsInQueueForLabTechnicians } = useSelector(
		(state) => state.getPatientsInQueue
	);
	const editTestNotes = useSelector(
		(state) => state.editTestNotesForPatientsInQueue
	);

	const editTestNotesForPatientsinQueue =
		editTestNotes?.editTestNotesForPatientsinQueue;
	const editTestNotesForPatientsinQueueLoading =
		editTestNotes?.editTestNotesForPatientsinQueue;

	console.log(editTestNotesForPatientsinQueueLoading);

	useEffect(() => {
		console.log(editTestNotesForPatientsinQueueLoading);
		dispatch(
			getPatientsInQueueForLabTechnician(labTechInfo?.data?.user?.deptIdArray)
		);
	}, [editTestNotesForPatientsinQueueLoading]);

	console.log(getTestTemplateOfRep);

	const [templateName, setTemplateName] = useState("Choose Template");
	const [templateIdx, setTemplateIdx] = useState();

	const handleTemplateSelect = (eventKey) => {
		setTemplateName(eventKey?.split("@@")[0]);
		setTemplateIdx(eventKey?.split("@@")[1]);
		setTemplateFields(
			getTestTemplateOfRep?.data?.data?.testDetails[location?.state?.testIdx]
				?.testTemplates[eventKey?.split("@@")[1]]?.templateFields
		);
	};

	const [templateFields, setTemplateFields] = useState([]);

	console.log(templateFields);

	const handleChangeFields = (index, value) => {
		let modifiedInput = [...templateFields];
		modifiedInput[index] = {
			...modifiedInput[index],
			labelValue: value,
		};
		setTemplateFields(modifiedInput);
	};

	useEffect(() => {
		let datearr = location?.state?.date?.toString()?.split("");
		let datestring =
			datearr[6] +
			datearr[7] +
			"-" +
			datearr[4] +
			datearr[5] +
			"-" +
			datearr[0] +
			datearr[1] +
			datearr[2] +
			datearr[3];
		setBookedDate(datestring);
		dispatch(
			getUserDataById(
				location?.state?.userId,
				location?.state?.username,
				location?.state?.userIdx
			)
		);
	}, []);

	const { userdata, loading } = useSelector((state) => state.getUserDataById);
	const [bloodGroup, setBloodGroup] = useState("");
	const [bookedDate, setBookedDate] = useState("");
	console.log(userdata);
	useEffect(() => {
		userdata?.data?.data?.vitals?.map((vital, index) => {
			if (vital?.vitalName === "BloodGroup") {
				setBloodGroup(vital?.vitalValue);
			}
		});
	}, [loading]);

	console.log(userdata);

	console.log(location?.state);

	const [show, setShow] = useState(false);
	const [rows, setRows] = useState([]);
	const [values, setValues] = useState([]);

	const [prevNotes, setPrevNotes] = useState([]);

	const handleChangePrevNotes = (index, value) => {
		let arr = [...prevNotes];
		arr[index] = value;
		setPrevNotes(arr);
	};

	console.log(prevNotes);

	const handleDeletePrevNotes = (index) => {
		let arr = [...prevNotes];
		if (index > -1) {
			arr.splice(index, 1);
		}
		setPrevNotes(arr);
	};

	const handleClose = () => setShow(false);

	const handleShow = () => {
		// Function to handle showing the modal and setting up rows
		setPrevNotes([]);
		setRows([]);
		setValues([]);

		setShow(true);
		setPrevNotes(
			getPatientsInQueueForLabTechnicians?.data?.data[location?.state?.deptInd]
				?.patientsInQueue[location?.state?.patientInd]?.labTechnicianNotes
		);
	};

	const handleNotesSelect = () => {
		let finalNotesArr = [];
		let newNotesArr = Object.values(values);
		console.log(newNotesArr);
		for (let i = 0; i < prevNotes?.length; i++) {
			finalNotesArr?.push(prevNotes[i]);
		}
		for (let i = 0; i < newNotesArr?.length; i++) {
			finalNotesArr?.push(newNotesArr[i]);
		}
		console.log(finalNotesArr);

		dispatch(
			editTestNotesForPatientsInQueue(
				location?.state?.deptId,
				location?.state?.prescriptionId,
				finalNotesArr,
				location?.state?.testName,
				location?.state?.userId,
				location?.state?.userIdx,
				location?.state?.testIdx
			)
		);
		handleClose();
	};

	const handleChange = (name, value) => {
		// const { name, value } = event.target;
		setValues((prevValues) => ({ ...prevValues, [name]: value }));
	};

	const handleAddRow = () => {
		setRows((prevRows) => [...prevRows, Date.now()]);
	};

	const handleDeleteRow = (index) => {
		console.log("OKKKK");
		const updatedValues = { ...values };
		Object.keys(updatedValues)
			.filter((key) => key.includes(index))
			.forEach((key) => delete updatedValues[key]);

		setValues(updatedValues);

		setRows((prevRows) => prevRows.filter((rowIndex) => rowIndex !== index));
	};

	const handleSubmit = () => {
		let flag = false;
		for (let i = 0; i < templateFields?.length; i++) {
			if (templateFields[i]?.labelValue === "") {
				flag = true;
				break;
			}
		}
		if (flag) {
			alert("Enter all the fields");
		} else {
			dispatch(
				createLabReport(
					location?.state?.specialityId,
					location?.state?.specialityName,
					location?.state?.doctorId,
					location?.state?.doctorName,
					location?.state?.departmentName,
					location?.state?.deptId,
					location?.state?.labTechnicianName,
					location?.state?.labTechnicianId,
					location?.state?.testName,
					location?.state?.paidAmount,
					location?.state?.testDesc,
					templateFields,
					getTestTemplateOfRep?.data?.data?.testDetails[
						location?.state?.testIdx
					]?.testTemplates[templateIdx]?.interpretation,
					location?.state?.userId,
					location?.state?.username,
					location?.state?.userIdx,
					//variables used to add report to prescription
					location?.state?.prescriptionId,
					getPatientsInQueueForLabTechnicians?.data?.data[
						location?.state?.deptInd
					]?.patientsInQueue[location?.state?.patientInd]?.labTechnicianNotes,
					location?.state?.date,
					location?.state?.testIdx
				)
			);
			history("/labTech/patientsinqueue", {
				state: {
					trigger: true,
				},
			});
		}
	};

	return (
		<>
			<Container className="mt-3">
				<Meta />
				<h3 className="header-center mt-3">Create Report</h3>

				<Container className="header-center mt-5">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center">
							<div className="cardHeading">
								Patient Name: {location?.state?.username}
							</div>
						</Col>
						<Col md="auto" className="text-center">
							<div className="cardHeading">
								Age: {userdata?.data?.data?.age}
							</div>
						</Col>
						<Col md="auto" className="text-center">
							<div className="cardHeading">
								Gender: {userdata?.data?.data?.gender}
							</div>
						</Col>
						<Col md="auto" className="text-center">
							<div className="cardHeading">Blood Group: {bloodGroup}</div>
						</Col>
						<Col md="auto" className="text-center">
							<div className="cardHeading">Booked Date: {bookedDate}</div>
						</Col>
					</Row>
				</Container>

				<Container className="header-center mt-5 mb-3">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center">
							<h6>
								Select the filters{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={templateName}
								onSelect={handleTemplateSelect}
							>
								{getTestTemplateOfRep?.data?.data?.testDetails[
									location?.state?.testIdx
								]?.testTemplates?.map((template, index) => (
									<Dropdown.Item
										eventKey={template?.templateName + "@@" + index}
									>
										{template?.templateName}
									</Dropdown.Item>
								))}
							</DropdownButton>
						</Col>
						<Col md="auto" className="text-center">
							<Button variant="primary" onClick={() => handleShow()}>
								Edit Lab Notes
							</Button>
						</Col>
					</Row>
				</Container>

				<Table striped bordered hover className="custom-table">
					<thead>
						<tr>
							<th scope="col">Name</th>
							<th scope="col"> Value</th>
							<th scope="col">Units</th>
							<th scope="col">Reference Values</th>
						</tr>
					</thead>
					<tbody>
						{getTestTemplateOfRep?.data?.data?.testDetails[
							location?.state?.testIdx
						]?.testTemplates[templateIdx]?.templateFields?.map(
							(item, index) => (
								<tr>
									<td>{item?.labelName}</td>
									<td>
										<textarea
											value={item?.labelvalue}
											className="form-control"
											rows={1}
											onChange={(e) =>
												handleChangeFields(index, e?.target?.value)
											}
										></textarea>
									</td>
									<td>{item?.units}</td>
									<td>{item?.referenceValues}</td>
								</tr>
							)
						)}
					</tbody>
				</Table>
				<div className=" mt-5 mb-5">
					<h6>Interpretation</h6>
					<div className="text-container">
						{
							getTestTemplateOfRep?.data?.data?.testDetails[
								location?.state?.testIdx
							]?.testTemplates[templateIdx]?.interpretation
						}
					</div>
				</div>
				<div className="row mb-3 offset-md-10">
					<button className="btn btn-danger mr-1">Clear</button>
					<button className="btn btn-success" onClick={handleSubmit}>
						Submit
					</button>
				</div>
			</Container>
			<Modal
				show={show}
				onHide={handleClose}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Add Lab Notes</Modal.Title>
				</Modal.Header>
				<Modal.Body className="notes-model">
					{prevNotes?.map((note, index) => (
						<div className="row mb-3">
							<div className="col-md-9 offset-md-1">
								<textarea
									className="form-control col-md-10"
									rows={2}
									value={prevNotes[index]}
									onChange={(e) => handleChangePrevNotes(index, e.target.value)}
								></textarea>
							</div>
							<div className="col-md-1">
								<button
									className="m-2 btn btn-danger"
									onClick={() => handleDeletePrevNotes(index)}
								>
									<i className="fa fa-trash fa-2x"></i>
								</button>
							</div>
						</div>
					))}
					{rows.map((rowIndex) => (
						<DynamicRow
							key={rowIndex}
							index={rowIndex}
							values={values}
							handleChange={handleChange}
							handleDelete={handleDeleteRow}
						/>
					))}
					<div className="row mt-4 mb-2 section">
						<button
							className="btn btn-secondary offset-md-5"
							onClick={handleAddRow}
						>
							+ Add more notes
						</button>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleNotesSelect}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default CreateReportScreen;
