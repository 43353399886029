/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getLabVisitedPatients = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getLabVisitedPatientsStart: (state) => {
			state.loading = true;
		},
		getLabVisitedPatientsSuccess: (state, action) => {
			state.loading = false;
			state.visitedPatients = action.payload;
			state.error = false;
			state.success = true;
		},
		getLabVisitedPatientsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getLabVisitedPatientsReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getLabVisitedPatientsStart,
	getLabVisitedPatientsSuccess,
	getLabVisitedPatientsFailure,
	getLabVisitedPatientsReset,
} = getLabVisitedPatients.actions;
export default getLabVisitedPatients.reducer;
