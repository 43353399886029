/** @format */
import { persistor } from "../store";
import {
	loginStart,
	loginFailure,
	loginSuccess,
} from "./MyProfile/doctorLogin";
import { SERVER_URL } from "../../App";
import { hospitalname } from "../../App";
import {
	getMyAppointmentsFailure,
	getMyAppointmentsRequest,
	getMyAppointmentsSuccess,
} from "./Appointment_Management/getMyAppointments";
import {
	getMyTimeSlotGetFailure,
	getMyTimeSlotGetRequest,
	getMyTimeSlotGetSuccess,
} from "./Slot_Management/getMyTimeslots";
import {
	createLeaveSuccess,
	createLeaveFailure,
	createLeaveStart,
} from "./Leave_Management/createLeave";
import {
	blockSlotStart,
	blockSlotSuccess,
	blockSlotFailure,
} from "./Slot_Management/blockSlots";
import {
	unBlockSlotStart,
	unBlockSlotSuccess,
	unBlockSlotFailure,
} from "./Slot_Management/unBlockSlots";
import {
	verifyFailure,
	verifyReset,
	verifySuccess,
	verifyStart,
	logout,
} from "./MyProfile/VerifyCode";

import {
	createTemplateStart,
	createTemplateSuccess,
	createTemplateFailure,
	createTemplateReset,
} from "./Template_Management/createTemplate";

import {
	getTemplateNamesRequest,
	getTemplateNamesSuccess,
	getTemplateNamesFailure,
	getTemplateNamesReset,
} from "./Template_Management/getTemplateNames";

import {
	getTemplateRequest,
	getTemplateSuccess,
	getTemplateFailure,
	getTemplateReset,
} from "./Template_Management/getTemplate";

import {
	UpdatePasswordFailure,
	UpdatePasswordStart,
	UpdatePasswordSuccess,
} from "./MyProfile/updatePassword";

import {
	getLeavesRequest,
	getLeavesSuccess,
	getLeavesFailure,
	getLeavesReset,
} from "./Leave_Management/getLeaves";

import {
	deleteLeavesFailure,
	deleteLeavesRequest,
	deleteLeavesSuccess,
} from "./Leave_Management/deleteLeave";

import {
	UpdateTemplateStart,
	UpdateTemplateFailure,
	UpdateTemplateSuccess,
} from "./Template_Management/updateTemplate";

import {
	deleteTemplateSuccess,
	deleteTemplateFailure,
	deleteTemplateRequest,
} from "./Template_Management/deleteTemplate";

import {
	resendOtpStart,
	resendOtpReset,
	resendOtpFailure,
	resendOtpSuccess,
} from "./MyProfile/resendOtp";

import {
	forgotPasswordFailure,
	forgotPasswordStart,
	forgotPasswordSuccess,
} from "./MyProfile/forgotPassword";

import {
	resetPasswordFailure,
	resetPasswordStart,
	resetPasswordSuccess,
} from "./MyProfile/resetPassword";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";

import {
	changeBookingPeriodStart,
	changeBookingPeriodSuccess,
	changeBookingPeriodFailure,
} from "./Slot_Management/changeBookingPeriod";

import {
	editDoctorRequest,
	editDoctorSuccess,
	editDoctorFailure,
	editDoctorReset,
} from "./MyProfile/editDoctor";

import {
	paymentStatusFailure,
	paymentStatusRequest,
	paymentStatusSuccess,
	paymentStatusReset,
} from "../paymentStatus";

import {
	paymentStatusvisitedPatientsRequest,
	paymentStatusvisitedPatientsFailure,
	paymentStatusvisitedPatientsSuccess,
} from "../updatePaymentStatusinVisitedPatient";

import {
	prescriptionCreatorStart,
	prescriptionCreatorSuccess,
	prescriptionCreatorFailure,
} from "./Prescription_Management/prescriptionCreator";

import {
	GetPrescriptionTemplateNamesStart,
	GetPrescriptionTemplateNamesSuccess,
	GetPrescriptionTemplateNamesFailure,
} from "./Prescription_Management/GetPrescriptionTemplateNames";

import {
	GetPrescriptionTemplateStart,
	GetPrescriptionTemplateSuccess,
	GetPrescriptionTemplateFailure,
} from "./Prescription_Management/GetPrescriptionTemplate";

import {
	getAllRecommendedWordsStart,
	getAllRecommendedWordsSuccess,
	getAllRecommendedWordsFailure,
	getAllRecommendedWordsReset,
} from "./Prescription_Management/getAllRecommendedWords";

import axios from "axios";

export const doctorLogin1 = (username, password) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(loginStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/doctor/login`,
			{ username, password },
			config
		);
		console.log(data);

		// console.log(data.user);
		dispatch(loginSuccess());
		dispatch(
			allMessagesSuccess(
				"Credentials verified, Please verify the otp sent to your registered email"
			)
		);
		localStorage.setItem("sessionStartTime", Date.now().toString());
	} catch (error) {
		dispatch(allMessagesFailure("Incorrect mobile number or password"));
		dispatch(
			loginFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const resendOtp = (username) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(resendOtpStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/doctor/resendOtp`,
			{ username },
			config
		);
		console.log(data);

		dispatch(resendOtpSuccess());
		dispatch(allMessagesSuccess("OTP sent successfully"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			resendOtpFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const verifyCode = (mobileNo, otp) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(verifyStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/doctor/otpVerification`,
			{ mobilenumber: mobileNo, otp },
			config
		);
		console.log(data);
		localStorage.setItem("token-user", data.token);

		localStorage.setItem("doctorInfo", JSON.stringify(data));
		localStorage.setItem(
			"username",
			JSON.stringify("2e09a5b01eac28408404f266726d465c")
		);
		// console.log(data.user);
		dispatch(verifySuccess(data));
		dispatch(allMessagesSuccess("OTP Verified"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			verifyFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const logout3 = () => (dispatch) => {
	dispatch(logout());
	// dispatch(userUpdateProfileReset());
	// dispatch(bookingListMyReset());
	// dispatch(tourReviewCreateReset());
	localStorage.removeItem("userInfo");
	localStorage.removeItem("adminInfo");
	localStorage.removeItem("receptionInfo");
	localStorage.removeItem("labTechInfo");
	localStorage.removeItem("doctorInfo");
	localStorage.removeItem("username");
	localStorage.removeItem("token-user");
	localStorage.removeItem("persist:root");
	localStorage.removeItem("sessionStartTime");
	persistor.pause();
	persistor.flush().then(() => {
		return persistor.purge();
	});
};

export const getMyAppointments =
	(speciality, doctor, date) => async (dispatch) => {
		try {
			console.log(speciality + " " + doctor + "  " + date);
			//loader function is called from getMyAppointmentss.js file
			dispatch(getMyAppointmentsRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			console.log(
				`${SERVER_URL}/api/v1/doctor/getMyAppointments/${speciality}/${doctor}/${date}`
			);
			//get request getMyAppointments function from doctor routes
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/doctor/getMyAppointments/${speciality}/${doctor}/${date}`,
				config
			);

			console.log(data);
			dispatch(getMyAppointmentsSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				getMyAppointmentsFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getMyTimeslots = (selectedname, date) => async (dispatch) => {
	try {
		console.log("11223344");
		const tempArr = date.split("/");
		const dateModified = tempArr[2] + "-" + tempArr[1] + "-" + tempArr[0];
		dateModified.trim();
		console.log(dateModified);
		dispatch(getMyTimeSlotGetRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/booking/getTimeSlots/${selectedname}/${dateModified}`,
			config
		);
		console.log(data.data.docs);
		dispatch(getMyTimeSlotGetSuccess(data.data.docs));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getMyTimeSlotGetFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const createLeave =
	(id, startDate, endDate, reason) => async (dispatch) => {
		try {
			dispatch(createLeaveStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/createLeave`,
				{ id, reason, startDate, endDate },
				config
			);
			console.log(data);
			dispatch(createLeaveSuccess(data));
			dispatch(allMessagesSuccess("Leave created successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				createLeaveFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getLeave = (id) => async (dispatch) => {
	try {
		dispatch(getLeavesRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/doctor/getLeaves/${id}`,
			config
		);
		console.log(data);
		dispatch(getLeavesSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getLeavesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const deleteLeave = (id, arr) => async (dispatch) => {
	try {
		dispatch(deleteLeavesRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		console.log(arr + "line 453");
		arr.sort(function (a, b) {
			return a > b ? 1 : a < b ? -1 : 0;
		});
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/doctor/deleteLeave`,
			{ id, leavesArr: arr },
			config
		);
		dispatch(deleteLeavesSuccess(data));
		dispatch(allMessagesSuccess("Leaves deleted Successfully"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			deleteLeavesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const blockSlot =
	(id, blockedSlots, dateModified, dateIndex, slotIdsIdx) =>
	async (dispatch) => {
		try {
			dispatch(blockSlotStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/blockSlots`,
				{ id, blockedSlots, dateModified, dateIndex, slotIdsIdx },
				config
			);
			console.log(data.data.docs);
			dispatch(blockSlotSuccess(data.data.docs));
			dispatch(allMessagesSuccess("Slot blocked Successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				blockSlotFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const unBlockSlot =
	(id, unBlockedSlots, dateModified, dateIndex, slotIdsIdx) =>
	async (dispatch) => {
		try {
			console.log(id, unBlockedSlots, dateModified);

			dispatch(unBlockSlotStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/unBlockSlots`,
				{ id, unBlockedSlots, dateModified, dateIndex, slotIdsIdx },
				config
			);
			console.log(data.data.docs);
			dispatch(unBlockSlotSuccess(data.data.docs));
			dispatch(allMessagesSuccess("Slot unblocked Successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				unBlockSlotFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const createTemplate = (id, name, medicines) => async (dispatch) => {
	try {
		dispatch(createTemplateStart());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/doctor/createTemplate`,
			{ id, name, medicines },
			config
		);
		dispatch(createTemplateSuccess(data));
		dispatch(allMessagesSuccess("Template created Successfully"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			createTemplateFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getTemplateNames = (id) => async (dispatch) => {
	try {
		dispatch(getTemplateNamesRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/doctor/getTemplateNames/${id}`,
			config
		);
		console.log(data);
		dispatch(getTemplateNamesSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getTemplateNamesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getTemplate1 = (id, templateId) => async (dispatch) => {
	try {
		dispatch(getTemplateRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		console.log(id, templateId);
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/doctor/getTemplate/${id}/${templateId}`,
			config
		);
		dispatch(getTemplateSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getTemplateFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const updateTemplate =
	(id, templateId, templateData) => async (dispatch) => {
		try {
			dispatch(UpdateTemplateStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/updateTemplate/${id}/${templateId}`,
				{ templateData: templateData },
				config
			);
			dispatch(UpdateTemplateSuccess(data));
			dispatch(allMessagesSuccess("Template updated Successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				UpdateTemplateFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const deleteTemplate = (id, templateId) => async (dispatch) => {
	try {
		dispatch(deleteTemplateRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.delete(
			`${SERVER_URL}/api/v1/doctor/deleteTemplate/${id}/${templateId}`,
			config
		);
		dispatch(deleteTemplateSuccess(data));
		dispatch(allMessagesSuccess("Template deleted Successfully"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			deleteTemplateFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const updatePassword = (password) => async (dispatch, getState) => {
	try {
		dispatch(UpdatePasswordStart());
		console.log("getting3221");
		const {
			userLogin: { userInfo },
		} = getState();
		console.log(password);
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};

		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/users/updatePassword`,
			{ password },
			config
		);
		console.log(data);
		dispatch(UpdatePasswordSuccess(data));
		dispatch(
			allMessagesSuccess("Password updated Successfully. Please login again")
		);
		dispatch(logout());
		// dispatch(userUpdateProfileReset());
		// dispatch(bookingListMyReset());
		// dispatch(tourReviewCreateReset());
		localStorage.removeItem("doctorInfo");
		localStorage.removeItem("token-user");
		localStorage.removeItem("persist:root");
		persistor.pause();
		persistor.flush().then(() => {
			return persistor.purge();
		});
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		const message =
			error.response && error.response.data.data.message
				? error.response.data.data.message
				: error.message;
		if (message === "Not authorized, token failed") {
			localStorage.removeItem("persist:root");
			dispatch(logout());
		}
		dispatch(UpdatePasswordFailure(message));
	}
};

export const forgotPassword = (username, email) => async (dispatch) => {
	try {
		dispatch(forgotPasswordStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
			},
		};

		const message = await axios.post(
			`${SERVER_URL}/api/v1/users/forgotPassword`,
			{
				username,
				email,
			},
			config
		);
		dispatch(forgotPasswordSuccess());
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			forgotPasswordFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const resetPassword =
	(password, token, userType) => async (dispatch) => {
		try {
			dispatch(resetPasswordStart());
			console.log(password, token);
			const data = await axios.post(
				`${SERVER_URL}/api/v1/users/resetPassword`,
				{
					password: password,
					token: token,
					userType,
				}
			);
			dispatch(resetPasswordSuccess());
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				resetPasswordFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const changeBookingPeriod =
	(
		newBookingPeriod,
		existingBookingPeriod,
		tempDays,
		appointmentsArrLength,
		_id,
		lastAppointmentDate
	) =>
	async (dispatch) => {
		try {
			dispatch(changeBookingPeriodStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/changeBookingPeriod`,
				{
					newBookingPeriod,
					existingBookingPeriod,
					tempDays,
					appointmentsArrLength,
					_id,
					lastAppointmentDate,
				},
				config
			);
			console.log(data);

			dispatch(verifySuccess(data));
			dispatch(loginSuccess(data));
			dispatch(allMessagesSuccess(" Booking Period changed Succesfully."));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error?.response);
			dispatch(
				changeBookingPeriodFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const editDoctor =
	(
		name,
		mobilenumber,
		email,
		speciality,

		dob,
		gender,
		designation,
		education,
		experience,
		personality,
		address,
		id,
		opConsultationFee,
		ipConsultationFee,
		ipSurgeryFee
	) =>
	async (dispatch) => {
		try {
			dispatch(editDoctorRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/editMyself`,
				{
					name,
					mobilenumber,
					email,
					speciality,

					dob,
					gender,
					designation,
					education,
					experience,
					personality,
					address,
					id,
					opConsultationFee,
					ipConsultationFee,
					ipSurgeryFee,
				},
				config
			);
			dispatch(editDoctorSuccess(data));
			localStorage.setItem("doctorInfo", JSON.stringify(data));
			console.log(data);
			// console.log(data.user);
			dispatch(verifySuccess(data));
			dispatch(loginSuccess(data));
			dispatch(allMessagesSuccess("Successfully changed the fields"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editDoctorFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const changeSlots =
	(days, id, appointmentDatesLength, bookingPeriod) => async (dispatch) => {
		try {
			dispatch(editDoctorRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/editMySlots`,
				{
					days,
					id,
					appointmentDatesLength,
					bookingPeriod,
				},
				config
			);
			//dispatch(editDoctorSuccess(data));
			localStorage.setItem("doctorInfo", JSON.stringify(data));

			// console.log(data.user);

			dispatch(loginSuccess(data));
			dispatch(editDoctorSuccess());
			dispatch(allMessagesSuccess("Successfully changed the slots"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editDoctorFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const paymentStatusUpdate =
	(
		discount,
		reason,
		amount,
		bookingId,
		modeOfPayment,
		billDetails,
		prescriptionAmountPaid,
		prescriptionAmountPaidCash,
		prescriptionAmountPaidOnline,
		InventorypaidAmount
	) =>
	async (dispatch) => {
		try {
			dispatch(paymentStatusRequest());
			// dispatch(paymentStatusReset());
			console.log(
				"line966" + discount,
				reason,
				amount,
				bookingId,
				modeOfPayment,
				billDetails
			);
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const data = await axios.post(
				`${SERVER_URL}/api/v1/booking/updatePaymentStatus`,
				{
					discount: parseFloat(discount.toFixed(2)),
					reason,
					amount: parseFloat(amount.toFixed(2)),
					bookingId,
					modeOfPayment,
					billDetails,
					prescriptionAmountPaid,
					prescriptionAmountPaidCash,
					prescriptionAmountPaidOnline,
					InventorypaidAmount,
				},
				config
			);

			console.log(data);
			dispatch(paymentStatusSuccess(data));
			dispatch(allMessagesSuccess("Payment Status changed Succesfully."));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				paymentStatusFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const paymentStatusvisitedPatients =
	(
		discount,
		reason,
		amount,
		userId,
		modeOfPayment,
		doctorId,
		prescriptionId,
		patientIndex,
		specialityIdData,
		billDetails
	) =>
	async (dispatch) => {
		try {
			dispatch(paymentStatusvisitedPatientsRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			console.log(username);
			const data = await axios.post(
				`${SERVER_URL}/api/v1/booking/updatePaymentStatusinVisitedPatient`,
				{
					discount: parseFloat(discount.toFixed(2)),
					reason,
					amount: parseFloat(amount.toFixed(2)),
					userId,
					modeOfPayment,
					doctorId,
					prescriptionId,
					username,
					userIdx: patientIndex,
					specialityId: specialityIdData,
					billDetails,
				},
				config
			);
			dispatch(paymentStatusvisitedPatientsSuccess());
			dispatch(allMessagesSuccess("Payment Status changed Succesfully."));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				paymentStatusvisitedPatientsFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const PrescriptionCreator =
	(prescriptionTemplateName, prescriptionTemplate, specialityIds) =>
	async (dispatch) => {
		try {
			dispatch(prescriptionCreatorStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");

			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const data = await axios.post(
				`${SERVER_URL}/api/v1/speciality/createPrescriptionTemplateInSpeciality`,
				{
					prescriptionTemplateName,
					prescriptionTemplate,
					specialityIds,
				},
				config
			);

			dispatch(prescriptionCreatorSuccess());
			dispatch(allMessagesSuccess("Template Saved Succesfully."));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				prescriptionCreatorFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const GetPrescriptionTemplateNames =
	(specialityId) => async (dispatch) => {
		try {
			//loader function is called from getMyAppointmentss.js file
			dispatch(GetPrescriptionTemplateNamesStart());
			console.log(specialityId);
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/speciality/getPrescriptionTemplateNamesandHistoryTemplateNamesInSpeciality/${specialityId}`,
				config
			);

			console.log(data);
			dispatch(GetPrescriptionTemplateNamesSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				GetPrescriptionTemplateNamesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const GetPrescriptionTemplate =
	(specialityId, index) => async (dispatch) => {
		try {
			//loader function is called from getMyAppointmentss.js file
			dispatch(GetPrescriptionTemplateStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/speciality/getPrescriptionTemplateInSpeciality/${specialityId}/${index}`,
				config
			);

			console.log(data);
			dispatch(GetPrescriptionTemplateSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				GetPrescriptionTemplateFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getAllRecommendedWordsArray = () => async (dispatch) => {
	try {
		//loader function is called from getMyAppointmentss.js file
		dispatch(getAllRecommendedWordsStart());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
				hospitalname: hospitalname,
			},
		};

		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/admin/getRecommendationNames`,
			config
		);

		console.log(data);
		dispatch(getAllRecommendedWordsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getAllRecommendedWordsFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};
