/** @format */

import { createSlice } from "@reduxjs/toolkit";
const adminItemsFromStorage = JSON.parse(localStorage.getItem("persist:root"))
  ? JSON.parse(localStorage.getItem("persist:root"))?.adminLogin
  : {};

const admin = createSlice({
  name: "admin",
  initialState: adminItemsFromStorage,
  reducers: {
    verifyStart: (state) => {
      state.loading = true;
    },
    verifySuccess: (state, action) => {
      state.loading = false;
      state.adminInfo = action.payload;
      state.error = false;
      state.success = true;
    },
    verifyFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    verifyReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
    logout: (state) => {
      state.adminInfo = {};
    },
  },
});

export const {
  verifyStart,
  verifySuccess,
  verifyFailure,
  logout,
  verifyReset,
} = admin.actions;
export default admin.reducer;
