/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getStatisticsofParticularTest = createSlice({
	name: 'getStatisticsofParticularTest',
	initialState: {},
	reducers: {
		getStatisticsofParticularTestRequest: (state) => {
			state.loading = true;
		},
		getStatisticsofParticularTestSuccess: (state, action) => {
			console.log('gotit13');
			state.loading = false;
			state.getStatisticsofParticulartest = action.payload;
			state.error = false;
			state.success = true;
		},
		getStatisticsofParticularTestFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getStatisticsofParticularTestReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getStatisticsofParticularTestRequest,
	getStatisticsofParticularTestSuccess,
	getStatisticsofParticularTestFailure,
	getStatisticsofParticularTestReset,
} = getStatisticsofParticularTest.actions;
export default getStatisticsofParticularTest.reducer;
