/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getIpBillReducer = createSlice({
	name: "getIpBill",
	initialState: {},
	reducers: {
		getIpBillRequest: (state) => {
			state.loading = true;
			state.getIpBill = [];
		},
		getIpBillSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getIpBill = action.payload;
			state.error = false;
			state.success = true;
		},
		getIpBillFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getIpBillReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getIpBillRequest,
	getIpBillSuccess,
	getIpBillFailure,
	getIpBillReset,
} = getIpBillReducer.actions;
export default getIpBillReducer.reducer;
