/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getReferralDoctor = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		getReferralDoctorStart: (state) => {
			state.loading = true;
		},
		getReferralDoctorSuccess: (state, action) => {
			state.loading = false;
			state.allReferralDoctors = action.payload;
			state.error = false;
			state.success = true;
		},
		getReferralDoctorFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getReferralDoctorReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getReferralDoctorStart,
	getReferralDoctorSuccess,
	getReferralDoctorFailure,
	getReferralDoctorReset,
} = getReferralDoctor.actions;
export default getReferralDoctor.reducer;
