/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getTestNameReducer = createSlice({
	name: 'getTestName',
	initialState: {},
	reducers: {
		getTestNameRequest: (state) => {
			state.loading = true;
		},
		getTestNameSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.getTestNameall = action.payload;
			state.error = false;
			state.success = true;
		},
		getTestNameFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getTestNameReset: (state) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getTestNameRequest,
	getTestNameSuccess,
	getTestNameFailure,
	getTestNameReset,
} = getTestNameReducer.actions;
export default getTestNameReducer.reducer;
