/** @format */

import { createSlice } from '@reduxjs/toolkit';
const labTechItemsFromStorage = JSON.parse(localStorage.getItem('persist:root'))
	? JSON.parse(localStorage.getItem('persist:root'))?.labTechLogin
	: {};

const labTech = createSlice({
	name: 'labTech',
	initialState: labTechItemsFromStorage,
	reducers: {
		verifyStart: (state) => {
			state.loading = true;
		},
		verifySuccess: (state, action) => {
			state.loading = false;
			state.labTechInfo = action.payload;
			state.error = false;
			state.success = true;
			console.log(state.labTechInfo);
		},
		verifyFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		verifyCodelabTechReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
		logout: (state) => {
			state.labTechInfo = {};
		},
	},
});

export const {
	verifyStart,
	verifySuccess,
	verifyFailure,
	logout,
	verifyReset,
} = labTech.actions;
export default labTech.reducer;
