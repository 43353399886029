/** @format */

import { createSlice } from "@reduxjs/toolkit";
const labTechItemsFromStorage = JSON.parse(localStorage.getItem("persist:root"))
  ? JSON.parse(localStorage.getItem("persist:root"))?.labTechLogin
  : {};

const labTech = createSlice({
  name: "labTech",
  initialState: labTechItemsFromStorage,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      //state.labTechInfo = action.payload;
      state.error = false;
      state.success = true;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    loginLabTechReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
    logout: (state) => {
      state.labTechInfo = {};
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logout, loginReset } =
  labTech.actions;
export default labTech.reducer;
