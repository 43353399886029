/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getIndividualCategoryRevenue = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		getIndividualCategoryRevenueStart: (state) => {
			state.loading = true;
		},
		getIndividualCategoryRevenueSuccess: (state, action) => {
			state.loading = false;
			state.getIndividualCategoryRevenue = action.payload;
			state.error = false;
			state.success = true;
		},
		getIndividualCategoryRevenueFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getIndividualCategoryRevenueReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getIndividualCategoryRevenueStart,
	getIndividualCategoryRevenueSuccess,
	getIndividualCategoryRevenueFailure,
	getIndividualCategoryRevenueReset,
} = getIndividualCategoryRevenue.actions;
export default getIndividualCategoryRevenue.reducer;
