/** @format */

import { createSlice } from '@reduxjs/toolkit';

const GetPrescriptionTemplateNames = createSlice({
	name: 'GetPrescriptionTemplateNames',
	initialState: {},
	reducers: {
		GetPrescriptionTemplateNamesStart: (state) => {
			state.loading = true;
			state.getPrescriptionTemplateNames = [];
		},
		GetPrescriptionTemplateNamesSuccess: (state, action) => {
			state.loading = false;
			state.getPrescriptionTemplateNames = action.payload;
			state.error = false;
			state.success = true;
		},
		GetPrescriptionTemplateNamesFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		GetPrescriptionTemplateNamesReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	GetPrescriptionTemplateNamesStart,
	GetPrescriptionTemplateNamesSuccess,
	GetPrescriptionTemplateNamesFailure,
	GetPrescriptionTemplateNamesReset,
} = GetPrescriptionTemplateNames.actions;
export default GetPrescriptionTemplateNames.reducer;
