/** @format */

import Message from "../../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Pagination from "react-bootstrap/Pagination";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import {
  createLeave,
  deleteLeave,
  getLeave,
  blockSlot,
  unBlockSlot,
} from "../../../redux/doctorRedux/doctorApiCalls";
import { getTimeslots } from "../../../redux/userApiCalls";
import {
  allMessagesStart,
  allMessagesSuccess,
  allMessagesFailure,
  allMessagesReset,
} from "../../../redux/allMessages";

import { createLeaveReset } from "../../../redux/doctorRedux/Leave_Management/createLeave";
import { deleteLeavesReset } from "../../../redux/doctorRedux/Leave_Management/deleteLeave";
import { getLeavesReset } from "../../../redux/doctorRedux/Leave_Management/getLeaves";
import { blockSlotReset } from "../../../redux/doctorRedux/Slot_Management/blockSlots";
import { unBlockSlotReset } from "../../../redux/doctorRedux/Slot_Management/unBlockSlots";
import { TimeSlotGetReset } from "../../../redux/getTimeSlots";

const DoctorCreateLeavesScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  // success and failure Message
  const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
  const getTimeSlotssuccess = getTimeSlotsData.success;
  const getTimeSlotserror = getTimeSlotsData.error;
  const getTimeSlotsloading = getTimeSlotsData.loading;

  const { getLeaves, success, error, loading } = useSelector(
    (state) => state.getLeaves
  );

  const createLeaveData = useSelector((state) => state.createLeaves);
  const createLeavesuccess = createLeaveData.success;
  const createLeaveerror = createLeaveData.error;
  const createLeaveloading = createLeaveData.loading;

  const doctorLogin = useSelector((state) => state.doctorLogin);
  const { doctorInfo } = doctorLogin;

  const [createStartDate, setCreateStartDate] = useState();
  const [createEndDate, setCreateEndDate] = useState();
  const [createReason, setCreateReason] = useState("");

  // Create Leaves
  var date = new Date();
  const dt = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);
  const days = [];
  const doctorAppointmentDates = doctorInfo?.data?.user?.appointmentDates;

  const formattedDates = doctorAppointmentDates?.map((entry) => {
    const dateString = entry.date.toString();

    // Extract year, month, and day
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = ("0" + dateString.slice(6, 8)).slice(-2);

    // Create the formatted date string

    days.push({ currDay: `${day}/${month}/${year}`, key: entry.date });
  });
  console.log(doctorAppointmentDates, days);

  function addOneDay(dateString) {
    const parts = dateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // JS months are 0-based
    const year = parseInt(parts[2], 10);

    const date = new Date(year, month, day);
    date.setDate(date.getDate() + 1); // Add one day

    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;

    return formattedDate;
  }

  // Adding a day to the last date in the array
  if (days.length > 0) {
    const lastDateString = days[days.length - 1].currDay;
    var nextDay = addOneDay(lastDateString);

    console.log(nextDay); // Logs the next day in a human-readable format
  } else {
    console.error("The days array is empty or undefined.");
  }

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var parts = days[days.length - 1].currDay.split("/"); // Split the date string
  var day = parseInt(parts[0]); // Parse day as integer
  var month = parseInt(parts[1]) - 1; // Parse month as integer (subtract 1 because month is zero-based)
  var year = parseInt(parts[2]); // Parse year as integer

  // Construct the Date object
  var date = new Date(year, month, day);
  date.setDate(date.getDate() + 1); // Add one day to the date

  // Get the day, month, and year of the updated date
  var newDay = date.getDate();
  var newMonth = date.getMonth() + 1; // Add 1 because month is zero-based
  var newYear = date.getFullYear();

  // Construct the formatted date string
  var LeavesStart = newDay + " " + monthNames[newMonth - 1] + " " + newYear;

  console.log(LeavesStart);

  var actualDate = 0;
  const handleCallback = (start, end, label) => {
    actualDate = start;
    let rSMon = (
      months.indexOf(start._d.toString().split(" ")[1]) + 1
    ).toString();
    if (rSMon.length === 1) rSMon = 0 + "" + rSMon;
    let rSDate = start._d.toString().split(" ")[2].toString();
    let rSYear = start._d.toString().split(" ")[3].toString();
    let rSModDate = rSYear + "-" + rSMon + "-" + rSDate;

    let rEMon = (
      months.indexOf(end._d.toString().split(" ")[1]) + 1
    ).toString();
    if (rEMon.length === 1) rEMon = 0 + "" + rEMon;
    let rEDate = end._d.toString().split(" ")[2].toString();
    let rEYear = end._d.toString().split(" ")[3].toString();
    let rEModDate = rEYear + "-" + rEMon + "-" + rEDate;

    setCreateStartDate(rSModDate);
    setCreateEndDate(rEModDate);
  };

  const submitCreateHandler = () => {
    const date1 = new Date(createStartDate);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(createStartDate, createEndDate);
    if (date1 < date2 || diffDays < 7) {
      alert("Booking for leaves must be starting from " + LeavesStart);
      let datenw = addOneDay(days[days.length - 1].currDay);
      datenw =
        datenw.split("/")[2] +
        "-" +
        datenw.split("/")[0] +
        "-" +
        datenw.split("/")[1];
      setCreateStartDate(datenw);
      setCreateEndDate(datenw);
    } else if (createReason == "") {
      alert("Please submit the reason for leave");
    } else {
      dispatch(
        createLeave(
          doctorInfo?.data?.user?._id,
          createStartDate,
          createEndDate,
          createReason
        )
      );
      let datenw = addOneDay(days[days.length - 1].currDay);
      datenw =
        datenw.split("/")[2] +
        "-" +
        datenw.split("/")[0] +
        "-" +
        datenw.split("/")[1];
      setCreateStartDate(datenw);
      setCreateEndDate(datenw);
      setCreateReason("");
    }
  };

  //ALerts//////////////////////////////////////////////////////////////////

  const [timerRunning, setTimerRunning] = useState(false);
  useEffect(() => {
    dispatch(allMessagesStart());
  }, []);
  const alertmsg = useSelector((state) => state.allMessages);

  useEffect(() => {
    if (createLeavesuccess && !timerRunning) {
      setTimerRunning(true);

      const timer = setTimeout(() => {
        // Clear the alert and stop the timer after 3 seconds
        dispatch(createLeaveReset());

        setTimerRunning(false);
      }, 3000);

      // Clean up the timer if the component unmounts or the alert changes
    }
  }, [createLeavesuccess, dispatch]);

  return (
    <>
      <Meta />

      {(loading || getTimeSlotsloading || createLeaveloading) && <Loader />}

      <div>
        <Container className="col-md-8 mt-5">
          <h4 className="header-center">Apply Leaves</h4>
          <div className="mt-5 row">
            <label className="ml-3 form-label col-md-3" htmlFor="name">
              <b>Select Date: </b>
              <span className="text-danger">
                <b>*</b>
              </span>
            </label>
            <DateRangePicker
              initialSettings={{ startDate: nextDay, endDate: nextDay }}
              onCallback={handleCallback}
            >
              <input className="form-control col-md-8" type="text" />
            </DateRangePicker>
          </div>
          <div className="mt-5 row">
            <label className="ml-3 form-label col-md-3" htmlFor="name">
              <b>Reason for leave: </b>
              <span className="text-danger">
                <b>*</b>
              </span>
            </label>
            <input
              className="form-control col-md-8"
              id="desc"
              type="text"
              placeholder="Enter reason"
              required="required"
              value={createReason}
              onChange={(e) => setCreateReason(e.target.value)}
            />
          </div>
          <h6 className="mt-5 header-center text-danger">
            <b>Note: Leaves from {LeavesStart} are acceptable.</b>
          </h6>
          <div className="mb-5 row justify-content-center">
            <button className="btn btn-success" onClick={submitCreateHandler}>
              Apply
            </button>
          </div>
        </Container>
      </div>
    </>
  );
};

export default DoctorCreateLeavesScreen;
