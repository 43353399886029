/** @format */

import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Col, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Meta from '../../../Components/Meta';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getLabDepts } from '../../../redux/adminRedux/adminApiCalls';
import {
	editLabTestTemplates,
	getTestTemplate,
} from '../../../redux/labRedux/labTechApiCalls';
import { Table } from 'react-bootstrap';

const DynamicRow = ({ index, values, handleChange, handleDelete }) => {
	return (
		<>
			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col md='4'>
						<Col md='12' className='text-center'>
							<h6>Label Name:</h6>{' '}
						</Col>
						<Col md='12' className='text-center'>
							<textarea
								rows={1}
								cols={33}
								type='text'
								name={`name-${index}`}
								value={values[`name-${index}`]}
								onChange={handleChange}></textarea>
						</Col>
					</Col>
					<Col md='3'>
						<Col md='12' className='text-center'>
							<h6>Units:</h6>{' '}
						</Col>
						<Col md='12' className='text-center'>
							<textarea
								rows={1}
								cols={33}
								type='text'
								name={`units-${index}`}
								value={values[`units-${index}`]}
								onChange={handleChange}></textarea>
						</Col>
					</Col>
					<Col md='4'>
						<Col md='12' className='text-center'>
							<h6>Reference Values:</h6>{' '}
						</Col>
						<Col md='12' className='text-center'>
							<textarea
								rows={1}
								cols={33}
								type='text'
								name={`reference-${index}`}
								value={values[`reference-${index}`]}
								onChange={handleChange}></textarea>
						</Col>
					</Col>
					<Col md='1'>
						<Col>
							<button
								className='btn btn-danger'
								onClick={() => handleDelete(index)}>
								<i className='fa fa-trash fa-2x'></i>
							</button>{' '}
						</Col>
					</Col>
				</Row>
			</Container>
		</>
	);
};

const EditTestTemplateScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	useEffect(() => {
		dispatch(getLabDepts());
	}, []);
	const { getlabDeptAll } = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	const { editLabTestTemplate } = useSelector(
		(state) => state.editTestTemplate
	);

	const [editClicked, setEditClicked] = useState(false);
	const [deptName, setDeptName] = useState('Choose Department');
	const [deptId, setDeptId] = useState('');
	const [testName, setTestName] = useState('Choose Test');
	const [templateName, setTemplateName] = useState('Choose Template');
	const [deptTestNames, setdeptTestNames] = useState([]);
	const [testIdx, setTestIdx] = useState([]);

	const handleDeptSelect = (eventKey) => {
		setDeptName(eventKey.split('@@')[0]);
		setDeptId(eventKey.split('@@')[1]);
		setTestIdx(eventKey.split('@@')[2]);
		setdeptTestNames(
			getlabDeptAll?.data?.data[eventKey.split('@@')[2]]?.testDetails
		);
		setTestName('Choose Test');
		setTemplateName('Choose Template');
	};

	const [selectedTestIdx, setSelectedTestIdx] = useState('');

	const handleTestSelect = (eventKey) => {
		setTestName(eventKey?.split('@@')[0]);
		setSelectedTestIdx(eventKey?.split('@@')[1]);
		dispatch(getTestTemplate(deptId, setSelectedTestIdx));
		setTemplateName('Choose Template');
	};

	const [selectedTemplateIdx, setSelectedTemplateIdx] = useState('');
	const [newTemplateName, setNewTemplateName] = useState('');
	const [newInterpretation, setNewInterpretation] = useState('');

	const [editValues, setEditValues] = useState([]);
	const handleTemplateSelect = (eventKey) => {
		setTemplateName(eventKey?.split('@@')[0]);
		setSelectedTemplateIdx(eventKey?.split('@@')[1]);
		setNewTemplateName(
			getTestTemplateOfRep?.data?.data?.testDetails[selectedTestIdx]
				?.testTemplates[eventKey?.split('@@')[1]]?.templateName
		);
		setEditValues(
			getTestTemplateOfRep?.data?.data?.testDetails[selectedTestIdx]
				?.testTemplates[eventKey?.split('@@')[1]]?.templateFields
		);
		setNewInterpretation(
			getTestTemplateOfRep?.data?.data?.testDetails[selectedTestIdx]
				?.testTemplates[eventKey?.split('@@')[1]]?.interpretation
		);
	};

	const { getTestTemplateOfRep, loading } = useSelector(
		(state) => state.getTemplateNamesOfReport
	);

	console.log(getTestTemplateOfRep);

	const [rows, setRows] = useState([]);
	const [values, setValues] = useState({});
	console.log(rows);
	console.log(values);
	const handleChange = (event) => {
		const { name, value } = event.target;
		setValues((prevValues) => ({ ...prevValues, [name]: value }));
	};

	const handleAddRow = () => {
		setRows((prevRows) => [...prevRows, Date.now()]);
	};

	const handleDeleteRow = (index) => {
		const updatedValues = { ...values };
		Object.keys(updatedValues)
			.filter((key) => key.includes(index))
			.forEach((key) => delete updatedValues[key]);

		setValues(updatedValues);

		setRows((prevRows) => prevRows.filter((rowIndex) => rowIndex !== index));
	};

	const handleChangeEdit = (value, index, key) => {
		if (key === 'labelName') {
			let modifiedFields = [...editValues];
			modifiedFields[index] = {
				...modifiedFields[index],
				labelName: value,
			};
			setEditValues(modifiedFields);
		} else if (key === 'units') {
			let modifiedFields = [...editValues];
			modifiedFields[index] = {
				...modifiedFields[index],
				units: value,
			};
			setEditValues(modifiedFields);
		} else if (key === 'referenceValues') {
			let modifiedFields = [...editValues];
			modifiedFields[index] = {
				...modifiedFields[index],
				referenceValues: value,
			};
			setEditValues(modifiedFields);
		}
	};

	const handleDeleteEdit = (index) => {
		const editData = [...editValues];
		if (index > -1) {
			editData.splice(index, 1);
		}
		setEditValues(editData);
		console.log(editValues);
	};

	const submitHandler = () => {
		if (
			Object.keys(values).length === 0 &&
			Object.keys(editValues).length === 0
		) {
			alert('Enter the test details');
		} else if (
			(Object.keys(values).length % 3 !== 0 ||
				Object.keys(values).length / 3 !== rows.length) &&
			Object.keys(editValues).length % 3 !== 0
		) {
			alert('Enter all the required fields');
		} else if (newInterpretation === '') {
			alert('Enter the interpretation');
		} else if (newTemplateName === '') {
			alert('Enter the Template Name');
		} else {
			const arr = Object.keys(values);
			let extractedValues = [];
			for (let i = 0; i < editValues?.length; i++) {
				extractedValues.push({
					labelName: editValues[i]?.labelName,
					units: editValues[i]?.units,
					referenceValues: editValues[i]?.referenceValues,
				});
			}
			console.log(arr);
			console.log(rows);
			for (let i = 0; i < rows.length; i++) {
				for (let j = 0; j < arr.length; j++) {
					if (
						arr[j].split('-')[1] == rows[i] &&
						arr[j].split('-')[0] === 'name'
					) {
						var name = values[arr[j]];
					}
					if (
						arr[j].split('-')[1] == rows[i] &&
						arr[j].split('-')[0] === 'units'
					) {
						var units = values[arr[j]];
					}
					if (
						arr[j].split('-')[1] == rows[i] &&
						arr[j].split('-')[0] === 'reference'
					) {
						var reference = values[arr[j]];
					}
				}
				console.log(name, units, reference);
				extractedValues.push({
					labelName: name,
					units: units,
					referenceValues: reference,
				});
			}
			console.log(extractedValues);
			dispatch(
				editLabTestTemplates(
					deptId,
					templateName,
					testName,
					newTemplateName,
					extractedValues,
					newInterpretation
				)
			);
			setDeptId('');
			setDeptName('Choose Department');
			setTestName('Choose Test');
			setNewInterpretation('');
			setTemplateName('Choose Template');
			setNewTemplateName('');
			setRows([]);
			setValues([]);
			setEditValues([]);
			setEditClicked(false);
		}
	};
	console.log(
		getTestTemplateOfRep?.data?.data?.testDetails?.[0]?.testTemplates?.[0]
			?.templateName
	);

	const discardHandler = () => {
		setEditClicked(false);
		setRows([]);
		setValues([]);
		handleTemplateSelect(templateName + '@@' + selectedTemplateIdx);
	};
	return (
		<>
			<Meta />
			<h2 className='header-center'>Edit Test Template</h2>
			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center'>
						<h6>
							Select the filters{' '}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md='auto' className='text-center'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={deptName}
							onSelect={handleDeptSelect}>
							{getlabDeptAll?.data?.data?.length === 0 ? (
								<Dropdown.Item>
									{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
								</Dropdown.Item>
							) : (
								getlabDeptAll?.data?.data?.map(
									(dept, index) =>
										dept.deptName !== 'hospital' &&
										dept.status !== 0 && (
											<Dropdown.Item
												eventKey={
													dept.deptName + '@@' + dept._id + '@@' + index
												}>
												{dept.deptName}
											</Dropdown.Item>
										)
								)
							)}
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={testName}
							onSelect={handleTestSelect}>
							{deptTestNames?.length === 0 ? (
								<Dropdown.Item>
									{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
								</Dropdown.Item>
							) : (
								deptTestNames?.map(
									(test, index) =>
										test.status !== 0 && (
											<Dropdown.Item eventKey={test.testName + '@@' + index}>
												{test.testName}
											</Dropdown.Item>
										)
								)
							)}
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={templateName}
							onSelect={handleTemplateSelect}>
							{getTestTemplateOfRep?.data?.data?.testDetails[
								selectedTestIdx
							]?.testTemplates?.map((template, index) => (
								<Dropdown.Item eventKey={template?.templateName + '@@' + index}>
									{template?.templateName}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</Col>
				</Row>
			</Container>
			{deptName !== 'Choose Department' &&
				testName !== 'Choose Test' &&
				templateName !== 'Choose Template' && (
					<Container className='header-center mt-3'>
						<Row className='justify-content-end'>
							<Col md='auto' className='text-center'>
								<button
									className='btn btn-outline-success'
									onClick={() => setEditClicked(true)}>
									Edit
								</button>
							</Col>
						</Row>
					</Container>
				)}
			{!editClicked &&
				deptName !== 'Choose Department' &&
				testName !== 'Choose Test' &&
				templateName !== 'Choose Template' && (
					<>
						<Container className='header-center mt-3 col-md-8'>
							<Table striped bordered hover className='custom-table'>
								<thead>
									<tr>
										<th>S.No</th>
										<th>Label Name</th>
										<th>Units</th>
										<th>Reference Values</th>
									</tr>
								</thead>
								<tbody>
									{editValues?.map((value, index) => (
										<tr>
											<td>{index + 1}</td>
											<td>{value?.labelName}</td>
											<td>{value?.units}</td>
											<td>{value?.referenceValues}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Container>
					</>
				)}
			{editClicked &&
				deptName !== 'Choose Department' &&
				testName !== 'Choose Test' &&
				templateName !== 'Choose Template' && (
					<>
						{editValues?.map((value, index) => (
							<Container className='header-center mt-5'>
								<Row className='justify-content-center'>
									<Col md='4'>
										<Col md='12' className='text-center'>
											<h6>Label Name:</h6>{' '}
										</Col>
										<Col md='12' className='text-center'>
											<textarea
												rows={1}
												cols={33}
												type='text'
												name={`name-${index}`}
												value={value?.labelName}
												onChange={(e) =>
													handleChangeEdit(e.target.value, index, 'labelName')
												}></textarea>
										</Col>
									</Col>
									<Col md='3'>
										<Col md='12' className='text-center'>
											<h6>Units:</h6>{' '}
										</Col>
										<Col md='12' className='text-center'>
											<textarea
												rows={1}
												cols={33}
												type='text'
												name={`units-${index}`}
												value={value?.units}
												onChange={(e) =>
													handleChangeEdit(e.target.value, index, 'units')
												}></textarea>
										</Col>
									</Col>
									<Col md='4'>
										<Col md='12' className='text-center'>
											<h6>Reference Values:</h6>{' '}
										</Col>
										<Col md='12' className='text-center'>
											<textarea
												rows={1}
												cols={33}
												type='text'
												name={`reference-${index}`}
												value={value?.referenceValues}
												onChange={(e) =>
													handleChangeEdit(
														e.target.value,
														index,
														'referenceValues'
													)
												}></textarea>
										</Col>
									</Col>
									<Col md='1'>
										<Col>
											<button
												className='btn btn-danger'
												onClick={() => handleDeleteEdit(index)}>
												<i className='fa fa-trash fa-2x'></i>
											</button>{' '}
										</Col>
									</Col>
								</Row>
							</Container>
						))}

						{rows.map((rowIndex) => (
							<DynamicRow
								key={rowIndex}
								index={rowIndex}
								values={values}
								handleChange={handleChange}
								handleDelete={handleDeleteRow}
							/>
						))}
						<Container className='header-center mt-5'>
							<Row className='justify-content-center'>
								<Col md='auto' className='text-center'>
									<button className='btn btn-secondary' onClick={handleAddRow}>
										+ Add more rows
									</button>
								</Col>
							</Row>
						</Container>

						<Container className='header-center mt-5'>
							<Row className='justify-content-center'>
								<Col md='auto' className='text-center'>
									<b>Interpretation:</b>
								</Col>
								<Col md='auto' className='text-center'>
									<textarea
										cols={110}
										rows={6}
										onChange={(e) => setNewInterpretation(e.target.value)}
										value={newInterpretation}></textarea>
								</Col>
							</Row>
						</Container>
						<Container className='header-center mt-5'>
							<Row className='justify-content-end'>
								<Col md='auto' className='text-center'>
									<button className='btn btn-danger' onClick={discardHandler}>
										Discard
									</button>
								</Col>
								<Col md='auto' className='text-center'>
									<button className='btn btn-success' onClick={submitHandler}>
										Submit
									</button>
								</Col>
							</Row>
						</Container>
					</>
				)}
		</>
	);
};

export default EditTestTemplateScreen;
