/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import Errortemplate from "../../Components/Errortemplate";
import { Link } from "react-router-dom";
import {
	login,
	verifyCodeAdmin,
	resendOtpAdmin,
} from "../../redux/adminRedux/adminApiCalls";
import Message from "../../Components/Message";

import {
	doctorLogin1,
	verifyCode,
	resendOtp,
} from "../../redux/doctorRedux/doctorApiCalls";
import {
	receptionLogin,
	resendOtpReception,
	verifyCodeReception,
} from "../../redux/receptionRedux/receptionApiCalls";
import {
	labTechLogin,
	verifyCodeLabTech,
} from "../../redux/labRedux/labTechApiCalls";
///reset calls
import { loginReset } from "../../redux/adminRedux/Admin_MyProfile/adminLogin";
import { verifyReset } from "../../redux/adminRedux/Admin_MyProfile/VerifyCode";
import { resendOtpReset } from "../../redux/adminRedux/Admin_MyProfile/resendOtp";

import { loginDoctorReset } from "../../redux/doctorRedux/MyProfile/doctorLogin";
import { verifyCodeDoctorReset } from "../../redux/doctorRedux/MyProfile/VerifyCode";
import { resendOtpDoctorReset } from "../../redux/doctorRedux/MyProfile/resendOtp";

import { loginReceptionReset } from "../../redux/receptionRedux/receptionLogin";
import { verifyCodeReceptionReset } from "../../redux/receptionRedux/VerifyCode";
import { resendOtpReceptionReset } from "../../redux/receptionRedux/resendOtp";

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";

const renderButton = (buttonProps) => {
	return (
		<button
			type="button"
			className="btn btn-danger col-md-5 offset-md-7 mt-3"
			{...buttonProps}
		>
			{buttonProps.remainingTime !== 0
				? `Please wait for ${buttonProps.remainingTime} sec`
				: "Resend"}
		</button>
	);
};
const renderTime = () => React.Fragment;

const AdminLoginScreens = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	//success and error//////////////////////////////////////
	const adminLogin = useSelector((state) => state.adminLogin);
	console.log(adminLogin);
	const { loading, error, adminInfo, success } = adminLogin;
	console.log(adminLogin);
	const doctorLogin = useSelector((state) => state.doctorLogin);
	const doctorLoginsuccess = doctorLogin.success;
	const doctorLoginerror = doctorLogin.error;
	const doctorLoginloading = doctorLogin.loading;

	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const receptionLoginsuccess = receptionLogin.success;
	const receptionLoginerror = receptionLogin.error;
	const receptionLoginloading = receptionLogin.loading;

	const { labTechInfo } = useSelector((state) => state.labTechLogin);

	const verifyPasswordAdmin = useSelector((state) => state.adminLogin2);
	const verifyPasswordDoctor = useSelector((state) => state.doctorLogin2);
	const verifyPasswordReception = useSelector((state) => state.receptionLogin2);
	const verifyPasswordLabTechnician = useSelector(
		(state) => state.labTechLogin2
	);

	///////////////////////////////////////////////////////////
	const [username, setusername] = useState("");
	const [password, setPassword] = useState("");
	const [generateOTPBtn, setGenerateOTPBtn] = useState(
		"btn btn-success mt-3 mb-3 offset-4 col-4"
	);
	const [OTP, setOTP] = useState("");
	const [show, setShow] = useState(false);
	const handleClick = () => setShow(!show);
	const [verifyOTP, setVerifyOTP] = useState("d-none");
	const [isMobile, setIsMobile] = useState(false);

	//const receptionLogin1 = useSelector((state) => state.receptionLogin1);
	// const { loading, error, receptionInfo, success } = receptionLogin1;

	console.log(doctorLogin);
	// const { loading, error, doctorInfo, success } = doctorLogin;
	console.log(adminInfo);
	useEffect(() => {
		if (adminInfo) {
			console.log("111");
			history("/admin/patientstobevisited");
		}
	}, [history, adminInfo]);
	useEffect(() => {
		if (doctorLogin.doctorInfo) {
			console.log("222");
			history("/doctor/patientstobevisited");
		}
	}, [history, doctorLogin.doctorInfo]);
	useEffect(() => {
		if (receptionInfo) {
			console.log(receptionInfo);
			console.log("222");
			history("/reception/patientstobevisited");
		}
	}, [history, receptionInfo]);
	useEffect(() => {
		if (labTechInfo) {
			console.log(labTechInfo);
			console.log("222");
			history("/labTech/createTest");
		}
	});
	//   useEffect(() => {
	//     if (receptionLogin1.receptionInfo) {
	//       console.log("222");
	//       history("/reception/myappointments");
	//     }
	//   }, [history, receptionLogin1.receptionInfo]);
	const submitHandler = () => {
		if (OTP.length < 6) {
			alert("OTP cannot be empty");
		} else {
			//role based authentication
			let temp = username.slice(0, 3);
			if (temp == "DOC") {
				console.log("doc login");
				// e.preventDefault();
				dispatch(verifyCode(username.slice(3, username.length + 1), OTP));
			} else if (temp == "ADM") {
				// e.preventDefault();
				dispatch(verifyCodeAdmin(username.slice(3, username.length + 1), OTP));
			} else if (temp == "REC") {
				// e.preventDefault();
				dispatch(
					verifyCodeReception(username.slice(3, username.length + 1), OTP)
				);
			} else if (temp == "LAB") {
				// e.preventDefault();
				dispatch(
					verifyCodeLabTech(username.slice(3, username.length + 1), OTP)
				);
			}
		}
	};
	const [timerRunning, setTimerRunning] = useState(false);

	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	useEffect(() => {
		if (success && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(loginReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [success, dispatch]);

	const handleResend = () => {
		let temp = username.slice(0, 3);
		if (temp == "DOC") {
			console.log("yeeee");
			//dispatch(resendOtp(username));
			dispatch(doctorLogin1(username, password));
		} else if (temp == "ADM") {
			//dispatch(resendOtpAdmin(username));
			dispatch(login(username, password));
		} else if (temp == "REC") {
			//dispatch(resendOtpReception(username));
			dispatch(receptionLogin(username, password));
		} else if (temp == "LAB") {
			//dispatch(resendOtpReception(username));
			dispatch(labTechLogin(username, password));
		}
	};

	const doctorLoginDetails = useSelector((state) => state.doctorLogin2);
	const receptionLoginDetails = useSelector((state) => state.receptionLogin2);
	const labTechLoginDetails = useSelector((state) => state.labTechLogin2);
	const adminLoginDetails = useSelector((state) => state.adminLogin2);

	const [generateClicked, setGenerateClicked] = useState(false);

	useEffect(() => {
		if (generateClicked) {
			if (
				doctorLoginDetails?.error ||
				receptionLoginDetails?.error ||
				labTechLoginDetails?.error ||
				adminLoginDetails?.error
			) {
				alert("Wrong credentials for username and password");
				setVerifyOTP("d-none");
			} else {
				setVerifyOTP("d-block");
			}
		}
	}, [
		doctorLoginDetails?.loading,
		receptionLoginDetails?.loading,
		labTechLoginDetails?.loading,
		adminLoginDetails?.loading,
	]);

	const generateOTP = () => {
		if (username == "") {
			alert("Username cannot be empty");
		} else if (password == "") {
			alert("Password cannot be empty");
		} else {
			let temp = username.slice(0, 3);
			if (temp == "DOC") {
				setVerifyOTP("d-block");
				setGenerateOTPBtn("btn btn-success mt-3 mb-3 offset-4 col-4 disabled");
				console.log(username.slice(3, username.length + 1));
				dispatch(doctorLogin1(username, password));
			} else if (temp == "ADM") {
				console.log(username);
				setVerifyOTP("d-block");
				setGenerateOTPBtn("btn btn-success mt`-3 mb-3 offset-4 col-4 disabled");
				console.log(username.slice(3, username.length + 1));
				dispatch(login(username, password));
			} else if (temp == "REC") {
				console.log(username);
				setVerifyOTP("d-block");
				setGenerateOTPBtn("btn btn-success mt-3 mb-3 offset-4 col-4 disabled");
				console.log(username.slice(3, username.length + 1));
				dispatch(receptionLogin(username, password));
			} else if (temp == "LAB") {
				console.log(username);
				setVerifyOTP("d-block");
				setGenerateOTPBtn("btn btn-success mt-3 mb-3 offset-4 col-4 disabled");
				console.log(username.slice(3, username.length + 1));
				dispatch(labTechLogin(username, password));
			} else {
				alert("Wrong credentials for username and password");
			}
		}
	};
	const [mybtn, setMyBtn] = useState("btn btn-blue");

	const handleThisForgot = () => {
		setMyBtn("btn btn-green");
		history("/verifymail");
	};
	console.log(error);

	return (
		<>
			{loading && <Loader />}

			<div className="row row-content">
				<div className="col-12 col-md-4 offset-md-4 loginForm">
					<h2 className="loginHeader header-center">Login</h2>
					<form className="form-group" novalidate id="user-form">
						<div className="form-group">
							<label for="username" className="col-md-12 col-form-label">
								<b>Username</b>{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<div className="col-md-12">
								<input
									type="text"
									className="form-control"
									id="username"
									name="username"
									placeholder="Enter your username"
									required
									onChange={(e) => setusername(e.target.value)}
								/>
							</div>
						</div>
						<div className="form-group">
							<label for="password" className="col-md-12 col-form-label">
								<b>Password</b>{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<div className="col-md-12 flex321">
								<input
									className="form-control"
									id="password"
									name="password"
									placeholder="••••••••"
									required
									onChange={(e) => setPassword(e.target.value)}
									type={show ? "text" : "password"}
								/>
								<button type="button" className="close" onClick={handleClick}>
									{show == 1 ? (
										<i class="fa fa-eye ppppp" aria-hidden="true"></i>
									) : (
										<i class="fa fa-eye-slash ppppp" aria-hidden="true"></i>
									)}
								</button>
							</div>
						</div>
						<div className="form-group row">
							<label for="sendOtpTo" className="col-md-4 form-check-label">
								<b>Send OTP to:</b>{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<div className="col-md-4">
								<input
									className="form-check-input"
									type="radio"
									name="sendOtpTo"
									id="radio1"
									value="option1"
									onChange={() => setIsMobile(true)}
									checked={isMobile}
								/>
								<label className="form-check-label" for="radio1">
									Mobile Number
								</label>
							</div>
							<div className="col-md-3">
								<input
									className="form-check-input"
									type="radio"
									name="sendOtpTo"
									id="radio2"
									value="option2"
									checked={!isMobile}
									onChange={() => setIsMobile(false)}
								/>
								<label className="form-check-label" for="radio2">
									Email
								</label>
							</div>
						</div>
						<div className="form-group row">
							<button
								type="button"
								onClick={() => generateOTP()}
								className={generateOTPBtn}
								disabled={doctorLoginsuccess || receptionLoginsuccess}
							>
								<b>Generate OTP</b>
							</button>
							<button
								type="button"
								onClick={() => handleThisForgot()}
								className="btn btn-outline-primary mt-3 mb-3 offset-4 col-4"
							>
								Forgot Password ?
							</button>
						</div>
						<div className={verifyOTP}>
							<div className="form-group">
								<OTPInput
									className="offset-md-2"
									value={OTP}
									onChange={setOTP}
									autoFocus
									OTPLength={6}
									otpType="number"
									disabled={false}
								/>
								<ResendOTP
									renderButton={renderButton}
									renderTime={renderTime}
									onResendClick={handleResend}
								/>
							</div>
							<div className="form-group">
								<button
									type="button"
									className="btn btn-success mt-2 offset-2 col-8"
									onClick={submitHandler}
								>
									<b>Verify OTP</b>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default AdminLoginScreens;
