/** @format */

import { React, useState } from "react";
import { resetPassword } from "../redux/doctorRedux/doctorApiCalls";
import { resetPasswordforReception } from "../redux/receptionRedux/receptionApiCalls";
import { resetPasswordForAdmin } from "../redux/adminRedux/adminApiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

const ForgotPassword = () => {
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const dispatch = useDispatch();
	const location = useLocation();
	console.log(location);
	const tokenPattern =
		/^\/(doctor|reception|admin|labtech|user)\/resetPassword\/(\w+)$/;

	const match = tokenPattern.exec(location.pathname);

	const forgotHandle = (e) => {
		e.preventDefault();
		// const urlSearchString = window.location.search;
		// const params = new URLSearchParams(urlSearchString);
		// console.log()

		//console.log(params);
		console.log(match[1] + "line28");
		if (newPassword === confirmPassword) {
			console.log(match[1], match[2]);
			if (match[1] == "doctor") {
				dispatch(resetPassword(newPassword, match[2], "DOC"));
			} else if (match[1] == "reception") {
				dispatch(resetPassword(newPassword, match[2], "REC"));
			} else if (match[1] === "admin") {
				dispatch(resetPassword(newPassword, match[2], "ADM"));
			} else if (match[1] === "labtech") {
				dispatch(resetPassword(newPassword, match[2], "LABTECH"));
			} else if (match[1] === "user") {
				dispatch(resetPassword(newPassword, match[2], "PAT"));
			}
		} else {
			console.log("not valid");
		}
	};
	const [show, setShow] = useState(false);
	const [show1, setShow1] = useState(false);
	const handleClick = () => setShow(!show);
	const handleClick1 = () => setShow1(!show1);

	return (
		<>
			<div className="forgotPassword">
				<div className="col-12 col-md-4 offset-md-4 loginForm">
					<h2 className="loginHeader">Forgot Password</h2>
					<form
						className="form-group"
						novalidate
						id="user-form"
						onSubmit={(e) => forgotHandle(e)}
					>
						<div className="form-group">
							<label for="username" className="col-md-12 col-form-label">
								<b>
									New Password <span className="text-danger">*</span>
								</b>
							</label>
							<div className="col-md-12 flex321">
								<input
									className="form-control"
									id="newPassword"
									name="password"
									placeholder="••••••••"
									type={show ? "text" : "password"}
									required
									onChange={(e) => setNewPassword(e.target.value)}
									minlength="8"
								/>
								<button type="button" className="close" onClick={handleClick}>
									{show == 1 ? (
										<i class="fa fa-eye ppppp" aria-hidden="true"></i>
									) : (
										<i class="fa fa-eye-slash ppppp" aria-hidden="true"></i>
									)}
								</button>
							</div>
						</div>
						<div className="form-group">
							<label for="password" className="col-md-12 col-form-label">
								<b>
									Confirm Password <span className="text-danger">*</span>
								</b>
							</label>
							<div className="col-md-12 flex321 ">
								<input
									className="form-control"
									id="confirmPassword"
									name="password"
									placeholder="••••••••"
									type={show1 ? "text" : "password"}
									required
									onChange={(e) => setConfirmPassword(e.target.value)}
									minlength="8"
								/>
								<button type="button" className="close" onClick={handleClick1}>
									{show1 == 1 ? (
										<i class="fa fa-eye ppppp" aria-hidden="true"></i>
									) : (
										<i class="fa fa-eye-slash ppppp" aria-hidden="true"></i>
									)}
								</button>
							</div>
						</div>
						<button type="submit" className="offset-md-5 btn btn-primary">
							submit
						</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default ForgotPassword;
