/** @format */

import { persistor } from "../store";
import {
	loginStart,
	loginFailure,
	loginSuccess,
} from "./Lab_Technician_Management/labTechLogin";
import { SERVER_URL } from "../../App";
import { hospitalname } from "../../App";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";
import {
	verifyStart,
	verifySuccess,
	verifyFailure,
	logout,
	verifyReset,
} from "./Lab_Technician_Management/VerifyCode";
import {
	createTestTemplateStart,
	createTestTemplateSuccess,
	createTestTemplateFailure,
	createTestTemplateReset,
} from "./Lab_Template_Management/CreateTestTemplate";

import {
	getTestTemplateRequest,
	getTestTemplateSuccess,
	getTestTemplateFailure,
	getTestTemplateReset,
} from "./Lab_Template_Management/GetTestTemplate";

import {
	resendOtpStart,
	resendOtpReset,
	resendOtpFailure,
	resendOtpSuccess,
} from "./Lab_Technician_Management/resendOtp";

import {
	UpdatePasswordFailure,
	UpdatePasswordStart,
	UpdatePasswordSuccess,
} from "./Lab_Technician_Management/updatePassword";

import {
	deleteLabTestTemplateFailure,
	deleteLabTestTemplateRequest,
	deleteLabTestTemplateSuccess,
} from "./Lab_Template_Management/DeleteTestTemplate";

import {
	editLabTestTemplateFailure,
	editLabTestTemplateRequest,
	editLabTestTemplateSuccess,
} from "./Lab_Template_Management/EditTestTemplate";

import {
	editLabTechnicianFailure,
	editLabTechnicianStart,
	editLabTechnicianSuccess,
} from "./Lab_Technician_Management/editLabTechnician";

import {
	getPatientsInQueueForLabTechnicianRequest,
	getPatientsInQueueForLabTechnicianSuccess,
	getPatientsInQueueForLabTechnicianFailure,
} from "./Lab_Department_Management/getPatientsInQueueForLabTechnician";

import {
	createReportStart,
	createReportSuccess,
	createReportFailure,
	createReportReset,
} from "./Lab_Technician_Management/createReport";

import {
	editTestStatusForPatientsInQueueStart,
	editTestStatusForPatientsInQueueSuccess,
	editTestStatusForPatientsInQueueFailure,
	editTestStatusForPatientsInQueueReset,
} from "./Lab_Technician_Management/editTestStatusForPatientsInQueue";

import {
	editTestNotesForPatientsInQueueStart,
	editTestNotesForPatientsInQueueSuccess,
	editTestNotesForPatientsInQueueFailure,
	editTestNotesForPatientsInQueueReset,
} from "./Lab_Technician_Management/editTestNotesForPatientsInQueue";

import {
	getLabVisitedPatientFailure,
	getLabVisitedPatientRequest,
	getLabVisitedPatientSuccess,
} from "./Lab_Department_Management/getLabVisitedPatients";

import {
	getLabVisitedPatientsStart,
	getLabVisitedPatientsSuccess,
	getLabVisitedPatientsFailure,
	getLabVisitedPatientsReset,
} from "./Lab_Technician_Management/getLabVisitedPatients";

import axios from "axios";

export const labTechLogin = (username, password) => async (dispatch) => {
	try {
		console.log("11223344");
		console.log(username, password);
		dispatch(loginStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/labDept/login`,
			{ username, password },
			config
		);
		console.log(data);

		// console.log(data.user);
		dispatch(loginSuccess());
		localStorage.setItem("sessionStartTime", Date.now().toString());
		dispatch(
			allMessagesSuccess(
				"Credentials verified, Please verify the otp sent to your registered email"
			)
		);
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(allMessagesFailure("Incorrect mobile number or password"));
		dispatch(
			loginFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const verifyCodeLabTech = (mobileNo, otp) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(verifyStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/labDept/otpVerification`,
			{ mobilenumber: mobileNo, otp },
			config
		);
		console.log(data);
		localStorage.setItem("token-user", data.token);

		localStorage.setItem("labTechInfo", JSON.stringify(data));
		localStorage.setItem(
			"username",
			JSON.stringify("0385d3bd8ebdc0c40c049ca17082129e")
		);
		// console.log(data.user);
		dispatch(verifySuccess(data));
		dispatch(allMessagesSuccess("OTP Verified"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			verifyFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const logout5 = () => (dispatch) => {
	dispatch(logout());
	// dispatch(userUpdateProfileReset());
	// dispatch(bookingListMyReset());
	// dispatch(tourReviewCreateReset());
	localStorage.removeItem("userInfo");
	localStorage.removeItem("adminInfo");
	localStorage.removeItem("receptionInfo");
	localStorage.removeItem("labTechInfo");
	localStorage.removeItem("doctorInfo");
	localStorage.removeItem("username");
	localStorage.removeItem("token-user");
	localStorage.removeItem("persist:root");
	localStorage.removeItem("sessionStartTime");
	persistor.pause();
	persistor.flush().then(() => {
		return persistor.purge();
	});
};

export const createTemplateForTest =
	(deptId, testName, templateName, templateFields, interpretation) =>
	async (dispatch) => {
		try {
			dispatch(createTestTemplateStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/createTemplateForTest`,
				{ deptId, testName, templateName, templateFields, interpretation },
				config
			);
			dispatch(createTestTemplateSuccess(data));
			dispatch(allMessagesSuccess("Template created Successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				createTestTemplateFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getTestTemplate = (deptId, testIdx) => async (dispatch) => {
	try {
		dispatch(getTestTemplateRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/labDept/getTemplatesForTest`,
			{ deptId, testIdx },
			config
		);
		dispatch(getTestTemplateSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getTestTemplateFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const resendOtp = (username) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(resendOtpStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/labDept/resendOtp`,
			{ username },
			config
		);
		console.log(data);
		dispatch(resendOtpSuccess());
		dispatch(allMessagesSuccess("OTP sent successfully"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			resendOtpFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const updatePassword = (password) => async (dispatch, getState) => {
	try {
		dispatch(UpdatePasswordStart());
		console.log("getting3221");
		// const {
		// 	user: { labTechInfo },
		// } = getState();
		// console.log(user + "line273");
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};

		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/users/updatePassword`,
			{ password },
			config
		);
		dispatch(UpdatePasswordSuccess(data));
		dispatch(
			allMessagesSuccess("Password updated Successfully. Please login again")
		);
		dispatch(logout());
		// dispatch(userUpdateProfileReset());
		// dispatch(bookingListMyReset());
		// dispatch(tourReviewCreateReset());
		localStorage.removeItem("labTechInfo");
		localStorage.removeItem("token-user");
		localStorage.removeItem("persist:root");
		persistor.pause();
		persistor.flush().then(() => {
			return persistor.purge();
		});
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		const message =
			error.response && error.response.data.data.message
				? error.response.data.data.message
				: error.message;
		if (message === "Not authorized, token failed") {
			localStorage.removeItem("persist:root");
			dispatch(logout());
		}
		dispatch(UpdatePasswordFailure(message));
	}
};

export const deleteTemplateInTest =
	(deptId, testName, templateName) => async (dispatch) => {
		try {
			console.log("11223344" + deptId);
			dispatch(deleteLabTestTemplateRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/deleteTestTemplate`,
				{
					deptId,
					testName,
					templateName,
				},
				config
			);
			console.log(data);
			dispatch(deleteLabTestTemplateSuccess(data));
			dispatch(allMessagesSuccess("Lab Technician Succesfully deleted"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				deleteLabTestTemplateFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const editLabTestTemplates =
	(
		id,
		templateName,
		testName,
		newTemplateName,
		newTemplateFields,
		newInterpretation
	) =>
	async (dispatch) => {
		console.log(newTemplateFields);
		try {
			dispatch(editLabTestTemplateRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			console.log(
				"Api called" + id,
				testName,
				newTemplateName,
				newTemplateFields,
				newInterpretation
			);
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/editTestTemplate`,
				{
					id,
					templateName,
					testName,
					newTemplateName,
					newTemplateFields,
					newInterpretation,
				},
				config
			);
			dispatch(editLabTestTemplateSuccess(data));

			dispatch(allMessagesSuccess("Successfully changed the fields"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editLabTestTemplateFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const editLabTechnician =
	(name, address, gender, dob, mobilenumber, email, username, id) =>
	async (dispatch) => {
		try {
			dispatch(editLabTechnicianStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/editLabTechnician`,
				{ name, address, gender, dob, mobilenumber, email, username, id },
				config
			);
			dispatch(editLabTechnicianSuccess(data));
			dispatch(verifySuccess(data));
			dispatch(allMessagesSuccess("Lab Test Succesfully updated"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editLabTechnicianFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getPatientsInQueueForLabTechnician =
	(LabTechnicianDeptIdArray) => async (dispatch) => {
		try {
			dispatch(getPatientsInQueueForLabTechnicianRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/getPatientsInQueueForLabTechnician`,
				{ LabTechnicianDeptIdArray },
				config
			);
			console.log(data);
			dispatch(getPatientsInQueueForLabTechnicianSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				getPatientsInQueueForLabTechnicianFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const createLabReport =
	(
		specialityId,
		specialityName,
		doctorId,
		doctorName,
		departmentName,
		deptId,
		labTechnicianName,
		labTechnicianId,
		testName,
		paidAmount,
		testDesc,
		report,
		interpretations,
		userId,
		username,
		userIdx,
		//variables used to add report to prescription
		prescriptionId,
		labTechnicianNotes,
		reportDate,
		testIdx
	) =>
	async (dispatch) => {
		try {
			dispatch(createReportStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/createLabReport`,
				{
					specialityId,
					specialityName,
					doctorId,
					doctorName,
					departmentName,
					deptId,
					labTechnicianName,
					labTechnicianId,
					testName,
					paidAmount,
					testDesc,
					report,
					interpretations,
					userId,
					username,
					userIdx,
					//variables used to add report to prescription
					prescriptionId,
					labTechnicianNotes,
					reportDate,
					testIdx,
				},
				config
			);
			console.log(data);
			dispatch(createReportSuccess(data));
			dispatch(allMessagesSuccess("Report created successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				createReportFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const editTestStatusForPatientsInQueue =
	(
		userIdx,
		userId,
		testName,
		deptId,
		doctorId,
		prescriptionId,
		status,
		testIdx
	) =>
	async (dispatch) => {
		try {
			dispatch(editTestStatusForPatientsInQueueStart());
			console.log(status + "line519");
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/editTestStatusForPatientsInQueue`,
				{
					userIdx,
					status,
					userId,
					testName,
					deptId,
					doctorId,
					prescriptionId,
					testIdx,
				},
				config
			);
			console.log(data);
			dispatch(editTestStatusForPatientsInQueueSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editTestStatusForPatientsInQueueFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const editTestNotesForPatientsInQueue =
	(deptId, prescriptionId, notes, testName, userId, userIdx, testIdx) =>
	async (dispatch) => {
		try {
			dispatch(editTestNotesForPatientsInQueueStart());
			// console.log(Notes + "line519");
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/addLabTechnicianNotes`,
				{ deptId, prescriptionId, notes, testName, userId, userIdx, testIdx },
				config
			);
			console.log(data);
			dispatch(editTestNotesForPatientsInQueueSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editTestNotesForPatientsInQueueFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getLabVisitedPatients = (date) => async (dispatch) => {
	try {
		dispatch(getLabVisitedPatientsStart());
		// console.log(Notes + "line519");
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/labDept/getLabVisitedPatients/${date}`,

			config
		);
		console.log(data);

		dispatch(getLabVisitedPatientsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getLabVisitedPatientsFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};
