/** @format */

import React, { useEffect, useState } from "react";
import {
	Form,
	InputGroup,
	Dropdown,
	Table,
	Button,
	Row,
	Col,
	Container,
	Modal,
	ListGroup,
	Card,
} from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useDispatch, useSelector } from "react-redux";
import {
	addService,
	getAllService,
	createIpBill,
	getIpBill,
	saveIpBill,
	getConsultantDoctorFees,
	getLabDepts,
	closeIpBill,
} from "../../redux/adminRedux/adminApiCalls";
import { useLocation } from "react-router-dom";
import { getUserDataById } from "../../redux/prescriptionRedux/prescriptionApiCalls";
import { getInventoryItemsForBilling } from "../../redux/inventoryRedux/inventoryApiCalls";
import { getSpecialities } from "../../redux/userApiCalls";
import { useNavigate } from "react-router-dom";
import { createIpBillReset } from "../../redux/adminRedux/IpBill_Management/createIpBill";
import {
	getIpBillRequest,
	getIpBillReset,
} from "../../redux/adminRedux/IpBill_Management/getIpBill";
import { saveIpBillReset } from "../../redux/adminRedux/IpBill_Management/saveIpBill";
import { closeIpBillReset } from "../../redux/adminRedux/IpBill_Management/closeIpBill";

function StartIpBillScreen() {
	const navigate = useNavigate();
	const location = useLocation();
	const params = new URLSearchParams(location.search);

	const { adminInfo } = useSelector((state) => state.adminLogin);

	const getCurrentDate = () => {
		const date = new Date();
		return date.toISOString().split("T")[0]; // YYYY-MM-DD
	};

	const currentDate = getCurrentDate();
	const dispatch = useDispatch();
	const [query, setQuery] = useState("");
	const [docquery, setDocQuery] = useState("");
	const [docsuggestions, setDocSuggestions] = useState([]);
	const [suggestions, setSuggestions] = useState([]);
	const [showAddForm, setShowAddForm] = useState(false);
	const [newPrice, setNewPrice] = useState("");
	const [professionalServices, setProfessionalServices] = useState([]);
	const [doctorServices, setDoctorServices] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
	const [activeDocSuggestionIndex, setActiveDocSuggestionIndex] = useState(-1);

	const [previousPayments, setPreviousPayments] = useState([]);

	const [cashPayment, setCashPayment] = useState("");
	const [onlinePayment, setOnlinePayment] = useState("");
	const [discount, setDiscount] = useState("");
	const [showPaymentForm, setShowPaymentForm] = useState(false);
	const [roomHistory, setRoomHistory] = useState([]);
	const [roomNumber, setRoomNumber] = useState("");
	const [bedNumber, setBedNumber] = useState("");
	const [totalAmount, setTotalAmount] = useState("");
	const [namesWithPrices, setNamesWithPrices] = useState("");
	const [getIpBillDetails, setGetIpBillDetails] = useState([]);
	const [admissionDate, setadmissionDate] = useState(getCurrentDate());
	const [roomsLength, setroomsLength] = useState("");
	// Static user details (You can replace these with actual user data)
	const [username, setusername] = useState("");
	const [phoneNumber, setphoneNumber] = useState("");
	const [ipBillId, setipBillId] = useState("");
	const [userIdx, setUserIdx] = useState(0);
	const [userId, setUserId] = useState("");
	const [userOption, setUserOption] = useState("");
	const [pocName, setPocName] = useState("");
	const [pocContact, setPocContact] = useState("");
	const [chiefConsultant, setChiefConsultant] = useState("");

	const [specialityOption, setSpecialityOption] = useState("Choose Speciality");
	const [specialityId, setSpecialityId] = useState("");
	const [DoctorFromSpecialityArray, setDoctorFromSpecialityArray] = useState(
		[]
	);

	const { getSpecialityAll } = useSelector((state) => state.getSpeciality);
	let allSpecialities = [];
	if (getSpecialityAll) {
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}

	useEffect(() => {
		dispatch(getSpecialities());
	}, []);
	const handleSpecialitySelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		setSpecialityOption(arr[0]);
		setSpecialityId(arr[1]);
		setDoctorOption("Choose Doctor");

		setDoctorFromSpecialityArray(
			getSpecialityAll.data.data[arr[2]].doctorsArray
		);
	};

	let allDoctors = [];
	const [doctorOption, setDoctorOption] = useState("Choose Doctor");
	const [doctorId, setDoctorId] = useState("");
	const handleDoctorSelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		if (specialityOption === "Choose Speciality") {
			alert("Speciality needs to be selected first, Please select Speciality");
		} else {
			setDoctorId(arr[1]);
			setDoctorOption(arr[0]);
			console.log(arr);
			console.log(allDoctors);
		}
	};

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const IpPatientDataaItemsParam = params.get("IpPatientData");
		if (IpPatientDataaItemsParam) {
			try {
				// Parse and decode the historyItems from the query parameter
				const parsedIPPatientdataItems = JSON.parse(
					decodeURIComponent(IpPatientDataaItemsParam)
				);
				console.log(parsedIPPatientdataItems);
				// setBillId(parsedBillItems);

				setUserOption(parsedIPPatientdataItems.username);
				setUserIdx(parsedIPPatientdataItems.userIdx);
				setUserId(parsedIPPatientdataItems.userId);
				setipBillId(parsedIPPatientdataItems.billId);
				setPocName(parsedIPPatientdataItems.pocName);
				setPocContact(parsedIPPatientdataItems.pocMno);
				dispatch(getIpBillRequest());
				if (parsedIPPatientdataItems.userId !== "") {
					dispatch(
						getUserDataById(
							parsedIPPatientdataItems.userId,
							parsedIPPatientdataItems.username,
							Number(parsedIPPatientdataItems.userIdx)
						)
					);
				}
				if (parsedIPPatientdataItems.billId !== "")
					dispatch(getIpBill(parsedIPPatientdataItems.billId));
			} catch (e) {
				console.error("Failed to parse history items", e);
			}
		}
	}, []);

	const getAllServiceDetails = useSelector((state) => state.getServices);
	const getAllDoctorFeesDetails = useSelector(
		(state) => state.getConsultantDoctorFees
	);
	console.log(getAllDoctorFeesDetails);
	useEffect(() => {
		const transformedDoctorServices =
			getAllDoctorFeesDetails?.getConsultantDoctor?.data?.data
				.map((doctor) => {
					return [
						{
							serviceName: `${doctor.name} (Consultation Fees)`,
							serviceType: "Consultation Fees",
							doctorName: doctor.name,
							doctorId: doctor._id,
							specialityId: doctor.specialityId,
							speciality: doctor.speciality,
							price: doctor.ipConsultationFee,
							quantity: 1,
							date: currentDate,
						},
						{
							serviceName: `${doctor.name} (Surgery Fees)`,
							serviceType: "Surgery Fees",
							doctorName: doctor.name,
							specialityId: doctor.specialityId,
							speciality: doctor.speciality,
							doctorId: doctor._id,
							price: doctor.ipSurgeryFees,
							quantity: 1,
							date: currentDate,
						},
					];
				})
				.flat(); // Flatten the array to combine all objects into a single array

		// Now set the transformed array to your state
		setDoctorServices(transformedDoctorServices);
	}, [getAllDoctorFeesDetails?.loading]);
	console.log(JSON.stringify(getAllDoctorFeesDetails) + "line53");
	useEffect(() => {
		setNamesWithPrices(getAllServiceDetails?.getAllServices?.data?.data);
	}, [getAllServiceDetails?.loading]);

	useEffect(() => {
		dispatch(getAllService());
		dispatch(getConsultantDoctorFees());
	}, []);
	const getIpDetails = useSelector((state) => state.getIpBill);

	// Helper function to get product details (mocked for demonstration)
	function getProductDetails(productId) {
		return (
			getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
				(product) => product._id === productId
			) || {}
		);
	}
	console.log(getIpDetails?.getIpBill?.data?.data[0]);
	useEffect(() => {
		if (Object.keys(getIpDetails).length !== 0 && ipBillId !== "") {
			if (getIpDetails?.success) {
				console.log(getIpDetails?.getIpBill?.data?.data[0]);
				const temp = getIpDetails?.getIpBill?.data?.data[0];
				setGetIpBillDetails(getIpDetails?.getIpBill?.data?.data[0]);
				setSpecialityOption(temp?.specialityName);
				setSpecialityId(temp?.specialityId);
				setDoctorOption(temp?.doctorName);
				setDoctorId(temp?.doctorId);
				if (temp?.userId)
					dispatch(
						getUserDataById(temp?.userId, temp?.username, Number(temp?.userIdx))
					);
				console.log(JSON.stringify(temp?.payments) + "line12");
				setroomsLength(temp?.admittedRoomHistory?.length);
				console.log(roomsLength + "line75");
				if (temp?.admittedRoomHistory?.length > 0) {
					setusername(temp?.username);
					setphoneNumber(temp?.mobilenumber);
					setRoomNumber(
						temp?.admittedRoomHistory[temp?.admittedRoomHistory?.length - 1]
							?.room
					);
					setBedNumber(
						temp?.admittedRoomHistory[temp?.admittedRoomHistory?.length - 1]
							?.bednumber
					);
					setadmissionDate(temp?.admissionDate);
				}
				setUserId(temp?.userId);
				setUserIdx(temp?.userIdx);

				setSelectedItems(temp?.generalServicesUsed);
				setProfessionalServices(temp?.DoctorServicesUsed);
				const allTests = temp?.labServicesUsed?.flatMap((service) =>
					service.testsToUpdate?.map((test) => ({
						deptId: test.deptId || "",
						deptName: test.departmentName || "",
						testName: test.testName || "",
						testPrice: test.paidAmount || "", // assuming paidAmount is the test price
						testIndex: test.testIdx || "",
						description: test.description || "", // description not present in the data
						presentInHospital: "1",
						addedNew: false,
					}))
				);

				const transformedProducts = temp?.InventoryServicesUsed?.map(
					(inventory) =>
						inventory?.products?.map((product) => {
							// Fetch product details from another data source if available
							// Assuming `getProductDetails` is a function that fetches product details by productId
							const productDetails = getProductDetails(product.productId);

							return {
								productName: productDetails?.name || "", // Replace with actual product name
								mrp: product?.totalSaleAmount / product?.quantity || "", // Replace with actual MRP
								quantity: product.quantity || "", // Use quantity from the product
								addedNew: false, // Default value
							};
						}) || [] // Default to empty array if no products are available
				).flat();
				setValues(transformedProducts);
				setLabTestsRows(allTests);
				// check for temp?.professional services used
				setPreviousPayments(temp?.payments);
				// settotalServiceCost(
				//   services?.reduce(
				//     (total, service) => total + service?.price * service?.quantity,
				//     0
				//   )
				// );
				// settotalPayments(
				//   payments?.reduce((total, payment) => total + payment?.amountPaid, 0)
				// );
				console.log(selectedItems);
				console.log(previousPayments);
				// console.log(payments);
				// console.log(totalServiceCost);
				// console.log(totalPayments);
			}
		}
	}, [getIpDetails?.loading]);

	const handleInputChange = (e) => {
		const value = e.target.value;
		setQuery(value);
		setActiveSuggestionIndex(-1);
		console.log(getAllServiceDetails?.getAllServices?.data?.data + "line65");
		console.log();
		if (value) {
			const filteredSuggestions = namesWithPrices.filter((item) =>
				item.name?.toLowerCase().includes(value?.toLowerCase())
			);
			setSuggestions(filteredSuggestions);
			setShowAddForm(filteredSuggestions.length === 0 && value !== "");
		} else {
			setSuggestions([]);
			setShowAddForm(false);
		}
	};
	const handleDocInputChange = (e) => {
		const value = e.target.value;
		setDocQuery(value);
		setActiveDocSuggestionIndex(-1);

		if (value) {
			const filteredSuggestions = doctorServices?.filter((service) =>
				service.serviceName?.toLowerCase().includes(value?.toLowerCase())
			);
			setDocSuggestions(filteredSuggestions);
			setShowAddForm(filteredSuggestions?.length === 0 && value !== "");
		} else {
			setDocSuggestions([]);
			setShowAddForm(false);
		}
	};

	const handleSuggestionClick = (suggestion) => {
		setQuery("");
		setSuggestions([]);
		setShowAddForm(false);
		setSelectedItems([
			...selectedItems,
			{
				serviceName: suggestion.name,
				price: Number(suggestion.price),
				quantity: 1,
				date: currentDate,
			},
		]);
	};
	const handleDoctorSuggestionClick = (suggestion) => {
		setDocQuery("");
		setDocSuggestions([]);
		setShowAddForm(false);

		setProfessionalServices((prevServices) => [
			...prevServices,
			{
				serviceName: suggestion.serviceName,
				serviceType: suggestion.serviceType,
				doctorName: suggestion.doctorName,
				price: Number(suggestion.price),
				quantity: 1,
				date: currentDate,
				doctorId: suggestion.doctorId,
				specialityId: suggestion.specialityId[0],
				speciality: suggestion.speciality[0],
				specialities: {
					id: suggestion.specialityId,
					name: suggestion.speciality,
				},
			},
		]);
	};

	const addServiceLoading = useSelector((state) => state.addService);

	useEffect(() => {
		dispatch(getAllService());
	}, [addServiceLoading?.loading]);

	const handleAddItem = async () => {
		if (query && newPrice) {
			try {
				const newItem = { serviceName: query, price: parseFloat(newPrice) };

				// Simulate API call to add item
				dispatch(addService(query, parseFloat(newPrice)));

				setNamesWithPrices([...namesWithPrices, newItem]);
				setSelectedItems([
					...selectedItems,
					{
						serviceName: newItem.serviceName,
						price: Number(newItem.price),
						quantity: 1,
						date: currentDate,
					},
				]);
				setQuery("");
				setNewPrice("");
				setSuggestions([]);
				setShowAddForm(false);
				setTotalAmount(calculateSubtotal("generalServices"));
			} catch (error) {
				console.error("Error adding item:", error);
				alert("Failed to add item.");
			}
		}
	};

	const handleAddDocItem = async () => {
		if (docquery && newPrice) {
			try {
				const newItem = { serviceName: query, price: parseFloat(newPrice) };

				// Simulate API call to add item
				dispatch(addService(query, parseFloat(newPrice)));

				setNamesWithPrices([...namesWithPrices, newItem]);
				setProfessionalServices([
					...professionalServices,
					{
						serviceName: newItem.serviceName,
						serviceType: newItem.serviceName?.includes("Consultation Fees")
							? "Consultation Fees"
							: "Surgery Fees",
						price: Number(newItem.price),
						quantity: 1,
						date: currentDate,
					},
				]);
				setDocQuery("");
				setNewPrice("");
				setSuggestions([]);
				setShowAddForm(false);
				setTotalAmount(calculateSubtotal("professionalServices"));
				alert("Item added successfully!");
			} catch (error) {
				console.error("Error adding item:", error);
				alert("Failed to add item.");
			}
		}
	};

	const handleTableInputChange = (index, value, field, serviceType) => {
		if (serviceType === "generalServices") {
			setSelectedItems((prevItems) =>
				prevItems.map((item, i) =>
					i === index ? { ...item, [field]: value } : item
				)
			);
		} else if (serviceType === "professionalServices") {
			setProfessionalServices((prevServices) =>
				prevServices.map((service, i) =>
					i === index ? { ...service, [field]: value } : service
				)
			);
		}
	};

	const handleRemoveItem = (index) => {
		const updatedItems = [...selectedItems];
		updatedItems.splice(index, 1);
		setSelectedItems(updatedItems);
	};

	const calculateSubtotal = (type) => {
		let total = 0;

		if (type === "generalServices") {
			total = selectedItems?.reduce(
				(acc, item) =>
					acc + (Number(item.price) || 0) * (Number(item.quantity) || 0),
				0
			);
		} else if (type === "professionalServices") {
			total = professionalServices?.reduce(
				(acc, item) => acc + (Number(item.price) || 0) * 1,
				0
			);
		} else if (type === "InventoryServices") {
			total = values?.reduce((acc, product) => {
				return (
					acc + (Number(product?.mrp) || 0) * (Number(product?.quantity) || 0)
				);
			}, 0);
		} else if (type === "LabServices") {
			total = LabTestsRows?.reduce((acc, test) => {
				return acc + (Number(test?.testPrice) || 0);
			}, 0);
		}

		// Check if total is NaN, and return 0 in such cases
		return isNaN(total) ? 0 : total;
	};

	const handleKeyDown = (type, e) => {
		if (type === "generalServices") {
			if (e.key === "ArrowDown") {
				if (activeSuggestionIndex < suggestions.length - 1) {
					setActiveSuggestionIndex(activeSuggestionIndex + 1);
				}
			} else if (e.key === "ArrowUp") {
				if (activeSuggestionIndex > 0) {
					setActiveSuggestionIndex(activeSuggestionIndex - 1);
				}
			} else if (e.key === "Enter" && activeSuggestionIndex >= 0) {
				handleSuggestionClick(suggestions[activeSuggestionIndex]);
				e.preventDefault();
			}
		} else {
			if (e.key === "ArrowDown") {
				if (activeSuggestionIndex < suggestions.length - 1) {
					setActiveDocSuggestionIndex(activeSuggestionIndex + 1);
				}
			} else if (e.key === "ArrowUp") {
				if (activeSuggestionIndex > 0) {
					setActiveDocSuggestionIndex(activeSuggestionIndex - 1);
				}
			} else if (e.key === "Enter" && activeSuggestionIndex >= 0) {
				handleDoctorSuggestionClick(suggestions[activeDocSuggestionIndex]);
				e.preventDefault();
			}
		}
	};

	//Inventory Code

	useEffect(() => {
		dispatch(getInventoryItemsForBilling());
	}, []);

	const getInventoryItemsForbilling = useSelector(
		(state) => state.getInventoryItemsForBilling
	);

	console.log(getInventoryItemsForbilling);

	const [values, setValues] = useState([
		{
			productName: "",
			mrp: "",
			quantity: "",
			addedNew: true,
		},
	]);

	const handleDeleteRow = (index) => {
		let updatedData = [...values];
		if (index > -1) {
			updatedData?.splice(index, 1);
		}
		setValues(updatedData);
	};

	const validateProductName = (index) => {
		if (rowFilteredProductStock[index]?.length === 0) {
			alert("Entered product is not available");
		}
	};

	const handleChange = (index, name, value) => {
		let updatedData = [...values];
		if (name === "productName") {
			const productArray =
				getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
					(product) => {
						return product?.name?.toLowerCase() === value?.toLowerCase();
					}
				);

			updatedData[index] = {
				...updatedData[index],
				[name]: productArray?.name,
				sellingPrice: productArray?.selling_prices[0]?.selling_price,
				hsn: productArray?.hsn_code,
			};
		} else updatedData[index][name] = value;
		setValues(updatedData);
	};

	const handleAddRow = () => {
		const newRow = {
			productName: "",
			mrp: "",
			quantity: "",
			addedNew: true,
		};
		setValues([...values, newRow]);
	};

	const [rowProductSearch, setRowProductSearch] = useState({});
	const [rowFilteredProductStock, setRowFilteredProductStock] = useState({});

	const handleProductSearchChange = (index, e) => {
		const value = e.target.value;
		setRowProductSearch((prev) => ({ ...prev, [index]: value }));
	};

	useEffect(() => {
		const updatedFilteredProductStock = {};
		Object.keys(rowProductSearch).forEach((index) => {
			const value = rowProductSearch[index];
			updatedFilteredProductStock[index] =
				getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data.filter(
					(item) => item.name.toLowerCase().includes(value.toLowerCase())
				);
		});
		setRowFilteredProductStock(updatedFilteredProductStock);
	}, [rowProductSearch]);

	const handleSelectProduct = (index, product) => {
		const updatedData = [...values];
		updatedData[index] = {
			...updatedData[index],
			productName: product.name,
		};
		setValues(updatedData);
		setRowProductSearch((prev) => ({ ...prev, [index]: "" }));
		setRowFilteredProductStock((prev) => ({ ...prev, [index]: [] }));
	};

	//Lab Tests Code
	useEffect(() => {
		dispatch(getLabDepts());
	}, []);
	const getlabDeptAll = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	const [LabTestsRows, setLabTestsRows] = useState([
		{
			deptId: "",
			deptName: "",
			testName: "Choose Test",
			testPrice: "",
			testIndex: "",
			description: "",
			presentInHospital: "1",
			addedNew: true,
		},
	]);
	const [LabDeptTests, setLabDeptTests] = useState([]);

	useEffect(() => {
		const testDetails = getlabDeptAll?.getlabDeptAll?.data?.data
			.filter((item) => item.deptName !== "hospital") // Filter out 'hospital' dept
			.flatMap((item) =>
				item.testDetails.map((test, index) => ({
					deptId: item._id, // deptId
					deptName: item.deptName, // deptName
					testName: test.testName, // testName
					testPrice: test.testPrice, // testPrice
					testIndex: index, // Index of the test in this department
				}))
			);

		setLabDeptTests(testDetails);
	}, [getlabDeptAll?.loading]);

	const editLabTestRow = (index, field, value) => {
		setLabTestsRows((prevRows) =>
			prevRows.map((row, rowIndex) =>
				rowIndex === index ? { ...row, [field]: value } : row
			)
		);
	};

	const AddLabTestRow = (index) => {
		const newLabTestRow = {
			deptId: "",
			deptName: "",
			testName: "Choose Test",
			testPrice: "",
			testIndex: "",
			description: "",
			presentInHospital: "1",
			addedNew: true,
		};
		setLabTestsRows([...LabTestsRows, newLabTestRow]);
	};

	const DeleteLabTestRow = (index) => {
		const LabTestsData = LabTestsRows.filter((_, i) => i !== index);

		setLabTestsRows(LabTestsData);
	};

	const handlePayment = () => {
		const cash = parseFloat(cashPayment) || 0;
		const online = parseFloat(onlinePayment) || 0;
		const discountAmount = parseFloat(discount) || 0;
		console.log(totalAmount + "line185");
		// if (cash + online + discountAmount > totalAmount) {
		//   alert("Payment cannot exceed the total amount.");
		//   return;
		// }

		const newPayment = {
			paidDate: getCurrentDate(),
			amountPaid: cash + online,
			amountPaidCash: cash,
			amountPaidOnline: online,
			discountAmount: discountAmount,
		};

		setPreviousPayments([...previousPayments, newPayment]);
		setCashPayment("");
		setOnlinePayment("");
		setDiscount("");
		setShowPaymentForm(false);
	};

	const { userdata } = useSelector((state) => state.getUserDataById);
	console.log(userdata);

	const handleSaveBill = () => {
		// Implement saving bill logic here
		console.log(JSON.stringify(previousPayments) + "line 202");
		console.log(JSON.stringify(selectedItems) + "line 206");
		let totamt =
			Number(calculateSubtotal("LabServices")?.toFixed(2)) +
			Number(calculateSubtotal("InventoryServices")?.toFixed(2)) +
			Number(calculateSubtotal("professionalServices")?.toFixed(2)) +
			Number(calculateSubtotal("generalServices")?.toFixed(2));
		setTotalAmount(totamt);
		const sum = previousPayments
			?.reduce((total, payment) => total + payment?.amountPaid, 0)
			.toFixed(2);
		const amountToBePaid = totamt - sum;

		let inventoryItemsTaken = values
			?.map((product) => {
				if (values?.length > 0 && product?.addedNew) {
					// Find the product in the database
					let productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(x) => x.name === product?.productName
						);

					if (productArrayFromDb) {
						// Find the selling price for the product
						let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
							(price) => price?.selling_price === Number(product?.mrp)
						);

						if (sellingPricesItem) {
							// Update the selling prices array
							let updatedSellingPrices = productArrayFromDb.selling_prices.map(
								(price) =>
									price === sellingPricesItem
										? {
												...price,
												quantityLeft:
													price.quantityLeft - Number(product?.quantity),
										  }
										: price
							);

							// Get the index of the updated selling price item
							const sellingPriceIndex =
								productArrayFromDb.selling_prices.indexOf(sellingPricesItem);

							return {
								productId: productArrayFromDb._id,
								selling_prices: updatedSellingPrices,
								Totalquantity:
									updatedSellingPrices[sellingPriceIndex]?.quantityLeft,
								status:
									updatedSellingPrices[sellingPriceIndex]?.quantityLeft > 0
										? "in-stock"
										: "out-of-stock",
							};
						}
					}
				}
			})
			.filter((item) => item !== undefined); // Filter out undefined values

		const labTests = LabTestsRows?.map((test) => {
			if (test?.addedNew) {
				return {
					deptId: test?.deptId || "",
					deptName: test?.deptName || "",
					description: test?.description || "",
					testName: test?.testName || "",
					testIdx: Number(test?.testIndex) || 0,
					paidAmount: Number(test?.testPrice) || 0,
				};
			}
			// Return undefined if addedNew is false
		}).filter((test) => test !== undefined); // Filter out undefined values

		console.log(labTests);

		let testsToUpdate = [];
		LabTestsRows?.map((test, index) => {
			if (ipBillId === "") {
				testsToUpdate?.push({
					deptId: test?.deptId,
					departmentName: test?.deptName,
					description: test?.description,
					paidAmount: Number(test?.testPrice),
					testName: test?.testName,
					testIdx: Number(test?.testIndex),
				});
			} else {
				if (!test?.addedNew) {
					testsToUpdate?.push({
						deptId: test?.deptId,
						departmentName: test?.deptName,
						description: test?.description,
						paidAmount: Number(test?.testPrice),
						testName: test?.testName,
						testIdx: Number(test?.testIndex),
					});
				}
			}
		});

		let labServicesUsed = [
			{
				date: getCurrentDate(),
				testsToUpdate: testsToUpdate,
			},
		];

		let productsArray = [];
		let purchasebasedoncategoryArray = [];

		values?.map((product, index) => {
			let productArrayFromDb =
				getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
					(x) => x.name === product?.productName
				);

			if (productArrayFromDb) {
				let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
					(price) => price?.selling_price === Number(product?.mrp)
				);

				const sellingPriceIndex =
					productArrayFromDb?.selling_prices?.indexOf(sellingPricesItem);

				if (sellingPricesItem && sellingPriceIndex !== -1) {
					var updatedSellingPrices = productArrayFromDb.selling_prices.map(
						(price) =>
							price === sellingPricesItem
								? {
										...price,
										quantityLeft:
											price.quantityLeft - Number(product?.quantity),
								  }
								: price
					);

					const mrp = Number(product?.mrp);
					const quantity = Number(product?.quantity);
					const costPrice = Number(
						updatedSellingPrices[sellingPriceIndex]?.cost_price
					);
					if (ipBillId === "") {
						productsArray.push({
							quantity: quantity,
							totalSaleAmount: quantity * mrp,
							totalProfitAmount: (mrp - costPrice) * quantity,
							vendorId: updatedSellingPrices[sellingPriceIndex]?.supplierId,
							productId: productArrayFromDb._id,
						});

						purchasebasedoncategoryArray.push({
							categoryId: productArrayFromDb?.categoryId,
							amount: quantity * mrp,
							amountPaidInCash: 0,
							amountPaidInOnline: quantity * mrp,
							profit: (mrp - costPrice) * quantity,
							discount: 0,
						});
					} else {
						if (!product?.addedNew) {
							productsArray.push({
								quantity: quantity,
								totalSaleAmount: quantity * mrp,
								totalProfitAmount: (mrp - costPrice) * quantity,
								vendorId: updatedSellingPrices[sellingPriceIndex]?.supplierId,
								productId: productArrayFromDb._id,
							});

							purchasebasedoncategoryArray.push({
								categoryId: productArrayFromDb?.categoryId,
								amount: quantity * mrp,
								amountPaidInCash: 0,
								amountPaidInOnline: quantity * mrp,
								profit: (mrp - costPrice) * quantity,
								discount: 0,
							});
						}
					}
				}
			}
		});

		let InventoryServicesUsed = [
			{
				date: getCurrentDate(),
				products: productsArray,
				purchaseBasedonCategory: purchasebasedoncategoryArray,
			},
		];

		const stock_expiring_in_one_week =
			adminInfo?.user?.stock_expiring_in_one_week?.map((adminProduct) => {
				// Find the matching product from values
				const matchingProduct = inventoryItemsTaken?.find((product) => {
					let productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(x) => x._id === product?.productId
						);

					let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
						(price) => price?.selling_price === Number(product?.mrp)
					);

					// Match admin product with values and selling price details
					return (
						adminProduct.name === productArrayFromDb?.name &&
						adminProduct.supplierId === sellingPricesItem?.supplierId &&
						adminProduct.batchNo === sellingPricesItem?.batchNo
					);
				});

				// If a matching product is found, update the quantityLeft by adding 3
				if (matchingProduct) {
					return {
						...adminProduct, // Keep all existing properties
						quantityLeft:
							adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
					};
				}

				// Return the product as is if no match
				return adminProduct;
			});

		const stock_expiring_in_one_month =
			adminInfo?.user?.stock_expiring_in_one_month?.map((adminProduct) => {
				// Find the matching product from values
				const matchingProduct = inventoryItemsTaken?.find((product) => {
					let productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(x) => x._id === product?.productId
						);

					let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
						(price) => price?.selling_price === Number(product?.mrp)
					);

					// Match admin product with values and selling price details
					return (
						adminProduct.name === productArrayFromDb?.name &&
						adminProduct.supplierId === sellingPricesItem?.supplierId &&
						adminProduct.batchNo === sellingPricesItem?.batchNo
					);
				});

				// If a matching product is found, update the quantityLeft by adding 3
				if (matchingProduct) {
					return {
						...adminProduct, // Keep all existing properties
						quantityLeft:
							adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
					};
				}

				// Return the product as is if no match
				return adminProduct;
			});

		const stock_expiring_in_three_months =
			adminInfo?.user?.stock_expiring_in_three_months?.map((adminProduct) => {
				// Find the matching product from values
				const matchingProduct = inventoryItemsTaken?.find((product) => {
					let productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(x) => x._id === product?.productId
						);
					let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
						(price) => price?.selling_price === Number(product?.mrp)
					);

					// Match admin product with values and selling price details
					return (
						adminProduct.name === productArrayFromDb?.name &&
						adminProduct.supplierId === sellingPricesItem?.supplierId &&
						adminProduct.batchNo === sellingPricesItem?.batchNo
					);
				});

				// If a matching product is found, update the quantityLeft by adding 3
				if (matchingProduct) {
					return {
						...adminProduct, // Keep all existing properties
						quantityLeft:
							adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
					};
				}

				// Return the product as is if no match
				return adminProduct;
			});

		const stock_expiring_in_six_months =
			adminInfo?.user?.stock_expiring_in_six_months?.map((adminProduct) => {
				// Find the matching product from values
				const matchingProduct = inventoryItemsTaken?.find((product) => {
					let productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(x) => x._id === product?.productId
						);

					let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
						(price) => price?.selling_price === Number(product?.mrp)
					);

					// Match admin product with values and selling price details
					return (
						adminProduct.name === productArrayFromDb?.name &&
						adminProduct.supplierId === sellingPricesItem?.supplierId &&
						adminProduct.batchNo === sellingPricesItem?.batchNo
					);
				});

				// If a matching product is found, update the quantityLeft by adding 3
				if (matchingProduct) {
					return {
						...adminProduct, // Keep all existing properties
						quantityLeft:
							adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
					};
				}

				// Return the product as is if no match
				return adminProduct;
			});

		if (ipBillId === "") {
			const newRoom = {
				room: roomNumber,
				bednumber: bedNumber,
				admissionDate: getCurrentDate(),
			};
			console.log(newRoom);
			setRoomHistory([...roomHistory, newRoom]);

			dispatch(
				createIpBill(
					userdata?.data?.data?.name,
					Number(userIdx),
					userdata?.data?.data?.mobilenumber,
					userId,
					admissionDate,
					selectedItems,
					professionalServices,
					[newRoom],
					amountToBePaid,
					previousPayments,
					inventoryItemsTaken?.length === 1 &&
						inventoryItemsTaken[0] === undefined
						? []
						: inventoryItemsTaken,
					labTests?.length === 1 && labTests[0]?.deptId === "" ? [] : labTests,
					doctorOption,
					doctorId,
					pocName,
					pocContact,
					specialityOption,
					specialityId,
					LabTestsRows.length === 1 && LabTestsRows[0]?.deptId === ""
						? []
						: labServicesUsed,
					values.length === 1 && values[0]?.productName === ""
						? []
						: InventoryServicesUsed,
					stock_expiring_in_one_week,
					stock_expiring_in_one_month,
					stock_expiring_in_three_months,
					stock_expiring_in_six_months
				)
			);
		} else {
			console.log(getIpBillDetails.admittedRoomHistory, roomsLength);
			let rooms = [...roomHistory];
			if (
				getIpBillDetails.admittedRoomHistory[roomsLength - 1]?.room !==
					roomNumber ||
				getIpBillDetails.admittedRoomHistory[roomsLength - 1]?.bednumber !==
					bedNumber
			) {
				const newRoom = {
					room: roomNumber,
					bednumber: bedNumber,
					admissionDate: getCurrentDate(),
				};
				console.log(newRoom);

				rooms?.push(newRoom);
				setRoomHistory([...roomHistory, newRoom]);
			}
			console.log(roomHistory);

			let testsToUpdate1 = [];

			LabTestsRows?.map((test, index) => {
				if (test?.addedNew) {
					testsToUpdate1?.push({
						deptId: test?.deptId,
						departmentName: test?.deptName,
						description: test?.description,
						paidAmount: Number(test?.testPrice),
						testName: test?.testName,
						testIdx: Number(test?.testIndex),
					});
				}
			});

			labServicesUsed.push({
				date: getCurrentDate(),
				testsToUpdate: testsToUpdate1,
			});

			let productsArray1 = [];
			let purchasebasedoncategoryArray1 = [];

			values?.map((product, index) => {
				if (product?.addedNew) {
					let productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(x) => x.name === product?.productName
						);

					if (productArrayFromDb) {
						let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
							(price) => price?.selling_price === Number(product?.mrp)
						);

						const sellingPriceIndex =
							productArrayFromDb?.selling_prices?.indexOf(sellingPricesItem);

						if (sellingPricesItem && sellingPriceIndex !== -1) {
							var updatedSellingPrices = productArrayFromDb.selling_prices.map(
								(price) =>
									price === sellingPricesItem
										? {
												...price,
												quantityLeft:
													price.quantityLeft - Number(product?.quantity),
										  }
										: price
							);

							const mrp = Number(product?.mrp);
							const quantity = Number(product?.quantity);
							const costPrice = Number(
								updatedSellingPrices[sellingPriceIndex]?.cost_price
							);

							productsArray1.push({
								quantity: quantity,
								totalSaleAmount: quantity * mrp,
								totalProfitAmount: (mrp - costPrice) * quantity,
								vendorId: updatedSellingPrices[sellingPriceIndex]?.supplierId,
								productId: productArrayFromDb._id,
							});

							purchasebasedoncategoryArray1.push({
								categoryId: productArrayFromDb?.categoryId,
								amount: quantity * mrp,
								amountPaidInCash: 0,
								amountPaidInOnline: quantity * mrp,
								profit: (mrp - costPrice) * quantity,
								discount: 0,
							});
						}
					}
				}
			});

			InventoryServicesUsed?.push({
				date: getCurrentDate(),
				products: productsArray1,
				purchaseBasedonCategory: purchasebasedoncategoryArray1,
			});
			labServicesUsed = labServicesUsed?.filter(
				(item) => item?.testsToUpdate?.length > 0
			);
			InventoryServicesUsed = InventoryServicesUsed?.filter(
				(item) =>
					item?.products?.length > 0 &&
					item?.purchaseBasedonCategory?.length > 0
			);
			dispatch(
				saveIpBill(
					ipBillId,
					Number(userIdx),
					userId,
					userdata?.data?.data?.mobilenumber,
					userdata?.data?.data?.name,
					selectedItems,
					professionalServices,
					rooms,
					amountToBePaid,
					previousPayments,
					inventoryItemsTaken?.length === 1 &&
						inventoryItemsTaken[0] === undefined
						? []
						: inventoryItemsTaken,
					stock_expiring_in_one_week,
					stock_expiring_in_one_month,
					stock_expiring_in_three_months,
					stock_expiring_in_six_months,

					labTests,
					doctorOption,
					doctorId,
					LabTestsRows.length === 1 && LabTestsRows[0]?.deptId === ""
						? []
						: labServicesUsed,
					values.length === 1 && values[0]?.productName === ""
						? []
						: InventoryServicesUsed
				)
			);
		}
	};

	const createIpBillDetails = useSelector((state) => state.createIpBill);
	const saveIpBillDetails = useSelector((state) => state.saveIpBill);
	const closeIpBillDetails = useSelector((state) => state.closeIpBill);

	useEffect(() => {
		if (
			createIpBillDetails?.success ||
			saveIpBillDetails?.success ||
			closeIpBillDetails?.success
		) {
			dispatch(createIpBillReset());
			dispatch(saveIpBillReset());
			dispatch(closeIpBillReset());
			navigate("/admittedPatients");
		}
	}, [
		createIpBillDetails?.loading,
		saveIpBillDetails?.loading,
		closeIpBillDetails?.loading,
	]);

	const handleClosePaymentModal = () => {
		setShowPaymentForm(false);
	};

	const [showFinalPaymentForm, setShowFinalPaymentForm] = useState(false);
	const [finalCashPayment, setFinalCashPayment] = useState(0);
	const [finalOnlinePayment, setFinalOnlinePayment] = useState(0);
	const [finalDiscount, setFinalDiscount] = useState(0);

	const handleFinalClosePaymentModal = () => {
		setShowFinalPaymentForm(false);
	};

	const handleValidationsBeforeClosing = () => {
		if (roomNumber === "" || bedNumber === "") {
			alert("Enter room and bed details");
		} else {
			setShowFinalPaymentForm(true);
			setFinalCashPayment(0);
			setFinalOnlinePayment(0);
			setFinalDiscount(0);
		}
	};

	const handleAmounts = (amount, type) => {
		let totamt =
			Number(calculateSubtotal("LabServices")?.toFixed(2)) +
			Number(calculateSubtotal("InventoryServices")?.toFixed(2)) +
			Number(calculateSubtotal("professionalServices")?.toFixed(2)) +
			Number(calculateSubtotal("generalServices")?.toFixed(2));
		setTotalAmount(totamt);
		const sum = previousPayments
			?.reduce((total, payment) => total + payment?.amountPaid, 0)
			.toFixed(2);
		const amountToBePaid = totamt - sum;
		if (amount > amountToBePaid) {
			alert(`Amount cannot exceed ${amountToBePaid}`);
		} else {
			if (type === "cash") {
				setFinalOnlinePayment(0);
				setFinalCashPayment(Number(amount));
				setFinalOnlinePayment(Number(amountToBePaid) - Number(amount));
			} else {
				setFinalCashPayment(0);
				setFinalOnlinePayment(Number(amount));
				setFinalCashPayment(Number(amountToBePaid) - Number(amount));
			}
		}
	};

	const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState(0);

	const handleFinalDiscount = (value) => {
		setFinalDiscount(value);
		let totamt =
			Number(calculateSubtotal("LabServices")?.toFixed(2)) +
			Number(calculateSubtotal("InventoryServices")?.toFixed(2)) +
			Number(calculateSubtotal("professionalServices")?.toFixed(2)) +
			Number(calculateSubtotal("generalServices")?.toFixed(2));
		setTotalAmount(totamt);
		const sum = previousPayments
			?.reduce((total, payment) => total + payment?.amountPaid, 0)
			.toFixed(2);
		const amountToBePaid = totamt - sum;
		let totamtafterdiscount =
			amountToBePaid - (amountToBePaid * Number(value) * 0.01).toFixed(2);
		setTotalAmountAfterDiscount(totamtafterdiscount);
	};

	const handleCloseBill = () => {
		let totamt =
			Number(calculateSubtotal("LabServices")?.toFixed(2)) +
			Number(calculateSubtotal("InventoryServices")?.toFixed(2)) +
			Number(calculateSubtotal("professionalServices")?.toFixed(2)) +
			Number(calculateSubtotal("generalServices")?.toFixed(2));
		setTotalAmount(totamt);
		const sum = previousPayments
			?.reduce((total, payment) => total + payment?.amountPaid, 0)
			.toFixed(2);
		const amountToBePaid = totamt - sum;

		let inventoryItemsTaken = values
			?.map((product) => {
				if (values?.length > 0 && product?.addedNew) {
					// Find the product in the database
					let productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(x) => x.name === product?.productName
						);

					if (productArrayFromDb) {
						// Find the selling price for the product
						let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
							(price) => price?.selling_price === Number(product?.mrp)
						);

						if (sellingPricesItem) {
							// Update the selling prices array
							let updatedSellingPrices = productArrayFromDb.selling_prices.map(
								(price) =>
									price === sellingPricesItem
										? {
												...price,
												quantityLeft:
													price.quantityLeft - Number(product?.quantity),
										  }
										: price
							);

							// Get the index of the updated selling price item
							const sellingPriceIndex =
								productArrayFromDb.selling_prices.indexOf(sellingPricesItem);

							return {
								productId: productArrayFromDb._id,
								selling_prices: updatedSellingPrices,
								Totalquantity:
									updatedSellingPrices[sellingPriceIndex]?.quantityLeft,
								status:
									updatedSellingPrices[sellingPriceIndex]?.quantityLeft > 0
										? "in-stock"
										: "out-of-stock",
							};
						}
					}
				}
			})
			.filter((item) => item !== undefined); // Filter out undefined values

		const labTests = LabTestsRows?.map((test) => {
			if (test?.addedNew) {
				return {
					deptId: test?.deptId || "",
					deptName: test?.deptName || "",
					description: test?.description || "",
					testName: test?.testName || "",
					testIdx: Number(test?.testIndex) || 0,
					paidAmount: Number(test?.testPrice) || 0,
				};
			}
			// Return undefined if addedNew is false
		}).filter((test) => test !== undefined); // Filter out undefined values

		console.log(labTests);

		let testsToUpdate = [];
		LabTestsRows?.map((test, index) => {
			if (ipBillId === "") {
				testsToUpdate?.push({
					deptId: test?.deptId,
					departmentName: test?.deptName,
					description: test?.description,
					paidAmount: Number(test?.testPrice),
					testName: test?.testName,
					testIdx: Number(test?.testIndex),
				});
			} else {
				if (!test?.addedNew) {
					testsToUpdate?.push({
						deptId: test?.deptId,
						departmentName: test?.deptName,
						description: test?.description,
						paidAmount: Number(test?.testPrice),
						testName: test?.testName,
						testIdx: Number(test?.testIndex),
					});
				}
			}
		});

		let labServicesUsed = [
			{
				date: getCurrentDate(),
				testsToUpdate: testsToUpdate,
			},
		];

		let productsArray = [];
		let purchasebasedoncategoryArray = [];

		values?.map((product, index) => {
			let productArrayFromDb =
				getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
					(x) => x.name === product?.productName
				);

			if (productArrayFromDb) {
				let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
					(price) => price?.selling_price === Number(product?.mrp)
				);

				const sellingPriceIndex =
					productArrayFromDb?.selling_prices?.indexOf(sellingPricesItem);

				if (sellingPricesItem && sellingPriceIndex !== -1) {
					var updatedSellingPrices = productArrayFromDb.selling_prices.map(
						(price) =>
							price === sellingPricesItem
								? {
										...price,
										quantityLeft:
											price.quantityLeft - Number(product?.quantity),
								  }
								: price
					);

					const mrp = Number(product?.mrp);
					const quantity = Number(product?.quantity);
					const costPrice = Number(
						updatedSellingPrices[sellingPriceIndex]?.cost_price
					);
					if (ipBillId === "") {
						productsArray.push({
							quantity: quantity,
							totalSaleAmount: quantity * mrp,
							totalProfitAmount: (mrp - costPrice) * quantity,
							vendorId: updatedSellingPrices[sellingPriceIndex]?.supplierId,
							productId: productArrayFromDb._id,
						});

						purchasebasedoncategoryArray.push({
							categoryId: productArrayFromDb?.categoryId,
							amount: quantity * mrp,
							amountPaidInCash: 0,
							amountPaidInOnline: quantity * mrp,
							profit: (mrp - costPrice) * quantity,
							discount: 0,
						});
					} else {
						if (!product?.addedNew) {
							productsArray.push({
								quantity: quantity,
								totalSaleAmount: quantity * mrp,
								totalProfitAmount: (mrp - costPrice) * quantity,
								vendorId: updatedSellingPrices[sellingPriceIndex]?.supplierId,
								productId: productArrayFromDb._id,
							});

							purchasebasedoncategoryArray.push({
								categoryId: productArrayFromDb?.categoryId,
								amount: quantity * mrp,
								amountPaidInCash: 0,
								amountPaidInOnline: quantity * mrp,
								profit: (mrp - costPrice) * quantity,
								discount: 0,
							});
						}
					}
				}
			}
		});

		let InventoryServicesUsed = [
			{
				date: getCurrentDate(),
				products: productsArray,
				purchaseBasedonCategory: purchasebasedoncategoryArray,
			},
		];

		const stock_expiring_in_one_week =
			adminInfo?.user?.stock_expiring_in_one_week?.map((adminProduct) => {
				// Find the matching product from values
				const matchingProduct = inventoryItemsTaken?.find((product) => {
					let productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(x) => x._id === product?.productId
						);

					let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
						(price) => price?.selling_price === Number(product?.mrp)
					);

					// Match admin product with values and selling price details
					return (
						adminProduct.name === productArrayFromDb?.name &&
						adminProduct.supplierId === sellingPricesItem?.supplierId &&
						adminProduct.batchNo === sellingPricesItem?.batchNo
					);
				});

				// If a matching product is found, update the quantityLeft by adding 3
				if (matchingProduct) {
					return {
						...adminProduct, // Keep all existing properties
						quantityLeft:
							adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
					};
				}

				// Return the product as is if no match
				return adminProduct;
			});

		const stock_expiring_in_one_month =
			adminInfo?.user?.stock_expiring_in_one_month?.map((adminProduct) => {
				// Find the matching product from values
				const matchingProduct = inventoryItemsTaken?.find((product) => {
					let productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(x) => x._id === product?.productId
						);

					let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
						(price) => price?.selling_price === Number(product?.mrp)
					);

					// Match admin product with values and selling price details
					return (
						adminProduct.name === productArrayFromDb?.name &&
						adminProduct.supplierId === sellingPricesItem?.supplierId &&
						adminProduct.batchNo === sellingPricesItem?.batchNo
					);
				});

				// If a matching product is found, update the quantityLeft by adding 3
				if (matchingProduct) {
					return {
						...adminProduct, // Keep all existing properties
						quantityLeft:
							adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
					};
				}

				// Return the product as is if no match
				return adminProduct;
			});

		const stock_expiring_in_three_months =
			adminInfo?.user?.stock_expiring_in_three_months?.map((adminProduct) => {
				// Find the matching product from values
				const matchingProduct = inventoryItemsTaken?.find((product) => {
					let productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(x) => x._id === product?.productId
						);
					let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
						(price) => price?.selling_price === Number(product?.mrp)
					);

					// Match admin product with values and selling price details
					return (
						adminProduct.name === productArrayFromDb?.name &&
						adminProduct.supplierId === sellingPricesItem?.supplierId &&
						adminProduct.batchNo === sellingPricesItem?.batchNo
					);
				});

				// If a matching product is found, update the quantityLeft by adding 3
				if (matchingProduct) {
					return {
						...adminProduct, // Keep all existing properties
						quantityLeft:
							adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
					};
				}

				// Return the product as is if no match
				return adminProduct;
			});

		const stock_expiring_in_six_months =
			adminInfo?.user?.stock_expiring_in_six_months?.map((adminProduct) => {
				// Find the matching product from values
				const matchingProduct = inventoryItemsTaken?.find((product) => {
					let productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(x) => x._id === product?.productId
						);

					let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
						(price) => price?.selling_price === Number(product?.mrp)
					);

					// Match admin product with values and selling price details
					return (
						adminProduct.name === productArrayFromDb?.name &&
						adminProduct.supplierId === sellingPricesItem?.supplierId &&
						adminProduct.batchNo === sellingPricesItem?.batchNo
					);
				});

				// If a matching product is found, update the quantityLeft by adding 3
				if (matchingProduct) {
					return {
						...adminProduct, // Keep all existing properties
						quantityLeft:
							adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
					};
				}

				// Return the product as is if no match
				return adminProduct;
			});
		let rooms = [...roomHistory];

		if (
			getIpBillDetails.admittedRoomHistory[roomsLength - 1]?.room !==
				roomNumber ||
			getIpBillDetails.admittedRoomHistory[roomsLength - 1]?.bednumber !==
				bedNumber
		) {
			const newRoom = {
				room: roomNumber,
				bednumber: bedNumber,
				admissionDate: getCurrentDate(),
			};
			console.log(newRoom);

			rooms?.push(newRoom);
			setRoomHistory([...roomHistory, newRoom]);
		}
		console.log(roomHistory);

		let testsToUpdate1 = [];

		LabTestsRows?.map((test, index) => {
			if (test?.addedNew) {
				testsToUpdate1?.push({
					deptId: test?.deptId,
					departmentName: test?.deptName,
					description: test?.description,
					paidAmount: Number(test?.testPrice),
					testName: test?.testName,
					testIdx: Number(test?.testIndex),
				});
			}
		});

		labServicesUsed.push({
			date: getCurrentDate(),
			testsToUpdate: testsToUpdate1,
		});

		let productsArray1 = [];
		let purchasebasedoncategoryArray1 = [];

		values?.map((product, index) => {
			if (product?.addedNew) {
				let productArrayFromDb =
					getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
						(x) => x.name === product?.productName
					);

				if (productArrayFromDb) {
					let sellingPricesItem = productArrayFromDb?.selling_prices?.find(
						(price) => price?.selling_price === Number(product?.mrp)
					);

					const sellingPriceIndex =
						productArrayFromDb?.selling_prices?.indexOf(sellingPricesItem);

					if (sellingPricesItem && sellingPriceIndex !== -1) {
						var updatedSellingPrices = productArrayFromDb.selling_prices.map(
							(price) =>
								price === sellingPricesItem
									? {
											...price,
											quantityLeft:
												price.quantityLeft - Number(product?.quantity),
									  }
									: price
						);

						const mrp = Number(product?.mrp);
						const quantity = Number(product?.quantity);
						const costPrice = Number(
							updatedSellingPrices[sellingPriceIndex]?.cost_price
						);

						productsArray1.push({
							quantity: quantity,
							totalSaleAmount: quantity * mrp,
							totalProfitAmount: (mrp - costPrice) * quantity,
							vendorId: updatedSellingPrices[sellingPriceIndex]?.supplierId,
							productId: productArrayFromDb._id,
						});

						purchasebasedoncategoryArray1.push({
							categoryId: productArrayFromDb?.categoryId,
							amount: quantity * mrp,
							amountPaidInCash: 0,
							amountPaidInOnline: quantity * mrp,
							profit: (mrp - costPrice) * quantity,
							discount: 0,
						});
					}
				}
			}
		});

		InventoryServicesUsed?.push({
			date: getCurrentDate(),
			products: productsArray1,
			purchaseBasedonCategory: purchasebasedoncategoryArray1,
		});
		labServicesUsed = labServicesUsed?.filter(
			(item) => item?.testsToUpdate?.length > 0
		);
		InventoryServicesUsed = InventoryServicesUsed?.filter(
			(item) =>
				item?.products?.length > 0 && item?.purchaseBasedonCategory?.length > 0
		);
		dispatch(
			closeIpBill(
				ipBillId,
				Number(userIdx),
				userId,
				userdata?.data?.data?.mobilenumber,
				userdata?.data?.data?.name,
				selectedItems,
				professionalServices,
				labServicesUsed,
				InventoryServicesUsed,
				rooms,
				amountToBePaid,
				previousPayments,
				inventoryItemsTaken,
				stock_expiring_in_one_week,
				stock_expiring_in_one_month,
				stock_expiring_in_three_months,
				stock_expiring_in_six_months,
				Number(finalCashPayment),
				Number(finalOnlinePayment),
				Number(finalDiscount),
				labTests,
				doctorOption,
				doctorId,
				getCurrentDate()
			)
		);
	};
	return (
		<div className="container mt-4">
			<h1 className="header-center mt-3">In Patient Billing</h1>
			<h4>Patient Details</h4>
			<div className="header-center card container mt-3">
				<Row className="justify-content-center mb-4 mt-4">
					<Col md="3" className="text-center">
						<h6>Patient: {userdata?.data?.data?.name}</h6>
					</Col>
					<Col md="3" className="text-center">
						<h6>Age: {userdata?.data?.data?.age}</h6>
					</Col>
					<Col md="3" className="text-center">
						<h6>Sex: {userdata?.data?.data?.gender}</h6>
					</Col>
					<Col md="3" className="text-center">
						<h6>Mobile Number: {userdata?.data?.data?.mobilenumber}</h6>
					</Col>
				</Row>
				<Form.Group as={Row} className="mb-4">
					<Form.Label column sm="2">
						<b>POC Name:</b>
					</Form.Label>
					<Col sm="4">
						<input
							type="text"
							className="form-control"
							value={pocName}
							onChange={(e) => setPocName(e?.target?.value)}
							placeholder="Enter Name"
						/>
					</Col>
					<Form.Label column sm="2">
						<b>POC Contact:</b>
					</Form.Label>
					<Col sm="4">
						<input
							type="text"
							className="form-control"
							value={pocContact}
							onChange={(e) => setPocContact(e?.target?.value)}
							placeholder="Enter Contact"
						/>
					</Col>
				</Form.Group>
			</div>
			<Container className="header-center card mt-3">
				<Row className="mb-3 mt-3 justify-content-center">
					<Form.Label column sm="2">
						<b>Chief Consultant:</b>
					</Form.Label>
					<Col sm="auto">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={specialityOption}
							onSelect={handleSpecialitySelect}
						>
							{allSpecialities.map(
								(specialities, index) =>
									specialities.specialization !== "referral" &&
									specialities.specialization !== "Consultant Doctor" && (
										<Dropdown.Item
											key={index}
											eventKey={
												specialities.specialization +
												"/" +
												specialities._id +
												"/" +
												index
											}
										>
											{specialities.specialization}
										</Dropdown.Item>
									)
							)}
						</DropdownButton>
					</Col>
					<Col sm="auto">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={doctorOption}
							onSelect={handleDoctorSelect}
						>
							{DoctorFromSpecialityArray.map((doctors, index) => (
								<Dropdown.Item
									key={index}
									eventKey={doctors.name + "/" + doctors.doctorsinfo}
								>
									{doctors.name}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</Col>
				</Row>

				<Row className="mb-3 mt-3 justify-content-center">
					<Form.Label column sm="2">
						<b>Room Number:</b>
					</Form.Label>
					<Col sm="4">
						<Form.Control
							type="text"
							placeholder="Enter room number"
							value={roomNumber}
							onChange={(e) => setRoomNumber(e.target.value)}
						/>
					</Col>

					<Form.Label column sm="2">
						<b>Bed Number:</b>
					</Form.Label>
					<Col sm="4">
						<Form.Control
							type="text"
							placeholder="Enter bed number"
							value={bedNumber}
							onChange={(e) => setBedNumber(e.target.value)}
						/>
					</Col>
				</Row>
			</Container>
			<h4 className="mt-5 mb-3">General Services</h4>
			<InputGroup className="mb-3">
				<Form.Control
					type="text"
					placeholder="Start typing a name"
					value={query}
					onChange={handleInputChange}
					onKeyDown={(e) => handleKeyDown("generalServices", e)}
				/>
				<Dropdown.Menu
					show={suggestions?.length > 0}
					className="w-100 dropScroll"
				>
					{suggestions?.map((suggestion, index) => (
						<Dropdown.Item
							key={index}
							onClick={() => handleSuggestionClick(suggestion)}
							active={index === activeSuggestionIndex}
							className="d-flex justify-content-between"
						>
							<span>{suggestion.name}</span>
							<span>Rs {suggestion.price}</span>
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</InputGroup>
			{showAddForm && (
				<div className="mb-3">
					<p>
						<strong>{query}</strong> is not in the list. Add it below:
					</p>
					<InputGroup>
						<Form.Control
							type="number"
							placeholder="Enter price"
							value={newPrice}
							onChange={(e) => setNewPrice(e.target.value)}
						/>
						<Button onClick={handleAddItem} variant="primary">
							Add Item
						</Button>
					</InputGroup>
				</div>
			)}
			{selectedItems?.length > 0 ? (
				<Table bordered>
					<thead>
						<tr>
							<th>Service Used</th>
							<th>Price</th>
							<th>Quantity</th>
							<th>Total Price</th>
							<th>Date</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{selectedItems?.map((item, index) => (
							<tr key={index}>
								<td>{item.serviceName}</td>
								<td>
									<Form.Control
										type="number"
										value={item.price}
										onChange={(e) =>
											handleTableInputChange(
												index,
												parseFloat(e.target.value),
												"price",
												"generalServices"
											)
										}
									/>
								</td>
								<td>
									<Form.Control
										type="number"
										value={item.quantity}
										onChange={(e) =>
											handleTableInputChange(
												index,
												parseFloat(e.target.value),
												"quantity",
												"generalServices"
											)
										}
									/>
								</td>
								<td>
									Rs{" "}
									{isNaN(item.price * item.quantity)
										? 0
										: (item.price * item.quantity).toFixed(2)}
								</td>
								<td>
									<Form.Control
										type="date"
										value={item.date}
										onChange={(e) =>
											handleTableInputChange(
												index,
												e.target.value,
												"date",
												"generalServices"
											)
										}
									/>
								</td>
								<td>
									<Button
										variant="danger"
										onClick={() => handleRemoveItem(index)}
									>
										<i className="fa fa-trash"></i>
									</Button>
								</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="3">
								<strong>Subtotal:</strong>
							</td>
							<td colSpan="3">
								Rs {calculateSubtotal("generalServices").toFixed(2)}
							</td>
						</tr>
					</tfoot>
				</Table>
			) : (
				<Table bordered>
					<thead>
						<tr>
							<th>Service Used</th>
							<th>Price</th>
							<th>Quantity</th>
							<th>Total Price</th>
							<th>Date</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody className="text-center" style={{ height: "50px" }}>
						<tr>
							<td
								colSpan="6"
								style={{ textAlign: "center", verticalAlign: "middle" }}
							>
								No data available
							</td>
						</tr>
					</tbody>
				</Table>
			)}
			<h4 className="mt-5 mb-3">Professional Services</h4>
			<InputGroup className="mb-3">
				<Form.Control
					type="text"
					placeholder="Start typing a service name"
					value={docquery}
					onChange={handleDocInputChange}
					onKeyDown={(e) => handleKeyDown("professionalServices", e)} // Optional: if you want to handle keyboard navigation
				/>
				<Dropdown.Menu
					show={docsuggestions?.length > 0}
					className="w-100 dropScroll"
				>
					{docsuggestions?.map((suggestion, index) => (
						<Dropdown.Item
							key={index}
							onClick={() => handleDoctorSuggestionClick(suggestion)}
							active={index === activeDocSuggestionIndex}
							className="d-flex justify-content-between"
						>
							<span>{suggestion.serviceName}</span>
							<span>Rs {suggestion.price}</span>
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</InputGroup>
			{showAddForm && (
				<div className="mb-3">
					<p>
						<strong>{docquery}</strong> is not in the list. Add it below:
					</p>
					{/* <InputGroup>
						<Form.Control
							type='number'
							placeholder='Enter price'
							value={newPrice}
							onChange={(e) => setNewPrice(e.target.value)}
						/>
						<Button onClick={handleAddDocItem} variant='primary'>
							Add Service
						</Button>
					</InputGroup> */}
				</div>
			)}
			{professionalServices?.length > 0 ? (
				<Table bordered>
					<thead>
						<tr>
							<th>Service Used</th>
							<th>Speciality</th>
							<th>Price</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
						{professionalServices?.map((service, index) => (
							<tr key={index}>
								<td>{service.serviceName}</td>
								<td>
									{service?.specialities?.name?.length > 1 ? (
										<DropdownButton
											key="down-centered"
											id={`dropdown-button-drop-down-centered`}
											drop="down-centered"
											variant="success"
											title={service.speciality}
											onSelect={(eventKey) => {
												let evevalue = eventKey?.split("@@");
												handleTableInputChange(
													index,
													evevalue[0],
													"speciality",
													"professionalServices"
												);
												handleTableInputChange(
													index,
													evevalue[1],
													"specialityId",
													"professionalServices"
												);
											}}
										>
											{service.specialities.name.map((specialities, index) => (
												<Dropdown.Item
													eventKey={
														specialities +
														"@@" +
														service.specialities?.id[index]
													}
												>
													{specialities}
												</Dropdown.Item>
											))}
										</DropdownButton>
									) : (
										service.speciality
									)}
								</td>
								<td>
									<Form.Control
										type="number"
										value={service.price}
										onChange={(e) =>
											handleTableInputChange(
												index,
												parseFloat(e.target.value),
												"price",
												"professionalServices"
											)
										}
									/>
								</td>
								<td>
									<Form.Control
										type="date"
										value={service.date}
										onChange={(e) =>
											handleTableInputChange(
												index,
												e.target.value,
												"date",
												"professionalServices"
											)
										}
									/>
								</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="3">
								<strong>Subtotal:</strong>
							</td>
							<td colSpan="2">
								Rs {calculateSubtotal("professionalServices").toFixed(2)}
							</td>
						</tr>
					</tfoot>
				</Table>
			) : (
				<Table bordered>
					<thead>
						<tr>
							<th>Service Used</th>
							<th>Speciality</th>
							<th>Price</th>
							<th>Quantity</th>
							<th>Total Price</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody className="text-center" style={{ height: "50px" }}>
						<tr>
							<td
								colSpan="6"
								style={{ textAlign: "center", verticalAlign: "middle" }}
							>
								No data available
							</td>
						</tr>
					</tbody>
				</Table>
			)}{" "}
			<h4 className="mt-5 mb-3">Inventory Items</h4>
			<Table bordered>
				<thead>
					<tr>
						<th>Product Name</th>
						<th>M.R.P</th>
						<th>Quantity</th>
						<th>Total Price</th>
						<th>Action</th>
					</tr>
				</thead>

				{values?.map((item, index) => (
					<tbody key={index}>
						<tr>
							<td>
								<input
									className="form-control"
									name="productName"
									value={item?.productName}
									onChange={(e) => {
										handleChange(index, "productName", e?.target?.value);
										handleProductSearchChange(index, e);
									}}
								/>

								{rowProductSearch[index] &&
									rowFilteredProductStock[index]?.length > 0 && (
										<ListGroup
											className="position-relative"
											style={{ zIndex: 100 }}
										>
											{rowFilteredProductStock[index]
												?.filter(
													(product) =>
														product.name?.toLowerCase() !==
														item.productName?.toLowerCase()
												)
												.map((product) => (
													<ListGroup.Item
														key={product.name}
														action
														onClick={() => handleSelectProduct(index, product)}
													>
														{product.name}
													</ListGroup.Item>
												))}
										</ListGroup>
									)}
							</td>
							<td>
								<DropdownButton
									key="down-centered"
									id={`dropdown-button-drop-down-centered`}
									drop="down-centered"
									variant="success"
									title={item?.mrp ? item?.mrp : "Choose"}
									onClick={() => validateProductName(index)}
									onSelect={(e) => handleChange(index, "mrp", e)}
								>
									<div className="dropScroll">
										{getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.map(
											(product) =>
												item?.productName === product?.name &&
												product?.selling_prices?.map((price) => (
													<Dropdown.Item
														key={price.selling_price}
														eventKey={price.selling_price}
													>
														{price.selling_price}
													</Dropdown.Item>
												))
										)}
									</div>
								</DropdownButton>
							</td>
							<td>
								<input
									className="form-control"
									name="quantity"
									value={item?.quantity}
									onChange={(e) => {
										const productArray =
											getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
												(product) => product?.name === item?.productName
											);

										const sellingPrice = productArray?.selling_prices?.find(
											(sp) => sp?.selling_price === Number(item?.mrp)
										);

										if (sellingPrice?.quantityLeft < Number(e?.target?.value)) {
											alert(
												`Available quantity is only ${sellingPrice?.quantityLeft}`
											);
										} else {
											handleChange(index, "quantity", e?.target?.value);
										}
									}}
								/>
							</td>
							<td>
								Rs{" "}
								{isNaN(Number(item.mrp) * Number(item.quantity))
									? "0.00"
									: (
											Number(item.mrp || 0) * Number(item.quantity || 0)
									  ).toFixed(2)}
							</td>

							<td>
								<button
									className="btn btn-danger"
									onClick={() => handleDeleteRow(index)}
								>
									<i className="fa fa-trash"></i>
								</button>
							</td>
						</tr>
					</tbody>
				))}

				<tfoot>
					<tr>
						<td colSpan="4" style={{ textAlign: "center" }}>
							<button className="btn btn-secondary" onClick={handleAddRow}>
								+ Add more items
							</button>
						</td>
					</tr>
					<tr>
						<td colSpan="4">
							<strong>Subtotal:</strong>
						</td>
						<td>Rs {calculateSubtotal("InventoryServices")?.toFixed(2)}</td>
					</tr>
				</tfoot>
			</Table>
			<h4 className="mt-5 mb-3">Lab Tests</h4>
			<Table bordered className="custom-table">
				<thead>
					<tr>
						<th style={{ width: "40%" }}>Test Name</th>
						<th>Description</th>
						<th>Price</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{LabTestsRows?.map((testRow, index) => {
						return (
							<tr key={index}>
								<td>
									<DropdownButton
										key="down-centered"
										id={`dropdown-button-drop-down-centered`}
										drop="down-centered"
										variant="success"
										title={LabTestsRows[index]?.testName}
										onSelect={(eventKey) => {
											const [testName, testPrice, deptId, deptName, testIndex] =
												eventKey.split("@@");

											editLabTestRow(index, "deptId", deptId);
											editLabTestRow(index, "deptName", deptName);
											editLabTestRow(index, "testName", testName);
											editLabTestRow(index, "testPrice", testPrice);
											editLabTestRow(index, "testIndex", testIndex);
										}}
									>
										{LabDeptTests?.map(
											(test) =>
												test.status !== 0 && (
													<Dropdown.Item
														eventKey={
															test.testName +
															"@@" +
															test.testPrice +
															"@@" +
															test.deptId +
															"@@" +
															test.deptName +
															"@@" +
															test.testIndex
														}
														key={test.testIndex}
													>
														{test.testName}
													</Dropdown.Item>
												)
										)}
									</DropdownButton>
								</td>
								<td>
									<textarea
										className="form-control"
										rows={1}
										value={testRow.description}
										onChange={(e) =>
											editLabTestRow(index, "description", e?.target?.value)
										}
									></textarea>
								</td>
								<td>{testRow.testPrice}</td>
								<td>
									<button
										className="btn btn-danger"
										onClick={() => DeleteLabTestRow(index)}
									>
										<i className="fa fa-trash"></i>
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>

				<tfoot>
					<tr>
						<td
							colSpan="4"
							style={{ textAlign: "center", verticalAlign: "middle" }}
						>
							<button className="btn btn-secondary" onClick={AddLabTestRow}>
								+ Add more tests
							</button>
						</td>
					</tr>
					<tr>
						<td colSpan="3">
							<strong>Subtotal:</strong>
						</td>
						<td>Rs {calculateSubtotal("LabServices")?.toFixed(2)}</td>
					</tr>
				</tfoot>
			</Table>
			<Container className="header-center mt-5 mb-3">
				<Row className="justify-content-between">
					<h4>Payments History</h4>

					<Button onClick={() => setShowPaymentForm(true)} variant="success">
						Make Advance Payment
					</Button>
				</Row>
			</Container>
			{previousPayments?.length > 0 ? (
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Date</th>
							<th>Paid Amount</th>
							<th>Paid in Cash</th>
							<th>Paid Online</th>
							{/* <th>Discount Amount</th> */}
						</tr>
					</thead>
					<tbody>
						{previousPayments?.map((payment, index) => (
							<tr key={index}>
								<td>{payment.paidDate}</td>
								<td>Rs {payment.amountPaid}</td>
								<td>Rs {payment.amountPaidCash}</td>
								<td>Rs {payment.amountPaidOnline}</td>
								{/* <td>Rs {payment.discountAmount}</td> */}
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td>
								<strong>Total:</strong>
							</td>
							<td>
								<strong>
									Rs{" "}
									{previousPayments
										?.reduce((sum, payment) => sum + payment.amountPaid, 0)
										.toFixed(2)}
								</strong>
							</td>
							<td>
								<strong>
									Rs{" "}
									{previousPayments
										?.reduce((sum, payment) => sum + payment.amountPaidCash, 0)
										.toFixed(2)}
								</strong>
							</td>
							<td>
								<strong>
									Rs{" "}
									{previousPayments
										?.reduce(
											(sum, payment) => sum + payment.amountPaidOnline,
											0
										)
										.toFixed(2)}
								</strong>
							</td>
						</tr>
					</tfoot>
				</Table>
			) : (
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Date</th>
							<th>Paid Amount</th>
							<th>Paid in Cash</th>
							<th>Paid Online</th>
							<th>Discount Amount</th>
						</tr>
					</thead>
					<tbody className="text-center" style={{ height: "50px" }}>
						<tr>
							<td
								colSpan="6"
								style={{ textAlign: "center", verticalAlign: "middle" }}
							>
								No data available
							</td>
						</tr>
					</tbody>
				</Table>
			)}
			<Row className="justify-content-center">
				<Card className="col-4">
					{" "}
					<div className="m-3 header-center">
						Total Amount:{" "}
						{Number(calculateSubtotal("LabServices")?.toFixed(2)) +
							Number(calculateSubtotal("InventoryServices")?.toFixed(2)) +
							Number(calculateSubtotal("professionalServices")?.toFixed(2)) +
							Number(calculateSubtotal("generalServices")?.toFixed(2))}
					</div>
				</Card>
				<Card className="col-4">
					<div className="m-3 header-center">
						Balance Amount:{" "}
						{(
							Number(calculateSubtotal("LabServices")?.toFixed(2)) +
							Number(calculateSubtotal("InventoryServices")?.toFixed(2)) +
							Number(calculateSubtotal("professionalServices")?.toFixed(2)) +
							Number(calculateSubtotal("generalServices")?.toFixed(2)) -
							(previousPayments?.reduce(
								(acc, payment) => acc + Number(payment?.amountPaid || 0),
								0
							) || 0)
						).toFixed(2)}
					</div>
				</Card>
			</Row>
			<Container className="header-center mt-3">
				<Row className="justify-content-center">
					<Col md="auto">
						<Button onClick={handleSaveBill} variant="primary">
							Save Bill
						</Button>
					</Col>
					<Col md="auto">
						<Button
							className="ms-2"
							onClick={handleValidationsBeforeClosing}
							variant="danger"
						>
							Close & Complete Payment
						</Button>
					</Col>
				</Row>
			</Container>
			<Modal show={showPaymentForm} onHide={handleClosePaymentModal}>
				<Modal.Header closeButton>
					<Modal.Title>Make Payment</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="4">
								<b>Cash Payment:</b>
							</Form.Label>
							<Col sm="8">
								<Form.Control
									type="number"
									placeholder="Enter cash amount"
									value={cashPayment}
									onChange={(e) => setCashPayment(e.target.value)}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="4">
								<b>Online Payment:</b>
							</Form.Label>
							<Col sm="8">
								<Form.Control
									type="number"
									placeholder="Enter online amount"
									value={onlinePayment}
									onChange={(e) => {
										setOnlinePayment(e.target.value);
										setDiscount(0);
									}}
								/>
							</Col>
						</Form.Group>
						{/* <Form.Group as={Row} className='mb-3'>
							<Form.Label column sm='4'>
								<b>Discount:</b>
							</Form.Label>
							<Col sm='8'>
								<Form.Control
									type='number'
									placeholder='Enter discount'
									value={discount}
									onChange={(e) => setDiscount(e.target.value)}
								/>
							</Col>
						</Form.Group> */}
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClosePaymentModal}>
						Close
					</Button>
					<Button variant="primary" onClick={handlePayment}>
						Add Payment
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showFinalPaymentForm} onHide={handleFinalClosePaymentModal}>
				<Modal.Header closeButton>
					<Modal.Title>Close Bill</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="4">
								<b>Balance Amount:</b>
							</Form.Label>
							<Col sm="8">
								<Form.Control
									type="number"
									value={(
										Number(calculateSubtotal("LabServices")?.toFixed(2)) +
										Number(calculateSubtotal("InventoryServices")?.toFixed(2)) +
										Number(
											calculateSubtotal("professionalServices")?.toFixed(2)
										) +
										Number(calculateSubtotal("generalServices")?.toFixed(2)) -
										(previousPayments?.reduce(
											(acc, payment) => acc + Number(payment?.amountPaid || 0),
											0
										) || 0)
									).toFixed(2)}
									disabled
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="4">
								<b>Discount:</b>
							</Form.Label>
							<Col sm="8">
								<Form.Control
									type="number"
									placeholder="Enter discount"
									value={finalDiscount}
									onChange={(e) => handleFinalDiscount(e.target.value)}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="4">
								<b>Final Amount:</b>
							</Form.Label>
							<Col sm="8">
								<Form.Control
									type="number"
									value={
										totalAmountAfterDiscount === 0
											? (
													Number(calculateSubtotal("LabServices")?.toFixed(2)) +
													Number(
														calculateSubtotal("InventoryServices")?.toFixed(2)
													) +
													Number(
														calculateSubtotal("professionalServices")?.toFixed(
															2
														)
													) +
													Number(
														calculateSubtotal("generalServices")?.toFixed(2)
													) -
													(previousPayments?.reduce(
														(acc, payment) =>
															acc + Number(payment?.amountPaid || 0),
														0
													) || 0)
											  ).toFixed(2)
											: totalAmountAfterDiscount
									}
									disabled
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="4">
								<b>Cash Payment:</b>
							</Form.Label>
							<Col sm="8">
								<Form.Control
									type="number"
									placeholder="Enter cash amount"
									value={finalCashPayment}
									onChange={(e) => handleAmounts(e.target.value, "cash")}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3">
							<Form.Label column sm="4">
								<b>Online Payment:</b>
							</Form.Label>
							<Col sm="8">
								<Form.Control
									type="number"
									placeholder="Enter online amount"
									value={finalOnlinePayment}
									onChange={(e) => {
										handleAmounts(e.target.value, "online");
									}}
								/>
							</Col>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleFinalClosePaymentModal}>
						Close
					</Button>
					<Button variant="primary" onClick={handleCloseBill}>
						Complete Payment
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default StartIpBillScreen;
