/** @format */

import { createSlice } from '@reduxjs/toolkit';

const onSpotPrescription = createSlice({
	name: 'prescription',
	initialState: {},
	reducers: {
		onSpotPrescriptionStart: (state) => {
			state.loading = true;
		},
		onSpotPrescriptionSuccess: (state, action) => {
			state.loading = false;
			state.prescriptionInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		onSpotPrescriptionFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		onSpotPrescriptionReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	onSpotPrescriptionStart,
	onSpotPrescriptionSuccess,
	onSpotPrescriptionFailure,
	onSpotPrescriptionReset,
} = onSpotPrescription.actions;
export default onSpotPrescription.reducer;
