/** @format */

import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Table from "react-bootstrap/Table";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Message from "../../../Components/Message";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import axios from "axios";
import { SERVER_URL } from "../../../App";
import Button from "react-bootstrap/Button";
import {
	createLabDept,
	getLabDepts,
	deleteLabDept,
} from "../../../redux/adminRedux/adminApiCalls";

const DeleteLabDeptScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	useEffect(() => {
		dispatch(getLabDepts());
	}, []);

	const { getlabDeptAll, loading } = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	const deleteLabDepts = useSelector((state) => state.deleteLabDept);

	useEffect(() => {
		dispatch(getLabDepts());
	}, [deleteLabDepts?.loading]);

	const deleteLab = (id, deptName) => {
		dispatch(deleteLabDept(id));
		// dispatch(getLabDepts());
	};
	let count = 0;
	return (
		<>
			<Meta />
			<div>
				<h1 className="header-center">Delete Lab Department </h1>
				<Container className="header-center mt-5 col-md-8">
					<Table striped bordered hover className="custom-table">
						<thead>
							<tr>
								<th>Sno</th>
								<th>Lab Department Name</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{getlabDeptAll?.data?.data?.map((lab, index) => {
								if (lab.deptName !== "hospital") {
									count++;
								}

								return (
									lab.deptName !== "hospital" &&
									lab.status !== 0 && (
										<tr key={lab._id}>
											<td>{count}</td>
											<td>{lab?.deptName}</td>
											<td>
												<button
													className="btn btn-danger"
													onClick={() => deleteLab(lab._id, lab?.deptName)}
												>
													<i className="fas fa-trash"></i>
												</button>
											</td>
										</tr>
									)
								);
							})}
						</tbody>
					</Table>
				</Container>
			</div>
		</>
	);
};
export default DeleteLabDeptScreen;
