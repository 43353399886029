/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getInventoryCategory = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getInventoryCategoryStart: (state) => {
			state.loading = true;
		},
		getInventoryCategorySuccess: (state, action) => {
			state.loading = false;
			state.getInventorycategory = action.payload;
			state.error = false;
			state.success = true;
		},
		getInventoryCategoryFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getInventoryCategoryReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getInventoryCategoryStart,
	getInventoryCategorySuccess,
	getInventoryCategoryFailure,
	getInventoryCategoryReset,
} = getInventoryCategory.actions;
export default getInventoryCategory.reducer;
