import { createSlice } from "@reduxjs/toolkit";

const getuserByIdForPrescription = createSlice({
	name: "getuserByIdForPrescription",
	initialState: {},
	reducers: {
		getuserByIdForPrescriptionRequest: (state) => {
			state.loading = true;
		},
		getuserByIdForPrescriptionSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.userdata = action.payload;
			state.error = false;
			state.success = true;
		},
		getuserByIdForPrescriptionFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getuserByIdForPrescriptionReset: (state) => {
			return { error: false, success: false };
		},
		getUserDataErrorByIdReset: (state) => {
			return { userData: false };
		},
	},
});

export const {
	getuserByIdForPrescriptionRequest,
	getuserByIdForPrescriptionSuccess,
	getuserByIdForPrescriptionFailure,
	getuserByIdForPrescriptionReset,
	getUserDataErrorByIdReset,
} = getuserByIdForPrescription.actions;
export default getuserByIdForPrescription.reducer;
