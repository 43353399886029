/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createSupplier = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createSupplierStart: (state) => {
			state.loading = true;
		},
		createSupplierSuccess: (state, action) => {
			state.loading = false;
			state.createsupplier = action.payload;
			state.error = false;
			state.success = true;
		},
		createSupplierFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createSupplierReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createSupplierStart,
	createSupplierSuccess,
	createSupplierFailure,
	createSupplierReset,
} = createSupplier.actions;
export default createSupplier.reducer;
