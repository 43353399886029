/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getLabTech = createSlice({
	name: "getLabTech",
	initialState: {},
	reducers: {
		getLabTechRequest: (state) => {
			state.loading = true;
		},
		getLabTechSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getLabTechn = action.payload;
			state.error = false;
			state.success = true;
		},
		getLabTechFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getLabTechReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getLabTechRequest,
	getLabTechSuccess,
	getLabTechFailure,
	getLabTechReset,
} = getLabTech.actions;
export default getLabTech.reducer;
