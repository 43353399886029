/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getHistoryTemplateInSpeciality = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		getHistoryTemplateInSpecialityStart: (state) => {
			state.loading = true;
			state.getHistoryTemplateinSpeciality = [];
			state.error = false;
			state.success = false;
		},
		getHistoryTemplateInSpecialitySuccess: (state, action) => {
			state.loading = false;
			state.getHistoryTemplateinSpeciality = action.payload;
			state.error = false;
			state.success = true;
		},
		getHistoryTemplateInSpecialityFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getHistoryTemplateInSpecialityReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getHistoryTemplateInSpecialityStart,
	getHistoryTemplateInSpecialitySuccess,
	getHistoryTemplateInSpecialityFailure,
	getHistoryTemplateInSpecialityReset,
} = getHistoryTemplateInSpeciality.actions;
export default getHistoryTemplateInSpeciality.reducer;
