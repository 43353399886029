/** @format */

import { createSlice } from '@reduxjs/toolkit';

const addVitals = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		addVitalsStart: (state) => {
			state.loading = true;
		},
		addVitalsSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		addVitalsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		addVitalsReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	addVitalsStart,
	addVitalsSuccess,
	addVitalsFailure,
	addVitalsReset,
} = addVitals.actions;
export default addVitals.reducer;
