/** @format */

import { createSlice } from "@reduxjs/toolkit";

const ViewLabReportDetails = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		ViewLabReportDetailsStart: (state) => {
			state.loading = true;
		},
		ViewLabReportDetailsSuccess: (state, action) => {
			state.loading = false;
			state.reportDetails = action.payload;
			state.error = false;
			state.success = true;
		},
		ViewLabReportDetailsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		ViewLabReportDetailsReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	ViewLabReportDetailsStart,
	ViewLabReportDetailsSuccess,
	ViewLabReportDetailsFailure,
	ViewLabReportDetailsReset,
} = ViewLabReportDetails.actions;
export default ViewLabReportDetails.reducer;
