/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "../../../../Components/Loader.js";
import Meta from "../../../../Components/Meta.js";
import Button from "react-bootstrap/Button";
import { Col, Row, Container, Card } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PieChart from "../../../../Components/PieChart.js";
import BarChart from "../../../../Components/Barchart.js";
import {
	getTodaysRevenues,
	getPastCategoryRevenues,
	getIndividualCategoryRevenue,
	getIndividualInventoryItemRevenue,
} from "../../../../redux/inventoryRedux/inventoryApiCalls.js";

const TodaysInventoryStatisticsScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	useEffect(() => {
		dispatch(getTodaysRevenues());
	}, []);

	const getTodaysRevenuesDetails = useSelector(
		(state) => state.getTodaysRevenues
	);

	console.log(getTodaysRevenuesDetails);

	const [TodaysSalesTotalAmount, setTodaysSalesTotalAmount] = useState([]);
	const [TodaysPurchaseAmount, setTodaysPurchaseAmount] = useState([]);
	// const [TodaysDiscountAmount, setTodaysDiscountAmount] = useState([]);
	const [TodaysProfitAmount, setTodaysProfitAmount] = useState([]);

	useEffect(() => {
		// Today's Sale Amount
		let todaysSalesTotalAmount = [];
		getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.forEach((item) => {
			item.CategoryName !== "hospital" &&
				todaysSalesTotalAmount.push({
					id: item?.CategoryName,
					label: item?.CategoryName,
					value: item?.todays_category_sales?.category_sales_amount,
				});
		});

		setTodaysSalesTotalAmount(todaysSalesTotalAmount);

		// Today's Purchase Amount
		let todaysPurchaseAmount = [];
		getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.forEach((item) => {
			// Check if the category already exists in the array
			const existingEntry = todaysPurchaseAmount.find(
				(entry) => entry.id === item.CategoryName
			);

			if (existingEntry) {
				// If the entry exists, add all the purchase_amounts
				item.todays_category_purchase.category_purchase_bills.forEach(
					(bill) => {
						existingEntry.value += bill.purchase_amount;
					}
				);
			} else {
				// Create a new entry for the category
				const totalPurchaseAmount =
					item.todays_category_purchase.category_purchase_bills.reduce(
						(sum, bill) => sum + bill.purchase_amount,
						0
					);
				item.CategoryName !== "hospital" &&
					todaysPurchaseAmount.push({
						id: item.CategoryName,
						label: item.CategoryName,
						value: totalPurchaseAmount,
					});
			}
		});
		setTodaysPurchaseAmount(todaysPurchaseAmount);
		console.log(todaysPurchaseAmount);

		//Today's Discounts Revenue
		// let todaysDiscountAmount = [];
		// getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.forEach((item) => {
		// 	item.CategoryName !== "hospital" &&
		// 		todaysDiscountAmount.push({
		// 			id: item.CategoryName,
		// 			label: item.CategoryName,
		// 			value: item.todays_category_sales.category_sales_discount,
		// 		});
		// });
		// setTodaysDiscountAmount(todaysDiscountAmount);

		//Today's Profits Revenue
		let todaysProfitAmount = [];
		getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.forEach((item) => {
			item.CategoryName !== "hospital" &&
				todaysProfitAmount.push({
					id: item.CategoryName,
					label: item.CategoryName,
					value: item.todays_category_sales.category_sales_profit,
				});
		});
		setTodaysProfitAmount(todaysProfitAmount);
	}, [getTodaysRevenuesDetails?.loading]);

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href='/admin/AllPharmacySales'>
					Pharmacy
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/VendorLevelStatistics'>
					Vendor Level
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/CategoryLevelStatistics'>
					Category Level
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/todaysinventorystatistics'>
					Today's Pharmacy
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className='header-center mt-3 mb-3'>Today's Pharmacy Revenue</h1>
			<Container className='header-center card'>
				<Row className='justify-content-center mt-3 mb-3'>
					<Col md='auto'>
						<b>Total Amount: </b>Rs{" "}
						{getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.map(
							(category) =>
								category?.CategoryName === "hospital" && (
									<span key={category?.CategoryName}>
										{category?.todays_category_sales?.category_sales_amount}
									</span>
								)
						)}
					</Col>
					<Col md='auto'>
						<b>Total Cash: </b>Rs{" "}
						{getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.map(
							(category) =>
								category?.CategoryName === "hospital" && (
									<span key={category?.CategoryName}>
										{category?.todays_category_sales?.category_sales_amountCash}
									</span>
								)
						)}
					</Col>
					<Col md='auto'>
						<b>Total Online: </b>Rs{" "}
						{getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.map(
							(category) =>
								category?.CategoryName === "hospital" && (
									<span key={category?.CategoryName}>
										{
											category?.todays_category_sales
												?.category_sales_amountOnline
										}
									</span>
								)
						)}
					</Col>
					<Col md='auto'>
						<b>Total Profit Amount: </b>Rs{" "}
						{getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.map(
							(category) =>
								category?.CategoryName === "hospital" && (
									<span key={category?.CategoryName}>
										{category?.todays_category_sales?.category_sales_profit}
									</span>
								)
						)}
					</Col>
					<Col md='auto'>
						<b>Total Discount Amount: </b>Rs{" "}
						{getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.map(
							(category) =>
								category?.CategoryName === "hospital" && (
									<span key={category?.CategoryName}>
										{category?.todays_category_sales?.category_sales_discount}
									</span>
								)
						)}
					</Col>
				</Row>
			</Container>
			<Row>
				<Col md='6'>
					<h4 className='header-center mt-5'>Today's Sales Revenue</h4>
					<Container className='header-center mt-3'>
						<PieChart data={TodaysSalesTotalAmount} />
					</Container>
				</Col>
				<Col md='6'>
					<h4 className='header-center mt-5'>Today's Sales Profits Revenue</h4>
					<Container className='header-center mt-3'>
						<PieChart data={TodaysProfitAmount} />
					</Container>
				</Col>
			</Row>
			<Row className='justify-content-center mt-3 mb-3'>
				<Col>
					<h4 className='header-center mt-5'>Today's Purchase Revenue</h4>
					<Container className='header-center mt-3'>
						<Row className='justify-content-center'>
							<Col md='auto' className='card'>
								<div className='mt-3 mb-3'>
									<b>Total Purchase Amount: </b>

									{getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.map(
										(category) =>
											category?.CategoryName === "hospital" && (
												<span key={category?.CategoryName}>
													Rs{" "}
													{
														category?.todays_category_purchase
															?.category_purchase_amount
													}
												</span>
											)
									)}
								</div>
							</Col>
						</Row>
					</Container>
					<PieChart data={TodaysPurchaseAmount} />
				</Col>
				{/* <Col md='6'>
					<h4 className='header-center mt-5'>
						Today's Sales Discounts Revenue
					</h4>
					<Card style={{ display: "flex", alignItems: "center" }}></Card>
					<PieChart data={TodaysDiscountAmount} />
				</Col> */}
			</Row>
		</>
	);
};

export default TodaysInventoryStatisticsScreens;
