// src/Autocomplete.js
import React, { useState } from "react";
import "../styles/Autocomplete.css";

const Autocomplete = ({ suggestions }) => {
	const [inputValue, setInputValue] = useState("");
	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [activeSuggestion, setActiveSuggestion] = useState(0);

	const onChange = (e) => {
		const userInput = e.target.value;
		setInputValue(userInput);

		const filtered = suggestions.filter(
			(suggestion) =>
				suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
		);

		setFilteredSuggestions(filtered);
		setShowSuggestions(true);
		setActiveSuggestion(0);
	};

	const onClick = (e) => {
		setFilteredSuggestions([]);
		setInputValue(e.target.innerText);
		setShowSuggestions(false);
		setActiveSuggestion(0);
	};

	const onKeyDown = (e) => {
		if (e.keyCode === 13) {
			// Enter key
			setInputValue(filteredSuggestions[activeSuggestion]);
			setShowSuggestions(false);
		} else if (e.keyCode === 38) {
			// Up arrow
			if (activeSuggestion === 0) {
				return;
			}
			setActiveSuggestion(activeSuggestion - 1);
		} else if (e.keyCode === 40) {
			// Down arrow
			if (activeSuggestion + 1 === filteredSuggestions.length) {
				return;
			}
			setActiveSuggestion(activeSuggestion + 1);
		}
	};

	const renderSuggestions = () => {
		if (showSuggestions && inputValue) {
			if (filteredSuggestions.length) {
				return (
					<ul className="suggestions">
						{filteredSuggestions.map((suggestion, index) => {
							let className;
							if (index === activeSuggestion) {
								className = "suggestion-active";
							}
							return (
								<li key={suggestion} className={className} onClick={onClick}>
									{suggestion}
								</li>
							);
						})}
					</ul>
				);
			} else {
				return (
					<div className="no-suggestions">
						<em>No suggestions available.</em>
					</div>
				);
			}
		}
		return null;
	};

	return (
		<div>
			<input
				type="text"
				onChange={onChange}
				onKeyDown={onKeyDown}
				value={inputValue}
			/>
			{renderSuggestions()}
		</div>
	);
};

export default Autocomplete;
