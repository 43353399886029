/** @format */

import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Loader';
import Table from 'react-bootstrap/Table';
import Meta from '../../../Components/Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Message from '../../../Components/Message';
import { createReceptionistReset } from '../../../redux/adminRedux/Reception_Management/addReceptionist';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from 'axios';
import { SERVER_URL } from '../../../App';
import Button from 'react-bootstrap/Button';
import {
	deleteReception,
	getAllReception,
} from '../../../redux/adminRedux/adminApiCalls';

const DeleteReceptionScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	var getAllReceptionData = useSelector((state) => state.getAllReception);
	getAllReceptionData = getAllReceptionData?.getAllReception?.data;
	console.log(getAllReceptionData);

	useEffect(() => {
		dispatch(getAllReception());
	}, []);

	const deleteReceptionHandle = (id) => {
		dispatch(deleteReception(id));
		dispatch(getAllReception());
	};

	const today = new Date().toISOString().split('T')[0];
	return (
		<>
			<Meta />
			<div>
				<h1 className='header-center'>Delete Receptionist</h1>
				<Container className='header-center mt-5 col-md-8'>
					<Table striped bordered hover className='custom-table'>
						<thead>
							<tr>
								<th>Sno</th>
								<th>Receptionist Name</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{getAllReceptionData?.data?.map((rec, index) => (
								<tr>
									<td>{index + 1}</td>
									<td>{rec?.name}</td>
									<td>
										<button
											className='btn btn-danger'
											onClick={() => deleteReceptionHandle(rec?._id)}>
											<i className='fas fa-trash'></i>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Container>
			</div>
		</>
	);
};
export default DeleteReceptionScreen;
