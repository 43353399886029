/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getTestStatisticsForParticularYear = createSlice({
	name: 'getTestStatisticsForParticularYear',
	initialState: {},
	reducers: {
		getTestStatisticsForParticularYearRequest: (state) => {
			state.loading = true;
		},
		getTestStatisticsForParticularYearSuccess: (state, action) => {
			console.log('gotit12');
			state.loading = false;
			state.getTestStatisticsForParticularyear = action.payload;
			state.error = false;
			state.success = true;
		},
		getTestStatisticsForParticularYearFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getTestStatisticsForParticularYearReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getTestStatisticsForParticularYearRequest,
	getTestStatisticsForParticularYearSuccess,
	getTestStatisticsForParticularYearFailure,
	getTestStatisticsForParticularYearReset,
} = getTestStatisticsForParticularYear.actions;
export default getTestStatisticsForParticularYear.reducer;
