/** @format */

import axios from "axios";
import { SERVER_URL } from "../App";
import { hospitalname } from "../App";
import { loginSuccess } from "./userLogin";
import { loginStart } from "./userLogin";
import { loginFailure } from "./userLogin";
import { registerStart } from "./userRegister";
import { registerFailure } from "./userRegister";
import { registerSuccess } from "./userRegister";
import { logout } from "./userLogin";
import { ProductReviewCreateSuccess } from "./userReview";
import { ProductReviewCreateRequest } from "./userReview";
import { ProductReviewCreateFailure } from "./userReview";
import {
	ProductReviewGetRequest,
	ProductReviewGetFailure,
	ProductReviewGetSuccess,
} from "./getReviews";
import { UpdateFailure, UpdateStart, UpdateSuccess } from "./userUpdate";
import { DeleteFailure, DeleteStart, DeleteSuccess } from "./deleteUser";
import {
	UpdatePasswordFailure,
	UpdatePasswordStart,
	UpdatePasswordSuccess,
} from "./updatePassword";
import {
	createBookingStart,
	createBookingFailure,
	createBookingSuccess,
} from "./createBooking";

import {
	specialityGetRequest,
	specialityGetSuccess,
	specialityGetFailure,
} from "./getSpecialities";
import {
	TimeSlotGetRequest,
	TimeSlotGetFailure,
	TimeSlotGetSuccess,
} from "./getTimeSlots";
import { persistor } from "./store";
import {
	getDoctorfromSpecialityFailure,
	getDoctorfromSpecialityRequest,
	getDoctorfromSpecialitySuccess,
	getDoctorfromSpecialityReset,
} from "./getDoctorfromSpeciality";
import {
	UserReviewGetRequest,
	UserReviewGetFailure,
	UserReviewGetSuccess,
	UserReviewGetReset,
} from "./getUserReview";

import {
	UserBookingGetFailure,
	UserBookingGetRequest,
	UserBookingGetSuccess,
} from "./getPatientBooking";

import {
	getUserdataRequest,
	getUserdataSuccess,
	getUserdataFailure,
} from "./getUser";

import {
	deleteBookingFailure,
	deleteBookingSuccess,
	deleteBookingStart,
} from "./deleteBooking";

import {
	reviewUpdateFailure,
	reviewUpdateRequest,
	reviewUpdateSuccess,
} from "./updateReview";
import {
	createCategoryStart,
	createCategoryFailure,
	createCategorySuccess,
} from "./createCategory";

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../redux/allMessages";

// import { UpdateStart } from "./userUpdate";
// import { UpdateSuccess } from "./userUpdate";
// import { UpdateFailure } from "./userUpdate";
// import { userUpdateProfileReset } from "./userUpdate";
// import { bookingListMyReset } from "./getMyAppointmentss";
// import { tourReviewCreateReset } from "./tourReview";
const username = localStorage.getItem("username");

export const login = (mobileno, password) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(loginStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const data1 = await axios.post(
			`${SERVER_URL}/api/v1/users/login`,
			{ mobileno, password },
			config
		);
		console.log(data1);

		const { data } = data1.data;
		let users = data.user.name + "/";
		for (let i = 0; i < data.user.registered_patients_array.length; i++) {
			users = users + data.user.registered_patients_array[i].name + "/";
		}

		localStorage.setItem("token-user", data.token);
		localStorage.setItem("userInfo", JSON.stringify(data));
		localStorage.setItem(
			"username",
			"2e40ad879e955201df4dedbf8d479a12".toString()
		);

		console.log(data);
		dispatch(loginSuccess(data1.data));
		dispatch(allMessagesSuccess(" Successfully logged in"));
		localStorage.setItem("sessionStartTime", Date.now().toString());
	} catch (error) {
		console.log(error?.response?.data?.data?.message);
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			loginFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const register =
	(name, password, mobilenumber, gender, dob, address, bloodGroup) =>
	async (dispatch) => {
		try {
			dispatch(registerStart());

			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/users/signup`,
				{
					name,
					password,
					mobilenumber,
					gender,
					dob,
					address,
					bloodGroup,
				},
				config
			);
			console.log(data);
			dispatch(registerSuccess(data));
			dispatch(allMessagesSuccess(" Registered Succesfully. Please login"));
		} catch (error) {
			console.log(error?.response);
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				registerFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const logout1 = () => (dispatch) => {
	dispatch(logout());
	// dispatch(userUpdateProfileReset());
	// dispatch(bookingListMyReset());
	// dispatch(tourReviewCreateReset());
	localStorage.removeItem("userInfo");
	localStorage.removeItem("adminInfo");
	localStorage.removeItem("receptionInfo");
	localStorage.removeItem("labTechInfo");
	localStorage.removeItem("doctorInfo");
	localStorage.removeItem("username");
	localStorage.removeItem("token-user");
	localStorage.removeItem("persist:root");
	localStorage.removeItem("sessionStartTime");
	persistor.pause();
	persistor.flush().then(() => {
		return persistor.purge();
	});
};

export const createReview = (rating, reviews) => async (dispatch, getState) => {
	try {
		dispatch(ProductReviewCreateRequest());

		const {
			userLogin: { userInfo },
		} = getState();
		console.log(getState());
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};

		const data = await axios.post(
			`${SERVER_URL}/api/v1/reviews`,
			{ rating: rating, review: reviews },
			config
		);
		console.log(data + "review123");
		dispatch(ProductReviewCreateSuccess());
		dispatch(
			allMessagesSuccess(
				"Review created Successfully. Review will be verified by admin"
			)
		);
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		const message =
			error.response && error.response.data.data.message
				? error.response.data.data.message
				: error.message;
		if (message === "Not authorized, token failed") {
			localStorage.removeItem("persist:root");
			dispatch(logout());
		}
		dispatch(ProductReviewCreateFailure(message));
	}
};

export const getReviews = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(ProductReviewGetRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/reviews`,

			config
		);

		console.log(data);
		dispatch(ProductReviewGetSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		console.log(error);
		dispatch(
			ProductReviewGetFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getUserdata = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getUserdataRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/users/getuser`,

			config
		);

		console.log(data);
		dispatch(getUserdataSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getUserdataFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const updateUserProfile =
	(name, gender, dob, mobno, bloodGroup, user) => async (dispatch) => {
		try {
			dispatch(UpdateStart());
			console.log("getting3221");
			console.log(bloodGroup);
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			console.log("getting3221asd");

			const { data } = await axios.patch(
				`${SERVER_URL}/api/v1/booking/addRegisterPatient`,
				{ name, gender, dob, mobno, bloodGroup },
				config
			);
			console.log(data);
			dispatch(UpdateSuccess(data));
			if (user === "user") dispatch(loginSuccess(data));

			if (user === "user")
				localStorage.setItem("userInfo", JSON.stringify(data));
			dispatch(allMessagesSuccess("Added patient successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			const message =
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message;
			if (message === "Not authorized, token failed") {
				localStorage.removeItem("persist:root");
				dispatch(logout());
			}
			dispatch(UpdateFailure(message));
		}
	};

export const updateUserProfileByAdmin =
	(name, gender, dob, mobno, bloodGroup, user) => async (dispatch) => {
		try {
			dispatch(UpdateStart());
			console.log("getting3221");

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			console.log("getting3221asd");

			const { data } = await axios.patch(
				`${SERVER_URL}/api/v1/booking/addRegisterPatient`,
				{ name, gender, dob, mobno, bloodGroup, username },
				config
			);
			console.log(data);
			dispatch(UpdateSuccess(data));
			if (user === "user") dispatch(loginSuccess(data));
			dispatch(allMessagesSuccess("Added patient successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			const message =
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message;
			if (message === "Not authorized, token failed") {
				localStorage.removeItem("persist:root");
				dispatch(logout());
			}
			dispatch(UpdateFailure(message));
		}
	};

export const updatePassword = (password) => async (dispatch, getState) => {
	try {
		dispatch(UpdatePasswordStart());
		console.log("getting3221");
		const {
			userLogin: { userInfo },
		} = getState();
		console.log(password);
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};

		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/users/updatePassword`,
			{ password },
			config
		);
		console.log(data);
		dispatch(UpdatePasswordSuccess(data));
		dispatch(logout());
		// dispatch(userUpdateProfileReset());
		// dispatch(bookingListMyReset());
		// dispatch(tourReviewCreateReset());
		dispatch(
			allMessagesSuccess("Password updated Successfully.Please Login again")
		);
		localStorage.removeItem("userInfo");
		localStorage.removeItem("token-user");
		localStorage.removeItem("persist:root");
		persistor.pause();
		persistor.flush().then(() => {
			return persistor.purge();
		});
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		const message =
			error.response && error.response.data.data.message
				? error.response.data.data.message
				: error.message;
		if (message === "Not authorized, token failed") {
			localStorage.removeItem("persist:root");
			dispatch(logout());
		}
		dispatch(UpdatePasswordFailure(message));
	}
};

export const getSpecialities = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(specialityGetRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/booking/getspeciality`,

			config
		);
		console.log(data);
		dispatch(specialityGetSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			specialityGetFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getTimeslots = (id) => async (dispatch) => {
	try {
		console.log("11223344");
		console.log(id + "line505");
		dispatch(TimeSlotGetRequest());
		const username = localStorage.getItem("username");
		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		console.log(config);
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/booking/getTimeSlots/${id}`,
			config
		);
		console.log(data.data.data);
		dispatch(TimeSlotGetSuccess(data.data.data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			TimeSlotGetFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getDoctorFromSpeciality = (speciality) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getDoctorfromSpecialityRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		//speciality = 'ortho';
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/booking/getDoctorfromSpeciality/${speciality}`,
			config
		);
		var p = {
			status: data.status,
			data: [data.data],
		};

		dispatch(getDoctorfromSpecialitySuccess(p));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getDoctorfromSpecialityFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getUserReview = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(UserReviewGetRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/reviews/getmy`,

			config
		);

		console.log(data);
		dispatch(UserReviewGetSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			UserReviewGetFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const createBooking =
	(
		name,
		userIdx,
		doctorName,
		specialityName,
		speciality,
		doctor,
		scheduledDate,
		slot,
		slotCount,
		dayIdx,
		userPhone,
		userId
	) =>
	async (dispatch) => {
		try {
			console.log(
				name,
				userIdx,
				doctorName,
				specialityName,
				speciality,
				doctor,
				scheduledDate,
				slot,
				slotCount,
				dayIdx,
				userPhone,
				userId
			);
			dispatch(createBookingStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const data = await axios.post(
				`${SERVER_URL}/api/v1/booking`,
				{
					name: name,
					userIdx: userIdx,
					specialityName: specialityName,
					doctorName: doctorName,
					specialityId: speciality,
					doctor: doctor,
					scheduledDate: scheduledDate,
					slot: slot,
					slotCount: slotCount,
					dayIdx: dayIdx + 1,
					userPhone: userPhone,
					username: username,
					userId: userId,
				},
				config
			);

			console.log(data + "review123");
			dispatch(createBookingSuccess(data));
			dispatch(allMessagesSuccess("Booking created Successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			const message =
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message;
			if (message === "Not authorized, token failed") {
				localStorage.removeItem("persist:root");
				dispatch(logout());
			}
			dispatch(createBookingFailure(message));
		}
	};

export const getUserBooking = (ids) => async (dispatch) => {
	try {
		console.log(ids + "    11223344");
		dispatch(UserBookingGetRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/booking/getUserBookings/${username}`,
			{ ids },
			config
		);

		console.log(data + "56777777");
		dispatch(UserBookingGetSuccess(data));
	} catch (error) {
		// dispatch(
		// 	allMessagesFailure(
		// 		error.response && error.response.data.data.message
		// 			? error.response.data.data.message
		// 			: error.message
		// 	)
		// );
		dispatch(
			UserBookingGetFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const deleteBooking = (id) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(deleteBookingStart());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const data = await axios.delete(
			`${SERVER_URL}/api/v1/booking/${id}/${username}`,
			config
		);

		console.log(data);
		dispatch(deleteBookingSuccess(data));
		dispatch(allMessagesSuccess("Booking deleted successfully"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			deleteBookingFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const updateReview = (id) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(reviewUpdateRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.update(
			`${SERVER_URL}/api/v1/reviews/${id}`,
			config
		);

		console.log(data);
		dispatch(reviewUpdateSuccess(data));
		dispatch(
			allMessagesSuccess(
				"Review updated successfully. Review will be verified by admin"
			)
		);
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			reviewUpdateFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const createCategory =
	(userId, category, name, userIdx) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(createCategoryStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/prescription/createCategory`,
				{ userId, category, name, userIdx },
				config
			);
			dispatch(createCategorySuccess(data));
			dispatch(allMessagesSuccess("Category added Successfully "));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error);
			dispatch(
				createCategoryFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};
