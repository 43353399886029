/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getHistoryByIdx = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getHistoryByIdxStart: (state) => {
			state.loading = true;
		},
		getHistoryByIdxSuccess: (state, action) => {
			state.loading = false;
			state.getHistoryByIdx = action.payload;
			state.error = false;
			state.success = true;
		},
		getHistoryByIdxFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getHistoryByIdxReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getHistoryByIdxStart,
	getHistoryByIdxSuccess,
	getHistoryByIdxFailure,
	getHistoryByIdxReset,
} = getHistoryByIdx.actions;
export default getHistoryByIdx.reducer;
