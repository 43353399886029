/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getRecentHistoryForDoctor = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getRecentHistoryForDoctorStart: (state) => {
			state.loading = true;
		},
		getRecentHistoryForDoctorSuccess: (state, action) => {
			state.loading = false;
			state.getRecentHistoryForDoctor = action.payload;
			state.error = false;
			state.success = true;
		},
		getRecentHistoryForDoctorFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getRecentHistoryForDoctorReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getRecentHistoryForDoctorStart,
	getRecentHistoryForDoctorSuccess,
	getRecentHistoryForDoctorFailure,
	getRecentHistoryForDoctorReset,
} = getRecentHistoryForDoctor.actions;
export default getRecentHistoryForDoctor.reducer;
