import { createSlice } from "@reduxjs/toolkit";

const deleteDoctorReducer = createSlice({
  name: "deleteDoctor",
  initialState: {},
  reducers: {
    deleteDoctorRequest: (state) => {
      state.loading = true;
    },
    deleteDoctorSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.deleteDoctor = action.payload;
      state.error = false;
      state.success = true;
    },
    deleteDoctorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteDoctorReset: (state) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  deleteDoctorRequest,
  deleteDoctorSuccess,
  deleteDoctorFailure,
  deleteDoctorReset,
} = deleteDoctorReducer.actions;
export default deleteDoctorReducer.reducer;
