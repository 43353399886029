/** @format */

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const doctors = [
	// {
	// 	name: 'DR. K. SARITHA REDDY',
	// 	specialty: 'OBSTETRICS AND GYNAECOLOGY SPECIALIST',
	// 	qualifications: 'MBBS, DGO',
	// },
	{
		name: 'Dr.PANCHAKSHARI.M',
		specialty: 'GENETIC COUNCILOR, SONOLOGIST',
		qualifications: 'MD ANATOMY',
	},
	{
		name: 'Dr.AWAIS GHORI',
		specialty: 'MS GEN SURGERY',
		qualifications: 'MS GEN SURGERY',
	},
	{
		name: 'Dr.NAGESH',
		specialty: 'ANESTHESIA CARE',
		qualifications: 'ANESTHESIOLOGIST',
	},
];

const Doctors = () => {
	return (
		<section className='doctors-section'>
			<Container>
				<h2 className='text-center mb-5'>Meet Our Doctors</h2>
				<p>
					Dr. Saritha Reddy, a highly accomplished medical professional with an
					MBBS and DGO, has brought her extensive expertise and passion to the
					forefront. Specializing in high-risk pregnancies, she is deeply
					invested in fetal medicine and fetal ultrasound, areas where her
					knowledge and skills have been instrumental in improving patient
					outcomes. Her commitment to excellence extends to the field of
					infertility treatment, where she has achieved numerous accolades and
					made significant advancements. Dr. Saritha Reddy's approach to
					healthcare is marked by a blend of scientific rigor and heartfelt
					compassion, qualities that resonate with the hospital's ethos. Her
					work in fetal medicine, in particular, has been groundbreaking,
					offering hope and advanced care to countless expectant mothers facing
					challenging pregnancies. Alongside her, Karangula Dinesh Reddy has
					played a pivotal role in the administrative and operational aspects of
					the hospital, ensuring that it remains a beacon of quality healthcare.
					Under their dedicated leadership, Dr. K. ANJAL REDDY MEMORIAL HOSPITAL
					continues to build on its storied past while embracing the future with
					innovative medical practices and technologies. The institution remains
					steadfast in its commitment to providing top-tier healthcare services,
					focusing on patient-centric care that emphasizes empathy, precision,
					and continuous improvement. The hospital's environment fosters a
					culture of excellence and collaboration, ensuring that every patient
					receives the best possible care. The story of DR. K. ANJAL REDDY
					MEMORIAL HOSPITAL is not just one of medical achievement but also of
					enduring legacy, compassion, and a relentless pursuit of better
					healthcare outcomes. As Karangula Dinesh Reddy and Dr. Saritha Reddy
					steer the hospital into a new era, they honor the foundational values
					instilled by Dr. Anjal Reddy while also infusing their own vision and
					innovations. Their work ensures that Dr. K. ANJAL REDDY MEMORIAL
					HOSPITAL remains a trusted institution, where the community can always
					find exceptional care and support.
				</p>
				<Row className='justify-content-center'>
					<Col lg={3} key={0} className='mb-4'>
						<Card className='doctor-card text-center'>
							<Card.Body>
								<div className='doctor-icon-1 mb-3'></div>

								<Card.Title>DR. K. SARITHA REDDY</Card.Title>
								<Card.Text>
									<b>SPECIALITY : OBSTETRICS AND GYNAECOLOGY SPECIALIST</b>
									{}
								</Card.Text>
								<Card.Text>
									<b>QUALIFICATION : MBBS, DGO</b>
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					{doctors.map((doctor, index) => (
						<Col lg={3} key={index} className='mb-4'>
							<Card className='doctor-card text-center'>
								<Card.Body>
									<div className='doctor-icon mb-3'></div>
									<Card.Title>{doctor.name}</Card.Title>
									<Card.Text>
										<b>SPECIALITY : </b>
										{doctor.specialty}
									</Card.Text>
									<Card.Text>
										<b>QUALIFICATION : </b>
										{doctor.qualifications}
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
			</Container>
		</section>
	);
};

export default Doctors;
