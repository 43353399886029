/** @format */

import { createSlice } from "@reduxjs/toolkit";

const allMessagesReducer = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		allMessagesStart: (state) => {
			state.loading = true;
		},
		allMessagesSuccess: (state, action) => {
			state.loading = false;
			state.alertMsg = action.payload;
			state.error = false;
			state.success = true;
		},
		allMessagesFailure: (state, action) => {
			state.loading = false;
			state.alertMsg = action.payload;
			state.error = true;
			state.success = false;
		},
		allMessagesReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} = allMessagesReducer.actions;
export default allMessagesReducer.reducer;
