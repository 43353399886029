import { createSlice } from "@reduxjs/toolkit";

const createLabTestReducer = createSlice({
	name: "createLabTest",
	initialState: {},
	reducers: {
		createLabTestRequest: (state) => {
			state.loading = true;
		},
		createLabTestSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.createLabTest = action.payload;
			state.error = false;
			state.success = true;
		},
		createLabTestFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createLabTestReset: (state) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createLabTestRequest,
	createLabTestSuccess,
	createLabTestFailure,
	createLabTestReset,
} = createLabTestReducer.actions;
export default createLabTestReducer.reducer;
