/** @format */

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userLoginRedux from "./userLogin";
import userRegisterRedux from "./userRegister";
import productReviewReducer from "./userReview";
import ProductReviewGetReducer from "./getReviews";
import userUpdateReducer from "./userUpdate";
import UpdatePasswordReducer from "./updatePassword";
import createSpecialityReducer from "./adminRedux/Speciality_Management/createSpeciality";
import getSpecialityReducer from "./adminRedux/Speciality_Management/getSpeciality";
import createReviewReducer from "./adminRedux/Review_Management/createReview";
import getReviewReducer from "./adminRedux/Review_Management/getReviews";
import adminLoginReducer from "./adminRedux/Admin_MyProfile/VerifyCode";
import adminLogin2Reducer from "./adminRedux/Admin_MyProfile/adminLogin";
import createDoctorReducer from "./adminRedux/Doctor_Management/createDoctor";
import ViewReportReducer from "./adminRedux/Lab_Report_Management/ViewReport";
import getLabTechReducer from "./adminRedux/Lab_Management/Lab_Technician_Management/getLabTechniciansFromParticularDepartment";
import getLabTechnicianReducer from "./adminRedux/Lab_Management/Lab_Technician_Management/getLabTechnician";
import getDoctorfromSpecialityReducer from "./getDoctorfromSpeciality";
import UserReviewGetReducer from "./getUserReview";
import TimeSlotGetReducer from "./getTimeSlots";
import createBookingReducer from "./createBooking";
import getPatientBookingReducer from "./getPatientBooking";
import doctorBookingGetReducer from "./adminRedux/Booking_Management/getBooking";
import getUserdataReducer from "./getUser";
import deleteBookingReducer from "./deleteBooking";
import changeVisitedReducer from "./adminRedux/Booking_Management/changeVisited";
import createReferralDoctorReducer from "./adminRedux/Doctor_Management/createReferralDoctor";
import updateReviewReducer from "./updateReview";
import changeOnHoldReducer from "./adminRedux/Booking_Management/changeOnHold";
import changeSlotReducer from "./adminRedux/Booking_Management/changeSlot";
import adminCreateBookingReducer from "./adminRedux/Booking_Management/adminCreateBooking";
import getUserDataByCategoryReducer from "./adminRedux/Patient_Management/getUserDataByCategory";
import DoctorGetMyAppointmentsReducer from "./doctorRedux/Appointment_Management/getMyAppointments";
import DoctorGetMyTimeSlotsReducer from "./doctorRedux/Slot_Management/getMyTimeslots";
import getUserDataInSearchPatientReducer from "./adminRedux/Patient_Management/getUserDataInSearchPatients";
import DeleteLabTestReducer from "./adminRedux/Lab_Management/Test_Management/deleteLabTest";
import DeleteLabTechReducer from "./adminRedux/Lab_Management/Lab_Technician_Management/deleteLabTech";
import UpdatePasswordReducerAdmin from "./adminRedux/Admin_MyProfile/UpdatePassword";
import doctorLoginReducer from "./doctorRedux/MyProfile/VerifyCode";
import doctorLogin2Reducer from "./doctorRedux/MyProfile/doctorLogin";
import createCategoryReducer from "./createCategory";
import paymentStatusvisitedPatientsReducer from "./updatePaymentStatusinVisitedPatient";
import createPrescriptionReducer from "./prescriptionRedux/createPrescription";
import onSpotPrescriptionReducer from "./prescriptionRedux/onSpotPrescription";
import createPrescriptionTemplateReducer from "./prescriptionRedux/createPrescriptionTemplate";
import editPrescriptionReducer from "./prescriptionRedux/editPrescription";
import getCategoriesVitalsReducer from "./prescriptionRedux/getCategoriesVitals";
import viewPatientHistoryReducer from "./prescriptionRedux/viewPatientHistory";
import getUserDataByIdReducer from "./prescriptionRedux/getUserDataById";
import getPrescriptionReducer from "./prescriptionRedux/getPrescription";
import paymentStatusReducer from "./paymentStatus";
import getTemplateNamesReducer from "./doctorRedux/Template_Management/getTemplateNames";
import getTemplateReducer from "./doctorRedux/Template_Management/getTemplate";
import createTemplateReducer from "./doctorRedux/Template_Management/createTemplate";
import updateTemplateReducer from "./doctorRedux/Template_Management/updateTemplate";
import deleteTemplateReducer from "./doctorRedux/Template_Management/deleteTemplate";
import updateDoctorPasswordReducer from "./doctorRedux/MyProfile/updatePassword";
import getLeavesReducer from "./doctorRedux/Leave_Management/getLeaves";
import prescriptionCreatorReducer from "./doctorRedux/Prescription_Management/prescriptionCreator";
import editPrescriptionTemplateInSpecialityReducer from "./prescriptionRedux/editHistoryTemplateInSpeciality";
import GetPrescriptionTemplateNamesReducer from "./doctorRedux/Prescription_Management/GetPrescriptionTemplateNames";
import GetPrescriptionTemplateReducer from "./doctorRedux/Prescription_Management/GetPrescriptionTemplate";
import deleteLeavesReducer from "./doctorRedux/Leave_Management/deleteLeave";
import createLeavesReducer from "./doctorRedux/Leave_Management/createLeave";
import getPrescriptionByCategoryReducer from "./userRedux/getPrescriptionByCategory";
import getPrescriptionIdsofCategoryReducer from "./userRedux/getPrescriptionIdsofCategory";
import getPrescriptionByDateReducer from "./userRedux/getPrescriptionByDate";
import deleteDoctorReducer from "./adminRedux/Doctor_Management/deleteDoctor";
import deleteLabDeptReducer from "./adminRedux/Lab_Management/Lab_Department_Management/deleteLabDept";
import createLabDeptReducer from "./adminRedux/Lab_Management/Lab_Department_Management/createDept";
import getLabDeptReducer from "./adminRedux/Lab_Management/Lab_Department_Management/getLabDepts";
import createLabReportDetails from "./adminRedux/Lab_Management/Lab_Technician_Management/addLabReportDetails";
import editTestStatusForPatientsInQueue from "./labRedux/Lab_Technician_Management/editTestStatusForPatientsInQueue";
import editTestNotesForPatientsInQueue from "./labRedux/Lab_Technician_Management/editTestNotesForPatientsInQueue";
import getTestNamesReducer from "./adminRedux/Lab_Management/Test_Management/getTestNames";
import editTestReducer from "./adminRedux/Lab_Management/Test_Management/editLabTest";
import getTestTemplateReducer from "./labRedux/Lab_Template_Management/GetTestTemplate";
import deleteTestTemplateReducer from "./labRedux/Lab_Template_Management/DeleteTestTemplate";
import editTestTemplateReducer from "./labRedux/Lab_Template_Management/EditTestTemplate";
import createReportReducer from "./labRedux/Lab_Technician_Management/createReport";
import getScheduledLabTestsReducer from "./receptionRedux/getScheduledLabTests";
import getPatientsInQueueForLabTechnicianReducer from "./labRedux/Lab_Department_Management/getPatientsInQueueForLabTechnician";
import resendOtpDoctorReducer from "./doctorRedux/MyProfile/resendOtp";
import resendOtpAdminReducer from "./adminRedux/Admin_MyProfile/resendOtp";
import labTechLoginReducer from "./labRedux/Lab_Technician_Management/VerifyCode";
import labTechLogin2Reducer from "./labRedux/Lab_Technician_Management/labTechLogin";
import getLabVisitedPatientsReducer from "./labRedux/Lab_Technician_Management/getLabVisitedPatients";
import getAllLabTechniciansReducer from "./adminRedux/Lab_Management/Lab_Technician_Management/getAllLabTechnicians";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import getLeavesForAdminReducer from "./doctorRedux/Leave_Management/getLeaves";
import getRegisteredPatientsReducer from "./userRedux/getRegisteredPatients";
import getDoctorVisitedPatientsforParticularDateReducer from "./adminRedux/Doctor_Management/getDoctorVisitedPatientsforParticularDate";
import editUserReducer from "./adminRedux/Patient_Management/userEdit";
import allMessagesReducer from "./allMessages";

//Reception Redux
import AddScheduledLabTestsToLabDeptReducer from "./receptionRedux/AddScheduledLabTestsToLabDept";
import createBookingReceptionReducer from "./receptionRedux/createBookingReception";
import receptionLoginReducer from "./receptionRedux/receptionLogin";
import receptionforgotPasswordReducer from "./receptionRedux/forgotPassword";
import receptionresendOtpReducer from "./receptionRedux/resendOtp";
import receptionresetPasswordReducer from "./receptionRedux/resetPassword";
import receptionupdatePasswordReducer from "./receptionRedux/updatePassword";
import receptionVerifyCodeReducer from "./receptionRedux/VerifyCode";
import deleteReceptionReducer from "./adminRedux/Reception_Management/deleteReception";
import editReceptionReducer from "./receptionRedux/editReception";
import addVitalInSpecialityReducer from "./adminRedux/Speciality_Management/addVitalInSpeciality";
import getVitalInSpecialityReducer from "./adminRedux/Speciality_Management/getVitalsInSpeciality";
//block slots
import blockSlotsReducer from "./doctorRedux/Slot_Management/blockSlots";
import unBlockSlotsReducer from "./doctorRedux/Slot_Management/unBlockSlots";

import getAllReceptionsReducer from "./adminRedux/Reception_Management/getAllReceptions";
import scheduleAppointmentDateByDoctorReducer from "./prescriptionRedux/scheduleAppointmentDateByDoctor";
import getScheduleAppointmentsForNotificationToDoctorReceptionReducer from "./prescriptionRedux/getScheduleAppointmentsForNotificationToDoctorReception";
import getuserByIdForPrescriptionReducer from "./prescriptionRedux/getuserByIdForPrescription";
import rescheduleAppointmentForPatientinBlockedSlotReducer from "./receptionRedux/rescheduleAppointmentForPatientinBlockedSlot";
import getPatientsInBlockedSlotReducer from "./receptionRedux/getPatientsInBlockedSlot";
import getBlockedSlotsDatesReducer from "./receptionRedux/getBlockedSlotsDates";
import AddLabTestsToLabDeptForReferralDoctorReducer from "./receptionRedux/AddLabTestsToLabDeptForReferralDoctor";

import createLabTestReducer from "./adminRedux/Lab_Management/Test_Management/createLabTest";

import getReferralDoctorRevenueReducer from "./statisticsRedux/getReferralDoctorRevenue";
import getLabStatisticsReducer from "./statisticsRedux/LabDeptRedux/getLabStatistics";
import todaysLabPatientsStatisticsReducer from "./statisticsRedux/LabDeptRedux/todaysLabPatientsStatistics";
import getStatisticsofParticularTestReducer from "./statisticsRedux/LabDeptRedux/getStatisticsofParticularTest";
import getTestStatisticsForParticularYearReducer from "./statisticsRedux/LabDeptRedux/getTestStatisticsForParticularYear";
import getDepartmentandTestStatisticsReducer from "./statisticsRedux/LabDeptRedux/getDepartmentandTestStatistics";
import getAllLabDepartmentStatisticsForParticularYearReducer from "./statisticsRedux/LabDeptRedux/getAllLabDepartmentStatisticsForParticularYear";
import getHospitalandSpecialityStatisticsReducer from "./statisticsRedux/SpecialityRedux/getHospitalandSpecialityStatistics";
import getSpecialityandDoctorStatisticsReducer from "./statisticsRedux/SpecialityRedux/getSpecialityandDoctorStatistics";
import getStatisticsofParticularDoctorReducer from "./statisticsRedux/SpecialityRedux/getStatisticsofParticularDoctor";
import todaysVisitedPatientsStatisticsReducer from "./statisticsRedux/SpecialityRedux/todaysVisitedPatientsStatistics";
import editBloodGroupReducer from "./userRedux/editBloodGroup";
import GetPrescriptionTemplate from "./doctorRedux/Prescription_Management/GetPrescriptionTemplate";

////////////////////////////////////////////History Redux///////////////////////////////////////
import createHistoryTemplateInSpecialityReducer from "./historyRedux/History_Management/createHistoryTemplateInSpeciality";
import editHistoryByIdxReducer from "./historyRedux/History_Management/editHistoryByIdx";
import editHistoryTemplateInSpecialityReducer from "./historyRedux/History_Management/editHistoryTemplateInSpeciality";
import getHistoryByIdxReducer from "./historyRedux/History_Management/getHistoryByIdx";
import getHistoryTemplateInSpecialityReducer from "./historyRedux/History_Management/getHistoryTemplateInSpeciality";
import getRecentHistoryForDoctorReducer from "./historyRedux/History_Management/getRecentHistoryForDoctor";
import createHistoryForPatientReducer from "./historyRedux/History_Management/createHistoryForPatient";

//////Case Redux/////////////////////
import createCaseInPatientReducer from "./historyRedux/Patient_Case_Management/createCaseInPatient";
import editPatientCaseReducer from "./historyRedux/Patient_Case_Management/editPatientCase";
import getCaseInPatientReducer from "./historyRedux/Patient_Case_Management/getCaseInPatient";

import StartPrescriptionToStartHistoryReducer from "./UINavigationRedux/StartPrescriptionToStartHistory";

import createInventoryCategoryReducer from "./inventoryRedux/CategoryRedux/createInventoryCategory";
import getInventoryCategoryReducer from "./inventoryRedux/CategoryRedux/getInventoryCategory";
import createInventoryItemReducer from "./inventoryRedux/InventoryItem/createInventoryItem";
import getInventoryItemsForBillingReducer from "./inventoryRedux/InventoryItem/getInventoryItemsForBilling";
import editInventoryItemReducer from "./inventoryRedux/InventoryItem/editInventoryItem";
import getSuppliersReducer from "./inventoryRedux/SupplierRedux/getSuppliers";
import getPurchaseByIdReducer from "./inventoryRedux/SupplierRedux/getPurchaseById";
import getAllPurchasesReducer from "./inventoryRedux/SupplierRedux/getAllPurchases";
import getBillReducer from "./inventoryRedux/InventoryItem/getBill";
import billReducer from "./adminRedux/Bill_Management/bill";
import editInventoryCategoryReducer from "./inventoryRedux/CategoryRedux/editInventoryCategory";
import inventoryInfoReducer from "./adminRedux/Admin_MyProfile/inventoryInfo";

////////////////////////////////////////////Ip billing Redux///////////////////////////////////////
import getAllServicesReducer from "./adminRedux/IpBill_Management/getAllService";
import getAllAdmittedPatientsReducer from "./adminRedux/IpBill_Management/getAllAdmittedPatients";
import addServiceReducer from "./adminRedux/IpBill_Management/addService";
import createIpBillReducer from "./adminRedux/IpBill_Management/createIpBill";
import saveIpBillReducer from "./adminRedux/IpBill_Management/saveIpBill";
import closeIpBillReducer from "./adminRedux/IpBill_Management/closeIpBill";
import getIpBillReducer from "./adminRedux/IpBill_Management/getIpBill";
import getConsultantDoctorFeesReducer from "./adminRedux/Doctor_Management/getConsultantDoctorFees";
import getAllRecommendedWordsReducer from "./doctorRedux/Prescription_Management/getAllRecommendedWords";
import recommendationDataReducer from "./doctorRedux/Prescription_Management/recommendationData";
import createSupplierReducer from "./inventoryRedux/SupplierRedux/createSupplier";
import newPurchaseFromSupplierReducer from "./inventoryRedux/SupplierRedux/newPurchaseFromSupplier";
import getTodaysRevenuesReducer from "./inventoryRedux/InventoryRevenues/getTodaysRevenues";
import getPastCategoryRevenuesReducer from "./inventoryRedux/InventoryRevenues/getPastCategoryRevenues";
import getCustomRevenuesOftheHospitalReducer from "./statisticsRedux/getCustomRevenuesOftheHospital";
const persistConfig = {
	key: "root",
	version: 1,
	storage,
};

const rootReducer = combineReducers({
	//reception Functions
	createBookingReception: createBookingReceptionReducer,
	AddScheduledLabTestsToLabDept: AddScheduledLabTestsToLabDeptReducer,
	receptionLogin: receptionVerifyCodeReducer,
	receptionLogin2: receptionLoginReducer,
	receptionforgotPassword: receptionforgotPasswordReducer,
	receptionresendOtp: receptionresendOtpReducer,
	receptionresetPassword: receptionresetPasswordReducer,
	receptionupdatePassword: receptionupdatePasswordReducer,
	receptionVerifyCode: receptionVerifyCodeReducer,
	deleteReception: deleteReceptionReducer,
	receptionupdartePassword: receptionupdatePasswordReducer,
	editReception: editReceptionReducer,
	getAllReception: getAllReceptionsReducer,
	rescheduleAppointmentForPatientinBlockedSlot:
		rescheduleAppointmentForPatientinBlockedSlotReducer,
	getPatientsInBlockedSlots: getPatientsInBlockedSlotReducer,
	getBlockedSlotsdates: getBlockedSlotsDatesReducer,
	AddLabTestsToLabDeptForReferralDoc:
		AddLabTestsToLabDeptForReferralDoctorReducer,
	//doctor functions
	resendOtpDoctor: resendOtpDoctorReducer,
	updateDoctorPassword: updateDoctorPasswordReducer,
	paymentStatusvisitedPatients: paymentStatusvisitedPatientsReducer,
	//leaves management
	getLeaves: getLeavesReducer,
	prescriptionCreator: prescriptionCreatorReducer,
	GetPrescriptionTemplate: GetPrescriptionTemplateReducer,
	GetPrescriptionTemplateNames: GetPrescriptionTemplateNamesReducer,
	deleteLeaves: deleteLeavesReducer,
	createLeaves: createLeavesReducer,
	blockSlots: blockSlotsReducer,
	unBlockSlots: unBlockSlotsReducer,
	//Template management
	getTemplateNames: getTemplateNamesReducer,
	getTemplate: getTemplateReducer,
	createTemplate: createTemplateReducer,
	updateTemplate: updateTemplateReducer,
	deleteTemplate: deleteTemplateReducer,
	//prescritpion management
	createCategory: createCategoryReducer,
	getPrescriptionByCategory: getPrescriptionByCategoryReducer,
	getPrescriptionByDate: getPrescriptionByDateReducer,
	getPrescriptionIdsofCategory: getPrescriptionIdsofCategoryReducer,
	createPrescription: createPrescriptionReducer,
	onSpotPrescription: onSpotPrescriptionReducer,
	editPrescription: editPrescriptionReducer,
	getCategoriesVitals: getCategoriesVitalsReducer,
	scheduleAppointmentDateByDoctor: scheduleAppointmentDateByDoctorReducer,
	getScheduleAppointmentsForNotificationToDoctorReception:
		getScheduleAppointmentsForNotificationToDoctorReceptionReducer,
	getuserByIdForPrescription: getuserByIdForPrescriptionReducer,
	//doctor
	doctorLogin: doctorLoginReducer,
	doctorLogin2: doctorLogin2Reducer,
	getUserdataByCategory: getUserDataByCategoryReducer,
	DoctorGetMyAppointments: DoctorGetMyAppointmentsReducer,
	DoctorGetMyTimeSlots: DoctorGetMyTimeSlotsReducer,
	getDoctorBooking: doctorBookingGetReducer,

	//user Functions
	userLogin: userLoginRedux,
	userRegister: userRegisterRedux,
	userReviewCreate: productReviewReducer,
	userReviewsAll: ProductReviewGetReducer,
	userUpdate: userUpdateReducer,
	UpdatePassword: UpdatePasswordReducer,
	getRegisteredPatients: getRegisteredPatientsReducer,
	updateReview: updateReviewReducer,
	deleteBooking: deleteBookingReducer,
	deleteLabTest: DeleteLabTestReducer,
	deleteLabTech: DeleteLabTechReducer,
	updateAdminPassword: UpdatePasswordReducerAdmin,
	userReview: UserReviewGetReducer,
	getUser: getUserdataReducer,
	getPatientBooking: getPatientBookingReducer,
	editBloodGroup: editBloodGroupReducer,
	//admin fucntions
	paymentStatus: paymentStatusReducer,
	resendOtpAdmin: resendOtpAdminReducer,
	createSpeciality: createSpecialityReducer,
	createLabDept: createLabDeptReducer,
	getLabDept: getLabDeptReducer,
	getTestNamesOfReport: getTestNamesReducer,
	editTestOfReport: editTestReducer,
	getTemplateNamesOfReport: getTestTemplateReducer,
	deleteTestTemplate: deleteTestTemplateReducer,
	editTestTemplate: editTestTemplateReducer,
	createLabreport: createReportReducer,
	getScheduledLabTest: getScheduledLabTestsReducer,
	getPatientsInQueue: getPatientsInQueueForLabTechnicianReducer,
	createLabReportDetails: createLabReportDetails,
	editTestStatusForPatientsInQueue: editTestStatusForPatientsInQueue,
	editTestNotesForPatientsInQueue: editTestNotesForPatientsInQueue,
	getSpeciality: getSpecialityReducer,
	adminReviewCreate: createReviewReducer,
	adminReviewGet: getReviewReducer,
	adminLogin: adminLoginReducer,
	createDoctor: createDoctorReducer,
	viewReport: ViewReportReducer,
	getLabTech: getLabTechReducer,
	getLabTechInfo: getLabTechnicianReducer,
	getLeavesForAdmin: getLeavesForAdminReducer,
	deleteDoctor: deleteDoctorReducer,
	deleteLabDept: deleteLabDeptReducer,
	getUserDataInSearchPatient: getUserDataInSearchPatientReducer,
	changeOnHold: changeOnHoldReducer,
	changeSlot: changeSlotReducer,
	adminCreateBooking: adminCreateBookingReducer,
	changeVisited: changeVisitedReducer,
	createReferralDoc: createReferralDoctorReducer,
	getDoctorVisitedPatientsforParticularDate:
		getDoctorVisitedPatientsforParticularDateReducer,
	editUser: editUserReducer,
	adminLogin2: adminLogin2Reducer,
	addVitalInSpeciality: addVitalInSpecialityReducer,
	getVitalInSpeciality: getVitalInSpecialityReducer,
	//common functions
	getUserDataById: getUserDataByIdReducer,
	viewPatientHistory: viewPatientHistoryReducer,
	getPrescription: getPrescriptionReducer,
	getDoctor: getDoctorfromSpecialityReducer,
	getTimeSlots: TimeSlotGetReducer,
	createBooking: createBookingReducer,

	//lab functions
	labTechLogin: labTechLoginReducer,
	labTechLogin2: labTechLogin2Reducer,
	getLabVisitedPatients: getLabVisitedPatientsReducer,
	getAllLabTechnicians: getAllLabTechniciansReducer,
	createLabTest: createLabTestReducer,
	allMessages: allMessagesReducer,

	//statistics functions
	getReferralDoctorRevenue: getReferralDoctorRevenueReducer,
	getLabStatistics: getLabStatisticsReducer,
	todaysLabPatientsStatistics: todaysLabPatientsStatisticsReducer,
	getStatisticsofParticularTest: getStatisticsofParticularTestReducer,
	getTestStatisticsForParticularYear: getTestStatisticsForParticularYearReducer,
	getDepartmentandTestStatistics: getDepartmentandTestStatisticsReducer,
	getAllLabDepartmentStatisticsForParticularYear:
		getAllLabDepartmentStatisticsForParticularYearReducer,
	getHospitalandSpecialityStatistics: getHospitalandSpecialityStatisticsReducer,
	getSpecialityandDoctorStatistics: getSpecialityandDoctorStatisticsReducer,
	getStatisticsofParticularDoctor: getStatisticsofParticularDoctorReducer,
	todaysVisitedPatientsStatistics: todaysVisitedPatientsStatisticsReducer,
	getCustomRevenuesOftheHospital: getCustomRevenuesOftheHospitalReducer,

	//History Reducer functions
	createHistoryTemplateInSpeciality: createHistoryTemplateInSpecialityReducer,
	editHistoryByIdx: editHistoryByIdxReducer,
	editHistoryTemplateInSpeciality: editHistoryTemplateInSpecialityReducer,
	getHistoryByIdx: getHistoryByIdxReducer,
	getHistoryTemplateInSpeciality: getHistoryTemplateInSpecialityReducer,
	getRecentHistoryForDoctor: getRecentHistoryForDoctorReducer,
	createHistoryForPatient: createHistoryForPatientReducer,
	createCaseInPatient: createCaseInPatientReducer,
	editPatientCase: editPatientCaseReducer,
	getCaseInPatient: getCaseInPatientReducer,
	StartPrescriptionToStartHistory: StartPrescriptionToStartHistoryReducer,

	getInventoryCategory: getInventoryCategoryReducer,
	createInventoryCategory: createInventoryCategoryReducer,
	createInventoryItem: createInventoryItemReducer,
	getInventoryItemsForBilling: getInventoryItemsForBillingReducer,
	editInventoryItem: editInventoryItemReducer,
	getSuppliers: getSuppliersReducer,
	getPurchaseById: getPurchaseByIdReducer,
	getAllPurchases: getAllPurchasesReducer,
	getBill: getBillReducer,
	billData: billReducer,
	//ip reducers
	getServices: getAllServicesReducer,
	getAllPatients: getAllAdmittedPatientsReducer,
	addService: addServiceReducer,
	createIpBill: createIpBillReducer,
	closeIpBill: closeIpBillReducer,
	saveIpBill: saveIpBillReducer,
	getIpBill: getIpBillReducer,
	getAllRecommendedWords: getAllRecommendedWordsReducer,
	getConsultantDoctorFees: getConsultantDoctorFeesReducer,
	editInventoryCategory: editInventoryCategoryReducer,
	inventoryInfo: inventoryInfoReducer,
	recommendationData: recommendationDataReducer,
	createSupplier: createSupplierReducer,
	newPurchaseFromSupplier: newPurchaseFromSupplierReducer,
	getTodaysRevenues: getTodaysRevenuesReducer,
	getPastCategoryRevenues: getPastCategoryRevenuesReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const userInfoFromStorage = localStorage.getItem("userInfo")
	? JSON.parse(localStorage.getItem("userInfo"))
	: null;
const adminInfoFromStorage = localStorage.getItem("adminInfo")
	? JSON.parse(localStorage.getItem("adminInfo"))
	: null;
const doctorInfoFromStorage = localStorage.getItem("doctorInfo")
	? JSON.parse(localStorage.getItem("doctorInfo"))
	: null;

const receptionInfoFromStorage = localStorage.getItem("receptionInfo")
	? JSON.parse(localStorage.getItem("receptionInfo"))
	: null;

const labTechItemsFromStorage = localStorage.getItem("labTechInfo")
	? JSON.parse(localStorage.getItem("labTechInfo"))
	: null;

const initialState = {
	userLogin: { userInfo: userInfoFromStorage },
	adminLogin: { adminInfo: adminInfoFromStorage },
	doctorLogin: { doctorInfo: doctorInfoFromStorage },
	receptionLogin: { receptionInfo: receptionInfoFromStorage },
	labTechLogin: { labTechInfo: labTechItemsFromStorage },
};
export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
	initialState: initialState,
});

export let persistor = persistStore(store);
