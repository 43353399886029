/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getLabTechnician = createSlice({
	name: 'getLabTechnician',
	initialState: {},
	reducers: {
		getLabTechnicianRequest: (state) => {
			state.loading = true;
		},
		getLabTechnicianSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.getLabTechnicianInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		getLabTechnicianFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getLabTechnicianReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getLabTechnicianRequest,
	getLabTechnicianSuccess,
	getLabTechnicianFailure,
	getLabTechnicianReset,
} = getLabTechnician.actions;
export default getLabTechnician.reducer;
