/** @format */

import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Nav, Navbar, Button, Dropdown, NavDropdown } from "react-bootstrap";
import { FaHome, FaInfoCircle, FaCog } from "react-icons/fa"; // Import some icons
import Collapse from "react-bootstrap/Collapse";
import { useLocation } from "react-router-dom";
import Fuse from "fuse.js";
import { getAllRecommendedWordsArray } from "../redux/doctorRedux/doctorApiCalls";
import { GetrecommendationDataSuccess } from "../redux/doctorRedux/Prescription_Management/recommendationData";
const SideBar = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const userLogin = useSelector((state) => state.userLogin);
	const adminLogin = useSelector((state) => state.adminLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const { labTechInfo } = useSelector((state) => state.labTechLogin);
	const { loading, userInfo } = userLogin;
	const { error, adminInfo } = adminLogin;
	const [allDau, setAllDau] = useState([]);
	const [openItems, setOpenItems] = useState({});
	console.log(labTechInfo);
	let carray = [];
	// console.log(user.userInfo === null ? "yes" : "no");
	const handleThisLogin = (carray) => {
		setAllDau(carray);
	};
	console.log(allDau);
	// console.log(JSON.stringify(receptionInfo));
	console.log("line 19");
	useEffect(() => {
		if (doctorInfo?.data?.user?.name) {
			carray = [
				{
					name: "My Appointments",
					link: "/doctor/patientstobevisited",
					dropdownItems: [],
					icon: "fa fa-clipboard",
				},
				{
					name: "Leaves Management",
					// link: "/doctor/createleaves",
					dropdownItems: [
						{
							name: "Create Leaves",
							link: "/doctor/leavesManagement/createleaves",
							icon: "fa fa-plane", // Updated icon
						},
						{
							name: "Delete Leaves",
							link: "/doctor/leavesManagement/deleteleaves",
							icon: "fa fa-plane-slash", // Updated icon
						},
					],
					icon: "fa fa-plane",
				},
				{
					name: "Slots Management",
					// link: "/doctor/blockslots",
					icon: "far fa-clock", // Updated icon
					dropdownItems: [
						{
							name: "Block Slots",
							link: "/doctor/SlotManagement/blockslots",
							icon: "fas fa-lock", // Updated icon
						},
						{
							name: "UnBlock Slots",
							link: "/doctor/SlotManagement/unblockslots",
							icon: "fas fa-unlock", // Updated icon
						},
						{
							name: "Edit My Slots",
							link: "/doctor/SlotManagement/editMySlots",
							icon: "far fa-edit", // Updated icon
						},
						{
							name: "Edit Booking Period",
							link: "/doctor/SlotManagement/editBookingPeriod",
							icon: "far fa-calendar-alt", // Updated icon
						},
					],
				},
				{
					name: "Medicine Templates",
					dropdownItems: [
						{
							name: "Add Medicine Template",
							link: "/doctor/templateManagement/addTemplates",
							icon: "fa fa-file-alt",
						},
						{
							name: "Edit Medicine Template",
							link: "/doctor/templateManagement/editTemplates",
							icon: "fa fa-edit",
						},
						{
							name: "Delete Medicine Template",
							link: "/doctor/templateManagement/deleteTemplates",
							icon: "fa fa-trash",
						},
					],
					icon: "fa fa-pills",
				},
				{
					name: "Precription Templates",
					dropdownItems: [
						{
							name: "Add Precription Template",
							link: "/doctor/prescritpioncreator",
							icon: "fa fa-file-alt",
						},
						{
							name: "Edit Precription Template",
							link: "/doctor/prescriptioneditor",
							icon: "fa fa-edit",
						},
					],
					icon: "fas fa-prescription",
				},
				{
					name: "History Templates",
					dropdownItems: [
						{
							name: "Add History Template",
							link: "/doctor/historycreator",
							icon: "fa fa-file-alt",
						},
						{
							name: "Edit History Template",
							link: "/doctor/historyeditor",
							icon: "fa fa-edit",
						},
					],
					icon: "fa fa-history",
				},
				{
					name: "Search Patients",
					link: "/doctor/searchPatients",
					dropdownItems: [],
					icon: "fa fa-search",
				},
				{
					name: "Patient Management",
					// link: '/admin/addSpeciality',
					dropdownItems: [
						{
							name: "Register Patient",
							link: "/patientManagement/registerPatient",
							icon: "fa fa-user-plus",
						},
						{
							name: "Edit Patient Info",
							link: "/patientManagement/addnewuser",
							icon: "fa fa-user",
						},
					],
					icon: "fa fa-user",
				},
				{
					name: "My Profile",
					link: "/doctor/myprofile",
					dropdownItems: [],
					icon: "fa fa-user",
				},
			];
			handleThisLogin(carray);
		} else if (adminInfo?.user?.isAdmin) {
			carray = [
				{
					name: "Appointments",
					link: "/admin/patientstobevisited",
					dropdownItems: [],
					icon: "fa fa-clipboard",
				},
				{
					name: "Book Appointments",
					link: "/admin/bookappointment",
					dropdownItems: [],
					icon: "fa fa-calendar-plus",
				},
				{
					name: "Book Lab Test",
					link: "/admin/labbooking",
					dropdownItems: [],
					icon: "fa fa-calendar-plus",
				},
				{
					name: "Sell Inventory Items",
					link: "/sellpharmacy",
					dropdownItems: [],
					icon: "fa fa-tags",
				},
				{
					name: "Ip billing",
					link: "/admittedPatients",
					dropdownItems: [],
					icon: "fas fa-bed",
				},
				{
					name: "Add Speciality",
					link: "/admin/addSpeciality",
					dropdownItems: [],
					icon: "fa fa-file-alt",
				},
				{
					name: "Doctor Management",

					dropdownItems: [
						{
							name: "Add Doctor",
							link: "/admin/addDoctor",
							icon: "fa fa-user-md",
						},
						{
							name: "Add Referral Doctor",
							link: "/admin/addreferraldoctor",
							icon: "fa fa-user-md",
						},
						{
							name: "Doctor Leaves",
							link: "/admin/doctorleaves",
							icon: "fa fa-user-md",
						},
						{
							name: "Delete Doctor",
							link: "/admin/deleteDoctor",
							icon: "fa fa-user-md",
						},
					],
					icon: "fa fa-user-md",
				},
				{
					name: "Reception Management",

					dropdownItems: [
						{
							name: "Add Reception",
							link: "/admin/ReceptionManagement/addReception",
							icon: "fa fa-user-nurse",
						},
						{
							name: "Delete Reception",
							link: "/admin/ReceptionManagement/deleteReception",
							icon: "fa fa-user-nurse",
						},
					],
					icon: "fa fa-user-nurse",
				},
				{
					name: "Lab Dept Management",

					dropdownItems: [
						{
							name: "Add Lab Dept",
							link: "/admin/labDeptManagement/addLabDept",
							icon: "fa fa-hospital-user",
						},
						{
							name: "Delete Lab Dept",
							link: "/admin/labDeptManagement/deleteLabDept",
							icon: "fa fa-hospital-user",
						},
					],
					icon: "fa fa-hospital-user",
				},
				{
					name: "Lab Test Management",

					dropdownItems: [
						{
							name: "Add Lab Test",
							link: "/admin/labTestManagement/addLabTest",
							icon: "fa fa-hospital-user",
						},
						{
							name: "Edit Lab Test",
							link: "/admin/labTestManagement/editLabTest",
							icon: "fa fa-hospital-user",
						},
						{
							name: "Delete Lab Test",
							link: "/admin/labTestManagement/deleteLabTest",
							icon: "fa fa-hospital-user",
						},
					],
					icon: "fa fa-hospital-user",
				},
				// {
				// 	name: "Lab Tech Management",

				// 	dropdownItems: [
				// 		{
				// 			name: "Add Lab Tech",
				// 			link: "/admin/labTechManagement/addLabTech",
				// 			icon: "fa fa-hospital-user",
				// 		},
				// 		{
				// 			name: "Edit Lab Tech",
				// 			link: "/admin/labTechManagement/editLabTech",
				// 			icon: "fa fa-hospital-user",
				// 		},
				// 		{
				// 			name: "Delete Lab Tech",
				// 			link: "/admin/labTechManagement/deleteLabTech",
				// 			icon: "fa fa-hospital-user",
				// 		},
				// 	],
				// 	icon: "fa fa-hospital-user",
				// },
				{
					name: "Patient Management",

					dropdownItems: [
						{
							name: "Register Patient",
							link: "/patientManagement/registerPatient",
							icon: "fa fa-user-plus",
						},
						{
							name: "Edit Patient Info",
							link: "/patientManagement/addnewuser",
							icon: "fa fa-user",
						},
					],
					icon: "fa fa-user",
				},
				{
					name: "Inventory Management",
					dropdownItems: [
						{
							name: "Inventory Dashboard",
							link: "/admin/pharmacydashboard",
							icon: "fa fa-chart-line",
						},
						{
							name: "Category Management",
							link: "/admin/pharmacycategories",
							icon: "fa fa-plus",
						},
						{
							name: "View Inventory",
							link: "/admin/pharmacyaddinventory",
							icon: "fa fa-boxes",
						},
						{
							name: "Purchase Inventory",
							link: "/admin/pharmacypurchaseinventory",
							icon: "fa fa-cart-plus",
						},
						{
							name: "Supplier Management",
							link: "/admin/suppliermanagement",
							icon: "fa fa-user-tag",
						},
					],
					icon: "fas fa-warehouse",
				},
				{
					name: "Feedbacks",
					link: "/admin/feedbacks",
					dropdownItems: [],
					icon: "fa fa-comment",
				},
				{
					name: "Search Patients",
					link: "/admin/searchPatients",
					dropdownItems: [],
					icon: "fa fa-search",
				},
				{
					name: "Revenue Management",

					dropdownItems: [
						{
							name: "Inventory Revenue",
							link: "/admin/AllPharmacySales",
							icon: "fa fa-boxes",
						},
						{
							name: "OP Revenue",
							link: "/admin/hospitalstatistics",
							icon: "fa fa-user-md",
						},
						{
							name: "Lab Revenue",
							link: "/admin/labstatistics",
							icon: "fa fa-flask",
						},
					],
					icon: "fa fa-chart-pie",
				},
			];
			handleThisLogin(carray);
		} else if (userInfo?.data?.user?.name) {
			carray = [
				{
					name: "About Us",
					link: "/",
					dropdownItems: [],
					icon: "fa fa-info-circle",
				},
				{
					name: "My Appointments",
					link: "/viewbookings",
					dropdownItems: [],
					icon: "fa fa-clipboard",
				},
				{
					name: "Book Appointments",
					link: "/booking",
					dropdownItems: [],
					icon: "fa fa-calendar-plus",
				},
				{
					name: "Feedbacks",
					link: "/feedbacks",
					dropdownItems: [],
					icon: "fa fa-comment",
				},
				{
					name: "My Profile",
					link: "/users",
					dropdownItems: [],
					icon: "fa fa-user",
				},
			];
			handleThisLogin(carray);
		} else if (receptionInfo?.data?.user?.name) {
			carray = [
				{
					name: "Appointments",
					link: "/reception/patientstobevisited",
					dropdownItems: [],
					icon: "fa fa-clipboard",
				},
				{
					name: "Book Appointments",
					link: "/reception/booking",
					dropdownItems: [],
					icon: "fa fa-calendar-plus",
				},
				{
					name: "Book Lab Test",
					link: "/reception/labbooking",
					dropdownItems: [],
					icon: "fa fa-calendar-plus",
				},
				{
					name: "Sell Inventory Items",
					link: "/sellpharmacy",
					dropdownItems: [],
					icon: "fa fa-tags",
				},
				{
					name: "Ip billing",
					link: "/admittedPatients",
					dropdownItems: [],
					icon: "fas fa-bed",
				},
				{
					name: "Scheduled Appointments",
					link: "/reception/scheduledAppointments",
					dropdownItems: [],
					icon: "fa fa-clock",
				},
				{
					name: "Search Patients",
					link: "/reception/searchPatient",
					dropdownItems: [],
					icon: "fa fa-search",
				},
				{
					name: "Blocked Slots",
					link: "/reception/BlockedSlots",
					dropdownItems: [],
					icon: "fa fa-ban",
				},
				{
					name: "Patient Management",
					// link: '/admin/addSpeciality',
					dropdownItems: [
						{
							name: "Register Patient",
							link: "/patientManagement/registerPatient",
							icon: "fa fa-user-plus",
						},
						{
							name: "Edit Patient Info",
							link: "/patientManagement/addnewuser",
							icon: "fa fa-user",
						},
					],
					icon: "fa fa-user",
				},
				{
					name: "My Profile",
					link: "/reception/myprofile",
					dropdownItems: [],
					icon: "fa fa-user",
				},
			];
			handleThisLogin(carray);
		} else if (labTechInfo?.data?.user?.name) {
			carray = [
				{
					name: "Report Templates",
					// link: "/labTech/createTest",
					dropdownItems: [
						{
							name: "Add Template",
							link: "/labTech/createTest",
							icon: "fa fa-file-alt",
						},
						{
							name: "Edit Template",
							link: "/labTech/editTest",
							icon: "fa fa-file-alt",
						},
						{
							name: "Delete Template",
							link: "/labTech/deleteTest",
							icon: "fa fa-file-alt",
						},
					],
					icon: "fa fa-file-alt",
				},
				{
					name: "Patients In Queue",
					link: "/labTech/patientsinqueue",
					dropdownItems: [],
					icon: "fa fa-user",
				},
			];
			handleThisLogin(carray);
		}
	}, [
		userInfo?.data?.user?.name,
		adminInfo?.user?.isAdmin,
		doctorInfo?.data?.user?.name,
		receptionInfo?.data?.user?.name,
		labTechInfo?.data?.user?.name,
	]);
	const [collapsed, setCollapsed] = useState(false);

	const handleToggle = () => {
		setCollapsed(!collapsed);
		if (!collapsed) {
			setOpenItems([]);
		}
	};

	const handleToggleSidebar = (index) => {
		setOpenItems((prevOpenItems) => ({
			...prevOpenItems,
			[index]: !prevOpenItems[index],
		}));
		setCollapsed(false);
	};

	useEffect(() => {
		if (location.pathname.includes("startprescription")) {
			setCollapsed(true);
		}
	}, [location.pathname]);
	return (
		<div
			className={`d-none d-md-block ${
				collapsed ? "collapsed-sidebar" : "expanded-sidebar"
			}`}>
			<div className={`d-flex flex-column `}>
				<Navbar
					bg='dark'
					variant='dark'
					className='d-flex flex-column align-items-start p-3'>
					<Button variant='outline-light' onClick={handleToggle}>
						<i className='fas fa-bars'></i>
					</Button>
					<Nav defaultActiveKey='/home' className='flex-column'>
						{allDau?.map((items, index) => (
							<>
								{items?.dropdownItems?.length !== 0 ? (
									<>
										<Nav.Item className='mt-1'>
											<Nav.Link
												className='sidebar-links'
												onClick={() => handleToggleSidebar(index)}
												aria-controls={`example-collapse-text-${index}`}
												aria-expanded={openItems[index]}
												title={items.name}>
												<i className={`${items.icon} mr-1`}></i>{" "}
												{!collapsed && items.name}{" "}
												<i class='fa fa-caret-down ml-1'></i>
											</Nav.Link>
										</Nav.Item>
										<Collapse
											in={openItems[index]}
											id={`example-collapse-text-${index}`}>
											<div>
												{items?.dropdownItems?.map(
													(dropdownItem, dropdownIndex) => (
														<Nav.Item
															key={dropdownIndex}
															className='text-light'>
															{dropdownItem &&
																dropdownItem.link &&
																dropdownItem.icon &&
																dropdownItem.name && (
																	<Nav.Link
																		className='ml-3'
																		href={dropdownItem?.link}>
																		<i
																			className={`${dropdownItem?.icon} mr-1`}></i>{" "}
																		{!collapsed && dropdownItem?.name}
																	</Nav.Link>
																)}
														</Nav.Item>
													)
												)}
											</div>
										</Collapse>
									</>
								) : (
									<Nav.Item className='mt-1'>
										<Nav.Link
											className='sidebar-links'
											href={items.link}
											title={items.name}>
											<i className={`${items.icon} mr-1`}></i>{" "}
											{!collapsed && items.name}
										</Nav.Link>
									</Nav.Item>
								)}
							</>
						))}
					</Nav>
				</Navbar>
			</div>
		</div>
	);
};
export default SideBar;
