/** @format */

import Message from "../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import { useNavigate, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
// Reset calls
import {
	getUserDataByCategory, //
	adminCreateBooking, //
} from "../../redux/adminRedux/adminApiCalls";
import { createBooking } from "../../redux/userApiCalls"; //write
import { allMessagesStart } from "../../redux/allMessages";
import {
	getSpecialities, // write
	getTimeslots, // write
} from "../../redux/userApiCalls";
import { TimeSlotGetReset } from "../../redux/getTimeSlots";
import { specialityGetReset } from "../../redux/getSpecialities";
import { createBookingReset } from "../../redux/createBooking";
import { scheduleAppointmentDateByDoctorReset } from "../../redux/prescriptionRedux/scheduleAppointmentDateByDoctor";
import { scheduleAppointmentDateByDoctorApiCall } from "../../redux/prescriptionRedux/prescriptionApiCalls";

const NextAppointmentScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();

	const [DoctorFromSpecialityArray, setDoctorFromSpecialityArray] = useState(
		[]
	);

	// success and error
	//const { allTimeSlots } = useSelector((state) => state.getTimeSlots);
	const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
	const doctorAppointmentDates =
		getTimeSlotsData?.allTimeSlots?.appointmentDates;

	const getSpecialityAllData = useSelector((state) => state.getSpeciality);
	const getSpecialityAllsuccess = getSpecialityAllData.success;
	const getSpecialityAllerror = getSpecialityAllData.error;
	const getSpecialityAllloading = getSpecialityAllData.loading;
	const getSpecialityAll = getSpecialityAllData.getSpecialityAll;

	const bookingallinfo = useSelector((state) => state.adminCreateBooking);
	const bookingerror = bookingallinfo.error;
	const createdbookingloading = bookingallinfo.loading;
	const bookinguserInfo = bookingallinfo.userInfo;
	const bookingsuccess = bookingallinfo.success;

	const [doctorSelected, setDoctorSelected] = useState(false);

	const scheduleAppointmentDateByDoctor = useSelector(
		(state) => state.scheduleAppointmentDateByDoctor
	);
	const scheduleAppointmentDateByDoctorerror =
		scheduleAppointmentDateByDoctor.error;
	const scheduleAppointmentDateByDoctorloading =
		scheduleAppointmentDateByDoctor.loading;
	const scheduleAppointmentDateByDoctorsuccess =
		scheduleAppointmentDateByDoctor.success;

	const [
		patientPreviousBookingDoctorNames,
		setpatientPreviousBookingDoctorNames,
	] = useState({});
	function arrayToDictionary(arr) {
		let dictionary = {};
		for (let i = 0; i < arr.length; i++) {
			dictionary[arr[i]] = true; // Use array element as key
		}
		return dictionary;
	}

	function isElementPresent(dictionary, element) {
		return !!dictionary[element]; // Check if key exists
	}

	var days = [];
	const formattedDates = doctorAppointmentDates?.map((entry) => {
		const dateString = entry.date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ("0" + dateString.slice(6, 8)).slice(-2);

		// Create the formatted date string

		days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
	});

	useEffect(() => {
		dispatch(getSpecialities());
	}, []);

	let allSpecialities = [];
	if (getSpecialityAll) {
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}

	const [specialityOption, setSpecialityOption] = useState("Choose Speciality");
	const [specialityId, setSpecialityId] = useState("");
	const handleSpecialitySelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		setSpecialityOption(arr[0]);
		setSpecialityId(arr[1]);
		// setDoctorOption("Choose Doctor");
		// setSlotOption();
		// setSlotCount(0);
		// setDoctorSelected(false);
		setDoctorFromSpecialityArray(
			getSpecialityAll.data.data[arr[2]].doctorsArray
		);
	};

	let allDoctors = [];
	const [doctorOption, setDoctorOption] = useState("Choose Doctor");
	const [doctorId, setDoctorId] = useState("");
	const handleDoctorSelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		setDoctorId(arr[1]);
		setDoctorOption(arr[0]);
		if (specialityOption === "Choose Speciality") {
			alert("Speciality needs to be selected first, Please select Speciality");
		} else if (
			isElementPresent(location?.state?.user_bookingDoctorsArray, arr[0])
		) {
			alert("A booking already exists with this doctor");
		} else {
			setSlotOption("Choose Slot");
			setSlotCount(0);
			setDoctorSelected(true);
			setDateOption("Choose Date");
			setDateSelected(false);
			dispatch(getTimeslots(arr[1]));
		}
	};

	const [dateOption, setDateOption] = useState("Choose Date");
	const [dateSelected, setDateSelected] = useState(false);
	const [dayIdx, setdayIdx] = useState(-1);
	const [dateOptionNumeric, setDateOptionNumeric] = useState("Choose Date");
	const [dateTimeSlots, setDateTimeSlots] = useState([]);

	const handleDateSelect = (eventKey) => {
		if (specialityOption == "Choose Speciality") {
			alert("Speciality needs to be selected first, Please select Speciality");
		} else if (doctorOption == "Choose Doctor") {
			alert("Doctor needs to be selected first, Please select Doctor");
		} else {
			const dateString = doctorAppointmentDates[eventKey].date.toString();

			// Extract year, month, and day
			const year = dateString.slice(0, 4);
			const month = dateString.slice(4, 6);
			const day = ("0" + dateString.slice(6, 8)).slice(-2);
			setdayIdx(eventKey);
			// Create the formatted date string
			setDateOptionNumeric(doctorAppointmentDates[eventKey].date);
			//days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
			setDateOption(`${day}-${month}-${year}`);
			setDateSelected(true);
			setDateTimeSlots(doctorAppointmentDates[eventKey].timeSlots);
			setSlotOption("Choose Slot");
		}
	};

	const [slotOption, setSlotOption] = useState("Choose Slot");
	const [slotCount, setSlotCount] = useState(0);
	const handleSlotSelect = (slot, slotcount) => {
		setSlotOption(slot);
		setSlotCount(slotcount + 1);
	};

	const week = [
		"sunday",
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday",
	];

	console.log(location?.state?.name, location?.state?.mno);

	const [selectedslot, setSelectedSlot] = useState("");
	const [selectedslotCount, setSelectedSlotCount] = useState(0);
	const selectedSlot = (slot, count) => {
		console.log(slot, count);
		setSelectedSlot(slot);
		setSelectedSlotCount(count);
	};

	const submitDoctorHandler = () => {
		if (specialityOption === "Choose Speciality") {
			alert("Speciality needs to be selected first, Please select Speciality");
		} else if (doctorOption === "Choose Doctor") {
			alert("Doctor needs to be selected first, Please select Doctor");
		} else if (dateOption == "Choose Date") {
			alert("Date needs to be selected first, Please select Date");
		} else if (slotOption === "Choose Slot") {
			alert("Please select a vaild slot");
		} else {
			console.log(
				dateOptionNumeric,
				slotOption,
				slotCount,
				doctorOption,
				doctorId,
				specialityOption,
				specialityId
			);
			dispatch(
				adminCreateBooking(
					location?.state?.name, //
					dateOptionNumeric,
					slotOption,
					slotCount,
					location?.state?.mno,
					doctorOption,
					doctorId,
					specialityOption,
					specialityId,
					location?.state?.id, //
					Number(dayIdx),
					Number(location?.state?.userIdx)
				)
			);
		}
	};

	const [nextDate, setNextDate] = useState("");
	const [nextMess, setNextMess] = useState("");
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	console.log(doctorInfo?.data?.user?.name);
	const submitNextHandler = () => {
		const date = new Date();
		let currentDate = `${date.getFullYear()}-${
			date.getMonth() + 1
		}-${date.getDate()}`;
		// console.log(doctorInfo?.data?.user?._id, doctorInfo?.data?.user?.name);

		let nextDateNumeric = Number(
			nextDate?.split("-")[0] +
				nextDate?.split("-")[1] +
				nextDate?.split("-")[2]
		);
		console.log(nextDateNumeric);
		if (new Date(nextDate) < new Date()) {
			alert("Selected date must be greater than today's date.");
		} else if (nextMess == "") {
			alert("Enter the message");
		} else {
			dispatch(
				scheduleAppointmentDateByDoctorApiCall(
					location.state.id,
					location.state.name,
					nextDateNumeric,
					doctorInfo?.data?.user?._id,
					doctorInfo?.data?.user?.name,
					nextMess,
					Number(location?.state?.userIdx)
				)
			);
			setNextDate("");
			setNextMess("");
		}
	};

	// ALerts////////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const [showPopup1, setShowPopup1] = React.useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log("error");
		dispatch(scheduleAppointmentDateByDoctorReset());
	};

	useEffect(() => {
		if (scheduleAppointmentDateByDoctorsuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(scheduleAppointmentDateByDoctorReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [scheduleAppointmentDateByDoctorsuccess, dispatch]);

	useEffect(() => {
		if (bookingsuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(createBookingReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [bookingsuccess, dispatch]);

	const handleClearData = () => {
		setSpecialityOption("Choose Speciality");
		setDoctorOption("Choose Doctor");
		setDateOption("Choose Date");
		setSlotOption("Choose Slot");
	};
	const handleClearData1 = () => {
		setNextMess("");
		setNextDate("");
	};

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	return (
		<>
			<Meta />
			<a
				className="btn offset-1 back-button mt-2"
				role="button"
				href="/doctor/patientstobevisited"
			>
				<i className="fa fa-angle-left custom fa-2x"></i>
			</a>
			<Container className="header-center mt-5 card">
				<Row className="justify-content-center mt-4 mb-4">
					<Col md="auto" className="text-center">
						<b>Visit Next Doctor</b>
					</Col>
				</Row>
				<Row className="justify-content-center mb-4">
					<Col md="auto" className="text-center">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={specialityOption}
							onSelect={handleSpecialitySelect}
						>
							{allSpecialities.map(
								(specialities, index) =>
									specialities.specialization !== "referral" &&
									specialities.specialization !== "Consultant Doctor" && (
										<Dropdown.Item
											eventKey={
												specialities.specialization +
												"/" +
												specialities._id +
												"/" +
												index
											}
										>
											{specialities.specialization}
										</Dropdown.Item>
									)
							)}
						</DropdownButton>
					</Col>
					<Col md="auto" className="text-center">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={doctorOption}
							onSelect={handleDoctorSelect}
						>
							{DoctorFromSpecialityArray.map((doctors, index) => (
								<Dropdown.Item
									eventKey={doctors.name + "/" + doctors.doctorsinfo}
								>
									{doctors.name}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</Col>
					<Col md="auto" className="text-center">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={dateOption}
							onSelect={handleDateSelect}
						>
							{" "}
							<div className="dropScroll">
								{days.map((day, index) => (
									<Dropdown.Item eventKey={index}>{day.currDay}</Dropdown.Item>
								))}
							</div>
						</DropdownButton>
					</Col>
					<Col md="auto" className="text-center">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={slotOption}
						>
							{" "}
							<div className="dropScroll">
								{dateSelected &&
									dateTimeSlots?.map((timeslots, index) =>
										!timeslots.isEmpty || timeslots.isBlocked ? (
											<Dropdown.Item
												className="bg-danger text-light"
												eventKey={index}
											>
												{timeslots.timeSlot}
											</Dropdown.Item>
										) : (
											<Dropdown.Item
												eventKey={index}
												onClick={() =>
													handleSlotSelect(
														timeslots.timeSlot,
														timeslots.curCount
													)
												}
											>{`${timeslots.timeSlot} `}</Dropdown.Item>
										)
									)}
							</div>
						</DropdownButton>
					</Col>
				</Row>
				<Row className="justify-content-end mb-4">
					<Col md="auto" className="text-center">
						<button className="btn btn-success" onClick={submitDoctorHandler}>
							Create Booking
						</button>
					</Col>
					<Col md="auto" className="text-center">
						<button className="btn btn-danger" onClick={handleClearData}>
							Clear
						</button>
					</Col>
				</Row>
			</Container>
			<Container className="header-center mt-5 card">
				<Row className="justify-content-center mt-4 mb-4">
					<Col md="auto" className="text-center">
						<b>Schedule Next Appointment</b>
					</Col>
				</Row>
				<Row className="justify-content-center mb-4">
					<Col>
						<Col md="12" className="text-center">
							<b>Date:</b>
						</Col>
						<Col md="12" className="text-center">
							<input
								type="date"
								className="form-control"
								onChange={(e) => setNextDate(e.target.value)}
								value={nextDate}
								min={today}
							/>
						</Col>
					</Col>
					<Col>
						<Col md="12" className="text-center">
							<b>Reason:</b>
						</Col>
						<Col md="12" className="text-center">
							<input
								type="text"
								placeholder="Enter Reason"
								className="form-control"
								onChange={(e) => setNextMess(e.target.value)}
								value={nextMess}
							/>
						</Col>
					</Col>
				</Row>
				<Row className="justify-content-end mb-4">
					<Col md="auto" className="text-center">
						<button className="btn btn-success" onClick={submitNextHandler}>
							Schedule Next Visit
						</button>
					</Col>
					<Col md="auto" className="text-center">
						<button className="btn btn-danger" onClick={handleClearData1}>
							Clear
						</button>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default NextAppointmentScreen;
