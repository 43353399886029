/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import Meta from "../Meta";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Modal from "react-bootstrap/Modal";
import { editUser } from "../../redux/adminRedux/adminApiCalls";
import { Col, Row, Container } from "react-bootstrap";
import {
	getUserDataByCategory, //
	//
} from "../../redux/adminRedux/adminApiCalls";
import Message from "../Message";
// import {bookingListScreen} from "../redux/userApiCalls";
import { getUserDataByCategoryReset } from "../../redux/adminRedux/Patient_Management/getUserDataByCategory";
import { updateUserProfile } from "../../redux/userApiCalls";
import { editBloodGroup } from "../../redux/userRedux/userApiCalls";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";
import Form from "react-bootstrap/Form";

const AddNewPatientScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	//success and failure messages
	const { userData, error, loading, success } = useSelector(
		(state) => state.getUserdataByCategory
	);
	console.log(userData);
	const userUpdate = useSelector((state) => state.userUpdate);
	const userUpdateLoading = userUpdate?.loading;
	const [lgShow, setLgShow] = useState(false);
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	console.log(error);
	const [mno, setMno] = useState("");
	const [editClicked, setEditClicked] = useState(false);
	const [searchClicked, setSearchClicked] = useState(false);

	const data2 = useSelector((state) => state.getUserDataInSearchPatient);
	const searchLoading = data2.loading;
	const searchUserData = data2.userData;
	const searchError = data2.error;

	const handleSearchPatient = (e) => {
		e.preventDefault();
		dispatch(getUserDataByCategory(mno));
		setSearchClicked(true);
		setUserOptionClicked(false);
		setEditClicked(false);
		setUname("");
	};
	const [showPopup, setShowPopup] = useState(false);

	const [nameNew, setNameNew] = useState("");
	const [genderNew, setGenderNew] = useState("");
	const [dobNew, setDobNew] = useState("");
	const [bloodGroupNew, setBloodGroupNew] = useState("Select a blood group");
	const handleBloodGroup = (eventKey) => {
		setBloodGroupNew(eventKey);
	};

	let bloodgroupflag = false;
	const BloodGrouphandler = (eventKey) => {
		setBloodGroup(eventKey);
		bloodgroupflag = true;
	};
	const blood_groups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-", "NA"];

	const addUserOnSubmit = (e) => {
		e.preventDefault();
		console.log(name + " " + gender + " " + dob + " " + mno);
		var flag = 0;
		for (
			let i = 0;
			i < userData?.data?.data?.registered_patients_array?.length;
			i++
		) {
			if (
				userData?.data?.data?.registered_patients_array[i]?.name === nameNew
			) {
				flag = 1;
				break;
			} else {
				flag = 0;
			}
		}
		var letters = /^[A-Za-z\s]+$/;
		if (nameNew === "") {
			alert("Username cannot be empty");
		} else if (!nameNew.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (genderNew === "") {
			alert("Please select gender, gender cannot be empty");
		} else if (dobNew === "") {
			alert("Please select dob, dob cannot be empty");
		} else if (new Date(dobNew) >= new Date(new Date())) {
			alert("Please select proper dob, dob should not exceed todays date");
		} else if (nameNew === userData?.data?.data?.name || flag == 1) {
			alert("Duplicate names are not allowed");
		} else {
			if (bloodGroupNew == "Select a blood group") {
				dispatch(
					updateUserProfile(nameNew.trim(), genderNew, dobNew, mno, "NA", "")
				);
			} else {
				dispatch(
					updateUserProfile(
						nameNew.trim(),
						genderNew,
						dobNew,
						mno,
						bloodGroupNew,
						""
					)
				);
			}
		}
		setNameNew("");
		setGenderNew("");
		setDobNew("");
		setLgShow(false);
		setBloodGroupNew("Select a blood group");
	};

	useEffect(() => {
		if (mno !== "") {
			dispatch(getUserDataByCategory(mno));
			setSearchClicked(true);
		}
	}, [userUpdateLoading]);

	let users_array = [];
	if (mno !== "") {
		users_array.push({
			name: userData?.data?.data?.name,
			dob: userData?.data?.data?.dob,
			gender: userData?.data?.data?.gender,
			id: userData?.data?.data?._id,
			bid: userData?.data?.data?.bookingId,
			vitals: userData?.data?.data?.vitals,
		});
		for (
			let i = 0;
			i < userData?.data?.data?.registered_patients_array?.length;
			i++
		) {
			users_array.push({
				name: userData?.data?.data?.registered_patients_array[i]?.name,
				id: userData?.data?.data?._id,
				bid: userData?.data?.data?.registered_patients_array[i]?.bookingId,
				dob: userData?.data?.data?.registered_patients_array[i]?.dob,
				gender: userData?.data?.data?.registered_patients_array[i]?.gender,
				vitals: userData?.data?.data?.registered_patients_array[i]?.vitals,
			});
		}
	}

	const [newMno, setNewMno] = useState("");
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [dob, setDob] = useState("");
	const [bloodGroup, setBloodGroup] = useState("");
	const [address, setAddress] = useState("");
	const [activeUser, setActiveUser] = useState(null);
	const [userOptionClicked, setUserOptionClicked] = useState(false);
	const [uname, setUname] = useState("");
	const [uid, setUid] = useState("");
	const [userIdx, setUserIdx] = useState("");
	const handelUserSelect = (id, bookingidarray, name, index) => {
		console.log(users_array[index]?.name + "line177");
		setNewMno(userData?.data?.data?.mobilenumber);
		setUid(id);
		setUserIdx(index);
		setName(users_array[index]?.name);
		setUname(users_array[index]?.name);
		setGender(users_array[index]?.gender);
		setDob(users_array[index]?.dob);
		for (let i = 0; i < users_array[index]?.vitals?.length; i++) {
			if (users_array[index]?.vitals[i]?.vitalName === "BloodGroup") {
				setBloodGroup(users_array[index]?.vitals[i]?.vitalValue);
			}
		}
		setAddress(userData?.data?.data?.address);
		setUserOptionClicked(true);
		setActiveUser(index);
		setEditClicked(false);
	};

	const submitHandler = () => {
		console.log(activeUser);
		var letters = /^[A-Za-z\s]+$/;
		if (name === "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (gender === "") {
			alert("Please select gender, gender cannot be empty");
		} else if (dob === "") {
			alert("Please select dob, dob cannot be empty");
		} else if (address === "") {
			alert("Address cannot be empty");
		} else {
			console.log(name);
			dispatch(
				editUser(
					userData?.data?.data?._id,
					name,
					newMno,
					gender,
					dob,
					address,
					activeUser,
					activeUser - 1 === -1 ? 0 : 1,
					bloodgroupflag,
					bloodGroup
				)
			);
			if (bloodgroupflag) {
				dispatch(
					editBloodGroup(userData?.data?.data?._id, activeUser, bloodGroup)
				);
			}
			setName("");
			setGender("");
			setDob("");
			setAddress("");
			setNewMno("");
		}
		console.log(name, newMno, gender, dob, address);
		setEditClicked(false);
		setSearchClicked(false);
		setUserOptionClicked(false);
	};

	const handleDiscard = () => {
		setNewMno(userData?.data?.data?.mobilenumber);
		setName(users_array[activeUser]?.name);
		setGender(users_array[activeUser]?.gender);
		setDob(users_array[activeUser]?.dob);
		setAddress(userData?.data?.data?.address);
		setUserOptionClicked(true);
		setEditClicked(false);
	};

	console.log(userData);
	const ISTOffset = 330; // 5 hours and 30 minutes in minutes
	const todayInIST = new Date(new Date().getTime() + ISTOffset * 60000)
		.toISOString()
		.split("T")[0];

	return (
		<div className="pageSize">
			<Meta />

			{loading && <Loader />}
			<form className="form-group" onSubmit={handleSearchPatient}>
				<Container className="header-center mt-5">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center">
							<h6>
								Enter Patient's Mobile Number{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center">
							<input
								className="form-control"
								type="text"
								placeholder="9999999999"
								id="mobilenumber"
								required="required"
								onChange={(e) => setMno(e.target.value)}
								minlength="10"
								maxlength="10"
								pattern="^[0-9]*$"
							/>
						</Col>
						<Col md="auto" className="text-center">
							<Button type="submit" id="search" className="btn btn-primary">
								Search
							</Button>
						</Col>
					</Row>
				</Container>
			</form>

			<Modal
				size="lg"
				show={lgShow}
				onHide={() => setLgShow(false)}
				aria-labelledby="example-modal-sizes-title-lg"
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-modal-sizes-title-lg">
						Add New User
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form className="form-group" onSubmit={addUserOnSubmit}>
						<div className="row mt-3">
							<label className="col-3 col-md-3" htmlFor="name">
								<b>Name </b>{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<input
								className="form-control col-8 col-md-8"
								id="name"
								type="name"
								required="required"
								value={nameNew}
								onChange={(e) => setNameNew(e.target.value)}
							/>
						</div>
						<div className="row mt-3">
							<label htmlFor="gender" className="col-4 col-md-3">
								<b>Gender</b>{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<div className="row">
								<b>
									<Form.Check
										onChange={() => {
											setGenderNew("Male");
										}}
										checked={genderNew === "Male"}
										className="form-check col-5 col-md-4"
										inline
										label="Male"
										type="radio"
										id={`inline-radio-1`}
									/>
									<Form.Check
										onChange={() => {
											setGenderNew("Female");
										}}
										checked={genderNew === "Female"}
										inline
										label="Female"
										className="form-check col-5 col-md-4"
										type="radio"
										id={`inline-radio-2`}
									/>
								</b>
							</div>
						</div>
						<div className="row mt-3">
							<label htmlFor="date" className="col-md-3">
								<b>Date of Birth</b>{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<input
								type="date"
								className="col-md-4 form-control"
								selected={dobNew}
								value={dobNew}
								onChange={(e) => setDobNew(e.target.value)}
								max={todayInIST}
							/>
						</div>
						<div className="row mt-3">
							<label for="bloodgroup" className="col-md-3">
								<b>Blood Group</b>
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<DropdownButton
								className="col-md-1 btn"
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={bloodGroupNew}
								onSelect={handleBloodGroup}
								required
							>
								{blood_groups.map((blood) => (
									<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
								))}
							</DropdownButton>
						</div>
						<div>
							<Button className="offset-5 offset-md-6 mt-5 btn" type="submit">
								Add
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			<div className="row offset-md-2">
				{!error &&
					searchClicked &&
					userData &&
					users_array.map((users, index) =>
						users.name === uname ? (
							<button
								onClick={() =>
									handelUserSelect(
										users.id,
										users.bookingidarray,
										users.name,
										index
									)
								}
								className="btn btn-secondary col-md-3 m-1"
							>
								{users.name}
							</button>
						) : (
							<button
								onClick={() =>
									handelUserSelect(
										users.id,
										users.bookingidarray,
										users.name,
										index
									)
								}
								className="btn btn-outline-secondary col-md-3 m-1"
							>
								{users.name}
							</button>
						)
					)}
			</div>
			<Container className="header-center mt-3 mb-3">
				<Row className="justify-content-end">
					<Col md="auto" className="text-center">
						{searchClicked && !error && (
							<button
								className="btn btn-outline-success"
								onClick={() => setEditClicked(true)}
							>
								<i className="fa fa-edit"></i>
								{"  "}Edit
							</button>
						)}{" "}
					</Col>
					<Col md="auto" className="text-center">
						{searchClicked &&
							!error &&
							(users_array.length >= 6 ? (
								<button disabled className="btn btn-danger">
									Users limit reached
								</button>
							) : (
								<button
									className="btn btn-outline-secondary"
									onClick={() => setLgShow(true)}
								>
									Add User
								</button>
							))}
					</Col>
				</Row>
			</Container>
			{userOptionClicked && searchClicked && !error ? (
				<Container className="card mt-5 col-md-8">
					<div class="card-body">
						<div className="card-text">
							<div className="row mb-2">
								<div className="col-4 col-md-3 cardHeading">Mobile Number:</div>
								{editClicked ? (
									<input
										type="text"
										className="col-8 form-control"
										value={newMno}
										onChange={(e) => setNewMno(e.target.value)}
										disabled={activeUser !== 0}
									/>
								) : (
									<div className="col-8">{newMno}</div>
								)}
							</div>
							<div className="row mb-2">
								<div className="col-4 col-md-3 cardHeading">Name:</div>
								{editClicked ? (
									<input
										type="text"
										className="col-8 form-control"
										value={name}
										onChange={(e) => setName(e.target.value)}
										disabled
									/>
								) : (
									<div className="col-8">{name}</div>
								)}
							</div>
							<div className="row mb-2">
								<div className="col-4 col-md-3 cardHeading">Gender:</div>
								{editClicked ? (
									<div className="col-md-9 col-8 row">
										<Form.Check
											onChange={() => {
												setGender("Male");
											}}
											checked={gender === "Male"}
											className="form-control col-2"
											inline
											label="Male"
											type="radio"
											id={`inline-radio-1`}
										/>

										<Form.Check
											onChange={() => {
												setGender("Female");
											}}
											checked={gender === "Female"}
											inline
											label="Female"
											className="form-control col-2"
											type="radio"
											id={`inline-radio-2`}
										/>

										{/* Add more checkboxes for other genders if needed */}
									</div>
								) : (
									<div className="col-8">{gender}</div>
								)}
							</div>
							<div className="row mb-2">
								<div className="col-4 col-md-3 cardHeading">Date Of Birth:</div>
								{editClicked ? (
									<input
										type="date"
										className="col-8 form-control"
										value={dob}
										onChange={(e) => setDob(e.target.value)}
										max={todayInIST}
									/>
								) : (
									<div className="col-8">{dob}</div>
								)}
							</div>
							<div className="row mb-2">
								<div className="col-4 col-md-3 cardHeading">Blood Group:</div>
								{editClicked ? (
									<DropdownButton
										key="down-centered"
										id={`dropdown-button-drop-down-centered`}
										drop="down-centered"
										variant="success"
										title={bloodGroup}
										onSelect={BloodGrouphandler}
										required
									>
										{blood_groups.map((blood) => (
											<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
										))}
									</DropdownButton>
								) : (
									<div className="col-8">{bloodGroup}</div>
								)}
							</div>
							<div className="row mb-2">
								<div className="col-4 col-md-3 cardHeading">Address:</div>
								{editClicked ? (
									<input
										type="text"
										className="col-8 form-control"
										value={address}
										onChange={(e) => setAddress(e.target.value)}
										disabled={activeUser !== 0}
									/>
								) : (
									<div className="col-8">{address}</div>
								)}
							</div>

							{editClicked && (
								<div className="row mb-2">
									<button
										className="btn btn-danger col-4 offset-1 col-md-2 offset-md-4"
										onClick={handleDiscard}
									>
										Discard Changes
									</button>
									<button
										className="btn btn-success col-4 col-md-2 offset-1"
										onClick={submitHandler}
									>
										Submit Changes
									</button>
								</div>
							)}
						</div>
					</div>
				</Container>
			) : (
				<div></div>
			)}
		</div>
	);
};

export default AddNewPatientScreen;
