/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createTestTemplate = createSlice({
	name: 'createTestTemplate',
	initialState: {},
	reducers: {
		createTestTemplateStart: (state) => {
			state.loading = true;
		},
		createTestTemplateSuccess: (state, action) => {
			state.loading = false;
			state.template = action.payload;
			state.error = false;
			state.success = true;
		},
		createTestTemplateFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createTestTemplateReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createTestTemplateStart,
	createTestTemplateSuccess,
	createTestTemplateFailure,
	createTestTemplateReset,
} = createTestTemplate.actions;
export default createTestTemplate.reducer;
