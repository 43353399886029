/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getInventoryItemsFavouriteItems = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		getInventoryItemsFavouriteItemsStart: (state) => {
			state.loading = true;
		},
		getInventoryItemsFavouriteItemsSuccess: (state, action) => {
			state.loading = false;
			state.getInventoryItemsFavouriteItems = action.payload;
			state.error = false;
			state.success = true;
		},
		getInventoryItemsFavouriteItemsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getInventoryItemsFavouriteItemsReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getInventoryItemsFavouriteItemsStart,
	getInventoryItemsFavouriteItemsSuccess,
	getInventoryItemsFavouriteItemsFailure,
	getInventoryItemsFavouriteItemsReset,
} = getInventoryItemsFavouriteItems.actions;
export default getInventoryItemsFavouriteItems.reducer;
