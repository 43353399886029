/** @format */

import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Loader';
import Table from 'react-bootstrap/Table';
import Meta from '../../../Components/Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Message from '../../../Components/Message';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from 'axios';
import { SERVER_URL } from '../../../App';
import Button from 'react-bootstrap/Button';
import {
	getLabDepts,
	deleteTechInDept,
	getLabTechniciansFromParticularDepartment,
} from '../../../redux/adminRedux/adminApiCalls';
import { getAllLabTechnicians } from '../../../redux/adminRedux/adminApiCalls';

const DeleteLabTechScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	useEffect(() => {
		dispatch(getAllLabTechnicians());
	}, []);

	const deleteLabTechnician = useSelector((state) => state.deleteLabTech);

	useEffect(() => {
		dispatch(getAllLabTechnicians());
	}, [deleteLabTechnician?.loading]);

	const { AllLabTechnicians } = useSelector(
		(state) => state.getAllLabTechnicians
	);

	console.log(AllLabTechnicians);

	const deleteTech = (id) => {
		dispatch(deleteTechInDept(id));
	};
	return (
		<>
			<Meta />

			<div>
				<h1 className='header-center'>Delete Lab Technician </h1>
				{
					<Container className='header-center mt-5 col-md-8'>
						<Table striped bordered hover className='custom-table'>
							<thead>
								<tr>
									<th>Sno</th>
									<th>Lab Technician Name</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{AllLabTechnicians?.data?.data?.map((tech, index) => (
									<tr>
										<td>{index + 1}</td>
										<td>{tech?.name}</td>
										<td>
											<button
												className='btn btn-danger'
												onClick={() => deleteTech(tech?._id)}>
												<i className='fas fa-trash'></i>
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Container>
				}
			</div>
		</>
	);
};
export default DeleteLabTechScreen;
