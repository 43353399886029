/** @format */

import React, { useState, useEffect, useRef } from "react";
import {
	Row,
	Col,
	Container,
	Table,
	Form,
	Button,
	ListGroup,
	Modal,
	DropdownButton,
	Dropdown,
	Card,
} from "react-bootstrap";
import Meta from "../../../Components/Meta";
import axios from "axios";
import { SERVER_URL } from "../../../App";
import { allMessagesFailure } from "../../../redux/allMessages";
import ButtonLoader from "../../../Components/ButtonLoader";
import { Breadcrumb } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	createInventoryCategory,
	createInventoryItem,
	createSupplier,
	getInventoryCategory,
	getInventoryItemsForBilling,
	getSuppliers,
	newPurchaseFromSupplier,
} from "../../../redux/inventoryRedux/inventoryApiCalls";
import { hospitalname } from "../../../App";
import {
	getInventoryItemsForBillingReset,
	getInventoryItemsForBillingStart,
} from "../../../redux/inventoryRedux/InventoryItem/getInventoryItemsForBilling";
import {
	createInventoryCategoryReset,
	createInventoryCategoryStart,
} from "../../../redux/inventoryRedux/CategoryRedux/createInventoryCategory";
import { get } from "lodash";
import { getInventoryInfo } from "../../../redux/adminRedux/adminApiCalls";

const PurchaseInventoryPharmacyScreens = () => {
	const dispatch = useDispatch();
	const [inventoryData, setInventoryData] = useState([
		{
			serialNo: 1,
			productName: "",
			quantity: "",
			freeItem: 0,
			batchNoFromUser: "",
			cost: "",
			hsn: "",
			expiryDate: "",
			brand: "",
			sgst: "",
			cgst: "",
			mrp: "",
			totalAmount: "",
			category: "",
			categoryId: "",
			existingCategory: false,
			unitsOfMeasurement: "Pieces",
			minStockLevel: 0,
			medicalComposition: "",
			rackNo: "",
			description: "",
		},
	]);

	const { inventoryInfo } = useSelector((state) => state.inventoryInfo);

	useEffect(() => {
		dispatch(getInventoryInfo());
	}, []);

	const [rowProductSearch, setRowProductSearch] = useState({});
	const [rowFilteredProductStock, setRowFilteredProductStock] = useState([]);

	const [rowBrandSearch, setRowBrandSearch] = useState({});
	const [rowFilteredBrandStock, setRowFilteredBrandStock] = useState({});

	useEffect(() => {
		dispatch(getInventoryCategory());
		dispatch(getInventoryItemsForBilling());
	}, []);

	const getInventorycategory = useSelector(
		(state) => state?.getInventoryCategory
	);

	const getInventoryItemsForbilling = useSelector(
		(state) => state?.getInventoryItemsForBilling
	);

	console.log(getInventoryItemsForbilling);

	console.log(getInventorycategory);

	useEffect(() => {
		dispatch(getSuppliers());
	}, []);

	const getsuppliers = useSelector((state) => state.getSuppliers);

	console.log(getsuppliers);

	const [showAddModal, setShowAddModal] = useState(false);

	const [showCategoryModal, setShowCategoryModal] = useState(false);
	const [newCategory, setNewCategory] = useState("");
	const [newCategoryDescription, setNewCategoryDescription] = useState("");

	useEffect(() => {
		const updatedFilteredProductStock = {};
		Object.keys(rowProductSearch).forEach((index) => {
			const value = rowProductSearch[index];
			updatedFilteredProductStock[index] =
				getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data.filter(
					(item) => item.name?.toLowerCase().includes(value.toLowerCase())
				);
		});
		setRowFilteredProductStock(updatedFilteredProductStock);
	}, [rowProductSearch, getInventoryItemsForbilling?.loading, inventoryData]);

	useEffect(() => {
		const updatedFilteredBrandStock = {};
		Object.keys(rowBrandSearch).forEach((index) => {
			const value = rowBrandSearch[index];
			updatedFilteredBrandStock[index] =
				getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data
					.flatMap((item) => item.selling_prices.map((price) => price.brand))
					.filter((brand) =>
						brand?.toLowerCase().includes(value.toLowerCase())
					);
		});
		setRowFilteredBrandStock(updatedFilteredBrandStock);
	}, [rowBrandSearch, getInventoryItemsForbilling?.loading]);

	const handleInputChange = (index, e, isDropdown = false) => {
		let updatedData = [...inventoryData];
		if (e !== "Add New Category") {
			if (isDropdown) {
				let valuesarr = e?.split("@@");
				updatedData[index] = {
					...updatedData[index],
					category: valuesarr[0],
					categoryId: valuesarr[1],
				};
			} else {
				let { name, value } = e?.target;
				if (name === "productName") {
					const productArray =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
							(product) => {
								return product?.name?.toLowerCase() === value?.toLowerCase();
							}
						);

					if (productArray) {
						updatedData[index] = {
							...updatedData[index],
							[name]: productArray?.name,
							mrp: productArray?.selling_prices[0]?.selling_price,
							hsn: productArray?.hsn_code,
							category: productArray?.category,
							categoryId: productArray?.categoryId,
							existingCategory: true,
						};
					} else {
						updatedData[index] = {
							...updatedData[index],
							[name]: value,
							mrp: "",
							hsn: "",
							category: "",
							existingCategory: false,
						};
					}
				} else if (name === "brand") {
					const productArray =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data
							.flatMap((item) =>
								item.selling_prices.map((price) => price.brand)
							)
							.filter((brand) => brand?.toLowerCase() === value.toLowerCase());
					if (productArray) {
						updatedData[index] = {
							...updatedData[index],
							[name]: productArray?.length > 0 ? productArray[0] : value,
						};
					} else {
						updatedData[index] = { ...updatedData[index], [name]: value };
					}
				} else {
					updatedData[index] = {
						...updatedData[index],
						[name]: value,
					};
				}
			}

			const updatedItem = updatedData[index];
			const cost = Number(updatedItem.cost) || 0;
			const quantity = Number(updatedItem.quantity) || 0;
			updatedData[index].totalAmount = (cost * quantity)?.toFixed(2);
			setInventoryData(updatedData);
		}
	};

	const addRow = () => {
		const newRow = {
			serialNo: inventoryData.length + 1,
			productName: "",
			quantity: "",
			freeItem: 0,
			batchNoFromUser: "",
			cost: "",
			hsn: "",
			expiryDate: "",
			brand: "",
			sgst: "",
			cgst: "",
			mrp: "",
			totalAmount: "",
			category: "",
			categoryId: "",
			existingCategory: false,
			unitsOfMeasurement: "Pieces",
			minStockLevel: 0,
			medicalComposition: "",
			rackNo: "",
			description: "",
		};
		scrollLeft();
		setInventoryData([...inventoryData, newRow]);
	};

	const deleteRow = (index) => {
		const updatedData = inventoryData.filter((_, i) => i !== index);
		const updatedDataWithSerial = updatedData.map((item, i) => ({
			...item,
			serialNo: i + 1,
		}));
		setInventoryData(updatedDataWithSerial);
	};

	const handleProductSearchChange = (index, e) => {
		const value = e.target.value;
		setRowProductSearch((prev) => ({ ...prev, [index]: value }));
	};

	const handleBrandSearchChange = (index, e) => {
		const value = e.target.value;
		setRowBrandSearch((prev) => ({ ...prev, [index]: value }));
	};

	const handleSelectProduct = (index, product) => {
		const updatedData = [...inventoryData];
		updatedData[index] = {
			...updatedData[index],
			productName: product.name,
			mrp: product?.selling_prices[0]?.selling_price,
			hsn: product?.hsn_code,
			description: product?.description,
			unitsOfMeasurement: product?.unit,
			category: product?.category,
			categoryId: product?.categoryId,
			existingCategory: true,
			minStockLevel: product?.minStockLevel ? product?.minStockLevel : 0,
			medicalComposition: product?.medical_composition,
			rackNo: product?.rackNo,
		};
		setInventoryData(updatedData);
		setRowProductSearch((prev) => ({ ...prev, [index]: "" }));
		setRowFilteredProductStock((prev) => ({ ...prev, [index]: [] }));
	};

	const handleSelectBrand = (index, brand) => {
		const updatedData = [...inventoryData];
		updatedData[index] = {
			...updatedData[index],
			brand: brand,
		};
		setInventoryData(updatedData);
		setRowBrandSearch((prev) => ({ ...prev, [index]: "" }));
		setRowFilteredBrandStock((prev) => ({ ...prev, [index]: [] }));
	};

	const createInventoryCategoryDetails = useSelector(
		(state) => state.createInventoryCategory
	);

	console.log(createInventoryCategoryDetails);

	const createInventoryItemDetails = useSelector(
		(state) => state.createInventoryItem
	);

	useEffect(() => {
		dispatch(getInventoryCategory());
	}, [createInventoryCategoryDetails?.loading]);

	useEffect(() => {
		dispatch(getInventoryItemsForBilling());
	}, [createInventoryItemDetails?.loading]);

	const handleAddCategory = () => {
		setShowCategoryModal(true);
	};

	const handleSaveCategory = () => {
		if (newCategory.trim() === "") return;
		setShowCategoryModal(false);
		dispatch(createInventoryCategory(newCategory, newCategoryDescription));
		setNewCategory("");
		setNewCategoryDescription("");
	};

	const [fileUrl, setFileUrl] = useState({});
	const [file, setFile] = useState(null);

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			setFile(selectedFile);
		}
	};

	const [loading, setLoading] = useState(false);
	const [customerName, setCustomerName] = useState("");
	const [customerGstId, setCustomerGstId] = useState("");
	const [invoiceDate, setInvoiceDate] = useState("");
	const [invoiceId, setInvoiceId] = useState("");
	const [invoiceTotalAmount, setInvoiceTotalAmount] = useState(0);
	const [invoiceSubTotal, setInvoiceSubTotal] = useState(0);
	const [taxTotalAmount, setTaxTotalAmount] = useState(0);
	const [vendorAddress, setVendorAddress] = useState("");
	const [vendorName, setVendorName] = useState("Choose Supplier");
	const [vendorId, setVendorId] = useState("");
	const [productItems, setProductItems] = useState([]);
	const [taxItems, setTaxItems] = useState([]);

	const handleVendorSelect = (eventKey) => {
		let arr = eventKey?.split("@@");
		setVendorName(arr[0]);
		setVendorId(arr[1]);
	};

	const handleUpload = async () => {
		// const formData = new FormData();
		// formData.append("image", "");
		const fileInput = document.querySelector('input[type="file"]');
		const file = fileInput.files[0];
		const formData = new FormData();
		formData.append("file", file);
		setLoading(true);
		try {
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",

					hospitalname: hospitalname,
				},
			};
			const response = await axios.post(
				`${SERVER_URL}/api/v1/ocrAzureImageToText/upload`,
				formData,
				config
			);
			setLoading(false);
			console.log(response?.data?.data?.data);
			setFileUrl(response?.data?.data?.data);
			// setResult(response?.data?.data[0]);
			const result = response?.data?.data;
			if (result && result.length > 0) {
				// console.log(JSON.stringify(result.documents[0]), 'line35');
				console.log(JSON.stringify(result));
				console.log(result);
				result.forEach((invoice, idx) => {
					console.log(`--------Analyzing invoice #${idx + 1}--------`);
					if (invoice?.fields) {
						setCustomerName(invoice?.fields?.customerName?.content);
						setCustomerGstId(invoice?.fields?.CustomerTaxId?.content);
						setInvoiceDate(invoice?.fields?.InvoiceDate?.content);
						setInvoiceId(invoice?.fields?.InvoiceId?.content);
						setInvoiceTotalAmount(
							invoice?.fields?.InvoiceTotal?.value?.currencyCode +
								" " +
								invoice?.fields?.SubTotal?.value?.amount
						);
						setInvoiceSubTotal(
							invoice?.fields?.SubTotal?.value?.currencyCode +
								" " +
								invoice?.fields?.SubTotal?.value?.amount
						);
						setTaxTotalAmount(
							invoice?.fileds?.TotalTax?.value?.currencyCode +
								" " +
								invoice?.fileds?.TotalTax?.value?.amount
						);
						// setVendorAddress(invoice?.fields?.VendorAddress?.content);
						// setVendorName(invoice?.fields?.VendorName?.content);

						const itemsArray = invoice.fields.Items.values.map(
							(item, index) => {
								const productArray =
									getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
										(product) => {
											return (
												product?.name?.toLowerCase() ===
												item?.properties?.Description?.content?.toLowerCase()
											);
										}
									);
								if (productArray) {
									return {
										serialNo: inventoryData?.length + index,
										productName: item?.properties?.Description?.content,
										hsn: productArray?.hsn_code,
										quantity: item?.properties?.Quantity?.value,
										cost: item?.properties?.UnitPrice?.value?.amount,
										totalAmount: item?.properties?.Amount?.value?.amount,
										expiryDate: formatDateForInput(
											item?.properties?.Date?.content
										),
										sgst: "",
										cgst: "",
										freeItem: 0,
										batchNoFromUser: "",
										brand: "",
										mrp: productArray?.selling_prices[0]?.selling_price,
										category: productArray?.category,
										categoryId: productArray?.categoryId,
										existingCategory: true,
										unitsOfMeasurement: "",
										minStockLevel: 0,
										medicalComposition: "",
										rackNo: "",
										description: "",
									};
								} else
									return {
										serialNo: inventoryData?.length + index,
										productName: item?.properties?.Description?.content,
										hsn: item?.properties?.ProductCode?.content
											? item?.properties?.ProductCode?.content
											: "",
										quantity: item?.properties?.Quantity?.value,
										cost: item?.properties?.UnitPrice?.value?.amount,
										totalAmount: item?.properties?.Amount?.value?.amount,
										expiryDate: formatDateForInput(
											item?.properties?.Date?.content
										),
										sgst: "",
										cgst: "",
										freeItem: 0,
										batchNoFromUser: "",
										brand: "",
										mrp: "",
										category: "",
										categoryId: "",
										existingCategory: false,
										unitsOfMeasurement: "",
										minStockLevel: 0,
										medicalComposition: "",
										rackNo: "",
										description: "",
									};
							}
						);
						// setProductItems(itemsArray);

						setInventoryData((prevData) => [
							...prevData.filter((item) => item.productName !== ""),
							...itemsArray.filter((item) => item.productName !== ""),
						]);

						// itemsArray?.map((product, index) => {
						// 	setRowFilteredProductStock((prevData) => [
						// 		...prevData,
						// 		product?.productName,
						// 	]);
						// });

						const taxArrary = invoice?.fields?.TaxDetails?.values?.map(
							(item) => {
								return {
									amount: item?.properties?.Amount?.value?.amount,
									taxRate: item?.properties?.TaxRate?.content,
								};
							}
						);
						setTaxItems(taxArrary);
					}
				});
			} else {
				console.log("No invoice fields were found.");
			}
		} catch (error) {
			console.error("Upload failed:", error);
			allMessagesFailure("Upload Failed");
		}
		handleCloseUploadAttachements();
	};

	function formatDateForInput(expiryDate) {
		if (!expiryDate) return ""; // Handle empty or undefined expiryDate

		// Split the expiryDate string to get MM and YY
		const [month, year] = expiryDate.split("/");

		// Prefix with '20' for year to handle two-digit year format
		const fullYear = `20${year}`;

		// Create the new date in YYYY-MM-DD format with day as '01'
		const formattedDate = `${fullYear}-${month}-01`;

		return formattedDate; // This format is compatible with <input type="date">
	}

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [showUploadAttachements, setShowUploadAttachements] = useState(false);

	const handleCloseUploadAttachements = () => setShowUploadAttachements(false);
	const handleShowUploadAttachements = () => setShowUploadAttachements(true);

	const [showSupplierModal, setShowSupplierModal] = useState(false);

	const handleShowSupplierModal = () => {
		setShowSupplierModal(true);
	};

	const handleCloseSupplierModal = () => {
		setShowSupplierModal(false);
	};

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [ContactNo, setContactNo] = useState("");
	const [address, setAddress] = useState("");
	const [vendorAccNo, setVendorAccNo] = useState("");
	const [vendorIFSC, setVendorIFSC] = useState("");
	const [vendorBankBranch, setVendorBankBranch] = useState("");

	const submitNewSupplier = async () => {
		if (name === "" || ContactNo === "") {
			alert("Please fill all the required fields");
		} else {
			const vendorBankDetails = {
				backAccountNo: vendorAccNo,
				bankIFSC: vendorIFSC,
				bankBranch: vendorBankBranch,
			};
			await dispatch(
				createSupplier(name, email, ContactNo, address, vendorBankDetails)
			);
			await dispatch(getSuppliers());
			setShowSupplierModal(false);
			setName("");
			setEmail("");
			setContactNo("");
			setAddress("");
			setVendorAccNo("");
			setVendorIFSC("");
			setVendorBankBranch("");
		}
	};

	const [showPaymentModal, setShowPaymentModal] = useState(false);

	const handleClosePaymentModal = () => {
		setShowPaymentModal(false);
	};

	useEffect(() => {
		document.querySelectorAll('input[id*="-"]').forEach((input) => {
			// Get the id attribute
			const id = input.getAttribute("id");
			const [index, key] = id.split("-"); // Split the id into index and key

			// Find the corresponding inventoryData item
			const item = inventoryData[index];

			// Check if the value for the current key is empty and the key is not one of the ignored ones
			if (
				item &&
				(item[key] === "" || item[key] === "Select Category") &&
				![
					"freeItem",
					"batchNoFromUser",
					"unitsOfMeasurement",
					"minStockLevel",
					"rackNo",
					"description",
					"medicalComposition",
				].includes(key)
			) {
				console.log("in-vaild");
			} else {
				if (key?.includes("category")) {
					input.props.variant = "success";
				}
				input.classList.remove("is-invalid");
			}
		});

		document.querySelectorAll('button[id*="-category"]').forEach((dropdown) => {
			const id = dropdown.getAttribute("id");
			const [index, key] = id.split("-"); // Split the id into index and key
			const item = inventoryData[index];

			if (item && (item[key] === "" || item[key] === "Select Category")) {
				console.log("in-valid");
			} else {
				dropdown.classList.remove("btn-danger");
				dropdown.classList.remove("dropdownclass");
				dropdown.classList.add("btn-success");
			}
		});
	}, [inventoryData]);

	const [addItemsLoading, setAddItemsLoading] = useState(false);

	const handleSubmit = async () => {
		const hasEmptyField = inventoryData.some((item) => {
			return Object.entries(item)
				.filter(
					([key]) =>
						key !== "freeItem" &&
						key !== "batchNoFromUser" &&
						key !== "unitsOfMeasurement" &&
						key !== "minStockLevel" &&
						key !== "rackNo" &&
						key !== "description" &&
						key !== "medicalComposition" &&
						key !== "sellingPrice"
				)
				.some(([, value]) => value === "");
		});
		console.log(document.querySelectorAll('input[id*="-"]'));
		document.querySelectorAll('input[id*="-"]').forEach((input) => {
			// Get the id attribute
			const id = input.getAttribute("id");
			const [index, key] = id.split("-"); // Split the id into index and key

			// Find the corresponding inventoryData item
			const item = inventoryData[index];

			// Check if the value for the current key is empty and the key is not one of the ignored ones
			if (
				item &&
				item[key] === "" &&
				![
					"freeItem",
					"batchNoFromUser",
					"unitsOfMeasurement",
					"minStockLevel",
					"rackNo",
					"description",
					"medicalComposition",
				].includes(key)
			) {
				input.classList.add("is-invalid");
			} else {
				input.classList.remove("is-invalid");
			}
		});

		document.querySelectorAll('button[id*="-category"]').forEach((dropdown) => {
			const id = dropdown.getAttribute("id");
			const [index, key] = id.split("-"); // Split the id into index and key
			const item = inventoryData[index];

			if (item && (item[key] === "" || item[key] === "Select Category")) {
				if (key === "category") {
					dropdown.classList.add("btn-danger"); // Apply Bootstrap danger variant manually
					dropdown.classList.add("dropdownclass");
					dropdown.classList.remove("btn-success"); // Remove success if it exists
				}
			} else {
				dropdown.classList.remove("btn-danger");
				dropdown.classList.remove("dropdownclass");
				dropdown.classList.add("btn-success");
			}
		});

		if (vendorName === "Choose Supplier") {
			alert("Choose a supplier");
		} else if (hasEmptyField) {
			const firstInvalidInput = document.querySelector(".is-invalid");
			const firstDropdownInvalidInput =
				document.querySelector(".dropdownclass");
			if (firstInvalidInput) {
				firstInvalidInput?.scrollIntoView({
					behavior: "smooth", // Smooth scroll animation
					block: "center", // Center the element in the view
					inline: "center",
				});
			} else {
				firstDropdownInvalidInput?.scrollIntoView({
					behavior: "smooth", // Smooth scroll animation
					block: "center", // Center the element in the view
					inline: "center",
				});
			}
		} else {
			setShowPaymentModal(true);
			const newInventoryItems = inventoryData
				?.filter((item) => {
					// Filter items where product name matches
					const productArrayFromDb =
						getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.filter(
							(x) => x.name === item?.productName
						);

					// Only return true if productArrayFromDb still contains items
					return productArrayFromDb.length === 0;
				})
				.map((item) => ({
					name: item?.productName,
					description: item?.description || "",
					unit:
						item?.unitsOfMeasurement === ""
							? "Pieces"
							: item?.unitsOfMeasurement,
					category: item?.category,
					categoryId: item?.categoryId,
					minStockLevel: item?.minStockLevel ? item?.minStockLevel : 0,
					medical_composition: item?.medicalComposition || "",
					hsn_code: item?.hsn || "",
					favourite: 0, // Set this as per your requirement
					rackNo: item?.rackNo || "",
				}));

			// The resulting `newInventoryItems` should now be a flat array of objects.

			setAddItemsLoading(true);

			(await newInventoryItems?.length) > 0 &&
				dispatch(createInventoryItem(newInventoryItems));
			await dispatch(getInventoryItemsForBilling());
			setAddItemsLoading(false);

			const result = inventoryData?.reduce(
				(acc, item) => {
					const mrp = Number(item?.mrp) || 0;
					const cost = Number(item?.cost) || 0;
					const quantity = Number(item?.quantity) || 0;
					const sgst = Number(item?.sgst) || 0;
					const cgst = Number(item?.cgst) || 0;

					const discount = ((mrp - cost) * quantity).toFixed(2);
					const totalPurchaseAmount = (cost * quantity).toFixed(2);

					// Accumulate the values
					acc.discount += parseFloat(discount); // Convert string back to number
					acc.totalPurchaseAmount += parseFloat(totalPurchaseAmount);
					acc.totalcgst += sgst;
					acc.totalsgst += cgst;

					return acc;
				},
				{ discount: 0, totalPurchaseAmount: 0, totalcgst: 0, totalsgst: 0 } // Initialize acc with 0 values
			);

			// Format final values if needed
			result.discount = result.discount.toFixed(2);
			result.totalPurchaseAmount = result.totalPurchaseAmount.toFixed(2);
			result.totalcgst = result.totalcgst.toFixed(2);
			result.totalsgst = result.totalsgst.toFixed(2);

			console.log(result);

			setTotal_discount_amount(result?.discount);
			setTotalPurchaseAmount(
				(
					Number(result?.totalPurchaseAmount) +
					Number(result?.totalcgst) +
					Number(result?.totalsgst)
				).toFixed(2)
			);

			setTotalAmountAfterDiscount(
				(
					Number(result?.totalPurchaseAmount) +
					Number(result?.totalcgst) +
					Number(result?.totalsgst)
				).toFixed(2)
			);
			setTotalCgst(result?.totalcgst);
			setTotalSgst(result?.totalsgst);
		}
	};

	const handleDiscountChange = (value) => {
		if (Number(value) > Number(TotalPurchaseAmount)) {
			alert("Discount cannot be more than " + Number(TotalPurchaseAmount));
			return;
		}
		setDiscount(Number(value) > 0 ? Number(value) : 0);
		setTotalAmountAfterDiscount(
			(
				Number(TotalPurchaseAmount) - (Number(value) > 0 ? Number(value) : 0)
			)?.toFixed(2)
		);
	};

	const [Total_discount_amount, setTotal_discount_amount] = useState(0);
	const [TotalPurchaseAmount, setTotalPurchaseAmount] = useState(0);
	const [totalSgst, setTotalSgst] = useState(0);
	const [totalCgst, setTotalCgst] = useState(0);
	const [purchaseDate, setPurchaseDate] = useState("");
	const [purchaseTime, setPurchaseTime] = useState("");
	const [reminderDate, setReminderDate] = useState("");
	const [reminderAmount, setReminderAmount] = useState(0);
	const [paidAmount, setPaidAmount] = useState();
	const [paidAmountDate, setPaidAmountDate] = useState("");
	const [modeOfPayment, setModeOfPayment] = useState("Online");
	const [discount, setDiscount] = useState(0);
	const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState(0);

	const isWithinDuration = (inputDate) => {
		const today = new Date();
		const selectedDate = new Date(inputDate);
		const diffInTime = selectedDate.getTime() - today.getTime();
		const diffInDays = diffInTime / (1000 * 3600 * 24);
		return diffInDays;
	};

	const [completePaymentLoading, setCompletePaymentLoading] = useState(false);
	const handleCompletePurchase = async () => {
		if (invoiceId === "") {
			alert("Enter Invoice Number");
			return;
		}
		console.log(inventoryData);
		setCompletePaymentLoading(true);
		const stockExpiringInOneWeek = inventoryData
			?.filter((item) => {
				let diffInDays = isWithinDuration(item?.expiryDate);
				return diffInDays >= 0 && diffInDays <= 7; // Keep only items expiring within a week
			})
			?.map((item, index) => {
				const productArrayFromDb =
					getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
						(x) => x.name === item?.productName
					);

				return {
					name: item?.productName,
					InventoryId: productArrayFromDb?._id,
					supplierId: vendorId,
					supplierName: vendorName,
					quantityLeft: Number(item?.quantity) + Number(item?.freeItem),
					expiryDate: Number(
						item?.expiryDate?.split("-")[0] +
							item?.expiryDate?.split("-")[1] +
							item?.expiryDate?.split("-")[2]
					),
					receivedDate: Number(
						purchaseDate?.split("-")[0] +
							purchaseDate?.split("-")[1] +
							purchaseDate?.split("-")[2]
					),
					batchNo: productArrayFromDb?.selling_prices?.length + 1,
					cost_price: Number(item?.cost),
					brand: item?.brand,
					selling_price: Number(item?.mrp),
					batchNoFromUser: item?.batchNoFromUser,
				};
			});
		const stockExpiringInOneMonth = inventoryData
			?.filter((item) => {
				let diffInDays = isWithinDuration(item?.expiryDate);
				return diffInDays > 7 && diffInDays <= 30; // Keep only items expiring within a week
			})
			?.map((item, index) => {
				const productArrayFromDb =
					getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
						(x) => x.name === item?.productName
					);

				return {
					name: item?.productName,
					InventoryId: productArrayFromDb?._id,
					supplierId: vendorId,
					supplierName: vendorName,
					quantityLeft: Number(item?.quantity) + Number(item?.freeItem),
					batchNoFromUser: item?.batchNoFromUser,
					expiryDate: Number(
						item?.expiryDate?.split("-")[0] +
							item?.expiryDate?.split("-")[1] +
							item?.expiryDate?.split("-")[2]
					),
					receivedDate: Number(
						purchaseDate?.split("-")[0] +
							purchaseDate?.split("-")[1] +
							purchaseDate?.split("-")[2]
					),
					batchNo: productArrayFromDb?.selling_prices?.length + 1,
					cost_price: Number(item?.cost),
					brand: item?.brand,
					selling_price: Number(item?.mrp),
				};
			});
		const stockExpiringInThreeMonths = inventoryData
			?.filter((item) => {
				let diffInDays = isWithinDuration(item?.expiryDate);
				return diffInDays > 30 && diffInDays <= 90; // Keep only items expiring within a week
			})
			?.map((item, index) => {
				const productArrayFromDb =
					getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
						(x) => x.name === item?.productName
					);

				return {
					name: item?.productName,
					InventoryId: productArrayFromDb?._id,
					supplierId: vendorId,
					supplierName: vendorName,
					quantityLeft: Number(item?.quantity) + Number(item?.freeItem),
					batchNoFromUser: item?.batchNoFromUser,
					expiryDate: Number(
						item?.expiryDate?.split("-")[0] +
							item?.expiryDate?.split("-")[1] +
							item?.expiryDate?.split("-")[2]
					),
					receivedDate: Number(
						purchaseDate?.split("-")[0] +
							purchaseDate?.split("-")[1] +
							purchaseDate?.split("-")[2]
					),
					batchNo: productArrayFromDb?.selling_prices?.length + 1,
					cost_price: Number(item?.cost),
					brand: item?.brand,
					selling_price: Number(item?.mrp),
				};
			});
		const stockExpiringInSixMonths = inventoryData
			?.filter((item) => {
				let diffInDays = isWithinDuration(item?.expiryDate);
				return diffInDays > 90 && diffInDays <= 180; // Keep only items expiring within a week
			})
			?.map((item, index) => {
				const productArrayFromDb =
					getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
						(x) => x.name === item?.productName
					);

				return {
					name: item?.productName,
					InventoryId: productArrayFromDb?._id,
					supplierId: vendorId,
					supplierName: vendorName,
					quantityLeft: Number(item?.quantity) + Number(item?.freeItem),
					batchNoFromUser: item?.batchNoFromUser,
					expiryDate: Number(
						item?.expiryDate?.split("-")[0] +
							item?.expiryDate?.split("-")[1] +
							item?.expiryDate?.split("-")[2]
					),
					receivedDate: Number(
						purchaseDate?.split("-")[0] +
							purchaseDate?.split("-")[1] +
							purchaseDate?.split("-")[2]
					),
					batchNo: productArrayFromDb?.selling_prices?.length + 1,
					cost_price: Number(item?.cost),
					brand: item?.brand,
					selling_price: Number(item?.mrp),
				};
			});
		const products = inventoryData?.map((item, index) => {
			return {
				ProductID:
					getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
						(x) => x.name === item?.productName
					)?._id,
				productName: item?.productName,
				QuantityPurchased: Number(item?.quantity) + Number(item?.freeItem),
				batchNoFromUser: item?.batchNoFromUser,
				costPrice: Number(item?.cost),
				expiryDate: Number(
					item?.expiryDate?.split("-")[0] +
						item?.expiryDate?.split("-")[1] +
						item?.expiryDate?.split("-")[2]
				),
				sellingPrice: Number(item?.mrp),
				brand: item?.brand,
				freeItemsGiven: Number(item?.freeItem),
				sgst: Number(item?.sgst),
				cgst: Number(item?.cgst),
			};
		});
		const partial_payments = [
			{
				Payment_amount: Number(paidAmount),
				modeOfpayment: modeOfPayment,
				Discount: discount,
				Paid_date: Number(
					paidAmountDate?.split("-")[0] +
						paidAmountDate?.split("-")[1] +
						paidAmountDate?.split("-")[2]
				),
			},
		];
		const reminders = [
			{
				date: Number(
					reminderDate?.split("-")[0] +
						reminderDate?.split("-")[1] +
						reminderDate?.split("-")[2]
				),
				payment_amount: Number(reminderAmount),
			},
		];
		const taxes = {
			CGST: Number(totalCgst),
			SGST: Number(totalSgst),
		};
		const updatedInventoryItems = inventoryData?.map((item, index) => {
			const targetDateStr =
				item?.expiryDate?.split("-")[0] +
				item?.expiryDate?.split("-")[1] +
				item?.expiryDate?.split("-")[2];
			const today = new Date();
			const year = parseInt(targetDateStr.slice(0, 4), 10);
			const month = parseInt(targetDateStr.slice(4, 6), 10) - 1; // Month is 0-indexed in JS Date
			const day = parseInt(targetDateStr.slice(6, 8), 10);
			const targetDate = new Date(year, month, day);
			const differenceInTime = targetDate - today;
			const differenceInDays = Math.ceil(
				differenceInTime / (1000 * 60 * 60 * 24)
			);
			const productArrayFromDb =
				getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
					(x) => x.name === item?.productName
				);
			return {
				ProductID: productArrayFromDb?._id,
				categoryId: productArrayFromDb?.categoryId,
				totalTax: Number(item?.cgst) + Number(item?.sgst),
				newstockBatch: {
					supplierId: vendorId,
					supplierName: vendorName,
					quantityLeft: Number(item?.quantity) + Number(item?.freeItem),
					batchNoFromUser: item?.batchNoFromUser,
					expiryDate: Number(
						item?.expiryDate?.split("-")[0] +
							item?.expiryDate?.split("-")[1] +
							item?.expiryDate?.split("-")[2]
					),
					receivedDate: Number(
						purchaseDate?.split("-")[0] +
							purchaseDate?.split("-")[1] +
							purchaseDate?.split("-")[2]
					),
					expiring_index:
						differenceInDays <= 7
							? 1
							: differenceInDays > 7 && differenceInDays <= 30
							? 2
							: differenceInDays > 30 && differenceInDays <= 90
							? 3
							: differenceInDays > 90 && differenceInDays < 180
							? 6
							: 7,
					cost_price: Number(item?.cost),
					brand: item?.brand,
					batchNo: productArrayFromDb?.selling_prices?.length + 1,
					selling_price: Number(item?.mrp),
				},
				newselling_price: {
					supplierId: vendorId,
					supplierName: vendorName,
					quantityLeft: Number(item?.quantity) + Number(item?.freeItem),
					batchNoFromUser: item?.batchNoFromUser,
					expiryDate: Number(
						item?.expiryDate?.split("-")[0] +
							item?.expiryDate?.split("-")[1] +
							item?.expiryDate?.split("-")[2]
					),
					receivedDate: Number(
						purchaseDate?.split("-")[0] +
							purchaseDate?.split("-")[1] +
							purchaseDate?.split("-")[2]
					),
					expiring_index:
						differenceInDays <= 7
							? 1
							: differenceInDays > 7 && differenceInDays <= 30
							? 2
							: differenceInDays > 30 && differenceInDays <= 90
							? 3
							: differenceInDays > 90 && differenceInDays < 180
							? 6
							: 7,
					cost_price: Number(item?.cost),
					brand: item?.brand,
					batchNo: productArrayFromDb?.selling_prices?.length + 1,
					selling_price: Number(item?.mrp),
				},
				purchase_amount:
					Number(item?.cost) *
					(Number(item?.quantity) + Number(item?.freeItem)),
				quantity: Number(item?.quantity) + Number(item?.freeItem),
				rackNo: item?.rackNo,
				unit:
					item?.unitsOfMeasurement === "" ? "Pieces" : item?.unitsOfMeasurement,
				medical_composition: item?.medicalComposition,
				hsn_code: item?.hsn,
				favourite: 0,
				description: item?.description,
				minStockLevel: item?.minStockLevel ? item?.minStockLevel : 0,
			};
		});
		await dispatch(
			newPurchaseFromSupplier(
				products,
				Number(
					purchaseDate?.split("-")[0] +
						purchaseDate?.split("-")[1] +
						purchaseDate?.split("-")[2]
				),
				purchaseTime,
				Number(TotalPurchaseAmount),
				paidAmount,
				Number(totalAmountAfterDiscount) === Number(paidAmount) ? 0 : 1,
				discount,
				invoiceId,

				partial_payments,
				reminders,
				taxes,
				vendorName,
				vendorId,
				modeOfPayment,
				reminders[0]?.date ? reminders[0]?.date : "",
				reminders[0]?.payment_amount,
				Number(totalSgst) + Number(totalCgst),
				updatedInventoryItems,
				stockExpiringInOneWeek,
				stockExpiringInOneMonth,
				stockExpiringInThreeMonths,
				stockExpiringInSixMonths,
				[]
			)
		);
		await dispatch(getInventoryInfo());
		setShowPaymentModal(false);
		setCompletePaymentLoading(true);
		setInventoryData([
			{
				serialNo: 1,
				productName: "",
				quantity: "",
				freeItem: 0,
				batchNoFromUser: "",
				cost: "",
				hsn: "",
				expiryDate: "",
				brand: "",
				sgst: "",
				cgst: "",
				mrp: "",
				totalAmount: "",
				category: "",
				categoryId: "",
				existingCategory: false,
				unitsOfMeasurement: "Pieces",
				minStockLevel: 0,
				medicalComposition: "",
				rackNo: "",
				description: "",
			},
		]);
		setVendorName("Choose Supplier");
		setVendorId("");
		const now = new Date();

		const currentDate = now.toISOString().split("T")[0]; // Format: YYYY-MM-DD
		const currentTime = now.toTimeString().split(" ")[0].slice(0, 5); // Format: HH:MM

		setPurchaseDate(currentDate);
		setPurchaseTime(currentTime);
		setPaidAmountDate(currentDate);
		setPaidAmount(0);
		setTotalPurchaseAmount(0);
		setTotalCgst(0);
		setTotalSgst(0);
		setReminderAmount(0);
		setPaidAmount(0);
		setModeOfPayment("Online");
		setDiscount(0);
		setTotalAmountAfterDiscount(0);
		setReminderDate("");

		setRowProductSearch({});
		setRowFilteredProductStock([]);

		setRowBrandSearch({});
		setRowFilteredBrandStock({});
	};

	const tableContainerRef = useRef(null);
	const [showScrollButtons, setShowScrollButtons] = useState(false);
	const [scrollY, setScrollY] = useState(0);

	const scrollLeft = () => {
		if (tableContainerRef.current) {
			tableContainerRef.current.scrollLeft -=
				tableContainerRef.current.clientWidth;
		}
	};

	const scrollRight = () => {
		if (tableContainerRef.current) {
			tableContainerRef.current.scrollLeft +=
				tableContainerRef.current.clientWidth;
		}
	};

	const handleScroll = () => {
		if (tableContainerRef.current) {
			const { scrollLeft, scrollWidth, clientWidth } =
				tableContainerRef.current;
			setShowScrollButtons(
				scrollLeft > 0 || scrollLeft < scrollWidth - clientWidth
			);
		}
	};

	const handleWindowScroll = () => {
		setScrollY(window.scrollY);
	};

	useEffect(() => {
		handleScroll(); // Check initially if buttons are needed
		window.addEventListener("scroll", handleWindowScroll);

		return () => {
			window.removeEventListener("scroll", handleWindowScroll);
		};
	}, []);

	useEffect(() => {
		const now = new Date();

		const currentDate = now.toISOString().split("T")[0]; // Format: YYYY-MM-DD
		const currentTime = now.toTimeString().split(" ")[0].slice(0, 5); // Format: HH:MM

		setPurchaseDate(currentDate);
		setPurchaseTime(currentTime);
		setPaidAmountDate(currentDate);
	}, []);

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href='/admin/pharmacypurchaseinventory'>
					New Purchase
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/pastpurchases'>
					Past Purchases
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className='header-center mt-3'>Purchase Inventory</h1>

			<Container className='header-center mt-5 mb-3'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center mt-1'>
						<h6>
							Choose Supplier{" "}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={vendorName}
							onSelect={handleVendorSelect}>
							<div className='dropScroll'>
								{getsuppliers?.getsuppliers?.data?.data.map(
									(supplier, index) => (
										<Dropdown.Item
											eventKey={supplier?.name + "@@" + supplier?._id}>
											{supplier?.name}
										</Dropdown.Item>
									)
								)}
							</div>
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						<button
							className='btn btn-secondary'
							onClick={handleShowSupplierModal}>
							Add New Supplier Details
						</button>
					</Col>
				</Row>
			</Container>
			<Container>
				<Row className='justify-content-end mb-5'>
					<Button variant='primary' onClick={handleShowUploadAttachements}>
						Upload File
					</Button>
				</Row>
			</Container>
			<div style={{ position: "relative", width: "100%", overflow: "hidden" }}>
				{showScrollButtons && (
					<Button
						variant='primary'
						onClick={scrollLeft}
						className='ml-3'
						title='Scroll Left'
						style={{
							position: "fixed",
							top: `calc(50% + ${scrollY}px)`,
							transform: "translateY(-50%)",
							zIndex: 1,
						}}>
						<i className='fa fa-arrow-left'></i>
					</Button>
				)}
				<div
					ref={tableContainerRef}
					onScroll={handleScroll}
					style={{
						overflowX: "auto",
						overflowY: "hidden",
						whiteSpace: "nowrap",
						width: "90%",
						margin: "0 auto",
					}}>
					<Table
						bordered
						className='custom-table'
						style={{ width: "270%", marginBottom: "15%" }}>
						<thead>
							<tr>
								<th scope='col'>#</th>
								<th scope='col'>Action</th>
								<th scope='col' style={{ width: "20%" }}>
									Product Name{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</th>
								<th scope='col'>
									Total Quantity{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</th>
								<th scope='col'>Free Items Given</th>
								<th scope='col'>
									Expiry Date{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</th>
								<th scope='col' style={{ width: "10%" }}>
									HSN{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</th>
								<th scope='col' style={{ width: "15%" }}>
									Brand{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</th>
								<th scope='col'>
									M.R.P (per unit){" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</th>
								<th scope='col'>
									Cost (per unit){" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</th>
								<th scope='col'>
									Total Amount{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</th>
								<th scope='col'>
									SGST (RS per unit){" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</th>
								<th scope='col'>
									CGST (RS per unit){" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</th>
								<th scope='col'>
									Category{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</th>
								<th scope='col' style={{ width: "10%" }}>
									Batch No
								</th>
								<th scope='col'>Units of Measurement</th>
								<th scope='col'>Min Stock Level</th>

								<th scope='col'>Rack No</th>
								<th scope='col'>Product Description</th>
								<th scope='col' style={{ width: "20%" }}>
									Medical Composition
								</th>
							</tr>
						</thead>
						<tbody>
							{inventoryData.map((item, index) => (
								<tr key={index}>
									<td>{index + 1}</td>
									<td>
										<Button
											variant='danger'
											onClick={() => deleteRow(index)}
											title='Delete'>
											<i className='fa fa-trash'></i>
										</Button>
									</td>
									<td>
										<Form.Group className='position-relative'>
											<Form.Control
												id={`${index}-productName`}
												type='text'
												name='productName'
												value={item.productName}
												onChange={(e) => {
													handleInputChange(index, e);
												}}
												onKeyUp={(e) => handleProductSearchChange(index, e)}
												autoComplete='off'
											/>
											{rowProductSearch[index] &&
												rowFilteredProductStock[index]?.length > 0 &&
												rowFilteredProductStock[index] &&
												rowFilteredProductStock[index]?.find(
													(product) =>
														product.name?.toLowerCase() !==
														item.productName?.toLowerCase()
												) && (
													<ListGroup
														className='position-relative dropScroll'
														style={{ zIndex: 100 }}>
														{rowFilteredProductStock[index].map((product) => (
															<ListGroup.Item
																key={product.name}
																action
																onClick={() =>
																	handleSelectProduct(index, product)
																}>
																{product.name}
															</ListGroup.Item>
														))}
													</ListGroup>
												)}
										</Form.Group>
									</td>
									<td>
										<Form.Control
											type='number'
											id={`${index}-quantity`}
											name='quantity'
											value={item.quantity}
											onChange={(e) => {
												handleInputChange(index, e);
											}}
										/>
									</td>
									<td>
										<Form.Control
											type='number'
											name='freeItem'
											value={item.freeItem}
											onChange={(e) => {
												handleInputChange(index, e);
											}}
										/>
									</td>
									<td>
										<Form.Control
											type='date'
											name='expiryDate'
											id={`${index}-expiryDate`}
											value={item.expiryDate}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>
									<td>
										<Form.Control
											type='text'
											name='hsn'
											id={`${index}-hsn`}
											value={item.hsn}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>
									<td>
										<Form.Group className='position-relative'>
											<Form.Control
												type='text'
												name='brand'
												id={`${index}-brand`}
												value={item.brand}
												onChange={(e) => handleInputChange(index, e)}
												onKeyUp={(e) => handleBrandSearchChange(index, e)}
												autoComplete='off'
											/>
											{rowBrandSearch[index] &&
												rowFilteredBrandStock[index]?.length > 0 &&
												rowFilteredBrandStock[index]?.find(
													(product) =>
														product?.toLowerCase() !==
														item?.brand?.toLowerCase()
												) && (
													<ListGroup
														className='position-relative dropScroll'
														style={{ zIndex: 100 }}>
														{rowFilteredBrandStock[index].map((brand) => (
															<ListGroup.Item
																key={brand}
																action
																onClick={() => handleSelectBrand(index, brand)}>
																{brand}
															</ListGroup.Item>
														))}
													</ListGroup>
												)}
										</Form.Group>
									</td>

									<td>
										<Form.Control
											type='number'
											name='mrp'
											value={item.mrp}
											id={`${index}-mrp`}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>
									<td>
										<Form.Control
											type='number'
											name='cost'
											value={item.cost}
											id={`${index}-cost`}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>
									<td>
										<Form.Control
											type='number'
											name='totalAmount'
											value={item.totalAmount}
											id={`${index}-totalAmount`}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>
									<td>
										<Form.Control
											type='number'
											name='sgst'
											value={item.sgst}
											id={`${index}-sgst`}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>
									<td>
										<Form.Control
											type='number'
											name='cgst'
											value={item.cgst}
											id={`${index}-cgst`}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>
									<td>
										<DropdownButton
											variant='success'
											id={`${index}-category`}
											title={item.category || "Select Category"}
											name='category'
											onSelect={(e) => handleInputChange(index, e, true)}
											disabled={item?.existingCategory}>
											<div className='dropScroll position-relative'>
												{getInventorycategory?.getInventorycategory?.data?.data?.map(
													(item) => {
														if (item?.name !== "hospital") {
															return (
																<Dropdown.Item
																	eventKey={item?.name + "@@" + item?._id}>
																	{item?.name}
																</Dropdown.Item>
															);
														}
														return null;
													}
												)}
												<Dropdown.Item
													onClick={handleAddCategory}
													eventKey='Add New Category'>
													Add New Category
												</Dropdown.Item>
											</div>
										</DropdownButton>
									</td>
									<td>
										<Form.Control
											type='text'
											name='batchNoFromUser'
											value={item.batchNoFromUser}
											onChange={(e) => {
												handleInputChange(index, e);
											}}
										/>
									</td>
									<td>
										<Form.Control
											type='text'
											name='unitsOfMeasurement'
											value={item?.unitsOfMeasurement}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>

									<td>
										<Form.Control
											type='text'
											name='minStockLevel'
											value={item?.minStockLevel}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>

									<td>
										<Form.Control
											type='text'
											name='rackNo'
											value={item?.rackNo}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>
									<td>
										<Form.Control
											type='text'
											name='description'
											value={item?.description}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>
									<td>
										<Form.Control
											type='text'
											name='medicalComposition'
											value={item?.medicalComposition}
											onChange={(e) => handleInputChange(index, e)}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
				{showScrollButtons && (
					<Button
						variant='primary'
						className='mr-4'
						onClick={scrollRight}
						title='Scroll Right'
						style={{
							position: "fixed",
							right: 0,
							top: `calc(50% + ${scrollY}px)`,
							transform: "translateY(-50%)",
							zIndex: 1,
						}}>
						<i className='fa fa-arrow-right'></i>
					</Button>
				)}
			</div>
			<Row className='justify-content-center mt-3'>
				<Button variant='primary' onClick={addRow}>
					Add Row
				</Button>
			</Row>

			<Row className='justify-content-center mt-3 mb-3'>
				<Button variant='primary' onClick={handleSubmit}>
					Complete Purchase {addItemsLoading && <ButtonLoader></ButtonLoader>}
				</Button>
			</Row>

			{/* Add Category Modal */}
			<Modal
				show={showCategoryModal}
				onHide={() => setShowCategoryModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Add New Category</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className='mb-3'>
							<Form.Label>Category Name</Form.Label>
							<Form.Control
								type='text'
								value={newCategory}
								onChange={(e) => setNewCategory(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Description</Form.Label>
							<Form.Control
								type='text'
								value={newCategoryDescription}
								onChange={(e) => setNewCategoryDescription(e.target.value)}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => setShowCategoryModal(false)}>
						Close
					</Button>
					<Button variant='primary' onClick={handleSaveCategory}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showPaymentModal} onHide={handleClosePaymentModal} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>
						Purchase Inventory{" "}
						{addItemsLoading && <ButtonLoader></ButtonLoader>}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='row mt-2'>
						<div className='col-5 col-md-3 cardHeading'>
							Total Amount (including taxes):
						</div>
						<div className='col-8'>
							<input
								type='text'
								disabled
								className='form-control'
								value={`Rs ${TotalPurchaseAmount}`}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-5 col-md-3 cardHeading'>Total Taxes:</div>
						<div className='col-8'>
							<input
								type='text'
								disabled
								className='form-control'
								value={`Rs ${Number(totalSgst) + Number(totalCgst)}`}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-5 col-md-3 cardHeading'>Purchase Date:</div>
						<div className='col-8'>
							<input
								type='date'
								className='form-control'
								value={purchaseDate}
								onChange={(e) => setPurchaseDate(e?.target?.value)}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-5 col-md-3 cardHeading'>Purchase Time:</div>
						<div className='col-8'>
							<input
								type='time'
								className='form-control'
								value={purchaseTime}
								onChange={(e) => setPurchaseTime(e?.target?.value)}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-5 col-md-3 cardHeading'>
							Invoice No:{" "}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</div>
						<div className='col-8'>
							<input
								type='text'
								className='form-control'
								value={invoiceId}
								onChange={(e) => setInvoiceId(e?.target?.value)}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-5 col-md-3 cardHeading'>Discount (Rs):</div>
						<div className='col-8'>
							<input
								type='number'
								className='form-control'
								value={discount}
								onChange={(e) => handleDiscountChange(e?.target?.value)}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-5 col-md-3 cardHeading'>
							Total amount after discount (including taxes):
						</div>
						<div className='col-8'>
							<input
								type='text'
								disabled
								className='form-control'
								value={`Rs ${totalAmountAfterDiscount}`}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-5 col-md-3 cardHeading'>
							Amount Paid:{" "}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</div>
						<div className='col-8'>
							<input
								type='number'
								className='form-control'
								value={paidAmount}
								onChange={(e) => {
									const paidValue = Number(e?.target?.value);

									if (paidValue < Number(totalAmountAfterDiscount)) {
										setPaidAmount(paidValue);
										const now = new Date();
										now.setDate(now.getDate() + 7); // Add 7 days to the current date
										const reminderDate = now.toISOString().split("T")[0]; // Format: YYYY-MM-DD

										setReminderDate(reminderDate);
										setReminderAmount(
											(Number(totalAmountAfterDiscount) - paidValue)?.toFixed(2)
										);
									} else if (paidValue > Number(totalAmountAfterDiscount)) {
										alert("Amount paid cannot be greater than total amount");
									} else {
										setPaidAmount(paidValue);
										setReminderDate("");
										setReminderAmount("");
									}
								}}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-5 col-md-3 cardHeading'>Reminder:</div>
						<div className='col-4'>
							<input
								type='date'
								className='form-control'
								value={reminderDate}
								onChange={(e) => setReminderDate(e?.target?.value)}
							/>
						</div>
						<div className='col-4'>
							<input
								type='number'
								className='form-control'
								value={reminderAmount}
								onChange={(e) => setReminderAmount(e?.target?.value)}
							/>
						</div>
					</div>

					<div className='row mt-2'>
						<div className='col-5 col-md-3 cardHeading'>Amount Paid Date:</div>
						<div className='col-8'>
							<input
								type='date'
								className='form-control'
								value={paidAmountDate}
								onChange={(e) => setPaidAmountDate(e?.target?.value)}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-5 col-md-3 cardHeading'>Mode Of Payment:</div>
						<div className='col-8'>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={modeOfPayment}
								onSelect={setModeOfPayment}>
								<Dropdown.Item eventKey={"Cash"}>Cash</Dropdown.Item>
								<Dropdown.Item eventKey={"Online"}>Online</Dropdown.Item>
							</DropdownButton>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='primary'
						onClick={handleCompletePurchase}
						disabled={addItemsLoading}>
						Complete Payment{" "}
						{completePaymentLoading && <ButtonLoader></ButtonLoader>}
					</Button>
					<Button variant='secondary' onClick={handleClosePaymentModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showSupplierModal}
				onHide={handleCloseSupplierModal}
				size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>Add New Supplier</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className='form-group' noValidate='true'>
						<Container className='mt-3'>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Name: </b>
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<input
										className='form-control'
										id='speciality'
										type='text'
										required='required'
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Contact Number: </b>
										<span className='text-danger'>
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<input
										className='form-control'
										id='speciality'
										type='number'
										required='required'
										value={ContactNo}
										onChange={(e) => setContactNo(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Email: </b>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<input
										className='form-control'
										id='speciality'
										type='text'
										required='required'
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Address: </b>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<textarea
										className='form-control'
										id='speciality'
										type='text'
										required='required'
										value={address}
										onChange={(e) => setAddress(e.target.value)}></textarea>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Bank A/C: </b>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<textarea
										className='form-control'
										id='speciality'
										type='text'
										required='required'
										value={vendorAccNo}
										onChange={(e) => setVendorAccNo(e.target.value)}></textarea>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>IFS Code: </b>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<textarea
										className='form-control'
										id='speciality'
										type='text'
										required='required'
										value={vendorIFSC}
										onChange={(e) => setVendorIFSC(e.target.value)}></textarea>
								</Col>
							</Row>
							<Row className='justify-content-center'>
								<Col md='4' className=' mt-1'>
									<label htmlFor='name'>
										<b>Branch: </b>
									</label>
								</Col>
								<Col md='6' className=' mt-1'>
									<textarea
										className='form-control'
										id='speciality'
										type='text'
										required='required'
										value={vendorBankBranch}
										onChange={(e) =>
											setVendorBankBranch(e.target.value)
										}></textarea>
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleCloseSupplierModal}>
						Close
					</Button>
					<Button variant='primary' onClick={submitNewSupplier}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showUploadAttachements}
				onHide={handleCloseUploadAttachements}
				size='md'>
				<Modal.Header closeButton>
					<Modal.Title>Upload Attachments</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col md='auto'>
							<input type='file' name='file' onChange={handleFileChange} />
						</Col>
						<Col md='auto'>
							<button
								className='btn btn-primary'
								onClick={() => handleUpload(file)}>
								{!loading && <>Upload</>}{" "}
								{loading && (
									<>
										Uploading <ButtonLoader></ButtonLoader>
									</>
								)}
							</button>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleCloseUploadAttachements}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default PurchaseInventoryPharmacyScreens;
