/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createPrescriptionTemplate = createSlice({
	name: 'prescriptionTemplateInfo',
	initialState: {},
	reducers: {
		createPrescriptionTemplateStart: (state) => {
			state.loading = true;
		},
		createPrescriptionTemplateSuccess: (state, action) => {
			state.loading = false;
			state.prescriptionTemplateInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		createPrescriptionTemplateFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createPrescriptionTemplateReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createPrescriptionTemplateStart,
	createPrescriptionTemplateSuccess,
	createPrescriptionTemplateFailure,
	createPrescriptionTemplateReset,
} = createPrescriptionTemplate.actions;
export default createPrescriptionTemplate.reducer;
