/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getVitals = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getVitalsStart: (state) => {
			state.loading = true;
		},
		getVitalsSuccess: (state, action) => {
			state.loading = false;
			state.allVitals = action.payload;
			state.error = false;
			state.success = true;
		},
		getVitalsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getVitalsReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getVitalsStart,
	getVitalsSuccess,
	getVitalsFailure,
	getVitalsReset,
} = getVitals.actions;
export default getVitals.reducer;
