/** @format */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getAllPurchases } from '../../../redux/inventoryRedux/inventoryApiCalls';
import { Breadcrumb } from 'react-bootstrap';
import Meta from '../../../Components/Meta';
import { Row } from 'react-bootstrap';

const formatDate = (dateString) => {
	if (!dateString) return ''; // Handle edge case where dateString is falsy

	// Convert dateString to string format if it's not already
	dateString = dateString.toString();

	if (dateString.length === 8) {
		// Assuming dateString is in yyyymmdd format
		const year = dateString.substring(0, 4);
		const month = dateString.substring(4, 6);
		const day = dateString.substring(6, 8);
		return `${day}-${month}-${year}`;
	} else {
		// Return empty string if dateString is not in expected format
		return '';
	}
};

const PastPurchaseInventoryScreens = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAllPurchases());
	}, [dispatch]);

	const { getAllpurchase } = useSelector((state) => state.getAllPurchases);

	console.log(getAllpurchase);

	// Flatten the data to have each product as a separate row
	let rowData = [];
	getAllpurchase?.data?.data.forEach((purchase) => {
		purchase.products.forEach((product) => {
			rowData.push({
				PurchaseDateTime: `${formatDate(purchase.PurchaseDate)} ${
					purchase.PurchaseTime
				}`, // Combine PurchaseDate and PurchaseTime
				InvoiceId: purchase.InvoiceId,
				TotalPurchaseAmount: purchase.TotalPurchaseAmount,
				TotalDiscountAmount: purchase.Total_discount_amount,
				PartialAmountPaid: purchase.partial_amount_paid,
				PaymentMode: purchase.partial_payments[0]?.modeOfpayment,
				QuantityPurchased: product.QuantityPurchased,
				BrandName: product.brand,
				CostPrice: product.costPrice,
				ExpiryDate: formatDate(product.expiryDate), // Format ExpiryDate
				ProductName: product.productName,
				TotalCost: product.costPrice * product.QuantityPurchased,
			});
		});
	});

	const columnDefs = [
		{
			headerName: 'S.No',
			valueGetter: (params) => params.node.rowIndex + 1,
			minWidth: 90,
			sortable: false,
		},
		{
			headerName: 'Purchase Date',
			field: 'PurchaseDateTime',
			minWidth: 180,
			sortable: true,
			filter: true,
		},
		{
			headerName: 'Invoice ID',
			field: 'InvoiceId',
			minWidth: 120,
			sortable: true,
			filter: true,
		},
		{
			headerName: 'Purchase Amount',
			field: 'TotalPurchaseAmount',
			minWidth: 180,
			sortable: true,
			filter: true,
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
		},
		{
			headerName: 'Discount Amount',
			field: 'TotalDiscountAmount',
			minWidth: 180,
			sortable: true,
			filter: true,
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
		},
		{
			headerName: 'Partial Amount Paid',
			field: 'PartialAmountPaid',
			minWidth: 180,
			sortable: true,
			filter: true,
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
		},
		{
			headerName: 'Payment Mode',
			field: 'PaymentMode',
			minWidth: 180,
			sortable: true,
			filter: true,
		},
		{
			headerName: 'Quantity Purchased',
			field: 'QuantityPurchased',
			minWidth: 180,
			sortable: true,
			filter: true,
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
		},
		{
			headerName: 'Brand Name',
			field: 'BrandName',
			minWidth: 180,
			sortable: true,
			filter: true,
		},
		{
			headerName: 'Cost Price/Piece',
			field: 'CostPrice',
			minWidth: 180,
			sortable: true,
			filter: true,
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
		},
		{
			headerName: 'Total Cost',
			field: 'TotalCost',
			minWidth: 180,
			sortable: true,
			filter: true,
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
		},
		{
			headerName: 'Expiry Date',
			field: 'ExpiryDate',
			minWidth: 180,
			sortable: true,
			filter: true,
		},
		{
			headerName: 'Product Name',
			field: 'ProductName',
			minWidth: 180,
			sortable: true,
			filter: true,
		},
	];

	const defaultColDef = {
		sortable: true,
		filter: true,
		resizable: true,
		flex: 1,
		floatingFilter: true,
	};

	const gridOptions = {
		columnDefs: columnDefs,
		defaultColDef: defaultColDef,
		rowData: rowData,
		pagination: true,
		paginationPageSize: 10,
		enableRangeSelection: true,
	};

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href='/admin/pharmacypurchaseinventory'>
					New Purchase
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/pastpurchases'>
					Past Purchases
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className='header-center mt-3'>Past Purchase Inventory</h1>
			<Row className='mt-5 justify-content-center'>
				<div
					className='ag-theme-alpine'
					style={{ height: '600px', width: '90%' }}>
					<AgGridReact {...gridOptions} />
				</div>
			</Row>
		</>
	);
};

export default PastPurchaseInventoryScreens;
