/** @format */

import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormControl,
  FormLabel,
  FormCheck,
  Row,
  Col,
  Modal,
  Container,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { MultiSelect } from "react-multi-select-component";
import "../../../styles/FormBuilder.css"; // Import your CSS file for styling
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PrescriptionCreator } from "../../../redux/doctorRedux/doctorApiCalls";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  GetPrescriptionTemplateNames,
  GetPrescriptionTemplate,
} from "../../../redux/doctorRedux/doctorApiCalls";
import {
  getHistoryTemplateInSpeciality,
  editHistoryTemplateInSpeciality,
} from "../../../redux/historyRedux/historyApiCalls";
import Meta from "../../../Components/Meta";

const HistoryEditorScreens = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { doctorInfo } = useSelector((state) => state.doctorLogin);

  const [templateName, setTemplateName] = useState("Choose Template");
  const [templateId, setTemplateId] = useState("");
  const [templateIdx, setTemplateIdx] = useState();
  const handleTemplateName = (eventKey) => {
    let arr = eventKey?.split("@@");
    setTemplateName(arr[0]);
    setTemplateId(arr[1]);
    setTemplateIdx(arr[2]);
    dispatch(
      getHistoryTemplateInSpeciality(
        doctorInfo?.data?.user?.specialityId[0],
        arr[2]
      )
    );
  };
  useEffect(() => {
    dispatch(
      GetPrescriptionTemplateNames(doctorInfo?.data?.user?.specialityId[0])
    );
  }, []);

  const { getPrescriptionTemplateNames } = useSelector(
    (state) => state.GetPrescriptionTemplateNames
  );

  console.log(getPrescriptionTemplateNames);

  const { getHistoryTemplateinSpeciality, loading } = useSelector(
    (state) => state.getHistoryTemplateInSpeciality
  );

  useEffect(() => {
    setFormElements(
      getHistoryTemplateinSpeciality?.data?.data?.historyTemplates[0]?.template
    );
  }, [loading]);

  const [formElements, setFormElements] = useState([]);
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editLabel, setEditLabel] = useState("");
  const [editOptions, setEditOptions] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const [editHeaderValue, setEditHeaderValue] = useState("");
  const [editParagraphValue, setEditParagraphValue] = useState("");
  const [draggingElement, setDraggingElement] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [editModalOptions, setEditModalOptions] = useState([]);
  const [newOption, setNewOption] = useState("");
  const [showAddOptionInput, setShowAddOptionInput] = useState(false);

  console.log(selectedOptions);

  const addFormElement = (elementType, label = "Label Name") => {
    let defaultOptions = [];
    if (
      elementType === "radio" ||
      elementType === "checkbox" ||
      elementType === "select" ||
      elementType === "multiselect"
    ) {
      defaultOptions = ["Option 1", "Option 2", "Option 3"];
    }
    setFormElements([
      ...formElements,
      {
        type: elementType,
        label:
          elementType === "header"
            ? "Header"
            : elementType === "paragraph"
            ? "Paragraph"
            : label,
        value:
          elementType === "header"
            ? "Header"
            : elementType === "paragraph"
            ? "Paragraph"
            : "",
        options: elementType === "header" ? [] : defaultOptions,
        required: false,
        rows: 3,
        cols: 3,
        cellData: {},
      },
    ]);
  };

  const handleDragStart = (event, elementType) => {
    event.dataTransfer.setData("elementType", elementType);
    setDraggingElement(elementType);
  };

  const handleValueChange = (event, index) => {
    const templateArray = [...formElements];

    const updatedFormData = templateArray.map((element, arrIndex) => {
      if (arrIndex === index) {
        return { ...element, value: event?.target?.value };
      }
      return element;
    });
    let templates = [...formElements];
    templates = updatedFormData;

    setFormElements(templates);
  };

  const handleCheckboxRadioChange = (event, option, index) => {
    if (event.target.type === "checkbox") {
      // For checkboxes, toggle the selected option
      const selectedOptions = formElements[index]?.value || [];
      const updatedOptions = event.target.checked
        ? [...selectedOptions, option]
        : selectedOptions.filter((item) => item !== option);

      const templateArray = formElements;

      const updatedFormData = templateArray.map((element, arrIndex) => {
        if (arrIndex === index) {
          return { ...element, value: updatedOptions };
        }
        return element;
      });
      let templates = [...formElements];
      templates = updatedFormData;

      setFormElements(templates);
    } else if (event.target.type === "radio") {
      // For radio buttons, select only the clicked option
      const templateArray = formElements;

      const updatedFormData = templateArray.map((element, arrIndex) => {
        if (arrIndex === index) {
          return { ...element, value: option };
        }
        return element;
      });
      let templates = [...formElements];
      templates = updatedFormData;

      setFormElements(templates);
    }
  };

  const handleMultiselectChange = (selectedOptions, index) => {
    // Extract labels and values from selectedOptions
    const selectedValues = selectedOptions.map((option) => ({
      label: option.label,
      value: option.value,
    }));

    const templateArray = formElements;

    const updatedFormData = templateArray.map((element, arrIndex) => {
      if (arrIndex === index) {
        return { ...element, value: selectedValues };
      }
      return element;
    });
    let templates = [...formElements];
    templates = updatedFormData;

    setFormElements(templates);
  };

  // Handler for select
  const handleSelectChange = (event, index) => {
    const templateArray = formElements;

    const updatedFormData = templateArray.map((element, arrIndex) => {
      if (arrIndex === index) {
        return { ...element, value: event?.target?.value };
      }
      return element;
    });
    let templates = [...formElements];
    templates = updatedFormData;

    setFormElements(templates);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const elementType = event.dataTransfer.getData("elementType");
    if (elementType === "header") {
      addFormElement("header");
    } else if (elementType === "paragraph") {
      addFormElement("paragraph");
      setDraggingElement(false);
    } else {
      addFormElement(elementType);
    }
    setDraggingElement("");
  };

  const handleItemClick = (elementType) => {
    addFormElement(elementType);
  };

  const handleFormLabelChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleLabelChange = (event, index) => {
    const updatedElements = [...formElements];
    if (updatedElements[index]) {
      updatedElements[index].label = event.target.value;
      setFormElements(updatedElements);
    }
  };

  const handleOptionChange = (event, index) => {
    const updatedOptions = [...editOptions];
    updatedOptions[index] = event.target.value;
    setEditOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setShowAddOptionInput(true);
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = [...editOptions];
    updatedOptions.splice(index, 1);
    setEditOptions(updatedOptions);
  };

  const handleDeleteElement = (index) => {
    const updatedElements = [...formElements];
    updatedElements.splice(index, 1);
    setFormElements(updatedElements);
    setSelectedElementIndex(null);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleEditModalSave = () => {
    if (selectedElementIndex !== null) {
      const templateArray = formElements;
      if (
        templateArray[selectedElementIndex] &&
        templateArray[selectedElementIndex].type !== "header" &&
        templateArray[selectedElementIndex].type !== "paragraph"
      ) {
        const updatedFormData = templateArray.map((element, arrIndex) => {
          if (arrIndex === selectedElementIndex) {
            return {
              ...element,
              label: editLabel,
              options: editOptions,
              required: isRequired,
            };
          }
          return element;
        });
        let templates = [...formElements];
        templates = updatedFormData;

        setFormElements(templates);
        handleEditModalClose();
        handleEditModalClose();
      }
    }
  };

  const openEditModal = (index) => {
    setSelectedElementIndex(index);
    if (formElements?.[index]) {
      setEditLabel(formElements?.[index].label);
      setEditOptions(formElements?.[index].options);
      setIsRequired(formElements?.[index].required);
      setEditHeaderValue(formElements?.[index].value);
      setEditParagraphValue(formElements?.[index].value);
    }
    setShowEditModal(true);
  };

  const handleSubmitForm = () => {
    console.log({ templateName, formElements });
    // Submit form data here
    dispatch(
      editHistoryTemplateInSpeciality(
        formElements,
        templateName,
        templateIdx,
        doctorInfo?.data?.user?.specialityId
      )
    );
    // window?.location?.reload();
    setTemplateId("");
    setTemplateName("Choose Template");
    setFormElements([]);
  };

  const addHeaderToForm = () => {
    addFormElement("header");
  };

  const addParagraphToForm = () => {
    addFormElement("paragraph");
  };

  const handleHeaderEditModalSave = () => {
    if (selectedElementIndex !== null) {
      const templateArray = formElements;

      const updatedFormData = templateArray.map((element, arrIndex) => {
        if (arrIndex === selectedElementIndex) {
          return { ...element, value: editHeaderValue };
        }
        return element;
      });
      let templates = [...formElements];
      templates = updatedFormData;

      setFormElements(templates);
      handleEditModalClose();
    }
  };

  const handleParagraphEditModalSave = () => {
    if (selectedElementIndex !== null) {
      const templateArray = formElements;

      const updatedFormData = templateArray.map((element, arrIndex) => {
        if (arrIndex === selectedElementIndex) {
          return { ...element, value: editParagraphValue };
        }
        return element;
      });
      let templates = [...formElements];
      templates = updatedFormData;

      setFormElements(templates);
      handleEditModalClose();
    }
  };
  // Handle cell data updates
  const handleCellChange = (row, col, value, index) => {
    const updatedElements = [...formElements];
    if (updatedElements[index]) {
      updatedElements[index].cellData[`${row}-${col}`] = value;
      setFormElements(updatedElements);
    }
  };

  // Handle table resizing while preserving data
  const resizeTable = (newRows, newColumns, index) => {
    const updatedElements = [...formElements];
    if (updatedElements[index]) {
      updatedElements[index].rows = newRows;
      updatedElements[index].cols = newColumns;
      setFormElements(updatedElements);
    }
  };
  return (
    <div className="form-builder">
      <Meta />
      <h2 className="header-center mb-5">Edit History Template</h2>
      <Container className="header-center mt-4 mb-5">
        <Row className="justify-content-center">
          <Col md="auto" className="text-center mt-1">
            <h6>
              Select the filters{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>
          </Col>
          <DropdownButton
            key="down-centered"
            id={`dropdown-button-drop-down-centered`}
            drop="down-centered"
            variant="success"
            title={templateName}
            onSelect={handleTemplateName}
          >
            <div className="dropScroll">
              {getPrescriptionTemplateNames?.data?.data?.historyTemplatesNamesArray.map(
                (template, index) => (
                  <Dropdown.Item
                    eventKey={
                      template.templateName + "@@" + template._id + "@@" + index
                    }
                  >
                    {template.templateName}
                  </Dropdown.Item>
                )
              )}
            </div>
          </DropdownButton>
        </Row>
      </Container>

      {templateName !== "Choose Template" && (
        <div className="form-builder">
          <Row>
            <Col md={3} className="toolbox">
              <h3>Toolbox</h3>
              <div
                className="toolbox-item"
                draggable
                onDragStart={(e) => handleDragStart(e, "input")}
              >
                Input
              </div>
              <div
                className="toolbox-item"
                draggable
                onDragStart={(e) => handleDragStart(e, "table")}
              >
                Table
              </div>
              <div
                className="toolbox-item"
                draggable
                onDragStart={(e) => handleDragStart(e, "textarea")}
              >
                Textarea
              </div>
              <div
                className="toolbox-item"
                draggable
                onDragStart={(e) => handleDragStart(e, "select")}
              >
                Select
              </div>
              <div
                className="toolbox-item"
                draggable
                onDragStart={(e) => handleDragStart(e, "checkbox")}
              >
                Checkbox
              </div>
              <div
                className="toolbox-item"
                draggable
                onDragStart={(e) => handleDragStart(e, "radio")}
              >
                Radio
              </div>
              <div
                className="toolbox-item"
                draggable
                onDragStart={(e) => handleDragStart(e, "header")}
              >
                Header
              </div>
              <div
                className="toolbox-item"
                draggable
                onDragStart={(e) => handleDragStart(e, "paragraph")}
              >
                Paragraph
              </div>
              <div
                className="toolbox-item"
                draggable
                onDragStart={(e) => handleDragStart(e, "multiselect")}
              >
                MultiSelect
              </div>
            </Col>
            <Col md={9}>
              <div
                className={`form-canvas ${
                  draggingElement === "paragraph" ? "dragged-paragraph" : ""
                }`}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
              >
                <Form>
                  {formElements?.map((element, index) => (
                    <div
                      key={index}
                      className={`form-element ${
                        selectedElementIndex === index ? "selected" : ""
                      }`}
                    >
                      <div className="form-element-header">
                        <div className="label-edit-delete">
                          {element.type !== "header" &&
                          element.type !== "paragraph" ? (
                            <>
                              <FormLabel className="label">
                                {element.label}
                              </FormLabel>
                              {element.type === "table" && (
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={element?.rows}
                                    onChange={(e) =>
                                      resizeTable(
                                        Number(e.target.value),
                                        Number(element?.cols),
                                        index
                                      )
                                    }
                                  />
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={element?.cols}
                                    onChange={(e) =>
                                      resizeTable(
                                        Number(element?.rows),
                                        Number(e.target.value),
                                        index
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </>
                          ) : element.type !== "paragraph" ? (
                            <h3>{element.value}</h3>
                          ) : (
                            <p className="paragraph">{element.value}</p>
                          )}
                          <div className="edit-delete-buttons">
                            <button
                              type="button"
                              className="edit-icon btn"
                              onClick={() => openEditModal(index)}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button
                              type="button"
                              className="delete-icon btn"
                              onClick={() => handleDeleteElement(index)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-element-input">
                        {element.type === "select" ? (
                          <Form.Control
                            as="select"
                            onChange={(e) => handleSelectChange(e, index)}
                          >
                            {element.options.map((option, optionIndex) => (
                              <option key={optionIndex}>{option}</option>
                            ))}
                          </Form.Control>
                        ) : element.type === "table" ? (
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              overflowX: "scroll",
                            }}
                          >
                            <Table
                              bordered
                              className="custom-table"
                              style={{ width: "150%" }}
                            >
                              <thead className="bg-light">
                                <tr>
                                  {[...Array(element.cols)].map(
                                    (_, colIndex) => (
                                      <td key={colIndex}>
                                        <Form.Control
                                          type="text"
                                          value={
                                            element.cellData[
                                              `${0}-${colIndex}`
                                            ] || ""
                                          }
                                          onChange={(e) =>
                                            handleCellChange(
                                              0,
                                              colIndex,
                                              e.target.value,
                                              index
                                            )
                                          }
                                        />
                                      </td>
                                    )
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {[...Array(element.rows)].map((_, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {[...Array(element.cols)].map(
                                      (_, colIndex) => (
                                        <td key={colIndex}>
                                          <Form.Control
                                            type="text"
                                            value={
                                              element.cellData[
                                                `${rowIndex}-${colIndex}`
                                              ] || ""
                                            }
                                            onChange={(e) =>
                                              handleCellChange(
                                                rowIndex,
                                                colIndex,
                                                e.target.value,
                                                index
                                              )
                                            }
                                          />
                                        </td>
                                      )
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        ) : element.type === "multiselect" ? (
                          <MultiSelect
                            options={element.options.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                            value={element.value || []}
                            onChange={(selectedOptions) =>
                              handleMultiselectChange(selectedOptions, index)
                            }
                            labelledBy="Select"
                          />
                        ) : element.type === "checkbox" ? (
                          <div>
                            {element.options.map((option, optionIndex) => (
                              <Form.Check
                                key={optionIndex}
                                type="checkbox"
                                label={option}
                                required={element.required}
                                onChange={(e) =>
                                  handleCheckboxRadioChange(
                                    e,

                                    option,

                                    index
                                  )
                                }
                                checked={formElements[index]?.value?.includes(
                                  option
                                )}
                              />
                            ))}
                          </div>
                        ) : element.type === "radio" ? (
                          <div>
                            {element.options.map((option, optionIndex) => (
                              <Form.Check
                                key={optionIndex}
                                type="radio"
                                label={option}
                                required={element.required}
                                onChange={(e) =>
                                  handleCheckboxRadioChange(
                                    e,

                                    option,

                                    index
                                  )
                                }
                                checked={element?.value === option}
                              />
                            ))}
                          </div>
                        ) : element.type !== "header" &&
                          element.type !== "paragraph" ? (
                          element.type === "input" ? (
                            <FormControl
                              type={element.type}
                              placeholder="Enter Value"
                              value={element.value}
                              onChange={(e) => handleValueChange(e, index)}
                            />
                          ) : (
                            <textarea
                              rows={2}
                              cols={100}
                              className="form-control"
                              onChange={(e) => handleValueChange(e, index)}
                              value={element.value}
                            ></textarea>
                          )
                        ) : null}
                      </div>
                    </div>
                  ))}
                </Form>
              </div>
            </Col>
          </Row>

          <Modal show={showEditModal} onHide={handleEditModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Form Element</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {formElements?.[selectedElementIndex]?.type === "header" ? (
                <>
                  <FormLabel>Edit Value:</FormLabel>
                  <FormControl
                    type="text"
                    value={editHeaderValue}
                    onChange={(e) => setEditHeaderValue(e.target.value)}
                  />
                </>
              ) : formElements?.[selectedElementIndex]?.type === "paragraph" ? (
                <>
                  <FormLabel>Edit Value:</FormLabel>
                  <FormControl
                    type="text"
                    value={editParagraphValue}
                    onChange={(e) => setEditParagraphValue(e.target.value)}
                  />
                </>
              ) : (
                <>
                  <FormLabel>Edit Label:</FormLabel>
                  <FormControl
                    type="text"
                    value={editLabel}
                    onChange={(e) => setEditLabel(e.target.value)}
                  />
                  {formElements?.[selectedElementIndex]?.type !== "header" &&
                  formElements?.[selectedElementIndex]?.type !== "paragraph" &&
                  (formElements?.[selectedElementIndex]?.type === "checkbox" ||
                    formElements?.[selectedElementIndex]?.type === "radio" ||
                    formElements?.[selectedElementIndex]?.type === "select" ||
                    formElements?.[selectedElementIndex]?.type ===
                      "multiselect") ? (
                    <>
                      <FormLabel>Edit Options:</FormLabel>
                      {editOptions.map((option, index) => (
                        <div key={index} className="header-center">
                          <Row className="mt-1">
                            <Col md="10">
                              <FormControl
                                type="text"
                                value={option}
                                onChange={(e) => handleOptionChange(e, index)}
                              />
                            </Col>
                            <Col md="1">
                              <Button
                                variant="danger"
                                onClick={() => handleDeleteOption(index)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </Col>
                          </Row>
                          {index === editOptions.length - 1 && (
                            <Button
                              variant="primary"
                              onClick={handleAddOption}
                              className="mt-2 mb-2"
                            >
                              <FontAwesomeIcon icon={faPlus} /> Add
                            </Button>
                          )}
                        </div>
                      ))}
                      {showAddOptionInput && (
                        <Row>
                          <Col md="10">
                            <FormControl
                              type="text"
                              value={newOption}
                              onChange={(e) => setNewOption(e.target.value)}
                              placeholder="New Option"
                            />
                          </Col>
                          <Col md="1">
                            <Button
                              variant="primary"
                              onClick={() => {
                                setEditOptions([...editOptions, newOption]);
                                setNewOption("");
                                setShowAddOptionInput(false);
                              }}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </>
                  ) : null}
                  {formElements?.[selectedElementIndex]?.type !== "header" &&
                    formElements?.[selectedElementIndex]?.type !==
                      "paragraph" && (
                      <FormCheck
                        type="checkbox"
                        label="Required"
                        checked={isRequired}
                        onChange={() => setIsRequired(!isRequired)}
                      />
                    )}
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleEditModalClose}>
                Close
              </Button>
              {formElements?.[selectedElementIndex]?.type === "header" ? (
                <Button variant="primary" onClick={handleHeaderEditModalSave}>
                  Save Changes
                </Button>
              ) : formElements?.[selectedElementIndex]?.type === "paragraph" ? (
                <Button
                  variant="primary"
                  onClick={handleParagraphEditModalSave}
                >
                  Save Changes
                </Button>
              ) : (
                <Button variant="primary" onClick={handleEditModalSave}>
                  Save Changes
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      )}
      <Container className="header-center mt-4 mb-4">
        <Row className="mt-4 mb-2 justify-content-center">
          <Col md="auto" className="text-center mt-1">
            <Button onClick={handleSubmitForm}>Submit Form</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HistoryEditorScreens;
