/** @format */

import { createSlice } from "@reduxjs/toolkit";

const editLabTechnician = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		editLabTechnicianStart: (state) => {
			state.loading = true;
		},
		editLabTechnicianSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		editLabTechnicianFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editLabTechnicianReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editLabTechnicianStart,
	editLabTechnicianSuccess,
	editLabTechnicianFailure,
	editLabTechnicianReset,
} = editLabTechnician.actions;
export default editLabTechnician.reducer;
