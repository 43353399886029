/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Form,
	InputGroup,
	Dropdown,
	Table,
	Button,
	Row,
	Col,
	Container,
	Modal,
	ListGroup,
	Card,
} from "react-bootstrap";
import { getIpBill } from "../../redux/adminRedux/adminApiCalls";
import Meta from "../../Components/Meta";
import { getIpBillRequest } from "../../redux/adminRedux/IpBill_Management/getIpBill";
import { getUserDataById } from "../../redux/prescriptionRedux/prescriptionApiCalls";
import { useReactToPrint } from "react-to-print";
const InpatientBill = () => {
	const dispatch = useDispatch();

	const [ipBillId, setipBillId] = useState("");
	const [userIdx, setUserIdx] = useState(0);
	const [userId, setUserId] = useState("");
	const [userOption, setUserOption] = useState("");
	const [pocName, setPocName] = useState("");
	const [pocContact, setPocContact] = useState("");

	const getIpDetails = useSelector((state) => state.getIpBill);
	console.log(getIpDetails);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const IpPatientDataaItemsParam = params.get("IpPatientData");
		if (IpPatientDataaItemsParam) {
			try {
				const parsedIPPatientdataItems = JSON.parse(
					decodeURIComponent(IpPatientDataaItemsParam)
				);

				setUserOption(parsedIPPatientdataItems.username);
				setUserIdx(parsedIPPatientdataItems.userIdx);
				setUserId(parsedIPPatientdataItems.userId);
				setipBillId(parsedIPPatientdataItems.billId);
				setPocName(parsedIPPatientdataItems.pocName);
				setPocContact(parsedIPPatientdataItems.pocMno);
				dispatch(getIpBillRequest());

				dispatch(getIpBill(parsedIPPatientdataItems.billId));
			} catch (e) {
				console.error("Failed to parse history items", e);
			}
		}
	}, []);

	useEffect(() => {
		if (Object.keys(getIpDetails).length !== 0 && ipBillId !== "") {
			if (getIpDetails?.success) {
				const temp = getIpDetails?.getIpBill?.data?.data[0];
				if (temp?.userId)
					dispatch(
						getUserDataById(temp?.userId, temp?.username, Number(temp?.userIdx))
					);
			}
		}
	}, [getIpDetails?.loading]);

	const printRef = useRef();

	// const printInvoice = () => {
	// 	const printWindow = window.open("", "", "width=800,height=600");
	// 	const styles = Array.from(
	// 		document.querySelectorAll('link[rel="stylesheet"], style')
	// 	)
	// 		.map((style) => style.outerHTML)
	// 		.join("\n");
	// 	printWindow.document.write(`
	//   <html>
	//     <head>
	//       <title>Print</title>
	//       ${styles}
	//     </head>
	//     <body>
	// `);

	// 	printWindow.document.write(document.getElementById(`printBill`)?.innerHTML);

	// 	printWindow.document.write("</body></html>");
	// 	printWindow.document.close();
	// 	printWindow.focus();
	// 	printWindow.print();
	// 	printWindow.close();
	// };
	const reportTemplateRef = useRef(null);
	const printInvoice = useReactToPrint({
		content: () => reportTemplateRef.current,
		documentTitle: "123",
		onBeforeGetContent: () => {
			const content = reportTemplateRef.current;
			return content;
		},
		onAfterPrint: () => console.log("Printed PDF successfully!"),
	});

	const { userdata } = useSelector((state) => state.getUserDataById);
	console.log(userdata);

	const calculateAmounts = (type) => {
		let totalCost = 0;
		if (type === "General Services") {
			totalCost =
				getIpDetails?.getIpBill?.data?.data[0]?.generalServicesUsed?.reduce(
					(accumulator, service) => {
						return (
							accumulator + Number(service.price) * Number(service.quantity)
						);
					},
					0
				);
		} else if (type === "Professional Services") {
			totalCost =
				getIpDetails?.getIpBill?.data?.data[0]?.DoctorServicesUsed?.reduce(
					(accumulator, service) => {
						return accumulator + Number(service.price);
					},
					0
				);
		} else if (type === "Inventory Services") {
			totalCost =
				getIpDetails?.getIpBill?.data?.data[0]?.DoctorServicesUsed?.reduce(
					(accumulator, service) => {
						return accumulator + Number(service.price); // Accumulate the total price
					},
					0
				);
		} else if (type === "Lab Services") {
			totalCost =
				getIpDetails?.getIpBill?.data?.data[0]?.labServicesUsed?.reduce(
					(accumulator, service) => {
						// Check if there are tests to update
						return (
							accumulator +
							service?.testsToUpdate?.reduce(
								(innerAccumulator, test) =>
									innerAccumulator + Number(test.paidAmount),
								0 // Initial value for the inner accumulator
							)
						);
					},
					0 // Initial value for the outer accumulator
				);
		} else if (type === "Payment History") {
			totalCost = getIpDetails?.getIpBill?.data?.data[0]?.payments?.reduce(
				(accumulator, payment) => {
					return accumulator + Number(payment.amountPaid);
				},
				0 // Initial value for the accumulator
			);
		}
		return totalCost;
	};

	return (
		<>
			<Meta />
			<Container className="header-center mt-3">
				<Row className="justify-content-end">
					<Col md="auto">
						<button className="btn btn-primary" onClick={printInvoice}>
							Print bill <i class="fa fa-print"></i>
						</button>
					</Col>
				</Row>
			</Container>
			<div id="printBill" ref={reportTemplateRef}>
				<h1 className="header-center mt-3 mb-5">In Patient Final Bill</h1>
				<Container className="bill-container">
					{/* Logo and Hospital Information */}
					<Row className="header-section mb-4">
						<Col xs={3}>
							<img
								src="/logo3.png"
								alt="logo"
								className="hospital-logo"
								width="140"
								height="140"
							/>
						</Col>

						<Col xs={9} className="text-right">
							<h4>Dr. K. Anjal Reddy Memorial Hospital</h4>
							<p>Nethaji road, Kamareddy,</p>
							<p>Telangana 503111</p>
							<p>Contact: +91 8688044440</p>
						</Col>
					</Row>
				</Container>
				<h4 className="container">Patient Details</h4>
				<div className="header-center card container mt-3">
					<Row className="justify-content-center mb-4 mt-4">
						<Col md="3" className="text-center">
							<h6>Patient: {userdata?.data?.data?.name}</h6>
						</Col>
						<Col md="3" className="text-center">
							<h6>Age: {userdata?.data?.data?.age}</h6>
						</Col>
						<Col md="3" className="text-center">
							<h6>Sex: {userdata?.data?.data?.gender}</h6>
						</Col>
						<Col md="3" className="text-center">
							<h6>Mobile Number: {userdata?.data?.data?.mobilenumber}</h6>
						</Col>
					</Row>
					<Form.Group as={Row} className="mb-4">
						<Form.Label column sm="2">
							<b>POC Name:</b>
						</Form.Label>
						<Col sm="4">
							<input
								type="text"
								className="form-control"
								value={pocName}
								placeholder="Enter Name"
								disabled
							/>
						</Col>
						<Form.Label column sm="2">
							<b>POC Contact:</b>
						</Form.Label>
						<Col sm="4">
							<input
								type="text"
								className="form-control"
								value={pocContact}
								placeholder="Enter Contact"
								disabled
							/>
						</Col>
					</Form.Group>
				</div>
				<Container className="header-center card mt-3">
					<Row className="mb-3 mt-3 justify-content-center">
						<Form.Label column sm="2">
							<b>Chief Consultant:</b>
						</Form.Label>
						<Col sm="auto">
							<Form.Control
								type="text"
								placeholder="Enter room number"
								value={getIpDetails?.getIpBill?.data?.data[0]?.specialityName}
								disabled
							/>
						</Col>
						<Col sm="auto">
							<Form.Control
								type="text"
								placeholder="Enter room number"
								value={getIpDetails?.getIpBill?.data?.data[0]?.doctorName}
								disabled
							/>
						</Col>
					</Row>

					<Row className="mb-3 mt-3 justify-content-center">
						<Form.Label column sm="2">
							<b>Room Number:</b>
						</Form.Label>
						<Col sm="4">
							<Form.Control
								type="text"
								placeholder="Enter room number"
								value={
									// getIpDetails?.getIpBill?.data?.data[0]?.admittedRoomHistory[
									// 	getIpDetails?.getIpBill?.data?.data[0]?.admittedRoomHistory
									// 		?.length - 1
									// ]?.room
									"3A"
								}
								disabled
							/>
						</Col>

						<Form.Label column sm="2">
							<b>Bed Number:</b>
						</Form.Label>
						<Col sm="4">
							<Form.Control
								type="text"
								placeholder="Enter bed number"
								value={
									// getIpDetails?.getIpBill?.data?.data[0]?.admittedRoomHistory[
									// 	getIpDetails?.getIpBill?.data?.data[0]?.admittedRoomHistory
									// 		?.length - 1
									// ]?.bednumber
									6
								}
								disabled
							/>
						</Col>
					</Row>
				</Container>
				<h4 className="mt-5 container">General Services</h4>
				<Container className="header-center mt-3">
					<Table bordered>
						<thead>
							<tr>
								<th>Service Used</th>
								<th>Price</th>
								<th>Quantity</th>
								<th>Total Price</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody className="text-center" style={{ height: "50px" }}>
							{getIpDetails?.getIpBill?.data?.data[0]?.generalServicesUsed?.map(
								(service, index) => (
									<tr>
										<td>{service?.serviceName}</td>
										<td>{service?.price}</td>
										<td>{service?.quantity}</td>
										<td>
											{Number(service?.price) * Number(service?.quantity)}
										</td>
										<td>{service?.date}</td>
									</tr>
								)
							)}
							<tr>
								<td colSpan="3" style={{ textAlign: "right" }}>
									Total Cost:
								</td>
								<td colSpan="2">{calculateAmounts("General Services")}</td>
							</tr>
						</tbody>
					</Table>
				</Container>
				<h4 className="mt-5 container">Professional Services</h4>
				<Container className="header-center mt-3">
					<Table bordered>
						<thead>
							<tr>
								<th>Service Used</th>
								<th>Speciality</th>
								<th>Price</th>
								<th>Total Price</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							{getIpDetails?.getIpBill?.data?.data[0]?.DoctorServicesUsed?.map(
								(service, index) => (
									<tr>
										<td>{service?.serviceName}</td>
										<td>{service?.speciality}</td>
										<td>{service?.price}</td>
										<td>{Number(service?.price)}</td>
										<td>{service?.date}</td>
									</tr>
								)
							)}
							<tr>
								<td colSpan="3" style={{ textAlign: "right" }}>
									Total Cost:
								</td>
								<td colSpan="2">{calculateAmounts("Professional Services")}</td>
							</tr>
						</tbody>
					</Table>
				</Container>
				<h4 className="mt-5 container">Inventory Services</h4>
				<Container className="header-center mt-3">
					<Table bordered>
						<thead>
							<tr>
								<th>Product Name</th>
								<th>Price</th>
								<th>Quantity</th>
								<th>Total Amount</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							{/* {getIpDetails?.getIpBill?.data?.data[0]?.InventoryServicesUsed?.map(
								(service, index) => (
									<tr>
										<td>{service?.serviceName}</td>
										<td>{service?.speciality}</td>
										<td>{service?.price}</td>
										<td>{service?.date}</td>
									</tr>
								)
							)} */}
							<tr>
								<td>Dolo 650MG</td>
								<td>Rs 90</td>
								<td>1</td>
								<td>Rs 90</td>
								<td>2024-09-24</td>
							</tr>
							<tr>
								<td>N95 Masks</td>
								<td>Rs 150</td>
								<td>2</td>
								<td>Rs 300</td>
								<td>2024-09-24</td>
							</tr>
							<tr>
								<td colSpan="4" style={{ textAlign: "right" }}>
									Total Cost:
								</td>
								{/* <td colSpan='2'>{calculateAmounts("Inventory Services")}</td> */}
								<td colSpan="1">390</td>
							</tr>
						</tbody>
					</Table>
				</Container>
				<h4 className="container mt-5 mb-3">Lab Tests</h4>
				<Container className="header-center mt-3">
					<Table bordered>
						<thead>
							<tr>
								<th>Department</th>
								<th>Test</th>
								<th>Price</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							{getIpDetails?.getIpBill?.data?.data[0]?.labServicesUsed?.map(
								(service, index) =>
									service?.testsToUpdate?.map((test, index) => (
										<tr>
											<td>{test?.departmentName}</td>
											<td>{test?.testName}</td>
											<td>{test?.paidAmount}</td>
											<td>{service?.date}</td>
										</tr>
									))
							)}
							<tr>
								<td colSpan="3" style={{ textAlign: "right" }}>
									Total Cost:
								</td>
								<td colSpan="2">{calculateAmounts("Lab Services")}</td>
							</tr>
						</tbody>
					</Table>
				</Container>
				<h4 className="container mt-5 mb-3">Payment History</h4>
				<Container className="header-center mt-3">
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>Amount paid</th>
								<th>Paid in Cash</th>
								<th>Paid Online</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							{getIpDetails?.getIpBill?.data?.data[0]?.payments?.map(
								(service, index) => (
									<tr>
										<td>{service?.amountPaid}</td>
										<td>{service?.amountPaidCash}</td>
										<td>{service?.amountPaidOnline}</td>
										<td>{service?.paidDate}</td>
									</tr>
								)
							)}
							<tr>
								<td colSpan="3" style={{ textAlign: "right" }}>
									Total Paid Amount:
								</td>
								<td colSpan="2">{calculateAmounts("Payment History")}</td>
							</tr>
						</tbody>
					</Table>
				</Container>
				<Row className="justify-content-center">
					<Card className="col-4">
						{" "}
						<div className="m-3 header-center">
							Total Amount:{" "}
							{Number(calculateAmounts("Lab Services")?.toFixed(2)) +
								Number(calculateAmounts("Inventory Services")?.toFixed(2)) +
								Number(calculateAmounts("professional Services")?.toFixed(2)) +
								Number(calculateAmounts("General Services")?.toFixed(2))}
						</div>
					</Card>
					<Card className="col-4">
						<div className="m-3 header-center">
							Balance Amount:{" "}
							{(
								Number(calculateAmounts("Lab Services")?.toFixed(2)) +
								Number(calculateAmounts("Inventory Services")?.toFixed(2)) +
								Number(calculateAmounts("professional Services")?.toFixed(2)) +
								Number(calculateAmounts("General Services")?.toFixed(2)) -
								Number(calculateAmounts("Payment History"))
							).toFixed(2)}
						</div>
					</Card>
				</Row>
			</div>
		</>
	);
};

export default InpatientBill;
