/** @format */

import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { Button, Toast, ToastContainer } from 'react-bootstrap';

const Message = ({ variant, children }) => {
	//   const [visible, setVisible] = useState(true);

	// const onDismiss = () => setVisible(false);

	// return (
	//   <Alert class="alert alert-danger alert-dismissible fade show" color={variant} isOpen={visible} toggle={onDismiss}>

	//     {children}
	//   </Alert>
	// )
	const [showPopup, setShowPopup] = React.useState(false);

	const toggleShowInfoPopup = () => {
		setShowPopup(!showPopup);
	};

	return (
		<>
			{(() => {
				if (!showPopup) {
					return (
						<>
							<div
								className={`alert alert-${variant} d-none d-md-block`}
								style={{
									position: 'fixed',
									width: '20%',
									right: '1rem',
								}}
								role='alert'>
								{children}
							</div>
							<div
								className={`alert alert-${variant} d-block d-md-none`}
								style={{
									zIndex: 9999, // Add this line to ensure the alert is on top
								}}
								role='alert'>
								{children}
							</div>
						</>
					);
				}
			})()}

			{/* <ToastContainer
				position='top-end'
				className='p-3'
				>
				<Toast>
					<Toast.Body>{children}</Toast.Body>
				</Toast>
			</ToastContainer> */}
		</>
	);
};

Message.defaultProps = {
	variant: 'danger',
};

export default Message;
