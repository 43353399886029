/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../Errortemplate";
import Loader from "../Loader";
import { register } from "../../redux/userApiCalls";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Message from "../Message";
import { registerReset } from "../../redux/userRegister";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";
import Meta from "../Meta";

const RegisterPatientScreen = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const [name, setName] = useState("");
	const [mobilenumber, setmobilenumber] = useState("");
	const [password, setPassword] = useState("");
	const [address, setAddress] = useState("");
	const [gender, setGender] = useState("");
	const [passwordConfirm, setpasswordConfirm] = useState("");
	const [dob, setDob] = useState("");
	const userLogin = useSelector((state) => state.userRegister);
	const [show, setShow] = useState(false);
	const handleClick = () => setShow(!show);
	const [show1, setShow1] = useState(false);
	const handleClick1 = () => setShow1(!show1);
	const [bloodGroup, setBloodGroup] = useState("Select a blood group");
	console.log(userLogin + "jdkjs");
	const [age, setAge] = useState("");

	// Handler to calculate dob based on age input
	const handleAgeChange = (e) => {
		const inputAge = e.target.value;
		setAge(inputAge); // Set the input age in state

		if (inputAge && inputAge > 0) {
			// Calculate the year of birth from the current year
			const currentYear = new Date().getFullYear();
			const birthYear = currentYear - inputAge;

			// Set the dob state in the format 1/1/age
			setDob(`${birthYear}-01-01`);
		} else {
			setDob(""); // Reset dob if age input is invalid
		}
	};
	const { loading, error, userInfo, success } = useSelector(
		(state) => state.userRegister
	);
	useEffect(() => {
		if (userInfo) {
			console.log("111");
			localStorage.removeItem("userRegister");
		}
	}, [history, userInfo, success]);
	const submitHandler = () => {
		// e.preventDefault();
		const currDate = new Date(); // Get current date
		let year = currDate.getFullYear();
		let year_now = dob.split("-")[0];
		console.log(year, year_now);
		var letters = /^[A-Za-z\s]+$/;
		if (name == "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (gender == "") {
			alert("Please select gender, gender cannot be empty");
		} else if (year - year_now < 18) {
			alert("Insufficient age, You need to be atleast 18 years");
		} else if (address == "") {
			alert("Address cannot be empty");
		} else {
			// alert("User created successfully");
			if (bloodGroup == "Select a blood group") {
				dispatch(
					register(
						name.trim(),
						mobilenumber,
						mobilenumber,
						gender,
						dob,
						address.trim(),
						"NA"
					)
				);
			} else {
				dispatch(
					register(
						name.trim(),
						mobilenumber,
						mobilenumber,
						gender,
						dob,
						address.trim(),
						bloodGroup
					)
				);
			}
			setName("");
			setmobilenumber("");
			setPassword("");
			setAddress("");
			setGender("");
			setpasswordConfirm("");
			setDob("");
			setAge("");
			setBloodGroup("Select a blood group");
		}
	};

	/////////////////////////////////Alert handling////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const handleBloodGroup = (eventKey) => {
		setBloodGroup(eventKey);
	};

	const blood_groups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-", "NA"];

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	return (
		<>
			<Meta />

			{loading && <Loader />}

			<div className='row row-content'>
				<div className='col-12 col-md-4 offset-md-4 registerForm'>
					<h2 className='loginHeader header-center'>Register</h2>

					<div className='form-group'>
						<label for='name' className='col-md-12 col-form-label'>
							<b>Name:</b>
						</label>
						<div className='col-md-12'>
							<input
								type='text'
								className='form-control'
								id='name'
								name='name'
								required='required'
								minLength='4'
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
					</div>
					<div className='form-group'>
						<label for='mobileno' className='col-md-12 col-form-label'>
							<b>Mobile Number:</b>
						</label>
						<div className='col-md-12'>
							<input
								type='tel'
								className='form-control'
								id='mobileno'
								name='mobileno'
								required='required'
								onChange={(e) => setmobilenumber(e.target.value)}
								minlength='10'
								maxlength='10'
								value={mobilenumber}
								pattern='^[0-9]*$'
							/>
						</div>
					</div>
					<div className='form-group'>
						<label for='bloodgroup' className='col-md-12 col-form-label'>
							<b>Blood Group:</b>
						</label>
						<DropdownButton
							className='col-md-1 btn'
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={bloodGroup}
							onSelect={handleBloodGroup}>
							{blood_groups.map((blood) => (
								<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
							))}
						</DropdownButton>
					</div>
					<div className='form-group'>
						<label for='mobileno' className='col-md-12 col-form-label'>
							<b>Gender:</b>
						</label>
						<div className='col-md-12'>
							<div className='row'>
								<div className='col-6'>
									<Form.Check
										onChange={() => {
											setGender("Male");
										}}
										checked={gender === "Male"}
										className='form-control'
										inline
										label='Male'
										type='radio'
										id={`inline-radio-1`}
									/>
								</div>
								<div className='col-6'>
									<Form.Check
										onChange={() => {
											setGender("Female");
										}}
										checked={gender === "Female"}
										inline
										label='Female'
										className='form-control'
										type='radio'
										id={`inline-radio-2`}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='form-group'>
						<label htmlFor='age' className='col-md-12 col-form-label'>
							<b>Age:</b>
						</label>
						<div className='col-md-12'>
							<input
								type='number'
								className='form-control'
								id='age'
								name='age'
								value={age}
								onChange={handleAgeChange}
								min='1'
								max='150'
							/>
						</div>
					</div>
					<div className='form-group'>
						<label for='name' className='col-md-12 col-form-label'>
							<b>Address:</b>
						</label>
						<div className='col-md-12'>
							<textarea
								type='text'
								className='form-control'
								id='address'
								name='address'
								required='required'
								minLength='4'
								value={address}
								onChange={(e) => setAddress(e.target.value)}
							/>
						</div>
					</div>
					<div className='form-group'>
						<button
							type='submit'
							className='btn btn-success mt-5 offset-2 col-8'
							onClick={submitHandler}>
							<b>Register</b>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default RegisterPatientScreen;
