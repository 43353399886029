/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Table, Card } from "react-bootstrap";
import "../styles/Bill.css"; // Add custom styles here
import { getBill } from "../redux/inventoryRedux/inventoryApiCalls";
import { useDispatch, useSelector } from "react-redux";
import Meta from "./Meta";
import { useReactToPrint } from "react-to-print";
import { getUserDataById } from "../redux/prescriptionRedux/prescriptionApiCalls";

const BillScreen = () => {
	const dispatch = useDispatch();

	const { adminInfo } = useSelector((state) => state.adminLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const { doctorInfo } = useSelector((state) => state.doctorLogin);

	const [billId, setBillId] = useState(null);

	useEffect(() => {
		// Get query parameters from the URL
		const params = new URLSearchParams(window.location.search);
		const billItemsParam = params.get("billItems");

		if (billItemsParam) {
			try {
				// Parse and decode the historyItems from the query parameter
				const parsedBillItems = JSON.parse(decodeURIComponent(billItemsParam));
				setBillId(parsedBillItems);
			} catch (e) {
				console.error("Failed to parse history items", e);
			}
		}
	}, []);

	// const billId = useSelector((state) => state.billData);

	useEffect(() => {
		if (billId?.billId) {
			dispatch(getBill(billId?.billId));
			dispatch(
				getUserDataById(
					billId?.userdata?.userId,
					billId?.userdata?.name,
					billId?.userdata?.userIdx
				)
			);
		}
	}, [billId]);

	const getbillDetails = useSelector((state) => state.getBill);
	const getbill = getbillDetails?.getbill?.data?.data;

	console.log(getbill);

	const reportTemplateRef = useRef(null);

	const handleDownloadPdf = useReactToPrint({
		content: () => reportTemplateRef.current,
		documentTitle: getbill?.invoiceNumber,
		onBeforeGetContent: () => {
			const content = reportTemplateRef.current;
			return content;
		},
		onAfterPrint: () => console.log("Printed PDF successfully!"),
	});

	const userdata1 = useSelector((state) => state.getUserDataById);
	const userdata = userdata1.userdata;

	return (
		<>
			<Meta />
			<div className="row justify-content-end mt-3 mb-3">
				<Col md="auto" className="mt-1">
					<button className="btn btn-success" onClick={handleDownloadPdf}>
						Download as PDF
					</button>
				</Col>
			</div>
			<Container className="bill-container" ref={reportTemplateRef}>
				<Card className="border-primary mb-3">
					<Card.Header className="d-flex justify-content-between align-items-center">
						<div>
							<strong>Invoice Number:</strong> {getbill?.invoiceNumber}
						</div>
						<div>
							<strong>Bill Date:</strong> {getbill?.billDate}
						</div>
					</Card.Header>

					<Card.Body>
						<table>
							<tbody>
								<tr>
									<td className="col-4">
										<img
											src="/logo3.png"
											alt="logo"
											className="hospital-logo"
											width="140"
											height="140"
										/>
									</td>
									<td>
										<h3>Dr. K. Anjal Reddy Memorial Hospital</h3>
										<p>Nethaji road, Kamareddy, Telangana 503111</p>
										<p>Phone: +91 8688044440</p>
									</td>
								</tr>
							</tbody>
						</table>

						<div className="d-flex justify-content-between align-items-center mt-3">
							<h6>Patient Name: {userdata?.data?.data?.name}</h6>
							<h6>Mobile Number: {userdata?.data?.data?.mobilenumber}</h6>

							<h6>Age: {userdata?.data?.data?.age}</h6>
							<h6>Gender: {userdata?.data?.data?.gender}</h6>
						</div>

						<hr />
						{getbill?.inventory_charges?.AmountToBePaid !== 0 && (
							<Section title="Inventory Charges">
								<Table bordered style={{ fontSize: "90%", marginBottom: "5%" }}>
									<thead>
										<tr>
											<th>Item Name</th>
											<th>Quantity</th>
											<th>Price</th>
										</tr>
									</thead>
									<tbody>
										{getbill?.inventory_charges?.inventoryDetails?.map(
											(detail) => (
												<tr key={detail._id}>
													<td>{detail.inventory_items.name}</td>
													<td>{detail.inventory_items.quantity}</td>
													<td>{detail.inventory_items.price}</td>
												</tr>
											)
										)}
									</tbody>
								</Table>
							</Section>
						)}
						{getbill?.lab_charges?.AmountToBePaid !== 0 && (
							<Section title="Lab Charges">
								<Table
									bordered
									className="custom-table"
									style={{ fontSize: "90%", marginBottom: "5%" }}
								>
									<thead>
										<tr>
											<th>Lab Department</th>
											<th>Test Name</th>
											<th>Amount</th>
										</tr>
									</thead>
									<tbody>
										{getbill?.lab_charges?.labDetails?.map((detail) => (
											<tr key={detail._id}>
												<td>{detail.labDeptName}</td>
												<td>{detail.test?.name}</td>
												<td>{detail.test?.amount}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</Section>
						)}
						{getbill?.prescription_charges?.AmountToBePaid !== 0 && (
							<Section title="Consultation Charges">
								<div className="d-flex justify-content-between align-items-center"></div>
								{
									<Table style={{ fontSize: "90%", marginBottom: "5%" }}>
										<thead>
											<tr>
												<th>Services</th>
												<th>Amount</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													Dr {getbill?.prescription_charges?.doctorName}{" "}
													(Consultation Fee)
												</td>
												<td>
													{getbill?.prescription_charges?.prescriptionAmount}
												</td>
											</tr>
											{getbill?.prescription_charges?.additionalCharges
												?.length > 0 &&
												getbill?.prescription_charges?.additionalCharges.map(
													(charge) => (
														<tr key={charge._id}>
															<td>{charge.additional_charge_key}</td>
															<td>{charge.additional_charge_amount}</td>
														</tr>
													)
												)}
										</tbody>
									</Table>
								}
							</Section>
						)}
						<hr />
						<Row>
							<Col>
								{getbill?.inventory_charges?.AmountToBePaid !== 0 && (
									<div className="d-flex justify-content-between">
										<h6>
											Total Amount: Rs{" "}
											{getbill?.inventory_charges?.AmountToBePaid}
										</h6>
										<h6>
											Due Amount: Rs {getbill?.inventory_charges?.AmountDue}
										</h6>
										{getbill?.inventory_charges?.discount?.discountAmount !==
											0 && (
											<h6>
												Discount: Rs{" "}
												{getbill?.inventory_charges?.discount?.discountAmount}
											</h6>
										)}
										{(adminInfo || receptionInfo || doctorInfo) &&
											getbill?.inventory_charges?.discount?.discountAmount !==
												0 && (
												<h6>
													Discount Reason:{" "}
													{getbill?.inventory_charges?.discount?.discountReason}
												</h6>
											)}
									</div>
								)}
								{getbill?.lab_charges?.AmountToBePaid !== 0 && (
									<div className="d-flex justify-content-between">
										<h6>
											Total Amount: Rs {getbill?.lab_charges?.AmountToBePaid}
										</h6>
										<h6>Due Amount: Rs {getbill?.lab_charges?.AmountDue}</h6>

										{getbill?.lab_charges?.discount?.discountAmount !== 0 && (
											<h6>
												Discount: Rs{" "}
												{getbill?.lab_charges?.discount?.discountAmount}
											</h6>
										)}
										{(adminInfo || receptionInfo || doctorInfo) &&
											getbill?.inventory_charges?.discount?.discountAmount !==
												0 && (
												<h6>
													Discount Reason:{" "}
													{getbill?.lab_charges?.discount?.discountReason}
												</h6>
											)}
									</div>
								)}
								{getbill?.prescription_charges?.AmountToBePaid !== 0 && (
									<div className="d-flex justify-content-between">
										<h6>
											Total Amount: Rs{" "}
											{getbill?.prescription_charges?.AmountToBePaid}
										</h6>
										<h6>
											Due Amount: Rs {getbill?.prescription_charges?.AmountDue}
										</h6>

										{getbill?.prescription_charges?.discount?.discountAmount !==
											0 && (
											<h6>
												Discount: Rs{" "}
												{
													getbill?.prescription_charges?.discount
														?.discountAmount
												}
											</h6>
										)}
										{(adminInfo || receptionInfo || doctorInfo) &&
											getbill?.inventory_charges?.discount?.discountAmount !==
												0 && (
												<h6>
													Discount Reason:{" "}
													{
														getbill?.prescription_charges?.discount
															?.discountReason
													}
												</h6>
											)}
									</div>
								)}
								<hr />
								<div className="text-right">
									<h6>Total Amount Paid: Rs {getbill?.AmountPaid}</h6>
									{(adminInfo || receptionInfo || doctorInfo) && (
										<>
											<h6>Amount Paid (Cash): Rs {getbill?.amountPaidCash}</h6>
											<h6>
												Amount Paid (Online): Rs {getbill?.amountPaidOnline}
											</h6>
										</>
									)}
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="text-muted">
									Bill generated by: {getbill?.billCreatedBy}
								</div>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Container>
		</>
	);
};

const Section = ({ title, children }) => (
	<div className="section">
		<h4>{title}</h4>
		{children}
	</div>
);

export default BillScreen;
