/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getAllPurchase = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getAllPurchaseStart: (state) => {
			state.loading = true;
		},
		getAllPurchaseSuccess: (state, action) => {
			state.loading = false;
			state.getAllpurchase = action.payload;
			state.error = false;
			state.success = true;
		},
		getAllPurchaseFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getAllPurchaseReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getAllPurchaseStart,
	getAllPurchaseSuccess,
	getAllPurchaseFailure,
	getAllPurchaseReset,
} = getAllPurchase.actions;
export default getAllPurchase.reducer;
