/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../../../../Components/Errortemplate";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "../../../../Components/Loader";
import Meta from "../../../../Components/Meta";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Col, Row, Container } from "react-bootstrap";
import { todaysLabPatientsStatistics } from "../../../../redux/statisticsRedux/statisticApiCalls";
import { getSpecialities } from "../../../../redux/userApiCalls";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PieChart from "../../../../Components/PieChart";
import BarChart from "../../../../Components/Barchart";

const TodaysLabPatientsScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	//success and failure messages

	useEffect(() => {
		dispatch(todaysLabPatientsStatistics());
	}, []);

	const todaysLabPatientsstatistic = useSelector(
		(state) => state.todaysLabPatientsStatistics
	);

	const todaysLabPatientsStatistic =
		todaysLabPatientsstatistic?.todaysLabPatientsStatistic;
	const todaysLabPatientsStatisticLoading = todaysLabPatientsstatistic?.loading;

	console.log(todaysLabPatientsStatistic);

	const [todaysLabPatientsDeptData, setTodaysLabPatientsDeptData] = useState(
		[]
	);
	const [todaysLabPatientsTestData, setTodaysLabPatientsTestData] = useState(
		[]
	);

	const [depts, setDepts] = useState([]);

	useEffect(() => {
		let todaysDeptData = [];
		todaysLabPatientsStatistic?.data?.data?.map((dept, index) => {
			dept?.deptName !== "hospital" &&
				dept?.status !== 0 &&
				todaysDeptData?.push({
					id: dept?.departmentName,
					label: dept?.departmentName,
					value: dept?.deptOpRevenueTodayCash + dept?.deptOpRevenueTodayOnline,
				});
		});
		setTodaysLabPatientsDeptData(todaysDeptData);
		setDeptName1(todaysLabPatientsStatistic?.data?.data[0]?.departmentName);
		setDeptInd(0);
		let todaysTestData = [];
		todaysLabPatientsStatistic?.data?.data[0]?.tests?.map((test, index) => {
			test.status !== 0 &&
				todaysTestData?.push({
					id: test?.testName,
					label: test?.testName,
					value: test?.testOpRevenueTodayCash + test?.testOpRevenueTodayOnline,
				});
		});
		setTodaysLabPatientsTestData(todaysTestData);
		let deptArr = [];
		todaysLabPatientsStatistic?.data?.data?.map((dept, index) => {
			dept.status !== 0 && deptArr?.push(dept?.departmentName);
		});
		setDepts(deptArr);
	}, [todaysLabPatientsStatisticLoading]);

	const [deptName1, setDeptName1] = useState();
	const [deptInd, setDeptInd] = useState();

	const handleDeptSelect1 = (eventKey) => {
		setDeptName1(eventKey?.split("@@")[0]);
		setDeptInd(eventKey?.split("@@")[1]);
		let todaysTestData = [];
		todaysLabPatientsStatistic?.data?.data[
			eventKey?.split("@@")[1]
		]?.tests?.map((test, index) => {
			todaysTestData?.push(
				test.status !== 0 && {
					id: test?.testName,
					label: test?.testName,
					value: test?.testOpRevenueTodayCash + test?.testOpRevenueTodayOnline,
				}
			);
		});
		setTodaysLabPatientsTestData(todaysTestData);
	};

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href='/admin/labstatistics'>Total Lab</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/deptstatistics'>
					Department
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/teststatistics'>Test</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/todayslabpatients'>
					Today's lab patients
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className='header-center'>Today's Lab Patients Revenue</h1>
			<h4 className='header-center mt-5'>Department Revenue</h4>
			<Container className='header-center '>
				<PieChart data={todaysLabPatientsDeptData} />
			</Container>
			<h4 className='header-center mt-5'>Test Revenue</h4>
			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={deptName1}
							onSelect={handleDeptSelect1}>
							<div className='dropButton'>
								{depts?.map(
									(dept, index) =>
										dept.deptName !== "hospital" &&
										dept.status !== 0 && (
											<Dropdown.Item eventKey={dept + "@@" + index}>
												{dept}
											</Dropdown.Item>
										)
								)}
							</div>
						</DropdownButton>
					</Col>
				</Row>
				<PieChart data={todaysLabPatientsTestData} />
			</Container>
		</>
	);
};

export default TodaysLabPatientsScreens;
