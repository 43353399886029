/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createReport = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createReportStart: (state) => {
			state.loading = true;
		},
		createReportSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		createReportFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createReportReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createReportStart,
	createReportSuccess,
	createReportFailure,
	createReportReset,
} = createReport.actions;
export default createReport.reducer;
