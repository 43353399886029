/** @format */

import { createSlice } from "@reduxjs/toolkit";

const startPrescriptionToStartHistory = createSlice({
	name: "startPrescriptionToStartHistory",
	initialState: {},
	reducers: {
		startPrescriptionToStartHistoryRequest: (state) => {
			state.loading = true;
		},
		startPrescriptionToStartHistorySuccess: (state, action) => {
			state.loading = false;
			state.startPrescriptionToStartHistory = action.payload;
			state.error = false;
			state.success = true;
		},
		startPrescriptionToStartHistoryFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		startPrescriptionToStartHistoryReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	startPrescriptionToStartHistoryRequest,
	startPrescriptionToStartHistorySuccess,
	startPrescriptionToStartHistoryFailure,
	startPrescriptionToStartHistoryReset,
} = startPrescriptionToStartHistory.actions;
export default startPrescriptionToStartHistory.reducer;
