/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../../../../Components/Errortemplate.js";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "../../../../Components/Loader.js";
import Meta from "../../../../Components/Meta.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Col, Row, Container } from "react-bootstrap";
import { getAllLabDepartmentStatisticsForParticularYear } from "../../../../redux/statisticsRedux/statisticApiCalls.js";
import { getSpecialities } from "../../../../redux/userApiCalls.js";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BarChart from "../../../../Components/Barchart.js";
import { getLabDepts } from "../../../../redux/adminRedux/adminApiCalls.js";

const LabStatisticsScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	//success and failure messages

	useEffect(() => {
		dispatch(getAllLabDepartmentStatisticsForParticularYear(2024));
	}, []);

	const departmentStatistics = useSelector(
		(state) => state.getAllLabDepartmentStatisticsForParticularYear
	);
	const getAllLabDepartmentStatisticsForParticularyear =
		departmentStatistics?.getAllLabDepartmentStatisticsForParticularyear;
	const getAllLabDepartmentStatisticsForParticularyearLoading =
		departmentStatistics?.loading;

	console.log(getAllLabDepartmentStatisticsForParticularyear);

	const [last30DaysDeptBar, setLast30DaysDeptBar] = useState([]);
	const [last12MonsDeptBar, setLast12MonsDeptBar] = useState([]);
	const [activeDeptBar, setActiveDeptBar] = useState("30days");

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	useEffect(() => {
		let days30DataDeptBar = [];
		let mons12DataDeptBar = [];
		getAllLabDepartmentStatisticsForParticularyear?.data?.data?.last_thirtydays_department?.map(
			(day, index) => {
				let datearr = day?.date?.toString()?.split("");
				let date =
					datearr[6] +
					datearr[7] +
					"-" +
					datearr[4] +
					datearr[5] +
					"-" +
					datearr[0] +
					datearr[1] +
					datearr[2] +
					datearr[3];
				days30DataDeptBar?.push({
					category: date,
					Amount:
						day?.patientsRevenueCash_department +
						day?.patientsRevenueOnline_department,
				});
			}
		);
		getAllLabDepartmentStatisticsForParticularyear?.data?.data?.last_twelve_months_department?.map(
			(mon, index) => {
				mons12DataDeptBar?.push({
					category: months[mon?.monthNumber - 1],
					Amount:
						mon?.patientsRevenueCash_department +
						mon?.patientsRevenueOnline_department,
				});
			}
		);
		setLast30DaysDeptBar(days30DataDeptBar);
		setLast12MonsDeptBar(mons12DataDeptBar);
	}, [getAllLabDepartmentStatisticsForParticularyearLoading]);

	console.log(last30DaysDeptBar);
	console.log(last12MonsDeptBar);

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href='/admin/labstatistics'>Total Lab</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/deptstatistics'>
					Department
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/teststatistics'>Test</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/todayslabpatients'>
					Today's lab patients
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className='header-center'>Lab Revenue</h1>
			<Container className='header-center mt-5'>
				<button
					className='btn btn-info m-1'
					onClick={() => setActiveDeptBar("30days")}>
					Last 30 days
				</button>
				<button
					className='btn btn-info m-1'
					onClick={() => setActiveDeptBar("12mons")}>
					Last 12 Months
				</button>

				{activeDeptBar === "30days" && <BarChart data={last30DaysDeptBar} />}
				{activeDeptBar === "12mons" && <BarChart data={last12MonsDeptBar} />}
			</Container>
		</>
	);
};

export default LabStatisticsScreens;
