/** @format */

import { createSlice } from "@reduxjs/toolkit";

const addLabTestsToLabDeptForReferralDoctor = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		addLabTestsToLabDeptForReferralDoctorStart: (state) => {
			state.loading = true;
		},
		addLabTestsToLabDeptForReferralDoctorSuccess: (state, action) => {
			state.loading = false;
			state.addLabTestsToLabDeptForReferraldoc = action.payload;
			state.error = false;
			state.success = true;
		},
		addLabTestsToLabDeptForReferralDoctorFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		addLabTestsToLabDeptForReferralDoctorReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	addLabTestsToLabDeptForReferralDoctorStart,
	addLabTestsToLabDeptForReferralDoctorSuccess,
	addLabTestsToLabDeptForReferralDoctorFailure,
	addLabTestsToLabDeptForReferralDoctorReset,
} = addLabTestsToLabDeptForReferralDoctor.actions;
export default addLabTestsToLabDeptForReferralDoctor.reducer;
