/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createHistoryTemplateInSpeciality = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createHistoryTemplateInSpecialityStart: (state) => {
			state.loading = true;
		},
		createHistoryTemplateInSpecialitySuccess: (state, action) => {
			state.loading = false;
			state.createHistoryTemplateInSpeciality = action.payload;
			state.error = false;
			state.success = true;
		},
		createHistoryTemplateInSpecialityFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createHistoryTemplateInSpecialityReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createHistoryTemplateInSpecialityStart,
	createHistoryTemplateInSpecialitySuccess,
	createHistoryTemplateInSpecialityFailure,
	createHistoryTemplateInSpecialityReset,
} = createHistoryTemplateInSpeciality.actions;
export default createHistoryTemplateInSpeciality.reducer;
