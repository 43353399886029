/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createLabReportDetails = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createLabReportDetailsStart: (state) => {
			state.loading = true;
		},
		createLabReportDetailsSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		createLabReportDetailsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createLabReportDetailsReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createLabReportDetailsStart,
	createLabReportDetailsSuccess,
	createLabReportDetailsFailure,
	createLabReportDetailsReset,
} = createLabReportDetails.actions;
export default createLabReportDetails.reducer;
