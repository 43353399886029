/** @format */
import Message from "./Message";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import Meta from "./Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Table } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { getSpecialities, getTimeslots } from "../redux/userApiCalls";
import {
	getDoctorsAppointmentsForDate, //
	//changeToVisited,
	changeToOnHold, //
	changeSlot, //
} from "../redux/adminRedux/adminApiCalls";
// import Accordion from 'react-bootstrap/Accordion';

import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";
import { doctorBookingGetReset } from "../redux/adminRedux/Booking_Management/getBooking";
import { changeOnHoldReset } from "../redux/adminRedux/Booking_Management/changeOnHold";
import { changeSlotReset } from "../redux/adminRedux/Booking_Management/changeSlot";
import { allMessagesStart } from "../redux/allMessages";
import { specialityGetReset } from "../redux/getSpecialities";
import { TimeSlotGetReset } from "../redux/getTimeSlots";
//payment
import { paymentStatusReset } from "../redux/paymentStatus";
import { paymentStatusUpdate } from "../redux/doctorRedux/doctorApiCalls";
import LoaderGrow from "./LoaderGrow";
import {
	billStart,
	billSuccess,
	billFailure,
	billReset,
} from "../redux/adminRedux/Bill_Management/bill";
import { getUserDataById } from "../redux/prescriptionRedux/prescriptionApiCalls";

//Date picker

const PatientsToBeVisitedScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const { adminInfo } = useSelector((state) => state.adminLogin);

	const [searchClicked, setSearchClicked] = useState(false);

	console.log(doctorInfo?.data?.user);
	const [DoctorFromSpecialityArray, setDoctorFromSpecialityArray] = useState(
		[]
	);
	//Doctor dropdown
	const [doctorOption, setDoctorOption] = useState(() => {
		const testData = doctorInfo;
		return testData !== undefined
			? testData?.data?.user?.name
			: "Choose Doctor";
	});
	const [doctorId, setDoctorId] = useState(() => {
		const testData = doctorInfo;
		return testData !== undefined ? testData?.data?.user?._id : "";
	});
	//Success and failure allMessages
	const data1 = useSelector((state) => state.getDoctorBooking);
	const { getBookingsall, loading, error } = data1;

	const changeOnHoldData = useSelector((state) => state.changeOnHold);
	const changeOnHoldError = changeOnHoldData.error;
	const changeOnHoldloading = changeOnHoldData.loading;
	const changeOnHoldsuccess = changeOnHoldData.success;

	const changeSlotData = useSelector((state) => state.changeSlot);
	const changeSlotError = changeSlotData.error;
	const changeSlotloading = changeSlotData.loading;
	const changeSlotsuccess = changeSlotData.success;

	const getSpecialityAllData = useSelector((state) => state.getSpeciality);
	const getSpecialityAllsuccess = getSpecialityAllData.success;
	const getSpecialityAllerror = getSpecialityAllData.error;
	const getSpecialityAllloading = getSpecialityAllData.loading;

	const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
	const getTimeSlotssuccess = getTimeSlotsData.success;
	const getTimeSlotserror = getTimeSlotsData.error;

	const paymentStatusData = useSelector((state) => state.paymentStatus);
	const paymentStatusDatasuccess = paymentStatusData.success;
	const paymentStatusDataerror = paymentStatusData.error;
	const paymentStatusDataloading = paymentStatusData.loading;
	const getTimeSlotsloading = getTimeSlotsData.loading;
	const doctorAppointmentDates =
		doctorInfo !== undefined
			? doctorInfo?.data?.user?.appointmentDates
			: getTimeSlotsData?.allTimeSlots?.appointmentDates;

	console.log(getTimeSlotsData);

	const { getSpecialityAll } = useSelector((state) => state.getSpeciality);

	const { allTimeSlots } = useSelector((state) => state.getTimeSlots);
	console.log(getBookingsall);

	console.log(getSpecialityAll);
	console.log(doctorAppointmentDates);

	//demographic data calculation
	// if(getBookingsall){
	// 	for(let i=0;i<getBookingsall.lenght;i++){
	// 		for(let j-0;j)
	// 	}
	// }

	// Accordian Collapse
	const [open, setOpen] = useState([]);
	const toggle = (id) => {
		if (open.includes(id)) {
			setOpen(open.filter((key) => key !== id));
		} else {
			setOpen([...open, id]);
		}
	};

	//Modal Open
	const [lgShow, setLgShow] = useState(false);
	//Date Format
	const days = [];

	const [shiftDate, setShiftDate] = useState("Choose Date");
	const [shiftDateNumeric, setShiftDateNumeric] = useState("");
	const [shiftDateIdx, setShiftDateIdx] = useState("");
	const handleDateSelection = (eventKey, docId) => {
		setShiftDate(eventKey?.split("@@")[0]);
		setShiftDateNumeric(eventKey?.split("@@")[1]);
		setShiftDateIdx(eventKey?.split("@@")[2]);
		dispatch(getTimeslots(docId));
	};

	const formattedDates = doctorAppointmentDates?.map((entry) => {
		const dateString = entry.date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ("0" + dateString.slice(6, 8)).slice(-2);

		// Create the formatted date string

		days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
	});
	console.log(doctorAppointmentDates, days);
	//Retrieving Specialities Data
	useEffect(() => {
		doctorInfo === undefined && dispatch(getSpecialities());
	}, []);
	useEffect(() => {
		if (doctorInfo !== undefined) {
			handleDateSelect(days[0]?.currDay + "@@" + days[0]?.key + "@@" + 0);
			submitHandler(true, days[0]?.key);
		}
	}, [doctorInfo]);

	let allSpecialities = [];
	let allDoctors = [];
	if (getSpecialityAll) {
		console.log(getSpecialityAll);
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}
	console.log(allSpecialities);

	//Retrieving Doctors Data from Specialities

	//Retrieving Bookings Data of selected Doctor

	let timeSlots = [];
	let isEmpty = true;
	for (let i = 0; i < allTimeSlots?.length; i++) {
		isEmpty = allTimeSlots[i].isEmpty;
		timeSlots.push({
			isEmpty: isEmpty,
			timeSlot: allTimeSlots[i].timeSlot,
			curCount: allTimeSlots[i].curCount,
			maxCount: allTimeSlots[i].maxCount,
		});
	}
	console.log(timeSlots);

	//Speciality dropdown
	const [specialityOption, setSpecialityOption] = useState(() => {
		const testData = doctorInfo;
		return testData !== undefined
			? testData?.user?.speciality[0]
			: "Choose Speciality";
	});

	const [specialityId, setSpecialityId] = useState(() => {
		const testData = doctorInfo;
		return testData !== undefined ? testData?.data?.user?.specialityId[0] : "";
	});
	const handleSpecialitySelect = (eventKey) => {
		console.log(eventKey);
		let arr = [];
		arr = eventKey.split("/");
		setSpecialityOption(arr[0]);
		setSpecialityId(arr[1]);
		setDoctorOption("Choose Doctor");
		setDateOption("Choose Date");
		setDateSelected(false);
		setSearchClicked(false);
		setDoctorFromSpecialityArray(
			getSpecialityAll.data.data[arr[2]].doctorsArray
		);
		console.log(getSpecialityAll.data.data[arr[2]].doctorsArray);
	};

	const handleDoctorSelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		setDoctorId(arr[1]);
		setDoctorOption(arr[0]);
		setDateOption("Choose Date");
		setDateSelected(false);
		setSearchClicked(false);
		dispatch(getTimeslots(arr[1]));
	};
	const [dateOptionNumeric, setDateOptionNumeric] = useState("");
	console.log(typeof dateOptionNumeric);
	const [dayIdx, setdayIdx] = useState(-1);
	//Date dropdown
	const [dateOption, setDateOption] = useState("Choose Date");
	const [dateSelected, setDateSelected] = useState(false);
	const handleDateSelect = (eventKey) => {
		console.log(eventKey.split("@@") + "line245");
		setDateOption(eventKey?.split("@@")[0]);
		setDateSelected(false);
		setDateOptionNumeric(eventKey?.split("@@")[1]);
		setdayIdx(eventKey?.split("@@")[2]);
		setSearchClicked(false);
	};

	//Search button function for patients to be visited
	const submitHandler = async (flag = false, key = "") => {
		if (specialityOption == "Choose Speciality") {
			alert("Speciality needs to be selected first, Please select Speciality");
		} else if (doctorOption == "Choose Doctor") {
			alert("Doctor needs to be selected first, Please select Doctor");
		} else if (dateOption == "Choose Date" && !flag) {
			alert("Date needs to be selected first, Please select Date");
		} else {
			if (dateOption !== "Choose Date") setDateSelected(true);
			console.log(dateOptionNumeric + "line261");
			setSearchClicked(true);
			console.log(doctorId + "line263");

			dispatch(
				getDoctorsAppointmentsForDate(
					doctorId,
					key === "" ? Number(dateOptionNumeric) : key
				)
			);
		}
	};

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [bookingId_payemnt, setbookingId_payemnt] = useState("");
	const [discount, setDiscount] = useState("");
	const [discountReason, setDiscountReason] = useState("");
	const [modeOfPayment, setModeOfPayment] = useState("Cash");
	const [opConsultationFeedata, setopConsultationFeedata] = useState(0);
	let [amountPaidCash, setAmountPaidCash] = useState(0);
	let [amountPaidOnline, setAmountPaidOnline] = useState(0);
	const [additionalCharges, setAdditionalCharges] = useState([]);
	const [totalAmount, setTotalAmount] = useState(0);
	const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState(0);
	const [userIdx, setUserIdx] = useState("");
	const [mno, setMno] = useState("");
	const handleUpdatePaymentStatus = (
		bookingId,
		opConsultationFee,
		mno,
		userIdx,
		name,
		userId
	) => {
		setShow(true);
		setbookingId_payemnt(bookingId);
		setTotalAmount(opConsultationFee);
		setTotalAmountAfterDiscount(opConsultationFee);
		setopConsultationFeedata(opConsultationFee);
		setDiscount(0);
		setDiscountReason("");
		setAdditionalCharges([]);
		// AddAdditionalCharge();
		setMno(mno);
		setUserIdx(userIdx);
		setAmountPaidCash(0);
		setAmountPaidOnline(0);
	};

	const AddAdditionalCharge = () => {
		let addncharges = [...additionalCharges];
		addncharges?.push({
			additional_charge_key: "",
			additional_charge_amount: 0,
		});
		setAdditionalCharges(addncharges);
		setAmountPaidOnline(0);
		setAmountPaidCash(0);
		setDiscount(0);
	};

	const editAdditionalChargeKey = (index, additional_charge_key) => {
		let addncharges = additionalCharges?.map((addncharge, i) => {
			if (index === i) {
				return { ...addncharge, additional_charge_key: additional_charge_key };
			} else {
				return addncharge;
			}
		});
		setAdditionalCharges(addncharges);
		setAmountPaidOnline(0);
		setAmountPaidCash(0);
		setDiscount(0);
	};

	const editAdditionalChargeAmount = (index, additional_charge_amount) => {
		let addncharges = additionalCharges?.map((addncharge, i) => {
			if (index === i) {
				return {
					...addncharge,
					additional_charge_amount: additional_charge_amount,
				};
			} else {
				return addncharge;
			}
		});
		setAdditionalCharges(addncharges);

		const additionalChargesTotal = addncharges?.reduce((total, addncharge) => {
			return total + (Number(addncharge?.additional_charge_amount) || 0); // Using || 0 to handle undefined or null values
		}, 0);

		setTotalAmount(
			Number(opConsultationFeedata) + Number(additionalChargesTotal)
		);
		setTotalAmountAfterDiscount(
			Number(opConsultationFeedata) + Number(additionalChargesTotal)
		);
		setAmountPaidOnline(0);
		setAmountPaidCash(0);
		setDiscount(0);
	};

	const deleteAdditionalCharge = (index) => {
		let addncharges = [...additionalCharges];
		addncharges?.splice(index, 1);
		setAdditionalCharges(addncharges);

		const additionalChargesTotal = addncharges?.reduce((total, addncharge) => {
			return total + (Number(addncharge?.additional_charge_amount) || 0); // Using || 0 to handle undefined or null values
		}, 0);

		setTotalAmount(
			Number(opConsultationFeedata) + Number(additionalChargesTotal)
		);
		setTotalAmountAfterDiscount(
			Number(opConsultationFeedata) + Number(additionalChargesTotal)
		);
		setAmountPaidOnline(0);
		setAmountPaidCash(0);
		setDiscount(0);
	};

	// Function to handle change of discount percentage
	const handleDiscountChange = (event) => {
		if (event?.target?.value > 100) {
			alert("Discount cannot be greater than 100%");
		} else if (event?.target?.value < 0) {
			alert("Discount cannot be less than 0%");
		} else {
			setDiscount(event?.target?.value);
			let discountpercent = event?.target?.value;
			let AmountAfterDiscount =
				totalAmount -
				parseFloat(totalAmount * discountpercent * 0.01).toFixed(2);
			setTotalAmountAfterDiscount(AmountAfterDiscount);
		}
		setAmountPaidOnline(0);
		setAmountPaidCash(0);
	};

	const handleAmounts = (amount, type) => {
		if (amount > totalAmountAfterDiscount) {
			alert(`Amount cannot exceed ${totalAmountAfterDiscount}`);
		} else {
			if (type === "cash") {
				setAmountPaidOnline(0);
				setAmountPaidCash(Number(amount));
				setAmountPaidOnline(Number(totalAmountAfterDiscount) - Number(amount));
			} else {
				setAmountPaidCash(0);
				setAmountPaidOnline(Number(amount));
				setAmountPaidCash(Number(totalAmountAfterDiscount) - Number(amount));
			}
		}
	};

	const handleSubmit = () => {
		console.log("Discount:", discount);
		console.log("Discount Reason:", discountReason);
		console.log("Mode of Payment:", modeOfPayment);
		console.log(additionalCharges);
		const additionalChargesTotal = additionalCharges?.reduce(
			(total, addncharge) => {
				return total + (addncharge?.additional_charge_amount || 0); // Using || 0 to handle undefined or null values
			},
			0
		);
		let bill = {
			AmountToBePaid: totalAmount,
			AmountPaid: Number(amountPaidCash) + Number(amountPaidOnline),
			AmountDue: 0,
			invoiceNumber:
				"INV" + getCurrentDateIST()?.split("-")?.join("") + mno + userIdx,
			prescription_charges: {
				AmountToBePaid: totalAmount,
				AmountDue: 0,
				discount: {
					discountAmount:
						Number(totalAmount) - Number(totalAmountAfterDiscount),
					discountReason: discountReason,
				},
				doctorId: doctorId,
				doctorName: doctorOption,
				prescriptionAmount: opConsultationFeedata,
				additionalCharges: additionalCharges,
			},
			inventory_charges: {},
			lab_charges: {},
			billDate: getCurrentDateIST(),
			billCreatedBy: adminInfo
				? adminInfo?.user?.name
				: receptionInfo
				? receptionInfo?.data?.user?.name
				: "",
			amountPaidOnline: amountPaidOnline,
			amountPaidCash: amountPaidCash,
		};
		amountPaidOnline = amountPaidOnline === "" ? 0 : amountPaidOnline;
		amountPaidCash = amountPaidCash === "" ? 0 : amountPaidCash;
		if (
			Number(amountPaidOnline) + Number(amountPaidCash) >
			Number(totalAmountAfterDiscount)
		) {
			alert(`Amount cannot exceed ${totalAmountAfterDiscount}`);
		} else if (amountPaidOnline + amountPaidCash < totalAmountAfterDiscount) {
			alert(`Amount cannot be less than ${totalAmountAfterDiscount}`);
		} else if (
			additionalCharges.some(
				(item) =>
					item.additional_charge_key === "" ||
					item.additional_charge_amount === 0
			)
		) {
			alert("Fill all the additional charges");
		} else {
			dispatch(
				paymentStatusUpdate(
					totalAmount - totalAmountAfterDiscount,
					discountReason,
					totalAmount,
					bookingId_payemnt,
					"Cash",
					bill
				)
			);
			setDiscount(0);
			setModeOfPayment("");
			setDiscountReason("");
			setAdditionalCharges([]);
			// Close the modal
			handleClose();
			setAmountPaidCash(0);
			setAmountPaidOnline(0);
			setTotalAmountAfterDiscount(0);
		}
	};

	const viewAppointmentHandler = (
		id,
		name,
		date,
		hasPaid,
		specialityName,
		speciality,
		bookingId,
		userIdx,
		userId,

		slot,
		slotstatus
	) => {
		console.log(userIdx);
		history("/patientstobevisited/userappointment", {
			state: {
				id: id,
				name: name,
				docname:
					doctorInfo !== undefined
						? doctorInfo?.data?.user?.name
						: doctorOption,
				date: date,
				hasPaid: hasPaid,
				specialityName: specialityName,
				speciality: speciality,
				bookingId: bookingId,
				userIdx: userIdx,
				userId: userId,
				slot: slot,
				slotstatus: slotstatus,
				doctorId:
					doctorInfo !== undefined ? doctorInfo?.data?.user?._id : doctorId,
			},
		});
	};

	const handleChangeToOnHold = async (id, index) => {
		setDateSelected(false);
		console.log(index - 1, dateOptionNumeric);
		await dispatch(changeToOnHold(id, Number(dayIdx), Number(index - 1)));
		dispatch(
			getDoctorsAppointmentsForDate(doctorId, Number(dateOptionNumeric))
		);
	};
	const [selectedPatientFromOnHold, setSelectedPatientFromOnHold] =
		useState("");
	const openModel = (_id) => {
		setSelectedPatientFromOnHold(_id);
		setLgShow(true);
		dispatch(getTimeslots(doctorId));
	};
	const [changeSlotOption, setChangeSlotOption] = useState("");
	const handleChangeToSomeSlot = async (id) => {
		if (changeSlotOption === "") {
			alert("Please select a vaild slot");
		} else {
			await dispatch(
				changeSlot(
					Number(changeSlotOption?.split("@@")[1]),
					Number(dayIdx),
					Number(shiftDateNumeric),
					selectedPatientFromOnHold,
					Number(shiftDateIdx)
				)
			);
			dispatch(
				getDoctorsAppointmentsForDate(doctorId, Number(dateOptionNumeric))
			);
			setLgShow(false);
		}
	};

	// calling the function
	// passing array argument

	useEffect(() => {
		setLgShow(false);
		doctorOption !== "Choose Doctor" &&
			dateOption !== "Choose Date" &&
			dispatch(
				getDoctorsAppointmentsForDate(doctorId, Number(dateOptionNumeric))
			);
	}, [
		history,
		dispatch,
		changeOnHoldloading,
		changeSlotloading,
		paymentStatusDataloading,
	]);

	/////////////////////////////////Alert handling////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	//doctor bookings get

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	const [isFaded, setIsFaded] = useState(false);
	const [timer, setTimer] = useState(Date.now());

	const handleFade = () => {
		setIsFaded(true);
		// Simulate an async operation, e.g., data fetching
		doctorOption !== "Choose Doctor" &&
			dateOption !== "Choose Date" &&
			dispatch(
				getDoctorsAppointmentsForDate(doctorId, Number(dateOptionNumeric))
			);
	};

	useEffect(() => {
		setTimeout(() => {
			setIsFaded(false);
		}, 1000);
	}, [loading]);

	useEffect(() => {
		const interval = setInterval(() => {
			setTimer(Date.now());
		}, 5 * 60 * 1000); // 5 minutes in milliseconds

		// Clean up the interval on component unmount
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		// This effect will run every time `timer` is updated
		if (doctorOption !== "Choose Doctor" && dateOption !== "Choose Date") {
			console.log(
				"5 minutes have passed:",
				new Date(timer).toLocaleTimeString()
			);
			handleFade();
		}
		// Any other logic that needs to run every 5 minutes can go here
	}, [timer]);

	const viewBill = (id, userIdx, name, userId) => {
		const userdata = {
			name: name,
			userId: userId,
			userIdx: userIdx,
		};
		const billUserDetails = {
			billId: id,
			userdata: userdata,
		};
		const queryParams = new URLSearchParams();

		// Convert historyItems to a JSON string and encode it for the URL
		queryParams.append(
			"billItems",
			encodeURIComponent(JSON.stringify(billUserDetails))
		);

		// Open the new tab with the query parameters
		window.open(`/bill?${queryParams.toString()}`, "_blank");
	};

	return (
		<>
			<Meta />

			<Container className='header-center align-items-center'>
				<Row className='justify-content-center'>
					<Col
						md='auto'
						className={`${!isFaded ? "d-none" : "text-center mt-1"}`}>
						<Loader />
						<h5 className='text-center text-muted'>
							The latest patient details are currently being loaded
						</h5>
					</Col>
				</Row>
			</Container>

			<div className={`${isFaded ? "d-none" : ""}`}>
				{doctorInfo === undefined && loading && <Loader />}

				{(changeOnHoldloading ||
					changeSlotloading ||
					getSpecialityAllloading ||
					getTimeSlotsloading ||
					paymentStatusDataloading) && <Loader />}

				{doctorInfo && (
					<Breadcrumb>
						<Breadcrumb.Item href='/doctor/patientstobevisited'>
							Patients to be visited
						</Breadcrumb.Item>
						<Breadcrumb.Item href='/doctor/visitedpatients'>
							Visited patients
						</Breadcrumb.Item>
					</Breadcrumb>
				)}
				{adminInfo && (
					<Breadcrumb>
						<Breadcrumb.Item href='/admin/patientstobevisited'>
							Patients to be visited
						</Breadcrumb.Item>
						<Breadcrumb.Item href='/admin/visitedpatients'>
							Visited patients
						</Breadcrumb.Item>
					</Breadcrumb>
				)}
				{receptionInfo && (
					<Breadcrumb>
						<Breadcrumb.Item href='/reception/patientstobevisited'>
							Patients to be visited
						</Breadcrumb.Item>
						<Breadcrumb.Item href='/reception/visitedpatients'>
							Visited patients
						</Breadcrumb.Item>
					</Breadcrumb>
				)}
				<h1 className='header-center'>Patients to be visited </h1>
				{doctorInfo === undefined && (
					<Container className='header-center mt-4 mb-5'>
						<Row className='justify-content-center'>
							<Col md='auto' className='text-center mt-1'>
								<h6>
									Select the filters{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</h6>
							</Col>
							<Col md='auto' className='text-center mt-1'>
								<DropdownButton
									key='down-centered'
									id={`dropdown-button-drop-down-centered`}
									drop='down-centered'
									variant='success'
									title={specialityOption}
									onSelect={handleSpecialitySelect}>
									{allSpecialities.map(
										(specialities, index) =>
											specialities.specialization !== "referral" &&
											specialities.specialization !== "Consultant Doctor" && (
												<Dropdown.Item
													eventKey={
														specialities.specialization +
														"/" +
														specialities._id +
														"/" +
														index
													}>
													{specialities.specialization}
												</Dropdown.Item>
											)
									)}
								</DropdownButton>
							</Col>
							<Col md='auto' className='text-center mt-1'>
								<DropdownButton
									key='down-centered'
									id={`dropdown-button-drop-down-centered`}
									drop='down-centered'
									variant='success'
									title={doctorOption}
									onSelect={handleDoctorSelect}>
									{DoctorFromSpecialityArray.map((doctors, index) => (
										<Dropdown.Item
											eventKey={doctors.name + "/" + doctors.doctorsinfo}>
											{doctors.name}
										</Dropdown.Item>
									))}
								</DropdownButton>
							</Col>
							<Col md='auto' className='text-center mt-1'>
								<DropdownButton
									key='down-centered'
									id={`dropdown-button-drop-down-centered`}
									drop='down-centered'
									variant='success'
									title={dateOption}
									onSelect={handleDateSelect}>
									{" "}
									<div className='dropScroll'>
										{days.map((day, index) => (
											<Dropdown.Item
												eventKey={
													day?.currDay + "@@" + day?.key + "@@" + index
												}>
												{day.currDay}
											</Dropdown.Item>
										))}
									</div>
								</DropdownButton>
							</Col>
							<Col md='auto' className='text-center mt-1'>
								<Button
									id='searchButton'
									variant='primary'
									onClick={submitHandler}>
									Search
								</Button>
							</Col>
						</Row>
					</Container>
				)}
				{doctorInfo !== undefined && (
					<Container className='header-center mt-5'>
						<Row className='justify-content-center'>
							<Col md='auto' className='text-center'>
								<h6>
									Select the Date{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</h6>
							</Col>
							<Col md='auto' className='text-center'>
								<DropdownButton
									key='down-centered'
									id={`dropdown-button-drop-down-centered`}
									drop='down-centered'
									variant='success'
									title={dateOption}
									onSelect={handleDateSelect}>
									{" "}
									<div className='dropScroll'>
										{days.map((day, index) => (
											<Dropdown.Item
												eventKey={
													day?.currDay + "@@" + day?.key + "@@" + index
												}>
												{day.currDay}
											</Dropdown.Item>
										))}
									</div>
								</DropdownButton>
							</Col>
							<Col md='auto' className='text-center'>
								<Button
									id='searchButton'
									variant='primary'
									onClick={submitHandler}>
									Search
								</Button>
							</Col>
						</Row>
					</Container>
				)}
				{doctorInfo !== undefined && <div className='row mt-5 mb-3'></div>}
				{specialityOption === "Choose Speciality" ||
				doctorOption === "Choose Doctor" ||
				dateOption === "Choose Date" ? (
					<h5 className='text-danger text-center'>
						{doctorInfo === undefined
							? "Select the filters."
							: "select the date."}
					</h5>
				) : (
					<>
						<>
							{searchClicked === false ? (
								<h6 className='text-danger text-center'>
									Click on search button.
								</h6>
							) : (
								<Container>
									{getBookingsall?.data?.data?.map(
										(slot1, index) =>
											slot1[2]?.length !== 0 &&
											slot1[0] !== "onHold" && (
												<Accordion
													open={open}
													toggle={toggle}
													className={
														slot1[1] ? "mt-2 accordion-blocked" : "mt-2"
													}>
													<AccordionItem>
														<AccordionHeader targetId={index}>
															{slot1[0]}
														</AccordionHeader>
														<AccordionBody accordionId={index}>
															<Table
																striped
																bordered
																hover
																className='custom-table'>
																<thead>
																	<tr>
																		<th scope='col'>Patient No</th>
																		<th scope='col'>Patient Name</th>
																		<th scope='col'>Patient Mobile Number</th>
																		{slot1[1] ? (
																			<></>
																		) : (
																			<th scope='col'>Status</th>
																		)}
																		<th scope='col'>Action</th>
																		{doctorInfo && (
																			<th scope='col'>Past Cases</th>
																		)}
																		{receptionInfo && (
																			<th scope='col'>Past Cases</th>
																		)}
																	</tr>
																</thead>
																{slot1[2]?.map(
																	(
																		{
																			_id,
																			name,
																			slot, //onhold
																			slotCount, // onhold:1
																			userPhone,
																			patientNo,
																			onHold,
																			user,
																			scheduledDate,
																			billDetails,
																			opConsultationFee,
																			specialityName,
																			speciality,
																			userIdx,
																		},
																		sno
																	) => (
																		<tbody>
																			<tr>
																				<td>{sno + 1}</td>
																				<td>{name}</td>
																				<td>{userPhone}</td>
																				{slot1[1] ? (
																					<> </>
																				) : (
																					<td>
																						{onHold === 0 ? (
																							<OverlayTrigger
																								delay={{ hide: 450, show: 300 }}
																								overlay={(props) => (
																									<Tooltip {...props}>
																										Change to On Hold
																									</Tooltip>
																								)}
																								placement='bottom'>
																								<button
																									className='btn btn-warning'
																									onClick={() =>
																										handleChangeToOnHold(
																											_id,
																											index
																										)
																									}>
																									<i className='fa fa-pause'></i>
																								</button>
																							</OverlayTrigger>
																						) : (
																							<div></div>
																						)}
																					</td>
																				)}
																				<td>
																					{billDetails?.billId === "" ? (
																						<button
																							className='btn btn-success'
																							onClick={() =>
																								handleUpdatePaymentStatus(
																									_id,
																									opConsultationFee,
																									userPhone,
																									userIdx,
																									name,
																									user
																								)
																							}>
																							<i className='	fa fa-credit-card'></i>{" "}
																							Payment
																						</button>
																					) : (
																						<div>
																							<button
																								className='btn btn-success'
																								onClick={() =>
																									viewBill(
																										billDetails?.billId,
																										userIdx,
																										name,
																										user
																									)
																								}>
																								<i class='fas fa-file-invoice-dollar'></i>{" "}
																								View Bill
																							</button>
																						</div>
																					)}
																				</td>
																				{doctorInfo && (
																					<td>
																						<button
																							className='btn btn-success'
																							onClick={() =>
																								viewAppointmentHandler(
																									user,
																									name,
																									scheduledDate,
																									billDetails?.paidAmount !== 0,
																									specialityName,
																									speciality,
																									_id,
																									userIdx,
																									user,
																									slot,
																									slot1[1]
																										? "blocked"
																										: "unblocked"
																								)
																							}>
																							View Cases
																						</button>
																					</td>
																				)}
																				{receptionInfo && (
																					<td>
																						<button
																							className='btn btn-success'
																							onClick={() =>
																								viewAppointmentHandler(
																									user,
																									name,
																									scheduledDate,
																									billDetails?.paidAmount !== 0,
																									specialityName,
																									speciality,
																									_id,
																									userIdx,
																									user,
																									slot,
																									slot1[1]
																										? "blocked"
																										: "unblocked"
																								)
																							}>
																							View Cases
																						</button>
																					</td>
																				)}
																			</tr>
																		</tbody>
																	)
																)}
															</Table>
														</AccordionBody>
													</AccordionItem>
												</Accordion>
											)
									)}
								</Container>
							)}
							<Modal show={show} onHide={handleClose} size='lg'>
								<Modal.Header closeButton>
									<Modal.Title>Update Payment Status</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div className='row mt-2'>
										<div className='col-4 col-md-3 cardHeading'>
											Consultation Fee:
										</div>
										<input
											type='text'
											className='form-control col-8'
											value={`Rs ${opConsultationFeedata}`}
											disabled
										/>
									</div>

									{additionalCharges?.length > 0 && (
										<div className='row mt-2'>
											<div className='col-4 col-md-3 cardHeading'>
												Additional Charges:{" "}
											</div>
										</div>
									)}
									{additionalCharges?.map((addncharge, index) => (
										<div className='row mt-2'>
											<input
												type='text'
												className='offset-md-1 col-md-5 col-6 form-control'
												placeholder='Enter Name'
												value={addncharge?.additional_charge_key}
												onChange={(e) =>
													editAdditionalChargeKey(index, e.target.value)
												}
											/>
											<input
												type='number'
												className='offset-md-1 col-md-3 col-6 form-control'
												placeholder='Enter Amount'
												value={addncharge?.additional_charge_amount}
												onChange={(e) =>
													editAdditionalChargeAmount(index, e.target.value)
												}
											/>
											<button
												className='btn btn-danger ml-3'
												onClick={() => deleteAdditionalCharge(index)}>
												<i className='fa fa-trash'></i>
											</button>
										</div>
									))}
									<div className='row mt-2 d-flex justify-content-center'>
										<button
											className='btn btn-secondary'
											onClick={AddAdditionalCharge}>
											Add Additional Charges
										</button>
									</div>
									<div className='row mt-2'>
										<div className='col-4 col-md-3 cardHeading'>
											Discount (%):
										</div>
										<input
											type='number'
											className='col-8 form-control'
											value={discount}
											onChange={handleDiscountChange}
										/>
									</div>
									<div className='row mt-2'>
										<div className='col-4 col-md-3 cardHeading'>
											Discount Reason:
										</div>
										<input
											type='text'
											className='col-8 form-control'
											value={discountReason}
											onChange={(e) => setDiscountReason(e.target.value)}
										/>
									</div>
									<div className='row mt-2'>
										<div className='col-4 col-md-3 cardHeading'>
											Total Amount:
										</div>
										<input
											type='text'
											className='form-control col-8'
											value={`Rs ${totalAmountAfterDiscount}`}
											disabled
										/>
									</div>
									<div className='row mt-2'>
										<div className='col-4 col-md-3 cardHeading'>
											Total Cash:
										</div>

										<input
											type='number'
											className='form-control col-8'
											placeholder='Amount Paid Cash'
											value={amountPaidCash}
											onChange={(e) => handleAmounts(e.target.value, "cash")}
										/>
									</div>
									<div className='row mt-2'>
										<div className='col-4 col-md-3 cardHeading'>
											Total Online:
										</div>

										<input
											type='number'
											className='form-control col-8'
											placeholder='Amount Paid Online'
											value={amountPaidOnline}
											onChange={(e) => handleAmounts(e.target.value, "online")}
										/>
									</div>
								</Modal.Body>
								<Modal.Footer>
									<Button variant='primary' onClick={handleSubmit}>
										Mark as Paid
									</Button>
									<Button variant='secondary' onClick={handleClose}>
										Close
									</Button>
								</Modal.Footer>
							</Modal>
							{searchClicked && (
								<Container>
									{
										<Accordion open={open} toggle={toggle} className='mt-2'>
											<AccordionItem>
												<AccordionHeader targetId='1'>
													Patients On Hold
												</AccordionHeader>

												<AccordionBody accordionId='1'>
													<Table
														striped
														bordered
														hover
														className='custom-table'>
														<thead>
															<tr>
																<th scope='col'>Patient No</th>
																<th scope='col'>Patient Name</th>
																<th scope='col'>Patient Mobile Number</th>
																<th scope='col'>Status</th>
															</tr>
														</thead>
														<tbody>
															{getBookingsall?.data?.data[0][1]?.map(
																(
																	{
																		_id,
																		name,
																		slot,
																		slotCount,
																		visited,
																		userPhone,
																	},
																	sno
																) => (
																	<tr>
																		<td>{sno + 1}</td>
																		<td>{name}</td>
																		<td>{userPhone}</td>
																		<td>
																			{"   "}{" "}
																			<OverlayTrigger
																				delay={{ hide: 450, show: 300 }}
																				overlay={(props) => (
																					<Tooltip {...props}>
																						Schedule Appointment
																					</Tooltip>
																				)}
																				placement='bottom'>
																				<Button
																					variant='primary'
																					onClick={() => openModel(_id)}>
																					<i className='fa fa-arrow-up'></i>
																				</Button>
																			</OverlayTrigger>
																			<Modal
																				size='xl'
																				show={lgShow}
																				onHide={() => setLgShow(false)}
																				aria-labelledby='example-modal-sizes-title-xl'>
																				<Modal.Header closeButton>
																					<Modal.Title id='example-modal-sizes-title-xl'>
																						Select Slot
																					</Modal.Title>
																				</Modal.Header>
																				<Modal.Body>
																					<div className='row offset-md-4'>
																						<DropdownButton
																							className='col-2 btn'
																							key='down-centered'
																							id={`dropdown-button-drop-down-centered`}
																							drop='down-centered'
																							variant='success'
																							title={shiftDate}
																							onSelect={(eventKey) =>
																								handleDateSelection(
																									eventKey,
																									doctorId
																								)
																							}>
																							<div className='dropScroll'>
																								{days.map((day, index) => (
																									<Dropdown.Item
																										eventKey={
																											day?.currDay +
																											"@@" +
																											day?.key +
																											"@@" +
																											index
																										}>
																										{day.currDay}
																									</Dropdown.Item>
																								))}
																							</div>
																						</DropdownButton>
																					</div>
																					<div className='row offset-md-2'>
																						{shiftDate !== "Choose Date" &&
																							getTimeSlotsData?.allTimeSlots?.appointmentDates?.map(
																								(timeslots, index) => {
																									if (
																										timeslots?.date ==
																										shiftDateNumeric
																									) {
																										return timeslots?.timeSlots?.map(
																											(timeslot, i) => {
																												if (
																													timeslot?.isEmpty &&
																													!timeslot?.isBlocked
																												) {
																													return (
																														<button
																															key={timeslot.id} // Make sure to provide a unique key for each item
																															className={
																																changeSlotOption?.split(
																																	"@@"
																																)[0] ===
																																timeslot.timeSlot
																																	? "btn btn-success col-md-3 col-10 m-1"
																																	: "btn btn-outline-success col-md-3 col-10 m-1"
																															}
																															onClick={() =>
																																setChangeSlotOption(
																																	timeslot.timeSlot +
																																		"@@" +
																																		i
																																)
																															}>
																															<b>{`${
																																timeslot.timeSlot
																															} avl slots: ${
																																timeslot.maxAppointmentsperhour -
																																timeslot.curCount
																															} `}</b>
																														</button>
																													);
																												} else {
																													return (
																														<button
																															key={timeslot.id} // Make sure to provide a unique key for each item
																															className='btn btn-danger col-md-3 m-1'
																															disabled>
																															<b>{`${timeslot.timeSlot} Not available`}</b>
																														</button>
																													);
																												}
																											}
																										);
																									} else {
																										return null; // If the date doesn't match, return null to render nothing
																									}
																								}
																							)}
																					</div>
																				</Modal.Body>
																				<Modal.Footer>
																					<Button
																						onClick={() =>
																							handleChangeToSomeSlot(_id)
																						}
																						variant='primary'>
																						Submit
																					</Button>
																				</Modal.Footer>
																			</Modal>
																		</td>
																	</tr>
																)
															)}
														</tbody>
													</Table>
												</AccordionBody>
											</AccordionItem>
										</Accordion>
									}
								</Container>
							)}
						</>
					</>
				)}
			</div>
		</>
	);
};

export default PatientsToBeVisitedScreen;
