/** @format */

import { createSlice } from '@reduxjs/toolkit';

const editInventoryItem = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		editInventoryItemStart: (state) => {
			state.loading = true;
		},
		editInventoryItemSuccess: (state, action) => {
			state.loading = false;
			state.editInventoryitem = action.payload;
			state.error = false;
			state.success = true;
		},
		editInventoryItemFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editInventoryItemReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editInventoryItemStart,
	editInventoryItemSuccess,
	editInventoryItemFailure,
	editInventoryItemReset,
} = editInventoryItem.actions;
export default editInventoryItem.reducer;
