/** @format */

import { createSlice } from '@reduxjs/toolkit';

const editSupplier = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		editSupplierStart: (state) => {
			state.loading = true;
		},
		editSupplierSuccess: (state, action) => {
			state.loading = false;
			state.editSupplier = action.payload;
			state.error = false;
			state.success = true;
		},
		editSupplierFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editSupplierReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editSupplierStart,
	editSupplierSuccess,
	editSupplierFailure,
	editSupplierReset,
} = editSupplier.actions;
export default editSupplier.reducer;
