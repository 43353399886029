/** @format */

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";

import { persistor } from "../store";

import axios from "axios";
import { SERVER_URL } from "../../App";
import { hospitalname } from "../../App";

import {
	createHistoryTemplateInSpecialityStart,
	createHistoryTemplateInSpecialitySuccess,
	createHistoryTemplateInSpecialityFailure,
	createHistoryTemplateInSpecialityReset,
} from "./History_Management/createHistoryTemplateInSpeciality";

import {
	editHistoryByIdxStart,
	editHistoryByIdxSuccess,
	editHistoryByIdxFailure,
	editHistoryByIdxReset,
} from "./History_Management/editHistoryByIdx";

import {
	editHistoryTemplateInSpecialityStart,
	editHistoryTemplateInSpecialitySuccess,
	editHistoryTemplateInSpecialityFailure,
	editHistoryTemplateInSpecialityReset,
} from "./History_Management/editHistoryTemplateInSpeciality";

import {
	createHistoryForPatientStart,
	createHistoryForPatientSuccess,
	createHistoryForPatientFailure,
} from "./History_Management/createHistoryForPatient";

import {
	getHistoryByIdxStart,
	getHistoryByIdxSuccess,
	getHistoryByIdxFailure,
	getHistoryByIdxReset,
} from "./History_Management/getHistoryByIdx";

import {
	getHistoryTemplateInSpecialityStart,
	getHistoryTemplateInSpecialitySuccess,
	getHistoryTemplateInSpecialityFailure,
	getHistoryTemplateInSpecialityReset,
} from "./History_Management/getHistoryTemplateInSpeciality";

import {
	getRecentHistoryForDoctorStart,
	getRecentHistoryForDoctorSuccess,
	getRecentHistoryForDoctorFailure,
	getRecentHistoryForDoctorReset,
} from "./History_Management/getRecentHistoryForDoctor";

import {
	createCaseInPatientStart,
	createCaseInPatientSuccess,
	createCaseInPatientFailure,
	createCaseInPatientReset,
} from "./Patient_Case_Management/createCaseInPatient";

import {
	editCaseInPatientStart,
	editCaseInPatientSuccess,
	editCaseInPatientFailure,
	editCaseInPatientReset,
} from "./Patient_Case_Management/editPatientCase";

import {
	getCaseInPatientStart,
	getCaseInPatientSuccess,
	getCaseInPatientFailure,
	getCaseInPatientReset,
} from "./Patient_Case_Management/getCaseInPatient";

export const createHistoryTemplateInSpeciality =
	(historyTemplate, historyTemplateName, specialityIds) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(createHistoryTemplateInSpecialityStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/createHistoryTemplateInSpeciality`,
				{ historyTemplate, historyTemplateName, specialityIds },
				config
			);

			// console.log(data.user);
			dispatch(createHistoryTemplateInSpecialitySuccess(data));
			dispatch(
				allMessagesSuccess(
					`Succesfully created template : ${historyTemplateName}`
				)
			);
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				createHistoryTemplateInSpecialityFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const editHistoryByIdx =
	(historyId, index, newHistory) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(editHistoryByIdxStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/editHistoryByIdx`,
				{ historyId, index, newHistory },
				config
			);

			// console.log(data.user);
			dispatch(editHistoryByIdxSuccess(data));
			dispatch(allMessagesSuccess(`Succesfully edited the history`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editHistoryByIdxFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const editHistoryTemplateInSpeciality =
	(historyTemplate, historyTemplateName, historyTemplateIdx, specialityIds) =>
	async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(editHistoryTemplateInSpecialityStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/editHistoryTemplateInSpeciality`,
				{
					historyTemplate,
					historyTemplateName,
					historyTemplateIdx: Number(historyTemplateIdx),
					specialityIds,
				},
				config
			);

			// console.log(data.user);
			dispatch(editHistoryTemplateInSpecialitySuccess(data));
			dispatch(allMessagesSuccess(`Succesfully edited the template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editHistoryTemplateInSpecialityFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getPatientHistory = (historyId) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getHistoryByIdxStart());
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/history/getPatientHistory/${historyId}`,

			config
		);

		// console.log(data.user);
		dispatch(getHistoryByIdxSuccess(data));
		//dispatch(allMessagesSuccess(`Succesfully edited the template`));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getHistoryByIdxFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getHistoryTemplateInSpeciality =
	(specialityId, index) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(getHistoryTemplateInSpecialityStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/history/getHistoryTemplateInSpeciality/${specialityId}/${index}`,

				config
			);

			// console.log(data.user);
			dispatch(getHistoryTemplateInSpecialitySuccess(data));
			//dispatch(allMessagesSuccess(`Succesfully edited the template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				getHistoryTemplateInSpecialityFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getRecentHistoryForDoctor =
	(historyId, doctorId) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(getRecentHistoryForDoctorStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/history/getRecentHistoryForDoctor/${historyId}/${doctorId}`,

				config
			);

			// console.log(data.user);
			dispatch(getRecentHistoryForDoctorSuccess(data));
			//dispatch(allMessagesSuccess(`Succesfully edited the template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				getRecentHistoryForDoctorFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const createCaseInPatient =
	(
		historyIdx,
		historyId,
		caseName,
		prescripitionIdArray,
		caseStartDate,
		caseCloseDate,
		userId,
		userIdx,
		doctorId,
		doctorName
	) =>
	async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(createCaseInPatientStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/createCaseInPatient`,
				{
					historyIdx,
					historyId,
					caseName,
					prescripitionIdArray,
					caseStartDate,
					caseCloseDate,
					userId,
					userIdx,
					doctorId,
					doctorName,
				},

				config
			);

			// console.log(data.user);
			dispatch(createCaseInPatientSuccess(data));
			//dispatch(allMessagesSuccess(`Succesfully edited the template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				createCaseInPatientFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const editPatientCase =
	(
		historyIdx,
		historyId,
		caseName,
		prescripitionIdArray,
		caseStartDate,
		caseCloseDate,
		userId,
		userIdx,
		doctorId,
		doctorName
	) =>
	async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(editCaseInPatientStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/editPatientCase`,
				{
					historyIdx,
					historyId,
					caseName,
					prescripitionIdArray,
					caseStartDate,
					caseCloseDate,
					userId,
					userIdx,
					doctorId,
					doctorName,
				},

				config
			);

			// console.log(data.user);
			dispatch(editCaseInPatientSuccess(data));
			//dispatch(allMessagesSuccess(`Succesfully edited the template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editCaseInPatientFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getCaseInPatient = (userId, userIdx) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getCaseInPatientStart());
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/history/getCaseInPatient`,
			{
				userId,
				userIdx,
			},

			config
		);

		// console.log(data.user);
		dispatch(getCaseInPatientSuccess(data));
		//dispatch(allMessagesSuccess(`Succesfully edited the template`));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getCaseInPatientFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const createHistoryForPatient =
	(
		userId,
		userIdx,
		doctorName,
		doctorId,
		createdAt,
		historyData,
		historyId,
		specialityName,
		templateName
	) =>
	async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(createHistoryForPatientStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/createHistoryForPatient`,
				{
					userId,
					userIdx,
					doctorName,
					doctorId,
					createdAt,
					historyData,
					historyId,
					specialityName,
					templateName,
				},

				config
			);

			// console.log(data.user);
			dispatch(createHistoryForPatientSuccess(data));
			dispatch(allMessagesSuccess(`Succesfully created the history`));
		} catch (error) {
			dispatch(
				createHistoryForPatientFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				getCaseInPatientFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};
