/** @format */

import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Table from "react-bootstrap/Table";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Message from "../../../Components/Message";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import axios from "axios";
import { SERVER_URL } from "../../../App";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
	getLabDepts,
	createLabTech,
} from "../../../redux/adminRedux/adminApiCalls";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Collapse from "react-bootstrap/Collapse";

const AddLabTechScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	const [openItems, setOpenItems] = useState(false);

	// Add Lab Tech
	const { getlabDeptAll, loading } = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	var depts = [];

	const formattedDepts = getlabDeptAll?.data?.data?.map((entry) => {
		depts.push(entry?.deptName);
	});

	useEffect(() => {
		dispatch(getLabDepts());
	}, []);

	const [timerRunning, setTimerRunning] = useState(false);
	const [name, setName] = useState("");
	const [mobilenumber, setMobileNumber] = useState("");
	const [email, setEmail] = useState("");
	const [address, setAddress] = useState("");
	const [dob, setDob] = useState("");
	const [gender, setGender] = useState("");

	const [selDepts, setSelDepts] = useState([]);
	const [selDeptsIds, setSelDeptsIds] = useState([]);

	const handleDeptSelect = (item) => {
		if (!selDepts?.includes(item)) {
			let items = selDepts;
			items.push(item);
			setSelDepts(items);
			let deptIds = [];
			for (let i = 0; i < items?.length; i++) {
				for (let j = 0; j < getlabDeptAll?.data?.data?.length; j++) {
					if (items[i] === getlabDeptAll?.data?.data?.[j]?.deptName) {
						deptIds.push(getlabDeptAll?.data?.data?.[j]?._id);
					}
				}
			}
			setSelDeptsIds(deptIds);
		}
	};

	const handleDeleteDept = (dept) => {
		let sdepts = [...selDepts];
		let deptIds = [...selDeptsIds];
		let index = sdepts.indexOf(dept);
		if (index > -1) {
			sdepts.splice(index, 1);
			deptIds.splice(index, 1);
		}
		setSelDepts(sdepts);
		setSelDeptsIds(deptIds);
		console.log(selDepts);
	};

	useEffect(() => {
		console.log(selDepts);
	}, [selDepts]);

	const submitHandler = (e) => {
		// console.log(speciality)
		e.preventDefault();
		var letters = /^[A-Za-z\s]+$/;
		if (name === "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
			alert("Email address invalid");
		} else if (selDepts.length === 0) {
			alert("At least one department should be present.");
		} else if (mobilenumber.length === 0) {
			alert("Mobile number should be of 10 digits.");
		} else {
			let temp = window.confirm(
				"Are you sure you want to create \n" +
					"name : " +
					name +
					"\n" +
					"mobilenumber : " +
					mobilenumber +
					"\n" +
					"email: " +
					email +
					"\n" +
					"gender : " +
					gender +
					"\n" +
					"date of birth : " +
					dob +
					"\n" +
					"address : " +
					address +
					"\n" +
					"Department : " +
					selDepts
			);
			if (!temp) {
				alert("Please enter correct Lab Tech details");
			} else {
				console.log(name, mobilenumber, email, gender, dob, address);
				dispatch(
					createLabTech(
						name,
						mobilenumber,
						email,
						gender,
						dob,
						address,
						selDepts,
						selDeptsIds
					)
				);
				setName("");
				setMobileNumber("");
				setEmail("");
				setGender("");
				setDob("");
				setAddress("");
				setSelDepts([]);
				setSelDeptsIds([]);
			}
		}
	};

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	return (
		<>
			<Meta />

			<div>
				<h1 className="header-center">Add Lab Technician </h1>
				<div>
					<div className="row col-md-8 offset-md-2 form-group">
						<label className="form-label col-md-3" htmlFor="name">
							<b>Name: </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<input
							className="form-control col-md-8"
							id="name"
							type="text"
							placeholder="Enter your name"
							required="required"
							value={name}
							onChange={(e) => {
								setName(e.target.value);
								// setNewUser({ ...newUser, name: e.target.value });
							}}
						/>
					</div>
					<div className="row col-md-8 offset-md-2 form-group">
						<label className="form-label col-md-3" htmlFor="mobilenumber">
							<b>Mobile Number: </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<input
							className="form-control col-md-8"
							id="mobilenumber"
							type="text"
							placeholder="Enter your mobile number"
							required="required"
							value={mobilenumber}
							onChange={(e) => {
								setMobileNumber(e.target.value);
								// setNewUser({ ...newUser, mobilenumber: e.target.value });
							}}
							minlength="10"
							maxlength="10"
							pattern="^[0-9]*$"
						/>
					</div>
					<div className="row col-md-8 offset-md-2">
						<label className="form-label col-md-3" htmlFor="mobilenumber">
							<b>Department: </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						{/* <DropdownButton
							className='col-md-4'
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='secondary'
							title={deptName}
							onSelect={handleDeptSelect}>
							{getlabDeptAll?.data?.data?.length === 0 ? (
								<Dropdown.Item>
								
								</Dropdown.Item>
							) : (
								getlabDeptAll?.data?.data?.map((dept, index) => (
									<Dropdown.Item eventKey={dept.deptName + '@@' + dept._id}>
										{dept.deptName}
									</Dropdown.Item>
								))
							)}
						</DropdownButton> */}
						{/* <DropdownMultiselect
							options={depts}
							name="countries"
							buttonClass="btn btn-secondary"
							placeholder="Choose Department"
							placeholderMultipleChecked="Mulitple Selected"
							handleOnChange={(selected) => {
								handleDeptSelect(selected);
							}}
						/> */}
						<button
							className="btn btn-secondary"
							onClick={() => setOpenItems(!openItems)}
							aria-controls={`example-collapse-text`}
							aria-expanded={openItems}
						>
							Choose Department <i className="fas fa-caret-down"></i>
						</button>
					</div>
					<div className="row col-md-8 offset-md-2 mb-3">
						<Collapse
							className="offset-md-3 col-md-3 dropdown-box dropScroll"
							in={openItems}
							id={`example-collapse-text`}
						>
							<div>
								{depts?.map(
									(dept) =>
										dept !== "hospital" &&
										dept !== 0 && (
											<Dropdown.Item
												onClick={() => {
													handleDeptSelect(dept);
												}}
											>
												{dept}
											</Dropdown.Item>
										)
								)}
							</div>
						</Collapse>
					</div>

					<div className="row col-md-8 offset-md-2 mb-3">
						<label className="form-label col-md-3" htmlFor="email">
							<b>Selected Departments: </b>
						</label>
						<div
							className="card ml-3 col-md-8 divScroll"
							style={{ height: "100px" }}
						>
							<div className="row">
								{Array.isArray(selDepts) &&
									selDepts?.map((dept) => (
										<ButtonGroup
											aria-label="Basic example"
											className="ml-2 mt-2"
										>
											<Button variant="light">{dept}</Button>
											<Button
												variant="danger"
												onClick={() => handleDeleteDept(dept)}
											>
												<i className="fas fa-times"></i>
											</Button>
										</ButtonGroup>
									))}
							</div>
						</div>
					</div>

					<div className="row col-md-8 offset-md-2 form-group">
						<label className="form-label col-md-3" htmlFor="email">
							<b>Email </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<input
							className="form-control col-md-8"
							id="email"
							type="text"
							placeholder="Enter your email"
							required="required"
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
						/>
					</div>
					<div className="row col-md-8 offset-md-2 form-group">
						<label className="form-label col-md-3" htmlFor="name">
							<b>Address: </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<input
							className="form-control col-md-8"
							id="name"
							type="text"
							placeholder="Enter your address"
							required="required"
							value={address}
							onChange={(e) => {
								setAddress(e.target.value);
								// setNewUser({ ...newUser, address: e.target.value });
							}}
						/>
					</div>
					<div className="row col-md-8 offset-md-2 form-group">
						<label className="form-label col-md-3" htmlFor="name">
							<b>Date Of Birth:</b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<input
							className="form-control col-md-8"
							id="dob"
							type="date"
							required="required"
							value={dob}
							onChange={(e) => {
								setDob(e.target.value);
								// setNewUser({ ...newUser, dob: e.target.value });
							}}
							max={today}
						/>
					</div>
					<div className="row col-md-8 offset-md-2 form-group">
						<label className="form-label col-md-3" htmlFor="name">
							<b>Gender: </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<div className="row ml-2">
							<Form.Check
								onChange={() => {
									setGender("Male");
								}}
								checked={gender === "Male"}
								className="form-check col-5 col-md-4"
								inline
								label="Male"
								type="radio"
								id={`inline-radio-1`}
							/>
							<Form.Check
								onChange={() => {
									setGender("Female");
								}}
								checked={gender === "Female"}
								className="form-check col-5 col-md-4"
								inline
								label="Female"
								type="radio"
								id={`inline-radio-1`}
							/>
						</div>
						<div className="col-md-6 offset-md-6 offset-4 form-group">
							<button className="btn btn-success mt-3" onClick={submitHandler}>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default AddLabTechScreen;
