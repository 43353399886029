import { createSlice } from "@reduxjs/toolkit";

const getLeavesReducer = createSlice({
  name: "getLeaves",
  initialState: {},
  reducers: {
    getLeavesRequest: (state) => {
      state.loading = true;
    },
    getLeavesSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.getLeavesall = action.payload;
      state.error = false;
      state.success = true;
    },
    getLeavesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getLeavesReset: (state) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  getLeavesRequest,
  getLeavesSuccess,
  getLeavesFailure,
  getLeavesReset,
} = getLeavesReducer.actions;
export default getLeavesReducer.reducer;
