/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getStatisticsofParticularDoctor = createSlice({
	name: "getStatisticsofParticularDoctor",
	initialState: {},
	reducers: {
		getStatisticsofParticularDoctorRequest: (state) => {
			state.loading = true;
		},
		getStatisticsofParticularDoctorSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getStatisticsofParticulardoctor = action.payload;
			state.error = false;
			state.success = true;
		},
		getStatisticsofParticularDoctorFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getStatisticsofParticularDoctorReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getStatisticsofParticularDoctorRequest,
	getStatisticsofParticularDoctorSuccess,
	getStatisticsofParticularDoctorFailure,
	getStatisticsofParticularDoctorReset,
} = getStatisticsofParticularDoctor.actions;
export default getStatisticsofParticularDoctor.reducer;
