/** @format */

import { createSlice } from '@reduxjs/toolkit';

const editDoctorReducer = createSlice({
	name: 'editDoctor',
	initialState: {},
	reducers: {
		editDoctorRequest: (state) => {
			state.loading = true;
		},
		editDoctorSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.error = false;
			state.success = true;
		},
		editDoctorFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editDoctorReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	editDoctorRequest,
	editDoctorSuccess,
	editDoctorFailure,
	editDoctorReset,
} = editDoctorReducer.actions;
export default editDoctorReducer.reducer;
