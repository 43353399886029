/** @format */

import { createSlice } from "@reduxjs/toolkit";

const todaysVisitedPatientsStatistics = createSlice({
	name: "todaysVisitedPatientsStatistics",
	initialState: {},
	reducers: {
		todaysVisitedPatientsStatisticsRequest: (state) => {
			state.loading = true;
		},
		todaysVisitedPatientsStatisticsSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.todaysVisitedPatientsstatistics = action.payload;
			state.error = false;
			state.success = true;
		},
		todaysVisitedPatientsStatisticsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		todaysVisitedPatientsStatisticsReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	todaysVisitedPatientsStatisticsRequest,
	todaysVisitedPatientsStatisticsSuccess,
	todaysVisitedPatientsStatisticsFailure,
	todaysVisitedPatientsStatisticsReset,
} = todaysVisitedPatientsStatistics.actions;
export default todaysVisitedPatientsStatistics.reducer;
