/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { patientInventoryPurchase } from "../../../redux/inventoryRedux/inventoryApiCalls";
import { Breadcrumb } from "react-bootstrap";
import Meta from "../../../Components/Meta";
import { Col, Row, Container, Table, ListGroup } from "react-bootstrap";
import {
	getInventoryInfo,
	getUserDataByCategory,
} from "../../../redux/adminRedux/adminApiCalls";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import { updateUserProfileByAdmin } from "../../../redux/userApiCalls";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { getInventoryItemsForBilling } from "../../../redux/inventoryRedux/inventoryApiCalls";
import { getPrescription } from "../../../redux/prescriptionRedux/prescriptionApiCalls";
import { getPrescriptionReset } from "../../../redux/prescriptionRedux/getPrescription";
import ButtonLoader from "../../../Components/ButtonLoader";

const SellInventoryScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { adminInfo } = useSelector((state) => state.adminLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	console.log(adminInfo);
	const data1 = useSelector((state) => state.getUserdataByCategory);
	const userData = data1.userData;
	const { error, loading, success } = data1;

	const adduserinfo = useSelector((state) => state.userUpdate);
	const adduserinfoerror = adduserinfo.error;
	const adduserinfosuccess = adduserinfo.success;
	const adduserinfoloading = adduserinfo.loading;

	const [bloodGroup, setBloodGroup] = useState("Select a blood group");
	const handleBloodGroup = (eventKey) => {
		setBloodGroup(eventKey);
	};

	const { inventoryInfo } = useSelector((state) => state.inventoryInfo);

	useEffect(() => {
		dispatch(getInventoryInfo());
	}, []);

	const [lgShow, setLgShow] = useState(false);
	const [mno, setMno] = useState("");
	const handleSearchPatient = (e) => {
		e.preventDefault();
		setUserOption("Select a user");
		setUserIdx("");
		setPrescriptionsDataList(["Select a user"]);
		setMedicationData([]);
		setSelectedPrescriptions([]);
		dispatch(getUserDataByCategory(mno));
	};

	const [click, setClick] = useState(0);
	console.log(userData);
	const blood_groups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-", "NA"];
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [dob, setdob] = useState(new Date());

	const [userId, setUserId] = useState("");

	const [prescriptionsDataList, setPrescriptionsDataList] = useState([]);

	useEffect(() => {
		setPrescriptionsDataList(["Select a user"]);
		setSelectedPrescriptions([]);
		setMedicationData([]);
	}, []);

	const addUserOnSubmit = (e) => {
		e.preventDefault();
		console.log(name + " " + gender + " " + dob + " " + mno);
		var letters = /^[A-Za-z\s]+$/;
		if (name === "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (gender === "") {
			alert("Please select gender, gender cannot be empty");
		} else if (dob === "") {
			alert("Please select dob, dob cannot be empty");
		} else if (new Date(dob) >= new Date(new Date())) {
			alert("Please select proper dob, dob should not exceed todays date");
		} else {
			if (bloodGroup == "Select a blood group") {
				dispatch(
					updateUserProfileByAdmin(name.trim(), gender, dob, mno, "NA", "")
				);
			} else {
				dispatch(
					updateUserProfileByAdmin(
						name.trim(),
						gender,
						dob,
						mno,
						bloodGroup,
						""
					)
				);
			}
			setName("");
			setGender("");
			setdob("");
			setClick(1);
			setPrescriptionsDataList([]);
			setSelectedPrescriptions([]);
			setMedicationData([]);
			setBloodGroup("Select a blood group");
		}
	};
	const users_array = [];

	users_array.push({
		name: userData?.data?.data?.name,
		id: userData?.data?.data?.bookingId,
		doctorBookings: userData?.data?.data?.bookingDoctorsArray,
	});
	for (
		let i = 0;
		i < userData?.data?.data?.registered_patients_array?.length;
		i++
	) {
		users_array.push({
			name: userData?.data?.data?.registered_patients_array[i]?.name,
			id: userData?.data?.data?.registered_patients_array[i]?.bookingId,
			doctorBookings:
				userData?.data?.data?.registered_patients_array[i]?.bookingDoctorsArray,
		});
	}
	console.log(users_array);
	const [userOption, setUserOption] = useState("Select a user");
	const [userIdx, setUserIdx] = useState("");

	const handleUserSelect = (eventKey) => {
		setUserOption(eventKey?.split("@@")[0]);
		setUserIdx(eventKey?.split("@@")[1]);
		setUserId(userData?.data?.data?._id);
		if (eventKey?.split("@@")[1] === "0") {
			setPrescriptionsDataList(
				userData?.data?.data?.prescription_date?.length > 0
					? userData?.data?.data?.prescription_date
					: ["No prescriptions available"]
			);
			setSelectedPrescriptions([]);
			setMedicationData([]);
		} else {
			setPrescriptionsDataList(
				userData?.data?.data?.registered_patients_array[
					Number(eventKey?.split("@@")[1]) - 1
				]?.prescription_date?.length > 0
					? userData?.data?.data?.registered_patients_array[
							Number(eventKey?.split("@@")[1]) - 1
					  ]?.prescription_date
					: ["No prescriptions available"]
			);
			setSelectedPrescriptions([]);
			setMedicationData([]);
		}
	};

	useEffect(() => {
		//dispatch(getSpecialities());
		if (mno !== "") dispatch(getUserDataByCategory(mno));
		// if(mno !== "") {
		//   dispatch(getUserDataByCategory(mno));
		// }
	}, [history, dispatch, click, adduserinfosuccess, adduserinfoerror]);

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}
	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	useEffect(() => {
		dispatch(getInventoryItemsForBilling());
	}, []);

	const getInventoryItemsForbilling = useSelector(
		(state) => state.getInventoryItemsForBilling
	);

	console.log(getInventoryItemsForbilling);

	const [values, setValues] = useState([
		{
			productName: "",
			mrp: "",
			quantity: "",
			productId: "",
			expiryDate: "",
			sellingPricesIndex: -1,
		},
	]);

	const handleDeleteRow = (index) => {
		let updatedData = [...values];
		if (index > -1) {
			updatedData?.splice(index, 1);
		}
		setValues(updatedData);
	};

	const validateProductName = (index, sellingPriceslength) => {
		if (rowFilteredProductStock[index]?.length === 0) {
			alert("Entered product is not available");
		} else if (sellingPriceslength === 0) {
			alert("No stock available for the entered product");
		}
	};

	const handleChange = (index, name, value) => {
		let updatedData = [...values];
		if (name === "productName") {
			const productArray =
				getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
					(product) => {
						return product?.name?.toLowerCase() === value?.toLowerCase();
					}
				);
			updatedData[index] = {
				...updatedData[index],
				[name]: productArray?.name,
				productId: productArray?.productId,
				sellingPrice: productArray?.selling_prices,
				sellingPricesIndex: 0,
				hsn: productArray?.hsn_code,
				mrp:
					productArray?.selling_prices?.length === 1
						? productArray?.selling_prices[0]?.selling_price
						: "Choose",
				quantity: 0,
				expiryDate:
					productArray?.selling_prices?.length === 1
						? productArray?.selling_prices[0]?.expiryDate
						: "",
			};
		} else updatedData[index][name] = value;
		setValues(updatedData);
	};

	const handleAddRow = () => {
		const newRow = {
			productName: "",
			mrp: "",
			quantity: "",
			productId: "",
			expiryDate: "",
			sellingPricesIndex: "",
		};
		setValues([...values, newRow]);
	};

	const [rowProductSearch, setRowProductSearch] = useState({});
	const [rowFilteredProductStock, setRowFilteredProductStock] = useState({});

	const handleProductSearchChange = (index, e) => {
		const value = e.target.value;
		setRowProductSearch((prev) => ({ ...prev, [index]: value }));
	};

	useEffect(() => {
		const updatedFilteredProductStock = {};
		Object.keys(rowProductSearch).forEach((index) => {
			const value = rowProductSearch[index];
			updatedFilteredProductStock[index] =
				getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data.filter(
					(item) => item.name.toLowerCase().includes(value.toLowerCase())
				);
		});
		setRowFilteredProductStock(updatedFilteredProductStock);
	}, [rowProductSearch]);

	const handleSelectProduct = (index, product) => {
		console.log(product, "line265");
		const updatedData = [...values];
		updatedData[index] = {
			...updatedData[index],
			productName: product.name,
			productId: product._id,
			sellingPrice: product?.selling_prices,
			hsn: product?.hsn_code,
			mrp:
				product?.selling_prices?.length === 1
					? product?.selling_prices[0]?.selling_price
					: "Choose",
			quantity: 0,
			expiryDate:
				product?.selling_prices?.length === 1
					? product?.selling_prices[0]?.expiryDate
					: "",
			sellingPricesIndex: 0,
		};
		setValues(updatedData);
		setRowProductSearch((prev) => ({ ...prev, [index]: "" }));
		setRowFilteredProductStock((prev) => ({ ...prev, [index]: [] }));
	};

	const formatDate = (dateString) => {
		if (!dateString) return ""; // Handle edge case where dateString is falsy

		// Convert dateString to string format if it's not already
		dateString = dateString.toString();

		if (dateString.length === 8) {
			// Assuming dateString is in yyyymmdd format
			const year = dateString.substring(0, 4);
			const month = dateString.substring(4, 6);
			const day = dateString.substring(6, 8);
			return `${day}-${month}-${year}`;
		} else {
			// Return empty string if dateString is not in expected format
			return "";
		}
	};

	const [showPaymentModal, setShowPaymentModal] = useState(false);

	const handleClosePaymentModal = () => {
		setShowPaymentModal(false);
	};

	const handleOpenPaymentModal = () => {
		setShowPaymentModal(true);
	};

	const [amount, setAmount] = useState(0);
	const [discount, setDiscount] = useState("");
	const [discountType, setDiscountType] = useState("%");
	const [discountReason, setDiscountReason] = useState("");
	const [amountPaidCash, setAmountPaidCash] = useState(0);
	const [amountPaidOnline, setAmountPaidOnline] = useState(0);
	const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState(0);

	const submitHandler = () => {
		handleOpenPaymentModal();
		let totalAmount = values
			?.reduce((acc, product) => {
				return acc + Number(product?.mrp) * Number(product?.quantity);
			}, 0)
			.toFixed(2);

		setAmount(totalAmount);
		setTotalAmountAfterDiscount(totalAmount);
		setAmountPaidOnline(0);
		setAmountPaidCash(0);
		setDiscount(0);
		setDiscountReason("");
	};

	const handleDiscountType = (e) => {
		setDiscountType(e);
		if (Number(discount) > 0) {
			let totalamountafterdiscount =
				e === "%"
					? Number(amount) - Number(amount) * Number(discount) * 0.01
					: Number(amount) - Number(discount);

			setTotalAmountAfterDiscount(totalamountafterdiscount.toFixed(2));
			setAmountPaidOnline(0);
			setAmountPaidCash(0);
		}
	};

	const handleDiscountChange = (e) => {
		if (discountType === "%" && Number(e.target.value) > 100) {
			alert("Discount cannot be greater than 100%");
		} else if (discountType === "Rs" && Number(e.target.value) > amount) {
			alert("Discount cannot be greater than " + amount);
		} else {
			setDiscount(e?.target?.value);
			let totalamountafterdiscount =
				discountType === "%"
					? Number(amount) - Number(amount) * Number(e?.target?.value) * 0.01
					: Number(amount) - Number(e?.target?.value);
			setTotalAmountAfterDiscount(totalamountafterdiscount?.toFixed(2));
			setAmountPaidOnline(0);
			setAmountPaidCash(0);
		}
	};

	const handleAmounts = (value, type) => {
		value = Number(value);
		if (value > totalAmountAfterDiscount) {
			alert(`Amount cannot exceed ${totalAmountAfterDiscount}`);
		} else {
			if (type === "cash") {
				setAmountPaidOnline(0);
				setAmountPaidCash(Number(value));
				setAmountPaidOnline(
					(Number(totalAmountAfterDiscount) - Number(value)).toFixed(2)
				);
			} else {
				setAmountPaidCash(0);
				setAmountPaidOnline(Number(value));
				setAmountPaidCash(
					(Number(totalAmountAfterDiscount) - Number(value)).toFixed(2)
				);
			}
		}
	};

	const [completePaymentLoading, setCompletePaymentLoading] = useState(false);

	const handleCompletePayment = async () => {
		if (userOption === "Select a user") {
			alert("Select a user");
		} else if (mno === "") {
			alert("Search a patient and select the user");
		} else {
			setCompletePaymentLoading(true);
			const isDiscountPercentage = discountType === "%";
			const productsArray = values?.map((product, index) => {
				let productArrayFromDb =
					getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
						(x) => x.name === product?.productName
					);

				let sellingPricesItem =
					productArrayFromDb?.selling_prices[
						Number(product?.sellingPricesIndex)
					];

				const sellingPriceIndex = Number(product?.sellingPricesIndex);

				if (sellingPricesItem) {
					var updatedSellingPrices = productArrayFromDb.selling_prices.map(
						(price) =>
							price === sellingPricesItem
								? {
										...price,
										quantityLeft:
											price.quantityLeft - Number(product?.quantity),
								  }
								: price
					);
				}

				const mrp = Number(product?.mrp);
				const quantity = Number(product?.quantity);
				const costPrice = Number(
					updatedSellingPrices[sellingPriceIndex]?.cost_price
				);
				const discountAmount = isDiscountPercentage
					? mrp * Number(discount) * 0.01
					: mrp * Number((discount / amount)?.toFixed(2) * 100) * 0.01;
				const discountedPrice = mrp;

				return {
					productId: productArrayFromDb._id,
					selling_prices: updatedSellingPrices,
					Totalquantity: updatedSellingPrices?.reduce((acc, item) => {
						return acc + (item?.quantityLeft || 0); // Add quantityLeft if it exists, default to 0 if not
					}, 0),
					status:
						updatedSellingPrices?.reduce((acc, item) => {
							return acc + (item?.quantityLeft || 0); // Add quantityLeft if it exists, default to 0 if not
						}, 0) > 0
							? "in-stock"
							: "out-of-stock",
					Lifetime_sale_amount: productArrayFromDb?.Lifetime_sale_amount,
					// productArrayFromDb?.Lifetime_sale_amount +
					// quantity * discountedPrice,
					quantitySold: quantity,
					Lifetime_quantity_sold: productArrayFromDb?.Lifetime_quantity_sold,
					//	productArrayFromDb?.Lifetime_quantity_sold + quantity,
					Lifetime_profit: productArrayFromDb?.Lifetime_profit,
					// productArrayFromDb?.Lifetime_profit +
					// (discountedPrice - costPrice) * quantity,
					todays_profit: productArrayFromDb?.todays_profit,
					// productArrayFromDb?.todays_profit +
					// (discountedPrice - costPrice) * quantity,

					todays_sale_Amount: productArrayFromDb?.todays_sale_Amount,
					//productArrayFromDb?.todays_sale_Amount + quantity * discountedPrice,
					todays_quantity_sold: productArrayFromDb?.todays_quantity_sold,
					//productArrayFromDb?.todays_quantity_sold + quantity,
					vendorId: sellingPricesItem?.supplierId,
					present_sale_amount: parseFloat(
						(quantity * discountedPrice)?.toFixed(2)
					),
					present_profit: parseFloat(
						((discountedPrice - costPrice) * quantity)?.toFixed(2)
					),
				};
			});
			console.log(productsArray);
			console.log(values);
			let amountCash = Number(amountPaidCash);
			let amountOnline = Number(amountPaidOnline);
			const purchaseBasedonCategory = values?.map((product, index) => {
				let productArrayFromDb =
					getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
						(x) => x.name === product?.productName
					);
				let productArrayFromResult = productsArray?.find(
					(x) => x.productId === product?.productId
				);
				let cashProdAmount = 0;
				let OnlineProdAmount = 0;
				console.log(productsArray, product, "line426");
				if (amountCash >= Number(productArrayFromResult?.present_sale_amount)) {
					cashProdAmount = Number(productArrayFromResult?.present_sale_amount);
					amountCash -= Number(productArrayFromResult?.present_sale_amount);
				} else {
					cashProdAmount = amountCash;
					OnlineProdAmount =
						Number(productArrayFromResult?.present_sale_amount) - amountCash;
					amountCash = 0;
					amountOnline -= OnlineProdAmount;
				}
				return {
					categoryId: productArrayFromDb?.categoryId,
					amount: parseFloat(
						Number(productArrayFromResult?.present_sale_amount)?.toFixed(2)
					),
					amountPaidInCash: parseFloat(Number(cashProdAmount)?.toFixed(2)),
					amountPaidInOnline: parseFloat(Number(OnlineProdAmount)?.toFixed(2)),
					profit: parseFloat(
						Number(productArrayFromResult?.todays_profit)?.toFixed(2)
					),
					discount: 0,
				};
			});
			console.log(purchaseBasedonCategory);
			let inventoryDetails = values?.map((product, index) => {
				let productArrayFromDb =
					getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
						(x) => x.name === product?.productName
					);

				let sellingPricesItem =
					productArrayFromDb?.selling_prices[
						Number(product?.sellingPricesIndex)
					];

				const sellingPriceIndex = Number(product?.sellingPricesIndex);
				if (sellingPricesItem) {
					var updatedSellingPrices = productArrayFromDb.selling_prices.map(
						(price) =>
							price === sellingPricesItem
								? {
										...price,
										quantityLeft:
											price.quantityLeft - Number(product?.quantity),
								  }
								: price
					);
				}

				const mrp = Number(product?.mrp);
				const quantity = Number(product?.quantity);
				const costPrice = Number(
					updatedSellingPrices[sellingPriceIndex]?.cost_price
				);
				const discountAmount = isDiscountPercentage
					? mrp * Number(discount) * 0.01
					: mrp * Number((discount / amount)?.toFixed(2) * 100) * 0.01;
				const discountedPrice = mrp - (discountAmount ? discountAmount : 0);
				return {
					categoryName: productArrayFromDb?.category,
					categoryId: productArrayFromDb?.categoryId,
					amount: Number((Number(product?.mrp) * quantity).toFixed(2)),
					inventory_items: {
						name: productArrayFromDb?.name,
						price: Number((Number(product?.mrp) * quantity).toFixed(2)),
						quantity: quantity,
						amount: Number((Number(discountedPrice) * quantity).toFixed(2)),
					},
				};
			});
			let billDetails = {
				AmountToBePaid: amount,
				AmountPaid: Number(
					(Number(amountPaidCash) + Number(amountPaidOnline)).toFixed(2)
				),
				AmountDue: 0,
				invoiceNumber:
					"INV" + getCurrentDateIST()?.split("-")?.join("") + mno + userIdx,
				prescription_charges: {},
				billCreatedBy: adminInfo
					? adminInfo?.user?.name
					: receptionInfo
					? receptionInfo?.data?.user?.name
					: "",
				inventory_charges: {
					AmountToBePaid: Number(amount)?.toFixed(2),
					AmountDue: 0,
					discount: {
						discountAmount: parseFloat(
							(Number(amount) - Number(totalAmountAfterDiscount)).toFixed(2)
						),
						discountReason: discountReason,
					},
					inventoryDetails: inventoryDetails,
					additional_charges: [],
				},
				lab_charges: {},
				billDate: getCurrentDateIST(),
				billType: "Pharmacy Bill",
				amountPaidOnline: parseFloat(Number(amountPaidOnline).toFixed(2)),
				amountPaidCash: parseFloat(Number(amountPaidCash).toFixed(2)),
			};
			const stock_expiring_in_one_week =
				inventoryInfo?.user?.stock_expiring_in_one_week
					?.map((adminProduct) => {
						// Find the matching product from values
						const matchingProduct = values?.find((product) => {
							let productArrayFromDb =
								getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
									(x) => x.name === product?.productName
								);

							let sellingPricesItem =
								productArrayFromDb?.selling_prices[
									Number(product?.sellingPricesIndex)
								];

							// Match admin product with values and selling price details
							return (
								adminProduct.name === productArrayFromDb?.name &&
								adminProduct.supplierId === sellingPricesItem?.supplierId &&
								adminProduct.batchNo === sellingPricesItem?.batchNo
							);
						});

						// If a matching product is found, update the quantityLeft by adding 3
						if (matchingProduct) {
							return {
								...adminProduct, // Keep all existing properties
								quantityLeft:
									adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
							};
						}

						// Return undefined if no match
						return undefined;
					})
					?.filter(Boolean); // Remove undefined entries

			const stock_expiring_in_one_month =
				inventoryInfo?.user?.stock_expiring_in_one_month
					?.map((adminProduct) => {
						// Find the matching product from values
						const matchingProduct = values?.find((product) => {
							let productArrayFromDb =
								getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
									(x) => x.name === product?.productName
								);

							let sellingPricesItem =
								productArrayFromDb?.selling_prices[
									Number(product?.sellingPricesIndex)
								];

							// Match admin product with values and selling price details
							return (
								adminProduct.name === productArrayFromDb?.name &&
								adminProduct.supplierId === sellingPricesItem?.supplierId &&
								adminProduct.batchNo === sellingPricesItem?.batchNo
							);
						});

						// If a matching product is found, update the quantityLeft by adding 3
						if (matchingProduct) {
							return {
								...adminProduct, // Keep all existing properties
								quantityLeft:
									adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
							};
						}

						// Return undefined if no match
						return undefined;
					})
					?.filter(Boolean); // Remove undefined entries

			const stock_expiring_in_three_months =
				inventoryInfo?.user?.stock_expiring_in_three_months
					?.map((adminProduct) => {
						// Find the matching product from values
						const matchingProduct = values?.find((product) => {
							let productArrayFromDb =
								getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
									(x) => x.name === product?.productName
								);

							let sellingPricesItem =
								productArrayFromDb?.selling_prices[
									Number(product?.sellingPricesIndex)
								];

							// Match admin product with values and selling price details
							return (
								adminProduct.name === productArrayFromDb?.name &&
								adminProduct.supplierId === sellingPricesItem?.supplierId &&
								adminProduct.batchNo === sellingPricesItem?.batchNo
							);
						});

						// If a matching product is found, update the quantityLeft by adding 3
						if (matchingProduct) {
							return {
								...adminProduct, // Keep all existing properties
								quantityLeft:
									adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
							};
						}

						// Return undefined if no match
						return undefined;
					})
					?.filter(Boolean); // Remove undefined entries

			const stock_expiring_in_six_months =
				inventoryInfo?.user?.stock_expiring_in_six_months
					?.map((adminProduct) => {
						// Find the matching product from values
						const matchingProduct = values?.find((product) => {
							let productArrayFromDb =
								getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
									(x) => x.name === product?.productName
								);

							let sellingPricesItem =
								productArrayFromDb?.selling_prices[
									Number(product?.sellingPricesIndex)
								];

							// Match admin product with values and selling price details
							return (
								adminProduct.name === productArrayFromDb?.name &&
								adminProduct.supplierId === sellingPricesItem?.supplierId &&
								adminProduct.batchNo === sellingPricesItem?.batchNo
							);
						});

						// If a matching product is found, update the quantityLeft by adding 3
						if (matchingProduct) {
							return {
								...adminProduct, // Keep all existing properties
								quantityLeft:
									adminProduct.quantityLeft - Number(matchingProduct.quantity), // Update quantityLeft
							};
						}

						// Return undefined if no match
						return undefined;
					})
					?.filter(Boolean); // Remove undefined entries

			const doctorRevenues =
				MedicationData?.length > 0
					? MedicationData?.map((docpres, index) => {
							const totalAmount = docpres?.prescriptionData?.reduce(
								(acc, medicine) => {
									// Find matching medicine in values
									const matchingMed = values?.find(
										(med) => med?.productName === medicine?.name
									);

									// If a match is found, add the amount to the accumulator
									if (matchingMed) {
										const amount =
											Number(matchingMed?.mrp) * Number(matchingMed?.quantity);
										acc += amount;
									}

									return acc; // Return updated accumulator
								},
								0
							); // Initialize accumulator to 0

							return {
								doctor: docpres?.doctorId,
								speciality: docpres?.specialityId,
								doctorInventoryRevenues: totalAmount,
							};
					  })
					: [
							{
								doctor: doctorId,
								speciality: specialityId,
								doctorInventoryRevenues: values?.reduce((acc, product) => {
									return acc + Number(product?.mrp) * Number(product?.quantity);
								}, 0),
							},
					  ];

			await dispatch(
				patientInventoryPurchase(
					productsArray,
					billDetails,
					userId,
					Number(userIdx),
					purchaseBasedonCategory,
					totalAmountAfterDiscount,
					stock_expiring_in_one_week?.length === 1 &&
						stock_expiring_in_one_week[0] === undefined
						? []
						: stock_expiring_in_one_week,
					stock_expiring_in_one_month?.length === 1 &&
						stock_expiring_in_one_month[0] === undefined
						? []
						: stock_expiring_in_one_month,
					stock_expiring_in_three_months?.length === 1 &&
						stock_expiring_in_three_months[0] === undefined
						? []
						: stock_expiring_in_three_months,
					stock_expiring_in_six_months?.length === 1 &&
						stock_expiring_in_six_months[0] === undefined
						? []
						: stock_expiring_in_six_months,
					doctorRevenues
				)
			);
			handleClosePaymentModal();
			await dispatch(getInventoryItemsForBilling());
			await dispatch(getInventoryInfo());
			setCompletePaymentLoading(false);

			setPrescriptionsDataList([]);
			setSelectedPrescriptions([]);
			setMedicationData([]);
			handleClosePrescriptionsListModal();
			setValues([]);
			setUserId("");
			setUserOption("Select a user");
			setUserIdx("");
			setValues([
				{
					productName: "",
					mrp: "",
					quantity: "",
					productId: "",
					expiryDate: "",
					sellingPricesIndex: "",
				},
			]);
			setRowProductSearch({});
			setRowFilteredProductStock({});
			setAmount(0);
			setDiscount("");
			setDiscountType("%");
			setDiscountReason("");
			setAmountPaidCash(0);
			setAmountPaidOnline(0);
			setTotalAmountAfterDiscount(0);
			setSpecialityOption("Choose Speciality");
			setSpecialityId("");
			setDoctorFromSpecialityArray([]);

			setDoctorOption("Choose Doctors");
			setDoctorId("");
			setMno("");
		}
	};

	const [showPrescriptionsListModal, setShowPrescriptionsListModal] =
		useState(false);
	const handleClosePrescriptionsListModal = () =>
		setShowPrescriptionsListModal(false);
	const handleShowPrescriptionsListModal = () => {
		if (
			mno !== "" &&
			mno !== undefined &&
			prescriptionsDataList[0] !== "Select a user"
		) {
			setShowPrescriptionsListModal(true);
			setSelectedPrescriptions([]);
			setMedicationData([]);
		} else if (mno === "" || mno === undefined) {
			alert("Please enter Mobile Number and select user");
		} else if (prescriptionsDataList[0] === "Select a user") {
			alert("please select user");
		}
	};

	const [selectedPrescriptions, setSelectedPrescriptions] = useState([]);

	const [MedicationData, setMedicationData] = useState([]);

	const handleCheckboxChange = (event, item) => {
		const { checked } = event.target;

		setSelectedPrescriptions((prevSelected) => {
			if (checked) {
				return [...prevSelected, item];
			} else {
				return prevSelected.filter((selectedItem) => selectedItem !== item);
			}
		});
	};

	let presData = useSelector((state) => state.getPrescription);

	const handleLoadPrescriptionData = () => {
		// Clear medication data initially
		setMedicationData([]);

		// Iterate over selectedPrescriptions sequentially
		for (const prescription of selectedPrescriptions) {
			// Dispatch the getPrescription action
			dispatch(getPrescription(prescription?.prescriptionId));
		}

		handleClosePrescriptionsListModal();
	};

	useEffect(() => {
		setMedicationData([]);
		dispatch(getPrescriptionReset());
	}, []);

	useEffect(() => {
		if (presData?.success) {
			setMedicationData((prevMedication) => [
				...prevMedication, // Spread previous medication data (as an array)
				{
					prescriptionData:
						presData?.getPrescription?.data?.data?.doctor?.medicines || [],
					doctorName: presData?.getPrescription?.data?.data?.doctor?.doctorName,
					doctorId: presData?.getPrescription?.data?.data?.doctor?.doctorId,
					specialityId:
						presData?.getPrescription?.data?.data?.speciality?.specialityId,
				},
			]);
			dispatch(getPrescriptionReset());
		}
	}, [presData?.loading]);

	console.log(selectedPrescriptions);

	console.log(MedicationData);
	const [specialityOption, setSpecialityOption] = useState("Choose Speciality");
	const [specialityId, setSpecialityId] = useState("");
	const [DoctorFromSpecialityArray, setDoctorFromSpecialityArray] = useState(
		[]
	);

	const [doctorOption, setDoctorOption] = useState("Choose Doctors");
	const [doctorId, setDoctorId] = useState("");

	const handleSpecialitySelect = (eventKey) => {
		console.log(eventKey);
		let arr = [];
		arr = eventKey.split("/");
		setSpecialityOption(arr[0]);
		setSpecialityId(arr[1]);
		setDoctorOption("Choose Doctor");
		setDoctorFromSpecialityArray(
			getSpecialityAll.data.data[arr[2]].doctorsArray
		);
	};

	const handleDoctorSelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		setDoctorId(arr[1]);
		setDoctorOption(arr[0]);
	};

	const { getSpecialityAll } = useSelector((state) => state.getSpeciality);
	const getSpecialityAllData = useSelector((state) => state.getSpeciality);
	const getSpecialityAllsuccess = getSpecialityAllData.success;
	const getSpecialityAllerror = getSpecialityAllData.error;
	const getSpecialityAllloading = getSpecialityAllData.loading;

	let allSpecialities = [];
	if (getSpecialityAll) {
		console.log(getSpecialityAll);
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}
	console.log(allSpecialities);

	return (
		<>
			<Meta />
			<h1 className='header-center mt-3'>Patient Inventory Purchase</h1>
			<form className='form-group' onSubmit={handleSearchPatient}>
				<Container className='header-center mt-5'>
					<Row className='justify-content-center'>
						<Col md='auto' className='text-center mt-1'>
							<h6>
								Enter Patient's Mobile Number{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							<input
								className='form-control'
								type='text'
								placeholder='9999999999'
								id='mobilenumber'
								required='required'
								onChange={(e) => setMno(e.target.value)}
								minlength='10'
								maxlength='10'
								pattern='^[0-9]*$'
								value={mno}
							/>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							<Button type='submit' id='search' className='btn btn-primary'>
								Search
							</Button>
						</Col>
					</Row>
				</Container>
			</form>
			<Container className='header-center mt-5 mb-5 card col-md-9'>
				<Row className='mt-4 mb-4'>
					<Col md='auto' className='text-center mt-1'></Col>
					<Col md='auto' className='text-center mt-1'>
						<h6>
							Select a user{" "}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={userOption}
							onSelect={handleUserSelect}
							onClick={() => {
								if (mno === "" || mno === undefined) {
									alert("Enter Mobile Number");
								}
							}}>
							<div className='dropScroll'>
								{error !== "No patient exists with that mobile number" &&
									mno !== "" &&
									mno !== undefined &&
									users_array.map((users, index) => (
										<Dropdown.Item eventKey={users.name + "@@" + index}>
											{users.name}
										</Dropdown.Item>
									))}
							</div>
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						{users_array.length >= 6 ? (
							<Button disabled className='btn btn-danger'>
								Users limit reached
							</Button>
						) : (
							<Button
								className='btn btn-secondary'
								onClick={() => setLgShow(true)}>
								Add User
							</Button>
						)}
					</Col>
				</Row>
			</Container>
			{MedicationData?.length > 0 && (
				<Container className='mt-5 mb-5 card col-md-11'>
					<div className='row mt-4 mb-2 section'>
						<div className='col-md-4'>Medication</div>
					</div>
					<Table bordered className='custom-table'>
						<thead>
							<tr>
								<th>Medicine</th>
								<th>Frequency</th>
								<th>Duration</th>
							</tr>
						</thead>
						<tbody>
							{MedicationData?.map((docpres, idx) => (
								<>
									<thead>
										<tr>
											<th>Prescribed By: {docpres?.doctorName}</th>
										</tr>
									</thead>
									{docpres?.prescriptionData?.map((medicine, index) => (
										<tr key={idx}>
											<td>{medicine?.name}</td>
											<td>
												{medicine?.dosage?.includes("O@@") ? (
													medicine?.dosage?.split("O@@")[1]
												) : (
													<>
														{medicine?.dosage?.includes("A/F") ? (
															<span className='medication-definition after-food'>
																AF
															</span>
														) : (
															<span className='medication-definition before-food'>
																BF
															</span>
														)}
														{medicine?.dosage?.split(",")?.includes("M") && (
															<span className='medication-definition morning'>
																M
															</span>
														)}
														{medicine?.dosage?.split(",")?.includes("A") && (
															<span className='medication-definition afternoon'>
																AN
															</span>
														)}
														{medicine?.dosage?.split(",")?.includes("N") && (
															<span className='medication-definition night'>
																N
															</span>
														)}{" "}
														{medicine?.duration?.split(" ")[2]}
													</>
												)}
											</td>
											<td>
												{medicine?.duration?.split(" ")[0]}{" "}
												{medicine?.duration?.charAt(0) === "1"
													? medicine?.duration?.split(" ")[1].replace("s", "")
													: medicine?.duration?.split(" ")[1]}
											</td>
										</tr>
									))}
								</>
							))}
						</tbody>
					</Table>
					<div className='row mt-4 mb-2 section'>
						<div className='col-md-12'>
							<div className='medication-definition-box'>
								<span className='medication-definition before-food'>BF</span>
								<span className='mt-1'>Before Food</span>
								<span className='medication-definition after-food'>AF</span>
								<span className='mt-1'>After Food</span>
								<span className='medication-definition morning'>M</span>
								<span className='mt-1'>Morning</span>
								<span className='medication-definition afternoon'>AN</span>
								<span className='mt-1'>After Noon</span>
								<span className='medication-definition night'>N</span>
								<span className='mt-1'>Night</span>
							</div>
						</div>
					</div>
				</Container>
			)}
			<Container className='mt-5 mb-5 card col-md-11'>
				<Row className='mt-4 justify-content-between'>
					<Col md='auto' className='text-center mt-1'>
						<h6>
							Add Inventory Items{" "}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						<Button
							variant='primary'
							onClick={handleShowPrescriptionsListModal}>
							Show Prescriptions List
						</Button>
					</Col>
				</Row>
				<Table bordered className='custom-table mt-3'>
					<thead>
						<tr>
							<th>Product Name</th>
							<th>M.R.P</th>
							<th style={{ width: "10%" }}>Quantity</th>
							<th style={{ width: "15%" }}>Expiry Date</th>
							<th style={{ width: "15%" }}>Total Price</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{values.map((item, index) => (
							<tr key={index}>
								<td>
									<input
										className='form-control'
										name='productName'
										value={item?.productName}
										onChange={(e) => {
											handleChange(index, "productName", e?.target?.value);
											handleProductSearchChange(index, e);
										}}
									/>

									{rowProductSearch[index] &&
										rowFilteredProductStock[index]?.length > 0 && (
											<ListGroup
												className='position-relative dropScroll'
												style={{ zIndex: 100 }}>
												{rowProductSearch[index] &&
													rowFilteredProductStock[index]?.length > 0 &&
													rowFilteredProductStock[index] &&
													rowFilteredProductStock[index]?.find(
														(product) =>
															product.name?.toLowerCase() !==
															item.productName?.toLowerCase()
													) &&
													rowFilteredProductStock[index].map((product) => (
														<ListGroup.Item
															key={product.name}
															action
															onClick={() => {
																handleSelectProduct(index, product);
															}}>
															{product.name}
														</ListGroup.Item>
													))}
											</ListGroup>
										)}
								</td>
								<td>
									<DropdownButton
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={item?.mrp ? item?.mrp : "Choose"}
										onClick={() =>
											validateProductName(
												index,
												item?.sellingPrice?.length,
												item?.sellingPrice?.quantityLeft
											)
										}
										onSelect={(e) => {
											let arr = e?.split("@@");
											handleChange(index, "mrp", arr[0]);
											handleChange(index, "expiryDate", arr[1]);
											handleChange(index, "quantity", 1);
											handleChange(index, "sellingPricesIndex", arr[2]);
										}}>
										<div className='dropScroll'>
											{item?.sellingPrice?.map(
												(price, index2) =>
													price?.quantityLeft > 0 && (
														<Dropdown.Item
															eventKey={
																price.selling_price +
																"@@" +
																price?.expiryDate +
																"@@" +
																index2
															}>
															{`${price.selling_price} (${formatDate(
																price?.expiryDate
															)})`}
														</Dropdown.Item>
													)
											)}
										</div>
									</DropdownButton>
								</td>
								<td>
									<input
										className='form-control'
										type='number'
										name='quantity'
										value={item?.quantity}
										onChange={(e) => {
											const productArray =
												getInventoryItemsForbilling?.getInventoryItemsForbilling?.data?.data?.find(
													(product) => product?.name === item?.productName
												);

											if (
												productArray?.selling_prices[item?.sellingPricesIndex]
													?.quantityLeft < Number(e?.target?.value)
											) {
												alert(
													`Available quantity is only ${
														productArray?.selling_prices[
															item?.sellingPricesIndex
														]?.quantityLeft
													}`
												);
											} else {
												handleChange(index, "quantity", e?.target?.value);
											}
										}}
									/>
								</td>
								<td>
									<input
										className='form-control'
										disabled
										value={
											item?.expiryDate === 99999999
												? "Not available"
												: formatDate(item?.expiryDate)
										}
									/>
								</td>
								<td>
									<input
										className='form-control'
										disabled
										value={
											Number(item?.mrp) * Number(item?.quantity)
												? (Number(item?.mrp) * Number(item?.quantity))?.toFixed(
														2
												  )
												: 0
										}
									/>
								</td>
								<td>
									<button
										className='btn btn-danger'
										onClick={() => handleDeleteRow(index)}>
										<i className='fa fa-trash'></i>
									</button>
								</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan='6' style={{ textAlign: "center" }}>
								<button className='btn btn-secondary' onClick={handleAddRow}>
									+ Add more items
								</button>
							</td>
						</tr>
						<tr>
							<td colSpan='5' className='text-right'>
								<strong>Total Amount:</strong>
							</td>
							<td>
								Rs{" "}
								{values?.reduce((acc, product) => {
									return acc + Number(product?.mrp) * Number(product?.quantity);
								}, 0)
									? values
											?.reduce((acc, product) => {
												return (
													acc + Number(product?.mrp) * Number(product?.quantity)
												);
											}, 0)
											.toFixed(2)
									: "0.00"}
							</td>
						</tr>
					</tfoot>
				</Table>
			</Container>
			<Row className='justify-content-center mb-4'>
				<Col md='auto' className='text-center mt-1'>
					<button className='btn btn-secondary' onClick={submitHandler}>
						Complete Payment
					</button>
				</Col>
			</Row>
			<Modal
				size='lg'
				show={lgShow}
				onHide={() => setLgShow(false)}
				aria-labelledby='example-modal-sizes-title-lg'>
				<Modal.Header closeButton>
					<Modal.Title id='example-modal-sizes-title-lg'>
						Add New User
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form className='form-group' onSubmit={addUserOnSubmit}>
						<div className='row mt-3'>
							<label className='col-3 col-md-3' htmlFor='name'>
								<b>Name </b>{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
							<input
								className='form-control col-4'
								id='name'
								type='name'
								required='required'
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div className='row mt-3'>
							<label htmlFor='gender' className='col-4 col-md-3'>
								<b>Gender </b>{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
							<div className='row'>
								<b>
									<Form.Check
										onChange={() => {
											setGender("Male");
										}}
										checked={gender === "Male"}
										className='form-check col-5 col-md-4'
										inline
										label='Male'
										type='radio'
										id={`inline-radio-1`}
									/>
									<Form.Check
										onChange={() => {
											setGender("Female");
										}}
										checked={gender === "Female"}
										inline
										label='Female'
										className='form-check col-5 col-md-4'
										type='radio'
										id={`inline-radio-2`}
									/>
								</b>
							</div>
						</div>
						<div className='row mt-3'>
							<label htmlFor='date' className='col-md-3'>
								<b>Date of Birth </b>{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
							<input
								type='date'
								className='col-md-4 form-control'
								selected={dob}
								value={dob}
								onChange={(e) => setdob(e.target.value)}
								max={today}
							/>
						</div>
						<div className='row mt-3'>
							<label for='bloodgroup' className='col-md-3'>
								<b>Blood Group:</b>
							</label>
							<DropdownButton
								className='col-md-1 btn'
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={bloodGroup}
								onSelect={handleBloodGroup}>
								<div className='dropScroll'>
									{blood_groups.map((blood) => (
										<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
									))}
								</div>
							</DropdownButton>
						</div>
						<div>
							<Button
								className='offset-5 offset-md-6 mt-5 btn'
								type='submit'
								onClick={() => setLgShow(false)}>
								Add
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			<Modal show={showPaymentModal} onHide={handleClosePaymentModal} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>Update Payment Status</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>Amount:</div>
						<input
							type='text'
							className='form-control col-8'
							disabled
							value={amount}
						/>
					</div>

					{MedicationData?.length === 0 && (
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Prescribed By:</div>
							<div className='col-8 row'>
								<Col md='auto' className='text-center mt-1'>
									<DropdownButton
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={specialityOption}
										onSelect={handleSpecialitySelect}>
										{allSpecialities.map(
											(specialities, index) =>
												specialities.specialization !== "referral" &&
												specialities.specialization !== "Consultant Doctor" && (
													<Dropdown.Item
														eventKey={
															specialities.specialization +
															"/" +
															specialities._id +
															"/" +
															index
														}>
														{specialities.specialization}
													</Dropdown.Item>
												)
										)}
									</DropdownButton>
								</Col>
								<Col md='auto' className='text-center mt-1'>
									<DropdownButton
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={doctorOption}
										onSelect={handleDoctorSelect}>
										{DoctorFromSpecialityArray.map((doctors, index) => (
											<Dropdown.Item
												eventKey={doctors.name + "/" + doctors.doctorsinfo}>
												{doctors.name}
											</Dropdown.Item>
										))}
									</DropdownButton>
								</Col>
							</div>
						</div>
					)}

					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>Discount (%):</div>
						<DropdownButton
							className='col-2'
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={discountType}
							onSelect={handleDiscountType}>
							<Dropdown.Item eventKey={"%"}>%</Dropdown.Item>
							<Dropdown.Item eventKey={"Rs"}>Rs</Dropdown.Item>
						</DropdownButton>
						<input
							type='number'
							className='col-6 form-control'
							value={discount}
							onChange={handleDiscountChange}
						/>
					</div>
					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>Discount Reason:</div>
						<input
							type='text'
							className='col-8 form-control'
							value={discountReason}
							onChange={(e) => setDiscountReason(e.target.value)}
						/>
					</div>
					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>Total Amount:</div>
						<input
							type='text'
							className='col-8 form-control'
							value={totalAmountAfterDiscount}
							disabled
						/>
					</div>
					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>Amount Paid Cash:</div>
						<input
							type='number'
							className='col-3 form-control'
							placeholder='Amount Paid Cash'
							value={amountPaidCash}
							onChange={(e) => handleAmounts(e.target.value, "cash")}
						/>
					</div>
					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>
							Amount Paid Online:
						</div>
						<input
							type='number'
							className='col-3 form-control'
							placeholder='Amount Paid Online'
							value={amountPaidOnline}
							onChange={(e) => handleAmounts(e.target.value, "online")}
						/>
					</div>
					{/* <div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>
							Due Amount:
						</div>
						<input
							type='number'
							className='col-3 form-control'
							placeholder='Amount Paid Online'
							value={dueAmount}
							onChange={(e) => setDueAmount(e?.target?.value)}
						/>
					</div> */}
				</Modal.Body>
				<Modal.Footer>
					<Button variant='primary' onClick={handleCompletePayment}>
						Mark as Paid{" "}
						{completePaymentLoading && <ButtonLoader></ButtonLoader>}
					</Button>
					<Button variant='secondary' onClick={handleClosePaymentModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showPrescriptionsListModal}
				onHide={handleClosePrescriptionsListModal}>
				<Modal.Header closeButton>
					<Modal.Title>Prescription Selection</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form>
						{prescriptionsDataList?.length > 0 &&
							prescriptionsDataList[0] !== "No prescriptions available" &&
							prescriptionsDataList[0] !== "Select a user" &&
							prescriptionsDataList.map((item, index) => (
								<Form.Check
									key={index}
									type='checkbox'
									label={`${item?.doctorName} - ${item?.bookedDate} (${item?.category})`}
									id={`prescription-checkbox-${index}`}
									onChange={(e) => handleCheckboxChange(e, item)}
								/>
							))}

						{prescriptionsDataList[0] === "No prescriptions available" && (
							<h6 className='text-center'>No Prescriptions Available</h6>
						)}
					</Form>
				</Modal.Body>

				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={handleClosePrescriptionsListModal}>
						Close
					</Button>
					<Button variant='primary' onClick={handleLoadPrescriptionData}>
						Load Medication
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default SellInventoryScreens;
