/** @format */

import { createSlice } from "@reduxjs/toolkit";

const createReferralDoctor = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		createReferralDoctorStart: (state) => {
			state.loading = true;
		},
		createReferralDoctorSuccess: (state, action) => {
			state.loading = false;
			state.referralDoctorInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		createReferralDoctorFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createReferralDoctorReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createReferralDoctorStart,
	createReferralDoctorSuccess,
	createReferralDoctorFailure,
	createReferralDoctorReset,
} = createReferralDoctor.actions;
export default createReferralDoctor.reducer;
