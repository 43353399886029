/** @format */

import React from 'react';
import { Button } from 'react-bootstrap';

function HeroSection() {
	return (
		<div>
			<div
				className='hero-section d-none d-md-block'
				style={{ background: '#1c1c1c', color: '#fff' }}>
				<img src='/final.jpg' alt='Hospital' className='img-fluid' />
				<div className='hero-content'>
					<h1>Dr. K. Anjal Reddy Memorial Hospital</h1>
					<h3>
						Hand in hand for a better life <sub> ~ Dr. K. Anjal Reddy</sub>
					</h3>
					{/* <Button variant='primary'>Login</Button> */}
				</div>
			</div>
			<div
				className='hero-section d-block d-md-none'
				style={{ background: '#1c1c1c', color: '#fff' }}>
				<img src='/final.jpg' alt='Hospital' className='img-fluid' />
				<div className='hero-content'>
					<h6>Dr. K. Anjal Reddy Memorial Hospital</h6>
					<h6>
						Hand in hand for a better life <sub> ~ Dr. K. Anjal Reddy</sub>
					</h6>
					{/* <Button variant='primary'>Login</Button> */}
				</div>
			</div>
		</div>
	);
}

export default HeroSection;
