/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "../../../../Components/Loader.js";
import Meta from "../../../../Components/Meta.js";
import Button from "react-bootstrap/Button";
import { Col, Row, Container, Card } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PieChart from "../../../../Components/PieChart.js";
import BarChart from "../../../../Components/Barchart.js";
import {
	getTodaysRevenues,
	getPastCategoryRevenues,
	getIndividualCategoryRevenue,
	getIndividualInventoryItemRevenue,
} from "../../../../redux/inventoryRedux/inventoryApiCalls.js";
import { ResponsiveBar } from "@nivo/bar";

const CategotryInventoryStatisticsScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	useEffect(() => {
		dispatch(getPastCategoryRevenues());
	}, []);

	const getPastCategoryrevenues = useSelector(
		(state) => state.getPastCategoryRevenues
	);

	const [extractedCategory30DaysData, setExtractedCategory30DaysData] =
		useState([]);
	const [extractedCategory12MonsData, setExtractedCategory12MonsData] =
		useState([]);
	const [CategoryLast30DaysPie, setCategoryLast30DaysPie] = useState([]);
	const [CategoryLast12MonsPie, setCategoryLast12MonsPie] = useState([]);
	const [CategoryLast30DaysBar, setCategoryLast30DaysBar] = useState([]);
	const [CategoryLast12MonsBar, setCategoryLast12MonsBar] = useState([]);
	const [categoryName, setCategoryName] = useState("Choose Category");
	const [ActivePieChart, setActivePieChart] = useState("30days");
	const [ActiveBarChart, setActiveBarChart] = useState("30days");

	const handleCategorySelect = (eventKey) => {
		let arr = eventKey?.split("@@");
		setCategoryName(arr[0]);

		//pie
		let days30Data = [];
		let mon12Data = [];
		days30Data?.push({
			id: "Purchase",
			label: "Purchase",
			value: extractedCategory30DaysData[Number(arr[1])]?.Purchase,
		});
		days30Data?.push({
			id: "Sales",
			label: "Sales",
			value: extractedCategory30DaysData[Number(arr[1])]?.Sales,
		});
		days30Data?.push({
			id: "Discount",
			label: "Discount",
			value: extractedCategory30DaysData[Number(arr[1])]?.Discount,
		});
		days30Data?.push({
			id: "Profit",
			label: "Profit",
			value: extractedCategory30DaysData[Number(arr[1])]?.Profit,
		});
		setCategoryLast30DaysPie(days30Data);
		mon12Data?.push({
			id: "Purchase",
			label: "Purchase",
			value: extractedCategory12MonsData[Number(arr[1])]?.Purchase,
		});
		mon12Data?.push({
			id: "Sales",
			label: "Sales",
			value: extractedCategory12MonsData[Number(arr[1])]?.Sales,
		});
		mon12Data?.push({
			id: "Discount",
			label: "Discount",
			value: extractedCategory12MonsData[Number(arr[1])]?.Discount,
		});
		mon12Data?.push({
			id: "Profit",
			label: "Profit",
			value: extractedCategory12MonsData[Number(arr[1])]?.Profit,
		});
		setCategoryLast12MonsPie(mon12Data);

		// bar
		let days30DataBar = [];
		let mon12DataBar = [];
	};

	useEffect(() => {
		let last30DaysPieData = [];
		let last12MonthsPieData = [];
		let extractedCat30DaysData = [];
		let extractedCat12MonsData = [];
		getPastCategoryrevenues?.getPastCategoryRevenues?.data?.data.forEach(
			(category) => {
				if (category.name !== "hospital") {
					// Process last 30 days data
					let last30DaysPurchase =
						category.category_purchases.last_thirtydays_purchases.reduce(
							(acc, purchase) => acc + purchase?.category_purchase_amount,
							0
						);
					let last30DaysSales =
						category.category_sales.last_thirtydays_sales.reduce(
							(acc, sale) => acc + sale?.category_sales_amount,
							0
						);
					let last30DaysProfit =
						category.category_sales.last_thirtydays_sales.reduce(
							(acc, sale) => acc + sale?.category_sales_profit,
							0
						);
					let last30DaysDiscount =
						category.category_sales.last_thirtydays_sales.reduce(
							(acc, sale) => acc + sale?.category_sales_discount,
							0
						);

					extractedCat30DaysData.push({
						Name: category?.name,
						Purchase: last30DaysPurchase,
						Sales: last30DaysSales,
						Profit: last30DaysProfit,
						Discounts: last30DaysDiscount,
					});

					// Process last 12 months data
					let last12MonthsPurchase =
						category.category_purchases.last_twelvemonths_purchases.reduce(
							(acc, purchase) => acc + purchase?.category_purchase_amount,
							0
						);
					let last12MonthsSales =
						category.category_sales.last_twelvemonths_sales.reduce(
							(acc, sale) => acc + sale?.category_sales_amount,
							0
						);
					let last12MonthsProfit =
						category.category_sales.last_twelvemonths_sales.reduce(
							(acc, sale) => acc + sale?.category_sales_profit,
							0
						);
					let last12MonthsDiscount =
						category.category_sales.last_twelvemonths_sales.reduce(
							(acc, sale) => acc + sale?.category_sales_discount,
							0
						);

					extractedCat12MonsData.push({
						Name: category?.name,
						Purchase: last12MonthsPurchase,
						Sales: last12MonthsSales,
						Profit: last12MonthsProfit,
						Discounts: last12MonthsDiscount,
					});
				}
			}
		);
		setExtractedCategory30DaysData(extractedCat30DaysData);
		setExtractedCategory12MonsData(extractedCat12MonsData);

		setCategoryName(extractedCat30DaysData[0]?.Name);

		last30DaysPieData?.push({
			id: "Purchase",
			label: "Purchase",
			value: extractedCat30DaysData[0]?.Purchase,
		});
		last30DaysPieData?.push({
			id: "Sales",
			label: "Sales",
			value: extractedCat30DaysData[0]?.Sales,
		});
		last30DaysPieData?.push({
			id: "Discount",
			label: "Discount",
			value: extractedCat30DaysData[0]?.Discount,
		});
		last30DaysPieData?.push({
			id: "Profit",
			label: "Profit",
			value: extractedCat30DaysData[0]?.Profit,
		});
		setCategoryLast30DaysPie(last30DaysPieData);
		last12MonthsPieData?.push({
			id: "Purchase",
			label: "Purchase",
			value: extractedCat12MonsData[0]?.Purchase,
		});
		last12MonthsPieData?.push({
			id: "Sales",
			label: "Sales",
			value: extractedCat12MonsData[0]?.Sales,
		});
		last12MonthsPieData?.push({
			id: "Discount",
			label: "Discount",
			value: extractedCat12MonsData[0]?.Discount,
		});
		last12MonthsPieData?.push({
			id: "Profit",
			label: "Profit",
			value: extractedCat12MonsData[0]?.Profit,
		});
		setCategoryLast12MonsPie(last12MonthsPieData);
	}, [getPastCategoryrevenues?.loading]);

	console.log(getPastCategoryrevenues);

	const commonProps = {
		keys: ["Purchase", "Sales", "Profit", "Discounts", "Gst"],
		margin: { top: 50, right: 50, bottom: 50, left: 60 },
		padding: 0.3,
		groupMode: "stacked",
		margin: { top: 50, right: 130, bottom: 50, left: 100 },
		axisLeft: {
			tickSize: 5,
			tickPadding: 20,
			tickRotation: 0,
			legend: "Amount",
			legendPosition: "middle",
			legendOffset: -90,
		},
		axisBottom: {
			tickSize: 5,
			tickPawwdding: 5,
			tickRotation: 0,
			legend: "Vendors",
			legendPosition: "middle",
			legendOffset: 32,
		},
		colors: { scheme: "nivo" },
		enableLabel: true,
	};

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href="/admin/AllPharmacySales">
					Pharmacy
				</Breadcrumb.Item>
				<Breadcrumb.Item href="/admin/VendorLevelStatistics">
					Vendor Level
				</Breadcrumb.Item>
				<Breadcrumb.Item href="/admin/CategoryLevelStatistics">
					Category Level
				</Breadcrumb.Item>
				<Breadcrumb.Item href="/admin/todaysinventorystatistics">
					Today's Pharmacy
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className="header-center mt-3 mb-3">Category Level Revenue</h1>
			<Container className="header-center mt-3">
				<DropdownButton
					key="down-centered"
					id={`dropdown - button - drop - down - centered`}
					drop="down-centered"
					variant="success"
					title={categoryName}
					onSelect={handleCategorySelect}
				>
					{extractedCategory30DaysData?.map((category, index) => (
						<Dropdown.Item eventKey={category?.Name + "@@" + index}>
							{category?.Name}
						</Dropdown.Item>
					))}
				</DropdownButton>
				<Button
					className="btn btn-info m-1"
					onClick={() => setActivePieChart("30days")}
				>
					Last 30 Days
				</Button>
				<Button
					className="btn btn-info m-1"
					onClick={() => setActivePieChart("12mons")}
				>
					Last 12 Months
				</Button>

				{ActivePieChart === "30days" && (
					<PieChart data={CategoryLast30DaysPie} />
				)}
				{ActivePieChart === "12mons" && (
					<PieChart data={CategoryLast12MonsPie} />
				)}
			</Container>
			<div className="container header-center" style={{ height: "500px" }}>
				<Button
					className="btn btn-info m-1"
					onClick={() => setActiveBarChart("30days")}
				>
					Last 30 Days
				</Button>
				<Button
					className="btn btn-info m-1"
					onClick={() => setActiveBarChart("12mons")}
				>
					Last 12 Months
				</Button>
				{ActiveBarChart === "30days" && (
					<ResponsiveBar
						data={extractedCategory30DaysData}
						indexBy="Name"
						{...commonProps}
						// layout="horizontal"
					/>
				)}
				{ActiveBarChart === "12mons" && (
					<ResponsiveBar
						data={extractedCategory12MonsData}
						indexBy="Name"
						{...commonProps}
						// layout="horizontal"
					/>
				)}
			</div>
		</>
	);
};

export default CategotryInventoryStatisticsScreens;
