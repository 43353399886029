/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import { getReviews, createReview } from "../../redux/adminRedux/adminApiCalls";
import Message from "../../Components/Message";
import { getReviewsGetReset } from "../../redux/adminRedux/Review_Management/getReviews";
import { createReviewReset } from "../../redux/adminRedux/Review_Management/createReview";
import {
  allMessagesStart,
  allMessagesSuccess,
  allMessagesFailure,
  allMessagesReset,
} from "../../redux/allMessages";

const AdminFeedbackScreens = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const data = useSelector((state) => state.adminReviewGet);
  const [timerRunning, setTimerRunning] = useState(false);
  const [timerRunning1, setTimerRunning1] = useState(false);
  // console.log(data);
  const { getReviewsall, loading, error, success } = data;
  var data2;
  if (getReviewsall) {
    if (getReviewsall.data) {
      if (getReviewsall.data.docs) {
        data2 = getReviewsall.data.docs;
      }
    }
  }

  useEffect(() => {
    dispatch(allMessagesStart());
  }, []);
  const alertmsg = useSelector((state) => state.allMessages);

  console.log(data2);
  useEffect(() => {
    dispatch(getReviews());
    // history("/admin/feedbacks");
  }, [dispatch]);
  const handleAdd = (push, id) => {
    // history("/admin/feedbacks");
    dispatch(createReview(push, id));
    //window.location.reload();
  };
  const handleDelete = (push, id) => {
    dispatch(createReview(push, id));
    //window.location.reload();
  };

  const admincreatedReview = useSelector((state) => state.adminReviewCreate);
  const loadinreview = admincreatedReview.loading;
  const errorReview = admincreatedReview.error;
  const successReview = admincreatedReview.success;
  console.log(loadinreview, loading);
  const [loaderClass, setLoaderClass] = useState(false);
  useEffect(() => {
    if (successReview && !timerRunning) {
      setTimerRunning(true);
      // setLoaderClass(true);
      const timer = setTimeout(() => {
        // Clear the alert and stop the timer after 3 seconds
        dispatch(createReviewReset());

        setTimerRunning(false);
        // setLoaderClass(false);
        window.location.reload();
      }, 3000);

      // Clean up the timer if the component unmounts or the alert changes
    }
  }, [successReview, dispatch]);
  useEffect(() => {
    if (successReview && !timerRunning) {
      setTimerRunning1(true);
      setLoaderClass(true);
      const timer = setTimeout(() => {
        // Clear the alert and stop the timer after 3 seconds
        // dispatch(createReviewReset());

        setTimerRunning1(false);
        setLoaderClass(false);
        window.location.reload();
      }, 4000);

      // Clean up the timer if the component unmounts or the alert changes
    }
  }, [successReview, dispatch]);
  return (
    <>
      <Meta />

      {loading && <Loader />}

      <h1 className="header-center">Feedbacks</h1>

      {loadinreview || loaderClass ? (
        <Loader />
      ) : data2?.length === 0 ? (
        <h4 className="text-danger header-center">No feedbacks from users</h4>
      ) : (
        <div className="row">
          {data2?.map((reviews, index) => (
            <div className="card col-12 col-md-5 offset-md-1">
              <div className="row mt-3">
                <i className="fa fa-user fa-2x col-1"></i>
                <div className="col-5">Name: {reviews.user.name} </div>
                <div className="col-5"> Mobile No: {reviews.phoneNo} </div>
              </div>
              <div className="row mt-3">
                <div className="col-11 offset-1">Rating: {reviews.rating}</div>
                <div className="col-11 offset-1">Review: {reviews.review}</div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-3 offset-3">
                  <button
                    className="btn btn-success"
                    onClick={() => handleAdd(true, reviews._id)}
                  >
                    Add
                  </button>
                </div>
                <div className="col-3">
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(false, reviews._id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default AdminFeedbackScreens;
