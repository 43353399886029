/** @format */

import { createSlice } from "@reduxjs/toolkit";

const editLabTechnicianAdmin = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		editLabTechnicianAdminStart: (state) => {
			state.loading = true;
		},
		editLabTechnicianAdminSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		editLabTechnicianAdminFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editLabTechnicianAdminReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editLabTechnicianAdminStart,
	editLabTechnicianAdminSuccess,
	editLabTechnicianAdminFailure,
	editLabTechnicianAdminReset,
} = editLabTechnicianAdmin.actions;
export default editLabTechnicianAdmin.reducer;
