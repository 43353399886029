/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getAllLabTechnicians = createSlice({
	name: 'getAllLabTechnicians',
	initialState: {},
	reducers: {
		getAllLabTechniciansRequest: (state) => {
			state.loading = true;
		},
		getAllLabTechniciansSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.AllLabTechnicians = action.payload;
			state.error = false;
			state.success = true;
		},
		getAllLabTechniciansFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getAllLabTechniciansReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getAllLabTechniciansRequest,
	getAllLabTechniciansSuccess,
	getAllLabTechniciansFailure,
	getAllLabTechniciansReset,
} = getAllLabTechnicians.actions;
export default getAllLabTechnicians.reducer;
