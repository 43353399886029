/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { changeSlots } from "../../../redux/doctorRedux/doctorApiCalls";
import { Switch } from "react-switch-input";
import Form from "react-bootstrap/Form";
import Message from "../../../Components/Message";
import { editDoctorReset } from "../../../redux/doctorRedux/MyProfile/editDoctor";
import { allMessagesStart } from "../../../redux/allMessages";
import { Container, Col, Row } from "react-bootstrap";

const EditMySlots = () => {
	const dispatch = useDispatch();
	const { doctorInfo, loading, error } = useSelector(
		(state) => state.doctorLogin
	);

	const [daysData, setDaysData] = useState(doctorInfo?.data?.user?.days);
	console.log(doctorInfo);
	// State to track the active button
	const [activeButton, setActiveButton] = useState(0);
	const [switche, setSwitch] = useState(false);
	const [switchActivated, setSwitchActivated] = useState(null);

	// Function to handle button click
	const handleButtonClick = (index) => {
		setActiveButton(index);
	};

	const days = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];

	const handleChange = (value, index, key) => {
		if (key === "isWorking") {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				isWorking: !value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === "maxAppointmentsperhour") {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				maxAppointmentsperhour: value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === "startTime") {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				startTime: value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === "endTime") {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				endTime: value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === "startBreakTime") {
			let modifiedDaysData = [...daysData];
			const newBreakTime = modifiedDaysData.map((item, i) => {
				if (index === i) {
					let oldBreakTime = item.breakTime.split("-");
					oldBreakTime[0] = value;
					let updatedBreakTime = oldBreakTime.join("-");

					return { ...item, breakTime: updatedBreakTime };
				}
				return item;
			});
			setDaysData(newBreakTime);
		} else if (key === "endBreakTime") {
			let modifiedDaysData = [...daysData];
			const newBreakTime = modifiedDaysData.map((item, i) => {
				if (index === i) {
					let oldBreakTime = item.breakTime.split("-");
					oldBreakTime[1] = value;
					let updatedBreakTime = oldBreakTime.join("-");

					return { ...item, breakTime: updatedBreakTime };
				}
				return item;
			});
			setDaysData(newBreakTime);
		}
	};

	const [singleSelectedDayswitch, setSingleSelectedDaySwitch] = useState([]);

	const handleSwitch = (e) => {
		console.log(activeButton, e?.target?.checked);
		let singleSelectedDayData = [];
		if (e?.target?.checked) {
			setSwitch(true);
			for (let i = 0; i < daysData?.length; i++) {
				singleSelectedDayData.push(daysData[activeButton]);
			}
			console.log(singleSelectedDayData);
			setSingleSelectedDaySwitch(singleSelectedDayData);
			setSwitchActivated(activeButton);
		} else {
			setSwitch(false);
			singleSelectedDayData = [];
			setSingleSelectedDaySwitch([]);
			setSwitchActivated(null);
		}
	};

	const discardHandler = () => {
		setSwitch(false);
		document.getElementById("switch").checked = false;
		setSingleSelectedDaySwitch([]);
		setSwitchActivated(null);
		setDaysData(doctorInfo?.data?.user?.days);
		setActiveButton(0);
	};

	const submitHandler = () => {
		console.log("111");
		if (!switche) {
			dispatch(
				changeSlots(
					daysData,
					doctorInfo?.data?.user?._id,
					doctorInfo?.data?.user?.appointmentDates.length,
					doctorInfo?.data?.user?.bookingPeriod
				)
			);
		} else {
			dispatch(
				changeSlots(
					singleSelectedDayswitch,
					doctorInfo?.data?.user?._id,
					doctorInfo?.data?.user?.appointmentDates.length,
					doctorInfo?.data?.user?.bookingPeriod
				)
			);
		}
	};
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	return (
		<div className="pageSize">
			<Meta />

			{loading && <Loader />}

			<h1 className="header-center">Edit My Slots</h1>
			<div className="card col-12 col-md-10 offset-md-1 mt-5 mb-3">
				<div className="mt-3 offset-md-2">
					{days.map((day, index) => (
						<button
							key={index}
							onClick={() => handleButtonClick(index)}
							style={{ borderRadius: "0px" }}
							className={
								activeButton === index ? "btn btn-success" : "btn btn-secondary"
							}
						>
							{day}
						</button>
					))}
				</div>
				{!switche &&
					daysData?.map(
						(slotData, index) =>
							index === activeButton && (
								<div className="mt-4">
									<div className="row col-md-8 offset-md-2 form-group">
										<Form.Check
											type="checkbox"
											className="offset-md-1"
											onChange={(e) =>
												handleChange(slotData?.isWorking, index, "isWorking")
											}
											checked={slotData?.isWorking}
										/>
										<label
											className="form-label col-md-8"
											htmlFor="maxAppointmentsperhour"
										>
											<b>is Working? </b>
										</label>
									</div>
									<div className="row col-md-8 offset-md-2 form-group">
										<label
											className="form-label col-md-3"
											htmlFor="maxAppointmentsperhour"
										>
											<b>Max appointments/hour: </b>
											<span className="text-danger">
												<b>*</b>
											</span>
										</label>
										<input
											className="form-control col-md-8"
											id="maxAppointmentsperhour"
											type="number"
											required="required"
											value={
												slotData?.isWorking && slotData?.maxAppointmentsperhour
											}
											onChange={(e) => {
												handleChange(
													e.target.value,
													index,
													"maxAppointmentsperhour"
												);
											}}
											disabled={!slotData?.isWorking}
										/>
									</div>
									<div className="row col-md-8 offset-md-2 form-group">
										<label className="form-label col-md-3" htmlFor="startTime">
											<b>Start time: </b>
											<span className="text-danger">
												<b>*</b>
											</span>
										</label>
										<input
											className="form-control col-md-8"
											type="time"
											id="appt"
											name="appt"
											value={slotData?.isWorking && slotData?.startTime}
											onChange={(e) => {
												handleChange(e.target.value, index, "startTime");
											}}
											disabled={!slotData?.isWorking}
										/>
									</div>
									<div className="row col-md-8 offset-md-2 form-group">
										<label className="form-label col-md-3" htmlFor="endTime">
											<b>End time: </b>
											<span className="text-danger">
												<b>*</b>
											</span>
										</label>
										<input
											className="form-control col-md-8"
											type="time"
											id="appt"
											name="appt"
											value={slotData?.isWorking && slotData?.endTime}
											onChange={(e) => {
												handleChange(e.target.value, index, "endTime");
											}}
											disabled={!slotData?.isWorking}
										/>
									</div>
									<div className="row col-md-8 offset-md-2 form-group">
										<label className="form-label col-md-3" htmlFor="breakTime">
											<b>Break time: </b>
											<span className="text-danger">
												<b>*</b>
											</span>
										</label>
										<input
											className="form-control col-md-3"
											type="time"
											id="appt"
											name="appt"
											value={
												slotData?.isWorking &&
												slotData?.breakTime?.split("-")[0]
											}
											onChange={(e) =>
												handleChange(e.target.value, index, "startBreakTime")
											}
											disabled={!slotData?.isWorking}
										/>
										<input
											className="form-control col-md-3 offset-md-1"
											type="time"
											id="appt"
											name="appt"
											value={
												slotData?.isWorking &&
												slotData?.breakTime?.split("-")[1]
											}
											onChange={(e) =>
												handleChange(e.target.value, index, "endBreakTime")
											}
											disabled={!slotData?.isWorking}
										/>
									</div>
								</div>
							)
					)}
				{switche &&
					singleSelectedDayswitch?.map(
						(slotData, index) =>
							index === activeButton && (
								<div className="mt-4">
									<div className="row col-md-8 offset-md-2 form-group">
										<Form.Check
											type="checkbox"
											className="offset-md-1"
											onChange={(e) =>
												handleChange(slotData?.isWorking, index, "isWorking")
											}
											checked={slotData?.isWorking}
										/>
										<label
											className="form-label col-md-8"
											htmlFor="maxAppointmentsperhour"
										>
											<b>is Working? </b>
										</label>
									</div>
									<div className="row col-md-8 offset-md-2 form-group">
										<label
											className="form-label col-md-3"
											htmlFor="maxAppointmentsperhour"
										>
											<b>Max appointments/hour: </b>
											<span className="text-danger">
												<b>*</b>
											</span>
										</label>
										<input
											className="form-control col-md-8"
											id="maxAppointmentsperhour"
											type="number"
											required="required"
											value={
												slotData?.isWorking && slotData?.maxAppointmentsperhour
											}
											onChange={(e) => {
												handleChange(
													e.target.value,
													index,
													"maxAppointmentsperhour"
												);
											}}
											disabled={
												!slotData?.isWorking || index !== switchActivated
											}
										/>
									</div>
									<div className="row col-md-8 offset-md-2 form-group">
										<label className="form-label col-md-3" htmlFor="startTime">
											<b>Start time: </b>
											<span className="text-danger">
												<b>*</b>
											</span>
										</label>
										<input
											className="form-control col-md-8"
											type="time"
											id="appt"
											name="appt"
											value={slotData?.isWorking && slotData?.startTime}
											onChange={(e) => {
												handleChange(e.target.value, index, "startTime");
											}}
											disabled={
												!slotData?.isWorking || index !== switchActivated
											}
										/>
									</div>
									<div className="row col-md-8 offset-md-2 form-group">
										<label className="form-label col-md-3" htmlFor="endTime">
											<b>End time: </b>
											<span className="text-danger">
												<b>*</b>
											</span>
										</label>
										<input
											className="form-control col-md-8"
											type="time"
											id="appt"
											name="appt"
											value={slotData?.isWorking && slotData?.endTime}
											onChange={(e) => {
												handleChange(e.target.value, index, "endTime");
											}}
											disabled={
												!slotData?.isWorking || index !== switchActivated
											}
										/>
									</div>
									<div className="row col-md-8 offset-md-2 form-group">
										<label className="form-label col-md-3" htmlFor="breakTime">
											<b>Break time: </b>
											<span className="text-danger">
												<b>*</b>
											</span>
										</label>
										<input
											className="form-control col-md-3"
											type="time"
											id="appt"
											name="appt"
											value={
												slotData?.isWorking &&
												slotData?.breakTime?.split("-")[0]
											}
											onChange={(e) =>
												handleChange(e.target.value, index, "startBreakTime")
											}
											disabled={
												!slotData?.isWorking || index !== switchActivated
											}
										/>
										<input
											className="form-control col-md-3 offset-md-1"
											type="time"
											id="appt"
											name="appt"
											value={
												slotData?.isWorking &&
												slotData?.breakTime?.split("-")[1]
											}
											onChange={(e) =>
												handleChange(e.target.value, index, "endBreakTime")
											}
											disabled={
												!slotData?.isWorking || index !== switchActivated
											}
										/>
									</div>
								</div>
							)
					)}

				<div className="row offset-md-2 form-group">
					{switchActivated === null ? (
						<div className="col-md-6 row">
							<Switch className="col-md-1" onChange={handleSwitch} />{" "}
							<label className="form-label col-md-11">
								<b>Apply {days[activeButton]}'s configurations to all days</b>
							</label>
						</div>
					) : (
						<div className="col-md-6 row">
							<Switch
								className="col-md-1"
								id="switch"
								onChange={handleSwitch}
							/>{" "}
							<label className="form-label col-md-10">
								<b>
									Applied {days[switchActivated]}'s configurations to all days
								</b>
							</label>
						</div>
					)}
					<Row className="justify-content-center mt-3">
						<Col md="auto" className="text-center mt-1">
							<button className="btn btn-danger " onClick={discardHandler}>
								Discard Changes
							</button>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<button className="btn btn-success " onClick={submitHandler}>
								Submit
							</button>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default EditMySlots;
