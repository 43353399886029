/** @format */

import { createSlice } from '@reduxjs/toolkit';

const clearDueBill = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		clearDueBillStart: (state) => {
			state.loading = true;
		},
		clearDueBillSuccess: (state, action) => {
			state.loading = false;
			state.clearDueBill = action.payload;
			state.error = false;
			state.success = true;
		},
		clearDueBillFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		clearDueBillReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	clearDueBillStart,
	clearDueBillSuccess,
	clearDueBillFailure,
	clearDueBillReset,
} = clearDueBill.actions;
export default clearDueBill.reducer;
