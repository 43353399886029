import { createSlice } from "@reduxjs/toolkit";

const deleteLabTestTemplateReducer = createSlice({
	name: "deleteLabTestTemplate",
	initialState: {},
	reducers: {
		deleteLabTestTemplateRequest: (state) => {
			state.loading = true;
		},
		deleteLabTestTemplateSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.deleteLabTestTemplate = action.payload;
			state.error = false;
			state.success = true;
		},
		deleteLabTestTemplateFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		deleteLabTestTemplateReset: (state) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	deleteLabTestTemplateRequest,
	deleteLabTestTemplateSuccess,
	deleteLabTestTemplateFailure,
	deleteLabTestTemplateReset,
} = deleteLabTestTemplateReducer.actions;
export default deleteLabTestTemplateReducer.reducer;
