/** @format */

import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Table from "react-bootstrap/Table";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Message from "../../../Components/Message";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import axios from "axios";
import { SERVER_URL } from "../../../App";
import Button from "react-bootstrap/Button";
import {
  createLabDept,
  getLabDepts,
  deleteTestInDept,
} from "../../../redux/adminRedux/adminApiCalls";

const DeleteLabTestScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [deptName, setDeptName] = useState("Choose Department");
  const [deptId, setDeptId] = useState("");
  const [deptTestNames, setdeptTestNames] = useState([]);
  const [deptIdx, setDeptIdx] = useState();

  const { getlabDeptAll, loading } = useSelector((state) => state.getLabDept);
  console.log(getlabDeptAll);
  const handleDeptSelect = (eventKey) => {
    setDeptName(eventKey.split("@@")[0]);
    setDeptId(eventKey.split("@@")[1]);
    setDeptIdx(eventKey.split("@@")[2]);
    setdeptTestNames(
      getlabDeptAll?.data?.data[eventKey.split("@@")[2]]?.testDetails
    );
  };
  console.log(deptTestNames);
  useEffect(() => {
    dispatch(getLabDepts());
  }, []);

  const deleteLabTest = useSelector((state) => state.deleteLabTest);

  useEffect(() => {
    dispatch(getLabDepts());
  }, [deleteLabTest?.loading]);

  useEffect(() => {
    setdeptTestNames(getlabDeptAll?.data?.data[deptIdx]?.testDetails);
  }, [loading]);

  const deleteTest = (testName, testIdx) => {
    dispatch(deleteTestInDept(deptId, testName, testIdx));
  };

  let count = 0;
  return (
    <>
      <Meta />

      <div>
        <h1 className="header-center">Delete Lab Test </h1>
        <Container className="header-center mt-5">
          <Row className="justify-content-center">
            <Col md="auto" className="text-center mt-1">
              <h6>
                Select the filters{" "}
                <span className="text-danger">
                  <b>*</b>
                </span>
              </h6>
            </Col>
            <Col md="auto" className="text-center mt-1">
              <DropdownButton
                key="down-centered"
                id={`dropdown-button-drop-down-centered`}
                drop="down-centered"
                variant="success"
                title={deptName}
                onSelect={handleDeptSelect}
              >
                {getlabDeptAll?.data?.data?.length === 0 ? (
                  <Dropdown.Item>
                    {/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
                  </Dropdown.Item>
                ) : (
                  getlabDeptAll?.data?.data?.map(
                    (dept, index) =>
                      dept.deptName !== "hospital" &&
                      dept.status !== 0 && (
                        <Dropdown.Item
                          eventKey={
                            dept.deptName + "@@" + dept._id + "@@" + index
                          }
                        >
                          {dept.deptName}
                        </Dropdown.Item>
                      )
                  )
                )}
              </DropdownButton>
            </Col>
          </Row>
        </Container>

        {deptName !== "Choose Department" && (
          <Container className="header-center mt-5 col-md-8">
            <Table striped bordered hover className="custom-table">
              <thead>
                <tr>
                  <th>Sno</th>
                  <th>Lab Department Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {deptTestNames?.map((test, index) => {
                  if (test?.status !== 0) {
                    count++;
                  }
                  return (
                    test.status !== 0 && (
                      <tr>
                        <td>{count}</td>
                        <td>{test?.testName}</td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => deleteTest(test?.testName, index)}
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </Table>
          </Container>
        )}
      </div>
    </>
  );
};
export default DeleteLabTestScreen;
