/** @format */

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Loader';
import Table from 'react-bootstrap/Table';
import Meta from '../../../Components/Meta';
import Form from 'react-bootstrap/Form';
import {
	createDoctor,
	getLeaves,
	deleteDoctor,
	createReferralDoctor,
} from '../../../redux/adminRedux/adminApiCalls';
import { getSpecialities } from '../../../redux/userApiCalls';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Message from '../../../Components/Message';
import { createDoctorReset } from '../../../redux/adminRedux/Doctor_Management/createDoctor';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from 'axios';
import { SERVER_URL } from '../../../App';
import Button from 'react-bootstrap/Button';
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../../../redux/allMessages';
import { deleteDoctorReset } from '../../../redux/adminRedux/Doctor_Management/deleteDoctor';
import { getLeavesReset } from '../../../redux/adminRedux/Doctor_Management/getLeaves';
import { specialityGetReset } from '../../../redux/getSpecialities';
import { getDoctorfromSpecialityReset } from '../../../redux/getDoctorfromSpeciality';
import Spinner from 'react-bootstrap/Spinner';
import { Switch } from 'react-switch-input';
import Collapse from 'react-bootstrap/Collapse';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

const AddReferralDoctorScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	const [docName, setDocName] = useState('');
	const [docAddress, setDocAddress] = useState('');
	const [docGender, setDocGender] = useState('');
	const [docMno, setDocMno] = useState('');
	const [docEmail, setDocEmail] = useState('');

	const submitDoctorHandler = (e) => {
		e.preventDefault();
		if (docMno?.length > 10) {
			alert('Mobile number cannot be more than 10 numbers');
		} else {
			dispatch(
				createReferralDoctor(
					docName,
					Number(docMno),
					docEmail,
					docGender,
					docAddress
				)
			);
			setDocName('');
			setDocAddress('');
			setDocGender('');
			setDocMno('');
			setDocEmail('');
		}
	};

	return (
		<>
			<Meta />
			<h1 className='header-center mt-3'>Add Referral Doctor</h1>
			<div className='card col-md-8 offset-md-2 mt-3'>
				<form className='form-group' onSubmit={submitDoctorHandler}>
					<div className='row mt-3'>
						<label className='col-3 col-md-3' htmlFor='name'>
							<b>Name </b>{' '}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</label>
						<input
							className='form-control col-8 col-md-8'
							id='name'
							type='name'
							required='required'
							value={docName}
							onChange={(e) => setDocName(e.target.value)}
						/>
					</div>
					<div className='row mt-3'>
						<label className='col-3 col-md-3' htmlFor='mno'>
							<b>Mobile Number </b>{' '}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</label>
						<input
							className='form-control col-8 col-md-8'
							id='mno'
							type='mno'
							required='required'
							value={docMno}
							onChange={(e) => setDocMno(e.target.value)}
						/>
					</div>
					<div className='row mt-3'>
						<label htmlFor='gender' className='col-4 col-md-3'>
							<b>Gender </b>{' '}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</label>
						<div className='row'>
							<b>
								<Form.Check
									onChange={() => {
										setDocGender('Male');
									}}
									checked={docGender === 'Male'}
									className='form-check col-5 col-md-4'
									inline
									label='Male'
									type='radio'
									id={`inline-radio-1`}
								/>
								<Form.Check
									onChange={() => {
										setDocGender('Female');
									}}
									checked={docGender === 'Female'}
									inline
									label='Female'
									className='form-check col-5 col-md-4'
									type='radio'
									id={`inline-radio-2`}
								/>
							</b>
						</div>
					</div>
					<div className='row mt-3'>
						<label className='col-3 col-md-3' htmlFor='email'>
							<b>Email </b>{' '}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</label>
						<input
							className='form-control col-8 col-md-8'
							id='email'
							type='email'
							required='required'
							value={docEmail}
							onChange={(e) => setDocEmail(e.target.value)}
						/>
					</div>
					<div className='row mt-3'>
						<label className='col-3 col-md-3' htmlFor='address'>
							<b>Address </b>{' '}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</label>
						<input
							className='form-control col-8 col-md-8'
							id='address'
							type='address'
							required='required'
							value={docAddress}
							onChange={(e) => setDocAddress(e.target.value)}
						/>
					</div>
					<div>
						<Button className='offset-5 offset-md-6 mt-5 btn' type='submit'>
							Add
						</Button>
					</div>
				</form>
			</div>
		</>
	);
};

export default AddReferralDoctorScreens;
