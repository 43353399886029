/** @format */

import React, { useEffect, useState, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { Container, Col, Row } from "react-bootstrap";
import { getPatientsInQueueForLabTechnician } from "../../redux/labRedux/labTechApiCalls";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Meta from "../../Components/Meta";
import {
  editTestStatusForPatientsInQueue,
  editTestNotesForPatientsInQueue,
} from "../../redux/labRedux/labTechApiCalls";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Loader from "../../Components/Loader";

const DynamicRow = memo(({ index, values, handleChange, handleDelete }) => {
  const dispatch = useDispatch();
  console.log(values);
  console.log("1");
  return (
    <div className="row mb-3">
      <div className="col-md-9 offset-md-1">
        <textarea
          className="form-control col-md-10"
          rows={2}
          value={values[index]}
          onChange={(e) => handleChange(`labNotes-${index}`, e.target.value)}
        ></textarea>
      </div>
      <div className="col-md-1">
        <button
          className="m-2 btn btn-danger"
          onClick={() => handleDelete(index)}
        >
          <i className="fa fa-trash fa-2x"></i>
        </button>
      </div>
    </div>
  );
});

const PatientsInqueueScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();

  const { labTechInfo } = useSelector((state) => state.labTechLogin);
  console.log(labTechInfo);

  useEffect(() => {
    // window?.location?.reload();
    dispatch(
      getPatientsInQueueForLabTechnician(labTechInfo?.data?.user?.deptIdArray)
    );
  }, []);

  const createreportload = useSelector((state) => state?.createLabreport);

  useEffect(() => {
    dispatch(
      getPatientsInQueueForLabTechnician(labTechInfo?.data?.user?.deptIdArray)
    );
  }, [createreportload?.loading]);

  const { getPatientsInQueueForLabTechnicians, loading } = useSelector(
    (state) => state.getPatientsInQueue
  );

  console.log(getPatientsInQueueForLabTechnicians);

  const [dates, setDates] = useState([]);
  const [datesObj, setDatesObj] = useState([]);

  const statusValues = ["In Queue", "Samples Collected", "In Progress"];

  const [statuses, setStatuses] = useState([]);

  let dateObjarr = [];
  let datearr = [];
  let statusarr = [];

  useEffect(() => {
    getPatientsInQueueForLabTechnicians?.data?.data?.forEach((dept, index) => {
      dept?.patientsInQueue?.forEach((patients, i) => {
        let date = patients?.date?.toString()?.split("");
        let formattedDate =
          date[6] +
          date[7] +
          "-" +
          date[4] +
          date[5] +
          "-" +
          date[0] +
          date[1] +
          date[2] +
          date[3];
        if (!datearr?.includes(formattedDate)) {
          datearr.push(formattedDate);
        }
        dateObjarr.push({
          date: formattedDate,
          deptInd: index,
          patientInd: i,
        });
        console.log(dateObjarr);
        statusarr.push(patients?.status);
      });
      const sortedDates = datearr.sort((a, b) => {
        // Split the dates and reverse the order to convert them to 'YYYY-MM-DD'
        const dateA = a.split("-").reverse().join("-");
        const dateB = b.split("-").reverse().join("-");

        // Compare the dates
        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      });
      setDates(sortedDates);
      setDatesObj(dateObjarr);
      setStatuses(statusarr);
    });
  }, [getPatientsInQueueForLabTechnicians?.data?.data]);

  console.log(dates);

  const [show, setShow] = useState(false);
  const [rows, setRows] = useState([]);
  const [values, setValues] = useState([]);

  const [prevNotes, setPrevNotes] = useState([]);

  const handleChangePrevNotes = (index, value) => {
    let arr = [...prevNotes];
    arr[index] = value;
    setPrevNotes(arr);
  };

  console.log(prevNotes);

  const handleDeletePrevNotes = (index) => {
    let arr = [...prevNotes];
    if (index > -1) {
      arr.splice(index, 1);
    }
    setPrevNotes(arr);
  };

  const handleClose = () => setShow(false);

  const [deptIndex, setDeptIndex] = useState(0);
  const [patientIndex, setPatientIndex] = useState(0);

  const handleShow = useCallback(
    (i, j) => {
      // Function to handle showing the modal and setting up rows
      setPrevNotes([]);
      setRows([]);
      setValues([]);
      setDeptIndex(i);
      setPatientIndex(j);
      console.log(
        getPatientsInQueueForLabTechnicians?.data?.data[i]?.patientsInQueue[j]
          ?.labTechnicianNotes?.length
      );
      setShow(true);
      setPrevNotes(
        getPatientsInQueueForLabTechnicians?.data?.data[i]?.patientsInQueue[j]
          ?.labTechnicianNotes
      );
    },
    [getPatientsInQueueForLabTechnicians?.data?.data]
  );

  const handleStatusSelect = (eventKey) => {
    let resarr = eventKey?.split("@@");
    let index = resarr[8];
    let status = resarr[0];
    let userIdx = resarr[2];
    let userId = resarr[3];
    let testName = resarr[4];
    let deptId = resarr[5];
    let doctorId = resarr[6];
    let prescriptionId = resarr[7];
    let prevStatus = resarr[9];
    let testIdx = resarr[10];

    if (prevStatus === status) {
      alert("Selected Test is already " + status);
    } else {
      let statusValuestest = [...statuses];
      statusValuestest[index] = status;
      setStatuses(statusValuestest);
      dispatch(
        editTestStatusForPatientsInQueue(
          Number(userIdx),
          userId,
          testName,
          deptId,
          doctorId,
          prescriptionId,
          status,
          Number(testIdx)
        )
      );
    }
  };

  const handleNotesSelect = () => {
    console.log(deptIndex, patientIndex);

    let finalNotesArr = [];
    let newNotesArr = Object.values(values);
    console.log(newNotesArr);
    for (let i = 0; i < prevNotes?.length; i++) {
      finalNotesArr?.push(prevNotes[i]);
    }
    for (let i = 0; i < newNotesArr?.length; i++) {
      finalNotesArr?.push(newNotesArr[i]);
    }
    console.log(finalNotesArr);

    dispatch(
      editTestNotesForPatientsInQueue(
        getPatientsInQueueForLabTechnicians?.data?.data[deptIndex]?._id,
        getPatientsInQueueForLabTechnicians?.data?.data[deptIndex]
          ?.patientsInQueue[patientIndex]?.prescriptionId,
        finalNotesArr,
        getPatientsInQueueForLabTechnicians?.data?.data[deptIndex]
          ?.patientsInQueue[patientIndex]?.testName,
        getPatientsInQueueForLabTechnicians?.data?.data[deptIndex]
          ?.patientsInQueue[patientIndex]?.userId,
        getPatientsInQueueForLabTechnicians?.data?.data[deptIndex]
          ?.patientsInQueue[patientIndex]?.userIdx,
        getPatientsInQueueForLabTechnicians?.data?.data[deptIndex]
          ?.patientsInQueue[patientIndex]?.testIdx
      )
    );
    handleClose();
  };

  const editTestNotesForPatientsinQueue = useSelector(
    (state) => state.editTestNotesForPatientsInQueue
  );
  const editTestNotesForPatientsinQueueLoading =
    editTestNotesForPatientsinQueue?.loading;

  useEffect(() => {
    dispatch(
      getPatientsInQueueForLabTechnician(labTechInfo?.data?.user?.deptIdArray)
    );
  }, [editTestNotesForPatientsinQueueLoading]);

  const createReportHandler = (
    specialityId,
    specialityName,
    doctorId,
    doctorName,
    departmentName,
    deptId,
    labTechnicianName,
    labTechnicianId,
    testName,
    paidAmount,
    testDesc,
    userId,
    username,
    userIdx,
    //variables used to add report to prescription
    prescriptionId,
    labTechnicianNotes,
    date,
    testIdx,
    deptInd,
    patientInd
  ) => {
    const data = {
      specialityId: specialityId,
      specialityName: specialityName,
      doctorId: doctorId,
      doctorName: doctorName,
      departmentName: departmentName,
      deptId: deptId,
      labTechnicianName: labTechnicianName,
      labTechnicianId: labTechnicianId,
      testName: testName,
      paidAmount: paidAmount,
      testDesc: testDesc,
      userId: userId,
      username: username,
      userIdx: userIdx,
      //variables used to add report to prescription
      prescriptionId: prescriptionId,
      labTechnicianNotes: labTechnicianNotes,
      date: date,
      testIdx: testIdx,
      deptInd: deptInd,
      patientInd: patientInd,
    };
    console.log(data);

    history("/labTech/createReport", {
      state: {
        specialityId: specialityId,
        specialityName: specialityName,
        doctorId: doctorId,
        doctorName: doctorName,
        departmentName: departmentName,
        deptId: deptId,
        labTechnicianName: labTechnicianName,
        labTechnicianId: labTechnicianId,
        testName: testName,
        paidAmount: paidAmount,
        testDesc: testDesc,
        userId: userId,
        username: username,
        userIdx: userIdx,
        //variables used to add report to prescription
        prescriptionId: prescriptionId,
        labTechnicianNotes: labTechnicianNotes,
        date: date,
        testIdx: testIdx,
        deptInd: deptInd,
        patientInd: patientInd,
      },
    });
  };

  const [open, setOpen] = useState([]);
  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((key) => key !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  console.log(rows);
  console.log(values);
  const handleChange = (name, value) => {
    // const { name, value } = event.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleAddRow = () => {
    setRows((prevRows) => [...prevRows, Date.now()]);
  };

  const handleDeleteRow = (index) => {
    console.log("OKKKK");
    const updatedValues = { ...values };
    Object.keys(updatedValues)
      .filter((key) => key.includes(index))
      .forEach((key) => delete updatedValues[key]);

    setValues(updatedValues);

    setRows((prevRows) => prevRows.filter((rowIndex) => rowIndex !== index));
  };

  function LinkWithTooltip({ id, children, href, tooltip }) {
    return (
      <OverlayTrigger
        overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
        placement="top"
        delayShow={300}
        delayHide={150}
      >
        <a href={href}>{children}</a>
      </OverlayTrigger>
    );
  }

  const [isFaded, setIsFaded] = useState(false);
  const [timer, setTimer] = useState(Date.now());

  const handleFade = () => {
    setIsFaded(true);
    // Simulate an async operation, e.g., data fetching

    dispatch(
      getPatientsInQueueForLabTechnician(labTechInfo?.data?.user?.deptIdArray)
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setIsFaded(false);
    }, 1000);
  }, [loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(Date.now());
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // This effect will run every time `timer` is updated

    console.log("5 minutes have passed:", new Date(timer).toLocaleTimeString());
    handleFade();

    // Any other logic that needs to run every 5 minutes can go here
  }, [timer]);

  return (
    <>
      <Meta />
      <Container className="header-center align-items-center">
        <Row className="justify-content-center">
          <Col
            md="auto"
            className={`${!isFaded ? "d-none" : "text-center mt-1"}`}
          >
            <Loader />
            <h5 className="text-center text-muted">
              The latest patient details are currently being loaded
            </h5>
          </Col>
        </Row>
      </Container>
      <div className={`${isFaded ? "d-none" : ""}`}>
        <Breadcrumb>
          <Breadcrumb.Item href="/labTech/patientsinqueue">
            Patients in queue
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/labTech/labvisited">
            Visited patients
          </Breadcrumb.Item>
        </Breadcrumb>
        <h3 className="header-center mt-3">Patients In Queue</h3>
        <Container className="mt-5">
          {dates?.map((date, index) => (
            <Accordion open={open} toggle={toggle} className="mb-2">
              <AccordionItem>
                <AccordionHeader targetId={index}>{date}</AccordionHeader>
                <AccordionBody accordionId={index}>
                  <div>
                    <Table striped bordered hover className="custom-table">
                      <thead>
                        <tr>
                          <th scope="col">Test Name</th>
                          <th scope="col">Patient</th>
                          <th scope="col">Mobile Number</th>
                          <th scope="col">Doctor</th>
                          <th scope="col">Description</th>
                          <th scope="col">Notes</th>
                          <th scope="col">Status</th>

                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {datesObj?.map(
                          (patient, i) =>
                            patient?.date === date && (
                              <>
                                <tr>
                                  <td>
                                    {
                                      getPatientsInQueueForLabTechnicians?.data
                                        ?.data[patient?.deptInd]
                                        ?.patientsInQueue[patient?.patientInd]
                                        ?.testName
                                    }
                                  </td>
                                  <td>
                                    {
                                      getPatientsInQueueForLabTechnicians?.data
                                        ?.data[patient?.deptInd]
                                        ?.patientsInQueue[patient?.patientInd]
                                        ?.userName
                                    }
                                  </td>
                                  <td>
                                    {
                                      getPatientsInQueueForLabTechnicians?.data
                                        ?.data[patient?.deptInd]
                                        ?.patientsInQueue[patient?.patientInd]
                                        ?.mobileNumber
                                    }
                                  </td>
                                  <td>
                                    {
                                      getPatientsInQueueForLabTechnicians?.data
                                        ?.data[patient?.deptInd]
                                        ?.patientsInQueue[patient?.patientInd]
                                        ?.doctorName
                                    }
                                  </td>
                                  <td>
                                    <LinkWithTooltip
                                      tooltip={
                                        getPatientsInQueueForLabTechnicians
                                          ?.data?.data[patient?.deptInd]
                                          ?.patientsInQueue[patient?.patientInd]
                                          ?.description
                                      }
                                      href="#"
                                      id="tooltip-1"
                                    >
                                      {getPatientsInQueueForLabTechnicians?.data
                                        ?.data[patient?.deptInd]
                                        ?.patientsInQueue[patient?.patientInd]
                                        ?.description?.length > 20
                                        ? getPatientsInQueueForLabTechnicians?.data?.data[
                                            patient?.deptInd
                                          ]?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.description?.slice(0, 20) + "..."
                                        : getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.description}
                                    </LinkWithTooltip>{" "}
                                  </td>
                                  <td style={{ display: "flex" }}>
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        handleShow(
                                          patient?.deptInd,
                                          patient?.patientInd
                                        )
                                      }
                                    >
                                      Lab Notes
                                    </Button>
                                  </td>
                                  <td>
                                    <DropdownButton
                                      key="down-centered"
                                      id={`dropdown-button-drop-down-centered`}
                                      drop="down-centered"
                                      variant="success"
                                      title={statuses[i]}
                                      onSelect={handleStatusSelect}
                                    >
                                      {statusValues?.map((item, j) => (
                                        <Dropdown.Item
                                          eventKey={
                                            item +
                                            "@@" +
                                            patient?.patientInd +
                                            "@@" +
                                            getPatientsInQueueForLabTechnicians
                                              ?.data?.data[patient?.deptInd]
                                              ?.patientsInQueue[
                                              patient?.patientInd
                                            ]?.userIdx +
                                            "@@" +
                                            getPatientsInQueueForLabTechnicians
                                              ?.data?.data[patient?.deptInd]
                                              ?.patientsInQueue[
                                              patient?.patientInd
                                            ]?.userId +
                                            "@@" +
                                            getPatientsInQueueForLabTechnicians
                                              ?.data?.data[patient?.deptInd]
                                              ?.patientsInQueue[
                                              patient?.patientInd
                                            ]?.testName +
                                            "@@" +
                                            getPatientsInQueueForLabTechnicians
                                              ?.data?.data[patient?.deptInd]
                                              ?._id +
                                            "@@" +
                                            getPatientsInQueueForLabTechnicians
                                              ?.data?.data[patient?.deptInd]
                                              ?.patientsInQueue[
                                              patient?.patientInd
                                            ]?.doctorId +
                                            "@@" +
                                            getPatientsInQueueForLabTechnicians
                                              ?.data?.data[patient?.deptInd]
                                              ?.patientsInQueue[
                                              patient?.patientInd
                                            ]?.prescriptionId +
                                            "@@" +
                                            i +
                                            "@@" +
                                            statuses[i] +
                                            "@@" +
                                            getPatientsInQueueForLabTechnicians
                                              ?.data?.data[patient?.deptInd]
                                              ?.patientsInQueue[
                                              patient?.patientInd
                                            ]?.testIdx
                                          }
                                        >
                                          {item}
                                        </Dropdown.Item>
                                      ))}
                                    </DropdownButton>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-success"
                                      onClick={() =>
                                        createReportHandler(
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.specialityId,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.specialityName,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.doctorId,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.doctorName,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.deptName,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]?._id,
                                          labTechInfo?.data?.user?.name,
                                          labTechInfo?.data?.user?._id,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.testName,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.billDetails?.paidAmount,
                                          // getPatientsInQueueForLabTechnicians?.data
                                          // 	?.data[patient?.deptInd]
                                          // 	?.patientsInQueue[patient?.patientInd]
                                          // 	?.Desc
                                          "sfsegsdgsdt",
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.userId,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.userName,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.userIdx,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.prescriptionId,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.labTechnicianNotes,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.date,
                                          getPatientsInQueueForLabTechnicians
                                            ?.data?.data[patient?.deptInd]
                                            ?.patientsInQueue[
                                            patient?.patientInd
                                          ]?.testIdx,
                                          patient?.deptInd,
                                          patient?.patientInd
                                        )
                                      }
                                    >
                                      Create Report
                                    </button>
                                  </td>
                                </tr>
                                <Modal
                                  show={show}
                                  onHide={handleClose}
                                  size="lg"
                                  aria-labelledby="contained-modal-title-vcenter"
                                  centered
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>Add Lab Notes</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body className="notes-model">
                                    {prevNotes?.map((note, index) => (
                                      <div className="row mb-3">
                                        <div className="col-md-9 offset-md-1">
                                          <textarea
                                            className="form-control col-md-10"
                                            rows={2}
                                            value={prevNotes[index]}
                                            onChange={(e) =>
                                              handleChangePrevNotes(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          ></textarea>
                                        </div>
                                        <div className="col-md-1">
                                          <button
                                            className="m-2 btn btn-danger"
                                            onClick={() =>
                                              handleDeletePrevNotes(index)
                                            }
                                          >
                                            <i className="fa fa-trash fa-2x"></i>
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                    {rows.map((rowIndex) => (
                                      <DynamicRow
                                        key={rowIndex}
                                        index={rowIndex}
                                        values={values}
                                        handleChange={handleChange}
                                        handleDelete={handleDeleteRow}
                                      />
                                    ))}
                                    <div className="row mt-4 mb-2 section">
                                      <button
                                        className="btn btn-secondary offset-md-5"
                                        onClick={handleAddRow}
                                      >
                                        + Add more notes
                                      </button>
                                    </div>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleClose}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={handleNotesSelect}
                                    >
                                      Save Changes
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </>
                            )
                        )}
                      </tbody>
                    </Table>
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          ))}
        </Container>
      </div>
    </>
  );
};

export default PatientsInqueueScreen;
