/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getReferralDoctorRevenue = createSlice({
	name: "getReferralDoctorRevenue",
	initialState: {},
	reducers: {
		getReferralDoctorRevenueRequest: (state) => {
			state.loading = true;
		},
		getReferralDoctorRevenueSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getReferralDoctorRevenues = action.payload;
			state.error = false;
			state.success = true;
		},
		getReferralDoctorRevenueFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getReferralDoctorRevenueReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getReferralDoctorRevenueRequest,
	getReferralDoctorRevenueSuccess,
	getReferralDoctorRevenueFailure,
	getReferralDoctorRevenueReset,
} = getReferralDoctorRevenue.actions;
export default getReferralDoctorRevenue.reducer;
