/** @format */
// import 'react-toastify/dist/ReactToastify.css';

import React, { createContext, useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import HomeScreens from "./Screens/HomeScreen";
import FeedbackScreens from "./Screens/FeedbackScreen";
//import TourScreen from "./screens/TourScreen";
import LoginScreen from "./Screens/LoginScreen";
import RegisterScreen from "./Screens/RegisterScreen";
//import AccountScreen from "./screens/AccountScreen";
import BookingListScreen from "./Screens/BookingListScreen";
import ViewPrescriptionComponent from "./Components/ViewPrescriptionComponent.js";
import ViewPrescriptionPatientScreen from "./Screens/ViewPrescriptionPatient";
import MyProfileScreenAdmin from "./Screens/adminScreens/MyProfile/MyProfile";
import AddLabDeptScreen from "./Screens/adminScreens/LabDeptManagement/AddLabDept";
import DeleteLabDeptScreen from "./Screens/adminScreens/LabDeptManagement/DeleteLabDept";
import AddLabTestScreen from "./Screens/adminScreens/LabTestManagement/AddLabTest";
import EditLabTestScreen from "./Screens/adminScreens/LabTestManagement/EditLabTest";
import DeleteLabTestScreen from "./Screens/adminScreens/LabTestManagement/DeleteLabTest";
import AddLabTechScreen from "./Screens/adminScreens/LabTechManagement/AddLabTech";
import DeleteLabTechScreen from "./Screens/adminScreens/LabTechManagement/DeleteLabTech";
import EditLabTechScreen from "./Screens/adminScreens/LabTechManagement/EditLabTech";
import AddSpecialityScreens from "./Screens/adminScreens/SpecialityManagement/AddSpeciality";
import DeleteSpecialityScreens from "./Screens/adminScreens/SpecialityManagement/DeleteSpeciality";
import RegisterPatientScreen from "./Components/PatientManagement/RegisterPatient";
import AdminLoginScreens from "./Screens/adminScreens/AdminLoginScreen";
import AddDoctorScreens from "./Screens/adminScreens/DoctorManagement/AddDoctor";
import DeleteDoctorScreens from "./Screens/adminScreens/DoctorManagement/DeleteDoctor.js";
import DoctorLeavesScreens from "./Screens/adminScreens/DoctorManagement/DoctorLeaves.js";
import AddReferralDoctorScreens from "./Screens/adminScreens/DoctorManagement/AddReferralDoctor.js";
import AdminFeedbackScreens from "./Screens/adminScreens/AdminFeedbackScreen";
import UpdatePasswordAdminScreen from "./Screens/adminScreens/MyProfile/UpdatePasswordAdminScreen.js";
import AddReceptionScreen from "./Screens/adminScreens/ReceptionManagement/AddReception";
import DeleteReceptionScreen from "./Screens/adminScreens/ReceptionManagement/DeleteReception";
import ViewAppointmentScreen from "./Screens/ViewAppointments";
// import '../node_modules/font-awesome/css/font-awesome.min.css';
import AppointmentListScreen from "./Screens/adminScreens/AdminAppointmentsListScreen";
import UpdatePasswordScreen from "./Screens/UpdatepasswordScreen";
import AddNewUser from "./Screens/AddNewUser.js";
import SearchPatientsScreens from "./Screens/adminScreens/SearchPatients";
import ViewPrescriptionAdminScreen from "./Screens/adminScreens/viewPrescriptionAdmin";
import PrescriptionCreatorScreens from "./Screens/doctorScreens/PrescriptionManagement/PrescriptionCreator.js";
import HistoryCreatorScreens from "./Screens/doctorScreens/HistoryManagement/HistoryCreator.js";
import PrescriptionEditorScreens from "./Screens/doctorScreens/PrescriptionManagement/PrescriptionEditor.js";
import HistoryEditorScreens from "./Screens/doctorScreens/HistoryManagement/HistorEditor.js";
import OnSpotAppointmentsScreens from "./Screens/adminScreens/onSpotAppointment";
import EditPrescriptionScreen from "./Screens/doctorScreens/editPrescription";
import GetUserPrescriptionsScreen from "./Screens/doctorScreens/GetUserPrescriptions";
import DoctorCreateLeavesScreen from "./Screens/doctorScreens/LeavesManagement/DoctorCreateLeaves";
import DoctorDeleteLeavesScreen from "./Screens/doctorScreens/LeavesManagement/DoctorDeleteLeaves";
import DoctorBlockSlotsScreen from "./Screens/doctorScreens/SlotManagement/DoctorBlockSlots";
import DoctorUnblockSlotsScreen from "./Screens/doctorScreens/SlotManagement/DoctorUnblockSlots";
// import DoctorLeavesScreen from "./Screens/doctorScreens/DoctorLeavesSlots";
import UpdatePasswordDoctorScreen from "./Screens/doctorScreens/MyProfile/UpdatePasswordDoctorScreen.js";
import ViewPrescriptionDoctorScreen from "./Screens/doctorScreens/viewPrescriptionDoctor";
// import DoctorLoginScreen from "./Screens/doctorScreens/DoctorLogin";
import EditBookingPeriodScreen from "./Screens/doctorScreens/SlotManagement/editBookingPeriod";
import EditMySlots from "./Screens/doctorScreens/SlotManagement/EditMySlots";
import AddTemplateScreen from "./Screens/doctorScreens/MedicinesTemplatesManagement/AddMedicineTemplate.js";
import EditTemplateScreen from "./Screens/doctorScreens/MedicinesTemplatesManagement/EditMedicineTemplate.js";
import DeleteTemplateScreen from "./Screens/doctorScreens/MedicinesTemplatesManagement/DeleteMedicineTemplate.js";
import SearchPatientScreens from "./Screens/doctorScreens/SearchPatients";
import MyUsers from "./Screens/MyUsers";
import UserInfo from "./Screens/UsersInfoScreen";
import { useSelector } from "react-redux";

import OnSpotBookingScreens from "./Screens/receptionScreens/OnSpotBooking";
import OnSpotLabBookingScreens from "./Screens/receptionScreens/BookLabTest.js";
import SearchPatientScreensREC from "./Screens/receptionScreens/SearchPatients";
import UpdatePasswordRECScreen from "./Screens/receptionScreens/updatePasswordReception";
import ViewPrescriptionReceptionScreen from "./Screens/receptionScreens/viewPrescriptionReception";
import MyProfileScreenREC from "./Screens/receptionScreens/MyProfile.js";
import BlockedSlots from "./Screens/receptionScreens/BlockedSlots";
import AppointmentListScreenREC from "./Screens/receptionScreens/AppointmentsList";
import ScheduledAppointments from "./Screens/receptionScreens/ScheduledAppointments";
// import MedicineManagement from "./Screens/doctorScreens/MedicineManagement";
import StartPrescriptionScreens from "./Screens/doctorScreens/PrescriptionManagement/StartPrescription.js";
import StartHistoryScreens from "./Screens/doctorScreens/HistoryManagement/StartHistory.js";
import NextAppointmentScreen from "./Screens/doctorScreens/nextAppointment";
import MyProfileScreen from "./Screens/doctorScreens/MyProfile/MyProfile.js";
import WatchListScreen from "./Screens/doctorScreens/watchList.js";

//lab functionalities

import CreateTestTemplateScreen from "./Screens/labTechScreens/Test_Template_Management/CreateTestTemplate.js";
import EditTestTemplateScreen from "./Screens/labTechScreens/Test_Template_Management/EditTestTemplate.js";
import DeleteTestTemplateScreen from "./Screens/labTechScreens/Test_Template_Management/DeleteTestTemplate.js";
import LabVisitedScreen from "./Screens/labTechScreens/LabVisitedPatients.js";
import MyProfileScreenLab from "./Screens/labTechScreens/MyProfile/MyProfile.js";
import UpdatePasswordScreenLab from "./Screens/labTechScreens/MyProfile/UpdatePassword.js";
import PatientsInqueueScreen from "./Screens/labTechScreens/PatientsInQueue.js";
import CreateReportScreen from "./Screens/labTechScreens/CreateReport.js";

//components functionalities

import AlertMessageBar from "./Components/AlertMessage";
import ForgotPassowrd from "./Components/ForgotPassword";
import VerifyEmail from "./Components/VerifyEmail";
import PatientsToBeVisitedScreen from "./Components/PatientsToBeVisited";
import AddNewPatientScreen from "./Components/PatientManagement/AddNewPatient";
import PatientsVisitedScreen from "./Components/VisitedPatients";
import ViewReportScreen from "./Components/ViewReport.js";
import SideBar from "./Components/SideBar.js";
import AboutUs from "./Screens/AboutUs";
import BillingScreen from "./Components/billingScreen.js";

import ReferralDoctorRevenueScreens from "./Screens/adminScreens/Statistics/OpStatistics/ReferralDoctorRevenue.js";
import DepartmentStatisticsScreens from "./Screens/adminScreens/Statistics/LabStatistics/DepartmentStatistics.js";
import LabStatisticsScreens from "./Screens/adminScreens/Statistics/LabStatistics/LabStatistics.js";
import TestStatisticsScreens from "./Screens/adminScreens/Statistics/LabStatistics/TestStatistics.js";
import TodaysLabPatientsScreens from "./Screens/adminScreens/Statistics/LabStatistics/TodaysLabPatientsStatistics.js";
import HospitalStatisticsScreens from "./Screens/adminScreens/Statistics/OpStatistics/HospitalStatistics.js";
import SpecialityStatisticsScreens from "./Screens/adminScreens/Statistics/OpStatistics/SpecialityStatistics.js";
import DoctorStatisticsScreens from "./Screens/adminScreens/Statistics/OpStatistics/DoctorStatistics.js";
import TodaysOPStatisticsScreens from "./Screens/adminScreens/Statistics/OpStatistics/TodaysOPPatientsStatistics.js";

// import ViewMoreDoctor from "./HomeComponent/ViewMoreDoctor.js";
import MainComponent from "./HomeComponent/MainComponent.js";
//import MainComponent from "./HomeComponent/MainComponent.js";

// import InventoryCategoryScreens from "./Screens/adminScreens/InventoryManagement/CategoryManagement.js";
// import AddInventoryScreens from "./Screens/adminScreens/InventoryManagement/AddInventory.js";
// import PurchaseInventoryScreens from "./Screens/adminScreens/InventoryManagement/PurchaseInventory.js";
// import PastPurchaseInventoryScreens from "./Screens/adminScreens/InventoryManagement/PastPurchases.js";
// import PatientPurchaseInventoryScreens from "./Screens/adminScreens/InventoryManagement/PatientInventoryPurchase.js";
import BillScreen from "./Components/Bill.js";

//ip billing screens
import StartIpBillScreen from "./Screens/ipBillingScreens/startIpBill.js";
import AutocompleteArray from "./Screens/adminScreens/fuseComponent.js";
import AdmittedPatientsScreen from "./Components/AdmittedPatients.js";
import InpatientBill from "./Screens/ipBillingScreens/viewIpBill.js";

import Dashboard from "./Screens/adminScreens/PharmacyManagement/MainDashboardScreen.js";
import AddInventoryPharmacyScreens from "./Screens/adminScreens/PharmacyManagement/AddInventory.js";
import PurchaseInventoryPharmacyScreens from "./Screens/adminScreens/PharmacyManagement/PurchaseInventory.js";
import SupplierManagementScreens from "./Screens/adminScreens/PharmacyManagement/SupplierManagement.js";
import SellInventoryScreens from "./Screens/adminScreens/PharmacyManagement/SellInventory.js";
import PharmacyCategoryScreens from "./Screens/adminScreens/PharmacyManagement/CategoryManagement.js";
import PastPurchaseInventoryScreens from "./Screens/adminScreens/PharmacyManagement/PastPurchases.js";

import TodaysInventoryStatistics from "./Screens/adminScreens/Statistics/InventoryStatistics/TodaysInventoryStatistics.js";
import PharmacyStatisticsScreens from "./Screens/adminScreens/Statistics/InventoryStatistics/AggregatedInventoryStatistics.js";
import VendorLevelStatistics from "./Screens/adminScreens/Statistics/InventoryStatistics/vendorLevelStatistics.js";
import CategotryInventoryStatisticsScreens from "./Screens/adminScreens/Statistics/InventoryStatistics/categoryLevelInventoryStatistics.js";

import { logout1 } from "./redux/userApiCalls";
import { logout2 } from "./redux/adminRedux/adminApiCalls";
import {
	logout3,
	getAllRecommendedWordsArray,
} from "./redux/doctorRedux/doctorApiCalls";
import { logout4 } from "./redux/receptionRedux/receptionApiCalls";
import { logout5 } from "./redux/labRedux/labTechApiCalls";
import SectionNavigator from "./Components/SectionNavigator.js";
import Fuse from "fuse.js";
import Meta from "./Components/Meta.js";
import { allMessagesReset } from "./redux/allMessages.js";
import CustomRevenueStatisticsScreen from "./Screens/adminScreens/Statistics/CustomRevenueStatisticsScreen.js";
export const SERVER_URL = "https://backend.hospital.hextgen.com";
//export const SERVER_URL = "http://localhost:5000";
// export const SERVER_URL = "https://multitenancy.hospital.hextgen.com";

// export const SERVER_URL = "https://multitenancy.hospital.hextgen.com";

export const hospitalname = "AMH_DB";

function App() {
	const { userInfo } = useSelector((state) => state.userLogin);
	const adminLogin = useSelector((state) => state.adminLogin);
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const doctorObj = useSelector((state) => state.doctorLogin);
	const { loading, error, adminInfo } = adminLogin;
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const { labTechInfo } = useSelector((state) => state.labTechLogin);
	const createdSpeciality = useSelector((state) => state.createSpeciality);
	// const { loading, success, userInfo, error } = createdSpeciality;

	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const dispatch = useDispatch();

	// useEffect(() => {
	// 	dispatch(allMessagesReset());
	// 	const checkSession = () => {
	// 		const sessionStartTime = localStorage.getItem("sessionStartTime");
	// 		if (sessionStartTime) {
	// 			const currentTime = Date.now();
	// 			const sessionDuration = currentTime - parseInt(sessionStartTime, 10);
	// 			const maxSessionDuration = 12 * 60 * 60 * 1000; // 6 hours in milliseconds

	// 			if (sessionDuration >= maxSessionDuration) {
	// 				dispatch(logout1());
	// 				dispatch(logout2());
	// 				dispatch(logout3());
	// 				dispatch(logout4());
	// 				dispatch(logout5());

	// 				window.location.href = "/admin/login";
	// 			}
	// 		} else {
	// 			if (
	// 				window.location.pathname !== "/" &&
	// 				!window.location.pathname?.includes("/admin/login") &&
	// 				!window.location.pathname?.includes("/admin/login/")
	// 			) {
	// 				// alert("Software Updated. Please login again");
	// 				dispatch(logout1());
	// 				dispatch(logout2());
	// 				dispatch(logout3());
	// 				dispatch(logout4());
	// 				dispatch(logout5());

	// 				window.location.href = "/admin/login";
	// 			}
	// 		}
	// 	};

	// 	checkSession();
	// 	const interval = setInterval(checkSession, 60000); // Check every minute

	// 	return () => clearInterval(interval);
	// }, []);

	const [navigatorItems, setNavigatorItems] = useState([]);
	const getAllRecommendedWordsDetails = useSelector(
		(state) => state.getAllRecommendedWords
	);
	console.log(getAllRecommendedWordsDetails);
	useEffect(() => {
		if (doctorInfo?.data?.user?.name) {
			dispatch(getAllRecommendedWordsArray());
		}
	}, [doctorInfo?.data?.user?.name]);
	const [fuseState, setFuseState] = useState(null);
	let fuse;
	useEffect(() => {
		if (!getAllRecommendedWordsDetails?.loading) {
			const fuseOptions = {
				keys: ["0"], // We are targeting the first element of each array for searching
				threshold: 0.3, // Adjust fuzziness level, lower value is stricter
				minMatchCharLength: 2, // Minimum characters to trigger search
			};
			const medicationData =
				getAllRecommendedWordsDetails?.getAllRecommendedWords?.data?.find(
					(item) => item?.recommendationName === "Medication"
				)?.namesArray || [];

			console.log(medicationData);
			fuse = new Fuse(medicationData, fuseOptions);
			console.log(fuse);
			setFuseState(fuse);
		}

		// dispatch(GetrecommendationDataSuccess(fuse));
	}, [getAllRecommendedWordsDetails?.loading]);
	console.log(fuse);

	console.log(adminInfo);

	return (
		<Router>
			<div className="d-flex">
				{userInfo?.data?.user?.name ||
				adminInfo?.user?.isAdmin ||
				doctorInfo?.data?.user?.name ||
				receptionInfo?.data?.user?.name ||
				labTechInfo?.data?.user?.name ? (
					<>
						<Meta />
						<SideBar />
						{window?.location?.href?.includes("startprescription") && (
							<SectionNavigator navitems={navigatorItems} />
						)}
					</>
				) : (
					<></>
				)}
				<div className="flex-grow-1 main-content">
					<Header />
					<AlertMessageBar />
					<Routes>
						<Route path="/" element={<MainComponent />} exact />
						{/* <Route
							path="/doctor/:doctorName"
							element={<ViewMoreDoctor />}
							exact
						/> */}
						<Route path="/feedbacks" element={<FeedbackScreens />} exact />
						{/* <Route path="/login" element={<LoginScreen />} /> */}
						{/* <Route path="/register" element={<RegisterScreen />} /> */}
						<Route path="/billingScreen" element={<BillingScreen />} />
						{/* <Route
									path="/users"
									element={userInfo ? <MyUsers /> : <LoginScreen />}
								/>
								<Route
									path="/users/usersInfo"
									element={userInfo ? <UserInfo /> : <LoginScreen />}
								/>
								<Route
									path="/users/usersInfo/viewPrescription"
									element={
										userInfo ? (
											<ViewPrescriptionPatientScreen />
										) : (
											<LoginScreen />
										)
									}
								/>
								<Route
									path="/booking"
									element={userInfo ? <BookingListScreen /> : <LoginScreen />}
								/>
								<Route
									path="/viewbookings"
									element={
										userInfo ? <ViewAppointmentScreen /> : <LoginScreen />
									}
								/>
								<Route
									path="/updatepassword"
									element={
										userInfo ? <UpdatePasswordScreen /> : <LoginScreen />
									}
								/>
								<Route
									path="/addnewuser"
									element={userInfo ? <AddNewUser /> : <LoginScreen />}
								/> */}
						<Route
							path="/admin/addSpeciality"
							element={
								adminInfo ? <AddSpecialityScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/startIpBill"
							element={
								adminInfo || receptionInfo || doctorInfo ? (
									<StartIpBillScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route path="/fuse" element={<AutocompleteArray />} />
						<Route
							path="/viewIpBill"
							element={
								adminInfo || receptionInfo || doctorInfo ? (
									<InpatientBill />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admittedPatients"
							element={
								adminInfo || receptionInfo || doctorInfo ? (
									<AdmittedPatientsScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						{/* <Route
									path='/admin/deleteSpeciality'
									element={
										adminInfo ? (
											<DeleteSpecialityScreens />
										) : (
											<AdminLoginScreens />
										)
									}
								/> */}
						<Route path="/admin/login" element={<AdminLoginScreens />} />
						<Route
							path="/admin/deleteDoctor"
							element={
								adminInfo ? <DeleteDoctorScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/doctorleaves"
							element={
								adminInfo ? <DoctorLeavesScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/addreferraldoctor"
							element={
								adminInfo ? <AddReferralDoctorScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/addDoctor"
							element={adminInfo ? <AddDoctorScreens /> : <AdminLoginScreens />}
						/>
						<Route
							path="/patientManagement/registerPatient"
							element={
								adminInfo || receptionInfo || doctorInfo ? (
									<RegisterPatientScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/myprofile"
							element={
								adminInfo ? <MyProfileScreenAdmin /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/labDeptManagement/addLabDept"
							element={adminInfo ? <AddLabDeptScreen /> : <AdminLoginScreens />}
						/>
						<Route
							path="/admin/labDeptManagement/deleteLabDept"
							element={
								adminInfo ? <DeleteLabDeptScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/labTestManagement/addLabTest"
							element={adminInfo ? <AddLabTestScreen /> : <AdminLoginScreens />}
						/>
						<Route
							path="/admin/labTestManagement/editLabTest"
							element={
								adminInfo ? <EditLabTestScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/labTestManagement/deleteLabTest"
							element={
								adminInfo ? <DeleteLabTestScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/labTechManagement/deleteLabTech"
							element={
								adminInfo ? <DeleteLabTechScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/labTechManagement/editLabTech"
							element={
								adminInfo ? <EditLabTechScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/labTechManagement/addLabTech"
							element={adminInfo ? <AddLabTechScreen /> : <AdminLoginScreens />}
						/>
						{/* <Route path='/admin/home' element={<AdminHomeScreens />} /> */}
						<Route
							path="/admin/feedbacks"
							element={
								adminInfo ? <AdminFeedbackScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/updatepassword"
							element={
								adminInfo ? (
									<UpdatePasswordAdminScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						{/* <Route
									path="/admin/appointments"
									element={
										adminInfo ? (
											<AppointmentListScreen />
										) : (
											<AdminLoginScreens />
										)
									}
								/> */}
						<Route
							path="/admin/receptionManagement/addReception"
							element={
								adminInfo ? <AddReceptionScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/receptionManagement/deleteReception"
							element={
								adminInfo ? <DeleteReceptionScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/searchPatients"
							element={
								adminInfo ? <SearchPatientsScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/searchPatients/viewpres"
							element={
								adminInfo ? (
									<ViewPrescriptionAdminScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/viewprescription"
							element={
								adminInfo || receptionInfo || doctorInfo ? (
									<ViewPrescriptionComponent />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/labbooking"
							element={
								adminInfo ? <OnSpotLabBookingScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/bookappointment"
							element={
								adminInfo ? (
									<OnSpotAppointmentsScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/patientstobevisited/userappointment/editpres"
							element={
								doctorInfo ? <EditPrescriptionScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/doctor/viewpres"
							element={
								doctorInfo ? (
									<ViewPrescriptionDoctorScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						{/* <Route
									path="/patientstobevisited"
									element={
										doctorInfo ? (
											<GetMyAppointmentsScreen />
										) : (
											<AdminLoginScreens />
										)
									}
								/> */}
						<Route
							path="/doctor/searchPatients"
							element={
								doctorInfo ? <SearchPatientScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/doctor/prescritpioncreator"
							element={
								doctorInfo ? (
									<PrescriptionCreatorScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/doctor/historycreator"
							element={
								doctorInfo ? <HistoryCreatorScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/doctor/prescriptioneditor"
							element={
								doctorInfo ? (
									<PrescriptionEditorScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/doctor/historyeditor"
							element={
								doctorInfo ? <HistoryEditorScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/doctor/updatepassword"
							element={
								doctorInfo ? (
									<UpdatePasswordDoctorScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/patientstobevisited/userappointment"
							element={
								doctorInfo || receptionInfo ? (
									<GetUserPrescriptionsScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						{/* <Route path="/doctor/login" element={<DoctorLoginScreen />} /> */}
						<Route
							path="/doctor/leavesManagement/createleaves"
							element={
								doctorInfo ? (
									<DoctorCreateLeavesScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/doctor/leavesManagement/deleteleaves"
							element={
								doctorInfo ? (
									<DoctorDeleteLeavesScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/doctor/SlotManagement/blockslots"
							element={
								doctorInfo ? <DoctorBlockSlotsScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/doctor/SlotManagement/unblockslots"
							element={
								doctorInfo ? (
									<DoctorUnblockSlotsScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/doctor/SlotManagement/editBookingPeriod"
							element={
								doctorInfo ? <EditBookingPeriodScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/doctor/SlotManagement/editMySlots"
							element={doctorInfo ? <EditMySlots /> : <AdminLoginScreens />}
						/>
						<Route
							path="/doctor/templateManagement/addTemplates"
							element={
								doctorInfo ? <AddTemplateScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/doctor/templateManagement/deleteTemplates"
							element={
								doctorInfo ? <DeleteTemplateScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/doctor/templateManagement/editTemplates"
							element={
								doctorInfo ? <EditTemplateScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/history"
							element={
								doctorInfo || receptionInfo ? (
									<StartHistoryScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/startprescription"
							element={
								doctorInfo || receptionInfo ? (
									<StartPrescriptionScreens
										setNavigatorItems={setNavigatorItems}
										fuseState={fuseState}
									/>
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/doctor/nextappointment"
							element={
								doctorInfo ? <NextAppointmentScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/doctor/myprofile"
							element={doctorInfo ? <MyProfileScreen /> : <AdminLoginScreens />}
						/>
						{/* <Route
									path='/doctor/watchlist'
									element={
										doctorInfo ? <WatchListScreen /> : <AdminLoginScreens />
									}
								/> */}
						{/* <Route
                    path="/doctor/medicinemanagment"
                    element={<MedicineManagement />}
                  /> */}
						<Route
							path="/reception/booking"
							element={
								receptionInfo ? <OnSpotBookingScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/reception/labbooking"
							element={
								receptionInfo ? (
									<OnSpotLabBookingScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/reception/myprofile"
							element={
								receptionInfo ? <MyProfileScreenREC /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/reception/searchPatient"
							element={
								receptionInfo ? (
									<SearchPatientScreensREC />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/reception/viewIpBill"
							element={
								receptionInfo ? <InpatientBill /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/reception/viewpres"
							element={
								receptionInfo ? (
									<ViewPrescriptionReceptionScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						{/* <Route
									path="/patientstobevisited"
									element={
										receptionInfo ? (
											<AppointmentListScreenREC />
										) : (
											<AdminLoginScreens />
										)
									}
								/> */}
						<Route
							path="/reception/scheduledAppointments"
							element={
								receptionInfo ? (
									<ScheduledAppointments />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/reception/BlockedSlots"
							element={receptionInfo ? <BlockedSlots /> : <AdminLoginScreens />}
						/>
						<Route
							path="/reception/updatepassword"
							element={
								receptionInfo ? (
									<UpdatePasswordRECScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/labTech/myprofile"
							element={
								labTechInfo ? <MyProfileScreenLab /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/labTech/updatepassword"
							element={
								labTechInfo ? (
									<UpdatePasswordScreenLab />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/labTech/patientsInQueue"
							element={
								labTechInfo ? <PatientsInqueueScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/labTech/createReport"
							element={
								labTechInfo ? <CreateReportScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/labTech/createTest"
							element={
								labTechInfo ? (
									<CreateTestTemplateScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/labTech/editTest"
							element={
								labTechInfo ? <EditTestTemplateScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/labTech/deleteTest"
							element={
								labTechInfo ? (
									<DeleteTestTemplateScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/labTech/labvisited"
							element={
								labTechInfo ? <LabVisitedScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/patientstobevisited"
							element={
								adminInfo ? (
									<PatientsToBeVisitedScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						{/* <Route
									path="/admin/referraldoctorrevenue"
									element={
										adminInfo ? (
											<ReferralDoctorRevenueScreens />
										) : (
											<AdminLoginScreens />
										)
									}
								/> */}
						<Route
							path="/admin/deptstatistics"
							element={
								adminInfo ? (
									<DepartmentStatisticsScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/overall"
							element={
								adminInfo ? (
									<CustomRevenueStatisticsScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/labstatistics"
							element={
								adminInfo ? <LabStatisticsScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/todayslabpatients"
							element={
								adminInfo ? <TodaysLabPatientsScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/teststatistics"
							element={
								adminInfo ? <TestStatisticsScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/hospitalstatistics"
							element={
								adminInfo ? (
									<HospitalStatisticsScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/specialitystatistics"
							element={
								adminInfo ? (
									<SpecialityStatisticsScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/doctorstatistics"
							element={
								adminInfo ? <DoctorStatisticsScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/todaysoppatients"
							element={
								adminInfo ? (
									<TodaysOPStatisticsScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						{/* <Route
							path='/admin/inventorycategory'
							element={
								adminInfo ? <InventoryCategoryScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path='/admin/addinventory'
							element={
								adminInfo ? <AddInventoryScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path='/admin/purchaseinventory'
							element={
								adminInfo ? <PurchaseInventoryScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path='/admin/pastpurchaseinventory'
							element={
								adminInfo ? (
									<PastPurchaseInventoryScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/> */}
						{/* <Route
							path='/admin/patientpurchaseinventory'
							element={
								adminInfo ? (
									<PatientPurchaseInventoryScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/> */}
						<Route
							path="/bill"
							element={
								adminInfo ||
								receptionInfo ||
								doctorInfo ||
								labTechInfo ||
								userInfo ? (
									<BillScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/pharmacydashboard"
							element={adminInfo ? <Dashboard /> : <AdminLoginScreens />}
						/>
						<Route
							path="/admin/pharmacyaddinventory"
							element={
								adminInfo ? (
									<AddInventoryPharmacyScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/pharmacypurchaseinventory"
							element={
								adminInfo ? (
									<PurchaseInventoryPharmacyScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/suppliermanagement"
							element={
								adminInfo ? (
									<SupplierManagementScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/sellpharmacy"
							element={
								adminInfo || receptionInfo || doctorInfo ? (
									<SellInventoryScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/pharmacycategories"
							element={
								adminInfo ? <PharmacyCategoryScreens /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/pastpurchases"
							element={
								adminInfo ? (
									<PastPurchaseInventoryScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/todaysinventorystatistics"
							element={
								adminInfo ? (
									<TodaysInventoryStatistics />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/AllPharmacySales"
							element={
								adminInfo ? (
									<PharmacyStatisticsScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/admin/VendorLevelStatistics"
							element={
								adminInfo ? <VendorLevelStatistics /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/admin/CategoryLevelStatistics"
							element={
								adminInfo ? (
									<CategotryInventoryStatisticsScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/reception/patientstobevisited"
							element={
								receptionInfo ? (
									<PatientsToBeVisitedScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						{/* <Route
							path='/reception/patientpurchaseinventory'
							element={
								receptionInfo ? (
									<PatientPurchaseInventoryScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/> */}
						<Route
							path="/doctor/patientstobevisited"
							element={
								doctorInfo ? (
									<PatientsToBeVisitedScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						{/* <Route
							path='/doctor/patientpurchaseinventory'
							element={
								doctorInfo ? (
									<PatientPurchaseInventoryScreens />
								) : (
									<AdminLoginScreens />
								)
							}
						/> */}
						<Route
							path="/admin/visitedpatients"
							element={
								adminInfo ? <PatientsVisitedScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/doctor/visitedpatients"
							element={
								doctorInfo ? <PatientsVisitedScreen /> : <AdminLoginScreens />
							}
						/>
						<Route
							path="/reception/visitedpatients"
							element={
								receptionInfo ? (
									<PatientsVisitedScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/viewreport"
							element={
								userInfo ||
								receptionInfo ||
								adminInfo ||
								doctorInfo ||
								labTechInfo ? (
									<ViewReportScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/patientManagement/addnewuser"
							element={
								receptionInfo || adminInfo || doctorInfo ? (
									<AddNewPatientScreen />
								) : (
									<AdminLoginScreens />
								)
							}
						/>
						<Route
							path="/reception/resetPassword/:token"
							element={<ForgotPassowrd />}
						/>
						<Route path="/verifymail" element={<VerifyEmail />} />
						<Route
							path="/admin/resetPassword/:token"
							element={<ForgotPassowrd />}
						/>
						<Route
							path="/doctor/resetPassword/:token"
							element={<ForgotPassowrd />}
						/>
						<Route
							path="/labtech/resetPassword/:token"
							element={<ForgotPassowrd />}
						/>
						<Route
							path="/user/resetPassword/:token"
							element={<ForgotPassowrd />}
						/>
						<Route path="/aboutus" element={<AboutUs />} />
					</Routes>
					<Footer />
				</div>
			</div>
		</Router>
	);
}

export default App;
