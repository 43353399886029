/** @format */

import { createSlice } from '@reduxjs/toolkit';

const editHistoryByIdx = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		editHistoryByIdxStart: (state) => {
			state.loading = true;
		},
		editHistoryByIdxSuccess: (state, action) => {
			state.loading = false;
			state.editHistoryByIdx = action.payload;
			state.error = false;
			state.success = true;
		},
		editHistoryByIdxFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editHistoryByIdxReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editHistoryByIdxStart,
	editHistoryByIdxSuccess,
	editHistoryByIdxFailure,
	editHistoryByIdxReset,
} = editHistoryByIdx.actions;
export default editHistoryByIdx.reducer;
