/** @format */

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Meta from "../../../Components/Meta";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLabDepts } from "../../../redux/adminRedux/adminApiCalls";
import { createTemplateForTest } from "../../../redux/labRedux/labTechApiCalls";

const DynamicRow = ({ index, values, handleChange, handleDelete }) => {
  return (
    <>
      <Container className="header-center mt-5">
        <Row className="justify-content-center">
          <Col md="4">
            <Col md="12" className="text-center">
              <h6>Label Name:</h6>{" "}
            </Col>
            <Col md="12" className="text-center">
              <textarea
                rows={1}
                cols={33}
                type="text"
                name={`name-${index}`}
                value={values[`name-${index}`]}
                onChange={handleChange}
              ></textarea>
            </Col>
          </Col>
          <Col md="3">
            <Col md="12" className="text-center">
              <h6>Units:</h6>{" "}
            </Col>
            <Col md="12" className="text-center">
              <textarea
                rows={1}
                cols={33}
                type="text"
                name={`units-${index}`}
                value={values[`units-${index}`]}
                onChange={handleChange}
              ></textarea>
            </Col>
          </Col>
          <Col md="4">
            <Col md="12" className="text-center">
              <h6>Reference Values:</h6>{" "}
            </Col>
            <Col md="12" className="text-center">
              <textarea
                rows={1}
                cols={33}
                type="text"
                name={`reference-${index}`}
                value={values[`reference-${index}`]}
                onChange={handleChange}
              ></textarea>
            </Col>
          </Col>
          <Col md="1">
            <Col>
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(index)}
              >
                <i className="fa fa-trash fa-2x"></i>
              </button>{" "}
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const CreateTestTemplateScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    dispatch(getLabDepts());
  }, []);
  const { getlabDeptAll } = useSelector((state) => state.getLabDept);
  console.log(getlabDeptAll);

  const [deptName, setDeptName] = useState("Choose Department");
  const [deptId, setDeptId] = useState("");
  const [testName, setTestName] = useState("Choose Test");
  const [deptTestNames, setdeptTestNames] = useState([]);
  const [testIdx, setTestIdx] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [interpretation, setInterpretation] = useState("");

  const handleDeptSelect = (eventKey) => {
    setDeptName(eventKey.split("@@")[0]);
    setDeptId(eventKey.split("@@")[1]);
    setTestIdx(eventKey.split("@@")[2]);
    setdeptTestNames(
      getlabDeptAll?.data?.data[eventKey.split("@@")[2]]?.testDetails
    );
    setTestName("Choose Test");
  };

  const handleTestSelect = (eventKey) => {
    setTestName(eventKey);
  };

  const [rows, setRows] = useState([]);
  const [values, setValues] = useState({});
  console.log(rows);
  console.log(values);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleAddRow = () => {
    setRows((prevRows) => [...prevRows, Date.now()]);
  };

  const handleDeleteRow = (index) => {
    const updatedValues = { ...values };
    Object.keys(updatedValues)
      .filter((key) => key.includes(index))
      .forEach((key) => delete updatedValues[key]);

    setValues(updatedValues);

    setRows((prevRows) => prevRows.filter((rowIndex) => rowIndex !== index));
  };

  useEffect(() => {
    handleAddRow();
  }, []);

  const submitHandler = () => {
    if (templateName === "") {
      alert("Enter template name");
    } else if (deptName === "Choose Department") {
      alert("Select deparment");
    } else if (testName === "Choose Test") {
      alert("Select Test");
    } else if (Object.keys(values).length === 0) {
      alert("Enter the test details");
    } else if (
      Object.keys(values).length % 3 !== 0 ||
      Object.keys(values).length / 3 !== rows.length
    ) {
      alert("Enter all the required fields");
    } else if (interpretation === "") {
      alert("Enter the interpretation");
    } else {
      const arr = Object.keys(values);
      const extractedValues = [];
      console.log(arr);
      console.log(rows);
      for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (
            arr[j].split("-")[1] == rows[i] &&
            arr[j].split("-")[0] === "name"
          ) {
            var name = values[arr[j]];
          }
          if (
            arr[j].split("-")[1] == rows[i] &&
            arr[j].split("-")[0] === "units"
          ) {
            var units = values[arr[j]];
          }
          if (
            arr[j].split("-")[1] == rows[i] &&
            arr[j].split("-")[0] === "reference"
          ) {
            var reference = values[arr[j]];
          }
        }
        extractedValues.push({
          labelName: name,
          labelValue: "",
          units: units,
          referenceValues: reference,
        });
      }
      console.log(extractedValues);
      dispatch(
        createTemplateForTest(
          deptId,
          testName,
          templateName,
          extractedValues,
          interpretation
        )
      );
      setDeptId("");
      setDeptName("Choose Department");
      setTestName("Choose Test");
      setInterpretation("");
      setTemplateName("");
      setRows([]);
      setValues([]);
      handleAddRow();
    }
  };

  return (
    <>
      <Meta />
      <h2 className="header-center">Create Test Template</h2>
      <Container className="header-center mt-5">
        <Row className="justify-content-center">
          <Col md="auto" className="text-center">
            <h6>
              Select the filters{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>
          </Col>
          <Col md="auto" className="text-center">
            <DropdownButton
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={deptName}
              onSelect={handleDeptSelect}
            >
              {getlabDeptAll?.data?.data?.length === 0 ? (
                <Dropdown.Item>
                  {/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
                </Dropdown.Item>
              ) : (
                getlabDeptAll?.data?.data?.map(
                  (dept, index) =>
                    dept.deptName !== "hospital" &&
                    dept.status !== 0 && (
                      <Dropdown.Item
                        eventKey={
                          dept.deptName + "@@" + dept._id + "@@" + index
                        }
                      >
                        {dept.deptName}
                      </Dropdown.Item>
                    )
                )
              )}
            </DropdownButton>
          </Col>
          <Col md="auto" className="text-center">
            <DropdownButton
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={testName}
              onSelect={handleTestSelect}
            >
              {deptTestNames?.length === 0 ? (
                <Dropdown.Item>
                  {/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
                </Dropdown.Item>
              ) : (
                deptTestNames?.map(
                  (test, index) =>
                    test.status !== 0 && (
                      <Dropdown.Item eventKey={test.testName}>
                        {test.testName}
                      </Dropdown.Item>
                    )
                )
              )}
            </DropdownButton>
          </Col>
        </Row>
      </Container>
      <Container className="header-center mt-5">
        <Row>
          <Col md="auto" className="text-center"></Col>
          <Col md="auto" className="text-center">
            <b>Template Name:</b>
          </Col>
          <Col md="auto" className="text-center">
            <textarea
              cols={55}
              rows={1}
              onChange={(e) => setTemplateName(e.target.value)}
              value={templateName}
            ></textarea>
          </Col>
        </Row>
      </Container>

      {rows.map((rowIndex) => (
        <DynamicRow
          key={rowIndex}
          index={rowIndex}
          values={values}
          handleChange={handleChange}
          handleDelete={handleDeleteRow}
        />
      ))}

      <Container className="header-center mt-5">
        <Row className="justify-content-center">
          <Col md="auto" className="text-center">
            <button className="btn btn-secondary" onClick={handleAddRow}>
              + Add more rows
            </button>
          </Col>
        </Row>
      </Container>

      <Container className="header-center mt-5">
        <Row>
          <Col md="auto" className="text-center"></Col>
          <Col md="auto" className="text-center">
            <b>Interpretation:</b>
          </Col>
          <Col md="auto" className="text-center">
            <textarea
              rows={6}
              cols={110}
              onChange={(e) => setInterpretation(e.target.value)}
              value={interpretation}
            ></textarea>
          </Col>
        </Row>
      </Container>

      <Container className="header-center mt-5">
        <Row className="justify-content-center">
          <Col md="auto" className="text-center">
            <button className="btn btn-success" onClick={submitHandler}>
              Submit
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateTestTemplateScreen;
