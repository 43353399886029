/** @format */
import { SERVER_URL } from "../../App";
import { hospitalname } from "../../App";
import axios from "axios";

import {
	getUserDataByCategoryRequest,
	getUserDataByCategorySuccess,
	getUserDataByCategoryFailure,
} from "./Patient_Management/getUserDataByCategory.js";

import {
	getUserDataInSearchPatientsRequest,
	getUserDataInSearchPatientsSuccess,
	getUserDataInSearchPatientsFailure,
} from "./Patient_Management/getUserDataInSearchPatients.js";

import {
	createSpecialityStart,
	createSpecialitySuccess,
	createSpecialityFailure,
} from "./Speciality_Management/createSpeciality.js";
import {
	createReviewStart,
	createReviewSuccess,
	createReviewFailure,
} from "./Review_Management/createReview.js";
import {
	specialityGetRequest,
	specialityGetSuccess,
	specialityGetFailure,
	specialityGetReset,
} from "./Speciality_Management/getSpeciality.js";
import {
	getReviewsGetRequest,
	getReviewsGetFailure,
	getReviewsGetSuccess,
	getReviewsGetReset,
} from "./Review_Management/getReviews.js";
import { persistor } from "../store";
import {
	createDoctorStart,
	createDoctorSuccess,
	createDoctorFailure,
} from "./Doctor_Management/createDoctor.js";

import {
	doctorBookingGetFailure,
	doctorBookingGetRequest,
	doctorBookingGetSuccess,
} from "./Booking_Management/getBooking.js";
import {
	loginStart,
	loginSuccess,
	logout,
} from "./Admin_MyProfile/adminLogin.js";
import { loginfailure } from "./Admin_MyProfile/adminLogin.js";
import {
	changeVisitedStart,
	changeVisitedFailure,
	changeVisitedSuccess,
} from "./Booking_Management/changeVisited.js";
import {
	changeOnHoldStart,
	changeOnHoldFailure,
	changeOnHoldSuccess,
} from "./Booking_Management/changeOnHold.js";
import {
	changeSlotSuccess,
	changeSlotFailure,
	changeSlotStart,
} from "./Booking_Management/changeSlot.js";

import {
	adminCreateBookingStart,
	adminCreateBookingFailure,
	adminCreateBookingSuccess,
} from "./Booking_Management/adminCreateBooking.js";

import {
	verifyFailure,
	verifyReset,
	verifySuccess,
	verifyStart,
} from "./Admin_MyProfile/VerifyCode.js";

import {
	deleteDoctorFailure,
	deleteDoctorRequest,
	deleteDoctorSuccess,
} from "./Doctor_Management/deleteDoctor.js";

import {
	getLeavesRequest,
	getLeavesFailure,
	getLeavesSuccess,
} from "../doctorRedux/Leave_Management/getLeaves.js";

import {
	resendOtpStart,
	resendOtpReset,
	resendOtpFailure,
	resendOtpSuccess,
} from "./Admin_MyProfile/resendOtp.js";

import {
	getDoctorVisitedPatientsforParticularDateFailure,
	getDoctorVisitedPatientsforParticularDateRequest,
	getDoctorVisitedPatientsforParticularDateSuccess,
} from "./Doctor_Management/getDoctorVisitedPatientsforParticularDate.js";

import {
	getConsultantDoctorFailure,
	getConsultantDoctorRequest,
	getConsultantDoctorSuccess,
} from "./Doctor_Management/getConsultantDoctorFees.js";

import {
	createReceptionistStart,
	createReceptionistSuccess,
	createReceptionistFailure,
} from "./Reception_Management/addReceptionist.js";

import {
	deleteReceptionFailure,
	deleteReceptionRequest,
	deleteReceptionSuccess,
} from "./Reception_Management/deleteReception.js";

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";

import {
	getAllReceptionFailure,
	getAllReceptionRequest,
	getAllReceptionSuccess,
} from "./Reception_Management/getAllReceptions.js";

import {
	resetPasswordFailure,
	resetPasswordStart,
	resetPasswordSuccess,
} from "./Admin_MyProfile/resetPassword.js";
import {
	createLabDeptStart,
	createLabDeptSuccess,
	createLabDeptFailure,
	createLabDeptReset,
} from "./Lab_Management/Lab_Department_Management/createDept.js";

import {
	getTestNameRequest,
	getTestNameFailure,
	getTestNameSuccess,
} from "./Lab_Management/Test_Management/getTestNames.js";

import {
	labDeptGetRequest,
	labDeptGetSuccess,
	labDeptGetFailure,
	labDeptGetReset,
} from "./Lab_Management/Lab_Department_Management/getLabDepts.js";

import {
	createLabTechnicianStart,
	createLabTechnicianSuccess,
	createLabTechnicianFailure,
	createLabTechnicianReset,
} from "./Lab_Management/Lab_Technician_Management/createLabTechnician.js";

import {
	createLabReportDetailsStart,
	createLabReportDetailsSuccess,
	createLabReportDetailsFailure,
	createLabReportDetailsReset,
} from "./Lab_Management/Lab_Technician_Management/addLabReportDetails.js";

import {
	createLabTestRequest,
	createLabTestSuccess,
	createLabTestFailure,
} from "./Lab_Management/Test_Management/createLabTest.js";

import {
	editUserRequest,
	editUserSuccess,
	editUserFailure,
	editUserReset,
} from "./Patient_Management/userEdit.js";

import {
	deleteLabDeptFailure,
	deleteLabDeptRequest,
	deleteLabDeptSuccess,
} from "./Lab_Management/Lab_Department_Management/deleteLabDept.js";

import {
	deleteLabTestFailure,
	deleteLabTestRequest,
	deleteLabTestSuccess,
} from "./Lab_Management/Test_Management/deleteLabTest.js";

import {
	deleteLabTechFailure,
	deleteLabTechRequest,
	deleteLabTechSuccess,
} from "./Lab_Management/Lab_Technician_Management/deleteLabTech.js";

import {
	getLabTechFailure,
	getLabTechRequest,
	getLabTechSuccess,
} from "./Lab_Management/Lab_Technician_Management/getLabTechniciansFromParticularDepartment.js";

import {
	editTestDetailsRequest,
	editTestDetailsSuccess,
	editTestDetailsFailure,
	editTestDetailsReset,
} from "./Lab_Management/Test_Management/editLabTest.js";

import {
	editLabTechnicianAdminFailure,
	editLabTechnicianAdminReset,
	editLabTechnicianAdminStart,
	editLabTechnicianAdminSuccess,
} from "./Lab_Management/Lab_Technician_Management/editLabTechnicianByAdmin.js";

import {
	getAllLabTechniciansRequest,
	getAllLabTechniciansSuccess,
	getAllLabTechniciansFailure,
} from "./Lab_Management/Lab_Technician_Management/getAllLabTechnicians.js";

import {
	getLabTechnicianRequest,
	getLabTechnicianSuccess,
	getLabTechnicianFailure,
} from "./Lab_Management/Lab_Technician_Management/getLabTechnician.js";

import {
	editAdminFailure,
	editAdminRequest,
	editAdminSuccess,
} from "./Admin_MyProfile/editAdmin.js";

import {
	UpdatePasswordStart,
	UpdatePasswordSuccess,
	UpdatePasswordFailure,
	UpdatePasswordReset,
} from "./Admin_MyProfile/UpdatePassword.js";

import {
	createReferralDoctorStart,
	createReferralDoctorSuccess,
	createReferralDoctorFailure,
	createReferralDoctorReset,
} from "./Doctor_Management/createReferralDoctor.js";

import {
	createConsultantDoctorStart,
	createConsultantDoctorSuccess,
	createConsultantDoctorFailure,
	createConsultantDoctorReset,
} from "./Doctor_Management/createConsultantDoctor.js";

import {
	ViewLabReportDetailsStart,
	ViewLabReportDetailsSuccess,
	ViewLabReportDetailsFailure,
	ViewLabReportDetailsReset,
} from "./Lab_Report_Management/ViewReport.js";

import {
	addVitalsFailure,
	addVitalsStart,
	addVitalsSuccess,
} from "./Speciality_Management/addVitalInSpeciality.js";

import {
	getVitalsFailure,
	getVitalsStart,
	getVitalsSuccess,
} from "./Speciality_Management/getVitalsInSpeciality.js";

import {
	addServiceFailure,
	addServiceStart,
	addServiceSuccess,
} from "./IpBill_Management/addService.js";

import {
	getAllServicesFailure,
	getAllServicesRequest,
	getAllServicesSuccess,
} from "./IpBill_Management/getAllService.js";

import {
	getAllAdmittedPatientsFailure,
	getAllAdmittedPatientsRequest,
	getAllAdmittedPatientsSuccess,
} from "./IpBill_Management/getAllAdmittedPatients.js";
import {
	createIpBillFailure,
	createIpBillStart,
	createIpBillSuccess,
} from "./IpBill_Management/createIpBill.js";

import {
	getIpBillFailure,
	getIpBillRequest,
	getIpBillSuccess,
} from "./IpBill_Management/getIpBill.js";
import {
	saveIpBillFailure,
	saveIpBillStart,
	saveIpBillSuccess,
} from "./IpBill_Management/saveIpBill.js";
import {
	closeIpBillStart,
	closeIpBillFailure,
	closeIpBillSuccess,
} from "./IpBill_Management/closeIpBill";
import {
	inventoryInfoRequest,
	inventoryInfoSuccess,
	inventoryInfoFailure,
	inventoryInfoReset,
} from "./Admin_MyProfile/inventoryInfo.js";

const username = localStorage.getItem("username");

export const login = (username, password) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(loginStart());
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/admin/login`,
			{ username, password },
			config
		);
		console.log(data);

		// console.log(data.user);
		dispatch(loginSuccess(data));
		dispatch(
			allMessagesSuccess(
				"Credentials verified, Please verify the otp sent to your registered email"
			)
		);
		localStorage.setItem("sessionStartTime", Date.now().toString());
	} catch (error) {
		dispatch(allMessagesFailure("Incorrect mobile number or password"));
		console.log(JSON.stringify(error) + "line298");
		dispatch(
			loginfailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		// dispatch(
		//   loginFailure(
		//     error?.response && error?.response?.data?.data?.message
		//       ? error?.response?.data?.data?.message
		//       : error?.message
		//   )
		// );
		console.log("!2332112");
	}
};

export const editAdmin =
	(name, mobilenumber, email, dob, gender, address, id) => async (dispatch) => {
		try {
			dispatch(editAdminRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/admin/editAdmin`,
				{
					name,
					mobilenumber,
					email,
					dob,
					gender,
					address,
					id,
				},
				config
			);
			dispatch(editAdminSuccess(data));
			localStorage.setItem("adminInfo", JSON.stringify(data.data));
			console.log(data);
			// console.log(data.user);

			dispatch(verifySuccess(data.data));
			dispatch(allMessagesSuccess("Successfully changed the fields"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				editAdminFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getInventoryInfo = () => async (dispatch) => {
	try {
		dispatch(inventoryInfoRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};

		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/admin/getAdminInfo`,

			config
		);
		dispatch(inventoryInfoSuccess(data?.data));
		const adminInfo = localStorage?.getItem("adminInfo");
		if (adminInfo) {
			dispatch(verifySuccess(data?.data));
		}
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			inventoryInfoFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const resendOtpAdmin = (username) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(resendOtpStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/admin/resendOtp`,
			{ username },
			config
		);
		console.log(data);

		// console.log(data.user);
		dispatch(resendOtpSuccess());
		dispatch(allMessagesSuccess("OTP sent successfully"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			resendOtpFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const verifyCodeAdmin = (mobileNo, otp) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(verifyStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/admin/otpVerification`,
			{ mobilenumber: mobileNo, otp },
			config
		);
		console.log(data);
		localStorage.setItem("token-user", data.token);
		localStorage.setItem("adminInfo", JSON.stringify(data.data));
		localStorage.setItem(
			"username",
			JSON.stringify("6fb4f22992a0d164b77267fde5477248")
		);
		// console.log(data.user);
		dispatch(verifySuccess(data.data));
		dispatch(allMessagesSuccess("OTP Verified"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			verifyFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const logout2 = () => (dispatch) => {
	dispatch(logout());
	// dispatch(userUpdateProfileReset());
	// dispatch(bookingListMyReset());
	// dispatch(tourReviewCreateReset());
	localStorage.removeItem("userInfo");
	localStorage.removeItem("adminInfo");
	localStorage.removeItem("receptionInfo");
	localStorage.removeItem("labTechInfo");
	localStorage.removeItem("doctorInfo");
	localStorage.removeItem("username");
	localStorage.removeItem("token-user");
	localStorage.removeItem("persist:root");
	localStorage.removeItem("sessionStartTime");
	persistor.pause();
	persistor.flush().then(() => {
		return persistor.purge();
	});
};

export const createSpeciality = (speciality) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(createSpecialityStart());
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/speciality/create`,
			{ speciality },
			config
		);

		// console.log(data.user);
		dispatch(createSpecialitySuccess(data));
		dispatch(allMessagesSuccess("Speciality Succesfully created"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			createSpecialityFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getSpecialities = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(specialityGetRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/speciality/getAll`,

			config
		);
		console.log(data);
		dispatch(specialityGetSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			specialityGetFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const createReview = (push, id) => async (dispatch, getState) => {
	try {
		dispatch(createReviewStart());

		const {
			userLogin: { userInfo },
		} = getState();
		console.log(getState());
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};

		const data = await axios.post(
			`${SERVER_URL}/api/v1/admin/push`,
			{ push: push, id: id },
			config
		);
		console.log(data + "review123");
		dispatch(createReviewSuccess(data));
		if (push) dispatch(allMessagesSuccess("Review created successfully"));
		else dispatch(allMessagesSuccess("Review deleted successfully"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		const message =
			error?.response && error?.response?.data?.data?.message
				? error?.response?.data?.data?.message
				: error?.message;
		if (message === "Not authorized, token failed") {
			localStorage.removeItem("persist:root");
			dispatch(logout());
		}
		dispatch(createReviewFailure(message));
	}
};

export const getReviews = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getReviewsGetRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/admin/getAllReviews`,

			config
		);
		console.log(data);
		dispatch(getReviewsGetSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getReviewsGetFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const createReception =
	(name, mobilenumber, email, gender, dob, address) => async (dispatch) => {
		try {
			dispatch(createReceptionistStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/admin/addReception`,
				{
					name,
					mobilenumber,
					email,
					gender,
					dob,
					address,
				},
				config
			);

			console.log(data);
			dispatch(createReceptionistSuccess(data));
			dispatch(allMessagesSuccess("Reception created successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			console.log(error.response.data.data.message);
			dispatch(
				createReceptionistFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const createDoctor =
	(
		name,
		mobilenumber,
		speciality,
		specialityId,
		designation,
		education,
		experience,
		personality,
		email,
		address,
		dob,
		gender,
		ipConsultationFee,
		opConsultationFee,
		ipSurgeryFees,
		days,
		bookingPeriod,
		imageUrl
	) =>
	async (dispatch) => {
		try {
			dispatch(createDoctorStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			console.log(imageUrl + "line654");
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/admin/adddoctor`,
				{
					name,
					mobilenumber,
					speciality,
					specialityId,
					designation,
					education,
					experience,
					personality,
					email,
					address,
					dob,
					gender,
					ipConsultationFee,
					opConsultationFee,
					ipSurgeryFees,
					days,
					bookingPeriod,
					imageUrl,
				},
				config
			);

			console.log(data);
			dispatch(createDoctorSuccess(data));
			dispatch(allMessagesSuccess("Doctor Succesfully created"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			console.log(error);
			dispatch(
				createDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getDoctorsAppointmentsForDate =
	(doctor, date) => async (dispatch) => {
		try {
			console.log(doctor + "  " + date);
			dispatch(doctorBookingGetRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			console.log(config.headers.Authorization + "1111222233333");
			// console.log(
			// 	`${SERVER_URL}/api/v1/booking/getDoctorsAppointmentsForDate/${speciality}/${doctor}/${date}`
			// );
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/booking/getDoctorsAppointmentsForDate/${doctor}/${date}`,
				config
			);

			console.log(data);
			dispatch(doctorBookingGetSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				doctorBookingGetFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const changeToVisited = (id) => async (dispatch) => {
	try {
		console.log("11223344" + id);
		dispatch(changeVisitedStart());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/reception/changeVisited`,
			{ id },
			config
		);
		console.log(data);
		dispatch(changeVisitedSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			changeVisitedFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const changeToOnHold = (id, dayIdx, slotIdx) => async (dispatch) => {
	try {
		console.log("11223344" + id);
		console.log(dayIdx);
		console.log(slotIdx);
		dispatch(changeOnHoldStart());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: `${username}`,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/reception/changeOnHold`,
			{ id, dayIdx, slotIdx },
			config
		);
		console.log(data);
		dispatch(changeOnHoldSuccess(data));
		dispatch(allMessagesSuccess("Moved the appointment to hold"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			changeOnHoldFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const changeSlot =
	(
		newbookingtimeSlotIdx,
		prevdayIdx,
		newBookingDate,
		bookingId,
		newbookingdayIdx
	) =>
	async (dispatch) => {
		try {
			// console.log('11223344' + id);
			dispatch(changeSlotStart());
			console.log(
				newbookingtimeSlotIdx,
				prevdayIdx,
				newBookingDate,
				bookingId,
				newbookingdayIdx + "line889"
			);
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: `${username}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/reception/changeSlot`,
				{
					newbookingtimeSlotIdx,
					prevdayIdx,
					newBookingDate,
					bookingId,
					newbookingdayIdx,
					username,
				},
				config
			);
			console.log(data);
			dispatch(changeSlotSuccess(data));
			dispatch(allMessagesSuccess("Slot changed successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				changeSlotFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getUserDataByCategory = (mobileno) => async (dispatch) => {
	try {
		console.log("11223344" + mobileno);
		dispatch(getUserDataByCategoryRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/users/getuserByCategory/${username}`,
			{ mobileno },
			config
		);

		console.log(data);
		dispatch(getUserDataByCategorySuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getUserDataByCategoryFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getUserDataInSearchPatient = (mobileno) => async (dispatch) => {
	try {
		console.log("11223344" + mobileno);
		dispatch(getUserDataInSearchPatientsRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/users/getuserByCategory/${username}`,
			{ mobileno },
			config
		);

		console.log(data);
		dispatch(getUserDataInSearchPatientsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getUserDataInSearchPatientsFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getUserDataByCategoryForUser = (mobileno) => async (dispatch) => {
	try {
		console.log("11223344" + mobileno);
		dispatch(getUserDataByCategoryRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/users/getuserByCategoryForUser`,
			{ mobileno },
			config
		);

		console.log(data);
		dispatch(getUserDataByCategorySuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getUserDataByCategoryFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const adminCreateBooking =
	(
		patient_name,
		dateOptionNumeric,
		slot,
		slotCount,
		userPhone,
		doctorName,
		doctor,
		specialityName,
		specialityId,
		userId,
		dayIdx,
		userIdx
	) =>
	async (dispatch) => {
		try {
			console.log(patient_name);
			dispatch(adminCreateBookingStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const data = await axios.post(
				`${SERVER_URL}/api/v1/admin/adminCreateBooking`,
				{
					name: patient_name,
					scheduledDate: dateOptionNumeric,
					slot: slot,
					slotCount: slotCount,
					userPhone: userPhone,
					doctorName: doctorName,
					doctor: doctor,
					specialityName: specialityName,
					specialityId: specialityId,
					userId: userId,
					dayIdx,
					userIdx,
				},
				config
			);
			console.log(data + "review123");
			dispatch(adminCreateBookingSuccess(data));
			dispatch(allMessagesSuccess("Booking created successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			const message =
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message;
			if (message === "Not authorized, token failed") {
				localStorage.removeItem("persist:root");
				dispatch(logout());
			}
			dispatch(adminCreateBookingFailure(message));
		}
	};

export const deleteDoctor = (id) => async (dispatch) => {
	try {
		console.log("11223344" + id);
		dispatch(deleteDoctorRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/admin/deleteDoctor/${id}`,
			{
				id,
			},
			config
		);
		console.log(data);
		dispatch(deleteDoctorSuccess(data));
		dispatch(allMessagesSuccess("Doctor Succesfully deleted"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			deleteDoctorFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getLeaves = (id) => async (dispatch) => {
	try {
		console.log("11223344" + id);
		dispatch(getLeavesRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/admin/getLeaves/${id}`,
			config
		);
		console.log(data);
		dispatch(getLeavesSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getLeavesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getDoctorVisitedPatientsforParticularDate =
	(id, date) => async (dispatch) => {
		try {
			console.log("11223344" + id);
			dispatch(getDoctorVisitedPatientsforParticularDateRequest());
			//date yyyy-mm-dd
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/admin/getPatientsforDate/${id}/${date}`,
				config
			);
			console.log(data);
			dispatch(getDoctorVisitedPatientsforParticularDateSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				getDoctorVisitedPatientsforParticularDateFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getConsultantDoctorFees = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getConsultantDoctorRequest());
		//date yyyy-mm-dd
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/ipBill/getConslutantDoctorFees`,
			config
		);
		console.log(data);
		dispatch(getConsultantDoctorSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getConsultantDoctorFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const deleteReception = (id) => async (dispatch) => {
	try {
		console.log("11223344" + id);
		dispatch(deleteReceptionRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/admin/deleteReception/${id}`,
			{
				id,
			},
			config
		);
		console.log(data);
		dispatch(deleteReceptionSuccess(data));
		dispatch(allMessagesSuccess("Receptionist Succesfully deleted"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			deleteReceptionFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getAllReception = () => async (dispatch) => {
	try {
		dispatch(getAllReceptionRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/admin/getAllReceptions`,

			config
		);
		console.log(data);
		dispatch(getAllReceptionSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getAllReceptionFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const resetPasswordForAdmin = (password, token) => async (dispatch) => {
	try {
		dispatch(resetPasswordStart());
		console.log(password, token);
		const data = await axios.post(`${SERVER_URL}/api/v1/admin/resetPassword`, {
			password: password,
			token: token,
		});
		dispatch(resetPasswordSuccess());
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			resetPasswordFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const createLabDept = (deptName) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(createLabDeptStart());
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/labDept/create`,
			{ deptName },
			config
		);

		// console.log(data.user);
		dispatch(createLabDeptSuccess(data));
		dispatch(allMessagesSuccess("Lab Department Succesfully created"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		console.log(
			error.response && error.response.data.data.message
				? error.response.data.data.message
				: error.message
		);
		dispatch(
			createLabDeptFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		// dispatch(allMessagesSuccess(error));
	}
};

export const getLabDepts = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(labDeptGetRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/labDept/getAll`,

			config
		);
		console.log(data);
		dispatch(labDeptGetSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			labDeptGetFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getTestNameFromDept = (deptId, testIdx) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getTestNameRequest());
		console.log(deptId + "line1089");
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/labDept/getTestNameForDept`,
			{ deptId, testIdx },
			config
		);
		console.log(data);
		dispatch(getTestNameSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getTestNameFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const createLabTech =
	(
		name,
		mobilenumber,
		email,
		gender,
		dob,
		address,
		deptNameArray,
		deptIdArray
	) =>
	async (dispatch) => {
		try {
			dispatch(createLabTechnicianStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/admin/addlabTech`,
				{
					name,
					mobilenumber,
					email,
					address,
					dob,
					gender,
					deptNameArray,
					deptIdArray,
				},
				config
			);

			console.log(data);
			dispatch(createLabTechnicianSuccess(data));
			dispatch(allMessagesSuccess("Lab Technician Succesfully created"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			console.log(error);
			dispatch(
				createLabTechnicianFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const createLabTest =
	(deptId, testName, testPrice, referralAmount, testDesc, newTestIdx) =>
	async (dispatch) => {
		try {
			dispatch(createLabTestRequest());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/addTestDetails`,
				{
					deptId,
					testName,
					testPrice,
					referralAmount,
					testDesc,
					newTestIdx,
				},
				config
			);

			console.log(data);
			dispatch(createLabTestSuccess(data));
			dispatch(allMessagesSuccess("Lab Test Succesfully created"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			console.log(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			);
			dispatch(
				createLabTestFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const editUser =
	(
		_id,
		name,
		mobilenumber,
		gender,
		dob,
		address,
		patientIndex,
		isRegisterPatient,
		bloodgroupflag,
		bloodGroup
	) =>
	async (dispatch) => {
		try {
			dispatch(editUserRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			console.log("Api called" + name, mobilenumber, gender, dob, address);

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/users/editPatient`,
				{
					_id,
					name,
					mobilenumber,
					gender,
					dob,
					address,
					username,
					patientIndex,
					isRegisterPatient,
					bloodgroupflag,
					bloodGroup,
				},
				config
			);
			dispatch(editUserSuccess(data));

			dispatch(getUserDataByCategorySuccess(data));
			dispatch(allMessagesSuccess("Successfully changed the fields"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				editUserFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const deleteLabDept = (deptId) => async (dispatch) => {
	try {
		console.log("11223344" + deptId);
		dispatch(deleteLabDeptRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/labDept/deleteLabdept`,
			{
				deptId,
			},
			config
		);
		console.log(data);
		dispatch(deleteLabDeptSuccess(data));
		dispatch(allMessagesSuccess("Lab Department Succesfully deleted"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			deleteLabDeptFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const deleteTestInDept =
	(deptId, testName, testIdx) => async (dispatch) => {
		try {
			console.log("11223344" + deptId);
			dispatch(deleteLabTestRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/deleteTestInDept`,
				{
					deptId,
					testName,
					testIdx,
				},
				config
			);
			console.log(data);
			dispatch(deleteLabTestSuccess(data));
			dispatch(allMessagesSuccess("Lab Test Succesfully deleted"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				deleteLabTestFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const deleteTechInDept = (labTechIdid) => async (dispatch) => {
	try {
		dispatch(deleteLabTechRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/labDept/deleteLabTechnician`,
			{
				labTechIdid,
			},
			config
		);
		console.log(data);
		dispatch(deleteLabTechSuccess(data));
		dispatch(allMessagesSuccess("Lab Technician Succesfully deleted"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			deleteLabTechFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getLabTechniciansFromParticularDepartment =
	(deptId) => async (dispatch) => {
		try {
			dispatch(getLabTechRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/getLabTechniciansFromParticularDepartment`,
				{ deptId },
				config
			);
			dispatch(getLabTechSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				getLabTechFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const editLabTest =
	(
		id,
		testName,
		testIdx,
		newTestName,
		newTestPrice,
		newTestReferralAmount,
		newTestDesc
	) =>
	async (dispatch) => {
		try {
			dispatch(editTestDetailsRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/editTest`,
				{
					id,
					testName,
					testIdx,
					newTestName,
					newTestPrice,
					newTestReferralAmount,
					newTestDesc,
				},
				config
			);
			dispatch(editTestDetailsSuccess(data));
			dispatch(allMessagesSuccess("Lab Test Succesfully updated"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				editTestDetailsFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const editLabTechnicianAdmin =
	(technicianId, updatedLabTechnician, deletedDepartments, newDepartments) =>
	async (dispatch) => {
		try {
			console.log(technicianId, updatedLabTechnician);
			dispatch(editLabTechnicianAdminStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/labDept/editLabTechnicianByAdmin`,
				{
					technicianId,
					updatedLabTechnician,
					deletedDepartments,
					newDepartments,
				},
				config
			);
			dispatch(editLabTechnicianAdminSuccess(data));
			dispatch(allMessagesSuccess("Lab Test Succesfully updated"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				editLabTechnicianAdminFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getAllLabTechnicians = () => async (dispatch) => {
	try {
		dispatch(getAllLabTechniciansRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/labDept/getAllLabTechnicians`,

			config
		);
		dispatch(getAllLabTechniciansSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getAllLabTechniciansFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getLabTechnician = (LabTechnicianId) => async (dispatch) => {
	try {
		dispatch(getLabTechnicianRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		console.log(LabTechnicianId + "line1578");
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/labDept/getLabTechnician/${LabTechnicianId}`,

			config
		);
		dispatch(getLabTechnicianSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getLabTechnicianFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const updatePassword = (password) => async (dispatch, getState) => {
	try {
		dispatch(UpdatePasswordStart());
		console.log("getting3221");
		const {
			userLogin: { userInfo },
		} = getState();
		console.log(password);
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};

		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/users/updatePassword`,
			{ password },
			config
		);
		dispatch(UpdatePasswordSuccess(data));
		dispatch(
			allMessagesSuccess("Password updated Successfully. Please login again ")
		);
		dispatch(logout());
		localStorage.removeItem("receptionInfo");
		localStorage.removeItem("doctorInfo");
		localStorage.removeItem("token-user");
		localStorage.removeItem("persist:root");
		persistor.pause();
		persistor.flush().then(() => {
			return persistor.purge();
		});
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		const message =
			error.response && error.response.data.data.message
				? error.response.data.data.message
				: error.message;
		if (message === "Not authorized, token failed") {
			localStorage.removeItem("persist:root");
			dispatch(logout());
		}
		dispatch(UpdatePasswordFailure(message));
	}
};

export const createReferralDoctor =
	(name, mobilenumber, email, gender, address) => async (dispatch) => {
		try {
			dispatch(createReferralDoctorStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/admin/addreferraldoctor`,
				{ name, mobilenumber, email, gender, address },
				config
			);
			dispatch(createReferralDoctorSuccess(data));
			dispatch(allMessagesSuccess("Doctor created successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				createReferralDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const createConsultantDoctor =
	(
		name,
		mobilenumber,
		email,
		gender,
		address,
		opConsultationFee,
		ipConsultationFee,
		ipSurgeryFees
	) =>
	async (dispatch) => {
		try {
			dispatch(createConsultantDoctorStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/ipBill/addconsultantdoctor`,
				{
					name,
					mobilenumber,
					email,
					gender,
					address,
					opConsultationFee,
					ipConsultationFee,
					ipSurgeryFees,
				},
				config
			);
			dispatch(createConsultantDoctorSuccess(data));
			dispatch(allMessagesSuccess("Consultant doctor created successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				createConsultantDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getLabReportId = (id) => async (dispatch) => {
	try {
		dispatch(ViewLabReportDetailsStart());
		// console.log(Notes + "line519");
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/labDept/getLabReportId/${id}`,

			config
		);
		console.log(data);
		dispatch(ViewLabReportDetailsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			ViewLabReportDetailsFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const addVitalInSpeciality = (vitalName) => async (dispatch) => {
	try {
		dispatch(addVitalsStart());
		// console.log(Notes + "line519");
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/speciality/addVitals`,
			{
				vitalName,
			},
			config
		);
		console.log(data);
		dispatch(addVitalsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			addVitalsFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getVitalInSpeciality = (specialityId) => async (dispatch) => {
	try {
		dispatch(getVitalsStart());
		// console.log(Notes + "line519");
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/speciality/getVitals/${specialityId}`,

			config
		);
		console.log(data);
		dispatch(getVitalsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getVitalsFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

//add services to database
export const addService = (name, price) => async (dispatch) => {
	try {
		dispatch(addServiceStart());
		// console.log(Notes + "line519");
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/ipBill/addService`,
			{
				name,
				price,
			},
			config
		);
		console.log(data);
		dispatch(addServiceSuccess(data));
		dispatch(allMessagesSuccess("Item added successfully"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			addServiceFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const getAllService = () => async (dispatch) => {
	try {
		dispatch(getAllServicesRequest());
		// console.log(Notes + "line519");
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/ipBill/getAllServicesDetails`,

			config
		);
		console.log(data);
		dispatch(getAllServicesSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getAllServicesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const getAllAdmittedPatients = () => async (dispatch) => {
	try {
		dispatch(getAllAdmittedPatientsRequest());
		// console.log(Notes + "line519");
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/ipBill/getAllAdmittedPatients`,

			config
		);
		console.log(data);
		dispatch(getAllAdmittedPatientsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getAllAdmittedPatientsFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

//create the ip bill for the first time
export const createIpBill =
	(
		username,
		userIdx,
		phoneNumber,
		userId,
		admissionDate,
		generalServicesUsed,
		DoctorServicesUsed,
		roomHistory,
		amountToBePaid,
		previousPayments,
		inventoryItemsTaken,
		labTests,
		doctorName,
		doctorId,
		patient_pocName,
		patient_pocMobileNumber,
		specialityName,
		specialityId,
		labServicesUsed,
		InventoryServicesUsed,
		stock_expiring_in_one_week,
		stock_expiring_in_one_month,
		stock_expiring_in_three_months,
		stock_expiring_in_six_months
	) =>
	async (dispatch) => {
		try {
			dispatch(createIpBillStart());
			console.log(
				username,
				userIdx,
				phoneNumber,
				userId,
				admissionDate,
				generalServicesUsed,
				DoctorServicesUsed,
				roomHistory,
				amountToBePaid,
				previousPayments,
				inventoryItemsTaken,
				labTests,
				doctorName,
				doctorId,
				patient_pocName,
				patient_pocMobileNumber,
				specialityName,
				specialityId,
				labServicesUsed,
				InventoryServicesUsed,
				stock_expiring_in_one_week,
				stock_expiring_in_one_month,
				stock_expiring_in_three_months,
				stock_expiring_in_six_months
			);
			const token = localStorage.getItem("token-user");
			const username1 = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username1,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/ipBill/createIpBill`,
				{
					username,
					userIdx,
					phoneNumber,
					userId,
					admissionDate,
					generalServicesUsed,
					DoctorServicesUsed,
					roomHistory,
					amountToBePaid,
					previousPayments,
					inventoryItemsTaken,
					labTests,
					doctorName,
					doctorId,
					patient_pocName,
					patient_pocMobileNumber,
					specialityName,
					specialityId,
					labServicesUsed,
					InventoryServicesUsed,
					stock_expiring_in_one_week,
					stock_expiring_in_one_month,
					stock_expiring_in_three_months,
					stock_expiring_in_six_months,
				},
				config
			);
			dispatch(createIpBillSuccess(data));
			dispatch(allMessagesSuccess("Bill created successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				createIpBillFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getIpBill = (billId) => async (dispatch) => {
	try {
		dispatch(getIpBillRequest());
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/ipBill/getIpBill/${billId}`,
			config
		);
		console.log(data);
		dispatch(getIpBillSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getIpBillFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

//save the ip bill
export const saveIpBill =
	(
		ipBillId,
		userIdx,
		userId,
		phoneNumber,
		username,
		generalServicesUsed,
		DoctorServicesUsed,
		roomHistory,
		amountToBePaid,
		previousPayments,
		inventoryItemsTaken,
		stock_expiring_in_one_week,
		stock_expiring_in_one_month,
		stock_expiring_in_three_months,
		stock_expiring_in_six_months,

		labTests,
		doctorName,
		doctorId,
		labServicesUsed,
		InventoryServicesUsed
	) =>
	async (dispatch) => {
		try {
			dispatch(saveIpBillStart());
			console.log(
				ipBillId,
				userIdx,
				userId,
				phoneNumber,
				username,
				generalServicesUsed,
				DoctorServicesUsed,
				roomHistory,
				amountToBePaid,
				previousPayments,
				inventoryItemsTaken,
				stock_expiring_in_one_week,
				stock_expiring_in_one_month,
				stock_expiring_in_three_months,
				stock_expiring_in_six_months,

				labTests,
				doctorName,
				doctorId,
				labServicesUsed,
				InventoryServicesUsed
			);
			const token = localStorage.getItem("token-user");
			const username1 = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username1,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/ipBill/saveIpBill`,
				{
					ipBillId,
					userIdx,
					userId,
					phoneNumber,
					username,
					generalServicesUsed,
					DoctorServicesUsed,
					roomHistory,
					amountToBePaid,
					previousPayments,
					inventoryItemsTaken,
					stock_expiring_in_one_week,
					stock_expiring_in_one_month,
					stock_expiring_in_three_months,
					stock_expiring_in_six_months,
					labTests,
					doctorName,
					doctorId,
					labServicesUsed,
					InventoryServicesUsed,
				},
				config
			);
			dispatch(saveIpBillSuccess(data));
			dispatch(allMessagesSuccess("Bill saved successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				saveIpBillFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const closeIpBill =
	(
		ipBillId,
		userIdx,
		userId,
		phoneNumber,
		username,
		generalServicesUsed,
		DoctorServicesUsed,
		labServicesUsed,
		InventoryServicesUsed,
		roomHistory,
		amountToBePaid,
		previousPayments,
		inventoryItemsTaken,
		stock_expiring_in_one_week,
		stock_expiring_in_one_month,
		stock_expiring_in_three_months,
		stock_expiring_in_six_months,
		AmountPaidNowCash,
		AmountPaidNowOnline,
		discountGivenNow,
		labTests,
		doctorName,
		doctorId,
		dischargeDate
	) =>
	async (dispatch) => {
		try {
			dispatch(closeIpBillStart());
			console.log(
				ipBillId,
				userIdx,
				userId,
				phoneNumber,
				username,
				generalServicesUsed,
				DoctorServicesUsed,
				labServicesUsed,
				InventoryServicesUsed,
				roomHistory,
				amountToBePaid,
				previousPayments,
				inventoryItemsTaken,
				stock_expiring_in_one_week,
				stock_expiring_in_one_month,
				stock_expiring_in_three_months,
				stock_expiring_in_six_months,
				AmountPaidNowCash,
				AmountPaidNowOnline,
				discountGivenNow,
				labTests,
				doctorName,
				doctorId,
				dischargeDate
			);
			const token = localStorage.getItem("token-user");
			const username1 = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username1,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/ipBill/closeIpBill`,
				{
					ipBillId,
					userIdx,
					userId,
					phoneNumber,
					username,
					generalServicesUsed,
					DoctorServicesUsed,
					labServicesUsed,
					InventoryServicesUsed,
					roomHistory,
					amountToBePaid,
					previousPayments,
					inventoryItemsTaken,
					stock_expiring_in_one_week,
					stock_expiring_in_one_month,
					stock_expiring_in_three_months,
					stock_expiring_in_six_months,
					AmountPaidNowCash,
					AmountPaidNowOnline,
					discountGivenNow,
					labTests,
					doctorName,
					doctorId,
					dischargeDate,
				},
				config
			);
			dispatch(closeIpBillSuccess(data));
			dispatch(allMessagesSuccess("Bill created successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				closeIpBillFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};
