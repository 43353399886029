/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import { createSpeciality } from "../../../redux/adminRedux/adminApiCalls";
import Message from "../../../Components/Message";
import { createSpecialityReset } from "../../../redux/adminRedux/Speciality_Management/createSpeciality";
import Form from "react-bootstrap/Form";
import {
  allMessagesStart,
  allMessagesSuccess,
  allMessagesFailure,
  allMessagesReset,
} from "../../../redux/allMessages";
import { Col, Row, Container } from "react-bootstrap";

const AddSpecialityScreens = () => {
  const dispatch = useDispatch();
  const [speciality, setSpeciality] = useState("");
  const [timerRunning, setTimerRunning] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(speciality);
    var letters = /^[A-Za-z\s]+$/;
    if (speciality == "") {
      alert("Speciality cannot be empty");
    } else if (!speciality.match(letters)) {
      alert("Speciality must contain only alphabets");
    } else {
      let temp = window.confirm(
        "Are you sure you want to create " + speciality
      );
      if (temp) {
        e.preventDefault();
        dispatch(createSpeciality(speciality));

        setSpeciality("");
      }
    }
  };

  const [showPopup, setShowPopup] = React.useState(false);
  const createdSpeciality = useSelector((state) => state.createSpeciality);
  const { loading, success, userInfo, error } = createdSpeciality;
  const [successfeedback, setsuccessfeedback] = useState(false);
  // useEffect(() => {
  // 	setsuccessfeedback(true)
  // 	const abcd=() =>{
  // 		setsuccessfeedback(false)
  // 		dispatch(createSpecialityReset())
  // 	}
  // 	setTimeout(abcd(), 5000);
  // },[success,error])
  // useEffect(() => {
  // 	setTimerRunning(true);
  // 	const timer = setTimeout(() => {
  // 		document.getElementById('hi').innerHTML= "Hello"
  // 	}, 3000);
  // },[timerRunning])
  useEffect(() => {
    dispatch(allMessagesStart());
  }, []);
  const alertmsg = useSelector((state) => state.allMessages);
  useEffect(() => {
    if (success && !timerRunning) {
      setTimerRunning(true);

      const timer = setTimeout(() => {
        // Clear the alert and stop the timer after 3 seconds
        dispatch(createSpecialityReset());

        setTimerRunning(false);
      }, 3000);

      // Clean up the timer if the component unmounts or the alert changes
    }
  }, [success, dispatch]);
  console.log(success);

  return (
    <>
      <Meta />

      {loading && <Loader />}

      <h1 className="header-center">Add Speciality</h1>
      <Form className="form-group" onSubmit={submitHandler} noValidate="true">
        <Container className="header-center mt-5">
          <Row className="justify-content-center">
            <Col md="auto" className="text-center mt-1">
              <label htmlFor="name">
                <b>Name </b>
                <span className="text-danger">
                  <b>*</b>
                </span>
              </label>
            </Col>
            <Col md="auto" className="text-center mt-1">
              <input
                className="form-control"
                id="speciality"
                type="text"
                required="required"
                value={speciality}
                onChange={(e) => setSpeciality(e.target.value)}
              />
            </Col>
            <Col md="auto" className="text-center mt-1">
              <button className="btn btn-success">Submit</button>
            </Col>
          </Row>
        </Container>
      </Form>
      {/* <div id='hi'>Hi</div> */}
    </>
  );
};

export default AddSpecialityScreens;
