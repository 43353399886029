/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import SearchPatient from "../../../Components/SearchPatients";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
	getLabDepts,
	getLabTechniciansFromParticularDepartment,
	getLabTechnician,
	editLabTechnicianAdmin,
} from "../../../redux/adminRedux/adminApiCalls";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";

const EditLabTechScreen = () => {
	const dispatch = useDispatch();
	const [openItems, setOpenItems] = useState(false);
	const [editClicked, setEditClicked] = useState(false);

	const [deptName, setDeptName] = useState("Choose Department");
	const [deptId, setDeptId] = useState("");
	const [labTech, setLabTech] = useState("Choose Lab Technician");
	const [labTechId, setLabTechId] = useState("");

	useEffect(() => {
		dispatch(getLabDepts());
	}, []);

	const { getlabDeptAll } = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	const { getLabTechn } = useSelector((state) => state.getLabTech);
	console.log(getLabTechn);

	const handleDeptSelect = (eventKey) => {
		setDeptName(eventKey.split("@@")[0]);
		setDeptId(eventKey.split("@@")[1]);
		dispatch(
			getLabTechniciansFromParticularDepartment(eventKey.split("@@")[1])
		);
		setLabTech("Choose Lab Technician");
		setLabTechId("");
		setEditClicked(false);
	};

	const handleLabTechSelect = (eventKey) => {
		console.log(eventKey);
		setLabTech(eventKey?.split("@@")[0]);
		setLabTechId(eventKey?.split("@@")[1]);
		dispatch(getLabTechnician(eventKey?.split("@@")[1]));
		setEditClicked(false);
	};

	const { getLabTechnicianInfo, loading } = useSelector(
		(state) => state.getLabTechInfo
	);
	console.log(getLabTechnicianInfo);

	const [name, setName] = useState();
	const [mno, setMno] = useState();
	const [department, setDepartment] = useState([]);
	const [departmentIds, setDepartmentIds] = useState([]);
	const [newDepartments, setNewDepartments] = useState([]);

	const [email, setEmail] = useState();
	const [dob, setDob] = useState();
	const [gender, setGender] = useState();
	const [address, setAddress] = useState();

	useEffect(() => {
		setDepartment(getLabTechnicianInfo?.data?.data?.deptNameArray);
		setDepartmentIds(getLabTechnicianInfo?.data?.data?.deptIdArray);
		setName(getLabTechnicianInfo?.data?.data?.name);
		setMno(getLabTechnicianInfo?.data?.data?.mobilenumber);
		setEmail(getLabTechnicianInfo?.data?.data?.email);
		setDob(getLabTechnicianInfo?.data?.data?.dob);
		setGender(getLabTechnicianInfo?.data?.data?.gender);
		setAddress(getLabTechnicianInfo?.data?.data?.address);
	}, [loading]);

	const [deletedDepartments, setDeletedDepartments] = useState([]);

	const handleDeptSelectEdit = (item) => {
		if (!department?.includes(item?.split("@@")[0])) {
			let items = [...department];
			items.push(item?.split("@@")[0]);
			setDepartment(items);
			let deptIds = [...departmentIds];
			deptIds.push(item?.split("@@")[1]);
			setDepartmentIds(deptIds);
			let deleteddepts = [...deletedDepartments];
			let index = deleteddepts.indexOf(item?.split("@@")[1]);
			if (index > -1) {
				deleteddepts.splice(index, 1);
			}
			setDeletedDepartments(deleteddepts);
		}
		if (
			!getLabTechnicianInfo?.data?.data?.deptIdArray?.includes(
				item?.split("@@")[1]
			)
		) {
			let deptes = [...newDepartments];
			deptes.push(item?.split("@@")[1]);
			setNewDepartments(deptes);
		}
	};

	console.log(newDepartments);

	const handleDeleteDept = (dept) => {
		// Create a copy of deletedDepartments array
		let deleteddepts = [...deletedDepartments];

		// Find the index of the department to be deleted
		let index = department.indexOf(dept);

		// If the department is found
		if (index > -1) {
			// Remove the department from the department array
			let sdepts = [...department];
			sdepts.splice(index, 1);
			setDepartment(sdepts);

			// Push the ID of the deleted department to deletedDepartments array
			deleteddepts.push(departmentIds[index]);
			setDeletedDepartments(deleteddepts);

			// Remove the corresponding department ID from departmentIds array
			let sdeptsIds = [...departmentIds];
			sdeptsIds.splice(index, 1);
			setDepartmentIds(sdeptsIds);
		}
	};

	const submitHandler = () => {
		const isValidInput = /^\d+$/.test(mno);
		if (!isValidInput) {
			alert("Only digits are allowed in Mobile Number.");
		} else if (mno?.length < 10 || mno?.length > 10) {
			alert("Mobile Number should be 10 digits only");
		} else {
			let updatedLabTechnician = {
				name: name,
				address: address,
				gender: gender,
				dob: dob,
				mobilenumber: mno,
				email: email,
				deptIdArray: departmentIds,
				deptNameArray: department,
				status: departmentIds?.length === 0 ? 0 : 1,
			};
			dispatch(
				editLabTechnicianAdmin(
					labTechId,
					updatedLabTechnician,
					deletedDepartments,
					newDepartments
				)
			);
			setEditClicked(false);
			dispatch(getLabTechnician(labTechId));
			window.location.reload();
			// setDeptId("");
			// setDeptName("Choose Department");
			// setLabTechId("");
			// setLabTech("Choose Lab Technician");
		}
	};

	const discardChanges = () => {
		setName(getLabTechnicianInfo?.data?.data?.name);
		setMno(getLabTechnicianInfo?.data?.data?.mobilenumber);
		setDepartment(getLabTechnicianInfo?.data?.data?.deptNameArray);
		setEmail(getLabTechnicianInfo?.data?.data?.email);
		setDob(getLabTechnicianInfo?.data?.data?.dob);
		setGender(getLabTechnicianInfo?.data?.data?.gender);
		setAddress(getLabTechnicianInfo?.data?.data?.address);
		setEditClicked(false);
	};
	return (
		<>
			<Meta />

			<h1 className="header-center">Edit Lab Technician</h1>
			<Container className="header-center mt-5">
				<Row className="justify-content-center">
					<Col md="auto" className="text-center mt-1">
						<h6>
							Select the filters{" "}
							<span className="text-danger">
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md="auto" className="text-center mt-1">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={deptName}
							onSelect={handleDeptSelect}
						>
							{getlabDeptAll?.data?.data?.length === 0 ? (
								<Dropdown.Item></Dropdown.Item>
							) : (
								getlabDeptAll?.data?.data?.map(
									(dept, index) =>
										dept.deptName !== "hospital" &&
										dept.status !== 0 && (
											<Dropdown.Item eventKey={dept.deptName + "@@" + dept._id}>
												{dept.deptName}
											</Dropdown.Item>
										)
								)
							)}
						</DropdownButton>
					</Col>
					<Col md="auto" className="text-center mt-1">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={labTech}
							onSelect={handleLabTechSelect}
						>
							{getLabTechn?.data?.data[0]?.labTechsArray?.length === 0 ? (
								<Dropdown.Item></Dropdown.Item>
							) : (
								getLabTechn?.data?.data[0]?.labTechsArray?.map(
									(tech, index) => (
										<Dropdown.Item eventKey={tech.name + "@@" + tech._id}>
											{tech.name}
										</Dropdown.Item>
									)
								)
							)}
						</DropdownButton>
					</Col>
				</Row>
			</Container>
			{deptName !== "Choose Department" &&
				labTech !== "Choose Lab Technician" && (
					<button
						className="btn btn-outline-success offset-8 offset-md-10 mt-2 mr-2"
						onClick={() => setEditClicked(true)}
					>
						<i className="fa fa-edit"></i>
						{"  "}Edit
					</button>
				)}
			{deptName !== "Choose Department" &&
				labTech !== "Choose Lab Technician" && (
					<Container className="card col-md-6">
						<div class="card-body">
							<div className="card-text">
								<div className="row mt-3">
									<div className="col-4 col-md-3 cardHeading">Name:</div>
									{editClicked ? (
										<input
											type="text"
											className="col-8 form-control"
											value={name}
											onChange={(e) => setName(e.target.value)}
											disabled
										/>
									) : (
										<div className="col-8">
											{getLabTechnicianInfo?.data?.data?.name}
										</div>
									)}
								</div>
								<div className="row mt-3">
									<div className="col-4 col-md-3 cardHeading">Mobile No:</div>
									{editClicked ? (
										<input
											type="text"
											className="col-8 form-control"
											value={mno}
											onChange={(e) => setMno(e.target.value)}
										/>
									) : (
										<div className="col-8">
											{getLabTechnicianInfo?.data?.data?.mobilenumber}
										</div>
									)}
								</div>
								{editClicked && (
									<div className="row mt-3">
										<label
											className="form-label col-md-3"
											htmlFor="mobilenumber"
										>
											<b>Department: </b>
											<span className="text-danger">
												<b>*</b>
											</span>
										</label>

										<button
											className="btn btn-secondary"
											onClick={() => setOpenItems(!openItems)}
											aria-controls={`example-collapse-text`}
											aria-expanded={openItems}
										>
											Choose Department <i className="fas fa-caret-down"></i>
										</button>
									</div>
								)}

								<div className="row">
									{editClicked && (
										<Collapse
											className="offset-md-3 col-md-3 dropdown-box dropScroll"
											in={openItems}
											id={`example-collapse-text`}
										>
											<div>
												{getlabDeptAll?.data?.data?.map(
													(dept) =>
														dept?.deptName !== "hospital" && (
															<Dropdown.Item
																onClick={() => {
																	handleDeptSelectEdit(
																		dept?.deptName + "@@" + dept?._id
																	);
																}}
															>
																{dept?.deptName}
															</Dropdown.Item>
														)
												)}
											</div>
										</Collapse>
									)}
								</div>

								<div className="row mt-3">
									<label className="col-4 col-md-3 cardHeading" htmlFor="email">
										{editClicked ? "Selected Departments: " : "Departments"}
									</label>
									{editClicked ? (
										<div
											className="card col-md-8 divScroll"
											style={{ height: "100px" }}
										>
											<div className="row mt-3">
												{Array.isArray(department) &&
													department?.map((dept) => (
														<ButtonGroup
															aria-label="Basic example"
															className="ml-2 mt-2"
														>
															<Button variant="light">{dept}</Button>
															<Button
																variant="danger"
																onClick={() => handleDeleteDept(dept)}
															>
																<i className="fas fa-times"></i>
															</Button>
														</ButtonGroup>
													))}
											</div>
										</div>
									) : (
										<div className="col-8">
											{Array.isArray(department) &&
												department?.map((dept) => (
													<button className="btn btn-secondary mr-1" disabled>
														{dept + " "}
													</button>
												))}
										</div>
									)}
								</div>
								<div className="row mt-3">
									<div className="col-4 col-md-3 cardHeading">Email Id:</div>
									{editClicked ? (
										<input
											type="text"
											className="col-8 form-control"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									) : (
										<div className="col-8">
											{getLabTechnicianInfo?.data?.data?.email}
										</div>
									)}
								</div>
								<div className="row mt-3">
									<div className="col-4 col-md-3 cardHeading">
										Date Of Birth:
									</div>
									{editClicked ? (
										<input
											type="date"
											className="col-8 form-control"
											value={dob}
											onChange={(e) => setDob(e.target.value)}
										/>
									) : (
										<div className="col-8">
											{getLabTechnicianInfo?.data?.data?.dob}
										</div>
									)}
								</div>
								<div className="row mt-3">
									<div className="col-4 col-md-3 cardHeading">Gender:</div>
									{editClicked ? (
										<div className="row">
											<div className="col-6">
												<Form.Check
													onChange={() => {
														setGender("Male");
													}}
													checked={gender === "Male"}
													className="form-control"
													inline
													label="Male"
													type="radio"
													id={`inline-radio-1`}
												/>
											</div>
											<div className="col-6">
												<Form.Check
													onChange={() => {
														setGender("Female");
													}}
													checked={gender === "Female"}
													inline
													label="Female"
													className="form-control"
													type="radio"
													id={`inline-radio-2`}
												/>
											</div>
										</div>
									) : (
										<div className="col-8">
											{getLabTechnicianInfo?.data?.data?.gender}
										</div>
									)}
								</div>

								<div className="row mt-3">
									<div className="col-4 col-md-3 cardHeading">Address:</div>
									{editClicked ? (
										<textarea
											type="text"
											className="col-8 form-control"
											value={address}
											onChange={(e) => setAddress(e.target.value)}
										></textarea>
									) : (
										<div className="col-8">
											{getLabTechnicianInfo?.data?.data?.address}
										</div>
									)}
								</div>
							</div>
						</div>
						{editClicked && (
							<div className="row mt-3">
								<button
									className="btn btn-danger col-4 offset-1 col-md-2 offset-md-4"
									onClick={discardChanges}
								>
									Discard Changes
								</button>
								<button
									className="btn btn-success col-4 col-md-2 offset-1"
									onClick={submitHandler}
								>
									Submit Changes
								</button>
							</div>
						)}
					</Container>
				)}
		</>
	);
};

export default EditLabTechScreen;
