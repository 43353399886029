/** @format */

import { createSlice } from "@reduxjs/toolkit";

const clearSupplierDueBill = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		clearSupplierDueBillStart: (state) => {
			state.loading = true;
		},
		clearSupplierDueBillSuccess: (state, action) => {
			state.loading = false;
			state.clearSupplierDueBill = action.payload;
			state.error = false;
			state.success = true;
		},
		clearSupplierDueBillFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		clearSupplierDueBillReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	clearSupplierDueBillStart,
	clearSupplierDueBillSuccess,
	clearSupplierDueBillFailure,
	clearSupplierDueBillReset,
} = clearSupplierDueBill.actions;
export default clearSupplierDueBill.reducer;
