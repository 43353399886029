/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getScheduledLabTests = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getScheduledLabTestsStart: (state) => {
			state.loading = true;
		},
		getScheduledLabTestsSuccess: (state, action) => {
			state.loading = false;
			state.getScheduledLabtests = action.payload;
			state.error = false;
			state.success = true;
		},
		getScheduledLabTestsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getScheduledLabTestsReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getScheduledLabTestsStart,
	getScheduledLabTestsSuccess,
	getScheduledLabTestsFailure,
	getScheduledLabTestsReset,
} = getScheduledLabTests.actions;
export default getScheduledLabTests.reducer;
