/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getCaseInPatient = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getCaseInPatientStart: (state) => {
			state.loading = true;
		},
		getCaseInPatientSuccess: (state, action) => {
			state.loading = false;
			state.getCaseInPatient = action.payload;
			state.error = false;
			state.success = true;
		},
		getCaseInPatientFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getCaseInPatientReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getCaseInPatientStart,
	getCaseInPatientSuccess,
	getCaseInPatientFailure,
	getCaseInPatientReset,
} = getCaseInPatient.actions;
export default getCaseInPatient.reducer;
