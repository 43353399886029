/** @format */

import { createSlice } from "@reduxjs/toolkit";

const editTestNotesForPatientsInQueue = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		editTestNotesForPatientsInQueueStart: (state) => {
			state.loading = true;
		},
		editTestNotesForPatientsInQueueSuccess: (state, action) => {
			state.loading = false;
			state.editTestNotesForPatientsinQueue = action.payload;
			state.error = false;
			state.success = true;
		},
		editTestNotesForPatientsInQueueFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editTestNotesForPatientsInQueueReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editTestNotesForPatientsInQueueStart,
	editTestNotesForPatientsInQueueSuccess,
	editTestNotesForPatientsInQueueFailure,
	editTestNotesForPatientsInQueueReset,
} = editTestNotesForPatientsInQueue.actions;
export default editTestNotesForPatientsInQueue.reducer;
