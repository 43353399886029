/** @format */

import React, { useState, useEffect } from "react";
import {
	Container,
	Table,
	Button,
	Modal,
	Form,
	Row,
	Col,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
	getAllAdmittedPatients,
	getUserDataByCategory,
	getUserDataInSearchPatient,
} from "../redux/adminRedux/adminApiCalls";
import CryptoJS from "crypto-js";
import { useNavigate, useLocation } from "react-router-dom";
import getUserDataById from "../redux/prescriptionRedux/getUserDataById";
import { updateUserProfileByAdmin } from "../redux/userApiCalls";
import Meta from "./Meta";

const AdmittedPatientsScreen = () => {
	const dispatch = useDispatch();
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const navigate = useNavigate();
	const [admittedPatientsData, setadmittedPatientsData] = useState("");

	const data1 = useSelector((state) => state.getUserdataByCategory);
	const userData = data1.userData;
	let { error, loading, success } = data1;
	let users_array = [];
	const handleModalOpen = () => {
		setModalOpen(true);
		setMno("");
		users_array = [];
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setSearchClicked(false);
		setMno("");
		setUserOption("Select a user");
		setUserIdx("");
	};

	const [mno, setMno] = useState("");
	const [userOptionClicked, setUserOptionClicked] = useState(false);

	const [searchClicked, setSearchClicked] = useState(false);

	console.log(userData);

	if (mno !== "") {
		users_array.push({
			name: userData?.data?.data?.name,
			id: userData?.data?.data?._id,
			bookingidarray: userData?.data?.data?.bookingIdArray,
			labReports: userData?.data?.data?.labReports,
		});
		for (
			let i = 0;
			i < userData?.data?.data?.registered_patients_array?.length;
			i++
		) {
			users_array.push({
				name: userData?.data?.data?.registered_patients_array[i]?.name,
				id: userData?.data?.data?._id,
				bookingidarray:
					userData?.data?.data?.registered_patients_array[i]?.bookingIdArray,
				labReports:
					userData?.data?.data?.registered_patients_array[i]?.labReports,
			});
		}
	}

	const [lgShow, setLgShow] = useState(false);
	const blood_groups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-", "NA"];
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [dob, setdob] = useState(new Date());
	const [bloodGroup, setBloodGroup] = useState("Select a blood group");
	const handleBloodGroup = (eventKey) => {
		setBloodGroup(eventKey);
	};

	const addUserOnSubmit = (e) => {
		e.preventDefault();
		console.log(name + " " + gender + " " + dob + " " + mno);
		var letters = /^[A-Za-z\s]+$/;
		if (name === "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (gender === "") {
			alert("Please select gender, gender cannot be empty");
		} else if (dob === "") {
			alert("Please select dob, dob cannot be empty");
		} else if (new Date(dob) >= new Date(new Date())) {
			alert("Please select proper dob, dob should not exceed todays date");
		} else {
			if (bloodGroup == "Select a blood group") {
				dispatch(
					updateUserProfileByAdmin(name.trim(), gender, dob, mno, "NA", "")
				);
			} else {
				dispatch(
					updateUserProfileByAdmin(
						name.trim(),
						gender,
						dob,
						mno,
						bloodGroup,
						""
					)
				);
			}
			setName("");
			setGender("");
			setdob("");
			setBloodGroup("Select a blood group");
		}
	};

	const [userOption, setUserOption] = useState("Select a user");
	const [userIdx, setUserIdx] = useState("");
	const [userId, setUserId] = useState("");

	const handleSearchPatient = (e) => {
		e.preventDefault();
		setUserOption("Select a user");
		setUserIdx("");
		setUserId("");
		setSearchClicked(true);
		users_array = [];
		dispatch(getUserDataByCategory(mno));
	};

	const handleUserSelect = (eventKey) => {
		setUserOption(eventKey?.split("@@")[0]);
		setUserIdx(eventKey?.split("@@")[1]);
		setUserId(eventKey?.split("@@")[2]);
	};

	const handleStartIPBill = () => {
		if (mno === "") {
			alert("Search for a patient and select a patient");
		} else if (userOption === "Select a user") {
			alert("Select a patient");
		} else {
			const billid = "";
			const pocName = "";
			const pocMno = "";

			const ipPatientData = {
				username: userOption,
				userId: userId,
				userIdx: userIdx,
				billId: billid,
				pocName: pocName,
				pocMno: pocMno,
			};

			const queryParams = new URLSearchParams();

			// Convert historyItems to a JSON string and encode it for the URL
			queryParams.append(
				"IpPatientData",
				encodeURIComponent(JSON.stringify(ipPatientData))
			);

			// Open the new tab with the query parameters
			window.open(`/startIpBill?${queryParams.toString()}`, "_blank");
			handleModalClose();
		}
	};

	const handleEditBill = (billId, pocName, pocMno) => {
		const userid = "";
		const useridx = "";
		const username = "";
		const ipPatientData = {
			username: username,
			userId: userid,
			userIdx: useridx,
			billId: billId,
			pocName: pocName,
			pocMno: pocMno,
		};
		const queryParams = new URLSearchParams();

		// Convert historyItems to a JSON string and encode it for the URL
		queryParams.append(
			"IpPatientData",
			encodeURIComponent(JSON.stringify(ipPatientData))
		);

		// Open the new tab with the query parameters
		window.open(`/startIpBill?${queryParams.toString()}`, "_blank");
	};

	const handleViewBill = (billId, pocName, pocMno) => {
		const userid = "";
		const useridx = "";
		const username = "";
		const ipPatientData = {
			username: username,
			userId: userid,
			userIdx: useridx,
			billId: billId,
			pocName: pocName,
			pocMno: pocMno,
		};
		const queryParams = new URLSearchParams();

		// Convert historyItems to a JSON string and encode it for the URL
		queryParams.append(
			"IpPatientData",
			encodeURIComponent(JSON.stringify(ipPatientData))
		);

		// Open the new tab with the query parameters
		window.open(`/viewIpBill?${queryParams.toString()}`, "_blank");
	};

	const admittedPatients = useSelector((state) => state.getAllPatients);
	useEffect(() => {
		setadmittedPatientsData(
			admittedPatients?.getAllAdmittedPatients?.data?.data?.admittedPatients
		);
		console.log(
			JSON.stringify(admittedPatients?.getAllAdmittedPatients) + "line34"
		);
	}, [admittedPatients?.loading]);

	useEffect(() => {
		dispatch(getAllAdmittedPatients());
	}, []);

	const [columnDefs] = useState([
		{
			headerName: "S.No",
			valueGetter: (params) => params.node.rowIndex + 1,
			sortable: false,
			flex: 1,
		},
		{
			headerName: "Username",
			field: "username",
			filter: "agTextColumnFilter",
			floatingFilter: true,
			flex: 4,
		},
		{
			headerName: "Admission Date",
			field: "admissionDate",
			valueGetter: (params) => formatDateTime(params.data.admissionDate),
			filter: "agDateColumnFilter",
			sortable: true,
			flex: 4,
		},
		{
			headerName: "Paid Amount",
			field: "paidAmount",
			filter: "agNumberColumnFilter",
			sortable: true,
			flex: 3,
		},
		{
			headerName: "Due Amount",
			field: "dueAmount",
			filter: "agNumberColumnFilter",
			sortable: true,
			flex: 3,
		},
		{
			headerName: "Room",
			field: "room",
			filter: "agTextColumnFilter",
			floatingFilter: true,
			flex: 2,
		},
		{
			headerName: "Bed Number",
			field: "bednumber",
			filter: "agTextColumnFilter",
			floatingFilter: true,
			flex: 3,
		},
		{
			headerName: "Action",
			field: "editBill",
			cellRendererFramework: (params) => (
				<>
					<button
						onClick={() =>
							handleViewBill(
								params.data.billId,
								params.data?.patient_pocName,
								params.data?.patient_pocMobileNumber
							)
						}
						className='btn btn-primary btn-sm mr-2'
						title='Edit Bill'>
						<i className='fa fa-eye'></i>
					</button>
					<button
						onClick={() =>
							handleEditBill(
								params.data.billId,
								params.data?.patient_pocName,
								params.data?.patient_pocMobileNumber
							)
						}
						className='btn btn-success btn-sm'
						title='Edit Bill'>
						<i className='fa fa-edit'></i>
					</button>
				</>
			),
			sortable: false,
			filter: false,
			flex: 1,
		},
	]);

	const defaultColDef = {
		flex: 1,
		minWidth: 100,
		filter: true,

		resizable: true,
	};

	const formatDateTime = (input) => {
		if (typeof input === "number") {
			// Handling numeric format like YYYYMMDD
			const date = input.toString();
			const year = date.substring(0, 4);
			const month = date.substring(4, 6);
			const day = date.substring(6, 8);
			return `${day}-${month}-${year}`;
		} else if (typeof input === "string") {
			if (input.includes("T")) {
				// Handling ISO format with time (YYYY-MM-DDTHH:MM:SS)
				const datePart = input.substring(0, 10);
				const timePart = input.substring(11, 19);
				const [year, month, day] = datePart.split("-");
				return `${day}-${month}-${year} ${timePart}`;
			} else if (input.match(/^\d{4}-\d{2}-\d{2}$/)) {
				// Handling date-only format (YYYY-MM-DD)
				const [year, month, day] = input.split("-");
				return `${day}-${month}-${year}`;
			}
		}

		throw new Error("Unsupported date/time format");
	};

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	const today = getCurrentDateIST();

	return (
		<>
			<Meta />
			<h1 className='header-center mt-3'>Admitted Patients</h1>
			<Container className='header-center mt-3'>
				<Row className='justify-content-end'>
					<button className='btn btn-success' onClick={handleModalOpen}>
						Admit New Patient
					</button>
				</Row>
			</Container>

			<Row className='justify-content-center mt-3'>
				<div
					className='ag-theme-alpine'
					style={{ width: "90%", height: "600px" }}>
					<AgGridReact
						rowData={admittedPatientsData}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						animateRows={true}
						pagination={true}
						paginationPageSize={10}
					/>
				</div>
			</Row>
			<Modal show={modalOpen} onHide={handleModalClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>Search Patient by Mobile Number</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form className='row form-group' onSubmit={handleSearchPatient}>
						<Container className='header-center mt-5'>
							<Row className='justify-content-center'>
								<Col md='auto' className='text-center'>
									<h6>
										Enter Patient's Mobile Number{" "}
										<span className='text-danger'>
											<b>*</b>
										</span>
									</h6>
								</Col>
								<Col md='auto' className='text-center'>
									<input
										className='form-control'
										type='text'
										placeholder='9999999999'
										id='mobilenumber'
										required='required'
										onChange={(e) => {
											setMno(e.target.value);
											setSearchClicked(false);
										}}
										minlength='10'
										maxlength='10'
										pattern='^[0-9]*$'
									/>
								</Col>
								<Col md='auto' className='text-center'>
									<button type='submit' className='btn btn-primary'>
										Search
									</button>
								</Col>

								<Col md='auto' className='text-center'>
									{error && searchClicked && (
										<a
											role='button'
											href='/patientManagement/registerPatient'
											target='_blank'
											type='submit'
											className='btn btn-danger mt-2'>
											Register new Patient
										</a>
									)}
								</Col>
							</Row>
						</Container>
					</form>
					{searchClicked && !error && (
						<Container className='header-center mt-5 mb-5 card col-md-9'>
							<Row className='mt-4 mb-4'>
								<Col md='auto' className='text-center mt-1'></Col>
								<Col md='auto' className='text-center mt-1'>
									<h6>
										Select a user{" "}
										<span className='text-danger'>
											<b>*</b>
										</span>
									</h6>
								</Col>
								<Col md='auto' className='text-center mt-1'>
									<DropdownButton
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={userOption}
										onSelect={handleUserSelect}>
										{error === "No patient exists with that mobile number" ? (
											<Dropdown.Item></Dropdown.Item>
										) : (
											users_array.map((users, index) => (
												<Dropdown.Item
													eventKey={
														users.name + "@@" + index + "@@" + users.id
													}>
													{users.name}
												</Dropdown.Item>
											))
										)}
									</DropdownButton>
								</Col>
								<Col md='auto' className='text-center mt-1'>
									{users_array.length >= 6 ? (
										<Button disabled className='btn btn-danger'>
											Users limit reached
										</Button>
									) : (
										<Button
											className='btn btn-secondary'
											onClick={() => setLgShow(true)}>
											Add User
										</Button>
									)}
								</Col>
							</Row>
						</Container>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleModalClose}>
						Close
					</Button>
					<Button variant='success' onClick={handleStartIPBill}>
						Start IP Bill
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				size='lg'
				show={lgShow}
				onHide={() => setLgShow(false)}
				aria-labelledby='example-modal-sizes-title-lg'>
				<Modal.Header closeButton>
					<Modal.Title id='example-modal-sizes-title-lg'>
						Add New User
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form className='form-group' onSubmit={addUserOnSubmit}>
						<div className='row mt-3'>
							<label className='col-3 col-md-3' htmlFor='name'>
								<b>Name </b>{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
							<input
								className='form-control col-4'
								id='name'
								type='name'
								required='required'
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div className='row mt-3'>
							<label htmlFor='gender' className='col-4 col-md-3'>
								<b>Gender </b>{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
							<div className='row'>
								<b>
									<Form.Check
										onChange={() => {
											setGender("Male");
										}}
										checked={gender === "Male"}
										className='form-check col-5 col-md-4'
										inline
										label='Male'
										type='radio'
										id={`inline-radio-1`}
									/>
									<Form.Check
										onChange={() => {
											setGender("Female");
										}}
										checked={gender === "Female"}
										inline
										label='Female'
										className='form-check col-5 col-md-4'
										type='radio'
										id={`inline-radio-2`}
									/>
								</b>
							</div>
						</div>
						<div className='row mt-3'>
							<label htmlFor='date' className='col-md-3'>
								<b>Date of Birth </b>{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
							<input
								type='date'
								className='col-md-4 form-control'
								selected={dob}
								value={dob}
								onChange={(e) => setdob(e.target.value)}
								max={today}
							/>
						</div>
						<div className='row mt-3'>
							<label for='bloodgroup' className='col-md-3'>
								<b>Blood Group:</b>
							</label>
							<DropdownButton
								className='col-md-1 btn'
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={bloodGroup}
								onSelect={handleBloodGroup}>
								{blood_groups.map((blood) => (
									<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
								))}
							</DropdownButton>
						</div>
						<div>
							<Button
								className='offset-5 offset-md-6 mt-5 btn'
								type='submit'
								onClick={() => setLgShow(false)}>
								Add
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default AdmittedPatientsScreen;
