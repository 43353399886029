/** @format */

// /** @format */

// import { createSlice, nanoid } from '@reduxjs/toolkit';
// import Message from './Message';
// import React, {
// 	useEffect,
// 	useState,
// 	useCallback,
// 	useRef,
// 	useMemo,
// } from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import Loader from './Loader';
// import Meta from './Meta';
// import { useNavigate } from 'react-router-dom';
// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';
// import Tooltip from 'react-bootstrap/Tooltip';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import { Table } from 'react-bootstrap';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import billTopBar from './billTopBar';
// import { toast } from 'react-toastify';
// import { useReactToPrint } from 'react-to-print';
// import domtoimage from 'dom-to-image';
// import DatePicker from 'react-datepicker';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
// import {
// 	getUserDataByCategory, //
// 	//
// } from '../redux/adminRedux/adminApiCalls';
// import { getUserDataByCategoryReset } from '../redux/adminRedux/Patient_Management/getUserDataByCategory';
// import Autocomplete from './AutoComplete';

// const BillingScreen = () => {
// 	const dispatch = useDispatch();
// 	const history = useNavigate();
// 	const { doctorInfo } = useSelector((state) => state.doctorLogin);
// 	const { receptionInfo } = useSelector((state) => state.receptionLogin);
// 	const { adminInfo } = useSelector((state) => state.adminLogin);
// 	const [isViewMode, setIsViewMode] = useState(false);
// 	const { userData, error, loading, success } = useSelector(
// 		(state) => state.getUserdataByCategory
// 	);
// 	const suggestions = [
// 		{ name: 'apple', amount: 120 },
// 		{ name: 'abbbb', amount: 1200 },
// 		{ name: 'acccc', amount: 10 },
// 		{ name: 'addddd', amount: 170 },
// 		{ name: 'banana', amount: 190 },
// 		{ name: 'grape', amount: 120 },
// 		{ name: 'orange', amount: 20 },
// 		{ name: 'mango', amount: 160 },
// 		{ name: 'strawberry', amount: 200 },
// 		{ name: 'blueberry', amount: 250 },
// 	];

// 	const [invoiceForm, setInvoiceForm] = useState({
// 		id: nanoid(),
// 		invoiceNo: '123456789',
// 		statusIndex: '1',
// 		statusName: 'Draft',
// 		totalAmount: 1200,
// 		dueDate: new Date(),
// 		createdDate: new Date(),
// 		currencyUnit: 'Rupees',
// 		products: [
// 			{
// 				amount: 1200,
// 				id: 'D9vPlvwg11cxYJToEf3x4',
// 				name: 'productName',
// 				productID: '',
// 				quantity: 1,
// 			},
// 		],
// 		taxes: [],
// 		clientDetail: {
// 			id: '',
// 			name: '',
// 			mobileNo: '',
// 			email: '',
// 			image: '',
// 			billingAddress: '',
// 		},
// 	});
// 	const [hospitalDetail, setHospitalDetail] = useState({
// 		id: '',
// 		hospitalName: 'Test name',
// 		hospitalMobile: 'Test number',
// 		hospitalEmail: 'Test mail',
// 		image: '',
// 		billingAddress: 'Test address',
// 	});
// 	const [isExporting, setIsExporting] = useState(false);
// 	const [nextDate, setNextDate] = useState('');
// 	const [mno, setMno] = useState('');
// 	const [searchClicked, setSearchClicked] = useState(false);
// 	const [userOptionClicked, setUserOptionClicked] = useState(false);
// 	const [editClicked, setEditClicked] = useState(false);
// 	const [activeUser, setActiveUser] = useState(null);
// 	const [uname, setUname] = useState('');
// 	const [name, setName] = useState('');
// 	const [uid, setUid] = useState('');
// 	const [userIdx, setUserIdx] = useState('');
// 	const [inputValue, setInputValue] = useState('');

// 	function getCurrentDateIST() {
// 		const now = new Date();
// 		const ISTOffset = 330; // IST offset is UTC+5:30
// 		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
// 		return ISTTime.toISOString().split('T')[0];
// 	}
// 	const today = getCurrentDateIST();

// 	const componentRef = useRef(null);
// 	const reactToPrintContent = useCallback(() => {
// 		return componentRef.current;
// 	}, []);
// 	const toggleViewMode = useCallback(() => {
// 		if (invoiceForm.statusIndex !== '1' && isViewMode) {
// 			toast.warn('You can only edit on Draft Mode', {
// 				position: 'bottom-center',
// 				autoClose: 3000,
// 			});
// 			return;
// 		}
// 		setIsViewMode((prev) => !prev);
// 	}, [invoiceForm, isViewMode]);
// 	// const openSettingModal = useCallback(() => {
// 	// 	if (invoiceForm.statusIndex !== '1' && isViewMode) {
// 	// 		toast.warn('You can only change Setting on Draft Mode', {
// 	// 			position: 'bottom-center',
// 	// 			autoClose: 3000,
// 	// 		});
// 	// 		return;
// 	// 	}
// 	// 	dispatch(setSettingModalOpen(true));
// 	// }, [dispatch, invoiceForm, isViewMode]);
// 	const handlePrint = useReactToPrint({
// 		content: reactToPrintContent,
// 		documentTitle: 'Invoice Letter',
// 		onAfterPrint: useCallback(() => {
// 			setIsExporting(false);
// 		}),
// 		removeAfterPrint: true,
// 	});
// 	const handleExport = useCallback(() => {
// 		setIsViewMode(true);
// 		setIsExporting(true);
// 		setTimeout(() => {
// 			handlePrint();
// 		}, 3000);
// 	}, [handlePrint]);

// 	const handleDownloadImg = useCallback(() => {
// 		setIsViewMode(true);
// 		setIsExporting(true);
// 		domtoimage
// 			.toJpeg(componentRef.current, { quality: 1 })
// 			.then(async (dataUrl) => {
// 				try {
// 					const res = await fetch(dataUrl);
// 					const blob = await res.blob();
// 					let a = document.createElement('a');
// 					a.href = URL.createObjectURL(blob);
// 					a.download = 'invoice.jpeg';
// 					a.hidden = true;
// 					document.body.appendChild(a);
// 					a.click();
// 					a.remove();
// 				} catch (e) {
// 					console.log(e);
// 				} finally {
// 					setIsExporting(false);
// 				}
// 			});
// 	});
// 	const handlerInvoiceClientValue = useCallback((event, keyName) => {
// 		const value =
// 			typeof event === 'string' ? new Date(event) : event?.target?.value;

// 		setInvoiceForm((prev) => {
// 			return {
// 				...prev,
// 				clientDetail: { ...prev.clientDetail, [keyName]: value },
// 			};
// 		});
// 	}, []);
// 	const handlerInvoiceValue = useCallback((event, keyName) => {
// 		const value =
// 			typeof event === 'string' ? new Date(event) : event?.target?.value;

// 		setInvoiceForm((prev) => {
// 			return { ...prev, [keyName]: value };
// 		});
// 	}, []);

// 	const sumProductTotal = (updateProducts) => {
// 		const total =
// 			updateProducts.reduce((prev, product) => {
// 				const strValue = (product.quantity * product.amount)
// 					.toFixed(4)
// 					.toString()
// 					.slice(0, -2);

// 				return parseFloat(strValue) + prev;
// 			}, 0) || 0;
// 		return total;
// 	};

// 	const getTotalTaxesWithPercent = (taxes, subTotalAmount) => {
// 		const isFindIndex = taxes.findIndex((tax) => tax.type === 'percentage');
// 		if (isFindIndex !== -1) {
// 			let updatedTaxes = [...taxes];
// 			const amount = (taxes[isFindIndex].value / 100) * subTotalAmount;
// 			updatedTaxes[isFindIndex] = {
// 				...updatedTaxes[isFindIndex],
// 				amount,
// 			};
// 			return [...updatedTaxes];
// 		}
// 		return taxes;
// 	};

// 	const addEmptyProduct = useCallback(() => {
// 		const emptyProduct = {
// 			id: nanoid(),
// 			name: '',
// 			productID: '',
// 			amount: 1,
// 			quantity: 0,
// 		};

// 		setInvoiceForm((prev) => {
// 			let updatedData = { ...prev };
// 			const updateProducts = [...prev.products, emptyProduct];
// 			const subTotalAmount = sumProductTotal(updateProducts);
// 			const updateTaxes = getTotalTaxesWithPercent(prev.taxes, subTotalAmount);
// 			updatedData.products = updateProducts;
// 			updatedData.taxes = updateTaxes;
// 			updatedData.totalAmount = sumTotalAmount(
// 				subTotalAmount,
// 				sumTotalTaxes(updateTaxes)
// 			);
// 			return updatedData;
// 		});
// 	}, []);

// 	const handlerTaxesValue = useCallback(
// 		(event, keyName, taxID) => {
// 			const value = event.target.value;
// 			let updateTaxes = [...invoiceForm.taxes];
// 			const isFindIndex = updateTaxes.findIndex((prod) => prod.id === taxID);
// 			if (isFindIndex === -1) {
// 				return;
// 			}
// 			const currentTax = updateTaxes[isFindIndex];

// 			if (currentTax.type === 'percentage' && keyName === 'value') {
// 				if (!`${value}`.match(/^[0-9]\d*(\.\d+)?$/)) {
// 					return;
// 				}

// 				if (value <= 0 || value > 100) {
// 					return;
// 				}
// 			}

// 			if (currentTax.type === 'flat' && keyName === 'value') {
// 				if (!`${value}`.match(/^[0-9]\d*(\.\d+)?$/)) {
// 					return;
// 				}

// 				if (value <= 0) {
// 					return;
// 				}
// 			}

// 			setInvoiceForm((prev) => {
// 				let taxes = [...prev.taxes];

// 				if (keyName === 'value') {
// 					const subTotalAmount = sumProductTotal(prev.products);
// 					const amount = (value / 100) * subTotalAmount;
// 					taxes[isFindIndex] = {
// 						...taxes[isFindIndex],
// 						[keyName]: value,
// 						amount: currentTax.type !== 'percentage' ? value : amount,
// 					};
// 					const totalAmount = sumTotalAmount(
// 						subTotalAmount,
// 						sumTotalTaxes(taxes)
// 					);
// 					return { ...prev, taxes: taxes, totalAmount: totalAmount };
// 				} else {
// 					taxes[isFindIndex] = {
// 						...taxes[isFindIndex],
// 						[keyName]: value,
// 					};
// 					return { ...prev, taxes: taxes };
// 				}
// 			});
// 		},
// 		[invoiceForm]
// 	);
// 	const sumTotalAmount = (subTotal, taxAmount) => {
// 		const total = parseFloat(subTotal) + parseFloat(taxAmount);

// 		return Number.isInteger(total)
// 			? total
// 			: total?.toFixed(4)?.toString()?.slice(0, -2);
// 	};
// 	const sumTotalTaxes = (taxes) => {
// 		return (
// 			taxes?.reduce((prev, tx) => {
// 				return prev + parseFloat(tx.amount);
// 			}, 0) || 0
// 		);
// 	};
// 	const onDeleteProduct = useCallback((prodID) => {
// 		setInvoiceForm((prev) => {
// 			let updatedData = { ...prev };
// 			const updateProducts = prev.products.filter((prod) => prod.id !== prodID);
// 			const subTotalAmount = sumProductTotal(updateProducts);
// 			const updateTaxes = getTotalTaxesWithPercent(prev.taxes, subTotalAmount);
// 			updatedData.products = updateProducts;
// 			updatedData.taxes = updateTaxes;
// 			updatedData.totalAmount = sumTotalAmount(
// 				subTotalAmount,
// 				sumTotalTaxes(updateTaxes)
// 			);
// 			return updatedData;
// 		});
// 	}, []);

// 	const addEmptyTax = useCallback(() => {
// 		setInvoiceForm((prev) => {
// 			const subTotalAmount = sumProductTotal(prev.products);
// 			const emptyTax = {
// 				id: nanoid(),
// 				title: 'Extra Fees',
// 				type: 'flat',
// 				value: 1,
// 				amount: 1,
// 			};
// 			const updateTaxes = [...prev.taxes, emptyTax];
// 			const totalAmount = sumTotalAmount(
// 				subTotalAmount,
// 				sumTotalTaxes(updateTaxes)
// 			);
// 			return { ...prev, taxes: updateTaxes, totalAmount };
// 		});
// 	}, []);

// 	const addPercentageTax = useCallback(() => {
// 		const isSomeTaxes = invoiceForm.taxes.some(
// 			(form) => form.type === 'percentage'
// 		);

// 		if (isSomeTaxes) {
// 			toast.error('Already Have Percentage Taxes!', {
// 				position: 'bottom-center',
// 				autoClose: 2000,
// 			});
// 			return;
// 		}

// 		setInvoiceForm((prev) => {
// 			const subTotalAmount = sumProductTotal(prev.products);
// 			const amount = (10 / 100) * subTotalAmount;
// 			const percentageTax = {
// 				id: nanoid(),
// 				title: 'Tax %',
// 				type: 'percentage',
// 				value: 10,
// 				amount,
// 			};
// 			const updateTaxes = [percentageTax, ...prev.taxes];
// 			const totalAmount = sumTotalAmount(
// 				subTotalAmount,
// 				sumTotalTaxes(updateTaxes)
// 			);

// 			return {
// 				...prev,
// 				taxes: updateTaxes,
// 				totalAmount: totalAmount,
// 			};
// 		});
// 	}, [invoiceForm]);

// 	const onDeleteTax = useCallback((taxID) => {
// 		setInvoiceForm((prev) => {
// 			const updateTaxes = prev.taxes.filter((prod) => prod.id !== taxID);
// 			let updatedData = { ...prev, taxes: updateTaxes };
// 			const subTotalAmount = sumProductTotal(prev.products);
// 			const totalAmount = sumTotalAmount(
// 				subTotalAmount,
// 				sumTotalTaxes(updateTaxes)
// 			);
// 			updatedData.totalAmount = totalAmount;
// 			return updatedData;
// 		});
// 	}, []);

// 	const totalPercentTax = useMemo(() => {
// 		const isSomeTax = invoiceForm?.taxes?.some(
// 			(tax) => tax.type === 'percentage'
// 		);

// 		if (!isSomeTax) {
// 			return 0;
// 		}

// 		const findIndex = invoiceForm?.taxes?.findIndex(
// 			(tax) => tax.type === 'percentage'
// 		);

// 		return findIndex !== -1
// 			? Number.isInteger(invoiceForm.taxes[findIndex].amount)
// 				? invoiceForm.taxes[findIndex].amount
// 				: invoiceForm.taxes[findIndex].amount.toFixed(4).toString().slice(0, -2)
// 			: 0;
// 	}, [invoiceForm]);
// 	const [selectedItem, setSelectedItem] = useState({
// 		name: '',
// 		amount: 0,
// 		quantity: 1,
// 	});
// 	const onClick = (e, productId) => {
// 		const selected = suggestions.find(
// 			(suggestion) => suggestion.name === e.target.innerText
// 		);
// 		console.log(selected, 'line437');
// 		setSelectedItem(selected);
// 		setFilteredSuggestions([]);
// 		setInputValue(e.target.innerText);
// 		setShowSuggestions(false);
// 		setActiveSuggestion(0);
// 		let updatedData = { ...invoiceForm };
// 		let updateProducts = [...invoiceForm.products];

// 		const isFindIndex = updateProducts.findIndex(
// 			(prod) => prod.id === productId
// 		);
// 		console.log(updateProducts, 'line479');
// 		if (isFindIndex !== -1) {
// 			updateProducts[isFindIndex] = {
// 				...updateProducts[isFindIndex],
// 				['name']: e,
// 				['amount']: selectedItem.amount,
// 				['quantity']: selectedItem.quantity,
// 			};

// 			updatedData.products = [...updateProducts];
// 		}
// 	};
// 	const handlerProductValue = useCallback(
// 		(event, keyName, productID) => {
// 			const value = event.target.value;
// 			const filtered = suggestions.filter(
// 				(suggestion) =>
// 					suggestion.name.toLowerCase().indexOf(value.toLowerCase()) > -1
// 			);
// 			setInputValue(value);
// 			setFilteredSuggestions(filtered);
// 			setShowSuggestions(true);
// 			setActiveSuggestion(0);
// 			// If Keyname Price or Quantity must be only number
// 			if (keyName === 'quantity') {
// 				if (!`${value}`.match(/^\d+$/)) {
// 					return;
// 				}
// 			}

// 			if (keyName === 'amount') {
// 				if (!`${value}`.match(/^[0-9]\d*(\.\d+)?$/)) {
// 					return;
// 				}
// 			}

// 			// Quantity Zero Case
// 			if ((keyName === 'quantity' || keyName === 'amount') && value <= 0) {
// 				return;
// 			}

// 			let updatedData = { ...invoiceForm };
// 			let updateProducts = [...invoiceForm.products];

// 			const isFindIndex = updateProducts.findIndex(
// 				(prod) => prod.id === productID
// 			);
// 			console.log(updateProducts, 'line479');
// 			if (isFindIndex !== -1) {
// 				updateProducts[isFindIndex] = {
// 					...updateProducts[isFindIndex],
// 					[keyName]: value,
// 					['amount']: selectedItem.amount,
// 					['quantity']: selectedItem.quantity,
// 				};

// 				updatedData.products = [...updateProducts];
// 			}

// 			if (keyName === 'quantity' || keyName === 'amount') {
// 				const subTotalAmount = sumProductTotal(updateProducts);
// 				const updateTaxes = getTotalTaxesWithPercent(
// 					invoiceForm.taxes,
// 					subTotalAmount
// 				);
// 				updatedData.taxes = updateTaxes;
// 				updatedData.totalAmount = sumTotalAmount(
// 					subTotalAmount,
// 					sumTotalTaxes(updateTaxes)
// 				);
// 			}
// 			setInvoiceForm(updatedData);
// 		},
// 		[invoiceForm]
// 	);
// 	const subTotal = useMemo(() => {
// 		if (!invoiceForm) {
// 			return 0;
// 		}

// 		if (!invoiceForm?.products) {
// 			return 0;
// 		}
// 		return sumProductTotal(invoiceForm.products);
// 	}, [invoiceForm]);
// 	const handleSearchPatient = (e) => {
// 		e.preventDefault();
// 		dispatch(getUserDataByCategory(mno));
// 		setSearchClicked(true);
// 		setUserOptionClicked(false);
// 		setEditClicked(false);
// 		setUname('');
// 	};
// 	const handelUserSelect = useCallback(
// 		(id, bookingidarray, name, index) => {
// 			let tempClientDetail = {
// 				id: '',
// 				name: users_array[index]?.name,
// 				mobileNo: userData?.data?.data?.mobilenumber,
// 				email: '',
// 				image: '',
// 				billingAddress: userData?.data?.data?.address,
// 			};
// 			let updatedData = { ...invoiceForm };
// 			updatedData.clientDetail = tempClientDetail;
// 			setInvoiceForm(updatedData);
// 			setUserOptionClicked(true);
// 			setActiveUser(index);
// 			setEditClicked(false);
// 			setName(users_array[index]?.name);
// 			setUname(users_array[index]?.name);
// 			setUid(id);
// 			setUserIdx(index);
// 		},
// 		[invoiceForm]
// 	);
// 	let users_array = [];
// 	if (mno !== '') {
// 		users_array.push({
// 			name: userData?.data?.data?.name,
// 			dob: userData?.data?.data?.dob,
// 			gender: userData?.data?.data?.gender,
// 			id: userData?.data?.data?._id,
// 			bid: userData?.data?.data?.bookingId,
// 			vitals: userData?.data?.data?.vitals,
// 		});
// 		for (
// 			let i = 0;
// 			i < userData?.data?.data?.registered_patients_array?.length;
// 			i++
// 		) {
// 			users_array.push({
// 				name: userData?.data?.data?.registered_patients_array[i]?.name,
// 				id: userData?.data?.data?._id,
// 				bid: userData?.data?.data?.registered_patients_array[i]?.bookingId,
// 				dob: userData?.data?.data?.registered_patients_array[i]?.dob,
// 				gender: userData?.data?.data?.registered_patients_array[i]?.gender,
// 				vitals: userData?.data?.data?.registered_patients_array[i]?.vitals,
// 			});
// 		}
// 	}
// 	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
// 	const [showSuggestions, setShowSuggestions] = useState(false);
// 	const [activeSuggestion, setActiveSuggestion] = useState(0);

// 	const onKeyDown = (e, productId) => {
// 		if (e.keyCode === 13) {
// 			// Enter key
// 			setInputValue(filteredSuggestions[activeSuggestion].name);
// 			setShowSuggestions(false);
// 		} else if (e.keyCode === 38) {
// 			// Up arrow
// 			if (activeSuggestion === 0) {
// 				return;
// 			}
// 			setActiveSuggestion(activeSuggestion - 1);
// 		} else if (e.keyCode === 40) {
// 			// Down arrow
// 			if (activeSuggestion + 1 === filteredSuggestions.length) {
// 				return;
// 			}
// 			setActiveSuggestion(activeSuggestion + 1);
// 		}
// 		handlerProductValue(e, 'name', productId);
// 	};
// 	const renderSuggestions = (productId) => {
// 		if (showSuggestions && inputValue) {
// 			if (filteredSuggestions.length) {
// 				return (
// 					<ul className='suggestions'>
// 						{filteredSuggestions.map((suggestion, index) => {
// 							let className;
// 							if (index === activeSuggestion) {
// 								className = 'suggestion-active';
// 							}
// 							return (
// 								<li
// 									key={suggestion.name}
// 									className={className}
// 									onClick={(e) => onClick(e, productId)}>
// 									{suggestion.name}
// 								</li>
// 							);
// 						})}
// 					</ul>
// 				);
// 			} else {
// 				return (
// 					<div className='no-suggestions'>
// 						<em>No suggestions available.</em>
// 					</div>
// 				);
// 			}
// 		}
// 		return null;
// 	};

// 	return (
// 		<div>
// 			{loading && <Loader />}
// 			<form className='form-group' onSubmit={handleSearchPatient}>
// 				<Container className='header-center mt-5'>
// 					<Row className='justify-content-center'>
// 						<Col md='auto' className='text-center'>
// 							<h6>
// 								Enter Patient's Mobile Number{' '}
// 								<span className='text-danger'>
// 									<b>*</b>
// 								</span>
// 							</h6>
// 						</Col>
// 						<Col md='auto' className='text-center'>
// 							<input
// 								className='form-control'
// 								type='text'
// 								placeholder='9999999999'
// 								id='mobilenumber'
// 								required='required'
// 								onChange={(e) => setMno(e.target.value)}
// 								minlength='10'
// 								maxlength='10'
// 								pattern='^[0-9]*$'
// 							/>
// 						</Col>
// 						<Col md='auto' className='text-center'>
// 							<Button type='submit' id='search' className='btn btn-primary'>
// 								Search
// 							</Button>
// 						</Col>
// 					</Row>
// 				</Container>
// 			</form>
// 			<div className='row offset-md-2'>
// 				{!error &&
// 					searchClicked &&
// 					userData &&
// 					users_array.map((users, index) =>
// 						users.name === uname ? (
// 							<button
// 								onClick={() =>
// 									handelUserSelect(
// 										users.id,
// 										users.bookingidarray,
// 										users.name,
// 										index
// 									)
// 								}
// 								className='btn btn-secondary col-md-3 m-1'>
// 								{users.name}
// 							</button>
// 						) : (
// 							<button
// 								onClick={() =>
// 									handelUserSelect(
// 										users.id,
// 										users.bookingidarray,
// 										users.name,
// 										index
// 									)
// 								}
// 								className='btn btn-outline-secondary col-md-3 m-1'>
// 								{users.name}
// 							</button>
// 						)
// 					)}
// 			</div>
// 			<div>
// 				{/* add a back button */}
// 				<div>
// 					<Button>
// 						{/*!{viewMode} ? <>View Mode</> : <>Editing Mode</>*/}
// 						mode
// 					</Button>
// 				</div>
// 			</div>
// 			<div>
// 				<Button>Setting</Button>
// 			</div>
// 			<div>
// 				<Button>Export PDF</Button>
// 			</div>
// 			<div>
// 				<Button>Download Image</Button>
// 			</div>
// 			<div
// 				id='InvoiceWrapper'
// 				ref={componentRef}
// 				style={isExporting ? { width: 1200 } : {}}>
// 				{/* Background Image */}
// 				<div
// 					style={
// 						{
// 							// backgroundImage: `url(${backgroundImage?.base64})`,
// 						}
// 					}>
// 					<div>
// 						{hospitalDetail?.image ? (
// 							<img
// 								className='object-contain h-20 w-20 mr-3 rounded'
// 								alt={hospitalDetail?.hospitalName}
// 								src={hospitalDetail?.image}
// 							/>
// 						) : (
// 							<span></span>
// 						)}

// 						<div>
// 							<p className='font-bold mb-2'>
// 								{hospitalDetail?.hospitalName || 'hospital Name'}
// 							</p>
// 							<p className='text-sm font-medium'>
// 								{hospitalDetail?.billingAddress ||
// 									'Plz add First hospital Data'}
// 							</p>
// 							<p className='text-sm font-medium'>
// 								{hospitalDetail?.hospitalMobile || 'hospital Ph'}
// 							</p>
// 							<p className='text-sm font-medium'>
// 								{hospitalDetail?.hospitalEmail || 'Company@email.com'}
// 							</p>
// 						</div>
// 					</div>
// 					<div className=' mt-5 sm:mt-0'>Invoice</div>
// 				</div>
// 			</div>
// 			<div>
// 				<div>
// 					<div>
// 						<div>Billing To</div>
// 					</div>
// 					<div>
// 						<div>
// 							{!isViewMode ? (
// 								<input
// 									autoComplete='nope'
// 									placeholder='Client Name'
// 									value={invoiceForm?.clientDetail?.name}
// 									onChange={(e) => handlerInvoiceClientValue(e, 'name')}
// 								/>
// 							) : (
// 								invoiceForm?.clientDetail?.name
// 							)}
// 						</div>
// 						<div>
// 							{!isViewMode ? (
// 								<input
// 									autoComplete='nope'
// 									placeholder='Client Address'
// 									value={invoiceForm?.clientDetail?.billingAddress}
// 									onChange={(e) =>
// 										handlerInvoiceClientValue(e, 'billingAddress')
// 									}
// 								/>
// 							) : (
// 								invoiceForm?.clientDetail?.billingAddress
// 							)}
// 						</div>
// 						<div>
// 							{!isViewMode ? (
// 								<input
// 									autoComplete='nope'
// 									placeholder='Client Mobile'
// 									value={invoiceForm?.clientDetail?.mobileNo}
// 									onChange={(e) => handlerInvoiceClientValue(e, 'mobileNo')}
// 								/>
// 							) : (
// 								invoiceForm?.clientDetail?.mobileNo
// 							)}
// 						</div>
// 						<div>
// 							{!isViewMode ? (
// 								<input
// 									autoComplete='nope'
// 									placeholder='Client Email'
// 									value={invoiceForm?.clientDetail?.email}
// 									onChange={(e) => handlerInvoiceClientValue(e, 'email')}
// 								/>
// 							) : (
// 								invoiceForm?.clientDetail?.email
// 							)}
// 						</div>
// 					</div>
// 				</div>
// 				<div className='flex-1'>
// 					<div className='flex flex-row justify-between items-center mb-1'>
// 						<div> INVOICE # </div>
// 						<div>
// 							{!isViewMode ? (
// 								<input
// 									autoComplete='nope'
// 									placeholder='Invoice No'
// 									value={invoiceForm.invoiceNo}
// 									onChange={(e) => handlerInvoiceValue(e, 'invoiceNo')}
// 								/>
// 							) : (
// 								invoiceForm.invoiceNo || '-'
// 							)}
// 						</div>
// 					</div>
// 					<div className='flex flex-row justify-between items-center mb-1'>
// 						<div> Creation Date </div>
// 						<div>
// 							<Col md='12' className='text-center'>
// 								<input
// 									type='date'
// 									className='form-control'
// 									onChange={(e) => setNextDate(e.target.value)}
// 									value={nextDate}
// 								/>
// 							</Col>
// 						</div>
// 					</div>
// 					{!isViewMode && (
// 						<div>
// 							<div> Change Currency </div>
// 							<div>
// 								<input
// 									autoComplete='nope'
// 									placeholder='Invoice No'
// 									value={invoiceForm.currencyUnit}
// 									onChange={(e) => handlerInvoiceValue(e, 'currencyUnit')}
// 								/>
// 							</div>
// 						</div>
// 					)}
// 				</div>
// 			</div>
// 			<div className='py-2 px-4'>
// 				<div
// 					className={
// 						isExporting
// 							? 'flex rounded-lg w-full flex-row px-4 py-1 text-white'
// 							: 'hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white'
// 					}
// 					style={{ backgroundColor: invoiceForm.color }}>
// 					<div
// 						className={
// 							'font-title ' +
// 							(isExporting
// 								? ' text-sm w-1/4 text-right pr-10'
// 								: ' w-full sm:w-1/4 text-right sm:pr-10')
// 						}>
// 						<span className='inline-block'>Description</span>
// 					</div>
// 					<div
// 						className={
// 							'font-title ' +
// 							(isExporting
// 								? ' text-sm  w-1/4 text-right pr-10'
// 								: ' w-full sm:w-1/4 text-right sm:pr-10')
// 						}>
// 						Price
// 					</div>
// 					<div
// 						className={
// 							'font-title ' +
// 							(isExporting
// 								? ' text-sm  w-1/4 text-right pr-10'
// 								: ' w-full sm:w-1/4 text-right sm:pr-10')
// 						}>
// 						Qty
// 					</div>
// 					<div
// 						className={
// 							'font-title' +
// 							(isExporting
// 								? ' text-sm w-1/4 text-right pr-10'
// 								: '  w-full sm:w-1/4 text-right sm:pr-10')
// 						}>
// 						Total
// 					</div>
// 				</div>

// 				<div>
// 					{invoiceForm?.products?.map((product, index) => (
// 						<div key={`${index}_${product.id}`}>
// 							<div>
// 								{!isExporting && <span>Description</span>}
// 								<span>
// 									{!isViewMode ? (
// 										<>
// 											<input
// 												autoComplete='nope'
// 												value={inputValue}
// 												placeholder='Product Name'
// 												onChange={(e) =>
// 													handlerProductValue(e, 'name', product.id)
// 												}
// 												onKeyDown={(e) => onKeyDown(e, product.id)}
// 											/>
// 											{renderSuggestions(product.id)}
// 										</>
// 									) : (
// 										<span>{product.name}</span>
// 									)}
// 								</span>
// 							</div>
// 							<div>
// 								{!isExporting && <span>Price</span>}
// 								<span>
// 									{!isViewMode ? (
// 										<input
// 											autoComplete='nope'
// 											value={product.amount}
// 											placeholder='Price'
// 											type='number'
// 											onChange={(e) =>
// 												handlerProductValue(e, 'amount', product.id)
// 											}
// 										/>
// 									) : (
// 										<span>
// 											<input
// 												value={product.amount}
// 												displayType='text'
// 												thousandSeparator
// 												renderText={(value, props) => (
// 													<span {...props}>{value}</span>
// 												)}
// 											/>
// 										</span>
// 									)}
// 								</span>
// 							</div>
// 							<div>
// 								{!isExporting && <span>Quantity</span>}
// 								<span>
// 									{!isViewMode ? (
// 										<input
// 											autoComplete='nope'
// 											value={product.quantity}
// 											type='number'
// 											placeholder='Quantity'
// 											onChange={(e) =>
// 												handlerProductValue(e, 'quantity', product.id)
// 											}
// 										/>
// 									) : (
// 										<span>
// 											<input
// 												value={product.quantity}
// 												displayType='text'
// 												thousandSeparator
// 												renderText={(value, props) => (
// 													<span {...props}>{value}</span>
// 												)}
// 											/>
// 										</span>
// 									)}
// 								</span>
// 							</div>
// 							<div>
// 								{!isExporting && <span>Total</span>}
// 								<span>
// 									<input
// 										value={
// 											Number.isInteger(product.quantity * product.amount)
// 												? product.quantity * product.amount
// 												: (product.quantity * product.amount)
// 														.toFixed(4)
// 														.toString()
// 														.slice(0, -2)
// 										}
// 										displayType='text'
// 										thousandSeparator
// 										renderText={(value, props) => (
// 											<span {...props}>{value}</span>
// 										)}
// 									/>{' '}
// 									{invoiceForm?.currencyUnit}
// 								</span>
// 							</div>
// 							{!isViewMode && (
// 								<button
// 									className='btn btn-danger'
// 									onClick={() => onDeleteProduct(product.id)}>
// 									<i className='fa fa-trash fa-2x'></i>
// 								</button>
// 							)}
// 						</div>
// 					))}
// 				</div>

// 				<div>
// 					{/* Add Product Actions */}
// 					{!isViewMode && (
// 						<div>
// 							<div>
// 								<Button size='sm' block onClick={addEmptyProduct}>
// 									<i className='fa fa-plus'>Add Empty Product</i>
// 								</Button>
// 							</div>
// 							<div>
// 								<Button>Add Existing Product</Button>
// 							</div>
// 						</div>
// 					)}
// 				</div>
// 				<div>
// 					<div>Subtotal</div>
// 					<div>
// 						<input
// 							value={subTotal}
// 							className='inline-block'
// 							displayType={'text'}
// 							thousandSeparator={true}
// 							renderText={(value, props) => (
// 								<span {...props}>
// 									{value} {invoiceForm?.currencyUnit}
// 								</span>
// 							)}
// 						/>
// 					</div>
// 				</div>
// 				{/* Subtotal Finished */}

// 				{/* Taxes */}
// 				{invoiceForm?.taxes?.map((tax, index) => (
// 					<div key={`${index}_${tax.id}`}>
// 						<div>
// 							<div>Tax Type</div>
// 							<div>
// 								<div>
// 									{!isViewMode && (
// 										<input
// 											autoComplete='nope'
// 											value={tax.title}
// 											type={'text'}
// 											placeholder='Tax Title'
// 											onChange={(e) => handlerTaxesValue(e, 'title', tax.id)}
// 										/>
// 									)}
// 								</div>
// 								<div>
// 									{!isViewMode ? (
// 										<>
// 											<input
// 												autoComplete='nope'
// 												value={tax.value}
// 												type={'number'}
// 												placeholder='Percentage'
// 												onChange={(e) => handlerTaxesValue(e, 'value', tax.id)}
// 											/>
// 											<span className='ml-1'>
// 												{tax.type === 'percentage'
// 													? '%'
// 													: invoiceForm.currencyUnit}
// 											</span>
// 										</>
// 									) : (
// 										<div className='text-right'>{tax.title}</div>
// 									)}
// 								</div>
// 							</div>
// 						</div>
// 						<div>
// 							{!isExporting && <span>Amount</span>}
// 							<span>
// 								<div>
// 									<input
// 										value={
// 											tax.type === 'percentage' ? totalPercentTax : tax.amount
// 										}
// 										className=''
// 										displayType={'text'}
// 										thousandSesparator={true}
// 										renderText={(value, props) => (
// 											<span {...props}>
// 												{value} {invoiceForm?.currencyUnit}
// 											</span>
// 										)}
// 									/>
// 								</div>
// 							</span>
// 						</div>
// 						{!isViewMode && (
// 							<div onClick={() => onDeleteTax(tax.id)}>
// 								<div>
// 									<i className='fa fa-trash fa-2x'></i>
// 									<span>Delete Tax</span>
// 								</div>
// 							</div>
// 						)}
// 					</div>
// 				))}
// 				{/* Taxes Finished*/}

// 				{/* Add Tax Action */}
// 				{!isViewMode && (
// 					<div>
// 						<div>
// 							<Button size='sm' block={1} onClick={addPercentageTax}>
// 								Add Taxes (%)
// 							</Button>
// 						</div>
// 						<div>
// 							<Button size='sm' block={1} onClick={addEmptyTax}>
// 								Add Extra Fee
// 							</Button>
// 						</div>
// 					</div>
// 				)}
// 				{/* Add Tax Action Finished*/}

// 				{/* Subtotal Start */}
// 				<div>
// 					<div style={{ backgroundColor: invoiceForm.color }}>
// 						<div>Total</div>
// 						<div>
// 							<input
// 								value={invoiceForm?.totalAmount}
// 								className=''
// 								displayType={'text'}
// 								thousandSeparator={true}
// 								renderText={(value, props) => (
// 									<span {...props}>
// 										{value}{' '}
// 										<span className={isExporting ? 'text-sm' : 'text-base'}>
// 											{invoiceForm?.currencyUnit}
// 										</span>
// 									</span>
// 								)}
// 							/>
// 						</div>
// 					</div>
// 				</div>
// 				{/* Subtotal Finished */}
// 			</div>
// 		</div>
// 	);
// };
// export default BillingScreen;

// aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa

/** @format */

import { createSlice, nanoid } from '@reduxjs/toolkit';
import Message from './Message';
import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	useMemo,
} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import Meta from './Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import billTopBar from './billTopBar';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import domtoimage from 'dom-to-image';
import DatePicker from 'react-datepicker';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {
	getUserDataByCategory, //
	//
} from '../redux/adminRedux/adminApiCalls';
import { getUserDataByCategoryReset } from '../redux/adminRedux/Patient_Management/getUserDataByCategory';
import Autocomplete from './AutoComplete';

const BillingScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const { adminInfo } = useSelector((state) => state.adminLogin);
	const [isViewMode, setIsViewMode] = useState(false);
	const { userData, error, loading, success } = useSelector(
		(state) => state.getUserdataByCategory
	);
	const suggestions = [
		{ name: 'apple', amount: 120 },
		{ name: 'abbbb', amount: 1200 },
		{ name: 'acccc', amount: 10 },
		{ name: 'addddd', amount: 170 },
		{ name: 'banana', amount: 190 },
		{ name: 'grape', amount: 120 },
		{ name: 'orange', amount: 20 },
		{ name: 'mango', amount: 160 },
		{ name: 'strawberry', amount: 200 },
		{ name: 'blueberry', amount: 250 },
	];
	// const suggestions = [
	// 	'apple',
	// 	'banana',
	// 	'grape',
	// 	'orange',
	// 	'mango',
	// 	'strawberry',
	// 	'blueberry',
	// ];

	const [invoiceForm, setInvoiceForm] = useState({
		id: nanoid(),
		invoiceNo: '123456789',
		statusIndex: '1',
		statusName: 'Draft',
		totalAmount: 1200,
		dueDate: new Date(),
		createdDate: new Date(),
		currencyUnit: 'Rupees',
		products: [
			{
				amount: 1200,
				id: 'D9vPlvwg11cxYJToEf3x4',
				name: 'productName',
				productID: '',
				quantity: 1,
			},
		],
		taxes: [],
		clientDetail: {
			id: '',
			name: '',
			mobileNo: '',
			email: '',
			image: '',
			billingAddress: '',
		},
	});
	const [hospitalDetail, setHospitalDetail] = useState({
		id: '',
		hospitalName: 'Test name',
		hospitalMobile: 'Test number',
		hospitalEmail: 'Test mail',
		image: '',
		billingAddress: 'Test address',
	});
	const [isExporting, setIsExporting] = useState(false);
	const [nextDate, setNextDate] = useState('');
	const [mno, setMno] = useState('');
	const [searchClicked, setSearchClicked] = useState(false);
	const [userOptionClicked, setUserOptionClicked] = useState(false);
	const [editClicked, setEditClicked] = useState(false);
	const [activeUser, setActiveUser] = useState(null);
	const [uname, setUname] = useState('');
	const [name, setName] = useState('');
	const [uid, setUid] = useState('');
	const [userIdx, setUserIdx] = useState('');
	const [inputValue, setInputValue] = useState('');

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split('T')[0];
	}
	const today = getCurrentDateIST();

	const componentRef = useRef(null);
	const reactToPrintContent = useCallback(() => {
		return componentRef.current;
	}, []);
	const toggleViewMode = useCallback(() => {
		if (invoiceForm.statusIndex !== '1' && isViewMode) {
			toast.warn('You can only edit on Draft Mode', {
				position: 'bottom-center',
				autoClose: 3000,
			});
			return;
		}
		setIsViewMode((prev) => !prev);
	}, [invoiceForm, isViewMode]);
	// const openSettingModal = useCallback(() => {
	// 	if (invoiceForm.statusIndex !== '1' && isViewMode) {
	// 		toast.warn('You can only change Setting on Draft Mode', {
	// 			position: 'bottom-center',
	// 			autoClose: 3000,
	// 		});
	// 		return;
	// 	}
	// 	dispatch(setSettingModalOpen(true));
	// }, [dispatch, invoiceForm, isViewMode]);
	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Invoice Letter',
		onAfterPrint: useCallback(() => {
			setIsExporting(false);
		}),
		removeAfterPrint: true,
	});
	const handleExport = useCallback(() => {
		setIsViewMode(true);
		setIsExporting(true);
		setTimeout(() => {
			handlePrint();
		}, 3000);
	}, [handlePrint]);

	const handleDownloadImg = useCallback(() => {
		setIsViewMode(true);
		setIsExporting(true);
		domtoimage
			.toJpeg(componentRef.current, { quality: 1 })
			.then(async (dataUrl) => {
				try {
					const res = await fetch(dataUrl);
					const blob = await res.blob();
					let a = document.createElement('a');
					a.href = URL.createObjectURL(blob);
					a.download = 'invoice.jpeg';
					a.hidden = true;
					document.body.appendChild(a);
					a.click();
					a.remove();
				} catch (e) {
					console.log(e);
				} finally {
					setIsExporting(false);
				}
			});
	});
	const handlerInvoiceClientValue = useCallback((event, keyName) => {
		const value =
			typeof event === 'string' ? new Date(event) : event?.target?.value;

		setInvoiceForm((prev) => {
			return {
				...prev,
				clientDetail: { ...prev.clientDetail, [keyName]: value },
			};
		});
	}, []);
	const handlerInvoiceValue = useCallback((event, keyName) => {
		const value =
			typeof event === 'string' ? new Date(event) : event?.target?.value;

		setInvoiceForm((prev) => {
			return { ...prev, [keyName]: value };
		});
	}, []);

	const sumProductTotal = (updateProducts) => {
		const total =
			updateProducts.reduce((prev, product) => {
				const strValue = (product.quantity * product.amount)
					.toFixed(4)
					.toString()
					.slice(0, -2);

				return parseFloat(strValue) + prev;
			}, 0) || 0;
		return total;
	};

	const getTotalTaxesWithPercent = (taxes, subTotalAmount) => {
		const isFindIndex = taxes.findIndex((tax) => tax.type === 'percentage');
		if (isFindIndex !== -1) {
			let updatedTaxes = [...taxes];
			const amount = (taxes[isFindIndex].value / 100) * subTotalAmount;
			updatedTaxes[isFindIndex] = {
				...updatedTaxes[isFindIndex],
				amount,
			};
			return [...updatedTaxes];
		}
		return taxes;
	};

	const addEmptyProduct = useCallback(() => {
		const emptyProduct = {
			id: nanoid(),
			name: '',
			productID: '',
			amount: 1,
			quantity: 1,
		};

		setInvoiceForm((prev) => {
			let updatedData = { ...prev };
			const updateProducts = [...prev.products, emptyProduct];
			const subTotalAmount = sumProductTotal(updateProducts);
			const updateTaxes = getTotalTaxesWithPercent(prev.taxes, subTotalAmount);
			updatedData.products = updateProducts;
			updatedData.taxes = updateTaxes;
			updatedData.totalAmount = sumTotalAmount(
				subTotalAmount,
				sumTotalTaxes(updateTaxes)
			);
			return updatedData;
		});
	}, []);

	const handlerTaxesValue = useCallback(
		(event, keyName, taxID) => {
			const value = event.target.value;
			let updateTaxes = [...invoiceForm.taxes];
			const isFindIndex = updateTaxes.findIndex((prod) => prod.id === taxID);
			if (isFindIndex === -1) {
				return;
			}
			const currentTax = updateTaxes[isFindIndex];

			if (currentTax.type === 'percentage' && keyName === 'value') {
				if (!`${value}`.match(/^[0-9]\d*(\.\d+)?$/)) {
					return;
				}

				if (value <= 0 || value > 100) {
					return;
				}
			}

			if (currentTax.type === 'flat' && keyName === 'value') {
				if (!`${value}`.match(/^[0-9]\d*(\.\d+)?$/)) {
					return;
				}

				if (value <= 0) {
					return;
				}
			}

			setInvoiceForm((prev) => {
				let taxes = [...prev.taxes];

				if (keyName === 'value') {
					const subTotalAmount = sumProductTotal(prev.products);
					const amount = (value / 100) * subTotalAmount;
					taxes[isFindIndex] = {
						...taxes[isFindIndex],
						[keyName]: value,
						amount: currentTax.type !== 'percentage' ? value : amount,
					};
					const totalAmount = sumTotalAmount(
						subTotalAmount,
						sumTotalTaxes(taxes)
					);
					return { ...prev, taxes: taxes, totalAmount: totalAmount };
				} else {
					taxes[isFindIndex] = {
						...taxes[isFindIndex],
						[keyName]: value,
					};
					return { ...prev, taxes: taxes };
				}
			});
		},
		[invoiceForm]
	);
	const sumTotalAmount = (subTotal, taxAmount) => {
		const total = parseFloat(subTotal) + parseFloat(taxAmount);

		return Number.isInteger(total)
			? total
			: total?.toFixed(4)?.toString()?.slice(0, -2);
	};
	const sumTotalTaxes = (taxes) => {
		return (
			taxes?.reduce((prev, tx) => {
				return prev + parseFloat(tx.amount);
			}, 0) || 0
		);
	};
	const onDeleteProduct = useCallback((prodID) => {
		setInvoiceForm((prev) => {
			let updatedData = { ...prev };
			const updateProducts = prev.products.filter((prod) => prod.id !== prodID);
			const subTotalAmount = sumProductTotal(updateProducts);
			const updateTaxes = getTotalTaxesWithPercent(prev.taxes, subTotalAmount);
			updatedData.products = updateProducts;
			updatedData.taxes = updateTaxes;
			updatedData.totalAmount = sumTotalAmount(
				subTotalAmount,
				sumTotalTaxes(updateTaxes)
			);
			return updatedData;
		});
	}, []);

	const addEmptyTax = useCallback(() => {
		setInvoiceForm((prev) => {
			const subTotalAmount = sumProductTotal(prev.products);
			const emptyTax = {
				id: nanoid(),
				title: 'Extra Fees',
				type: 'flat',
				value: 1,
				amount: 1,
			};
			const updateTaxes = [...prev.taxes, emptyTax];
			const totalAmount = sumTotalAmount(
				subTotalAmount,
				sumTotalTaxes(updateTaxes)
			);
			return { ...prev, taxes: updateTaxes, totalAmount };
		});
	}, []);

	const addPercentageTax = useCallback(() => {
		const isSomeTaxes = invoiceForm.taxes.some(
			(form) => form.type === 'percentage'
		);

		if (isSomeTaxes) {
			toast.error('Already Have Percentage Taxes!', {
				position: 'bottom-center',
				autoClose: 2000,
			});
			return;
		}

		setInvoiceForm((prev) => {
			const subTotalAmount = sumProductTotal(prev.products);
			const amount = (10 / 100) * subTotalAmount;
			const percentageTax = {
				id: nanoid(),
				title: 'Tax %',
				type: 'percentage',
				value: 10,
				amount,
			};
			const updateTaxes = [percentageTax, ...prev.taxes];
			const totalAmount = sumTotalAmount(
				subTotalAmount,
				sumTotalTaxes(updateTaxes)
			);

			return {
				...prev,
				taxes: updateTaxes,
				totalAmount: totalAmount,
			};
		});
	}, [invoiceForm]);

	const onDeleteTax = useCallback((taxID) => {
		setInvoiceForm((prev) => {
			const updateTaxes = prev.taxes.filter((prod) => prod.id !== taxID);
			let updatedData = { ...prev, taxes: updateTaxes };
			const subTotalAmount = sumProductTotal(prev.products);
			const totalAmount = sumTotalAmount(
				subTotalAmount,
				sumTotalTaxes(updateTaxes)
			);
			updatedData.totalAmount = totalAmount;
			return updatedData;
		});
	}, []);

	const totalPercentTax = useMemo(() => {
		const isSomeTax = invoiceForm?.taxes?.some(
			(tax) => tax.type === 'percentage'
		);

		if (!isSomeTax) {
			return 0;
		}

		const findIndex = invoiceForm?.taxes?.findIndex(
			(tax) => tax.type === 'percentage'
		);

		return findIndex !== -1
			? Number.isInteger(invoiceForm.taxes[findIndex].amount)
				? invoiceForm.taxes[findIndex].amount
				: invoiceForm.taxes[findIndex].amount.toFixed(4).toString().slice(0, -2)
			: 0;
	}, [invoiceForm]);
	const [selectedItem, setSelectedItem] = useState({
		name: '',
		price: '',
		quantity: '1',
	});
	const onClick = (e, productId) => {
		const selected = suggestions.find(
			(suggestion) => suggestion.name === e.target.innerText
		);

		setSelectedItem(selected);
		setFilteredSuggestions([]);
		// setInputValue(e.target.innerText);
		setShowSuggestions(false);
		setActiveSuggestion(0);
		// let updatedData = { ...invoiceForm };
		// let updateProducts = [...invoiceForm.products];

		// const isFindIndex = updateProducts.findIndex(
		// 	(prod) => prod.id === productId
		// );
		// if (isFindIndex !== -1) {
		// 	updateProducts[isFindIndex] = {
		// 		...updateProducts[isFindIndex],
		// 		['name']: selected.name,
		// 		['amount']: selected.amount,
		// 		['quantity']: selected.quantity,
		// 	};

		// 	updatedData.products = [...updateProducts];
		// }
		// console.log(updateProducts, 'line479');

		// setInvoiceForm(updatedData);
	};
	const handlerProductValue = useCallback(
		(event, keyName, productID) => {
			const value = event.target.value;
			const filtered = suggestions.filter(
				(suggestion) =>
					suggestion.name.toLowerCase().indexOf(value.toLowerCase()) > -1
			);
			// setInputValue(value);
			setFilteredSuggestions(filtered);
			setShowSuggestions(true);
			setActiveSuggestion(0);
			// If Keyname Price or Quantity must be only number
			if (keyName === 'quantity') {
				if (!`${value}`.match(/^\d+$/)) {
					return;
				}
			}

			if (keyName === 'amount') {
				if (!`${value}`.match(/^[0-9]\d*(\.\d+)?$/)) {
					return;
				}
			}

			// Quantity Zero Case
			if ((keyName === 'quantity' || keyName === 'amount') && value <= 0) {
				return;
			}

			let updatedData = { ...invoiceForm };
			let updateProducts = [...invoiceForm.products];

			const isFindIndex = updateProducts.findIndex(
				(prod) => prod.id === productID
			);

			if (isFindIndex !== -1) {
				updateProducts[isFindIndex] = {
					...updateProducts[isFindIndex],
					[keyName]: value,
					['amount']: selectedItem.amount,
					['quantity']: selectedItem.quantity,
				};

				updatedData.products = [...updateProducts];
			}

			if (keyName === 'quantity' || keyName === 'amount') {
				const subTotalAmount = sumProductTotal(updateProducts);
				const updateTaxes = getTotalTaxesWithPercent(
					invoiceForm.taxes,
					subTotalAmount
				);
				updatedData.taxes = updateTaxes;
				updatedData.totalAmount = sumTotalAmount(
					subTotalAmount,
					sumTotalTaxes(updateTaxes)
				);
			}
			setInvoiceForm(updatedData);
		},
		[invoiceForm]
	);
	const subTotal = useMemo(() => {
		if (!invoiceForm) {
			return 0;
		}

		if (!invoiceForm?.products) {
			return 0;
		}
		return sumProductTotal(invoiceForm.products);
	}, [invoiceForm]);
	const handleSearchPatient = (e) => {
		e.preventDefault();
		dispatch(getUserDataByCategory(mno));
		setSearchClicked(true);
		setUserOptionClicked(false);
		setEditClicked(false);
		setUname('');
	};
	const handelUserSelect = useCallback(
		(id, bookingidarray, name, index) => {
			let tempClientDetail = {
				id: '',
				name: users_array[index]?.name,
				mobileNo: userData?.data?.data?.mobilenumber,
				email: '',
				image: '',
				billingAddress: userData?.data?.data?.address,
			};
			let updatedData = { ...invoiceForm };
			updatedData.clientDetail = tempClientDetail;
			setInvoiceForm(updatedData);
			setUserOptionClicked(true);
			setActiveUser(index);
			setEditClicked(false);
			setName(users_array[index]?.name);
			setUname(users_array[index]?.name);
			setUid(id);
			setUserIdx(index);
		},
		[invoiceForm]
	);
	let users_array = [];
	if (mno !== '') {
		users_array.push({
			name: userData?.data?.data?.name,
			dob: userData?.data?.data?.dob,
			gender: userData?.data?.data?.gender,
			id: userData?.data?.data?._id,
			bid: userData?.data?.data?.bookingId,
			vitals: userData?.data?.data?.vitals,
		});
		for (
			let i = 0;
			i < userData?.data?.data?.registered_patients_array?.length;
			i++
		) {
			users_array.push({
				name: userData?.data?.data?.registered_patients_array[i]?.name,
				id: userData?.data?.data?._id,
				bid: userData?.data?.data?.registered_patients_array[i]?.bookingId,
				dob: userData?.data?.data?.registered_patients_array[i]?.dob,
				gender: userData?.data?.data?.registered_patients_array[i]?.gender,
				vitals: userData?.data?.data?.registered_patients_array[i]?.vitals,
			});
		}
	}
	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [activeSuggestion, setActiveSuggestion] = useState(0);

	const onKeyDown = (e, productId) => {
		if (e.keyCode === 13) {
			// Enter key
			// setInputValue(filteredSuggestions[activeSuggestion]);
			setShowSuggestions(false);
		} else if (e.keyCode === 38) {
			// Up arrow
			if (activeSuggestion === 0) {
				return;
			}
			setActiveSuggestion(activeSuggestion - 1);
		} else if (e.keyCode === 40) {
			// Down arrow
			if (activeSuggestion + 1 === filteredSuggestions.length) {
				return;
			}
			setActiveSuggestion(activeSuggestion + 1);
		}
		// let updatedData = { ...invoiceForm };
		// let updateProducts = [...invoiceForm.products];

		// const isFindIndex = updateProducts.findIndex(
		// 	(prod) => prod.id === productId
		// );

		// if (isFindIndex !== -1) {
		// 	updateProducts[isFindIndex] = {
		// 		...updateProducts[isFindIndex],
		// 		['name']: selectedItem.name,
		// 		['amount']: selectedItem.amount,
		// 		['quantity']: selectedItem.quantity,
		// 	};

		// 	updatedData.products = [...updateProducts];
		// }
		// setInvoiceForm(updatedData);
	};
	const renderSuggestions = (productId) => {
		if (showSuggestions && productId) {
			if (filteredSuggestions.length) {
				return (
					<ul className='suggestions'>
						{filteredSuggestions.map((suggestion, index) => {
							let className;
							if (index === activeSuggestion) {
								className = 'suggestion-active';
							}
							return (
								<li
									key={suggestion.name}
									className={className}
									onClick={(e) => onClick(e, productId)}>
									{suggestion.name}
								</li>
							);
						})}
					</ul>
				);
			} else {
				return (
					<div className='no-suggestions'>
						<em>No suggestions available.</em>
					</div>
				);
			}
		}
		return null;
	};

	return (
		<div>
			{loading && <Loader />}
			<form className='form-group' onSubmit={handleSearchPatient}>
				<Container className='header-center mt-5'>
					<Row className='justify-content-center'>
						<Col md='auto' className='text-center'>
							<h6>
								Enter Patient's Mobile Number{' '}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md='auto' className='text-center'>
							<input
								className='form-control'
								type='text'
								placeholder='9999999999'
								id='mobilenumber'
								required='required'
								onChange={(e) => setMno(e.target.value)}
								minlength='10'
								maxlength='10'
								pattern='^[0-9]*$'
							/>
						</Col>
						<Col md='auto' className='text-center'>
							<Button type='submit' id='search' className='btn btn-primary'>
								Search
							</Button>
						</Col>
					</Row>
				</Container>
			</form>
			<div className='row offset-md-2'>
				{!error &&
					searchClicked &&
					userData &&
					users_array.map((users, index) =>
						users.name === uname ? (
							<button
								onClick={() =>
									handelUserSelect(
										users.id,
										users.bookingidarray,
										users.name,
										index
									)
								}
								className='btn btn-secondary col-md-3 m-1'>
								{users.name}
							</button>
						) : (
							<button
								onClick={() =>
									handelUserSelect(
										users.id,
										users.bookingidarray,
										users.name,
										index
									)
								}
								className='btn btn-outline-secondary col-md-3 m-1'>
								{users.name}
							</button>
						)
					)}
			</div>
			<div>
				{/* add a back button */}
				<div>
					<Button>
						{/*!{viewMode} ? <>View Mode</> : <>Editing Mode</>*/}
						mode
					</Button>
				</div>
			</div>
			<div>
				<Button>Setting</Button>
			</div>
			<div>
				<Button>Export PDF</Button>
			</div>
			<div>
				<Button>Download Image</Button>
			</div>
			<div
				id='InvoiceWrapper'
				ref={componentRef}
				style={isExporting ? { width: 1200 } : {}}>
				{/* Background Image */}
				<div
					style={
						{
							// backgroundImage: `url(${backgroundImage?.base64})`,
						}
					}>
					<div>
						{hospitalDetail?.image ? (
							<img
								className='object-contain h-20 w-20 mr-3 rounded'
								alt={hospitalDetail?.hospitalName}
								src={hospitalDetail?.image}
							/>
						) : (
							<span></span>
						)}

						<div>
							<p className='font-bold mb-2'>
								{hospitalDetail?.hospitalName || 'hospital Name'}
							</p>
							<p className='text-sm font-medium'>
								{hospitalDetail?.billingAddress ||
									'Plz add First hospital Data'}
							</p>
							<p className='text-sm font-medium'>
								{hospitalDetail?.hospitalMobile || 'hospital Ph'}
							</p>
							<p className='text-sm font-medium'>
								{hospitalDetail?.hospitalEmail || 'Company@email.com'}
							</p>
						</div>
					</div>
					<div className=' mt-5 sm:mt-0'>Invoice</div>
				</div>
			</div>
			<div>
				<div>
					<div>
						<div>Billing To</div>
					</div>
					<div>
						<div>
							{!isViewMode ? (
								<input
									autoComplete='nope'
									placeholder='Client Name'
									value={invoiceForm?.clientDetail?.name}
									onChange={(e) => handlerInvoiceClientValue(e, 'name')}
								/>
							) : (
								invoiceForm?.clientDetail?.name
							)}
						</div>
						<div>
							{!isViewMode ? (
								<input
									autoComplete='nope'
									placeholder='Client Address'
									value={invoiceForm?.clientDetail?.billingAddress}
									onChange={(e) =>
										handlerInvoiceClientValue(e, 'billingAddress')
									}
								/>
							) : (
								invoiceForm?.clientDetail?.billingAddress
							)}
						</div>
						<div>
							{!isViewMode ? (
								<input
									autoComplete='nope'
									placeholder='Client Mobile'
									value={invoiceForm?.clientDetail?.mobileNo}
									onChange={(e) => handlerInvoiceClientValue(e, 'mobileNo')}
								/>
							) : (
								invoiceForm?.clientDetail?.mobileNo
							)}
						</div>
						<div>
							{!isViewMode ? (
								<input
									autoComplete='nope'
									placeholder='Client Email'
									value={invoiceForm?.clientDetail?.email}
									onChange={(e) => handlerInvoiceClientValue(e, 'email')}
								/>
							) : (
								invoiceForm?.clientDetail?.email
							)}
						</div>
					</div>
				</div>
				<div className='flex-1'>
					<div className='flex flex-row justify-between items-center mb-1'>
						<div> INVOICE # </div>
						<div>
							{!isViewMode ? (
								<input
									autoComplete='nope'
									placeholder='Invoice No'
									value={invoiceForm.invoiceNo}
									onChange={(e) => handlerInvoiceValue(e, 'invoiceNo')}
								/>
							) : (
								invoiceForm.invoiceNo || '-'
							)}
						</div>
					</div>
					<div className='flex flex-row justify-between items-center mb-1'>
						<div> Creation Date </div>
						<div>
							<Col md='12' className='text-center'>
								<input
									type='date'
									className='form-control'
									onChange={(e) => setNextDate(e.target.value)}
									value={nextDate}
								/>
							</Col>
						</div>
					</div>
					{!isViewMode && (
						<div>
							<div> Change Currency </div>
							<div>
								<input
									autoComplete='nope'
									placeholder='Invoice No'
									value={invoiceForm.currencyUnit}
									onChange={(e) => handlerInvoiceValue(e, 'currencyUnit')}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className='py-2 px-4'>
				<div
					className={
						isExporting
							? 'flex rounded-lg w-full flex-row px-4 py-1 text-white'
							: 'hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white'
					}
					style={{ backgroundColor: invoiceForm.color }}>
					<div
						className={
							'font-title ' +
							(isExporting
								? ' text-sm w-1/4 text-right pr-10'
								: ' w-full sm:w-1/4 text-right sm:pr-10')
						}>
						<span className='inline-block'>Description</span>
					</div>
					<div
						className={
							'font-title ' +
							(isExporting
								? ' text-sm  w-1/4 text-right pr-10'
								: ' w-full sm:w-1/4 text-right sm:pr-10')
						}>
						Price
					</div>
					<div
						className={
							'font-title ' +
							(isExporting
								? ' text-sm  w-1/4 text-right pr-10'
								: ' w-full sm:w-1/4 text-right sm:pr-10')
						}>
						Qty
					</div>
					<div
						className={
							'font-title' +
							(isExporting
								? ' text-sm w-1/4 text-right pr-10'
								: '  w-full sm:w-1/4 text-right sm:pr-10')
						}>
						Total
					</div>
				</div>

				<div>
					{invoiceForm?.products?.map((product, index) => (
						<div key={`${index}_${product.id}`}>
							<div>
								{!isExporting && <span>Description</span>}
								<span>
									{!isViewMode ? (
										<>
											<input
												autoComplete='nope'
												value={product.name}
												placeholder='Product Name'
												onChange={(e) =>
													handlerProductValue(e, 'name', product.id)
												}
												onKeyDown={(e) => onKeyDown(e, product.id)}
											/>
											{renderSuggestions(product.id)}
										</>
									) : (
										<span>{product.name}</span>
									)}
								</span>
							</div>
							<div>
								{!isExporting && <span>Price</span>}
								<span>
									{!isViewMode ? (
										<input
											autoComplete='nope'
											value={product.amount}
											placeholder='Price'
											type='number'
											onChange={(e) =>
												handlerProductValue(e, 'amount', product.id)
											}
										/>
									) : (
										<span>
											<input
												value={product.amount}
												displayType='text'
												thousandSeparator
												renderText={(value, props) => (
													<span {...props}>{value}</span>
												)}
											/>
										</span>
									)}
								</span>
							</div>
							<div>
								{!isExporting && <span>Quantity</span>}
								<span>
									{!isViewMode ? (
										<input
											autoComplete='nope'
											value={product.quantity}
											type='number'
											placeholder='Quantity'
											onChange={(e) =>
												handlerProductValue(e, 'quantity', product.id)
											}
										/>
									) : (
										<span>
											<input
												value={product.quantity}
												displayType='text'
												thousandSeparator
												renderText={(value, props) => (
													<span {...props}>{value}</span>
												)}
											/>
										</span>
									)}
								</span>
							</div>
							<div>
								{!isExporting && <span>Total</span>}
								<span>
									<input
										value={
											Number.isInteger(product.quantity * product.amount)
												? product.quantity * product.amount
												: (product.quantity * product.amount)
														.toFixed(4)
														.toString()
														.slice(0, -2)
										}
										displayType='text'
										thousandSeparator
										renderText={(value, props) => (
											<span {...props}>{value}</span>
										)}
									/>{' '}
									{invoiceForm?.currencyUnit}
								</span>
							</div>
							{!isViewMode && (
								<button
									className='btn btn-danger'
									onClick={() => onDeleteProduct(product.id)}>
									<i className='fa fa-trash fa-2x'></i>
								</button>
							)}
						</div>
					))}
				</div>

				<div>
					{/* Add Product Actions */}
					{!isViewMode && (
						<div>
							<div>
								<Button size='sm' block onClick={addEmptyProduct}>
									<i className='fa fa-plus'>Add Empty Product</i>
								</Button>
							</div>
							<div>
								<Button>Add Existing Product</Button>
							</div>
						</div>
					)}
				</div>
				<div>
					<div>Subtotal</div>
					<div>
						<input
							value={subTotal}
							className='inline-block'
							displayType={'text'}
							thousandSeparator={true}
							renderText={(value, props) => (
								<span {...props}>
									{value} {invoiceForm?.currencyUnit}
								</span>
							)}
						/>
					</div>
				</div>
				{/* Subtotal Finished */}

				{/* Taxes */}
				{invoiceForm?.taxes?.map((tax, index) => (
					<div key={`${index}_${tax.id}`}>
						<div>
							<div>Tax Type</div>
							<div>
								<div>
									{!isViewMode && (
										<input
											autoComplete='nope'
											value={tax.title}
											type={'text'}
											placeholder='Tax Title'
											onChange={(e) => handlerTaxesValue(e, 'title', tax.id)}
										/>
									)}
								</div>
								<div>
									{!isViewMode ? (
										<>
											<input
												autoComplete='nope'
												value={tax.value}
												type={'number'}
												placeholder='Percentage'
												onChange={(e) => handlerTaxesValue(e, 'value', tax.id)}
											/>
											<span className='ml-1'>
												{tax.type === 'percentage'
													? '%'
													: invoiceForm.currencyUnit}
											</span>
										</>
									) : (
										<div className='text-right'>{tax.title}</div>
									)}
								</div>
							</div>
						</div>
						<div>
							{!isExporting && <span>Amount</span>}
							<span>
								<div>
									<input
										value={
											tax.type === 'percentage' ? totalPercentTax : tax.amount
										}
										className=''
										displayType={'text'}
										thousandSesparator={true}
										renderText={(value, props) => (
											<span {...props}>
												{value} {invoiceForm?.currencyUnit}
											</span>
										)}
									/>
								</div>
							</span>
						</div>
						{!isViewMode && (
							<div onClick={() => onDeleteTax(tax.id)}>
								<div>
									<i className='fa fa-trash fa-2x'></i>
									<span>Delete Tax</span>
								</div>
							</div>
						)}
					</div>
				))}
				{/* Taxes Finished*/}

				{/* Add Tax Action */}
				{!isViewMode && (
					<div>
						<div>
							<Button size='sm' block={1} onClick={addPercentageTax}>
								Add Taxes (%)
							</Button>
						</div>
						<div>
							<Button size='sm' block={1} onClick={addEmptyTax}>
								Add Extra Fee
							</Button>
						</div>
					</div>
				)}
				{/* Add Tax Action Finished*/}

				{/* Subtotal Start */}
				<div>
					<div style={{ backgroundColor: invoiceForm.color }}>
						<div>Total</div>
						<div>
							<input
								value={invoiceForm?.totalAmount}
								className=''
								displayType={'text'}
								thousandSeparator={true}
								renderText={(value, props) => (
									<span {...props}>
										{value}{' '}
										<span className={isExporting ? 'text-sm' : 'text-base'}>
											{invoiceForm?.currencyUnit}
										</span>
									</span>
								)}
							/>
						</div>
					</div>
				</div>
				{/* Subtotal Finished */}
			</div>
		</div>
	);
};
export default BillingScreen;
