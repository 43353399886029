/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col, Container, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
	getInventoryCategory,
	getInventoryItemsForBilling,
	getTodaysRevenues,
} from "../../../redux/inventoryRedux/inventoryApiCalls";
import PieChart from "../../../Components/PieChart.js";
import BarChart from "../../../Components/Barchart.js";

const MinStockAgGrid = ({ inventoryData }) => {
	const [rowData, setRowData] = useState([]);

	const filterDataByStockLevel = (data) => {
		return data?.filter((product) => {
			const totalQuantityLeft = product?.selling_prices.reduce(
				(acc, curr) => acc + curr?.quantityLeft,
				0
			);
			return totalQuantityLeft < product?.minStockLevel;
		});
	};

	useEffect(() => {
		// Filter data based on minStockLevel
		const filteredData = filterDataByStockLevel(inventoryData);
		setRowData(filteredData);
	}, [inventoryData]);

	const columns = [
		{ headerName: "Product Name", field: "name" },
		{ headerName: "Min Stock Level", field: "minStockLevel" },
		{
			headerName: "Total Quantity Left",
			valueGetter: (params) =>
				params.data.selling_prices.reduce(
					(acc, curr) => acc + curr.quantityLeft,
					0
				),
		},
		{ headerName: "Status", field: "status" },
		{ headerName: "Category", field: "category" },
	];

	return (
		<div
			className='ag-theme-alpine'
			style={{
				height: "100%",
				width: "100%",
				overflow: "auto", // Changed from 'scroll' to 'auto'
				boxSizing: "border-box", // Ensure padding and borders are included in width/height
			}}>
			<AgGridReact
				rowData={rowData}
				columnDefs={columns}
				domLayout='autoHeight'
			/>
		</div>
	);
};

const ExpiringSoonAgGrid = ({ inventoryData, expiryPeriod }) => {
	const today = new Date();
	const expiryDays =
		{
			"1 Week": 7,
			"1 Month": 30,
			"3 Months": 90,
			"6 Months": 180,
		}[expiryPeriod] || 180; // Default to 180 days if none of the above

	const expiryPeriodFromToday = new Date(today);
	expiryPeriodFromToday.setDate(today.getDate() + expiryDays);

	const formatDate = (yyyymmdd) => {
		const year = Math.floor(yyyymmdd / 10000);
		const month = Math.floor((yyyymmdd % 10000) / 100) - 1; // Month is 0-indexed
		const day = yyyymmdd % 100;
		return new Date(year, month, day);
	};

	// Function to filter products and return only matching selling_prices
	const filterDataExpiringSoon = (data) => {
		return data
			?.map((product) => {
				// Filter the selling_prices array based on the expiry date criteria
				const filteredSellingPrices = product?.selling_prices?.filter(
					(price) => {
						const expiryDate = formatDate(price?.expiryDate);
						if (expiryPeriod === "1 Week") {
							// Show 0-7 days of expiry
							return expiryDate <= expiryPeriodFromToday && expiryDate >= today;
						} else if (expiryPeriod === "1 Month") {
							// Show 8-30 days of expiry
							return (
								expiryDate <= expiryPeriodFromToday &&
								expiryDate > today &&
								expiryDate <=
									new Date(today?.getTime() + 30 * 24 * 60 * 60 * 1000)
							);
						} else if (expiryPeriod === "3 Months") {
							// Show 31-90 days of expiry
							const daysFromToday = Math.floor(
								(expiryDate - today) / (1000 * 60 * 60 * 24)
							);
							return daysFromToday > 30 && daysFromToday <= 90; // Between 31 and 90 days
						} else {
							// Show 91-180 days of expiry
							const daysFromToday = Math.floor(
								(expiryDate - today) / (1000 * 60 * 60 * 24)
							);
							return daysFromToday > 90 && daysFromToday <= 180; // Between 91 and 180 days
						}
					}
				);

				// Return the product with only the filtered selling_prices
				if (filteredSellingPrices?.length > 0) {
					return {
						...product,
						selling_prices: filteredSellingPrices, // Include only filtered selling_prices
					};
				}

				return null; // Exclude the product if no selling_prices match
			})
			?.filter((product) => product !== null); // Remove null entries
	};

	const filteredData = filterDataExpiringSoon(inventoryData);

	// Flatten the filtered data to show each selling price item in its own row
	const flatData = filteredData?.flatMap((product) => {
		return product.selling_prices
			.filter((price) => formatDate(price.expiryDate) <= expiryPeriodFromToday)
			.map((price) => ({
				productId: product?.ProductID,
				productName: product?.name,
				batchNoFromUser: price?.batchNoFromUser,
				expiryDate: formatDate(price?.expiryDate)?.toLocaleDateString("en-GB"),
				status: product?.status,
				category: product?.category,
				minStockLevel: product?.minStockLevel,
				quantityLeft: price?.quantityLeft,
			}));
	});

	const columns = [
		{ headerName: "Product Name", field: "productName" },
		{ headerName: "Quantity", field: "quantityLeft" },
		{ headerName: "Batch No", field: "batchNoFromUser" },
		{ headerName: "Expiry Date", field: "expiryDate" },
		{ headerName: "Status", field: "status" },
		{ headerName: "Category", field: "category" },
	];

	const defaultColDef = {
		sortable: true,
		filter: true,
		resizable: true,
		floatingFilter: true,
	};

	return (
		<div
			className='ag-theme-alpine'
			style={{ height: "100%", width: "100%", overflow: "auto" }}>
			<AgGridReact
				rowData={flatData}
				columnDefs={columns}
				domLayout='autoHeight'
				defaultColDef={defaultColDef}
			/>
		</div>
	);
};

const VendorPaymentRemindersGrid = () => {
	// Helper function to format date from yyyymmdd to dd-mm-yyyy
	const formatDate = (dateStr) => {
		const year = dateStr?.substring(0, 4);
		const month = dateStr?.substring(4, 6);
		const day = dateStr?.substring(6, 8);
		return `${day}/${month}/${year}`;
	};
	// Sample data
	const { adminInfo } = useSelector((state) => state.adminLogin);
	const rowData = adminInfo?.user?.vendor_payment_reminders || [];

	// Transform the rowData to format the date
	const formattedRowData = rowData.map((row) => ({
		...row,
		date: formatDate(row?.date?.toString()),
	}));

	// Column definitions
	const columnDefs = [
		{ headerName: "Vendor Name", field: "vendorName" },
		{ headerName: "Due Date", field: "date" },
		{ headerName: "Payment Amount", field: "Payment_amount" },
	];

	return (
		<div
			className='ag-theme-alpine'
			style={{
				height: "100%",
				width: "100%",
				overflow: "auto",
				boxSizing: "border-box",
			}}>
			<AgGridReact rowData={formattedRowData} columnDefs={columnDefs} />
		</div>
	);
};

const Dashboard = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getInventoryCategory());
		dispatch(getInventoryItemsForBilling());
	}, []);

	const getInventorycategory = useSelector(
		(state) => state.getInventoryCategory
	);

	const getInventoryItemsForbilling = useSelector(
		(state) => state.getInventoryItemsForBilling
	);

	console.log(getInventoryItemsForbilling);

	useEffect(() => {
		dispatch(getTodaysRevenues());
	}, []);

	const getTodaysRevenuesDetails = useSelector(
		(state) => state.getTodaysRevenues
	);

	console.log(getTodaysRevenuesDetails);

	const [TodaysPurchaseAmount, setTodaysPurchaseAmount] = useState([]);
	const [TodaysSalesTotalAmount, setTodaysSalesTotalAmount] = useState([]);

	useEffect(() => {
		let todaysSalesTotalAmount = [];
		getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.forEach((item) => {
			item.CategoryName !== "hospital" &&
				todaysSalesTotalAmount.push({
					id: item?.CategoryName,
					label: item?.CategoryName,
					value: item?.todays_category_sales?.category_sales_amount,
				});
		});

		setTodaysSalesTotalAmount(todaysSalesTotalAmount);

		// Today's Purchase Amount
		let todaysPurchaseAmount = [];
		getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.forEach((item) => {
			// Check if the category already exists in the array
			const existingEntry = todaysPurchaseAmount.find(
				(entry) => entry.id === item.CategoryName
			);

			if (existingEntry) {
				// If the entry exists, add all the purchase_amounts
				item.todays_category_purchase.category_purchase_bills.forEach(
					(bill) => {
						existingEntry.value += bill.purchase_amount;
					}
				);
			} else {
				// Create a new entry for the category
				const totalPurchaseAmount =
					item.todays_category_purchase.category_purchase_bills.reduce(
						(sum, bill) => sum + bill.purchase_amount,
						0
					);
				item.CategoryName !== "hospital" &&
					todaysPurchaseAmount.push({
						id: item.CategoryName,
						label: item.CategoryName,
						value: totalPurchaseAmount,
					});
			}
		});
		setTodaysPurchaseAmount(todaysPurchaseAmount);
		console.log(todaysPurchaseAmount);
	}, [getTodaysRevenuesDetails?.loading]);

	const [ActivePeriodExpiry, setActivePeriodExpiry] = useState("1 Week");
	return (
		<Container fluid className='p-4'>
			<h1 className='mb-4 header-center'>Pharmacy Dashboard</h1>
			<Row className='mb-4'>
				<Col md={6}>
					<Card style={{ height: "350px" }}>
						<Card.Header>Min Stock Levels</Card.Header>
						<Card.Body className='p-0'>
							<MinStockAgGrid
								inventoryData={
									getInventoryItemsForbilling?.getInventoryItemsForbilling?.data
										?.data
								}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col md={6}>
					<Card style={{ height: "350px" }}>
						<Card.Header>Payment Reminders</Card.Header>

						<Card.Body className='d-flex flex-column p-0'>
							<VendorPaymentRemindersGrid />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className='mb-4'>
				<Col md={6}>
					<Card style={{ height: "600px" }}>
						<Card.Header>Recent Sales</Card.Header>
						<Card.Body>
							<Container className='header-center mt-3'>
								<PieChart data={TodaysSalesTotalAmount} />
							</Container>
						</Card.Body>
					</Card>
				</Col>
				<Col md={6}>
					<Card style={{ height: "600px" }}>
						<Card.Header>Recent Purchases</Card.Header>
						<Card.Body>
							<Card style={{ display: "flex", alignItems: "center" }}>
								<span className='mt-4'>
									<b>Total Purchase Amount </b>
								</span>
								{getTodaysRevenuesDetails?.getTodaysRevenues?.data?.data?.map(
									(category) =>
										category?.CategoryName === "hospital" && (
											<span key={category?.CategoryName} className='mb-4'>
												{
													category?.todays_category_purchase
														?.category_purchase_amount
												}
											</span>
										)
								)}
							</Card>
							<PieChart data={TodaysPurchaseAmount} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className='mb-4 justify-content-center'>
				<Col md={10}>
					<Card style={{ height: "400px" }}>
						<Card.Header>Products Expiring Soon</Card.Header>
						<Card.Body className='d-flex flex-column p-0'>
							<Container className='header-center mt-3 mb-3'>
								<Row className='justify-content-center'>
									<Col md='auto' className='mt-1'>
										<Button
											variant={
												ActivePeriodExpiry === "1 Week"
													? "primary"
													: "outline-primary"
											}
											onClick={() => setActivePeriodExpiry("1 Week")}>
											1 Week
										</Button>
									</Col>
									<Col md='auto' className='mt-1'>
										<Button
											variant={
												ActivePeriodExpiry === "1 Month"
													? "primary"
													: "outline-primary"
											}
											onClick={() => setActivePeriodExpiry("1 Month")}>
											1 Month
										</Button>
									</Col>
									<Col md='auto' className='mt-1'>
										<Button
											variant={
												ActivePeriodExpiry === "3 Months"
													? "primary"
													: "outline-primary"
											}
											onClick={() => setActivePeriodExpiry("3 Months")}>
											3 Months
										</Button>
									</Col>
									<Col md='auto' className='mt-1'>
										<Button
											variant={
												ActivePeriodExpiry === "6 Months"
													? "primary"
													: "outline-primary"
											}
											onClick={() => setActivePeriodExpiry("6 Months")}>
											6 Months
										</Button>
									</Col>
								</Row>
							</Container>
							<ExpiringSoonAgGrid
								inventoryData={
									getInventoryItemsForbilling?.getInventoryItemsForbilling?.data
										?.data
								}
								expiryPeriod={ActivePeriodExpiry}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Dashboard;
