/** @format */

import { createSlice } from "@reduxjs/toolkit";

const editPrescriptionTemplateInSpeciality = createSlice({
	name: "prescription",
	initialState: {},
	reducers: {
		editPrescriptionTemplateInSpecialityStart: (state) => {
			state.loading = true;
		},
		editPrescriptionTemplateInSpecialitySuccess: (state, action) => {
			state.loading = false;
			state.editPrescriptionTemplateInSpeciality = action.payload;
			state.error = false;
			state.success = true;
		},
		editPrescriptionTemplateInSpecialityFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editPrescriptionTemplateInSpecialityReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editPrescriptionTemplateInSpecialityStart,
	editPrescriptionTemplateInSpecialitySuccess,
	editPrescriptionTemplateInSpecialityFailure,
	editPrescriptionTemplateInSpecialityReset,
} = editPrescriptionTemplateInSpeciality.actions;
export default editPrescriptionTemplateInSpeciality.reducer;
