/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getPrescriptionReducer = createSlice({
	name: "IssueTitles",
	initialState: {},
	reducers: {
		getPrescriptionRequest: (state) => {
			state.loading = true;
		},
		getPrescriptionSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getPrescription = action.payload;
			state.error = false;
			state.success = true;
		},
		getPrescriptionFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getPrescriptionReset: (state) => {
			return {
				error: false,
				success: false,
				getPrescription: [],
			};
		},
	},
});

export const {
	getPrescriptionRequest,
	getPrescriptionSuccess,
	getPrescriptionFailure,
	getPrescriptionReset,
} = getPrescriptionReducer.actions;
export default getPrescriptionReducer.reducer;
