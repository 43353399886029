/** @format */
import Message from "../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { getSpecialities, getTimeslots } from "../../redux/userApiCalls";
import {
	getDoctorsAppointmentsForDate, //
	//changeToVisited,
	changeToOnHold, //
	changeSlot, //
	getDoctorVisitedPatientsforParticularDate,
} from "../../redux/adminRedux/adminApiCalls";
// import Accordion from 'react-bootstrap/Accordion';

import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";
import { doctorBookingGetReset } from "../../redux/adminRedux/Booking_Management/getBooking";
import { changeOnHoldReset } from "../../redux/adminRedux/Booking_Management/changeOnHold";
import { changeSlotReset } from "../../redux/adminRedux/Booking_Management/changeSlot";
import Form from "react-bootstrap/Form";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";
import { specialityGetReset } from "../../redux/getSpecialities";
import { TimeSlotGetReset } from "../../redux/getTimeSlots";

//Date picker

const AppointmentListScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	//Success and failure allMessages
	const data1 = useSelector((state) => state.getDoctorBooking);
	const { getBookingsall } = data1;
	const { loading, error, success } = data1;

	const changeOnHoldData = useSelector((state) => state.changeOnHold);
	const changeOnHoldError = changeOnHoldData.error;
	const changeOnHoldloading = changeOnHoldData.loading;
	const changeOnHoldsuccess = changeOnHoldData.success;

	const changeSlotData = useSelector((state) => state.changeSlot);
	const changeSlotError = changeSlotData.error;
	const changeSlotloading = changeSlotData.loading;
	const changeSlotsuccess = changeSlotData.success;

	const getSpecialityAllData = useSelector((state) => state.getSpeciality);
	const getSpecialityAllsuccess = getSpecialityAllData.success;
	const getSpecialityAllerror = getSpecialityAllData.error;
	const getSpecialityAllloading = getSpecialityAllData.loading;

	const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
	const getTimeSlotssuccess = getTimeSlotsData.success;
	const getTimeSlotserror = getTimeSlotsData.error;
	const getTimeSlotsloading = getTimeSlotsData.loading;
	const doctorAppointmentDates =
		getTimeSlotsData?.allTimeSlots?.appointmentDates;

	//Displaying the visited patients and patients to be visited part

	const [DoctorFromSpecialityArray, setDoctorFromSpecialityArray] = useState(
		[]
	);
	const [patientsToBeVisited, setPatientsToBeVisited] = useState("d-block");
	const [patientsVisited, setVisitedPatients] = useState("d-none");
	// Accordian Collapse
	const [open, setOpen] = useState("1");
	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};

	//Modal Open
	const [lgShow, setLgShow] = useState(false);
	//Date Format
	const days = [];

	const formattedDates = doctorAppointmentDates?.map((entry) => {
		const dateString = entry.date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ("0" + dateString.slice(6, 8)).slice(-2);

		// Create the formatted date string

		days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
	});
	console.log(doctorAppointmentDates, days);
	//Retrieving Specialities Data
	useEffect(() => {
		dispatch(getSpecialities());
	}, [dispatch]);
	const { getSpecialityAll } = useSelector((state) => state.getSpeciality);
	let allSpecialities = [];
	let allDoctors = [];
	if (getSpecialityAll) {
		console.log(getSpecialityAll);
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}
	console.log(allSpecialities);

	//Retrieving Doctors Data from Specialities

	//Retrieving Bookings Data of selected Doctor

	console.log(getBookingsall?.data?.data);

	const { allTimeSlots } = useSelector((state) => state.getTimeSlots);
	let timeSlots = [];
	let isEmpty = true;
	for (let i = 0; i < allTimeSlots?.length; i++) {
		isEmpty = allTimeSlots[i].isEmpty;
		timeSlots.push({
			isEmpty: isEmpty,
			timeSlot: allTimeSlots[i].timeSlot,
			curCount: allTimeSlots[i].curCount,
			maxCount: allTimeSlots[i].maxCount,
		});
	}
	console.log(timeSlots);

	//Speciality dropdown
	const [specialityOption, setSpecialityOption] = useState("Choose Speciality");
	const [specialityId, setSpecialityId] = useState("");
	const handleSpecialitySelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		setSpecialityOption(arr[0]);
		setSpecialityId(arr[1]);
		setDoctorOption("Choose Doctor");
		setDateOption("Choose Date");
		setDateSelected(false);
		// slotArray = [];
		// slotArrayVisited = [];

		console.log();
	};

	//Doctor dropdown
	const [doctorOption, setDoctorOption] = useState("Choose Doctor");
	const [doctorId, setDoctorId] = useState("");
	const handleDoctorSelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		setDoctorId(arr[1]);
		setDoctorOption(arr[0]);
		setDateOption("Choose Date");
		setDateSelected(false);
		dispatch(getTimeslots(arr[1]));
		// slotArray = [];
		// slotArrayVisited = [];
	};
	const [dateOptionNumeric, setDateOptionNumeric] = useState("Choose Date");
	//Date dropdown
	const [dateOption, setDateOption] = useState("Choose Date");
	const [dateSelected, setDateSelected] = useState(false);
	const handleDateSelect = (eventKey) => {
		setDateOption(eventKey);
		setDateSelected(false);
		setDateOptionNumeric(doctorAppointmentDates[eventKey].date);
		// slotArray = [];
		// slotArrayVisited = [];
	};

	//Search button function for patients to be visited
	const submitHandler = async () => {
		if (specialityOption == "Choose Speciality") {
			alert("Speciality needs to be selected first, Please select Speciality");
		} else if (doctorOption == "Choose Doctor") {
			alert("Doctor needs to be selected first, Please select Doctor");
		} else if (dateOption == "Choose Date") {
			alert("Date needs to be selected first, Please select Date");
		} else {
			if (dateOption !== "Choose Date") setDateSelected(true);
			const tempArr = dateOption.split("/");
			const dateModified = tempArr[2] + "-" + tempArr[1] + "-" + tempArr[0];
			dispatch(
				getDoctorsAppointmentsForDate(specialityId, doctorId, dateModified)
			);
		}
	};
	//Search button function for visited patients
	const [specialityVisited, setSpecialityVisited] =
		useState("Choose Speciality");
	const [specialityId1, setSpecialityId1] = useState("");
	const handleSpecialitySelect1 = (eventKey) => {
		let arr1 = [];
		arr1 = eventKey.split("/");
		setSpecialityVisited(arr1[0]);
		setSpecialityId1(arr1[1]);
		setDoctorVisited("Choose Doctor");
		setVisitedDate("");
		setSearchClicked(false);
		// slotArray = [];
		// slotArrayVisited = [];
		console.log();
	};

	const [doctorVisited, setDoctorVisited] = useState("Choose Doctor");
	const [doctorId1, setDoctorId1] = useState("");
	const handleDoctorSelect1 = (eventKey) => {
		let arr1 = [];
		arr1 = eventKey.split("/");
		setDoctorId1(arr1[1]);
		setDoctorVisited(arr1[0]);
		setVisitedDate("");
		setSearchClicked(false);
		// slotArray = [];
		// slotArrayVisited = [];
	};
	const [visitedDate, setVisitedDate] = useState("");
	const handleDateSelect1 = (eventKey) => {
		setVisitedDate(eventKey);
		setSearchClicked(false);
	};
	const [searchClicked, setSearchClicked] = useState(false);
	const searchForVisited = async () => {
		if (specialityVisited == "Choose Speciality") {
			alert("first select the speciality");
		} else if (doctorVisited == "Choose Doctor") {
			alert("first select the Doctor");
		} else if (visitedDate == "") {
			alert("first select the date");
		} else {
			const tempArr1 = visitedDate.split("-");
			const dateModified1 = tempArr1[0] + "-" + tempArr1[1] + "-" + tempArr1[2];
			setSearchClicked(true);
			dispatch(
				getDoctorVisitedPatientsforParticularDate(doctorId1, dateModified1)
			);
			console.log(doctorId1, dateModified1);
		}
	};

	const getVisiPat = useSelector(
		(state) => state.getDoctorVisitedPatientsforParticularDate
	);
	console.log(getVisiPat?.getDoctorVisitedPatientsforParticularDate);

	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	const exportToExcel = async () => {
		let tempObj =
			getVisiPat?.getDoctorVisitedPatientsforParticularDate?.data?.data
				?.visited_patients;
		console.log(tempObj);
		let tempArr = [];
		for (let i = 0; i < tempObj.length; i++) {
			const obj = {
				"Patient Name": tempObj[i].name,
				"Slot ": tempObj[i].slot,
				"Mobile No": tempObj[i].mobilenumber,
			};
			tempArr.push(obj);
		}
		console.log(tempArr + "dataaaaaa");

		const ws = XLSX.utils.json_to_sheet(tempArr);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, doctorVisited + "_" + visitedDate);
		console.log("saved");
	};
	// const handleChangeToVisited = (id) => {
	// 	console.log(id);
	// 	dispatch(changeToVisited(id));
	// };
	const handleChangeToOnHold = (id) => {
		setDateSelected(false);
		dispatch(changeToOnHold(id));
		const tempArr = dateOption.split("/");
		const dateModified = tempArr[2] + "-" + tempArr[1] + "-" + tempArr[0];
		dispatch(
			getDoctorsAppointmentsForDate(specialityId, doctorId, dateModified)
		);
	};
	const [selectedPatientFromOnHold, setSelectedPatientFromOnHold] =
		useState("");
	const openModel = (_id) => {
		setSelectedPatientFromOnHold(_id);
		setLgShow(true);
		dispatch(getTimeslots(doctorId, dateOption));
	};
	const [changeSlotOption, setChangeSlotOption] = useState("");
	const handleChangeToSomeSlot = () => {
		if (changeSlotOption === "") {
			alert("Please select a vaild slot");
		} else {
			dispatch(changeSlot(selectedPatientFromOnHold, changeSlotOption));
			setLgShow(false);
		}
	};

	// calling the function
	// passing array argument

	useEffect(() => {
		if (
			specialityOption !== "Choose Speciality" &&
			doctorOption !== "Choose Doctor" &&
			dateOption !== "Choose Date"
		) {
			if (dateOption !== "Choose Date") setDateSelected(true);
			setLgShow(false);
			const tempArr = dateOption.split("/");
			const dateModified = tempArr[2] + "-" + tempArr[1] + "-" + tempArr[0];
			dispatch(
				getDoctorsAppointmentsForDate(specialityId, doctorId, dateModified)
			);
		}
	}, [history, dispatch, changeOnHoldsuccess, changeOnHoldError]);

	useEffect(() => {
		if (
			specialityOption !== "Choose Speciality" &&
			doctorOption !== "Choose Doctor" &&
			dateOption !== "Choose Date"
		) {
			if (dateOption !== "Choose Date") setDateSelected(true);
			const tempArr = dateOption.split("/");
			const dateModified = tempArr[2] + "-" + tempArr[1] + "-" + tempArr[0];
			dispatch(
				getDoctorsAppointmentsForDate(specialityId, doctorId, dateModified)
			);
		}
	}, [history, dispatch, changeSlotsuccess, changeSlotError]);

	const [slotNotVisitedArr, setNotSlotVisitedArr] = useState([]);
	const [slotHoldArr, setSlotHoldArr] = useState([]);

	useEffect(() => {
		var slotArrayNotVisited = [];
		var tempNotVisitedArr = [];

		var j = 1;
		var slotArrHold = [];

		var tempNotVisitedArrFromHold = [];
		var slotArrayNotVisitedFromHold = [];

		console.log(slotArrayNotVisited);
		console.log(slotArrayNotVisitedFromHold);
		for (let i = 0; i < getBookingsall?.data?.data?.length; i++) {
			console.log(slotArrayNotVisitedFromHold);
			if (
				getBookingsall?.data?.data[i]?.slot !== "onHold" &&
				// getBookingsall?.data?.data[i]?.visited !== 1 &&
				getBookingsall?.data?.data[i]?.onHold === 0 &&
				!tempNotVisitedArr.includes(getBookingsall?.data?.data[i]?.slot)
			) {
				console.log(getBookingsall?.data?.data[i]?.slot, slotArrayNotVisited);
				slotArrayNotVisited.push([getBookingsall?.data?.data[i]?.slot]);
				console.log(slotArrayNotVisited);
				tempNotVisitedArr.push(getBookingsall?.data?.data[i]?.slot);
				console.log(tempNotVisitedArr);
			}
			if (getBookingsall?.data?.data[i]?.slot === "onHold") {
				slotArrHold.push({
					_id: getBookingsall?.data?.data[i]?._id,
					name: getBookingsall?.data?.data[i]?.name,
					slot: getBookingsall?.data?.data[i]?.slot,
					slotCount: getBookingsall?.data?.data[i]?.slotCount,
					visited: getBookingsall?.data?.data[i]?.visited,
					mobileNo: getBookingsall?.data?.data[i]?.userPhone,
					onHold: getBookingsall?.data?.data[i]?.onHold,
				});
			}
			if (
				getBookingsall?.data?.data[i]?.onHold !== 0 &&
				// getBookingsall?.data?.data[i]?.visited !== 1 &&
				!tempNotVisitedArrFromHold.includes(getBookingsall?.data?.data[i]?.slot)
			) {
				console.log(getBookingsall?.data?.data[i]?.slot);
				slotArrayNotVisitedFromHold.push([getBookingsall?.data?.data[i]?.slot]);
				tempNotVisitedArrFromHold.push(getBookingsall?.data?.data[i]?.slot);
			}
		}
		console.log(slotArrayNotVisited);
		console.log(slotArrayNotVisitedFromHold);
		console.log(slotArrayNotVisited);

		//loop for all bookings
		for (let i = 0; i < getBookingsall?.data?.data?.length; i++) {
			if (
				getBookingsall?.data?.data[i]?.slot !== "onHold" &&
				getBookingsall?.data?.data[i]?.visited !== 1 &&
				getBookingsall?.data?.data[i]?.onHold === 0
			) {
				for (let j = 0; j < slotArrayNotVisited?.length; j++) {
					//checking which slot will the current booking(getBookingsall?.data?.data[i]?) slot belong to
					if (
						slotArrayNotVisited[j][0] === getBookingsall?.data?.data[i]?.slot
					) {
						slotArrayNotVisited[j].push({
							_id: getBookingsall?.data?.data[i]?._id,
							name: getBookingsall?.data?.data[i]?.name,
							slot: getBookingsall?.data?.data[i]?.slot,
							slotCount: getBookingsall?.data?.data[i]?.slotCount,
							visited: getBookingsall?.data?.data[i]?.visited,
							mobileNo: getBookingsall?.data?.data[i]?.userPhone,
							onHold: getBookingsall?.data?.data[i]?.onHold,
						});
					}
				}
			}
			if (
				getBookingsall?.data?.data[i]?.onHold !== 0 &&
				getBookingsall?.data?.data[i]?.visited !== 1
			) {
				console.log(getBookingsall?.data?.data[i]?.onHold);
				for (let j = 0; j < slotArrayNotVisitedFromHold?.length; j++) {
					//checking which slot will the current booking(getBookingsall?.data?.data[i]?) slot belong to
					if (
						slotArrayNotVisitedFromHold[j][0] ===
						getBookingsall?.data?.data[i]?.slot
					) {
						slotArrayNotVisitedFromHold[j].push({
							_id: getBookingsall?.data?.data[i]?._id,
							name: getBookingsall?.data?.data[i]?.name,
							slot: getBookingsall?.data?.data[i]?.slot,
							slotCount: getBookingsall?.data?.data[i]?.slotCount,
							visited: getBookingsall?.data?.data[i]?.visited,
							mobileNo: getBookingsall?.data?.data[i]?.userPhone,
							onHold: getBookingsall?.data?.data[i]?.onHold,
						});
					}
				}
			}
		}
		for (let i = 0; i < slotArrayNotVisitedFromHold?.length; i++) {
			let timeSlot = slotArrayNotVisitedFromHold[i][0];
			let tempArr123 = slotArrayNotVisitedFromHold[i].slice(
				1,
				slotArrayNotVisitedFromHold[i].length + 1
			);
			tempArr123.sort(function (a, b) {
				return a["onHold"] - b["onHold"];
			});
			slotArrayNotVisitedFromHold[i] = [timeSlot];
			slotArrayNotVisitedFromHold[i] =
				slotArrayNotVisitedFromHold[i].concat(tempArr123);
		}
		console.log(slotArrayNotVisitedFromHold);

		slotArrayNotVisitedFromHold.sort(function (a, b) {
			return parseInt(a[0].split(".")[0]) - parseInt(b[0].split(".")[0]);
		});
		for (let j = 0; j < slotArrayNotVisitedFromHold?.length; j++) {
			let flag = 1;
			for (let i = 0; i < slotArrayNotVisited?.length; i++) {
				if (slotArrayNotVisitedFromHold[j][0] === slotArrayNotVisited[i][0]) {
					slotArrayNotVisited[i] = slotArrayNotVisited[i].concat(
						slotArrayNotVisitedFromHold[j]
							.slice(1, slotArrayNotVisitedFromHold[j].length + 1)
							.reverse()
					);
					console.log(slotArrayNotVisited[i]);
					flag = 0;
					break;
				}
			}
			if (flag == 1) {
				slotArrayNotVisited.push(slotArrayNotVisitedFromHold[j]);
			}
		}
		console.log(slotArrayNotVisited);
		console.log(slotArrHold);
		slotArrayNotVisited.sort(function (a, b) {
			return parseInt(a[0].split(".")[0]) - parseInt(b[0].split(".")[0]);
		});
		const temp_slotArrayNotVisited = slotArrayNotVisited;
		const temp_slotArrHold = slotArrHold;

		setSlotHoldArr(temp_slotArrHold);
		setNotSlotVisitedArr(temp_slotArrayNotVisited);
	}, [
		history,
		dispatch,
		getBookingsall,
		changeOnHoldsuccess,
		changeOnHoldError,
		changeSlotsuccess,
		changeSlotError,
	]);
	useEffect(() => {
		if (
			specialityOption !== "Choose Speciality" &&
			doctorOption !== "Choose Doctor" &&
			dateOption !== "Choose Date"
		) {
			if (dateOption !== "Choose Date") setDateSelected(true);
			const tempArr = dateOption.split("/");
			const dateModified = tempArr[2] + "-" + tempArr[1] + "-" + tempArr[0];
			dispatch(
				getDoctorsAppointmentsForDate(specialityId, doctorId, dateModified)
			);
		}
	}, [history, dispatch]);
	/////////////////////////////////Alert handling////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	//doctor bookings get

	useEffect(() => {
		if (changeOnHoldsuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(changeOnHoldReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [changeOnHoldsuccess, dispatch]);

	useEffect(() => {
		if (changeSlotsuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(changeSlotReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [changeSlotsuccess, dispatch]);

	const handlePatients = (take) => {
		if (take === "1") {
			setPatientsToBeVisited("d-block");
			setVisitedPatients("d-none");
		} else {
			setPatientsToBeVisited("d-none");
			setVisitedPatients("d-block");
		}
		console.log("you are in");
	};
	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item onClick={() => handlePatients("1")}>
					Patients To Be visited
				</Breadcrumb.Item>
				<Breadcrumb.Item onClick={() => handlePatients("2")}>
					Visited Patients
				</Breadcrumb.Item>
			</Breadcrumb>
			<div className={patientsToBeVisited}>
				{(loading ||
					changeOnHoldloading ||
					changeSlotloading ||
					getSpecialityAllloading ||
					getTimeSlotsloading) && <Loader />}

				<h1 className="header-center">Patients to be visited </h1>
				<div className="row mt-5 mb-3">
					<h6 className="col-2 offset-md-1">
						Select the filters{" "}
						<span className="text-danger">
							<b>*</b>
						</span>
					</h6>
					<DropdownButton
						className="col-2 btn"
						key="down-centered"
						id={`dropdown-button-drop-down-centered`}
						drop="down-centered"
						variant="success"
						title={specialityOption}
						onSelect={handleSpecialitySelect}
					>
						{allSpecialities.map((specialities, index) => (
							<Dropdown.Item
								eventKey={specialities.specialization + "/" + specialities._id}
							>
								{specialities.specialization}
							</Dropdown.Item>
						))}
					</DropdownButton>
					<DropdownButton
						className="col-2 btn"
						key="down-centered"
						id={`dropdown-button-drop-down-centered`}
						drop="down-centered"
						variant="success"
						title={doctorOption}
						onSelect={handleDoctorSelect}
					>
						{allDoctors.map((doctors, index) => (
							<Dropdown.Item
								eventKey={doctors.name + "/" + doctors.doctorsinfo}
							>
								{doctors.name}
							</Dropdown.Item>
						))}
					</DropdownButton>
					<DropdownButton
						className="col-2 btn"
						key="down-centered"
						id={`dropdown-button-drop-down-centered`}
						drop="down-centered"
						variant="success"
						title={dateOption}
						onSelect={handleDateSelect}
					>
						{" "}
						<div className="dropScroll">
							{days.map((day, index) => (
								<Dropdown.Item eventKey={index}>{day.currDay}</Dropdown.Item>
							))}
						</div>
					</DropdownButton>
					<Button
						id="searchButton"
						className="col-2 mt-2 btn-primary"
						onClick={submitHandler}
					>
						Search
					</Button>
				</div>
				<Container>
					{dateSelected &&
						slotNotVisitedArr.map((slot1, index) => (
							<Accordion open={open} toggle={toggle} className="mt-2">
								<AccordionItem>
									<AccordionHeader targetId={index}>{slot1[0]}</AccordionHeader>
									<AccordionBody accordionId={index}>
										<table
											className="table table-striped"
											style={{ backgroundColor: "white" }}
										>
											<thead>
												<tr>
													<th></th>
													<th scope="col">Patient No</th>
													<th scope="col">Patient Name</th>
													<th scope="col">Patient Mobile Number</th>
													<th scope="col">Status</th>
												</tr>
											</thead>
											{slot1?.slice(1, slot1.length + 1).map(
												(
													{
														_id,
														name,
														slot, //onhold
														slotCount, // onhold:1
														mobileNo,
														onHold,
													},
													sno
												) => (
													<tbody>
														<tr>
															<td></td>
															<td>{sno + 1}</td>
															<td>{name}</td>
															<td>{mobileNo}</td>
															<td>
																{onHold === 0 ? (
																	<OverlayTrigger
																		delay={{ hide: 450, show: 300 }}
																		overlay={(props) => (
																			<Tooltip {...props}>
																				Change to On Hold
																			</Tooltip>
																		)}
																		placement="bottom"
																	>
																		<button
																			className="btn btn-warning"
																			onClick={() => handleChangeToOnHold(_id)}
																		>
																			<i className="fa fa-pause"></i>
																		</button>
																	</OverlayTrigger>
																) : (
																	<div></div>
																)}
															</td>
														</tr>
													</tbody>
												)
											)}
										</table>
									</AccordionBody>
								</AccordionItem>
							</Accordion>
						))}
				</Container>
				<Container>
					{dateSelected ? (
						<Accordion open={open} toggle={toggle} className="mt-2">
							<AccordionItem>
								<AccordionHeader targetId="1">Patients On Hold</AccordionHeader>

								<AccordionBody accordionId="1">
									<table className="table table-striped">
										<thead>
											<tr>
												<th></th>
												<th scope="col">Patient No</th>
												<th scope="col">Patient Name</th>
												<th scope="col">Patient Mobile Number</th>
												<th scope="col">Status</th>
											</tr>
										</thead>
										<tbody>
											{dateSelected &&
												slotHoldArr.map(
													(
														{ _id, name, slot, slotCount, visited, mobileNo },
														sno
													) =>
														visited === 2 ? (
															<tr>
																<td></td>
																<td>{sno + 1}</td>
																<td>{name}</td>
																<td>{mobileNo}</td>
																<td>
																	{"   "}{" "}
																	{/* <button
																className='btn btn-primary'
																onClick={() => handleChangeToSomeSlot(_id)}>
																<i className='fa fa-arrow-up'></i>
															</button> */}
																	<OverlayTrigger
																		delay={{ hide: 450, show: 300 }}
																		overlay={(props) => (
																			<Tooltip {...props}>
																				Schedule Appointment
																			</Tooltip>
																		)}
																		placement="bottom"
																	>
																		<Button
																			className="btn btn-primary"
																			onClick={() => openModel(_id)}
																		>
																			<i className="fa fa-arrow-up"></i>
																		</Button>
																	</OverlayTrigger>
																	<Modal
																		size="xl"
																		show={lgShow}
																		onHide={() => setLgShow(false)}
																		aria-labelledby="example-modal-sizes-title-xl"
																	>
																		<Modal.Header closeButton>
																			<Modal.Title id="example-modal-sizes-title-xl">
																				Select Slot
																			</Modal.Title>
																		</Modal.Header>
																		<Modal.Body>
																			<div className="row offset-md-2">
																				{timeSlots.map((timeslots, index) =>
																					timeslots.isEmpty ? (
																						<Form.Check
																							onChange={() =>
																								setChangeSlotOption(
																									timeslots.timeSlot
																								)
																							}
																							className="btn btn-success col-md-3 m-1 col-10"
																							type="radio"
																							id={timeslots.timeSlot}
																							label={`${
																								timeslots.timeSlot
																							} Avl slots ${" "}
                    ${timeslots.maxCount - timeslots.curCount} `}
																							checked={
																								changeSlotOption ===
																								timeslots.timeSlot
																							}
																						/>
																					) : (
																						<Button
																							className="btn btn-danger col-md-3 m-1"
																							disabled
																						>
																							{timeslots.timeSlot} Not avaliable
																						</Button>
																					)
																				)}
																			</div>
																		</Modal.Body>
																		<Modal.Footer>
																			<Button
																				onClick={() => handleChangeToSomeSlot()}
																				className="btn btn-primary"
																			>
																				Submit
																			</Button>
																		</Modal.Footer>
																	</Modal>
																</td>
															</tr>
														) : (
															<div></div>
														)
												)}
										</tbody>
									</table>
								</AccordionBody>
							</AccordionItem>
						</Accordion>
					) : (
						<div> </div>
					)}
				</Container>
			</div>
			<div className={patientsVisited}>
				{loading && getSpecialityAllloading && getTimeSlotsloading && (
					<Loader />
				)}

				<h1 className="header-center">Visited Patients</h1>
				<div className="row mt-5 mb-3">
					<h6 className="col-2 offset-md-1">
						Select the filters{" "}
						<span className="text-danger">
							<b>*</b>
						</span>
					</h6>
					<DropdownButton
						className="col-2 btn"
						key="down-centered"
						id={`dropdown-button-drop-down-centered`}
						drop="down-centered"
						variant="success"
						title={specialityVisited}
						onSelect={handleSpecialitySelect1}
					>
						{allSpecialities.map((specialities, index) => (
							<Dropdown.Item
								eventKey={specialities.specialization + "/" + specialities._id}
							>
								{specialities.specialization}
							</Dropdown.Item>
						))}
					</DropdownButton>
					<DropdownButton
						className="col-2 btn"
						key="down-centered"
						id={`dropdown-button-drop-down-centered`}
						drop="down-centered"
						variant="success"
						title={doctorVisited}
						onSelect={handleDoctorSelect1}
					>
						{allDoctors.map((doctors, index) => (
							<Dropdown.Item eventKey={doctors.name + "/" + doctors._id}>
								{doctors.name}
							</Dropdown.Item>
						))}
					</DropdownButton>
					<input
						type="date"
						className="form-control col-2 mt-2 mr-5"
						onChange={(e) => handleDateSelect1(e.target.value)}
						max={today}
					/>
					<Button
						id="searchButton1"
						className="col-2 mt-2 btn-primary"
						onClick={searchForVisited}
					>
						Search
					</Button>
				</div>
				<Container>
					<button
						className="btn btn-success offset-md-10 mb-2"
						onClick={(e) => exportToExcel()}
					>
						<i className="fa fa-download"></i>&nbsp;Download
					</button>
					<table className="table table-striped">
						<thead>
							<tr>
								<th></th>
								<th scope="col">Patient No</th>
								<th scope="col">Patient Name</th>
								<th scope="col">Slot</th>
								<th scope="col">Patient Mobile Number</th>
							</tr>
						</thead>
						{specialityVisited !== "Choose Speciality" &&
							doctorVisited !== "Choose Doctor" &&
							visitedDate !== "" &&
							searchClicked &&
							getVisiPat?.getDoctorVisitedPatientsforParticularDate?.data?.data?.visited_patients?.map(
								({ name, mobilenumber, prescriptionId, slot }, index) => (
									<tbody>
										<tr>
											<td></td>
											<td>{index + 1}</td>
											<td>{name}</td>
											<td>{slot}</td>
											<td>{mobilenumber}</td>
										</tr>
									</tbody>
								)
							)}
					</table>
				</Container>
			</div>
		</>
	);
};

export default AppointmentListScreen;
