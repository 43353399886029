/** @format */
import Message from "../../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";

//API calls
import {
	deleteTemplate,
	getTemplateNames,
} from "../../../redux/doctorRedux/doctorApiCalls";
import { getTemplateReset } from "../../../redux/doctorRedux/Template_Management/getTemplate";
import { deleteTemplateReset } from "../../../redux/doctorRedux/Template_Management/deleteTemplate";
import { getTemplateNamesReset } from "../../../redux/doctorRedux/Template_Management/getTemplateNames";
import { allMessagesStart } from "../../../redux/allMessages";
import Table from "react-bootstrap/Table";

const DeleteTemplateScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	// success and failure Message
	const getTemplateNamesData = useSelector((state) => state.getTemplateNames);
	const templatenames = getTemplateNamesData.templatenames;
	const templatenamessuccess = getTemplateNamesData.success;
	const templatenameserror = getTemplateNamesData.error;
	const templatenamesloading = getTemplateNamesData.loading;

	const gettemplatesData = useSelector((state) => state.getTemplate);
	const templates = gettemplatesData;
	const getTemplatessuccess = gettemplatesData.success;
	const getTemplateserror = gettemplatesData.error;
	const getTemplatesloading = gettemplatesData.loading;

	const deleteTemplateData = useSelector((state) => state.deleteTemplate);
	const deleteTemplatesuccess = deleteTemplateData.success;
	const deleteTemplateerror = deleteTemplateData.error;
	const deleteTemplateloading = deleteTemplateData.loading;

	/////////////////////////////////////////////////////

	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { doctorInfo } = doctorLogin;

	console.log(doctorInfo?.data?.user?._id);

	useEffect(() => {
		dispatch(getTemplateNames(doctorInfo?.data?.user?._id));
	}, []);

	useEffect(() => {
		dispatch(getTemplateNames(doctorInfo?.data?.user?._id));
	}, [deleteTemplatesuccess, deleteTemplateerror, deleteTemplateloading]);

	//Delete Template

	const handleDeleteTemplate = (templateId) => {
		dispatch(deleteTemplate(doctorInfo?.data?.user?._id, templateId));
	};
	//ALerts//////////////////////////////////////////////////////////////////

	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);

	useEffect(() => {
		if (deleteTemplatesuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(deleteTemplateReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [deleteTemplatesuccess, dispatch]);

	return (
		<div className="pageSize">
			<Meta />
			{(templatenamesloading ||
				getTemplatesloading ||
				deleteTemplateloading) && <Loader />}

			<div>
				<h2 className="header-center mt-3 mb-3">Delete Medicine Template</h2>
				<Container>
					<Table striped bordered hover className="custom-table">
						<thead>
							<tr>
								<th scope="col">S.No</th>
								<th scope="col">Template Name</th>
								<th scope="col">Operations</th>
							</tr>
						</thead>
						<tbody>
							{templatenames?.data?.data ? (
								templatenames?.data?.data.map((name, index) => (
									<tr>
										<th scope="row">{index + 1}</th>
										<td>{name}</td>
										<td>
											<button className="m-2 btn btn-danger">
												<i
													className="fa fa-trash"
													onClick={() => handleDeleteTemplate(index)}
												></i>
											</button>
										</td>
									</tr>
								))
							) : (
								<div></div>
							)}
						</tbody>
					</Table>
				</Container>
			</div>
		</div>
	);
};

export default DeleteTemplateScreen;
