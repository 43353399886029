/** @format */

import { createSlice } from "@reduxjs/toolkit";

const editTestDetailsReducer = createSlice({
	name: "editTestDetails",
	initialState: {},
	reducers: {
		editTestDetailsRequest: (state) => {
			state.loading = true;
		},
		editTestDetailsSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.editTestDetails = action.payload;
			state.error = false;
			state.success = true;
		},
		editTestDetailsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editTestDetailsReset: (state) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editTestDetailsRequest,
	editTestDetailsSuccess,
	editTestDetailsFailure,
	editTestDetailsReset,
} = editTestDetailsReducer.actions;
export default editTestDetailsReducer.reducer;
