/** @format */

import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Table from "react-bootstrap/Table";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Message from "../../../Components/Message";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import axios from "axios";
import { SERVER_URL } from "../../../App";
import Button from "react-bootstrap/Button";
import {
	getLabDepts,
	editLabTest,
} from "../../../redux/adminRedux/adminApiCalls";

const EditLabTestScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	const [deptName, setDeptName] = useState("Choose Department");
	const [deptId, setDeptId] = useState("");
	const [testName, setTestName] = useState("Choose Test");
	const [deptTestNames, setdeptTestNames] = useState([]);
	const [testIdx, setTestIdx] = useState([]);
	const handleDeptSelect = (eventKey) => {
		setDeptName(eventKey.split("@@")[0]);
		setDeptId(eventKey.split("@@")[1]);
		setTestIdx(eventKey.split("@@")[2]);
		setdeptTestNames(
			getlabDeptAll?.data?.data[eventKey.split("@@")[2]].testDetails
		);
	};

	const handleTestSelect = (eventKey) => {
		setTestName(eventKey);
		setTestNameTestDetails(() => {
			const testData = getlabDeptAll?.data?.data[testIdx]?.testDetails?.find(
				(test) => test?.testName === eventKey
			);
			return testData ? testData.testName : undefined;
		});
		setTestPriceTestDetails(() => {
			const testData = getlabDeptAll?.data?.data[testIdx]?.testDetails?.find(
				(test) => test?.testName === eventKey
			);
			return testData ? testData.testPrice : undefined;
		});
		setReferralAmtTestDetails(() => {
			const testData = getlabDeptAll?.data?.data[testIdx]?.testDetails?.find(
				(test) => test?.testName === eventKey
			);
			return testData ? testData.referralAmount : undefined;
		});
		setTestDescTestDetails(() => {
			const testData = getlabDeptAll?.data?.data[testIdx]?.testDetails?.find(
				(test) => test?.testName === eventKey
			);
			return testData ? testData.testDesc : undefined;
		});
	};

	// Add Lab Test Details

	const { getlabDeptAll } = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	const { editTestDetails, loading } = useSelector(
		(state) => state.editTestOfReport
	);

	useEffect(() => {
		dispatch(getLabDepts());
	}, []);

	useEffect(() => {
		dispatch(getLabDepts());
	}, [loading]);

	const { getTestNameall } = useSelector((state) => state.getTestNamesOfReport);
	console.log(getTestNameall);

	const [testNameTestDetails, setTestNameTestDetails] = useState("");
	const [testPriceTestDetails, setTestPriceTestDetails] = useState("");
	const [referralAmtTestDetails, setReferralAmtTestDetails] = useState("");
	const [testDescTestDetails, setTestDescTestDetails] = useState("");
	const [testInd, setTestInd] = useState(0);

	const handleSubmitTestDetails = () => {
		dispatch(
			editLabTest(
				deptId,
				testName,
				testIdx,
				testNameTestDetails,
				testPriceTestDetails,
				0,
				testDescTestDetails
			)
		);
		setEditClicked(false);
		dispatch(getLabDepts());
		setDeptName(deptName);
		setDeptId(deptId);
		handleTestSelect(testName);
		setTestNameTestDetails(testNameTestDetails);
		setTestPriceTestDetails(testPriceTestDetails);
		setReferralAmtTestDetails(referralAmtTestDetails);
		setTestDescTestDetails(testDescTestDetails);
	};
	const [editClicked, setEditClicked] = useState(false);
	const today = new Date().toISOString().split("T")[0];

	const handleDiscardChanges = () => {
		setEditClicked(false);
		setTestNameTestDetails(() => {
			const testData = getlabDeptAll?.data?.data[testIdx]?.testDetails?.find(
				(test) => test?.testName === testName
			);
			return testData ? testData.testName : undefined;
		});
		setTestPriceTestDetails(() => {
			const testData = getlabDeptAll?.data?.data[testIdx]?.testDetails?.find(
				(test) => test?.testName === testName
			);
			return testData ? testData.testPrice : undefined;
		});
		setReferralAmtTestDetails(() => {
			const testData = getlabDeptAll?.data?.data[testIdx]?.testDetails?.find(
				(test) => test?.testName === testName
			);
			return testData ? testData.referralAmount : undefined;
		});
		setTestDescTestDetails(() => {
			const testData = getlabDeptAll?.data?.data[testIdx]?.testDetails?.find(
				(test) => test?.testName === testName
			);
			return testData ? testData.testDesc : undefined;
		});
	};
	return (
		<>
			<Meta />

			<div>
				<h1 className="header-center">Edit Lab Test </h1>
				<Container className="header-center mt-5">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center mt-1">
							<h6>
								<b>
									Select the filters <span className="text-danger">*</span>
								</b>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={deptName}
								onSelect={handleDeptSelect}
							>
								{getlabDeptAll?.data?.data?.length === 0 ? (
									<Dropdown.Item>
										{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
									</Dropdown.Item>
								) : (
									getlabDeptAll?.data?.data?.map(
										(dept, index) =>
											dept?.deptName !== "hospital" &&
											dept?.deptName !== 0 && (
												<Dropdown.Item
													eventKey={
														dept.deptName + "@@" + dept._id + "@@" + index
													}
												>
													{dept.deptName}
												</Dropdown.Item>
											)
									)
								)}
							</DropdownButton>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={testName}
								onSelect={handleTestSelect}
							>
								{deptTestNames?.length === 0 ? (
									<Dropdown.Item>
										{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
									</Dropdown.Item>
								) : (
									deptTestNames?.map(
										(test, index) =>
											test.status !== 0 && (
												<Dropdown.Item eventKey={test.testName}>
													{test.testName}
												</Dropdown.Item>
											)
									)
								)}
							</DropdownButton>
						</Col>
					</Row>
				</Container>

				{deptName !== "Choose Department" && testName !== "Choose Test" && (
					<>
						<button
							className="btn btn-outline-success offset-8 offset-md-10 mt-2 mr-2"
							onClick={() => setEditClicked(true)}
						>
							<i className="fa fa-edit"></i>
							{"  "}Edit
						</button>
						<div className="row col-md-8 offset-md-3 form-group row">
							<label className="form-label col-md-3" htmlFor="speciality">
								<b>Test Name: </b>
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							{editClicked ? (
								<input
									className="form-control col-md-6"
									type="text"
									placeholder="Enter Test Name"
									required="required"
									value={testNameTestDetails}
									onChange={(e) => {
										setTestNameTestDetails(e.target.value);
									}}
									disabled
								/>
							) : (
								<div>{testNameTestDetails}</div>
							)}
						</div>
						<div className="row col-md-8 offset-md-3 form-group row">
							<label className="form-label col-md-3" htmlFor="speciality">
								<b>Test Price: </b>
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							{editClicked ? (
								<input
									className="form-control col-md-6"
									type="number"
									placeholder="Enter Test Price"
									required="required"
									value={testPriceTestDetails}
									onChange={(e) => {
										setTestPriceTestDetails(e.target.value);
									}}
								/>
							) : (
								<div>{testPriceTestDetails}</div>
							)}
						</div>
						{/* <div className='row col-md-8 offset-md-3 form-group row'>
							<label className='form-label col-md-3' htmlFor='speciality'>
								<b>Referral Amount: </b>
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
							{editClicked ? (
								<input
									className='form-control col-md-6'
									type='number'
									placeholder='Enter Referral Amount'
									required='required'
									value={referralAmtTestDetails}
									onChange={(e) => {
										setReferralAmtTestDetails(e.target.value);
									}}
								/>
							) : (
								<div>{referralAmtTestDetails}</div>
							)}
						</div> */}
						<div className="row col-md-8 offset-md-3 form-group row">
							<label className="form-label col-md-3" htmlFor="speciality">
								<b>Test Description: </b>
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							{editClicked ? (
								<input
									className="form-control col-md-6"
									type="text"
									placeholder="Enter Test Description"
									required="required"
									value={testDescTestDetails}
									onChange={(e) => {
										setTestDescTestDetails(e.target.value);
									}}
								/>
							) : (
								<div>{testDescTestDetails}</div>
							)}
						</div>
						{editClicked && (
							<div className="col-md-5 row offset-md-5 form-group">
								<button
									className="btn btn-danger mr-3 mt-3"
									onClick={handleDiscardChanges}
								>
									Discard Changes
								</button>
								<button
									className="btn btn-success mt-3"
									onClick={handleSubmitTestDetails}
								>
									Submit
								</button>
							</div>
						)}
					</>
				)}
			</div>
		</>
	);
};
export default EditLabTestScreen;
