/** @format */

import { createSlice } from '@reduxjs/toolkit';

const editLabTestTemplateReducer = createSlice({
	name: 'editLabTestTemplate',
	initialState: {},
	reducers: {
		editLabTestTemplateRequest: (state) => {
			state.loading = true;
		},
		editLabTestTemplateSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.editLabTestTemplate = action.payload;
			state.error = false;
			state.success = true;
		},
		editLabTestTemplateFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editLabTestTemplateReset: (state) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editLabTestTemplateRequest,
	editLabTestTemplateSuccess,
	editLabTestTemplateFailure,
	editLabTestTemplateReset,
} = editLabTestTemplateReducer.actions;
export default editLabTestTemplateReducer.reducer;
