/** @format */

import { createSlice } from '@reduxjs/toolkit';

const GetPrescriptionTemplate = createSlice({
	name: 'GetPrescriptionTemplate',
	initialState: {},
	reducers: {
		GetPrescriptionTemplateStart: (state) => {
			state.loading = true;
			state.getPrescriptionTemplate = [];
			state.error = false;
			state.success = false;
		},
		GetPrescriptionTemplateSuccess: (state, action) => {
			state.loading = false;
			state.getPrescriptionTemplate = action.payload;
			state.error = false;
			state.success = true;
		},
		GetPrescriptionTemplateFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		GetPrescriptionTemplateReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	GetPrescriptionTemplateStart,
	GetPrescriptionTemplateSuccess,
	GetPrescriptionTemplateFailure,
	GetPrescriptionTemplateReset,
} = GetPrescriptionTemplate.actions;
export default GetPrescriptionTemplate.reducer;
