/** @format */

import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import {
	Button,
	Form,
	FormControl,
	FormLabel,
	FormCheck,
	Modal,
	Container,
	Row,
	Col,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEdit,
	faTrash,
	faPlus,
	faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
	GetPrescriptionTemplateNames,
	GetPrescriptionTemplate,
} from "../../../redux/doctorRedux/doctorApiCalls";
import { Pagination } from "react-bootstrap";
import { createCategory } from "../../../redux/userApiCalls";
import { getUserDataById } from "../../../redux/prescriptionRedux/prescriptionApiCalls";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
	getTemplate1,
	getTemplateNames, //
} from "../../../redux/doctorRedux/doctorApiCalls";
import { getLabDepts } from "../../../redux/adminRedux/adminApiCalls";
import {
	addVitalInSpeciality,
	getVitalInSpeciality,
} from "../../../redux/adminRedux/adminApiCalls";
import {
	getCategoriesVitals, //
} from "../../../redux/prescriptionRedux/prescriptionApiCalls";
import {
	createPrescription, //
} from "../../../redux/prescriptionRedux/prescriptionApiCalls";
import { getHistoryTemplateInSpeciality } from "../../../redux/historyRedux/historyApiCalls";
import {
	createHistoryForPatient,
	getPatientHistory,
} from "../../../redux/historyRedux/historyApiCalls";
import Table from "react-bootstrap/Table";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";
import { editHistoryByIdx } from "../../../redux/historyRedux/historyApiCalls";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
	getHistoryTemplateInSpecialityFailure,
	getHistoryTemplateInSpecialityStart,
} from "../../../redux/historyRedux/History_Management/getHistoryTemplateInSpeciality";

const StartHistoryScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);

	const [historyItems, setHistoryItems] = useState(null);

	useEffect(() => {
		// Get query parameters from the URL
		const params = new URLSearchParams(window.location.search);
		const historyItemsParam = params.get("historyItems");

		if (historyItemsParam) {
			try {
				// Parse and decode the historyItems from the query parameter
				const parsedHistoryItems = JSON.parse(
					decodeURIComponent(historyItemsParam)
				);
				setHistoryItems(parsedHistoryItems);
			} catch (e) {
				console.error("Failed to parse history items", e);
			}
		}
	}, []);

	useEffect(() => {
		if (historyItems?.speciality !== undefined)
			dispatch(GetPrescriptionTemplateNames(historyItems?.speciality));
	}, [historyItems]);

	const userdataLoading = useSelector((state) => state.getUserDataById);

	useEffect(() => {
		if (userdata?.data?.data?.historyId) {
			console.log(userdata?.data?.data?.historyId);
			dispatch(getPatientHistory(userdata?.data?.data?.historyId));
		}
	}, [userdataLoading?.loading]);

	let { getHistoryByIdx } = useSelector((state) => state.getHistoryByIdx);

	const Pasthistory = getHistoryByIdx?.data?.data?.history;
	const reversedHistory = Array.isArray(Pasthistory)
		? [...Pasthistory].reverse()
		: [];

	if (Array.isArray(Pasthistory)) {
		getHistoryByIdx = {
			...getHistoryByIdx,
			data: {
				...getHistoryByIdx.data,
				data: {
					...getHistoryByIdx.data.data,
					history: reversedHistory,
				},
			},
		};
	}

	const { getPrescriptionTemplateNames } = useSelector(
		(state) => state.GetPrescriptionTemplateNames
	);

	console.log(getPrescriptionTemplateNames);

	const { userdata } = useSelector((state) => state.getUserDataById);

	console.log(userdata);
	useEffect(() => {
		if (historyItems?.speciality !== undefined)
			dispatch(
				getUserDataById(
					historyItems.id,
					historyItems.name,
					historyItems?.userIdx
				)
			);
	}, [historyItems]);

	const [templateName, setTemplateName] = useState([]);
	const [tempName, setTempName] = useState("Choose Template");
	const [templateIdx, setTemplateIdx] = useState([]);
	const [formValues, setFormValues] = useState({}); // State variable to hold form values

	const [getTemplateDataEdited, setGetTemplateDataEdited] = useState(false);

	const TemplateHandler = async (temp) => {
		let arr = temp?.split("@@");
		setTempName(arr[0]);
		let templates = [...templateName];
		templates?.push(arr[0]);
		let templatesidx = [...templateIdx];
		templatesidx?.push(arr[2]);
		setTemplateName(templates);
		setTemplateIdx(templatesidx);
		setGetTemplateDataEdited(false);
		handleClose();
		setTempName("Choose Template");
		dispatch(getHistoryTemplateInSpeciality(historyItems?.speciality, arr[2]));
	};

	let { getHistoryTemplateinSpeciality, loading, success } = useSelector(
		(state) => state.getHistoryTemplateInSpeciality
	);

	useEffect(() => {
		dispatch(getHistoryTemplateInSpecialityStart());
		setHistoryTemplate([]);
		getHistoryTemplateinSpeciality = [];
	}, []);

	const [historyTemplate, setHistoryTemplate] = useState([]);

	useEffect(() => {
		if (success) {
			let templates = [...historyTemplate];
			templates?.push(
				getHistoryTemplateinSpeciality?.data?.data?.historyTemplates[0]
					?.template
			);
			setHistoryTemplate(templates);
		}
	}, [loading]);

	console.log(historyTemplate);

	console.log(getHistoryTemplateinSpeciality);

	// Handler for input elements
	const handleInputChange = (event, label, index, eleindex) => {
		const templateArray = historyTemplate[index];

		const updatedFormData = templateArray.map((element, arrIndex) => {
			if (arrIndex === eleindex) {
				return { ...element, value: event?.target?.value };
			}
			return element;
		});
		let templates = [...historyTemplate];
		templates[index] = updatedFormData;

		setHistoryTemplate(templates);
	};

	// Handler for checkbox and radio elements
	const handleCheckboxRadioChange = (event, label, option, index, eleindex) => {
		if (event.target.type === "checkbox") {
			// For checkboxes, toggle the selected option
			const selectedOptions =
				historyTemplate[activePage - 1][eleindex]?.value || [];
			const updatedOptions = event.target.checked
				? [...selectedOptions, option]
				: selectedOptions.filter((item) => item !== option);

			const templateArray = historyTemplate[activePage - 1];

			const updatedFormData = templateArray.map((element, arrIndex) => {
				if (arrIndex === eleindex) {
					return { ...element, value: updatedOptions };
				}
				return element;
			});
			let templates = [...historyTemplate];
			templates[activePage - 1] = updatedFormData;

			setHistoryTemplate(templates);
		} else if (event.target.type === "radio") {
			// For radio buttons, select only the clicked option
			const templateArray = historyTemplate[activePage - 1];

			const updatedFormData = templateArray.map((element, arrIndex) => {
				if (arrIndex === eleindex) {
					return { ...element, value: option };
				}
				return element;
			});
			let templates = [...historyTemplate];
			templates[activePage - 1] = updatedFormData;

			setHistoryTemplate(templates);
		}
	};

	const handleMultiselectChange = (selectedOptions, label, index, eleindex) => {
		// Extract labels and values from selectedOptions
		const selectedValues = selectedOptions.map((option) => ({
			label: option.label,
			value: option.value,
		}));

		const templateArray = historyTemplate[activePage - 1];

		const updatedFormData = templateArray.map((element, arrIndex) => {
			if (arrIndex === eleindex) {
				return { ...element, value: selectedValues };
			}
			return element;
		});
		let templates = [...historyTemplate];
		templates[activePage - 1] = updatedFormData;

		setHistoryTemplate(templates);
	};

	// Handler for select
	const handleSelectChange = (event, label, index, eleindex) => {
		const templateArray = historyTemplate[activePage - 1];

		const updatedFormData = templateArray.map((element, arrIndex) => {
			if (arrIndex === eleindex) {
				return { ...element, value: event?.target?.value };
			}
			return element;
		});
		let templates = [...historyTemplate];
		templates[activePage - 1] = updatedFormData;

		setHistoryTemplate(templates);
	};

	const handleSubmitForm = async () => {
		const hasEmptyStringOrUndefinedInHistoryTemplate = historyTemplate.some(
			(item) => item === "" || item === undefined
		);

		if (hasEmptyStringOrUndefinedInHistoryTemplate) {
			alert("Empty page cannot be submitted");
		} else {
			console.log("Form submitted:", historyTemplate);
			setAddNewHistory(false);
			await dispatch(
				createHistoryForPatient(
					historyItems?.id,
					historyItems?.userIdx,
					doctorInfo !== undefined
						? doctorInfo?.data?.user?.name
						: historyItems?.docname,
					doctorInfo !== undefined
						? doctorInfo?.data?.user?._id
						: historyItems?.doctorId,
					Date.now(),
					historyTemplate,
					userdata?.data?.data?.historyId,
					historyItems?.specialityName,
					templateName
				)
			);
			await dispatch(getPatientHistory(userdata?.data?.data?.historyId));
		}
	};

	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState([0]);

	const [editClicked, setEditClicked] = useState([]);

	const editBtnClicked = (templateIndex) => {
		let editbuttons = [...editClicked];
		editbuttons[templateIndex] = true;
		setEditClicked(editbuttons);
		setFormElements(historyTemplate[templateIndex]);
	};

	const handleEditDisable = () => {
		let editbuttons = [...editClicked];
		editbuttons[activePage - 1] = false;
		setEditClicked(editbuttons);
	};

	console.log(editClicked);

	// Form Builder Code

	const [formElements, setFormElements] = useState([]);
	const [selectedElementIndex, setSelectedElementIndex] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editLabel, setEditLabel] = useState("");
	const [editOptions, setEditOptions] = useState([]);
	const [isRequired, setIsRequired] = useState(false);
	const [editHeaderValue, setEditHeaderValue] = useState("");
	const [editParagraphValue, setEditParagraphValue] = useState("");
	const [draggingElement, setDraggingElement] = useState("");
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [editModalOptions, setEditModalOptions] = useState([]);
	const [newOption, setNewOption] = useState("");
	const [showAddOptionInput, setShowAddOptionInput] = useState(false);

	console.log(selectedOptions);

	const addFormElement = (elementType, label = "Label Name") => {
		let defaultOptions = [];
		if (
			elementType === "radio" ||
			elementType === "checkbox" ||
			elementType === "select" ||
			elementType === "multiselect"
		) {
			defaultOptions = ["Option 1", "Option 2", "Option 3"];
		}
		setFormElements([
			...formElements,
			{
				type: elementType,
				label:
					elementType === "header"
						? "Header"
						: elementType === "paragraph"
						? "Paragraph"
						: label,
				value:
					elementType === "header"
						? "Header"
						: elementType === "paragraph"
						? "Paragraph"
						: "",
				options: elementType === "header" ? [] : defaultOptions,
				required: false,
			},
		]);
	};

	const handleDragStart = (event, elementType) => {
		event.dataTransfer.setData("elementType", elementType);
		setDraggingElement(elementType);
	};

	const handleValueChange = (event, index) => {
		const updatedElements = [...formElements];
		updatedElements[index].value = event.target.value;
		setFormElements(updatedElements);
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const elementType = event.dataTransfer.getData("elementType");
		if (elementType === "header") {
			addFormElement("header");
		} else if (elementType === "paragraph") {
			addFormElement("paragraph");
			setDraggingElement(false);
		} else {
			addFormElement(elementType);
		}
		setDraggingElement("");
	};

	const handleItemClick = (elementType) => {
		addFormElement(elementType);
	};

	const handleLabelChange = (event, index) => {
		const updatedElements = [...formElements];
		if (updatedElements[index]) {
			updatedElements[index].label = event.target.value;
			setFormElements(updatedElements);
		}
	};

	const handleOptionChange = (event, index) => {
		const updatedOptions = [...editOptions];
		updatedOptions[index] = event.target.value;
		setEditOptions(updatedOptions);
	};

	const handleAddOption = () => {
		setShowAddOptionInput(true);
	};

	const handleDeleteOption = (index) => {
		const updatedOptions = [...editOptions];
		updatedOptions.splice(index, 1);
		setEditOptions(updatedOptions);
	};

	const handleDeleteElement = (index) => {
		const updatedElements = [...formElements];
		updatedElements.splice(index, 1);
		setFormElements(updatedElements);
		setSelectedElementIndex(null);
	};

	const handleEditModalClose = () => {
		setShowEditModal(false);
	};

	const handleEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].label = editLabel;
				if (
					updatedElements[selectedElementIndex].type !== "header" &&
					updatedElements[selectedElementIndex].type !== "paragraph"
				) {
					updatedElements[selectedElementIndex].options = editOptions;
					updatedElements[selectedElementIndex].required = isRequired;
				} else if (updatedElements[selectedElementIndex].type === "header") {
					updatedElements[selectedElementIndex].value = editHeaderValue;
				} else if (updatedElements[selectedElementIndex].type === "paragraph") {
					updatedElements[selectedElementIndex].value = editParagraphValue;
				}
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const openEditModal = (index) => {
		setSelectedElementIndex(index);
		if (formElements[index]) {
			setEditLabel(formElements[index].label);
			setEditOptions(formElements[index].options);
			setIsRequired(formElements[index].required);
			setEditHeaderValue(formElements[index].value);
			setEditParagraphValue(formElements[index].value);
		}
		setShowEditModal(true);
	};

	const handleSubmitFormBuilder = () => {
		console.log({ formElements });
		let prestemp = [...historyTemplate];
		prestemp[activePage - 1] = formElements;
		setHistoryTemplate(prestemp);
		let editbuttons = [...editClicked];
		editbuttons[activePage - 1] = false;
		setEditClicked(editbuttons);
	};

	const addHeaderToForm = () => {
		addFormElement("header");
	};

	const addParagraphToForm = () => {
		addFormElement("paragraph");
	};

	const handleHeaderEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].value = editHeaderValue;
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const handleParagraphEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].value = editParagraphValue;
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const [open, setOpen] = useState([]);
	const toggle = (id) => {
		if (editHistoryIndex !== "") {
			alert("Close all the edits.");
		} else if (open.includes(id)) {
			setOpen(open.filter((key) => key !== id));
		} else {
			setOpen([...open, id]);
		}
	};

	const [addNewHistory, setAddNewHistory] = useState(false);

	const [editHistoryIndex, setEditHistoryIndex] = useState("");

	const [activePageEdit, setActivePageEdit] = useState(1);

	const [finalHistoryTemplate, setFinalHistoryTemplate] = useState(
		getHistoryByIdx?.data?.data?.history[
			editHistoryIndex ? editHistoryIndex : 0
		]?.historyData
	);

	console.log(getHistoryByIdx?.data?.data?.history[0]);

	const editHistoryClicked = (index) => {
		console.log(index);
		setEditHistoryIndex(index);
		setFormElements(
			getHistoryByIdx?.data?.data?.history[index]?.historyData[
				activePageEdit - 1
			]
		);
		setOpen([index]);
	};

	const handlePageChangeEdit = (number) => {
		setFinalHistoryTemplate(
			getHistoryByIdx?.data?.data?.history[
				editHistoryIndex ? editHistoryIndex : 0
			]?.historyData
		);
		let temptemplate = [
			...getHistoryByIdx?.data?.data?.history[
				editHistoryIndex ? editHistoryIndex : 0
			]?.historyData,
		];
		temptemplate[activePageEdit - 1] = formElements;
		setFinalHistoryTemplate(temptemplate);
		setActivePageEdit(number);
		setFormElements(
			getHistoryByIdx?.data?.data?.history[editHistoryIndex]?.historyData[
				number - 1
			]
		);
	};

	const handleSubmitFormBuilderEdit = () => {
		let temptemplate = [...finalHistoryTemplate];
		temptemplate[activePageEdit - 1] = formElements;
		setFinalHistoryTemplate(temptemplate);
		console.log(temptemplate);
		setEditHistoryIndex("");
		dispatch(
			editHistoryByIdx(
				userdata?.data?.data?.historyId,
				getHistoryByIdx?.data?.data?.history?.length - 1 - editHistoryIndex,
				temptemplate
			)
		);

		console.log(userdata?.data?.data?.historyId);
		dispatch(getPatientHistory(userdata?.data?.data?.historyId));
	};

	const handleEditDisableEdit = () => {
		setAddNewHistory(false);

		setEditHistoryIndex("");

		setActivePageEdit(1);

		setFinalHistoryTemplate(
			getHistoryByIdx?.data?.data?.history[
				editHistoryIndex ? editHistoryIndex : 0
			]?.historyData
		);

		setFormElements([]);
	};

	const handleValueChangeEdit = (event, index) => {
		setFormElements((prevElements) => {
			return prevElements.map((element, i) =>
				i === index ? { ...element, value: event.target.value } : element
			);
		});
	};

	const handleCheckboxRadioChangeEdit = (event, label, option, eleindex) => {
		if (event.target.type === "checkbox") {
			// For checkboxes, toggle the selected option
			const selectedOptions = formElements[eleindex]?.value || [];
			const updatedOptions = event.target.checked
				? [...selectedOptions, option]
				: selectedOptions.filter((item) => item !== option);

			const templateArray = formElements;

			const updatedFormData = templateArray.map((element, arrIndex) => {
				if (arrIndex === eleindex) {
					return { ...element, value: updatedOptions };
				}
				return element;
			});
			let templates = [...formElements];
			templates = updatedFormData;

			setFormElements(templates);
		} else if (event.target.type === "radio") {
			// For radio buttons, select only the clicked option
			const templateArray = formElements;

			const updatedFormData = templateArray.map((element, arrIndex) => {
				if (arrIndex === eleindex) {
					return { ...element, value: option };
				}
				return element;
			});
			let templates = [...formElements];
			templates = updatedFormData;

			setFormElements(templates);
		}
	};

	const handleMultiselectChangeEdit = (selectedOptions, label, eleindex) => {
		// Extract labels and values from selectedOptions
		const selectedValues = selectedOptions.map((option) => ({
			label: option.label,
			value: option.value,
		}));

		const templateArray = formElements;

		const updatedFormData = templateArray.map((element, arrIndex) => {
			if (arrIndex === eleindex) {
				return { ...element, value: selectedValues };
			}
			return element;
		});
		let templates = [...formElements];
		templates = updatedFormData;

		setFormElements(templates);
	};

	// Handler for select
	const handleSelectChangeEdit = (event, label, eleindex) => {
		const templateArray = formElements;

		const updatedFormData = templateArray.map((element, arrIndex) => {
			if (arrIndex === eleindex) {
				return { ...element, value: event?.target?.value };
			}
			return element;
		});
		let templates = [...formElements];
		templates = updatedFormData;

		setFormElements(templates);
	};

	const handleHeaderParagrapghEdit = (event, label, eleindex) => {
		const templateArray = formElements;
		const updatedFormData = templateArray.map((element, arrIndex) => {
			if (arrIndex === eleindex) {
				return { ...element, value: event?.target?.value };
			}
			return element;
		});
		let templates = [...formElements];
		templates = updatedFormData;
		setFormElements(templates);
	};

	const handleNotParagraphEditModalSaveEdit = () => {
		console.log(editOptions);
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				// Create a new object with updated options to avoid mutation of a read-only object
				updatedElements[selectedElementIndex] = {
					...updatedElements[selectedElementIndex],
					options: editOptions,
				};
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
		setEditOptions([]);
	};

	// const reportTemplateRef = useRef();

	// const handleDownloadPdf = useReactToPrint({
	// 	content: () => reportTemplateRef.current,
	// 	documentTitle: "Visitor Pass",
	// 	onBeforeGetContent: () => {
	// 		// Apply styles to the content to add margins
	// 		const content = reportTemplateRef.current;
	// 		content.style.margin = "30mm 0 0 0"; // Adjust the margin as needed
	// 		return content;
	// 	},
	// 	onAfterPrint: () => console.log("Printed PDF successfully!"),
	// });

	// const handleDownloadPdf = async () => {
	// 	if (!reportTemplateRef.current) return;

	// 	const element = reportTemplateRef.current;
	// 	const { width: contentWidth, height: contentHeight } =
	// 		element.getBoundingClientRect();

	// 	const scaleFactor = 2; // Adjust scale factor if necessary

	// 	const canvas = await html2canvas(element, {
	// 		scale: scaleFactor,
	// 		useCORS: true,
	// 		width: contentWidth,
	// 		windowHeight: contentHeight,
	// 		scrollY: -window.scrollY, // Capture the entire content
	// 		logging: false,
	// 		letterRendering: true,
	// 		allowTaint: true,
	// 		imageTimeout: 15000,
	// 		removeContainer: true,
	// 		backgroundColor: "#ffffff",
	// 		dpi: window.devicePixelRatio * 96,
	// 	});

	// 	const imgData = canvas.toDataURL("image/jpeg", 1.0);
	// 	const pdf = new jsPDF({
	// 		orientation: "portrait",
	// 		unit: "mm",
	// 		format: "a4",
	// 	});

	// 	const pdfWidth = 210; // A4 width in mm
	// 	const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio

	// 	// Ensure that the pdfHeight is within valid bounds for an A4 page
	// 	if (pdfHeight <= 297) {
	// 		// A4 height in mm
	// 		pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
	// 	} else {
	// 		// If the content height exceeds A4 size, we need to handle multiple pages
	// 		let position = 0;
	// 		while (position < canvas.height) {
	// 			const pageHeight = Math.min(
	// 				297,
	// 				((canvas.height - position) * pdfWidth) / canvas.width
	// 			);
	// 			const pageImage = canvas.toDataURL(
	// 				"image/jpeg",
	// 				1.0,
	// 				position,
	// 				0,
	// 				canvas.width,
	// 				pageHeight
	// 			);
	// 			pdf.addImage(pageImage, "JPEG", 0, 0, pdfWidth, pageHeight);
	// 			position += (pageHeight * canvas.width) / pdfWidth;

	// 			if (position < canvas.height) {
	// 				pdf.addPage();
	// 			}
	// 		}
	// 	}

	// 	pdf.save("download.pdf");
	// };

	const [selectedTables, setSelectedTables] = useState([]);

	const handleCheckboxChange = (pageIndex) => {
		if (selectedTables.includes(pageIndex)) {
			setSelectedTables(selectedTables.filter((index) => index !== pageIndex));
		} else {
			setSelectedTables([...selectedTables, pageIndex]);
		}
	};

	const handlePrint = () => {
		// Filter and print the selected tables
		const printContents = selectedTables
			.map(
				(pageIndex) => document.getElementById(`table-${pageIndex}`).outerHTML
			)
			.join("");
		const originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();
		document.body.innerHTML = originalContents;
	};

	const deletePage = (index) => {
		if (index === 0) {
			alert("Atleast 1 page needs to be submitted");
		} else {
			// eslint-disable-next-line no-restricted-globals
			if (confirm(`Do you want to delete ${templateName[index]} section?`)) {
				let pages = [...totalPages];
				let removedItem = pages?.pop();
				setTotalPages(pages);

				let templatesSelect = [...templateName];
				templatesSelect?.splice(index, 1);
				setTemplateName(templatesSelect);

				let templatesIdxSelect = [...templateIdx];
				templatesIdxSelect?.splice(index, 1);
				setTemplateIdx(templatesIdxSelect);

				let historyTemplatesSelect = [...historyTemplate];
				historyTemplatesSelect?.splice(index, 1);
				setHistoryTemplate(historyTemplatesSelect);

				let editbuttons = [...editClicked];
				editbuttons?.splice(index, 1);
				setEditClicked(editbuttons);
				// handlePageChange(1);
			}
		}
	};

	console.log(activePage);

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<div className='pageSize'>
			<Container className='header-center mb-5 mt-3'>
				{editHistoryIndex === "" && (doctorInfo || receptionInfo) && (
					<Row className='justify-content-end'>
						{addNewHistory ? (
							<>
								<Col md='auto' className='text-center mt-1'>
									<button
										className='btn btn-warning'
										onClick={() => setAddNewHistory(false)}>
										<i class='fa fa-reply'></i> Exit Edit Mode
									</button>
								</Col>
								<Col md='auto' className='text-center mt-1'>
									<button className='btn btn-success' onClick={handleShow}>
										Add Section
									</button>
								</Col>
							</>
						) : (
							<Col md='auto' className='text-center mt-1'>
								<button
									className='btn btn-outline-secondary'
									onClick={() => {
										setAddNewHistory(true);
									}}>
									Add New History
								</button>
							</Col>
						)}
					</Row>
				)}

				<div className='header-center card mt-3'>
					<Row className='justify-content-center mb-4 mt-4'>
						<Col md='3' className='text-center'>
							<h6>Patient: {userdata?.data?.data?.name}</h6>
						</Col>
						<Col md='3' className='text-center'>
							<h6>Age: {userdata?.data?.data?.age}</h6>
						</Col>
						<Col md='3' className='text-center'>
							<h6>Sex: {userdata?.data?.data?.gender}</h6>
						</Col>
						<Col md='3' className='text-center'>
							<h6>Contact: {userdata?.data?.data?.mobilenumber}</h6>
						</Col>
					</Row>
				</div>
			</Container>

			{/* New Format */}
			{addNewHistory &&
				templateName?.map((template, templateIndex) =>
					!editClicked[templateIndex]
						? Array?.isArray(historyTemplate[templateIndex]) && (
								<Container className='card mt-4 mb-4'>
									<Row className='justify-content-between m-3'>
										<h3>{template}</h3>
										<div>
											<Button
												variant='success'
												title='Edit Section'
												onClick={() => editBtnClicked(templateIndex)}>
												<i className='fa fa-edit'></i>
											</Button>
											<button
												className='btn btn-danger ml-1'
												onClick={() => deletePage(templateIndex)}
												title='Delete Section'>
												<i className='fa fa-trash'></i>
											</button>
										</div>
									</Row>
									{historyTemplate[templateIndex]?.map((element, index) => {
										return (
											<div key={index} className='form-element'>
												{element.type === "header" ||
												element.type === "paragraph" ? (
													element?.type === "header" ? (
														<h3 className='header-center'>{element.value}</h3>
													) : (
														<p>{element.value}</p>
													)
												) : (
													<>
														<Form.Label>{element.label}</Form.Label>
														{element.type === "select" ? (
															<Form.Control
																as='select'
																onChange={(e) =>
																	handleSelectChange(
																		e,
																		element.label,
																		templateIndex,
																		index
																	)
																}>
																{element.options.map((option, optionIndex) => (
																	<option key={optionIndex}>{option}</option>
																))}
															</Form.Control>
														) : element.type === "multiselect" &&
														  element.options ? (
															<MultiSelect
																options={element.options.map((option) => ({
																	label: option,
																	value: option,
																}))}
																onChange={(selectedOptions) =>
																	handleMultiselectChange(
																		selectedOptions,
																		element.label,
																		templateIndex,
																		index
																	)
																}
																value={element.value || []} // Ensure that formValues[element.label] is an array of objects with label and value properties
																labelledBy='Select'
															/>
														) : element.type === "checkbox" ? (
															<div>
																{element.options.map((option, optionIndex) => (
																	<Form.Check
																		key={optionIndex}
																		type='checkbox'
																		label={option}
																		required={element.required}
																		onChange={(e) =>
																			handleCheckboxRadioChange(
																				e,
																				element.label,
																				option,
																				templateIndex,
																				index
																			)
																		}
																		checked={historyTemplate[templateIndex][
																			index
																		]?.value?.includes(option)}
																		inline
																	/>
																))}
															</div>
														) : // Inside the return statement of your component

														element.type === "radio" ? (
															<div>
																{element.options.map((option, optionIndex) => (
																	<Form.Check
																		key={optionIndex}
																		type='radio'
																		label={option}
																		required={element.required}
																		onChange={(e) =>
																			handleCheckboxRadioChange(
																				e,
																				element.label,
																				option,
																				templateIndex,
																				index
																			)
																		}
																		checked={element?.value === option} // Check if the option is selected
																		inline
																	/>
																))}
															</div>
														) : element.type === "file" ? (
															<input
																type='file'
																onChange={(e) =>
																	handleInputChange(
																		e,
																		element.label,
																		templateIndex,
																		index
																	)
																}
																className='form-control'
															/>
														) : element.type !== "header" &&
														  element.type !== "paragraph" ? (
															element.type === "input" ? (
																<FormControl
																	type={element.type}
																	placeholder='Enter Value'
																	value={element?.value || ""}
																	onChange={(e) =>
																		handleInputChange(
																			e,
																			element.label,
																			templateIndex,
																			index
																		)
																	}
																/>
															) : (
																<textarea
																	className='form-control'
																	placeholder='Enter Value'
																	value={element?.value || ""}
																	onChange={(e) =>
																		handleInputChange(
																			e,
																			element.label,
																			templateIndex,
																			index
																		)
																	}></textarea>
															)
														) : null}
													</>
												)}
											</div>
										);
									})}
								</Container>
						  )
						: Array?.isArray(historyTemplate[templateIndex]) && (
								<div className='form-builder'>
									<Row>
										<Col md={3} className='toolbox'>
											<h3>Toolbox</h3>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "input")}>
												Input
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "textarea")}>
												Textarea
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "select")}>
												Select
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "checkbox")}>
												Checkbox
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "radio")}>
												Radio
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "header")}>
												Header
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "paragraph")}>
												Paragraph
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "multiselect")}>
												MultiSelect
											</div>
										</Col>
										<Col md={9}>
											<div
												className={`form-canvas ${
													draggingElement === "paragraph"
														? "dragged-paragraph"
														: ""
												}`}
												onDragOver={(e) => e.preventDefault()}
												onDrop={handleDrop}>
												<Form>
													{formElements.map((element, index) => (
														<div
															key={index}
															className={`form-element ${
																selectedElementIndex === index ? "selected" : ""
															}`}>
															<div className='form-element-header'>
																<div className='label-edit-delete'>
																	{element.type !== "header" &&
																	element.type !== "paragraph" ? (
																		<FormLabel className='label'>
																			{element.label}
																		</FormLabel>
																	) : element.type !== "paragraph" ? (
																		<h3>{element.value}</h3>
																	) : (
																		<p className='paragraph'>{element.value}</p>
																	)}
																	<div className='edit-delete-buttons'>
																		<button
																			type='button'
																			className='edit-icon btn'
																			onClick={() => openEditModal(index)}>
																			<FontAwesomeIcon icon={faEdit} />
																		</button>
																		<button
																			type='button'
																			className='delete-icon btn'
																			onClick={() =>
																				handleDeleteElement(index)
																			}>
																			<FontAwesomeIcon icon={faTrash} />
																		</button>
																	</div>
																</div>
															</div>
															<div className='form-element-input'>
																{element.type === "select" ? (
																	<Form.Control as='select'>
																		{element.options.map(
																			(option, optionIndex) => (
																				<option key={optionIndex}>
																					{option}
																				</option>
																			)
																		)}
																	</Form.Control>
																) : element.type === "multiselect" ? (
																	<MultiSelect
																		options={element.options.map((option) => ({
																			label: option,
																			value: option,
																		}))}
																		value={selectedOptions}
																		onChange={setSelectedOptions}
																		labelledBy='Select'
																	/>
																) : element.type === "checkbox" ? (
																	<div>
																		{element.options.map(
																			(option, optionIndex) => (
																				<Form.Check
																					key={optionIndex}
																					type='checkbox'
																					label={option}
																					required={element.required}
																				/>
																			)
																		)}
																	</div>
																) : element.type === "radio" ? (
																	<div>
																		{element.options.map(
																			(option, optionIndex) => (
																				<Form.Check
																					key={optionIndex}
																					type='radio'
																					label={option}
																					required={element.required}
																				/>
																			)
																		)}
																	</div>
																) : element.type !== "header" &&
																  element.type !== "paragraph" ? (
																	element.type === "input" ? (
																		<FormControl
																			type={element.type}
																			placeholder='Enter Value'
																			value={element.value}
																			onChange={(e) =>
																				handleValueChange(e, index)
																			}
																		/>
																	) : (
																		<textarea
																			rows={2}
																			cols={100}
																			className='form-control'
																			onChange={(e) =>
																				handleValueChange(e, index)
																			}
																			value={element.value}></textarea>
																	)
																) : null}
															</div>
														</div>
													))}
												</Form>
											</div>
										</Col>
									</Row>
									<Row className='justify-content-center mt-3	'>
										<Button variant='primary' onClick={handleSubmitFormBuilder}>
											Save Changes
										</Button>
										<Button
											variant='danger'
											className='ml-3'
											onClick={handleEditDisable}>
											Discard Changes
										</Button>
									</Row>
									<Modal show={showEditModal} onHide={handleEditModalClose}>
										<Modal.Header closeButton>
											<Modal.Title>Edit Form Element</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											{formElements[selectedElementIndex]?.type === "header" ? (
												<>
													<FormLabel>Edit Value:</FormLabel>
													<FormControl
														type='text'
														value={editHeaderValue}
														onChange={(e) => setEditHeaderValue(e.target.value)}
													/>
												</>
											) : formElements[selectedElementIndex]?.type ===
											  "paragraph" ? (
												<>
													<FormLabel>Edit Value:</FormLabel>
													<FormControl
														type='text'
														value={editParagraphValue}
														onChange={(e) =>
															setEditParagraphValue(e.target.value)
														}
													/>
												</>
											) : (
												<>
													<FormLabel>Edit Label:</FormLabel>
													<FormControl
														type='text'
														value={editLabel}
														onChange={(e) => setEditLabel(e.target.value)}
													/>
													{formElements[selectedElementIndex]?.type !==
														"header" &&
													formElements[selectedElementIndex]?.type !==
														"paragraph" &&
													(formElements[selectedElementIndex]?.type ===
														"checkbox" ||
														formElements[selectedElementIndex]?.type ===
															"radio" ||
														formElements[selectedElementIndex]?.type ===
															"select" ||
														formElements[selectedElementIndex]?.type ===
															"multiselect") ? (
														<>
															<FormLabel>Edit Options:</FormLabel>
															{editOptions.map((option, index) => (
																<div key={index} className='header-center'>
																	<Row className='mt-1'>
																		<Col md='10'>
																			<FormControl
																				type='text'
																				value={option}
																				onChange={(e) =>
																					handleOptionChange(e, index)
																				}
																			/>
																		</Col>
																		<Col md='1'>
																			<Button
																				variant='danger'
																				onClick={() =>
																					handleDeleteOption(index)
																				}>
																				<FontAwesomeIcon icon={faTrash} />
																			</Button>
																		</Col>
																	</Row>
																	{index === editOptions.length - 1 && (
																		<Button
																			variant='primary'
																			onClick={handleAddOption}
																			className='mt-2 mb-2'>
																			<FontAwesomeIcon icon={faPlus} /> Add
																		</Button>
																	)}
																</div>
															))}
															{showAddOptionInput && (
																<Row>
																	<Col md='10'>
																		<FormControl
																			type='text'
																			value={newOption}
																			onChange={(e) =>
																				setNewOption(e.target.value)
																			}
																			placeholder='New Option'
																		/>
																	</Col>
																	<Col md='1'>
																		<Button
																			variant='primary'
																			onClick={() => {
																				setEditOptions([
																					...editOptions,
																					newOption,
																				]);
																				setNewOption("");
																				setShowAddOptionInput(false);
																			}}>
																			<FontAwesomeIcon icon={faCheck} />
																		</Button>
																	</Col>
																</Row>
															)}
														</>
													) : null}
													{formElements[selectedElementIndex]?.type !==
														"header" &&
														formElements[selectedElementIndex]?.type !==
															"paragraph" && (
															<FormCheck
																type='checkbox'
																label='Required'
																checked={isRequired}
																onChange={() => setIsRequired(!isRequired)}
															/>
														)}
												</>
											)}
										</Modal.Body>
										<Modal.Footer>
											<Button
												variant='secondary'
												onClick={handleEditModalClose}>
												Close
											</Button>
											{formElements[selectedElementIndex]?.type === "header" ? (
												<Button
													variant='primary'
													onClick={handleHeaderEditModalSave}>
													Save Changes
												</Button>
											) : formElements[selectedElementIndex]?.type ===
											  "paragraph" ? (
												<Button
													variant='primary'
													onClick={handleParagraphEditModalSave}>
													Save Changes
												</Button>
											) : (
												<Button variant='primary' onClick={handleEditModalSave}>
													Save Changes
												</Button>
											)}
										</Modal.Footer>
									</Modal>
								</div>
						  )
				)}

			{addNewHistory &&
				templateName?.length > 0 &&
				!editClicked?.some((value, index) => value === true) && (
					<Row className='justify-content-center mt-3'>
						<Col md='auto'>
							<button className='btn btn-success' onClick={handleShow}>
								Add Section
							</button>
						</Col>
						<Col md='auto'>
							<Button variant='primary' onClick={handleSubmitForm}>
								Submit
							</Button>
						</Col>
					</Row>
				)}
			{/* View/Edit Exisiting History */}
			{!addNewHistory &&
				getHistoryByIdx?.data?.data?.history?.map((history, i) => {
					let timestamp = Number(history?.createdAt);
					let date = new Date(timestamp);

					// Format the date to 12-hour format with AM/PM
					let options = {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
						second: "2-digit",
						hour12: true,
					};
					let formattedDate = date.toLocaleString("en-US", options);
					var f = 1;
					return (
						<Container>
							<Accordion open={open} toggle={toggle} className='mt-1'>
								<AccordionItem>
									<AccordionHeader targetId={i}>
										<div className='d-flex justify-content-between w-100'>
											<div>
												Created By: {history?.doctorName} (
												{history?.specialityName})
											</div>
											<div className='text-end'>
												Created On: {formattedDate}
											</div>
										</div>
									</AccordionHeader>
									<AccordionBody accordionId={i}>
										{editHistoryIndex === "" ? (
											<>
												{(doctorInfo || receptionInfo) && (
													<Row className='justify-content-end mb-2'>
														<Col>
															<button
																className='btn btn-outline-success mb-2'
																onClick={() => editHistoryClicked(i)}>
																Edit
															</button>
														</Col>
														{/* Uncomment and customize as needed
															<Col>
															<button
																className='btn btn-success'
																onClick={handlePrint}
															>
																Print Selected
															</button>
															</Col> */}
													</Row>
												)}

												{history?.historyData?.map((page, pageIndex) => (
													<div key={`table-${pageIndex}`}>
														<Table
															bordered
															className='custom-table'
															id={`table-${pageIndex}`}>
															<tbody>
																{page.map((item, idx) => {
																	if (f === 0) {
																		f = 1;
																	} else if (
																		item.label === "Header" ||
																		item.label === "Paragraph"
																	) {
																		return (
																			<tr key={`${item.label}-${idx}`}>
																				<td colSpan={4}>
																					{item.label === "Header" ? (
																						<h3>
																							{Array.isArray(item.value)
																								? item.value.join(", ")
																								: item.value}
																						</h3>
																					) : (
																						<p>
																							{Array.isArray(item.value)
																								? item.value.join(", ")
																								: item.value}
																						</p>
																					)}
																				</td>
																			</tr>
																		);
																	} else {
																		return (
																			<tr key={`${item.label}-${idx}`}>
																				<td>
																					<b>{item.label}: </b>
																				</td>
																				<td>
																					{item.type === "textarea" &&
																					item.value !== undefined
																						? item.value
																						: Array.isArray(item.value) &&
																						  item.type === "multiselect"
																						? Array.isArray(item.value) &&
																						  item.value
																								.map((option) => option.label)
																								.join(", ")
																						: item.type === "checkbox"
																						? Array.isArray(item.value) &&
																						  item.value
																								.map((option) => option)
																								.join(", ")
																						: item.value}
																				</td>
																				{page[idx + 1] &&
																				page[idx + 1].label !== "Header" &&
																				page[idx + 1].label !== "Paragraph" ? (
																					<>
																						<div className='d-none'>
																							{(f = 0)}
																						</div>
																						<td>
																							<b>{page[idx + 1].label}: </b>
																						</td>
																						<td>
																							{page[idx + 1].type ===
																								"textarea" &&
																							page[idx + 1].value !== undefined
																								? page[idx + 1].value
																								: Array.isArray(
																										page[idx + 1].value
																								  ) &&
																								  page[idx + 1].type ===
																										"multiselect"
																								? Array.isArray(
																										page[idx + 1].value
																								  ) &&
																								  page[idx + 1].value
																										.map(
																											(option) => option.label
																										)
																										.join(", ")
																								: page[idx + 1].type ===
																								  "checkbox"
																								? Array.isArray(
																										page[idx + 1].value
																								  ) &&
																								  page[idx + 1].value
																										.map((option) => option)
																										.join(", ")
																								: page[idx + 1].value}
																						</td>
																					</>
																				) : null}
																			</tr>
																		);
																	}
																})}
															</tbody>
														</Table>
													</div>
												))}
											</>
										) : (
											Array?.isArray(history?.historyData) && (
												<div className='form-builder'>
													<Row>
														<Col md={3} className='toolbox'>
															<h3>Toolbox</h3>
															<div
																className='toolbox-item'
																draggable
																onDragStart={(e) =>
																	handleDragStart(e, "input")
																}>
																Input
															</div>
															<div
																className='toolbox-item'
																draggable
																onDragStart={(e) =>
																	handleDragStart(e, "textarea")
																}>
																Textarea
															</div>
															<div
																className='toolbox-item'
																draggable
																onDragStart={(e) =>
																	handleDragStart(e, "select")
																}>
																Select
															</div>
															<div
																className='toolbox-item'
																draggable
																onDragStart={(e) =>
																	handleDragStart(e, "checkbox")
																}>
																Checkbox
															</div>
															<div
																className='toolbox-item'
																draggable
																onDragStart={(e) =>
																	handleDragStart(e, "radio")
																}>
																Radio
															</div>
															<div
																className='toolbox-item'
																draggable
																onDragStart={(e) =>
																	handleDragStart(e, "header")
																}>
																Header
															</div>
															<div
																className='toolbox-item'
																draggable
																onDragStart={(e) =>
																	handleDragStart(e, "paragraph")
																}>
																Paragraph
															</div>
															<div
																className='toolbox-item'
																draggable
																onDragStart={(e) =>
																	handleDragStart(e, "multiselect")
																}>
																MultiSelect
															</div>
														</Col>
														<Col md={9}>
															<div
																className={`form-canvas ${
																	draggingElement === "paragraph"
																		? "dragged-paragraph"
																		: ""
																}`}
																onDragOver={(e) => e.preventDefault()}
																onDrop={handleDrop}>
																<Form>
																	{formElements.map((element, index) => (
																		<div
																			key={index}
																			className={`form-element ${
																				selectedElementIndex === index
																					? "selected"
																					: ""
																			}`}>
																			<div className='form-element-header'>
																				<div className='label-edit-delete'>
																					{element.type !== "header" &&
																					element.type !== "paragraph" ? (
																						<FormLabel className='label'>
																							{element.label}
																						</FormLabel>
																					) : element.type !== "paragraph" ? (
																						<h3>{element.value}</h3>
																					) : (
																						<p className='paragraph'>
																							{element.value}
																						</p>
																					)}
																					<div className='edit-delete-buttons'>
																						<button
																							type='button'
																							className='edit-icon btn'
																							onClick={() =>
																								openEditModal(index)
																							}>
																							<FontAwesomeIcon icon={faEdit} />
																						</button>
																						<button
																							type='button'
																							className='delete-icon btn'
																							onClick={() =>
																								handleDeleteElement(index)
																							}>
																							<FontAwesomeIcon icon={faTrash} />
																						</button>
																					</div>
																				</div>
																			</div>
																			<div className='form-element-input'>
																				{element.type === "select" ? (
																					<Form.Control
																						as='select'
																						value={element.value || []}
																						onChange={(e) =>
																							handleSelectChangeEdit(
																								e,
																								element.label,
																								index
																							)
																						}>
																						{element.options.map(
																							(option, optionIndex) => (
																								<option key={optionIndex}>
																									{option}
																								</option>
																							)
																						)}
																					</Form.Control>
																				) : element.type === "multiselect" ? (
																					<MultiSelect
																						options={element.options.map(
																							(option) => ({
																								label: option,
																								value: option,
																							})
																						)}
																						// value={selectedOptions}
																						// onChange={setSelectedOptions}
																						labelledBy='Select'
																						value={element.value || []}
																						onChange={(selectedOptions) =>
																							handleMultiselectChangeEdit(
																								selectedOptions,
																								element.label,

																								index
																							)
																						}
																					/>
																				) : element.type === "checkbox" ? (
																					<div>
																						{element.options.map(
																							(option, optionIndex) => (
																								<Form.Check
																									key={optionIndex}
																									type='checkbox'
																									label={option}
																									required={element.required}
																									checked={formElements[
																										index
																									]?.value?.includes(option)}
																									onChange={(e) =>
																										handleCheckboxRadioChangeEdit(
																											e,
																											element.label,
																											option,
																											index
																										)
																									}
																								/>
																							)
																						)}
																					</div>
																				) : element.type === "radio" ? (
																					<div>
																						{element.options.map(
																							(option, optionIndex) => (
																								<Form.Check
																									key={optionIndex}
																									type='radio'
																									label={option}
																									required={element.required}
																									checked={
																										element?.value === option
																									}
																									onChange={(e) =>
																										handleCheckboxRadioChangeEdit(
																											e,
																											element.label,
																											option,
																											index
																										)
																									}
																								/>
																							)
																						)}
																					</div>
																				) : element.type !== "header" &&
																				  element.type !== "paragraph" ? (
																					element.type === "input" ? (
																						<FormControl
																							type={element.type}
																							placeholder='Enter Value'
																							value={element.value}
																							onChange={(e) =>
																								handleValueChangeEdit(e, index)
																							}
																						/>
																					) : (
																						<textarea
																							rows={2}
																							cols={100}
																							className='form-control'
																							onChange={(e) =>
																								handleValueChangeEdit(e, index)
																							}
																							value={element.value}></textarea>
																					)
																				) : null}
																			</div>
																		</div>
																	))}
																</Form>
															</div>
														</Col>
													</Row>

													<Modal
														show={showEditModal}
														onHide={handleEditModalClose}>
														<Modal.Header closeButton>
															<Modal.Title>Edit Form Element</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															{formElements[selectedElementIndex]?.type ===
															"header" ? (
																<>
																	<FormLabel>Edit Value:</FormLabel>
																	<FormControl
																		type='text'
																		value={
																			formElements[selectedElementIndex].value
																		}
																		onChange={(e) =>
																			handleHeaderParagrapghEdit(
																				e,
																				formElements[selectedElementIndex]
																					.label,
																				selectedElementIndex
																			)
																		}
																	/>
																</>
															) : formElements[selectedElementIndex]?.type ===
															  "paragraph" ? (
																<>
																	<FormLabel>Edit Value:</FormLabel>
																	<FormControl
																		type='text'
																		value={
																			formElements[selectedElementIndex].value
																		}
																		onChange={(e) =>
																			handleHeaderParagrapghEdit(
																				e,
																				formElements[selectedElementIndex]
																					.label,
																				selectedElementIndex
																			)
																		}
																	/>
																</>
															) : (
																<>
																	<FormLabel>Edit Label:</FormLabel>
																	<FormControl
																		type='text'
																		value={editLabel}
																		onChange={(e) =>
																			setEditLabel(e.target.value)
																		}
																	/>
																	{formElements[selectedElementIndex]?.type !==
																		"header" &&
																	formElements[selectedElementIndex]?.type !==
																		"paragraph" &&
																	(formElements[selectedElementIndex]?.type ===
																		"checkbox" ||
																		formElements[selectedElementIndex]?.type ===
																			"radio" ||
																		formElements[selectedElementIndex]?.type ===
																			"select" ||
																		formElements[selectedElementIndex]?.type ===
																			"multiselect") ? (
																		<>
																			<FormLabel>Edit Options:</FormLabel>
																			{editOptions.map((option, index) => (
																				<div
																					key={index}
																					className='header-center'>
																					<Row className='mt-1'>
																						<Col md='10'>
																							<FormControl
																								type='text'
																								value={option}
																								onChange={(e) =>
																									handleOptionChange(e, index)
																								}
																							/>
																						</Col>
																						<Col md='1'>
																							<Button
																								variant='danger'
																								onClick={() =>
																									handleDeleteOption(index)
																								}>
																								<FontAwesomeIcon
																									icon={faTrash}
																								/>
																							</Button>
																						</Col>
																					</Row>
																					{index === editOptions.length - 1 && (
																						<Button
																							variant='primary'
																							onClick={handleAddOption}
																							className='mt-2 mb-2'>
																							<FontAwesomeIcon icon={faPlus} />{" "}
																							Add
																						</Button>
																					)}
																				</div>
																			))}
																			{showAddOptionInput && (
																				<Row>
																					<Col md='10'>
																						<FormControl
																							type='text'
																							value={newOption}
																							onChange={(e) =>
																								setNewOption(e.target.value)
																							}
																							placeholder='New Option'
																						/>
																					</Col>
																					<Col md='1'>
																						<Button
																							variant='primary'
																							onClick={() => {
																								setEditOptions([
																									...editOptions,
																									newOption,
																								]);
																								setNewOption("");
																								setShowAddOptionInput(false);
																							}}>
																							<FontAwesomeIcon icon={faCheck} />
																						</Button>
																					</Col>
																				</Row>
																			)}
																		</>
																	) : null}
																	{formElements[selectedElementIndex]?.type !==
																		"header" &&
																		formElements[selectedElementIndex]?.type !==
																			"paragraph" && (
																			<FormCheck
																				type='checkbox'
																				label='Required'
																				checked={isRequired}
																				onChange={() =>
																					setIsRequired(!isRequired)
																				}
																			/>
																		)}
																</>
															)}
														</Modal.Body>
														<Modal.Footer>
															<Button
																variant='secondary'
																onClick={handleEditModalClose}>
																Close
															</Button>
															{formElements[selectedElementIndex]?.type ===
															"header" ? (
																<Button
																	variant='primary'
																	onClick={handleEditModalClose}>
																	Save Changes
																</Button>
															) : formElements[selectedElementIndex]?.type ===
															  "paragraph" ? (
																<Button
																	variant='primary'
																	onClick={handleEditModalClose}>
																	Save Changes
																</Button>
															) : (
																<Button
																	variant='primary'
																	onClick={handleNotParagraphEditModalSaveEdit}>
																	Save Changes
																</Button>
															)}
														</Modal.Footer>
													</Modal>
												</div>
											)
										)}
										{editHistoryIndex !== "" && (
											<Container className='pagination-container mt-5'>
												<Pagination>
													{getHistoryByIdx?.data?.data?.history[
														editHistoryIndex
													]?.historyData?.map((data, number) => (
														<Pagination.Item
															key={number + 1}
															active={number + 1 === activePageEdit}
															onClick={() => handlePageChangeEdit(number + 1)}>
															{number + 1}
														</Pagination.Item>
													))}
												</Pagination>
											</Container>
										)}
										{editHistoryIndex !== "" && (
											<Row className='justify-content-center'>
												<Button
													variant='primary'
													onClick={handleSubmitFormBuilderEdit}>
													Save Changes
												</Button>
												<Button
													variant='danger'
													className='ml-3'
													onClick={handleEditDisableEdit}>
													Discard Changes
												</Button>
											</Row>
										)}
									</AccordionBody>
								</AccordionItem>
							</Accordion>
						</Container>
					);
				})}

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Choose Template</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className='justify-content-center mt-3'>
						<Col md='auto' className='text-center mt-1'>
							<h6>
								Select the filters{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={tempName}
								onSelect={TemplateHandler}>
								<div className='dropScroll'>
									{getPrescriptionTemplateNames?.data?.data?.historyTemplatesNamesArray.map(
										(template, index) => (
											<Dropdown.Item
												eventKey={
													template.templateName +
													"@@" +
													template._id +
													"@@" +
													index
												}>
												{template.templateName}
											</Dropdown.Item>
										)
									)}
								</div>
							</DropdownButton>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default StartHistoryScreens;
