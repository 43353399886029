/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createInventoryItem = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createInventoryItemStart: (state) => {
			state.loading = true;
		},
		createInventoryItemSuccess: (state, action) => {
			state.loading = false;
			state.createInventoryitem = action.payload;
			state.error = false;
			state.success = true;
		},
		createInventoryItemFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createInventoryItemReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createInventoryItemStart,
	createInventoryItemSuccess,
	createInventoryItemFailure,
	createInventoryItemReset,
} = createInventoryItem.actions;
export default createInventoryItem.reducer;
