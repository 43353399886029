/** @format */

import { React, useState, useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const SectionNavigator = ({ navitems }) => {
	const location = useLocation();
	const [isStartPrescription, setIsStartPrescription] = useState(false);
	const [collapsed, setCollapsed] = useState(false);

	const handleToggle = () => {
		setCollapsed(!collapsed);
	};

	console.log(navitems);

	const handleLinkClick = (templateName) => {
		// Scroll to the section with the matching id
		const section = document.getElementById(`${templateName}`);
		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		}
	};

	useEffect(() => {
		// Update component whenever the location changes
		if (location.pathname.includes("startprescription")) {
			setIsStartPrescription(true); // Update state based on the URL
		} else {
			setIsStartPrescription(false); // Reset state based on the URL
		}
	}, [location.pathname]);

	return (
		<div
			className={`${
				isStartPrescription
					? `d-none d-md-block ${
							collapsed
								? "collapsed-section-navigator"
								: "expanded-section-navigator"
					  }`
					: `d-none ${
							collapsed
								? "collapsed-section-navigator"
								: "expanded-section-navigator"
					  }`
			}`}>
			<div className={`d-flex flex-column`}>
				<Navbar
					bg='dark'
					variant='dark'
					className='d-flex flex-column align-items-start p-3'>
					{/* Sidebar Heading */}
					<Navbar.Brand>
						{!collapsed && <h5 className='text-light'>Section Navigator</h5>}
					</Navbar.Brand>

					<Nav defaultActiveKey='/home' className='flex-column'>
						{navitems?.map((items, index) => (
							<Nav.Item className='mt-1 d-flex' key={index}>
								<Nav.Link
									className='sidebar-links'
									title={items?.templateName}
									onClick={(e) => {
										e.preventDefault(); // Prevent default navigation
										handleLinkClick(items?.templateName); // Scroll to section
									}}>
									{!collapsed && items?.templateName}{" "}
								</Nav.Link>
							</Nav.Item>
						))}
					</Nav>
				</Navbar>
			</div>
		</div>
	);
};
export default SectionNavigator;
