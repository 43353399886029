/** @format */
import Message from "../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
// import Accordion from 'react-bootstrap/Accordion';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";

import { getTimeslots } from "../../redux/userApiCalls";
import { getMyAppointments } from "../../redux/doctorRedux/doctorApiCalls";
import { getuserByIdForPrescription } from "../../redux/prescriptionRedux/prescriptionApiCalls";
import Form from "react-bootstrap/Form";
import { getMyAppointmentsReset } from "../../redux/doctorRedux/Appointment_Management/getMyAppointments";
import { TimeSlotGetReset } from "../../redux/getTimeSlots";
import { getuserByIdForPrescriptionReset } from "../../redux/prescriptionRedux/getuserByIdForPrescription";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";
import {
	startPrescriptionToStartHistoryRequest,
	startPrescriptionToStartHistorySuccess,
	startPrescriptionToStartHistoryFailure,
	startPrescriptionToStartHistoryReset,
} from "../../redux/UINavigationRedux/StartPrescriptionToStartHistory";

const GetUserPrescriptionsScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	//success and failure
	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { doctorInfo } = doctorLogin;
	console.log(doctorInfo?.data?.user);
	const getUserDataByIdData = useSelector(
		(state) => state.getuserByIdForPrescription
	);
	const userdata = getUserDataByIdData.userdata;
	const success = getUserDataByIdData.success;
	const loading = getUserDataByIdData.loading;
	const error = getUserDataByIdData.error;
	// Accordian Collapse
	const [open, setOpen] = useState("1");
	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};
	console.log(history);
	//Modal Open
	const [lgShow, setLgShow] = useState(false);
	const [startPres, setStartPres] = useState(false);
	const curr = new Date();
	const curDate = curr.getDate();
	console.log(
		curDate +
			" " +
			Number(
				location.state?.date?.toString()?.split("")[6] +
					location.state?.date?.toString()?.split("")[7]
			)
	);

	const date = location?.state?.date?.toString()?.split("");
	const todaysDate =
		date[6] +
		date[7] +
		"-" +
		date[4] +
		date[5] +
		"-" +
		date[0] +
		date[1] +
		date[2] +
		date[3];
	console.log(location.state?.id, location.state?.name);

	console.log(userdata);
	useEffect(() => {
		dispatch(
			getuserByIdForPrescription(
				location.state?.id,
				location.state?.name,
				location?.state?.userIdx
			)
		);
	}, []);

	console.log(location?.state);

	const prescriptionByDate = userdata?.data?.data?.prescDate;
	console.log(prescriptionByDate + "line 78");
	let scheduledObj = "";
	for (let i = 0; i < userdata?.data?.data?.scheduledAppointment?.length; i++) {
		if (
			userdata?.data?.data?.scheduledAppointment[i].doctor ==
			doctorInfo?.data?.user._id
		) {
			scheduledObj = userdata?.data?.data?.scheduledAppointment[i];
			console.log(scheduledObj);
			//   scheduledObj.date = userdata?.data?.data?.scheduledAppointment[i].date
			//     .split("-")
			//     .reverse()
			//     .join("-");
		}
	}

	//Filter dropdown
	const [filterOption, setFilterOption] = useState("Date");
	const handleFilterSelect = (eventKey) => {
		setFilterOption(eventKey);
	};

	const [categoryOption, setCategoryOption] = useState("Choose Category");
	const [categoryDropdown, setcategoryDropdown] = useState("d-none");

	const [ind, setInd] = useState(-1);

	const handleCategoryNameSelect = (eventKey) => {
		setCategoryOption(eventKey);
		for (let i = 0; i < userdata?.data?.data?.prescCategory.length; i++) {
			if (eventKey === userdata?.data?.data?.prescCategory[i]?.category) {
				setInd(i);
			}
		}
	};

	const handleCategorySelect = () => {
		setcategoryDropdown("d-block");
	};

	const handleDateSelect = () => {
		setcategoryDropdown("d-none");
	};

	const startPrescription = () => {
		if (location?.state?.slotstatus === "blocked") {
			alert("Please unblock the slot " + location?.state?.slot);
		} else {
			console.log(location.state.userIdx);
			history("/startprescription", {
				state: {
					id: location.state.id,
					name: location.state.name,
					date: location.state.date,
					specialityName: location.state.specialityName,
					speciality: location.state.speciality,
					bookingId: location.state.bookingId,
					activeTests: userdata?.data?.data?.activeTests,
					userIdx: location?.state?.userIdx,
					doctorId: location?.state?.doctorId,
					docname: location?.state?.docname,
				},
			});
		}
	};

	const [BloodGroup, setBloodGroup] = useState("");

	useEffect(() => {
		userdata?.data?.data?.vitals?.map((vital, index) => {
			if (vital?.vitalName === "BloodGroup") {
				setBloodGroup(vital?.vitalValue);
			}
		});
	}, [loading]);

	const viewBtn = (id, age, gender, bgrp, name, activeTests) => {
		const data = {
			id: id,
			age: age,
			gender: gender,
			bgrp: bgrp,
			name: name,
			activeTests: userdata?.data?.data?.activeTests,
			index: location?.state?.userIdx,
			userId: location?.state?.userId,
		};

		const newWindow = window.open(
			doctorInfo !== undefined ? "/doctor/viewpres" : "/reception/viewpres",
			"_blank"
		);
		newWindow.onload = () => {
			// Pass both data and unique identifier to the new window
			newWindow.postMessage(data, window.location.origin);
		};
	};

	const viewReportHandler = (id) => {
		// history("/viewreport", {
		// 	state: {
		// 		id: id,
		// 		age: userdata?.data?.data?.age,
		// 		gender: userdata?.data?.data?.gender,
		// 	},
		// });

		const data = {
			id: id,
			age: userdata?.data?.data?.age,
			gender: userdata?.data?.data?.gender,
		};
		let url = "/viewreport";

		const newWindow = window.open(url, "_blank");
		newWindow.onload = () => {
			// Pass both data and unique identifier to the new window
			newWindow.postMessage(data, window.location.origin);
		};
	};
	//ALerts
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const createHistory = () => {
		const data = {
			id: location.state.id,
			name: location.state.name,
			date: location.state.date,
			specialityName: location.state.specialityName,
			speciality: location.state.speciality,
			bookingId: location.state.bookingId,
			activeTests: userdata?.data?.data?.activeTests,
			userIdx: location?.state?.userIdx,
			doctorId: location?.state?.doctorId,
			docname: location?.state?.docname,
		};
		// console.log(data);
		// dispatch(startPrescriptionToStartHistoryReset());
		// dispatch(startPrescriptionToStartHistoryRequest());
		// dispatch(startPrescriptionToStartHistorySuccess(data));
		const queryParams = new URLSearchParams();

		// Convert historyItems to a JSON string and encode it for the URL
		queryParams.append(
			"historyItems",
			encodeURIComponent(JSON.stringify(data))
		);

		// Open the new tab with the query parameters
		window.open(`/history?${queryParams.toString()}`, "_blank");
		// window.open("/history", "_blank");
	};

	console.log(date);

	return (
		<>
			<Meta />
			{loading && <Loader />}
			<h4 className='header-center'>Patient Name: {location.state?.name}</h4>
			<a
				role='button'
				href='/doctor/patientstobevisited'
				className='btn offset-1 back-button'>
				<i className='fa fa-angle-left custom fa-2x'></i>
			</a>
			<Container className='header-center card mt-5'>
				<Row className='justify-content-center mb-4 mt-4'>
					<Col md='4' className='text-center'>
						<h6>Age: {userdata?.data?.data?.age}</h6>
					</Col>
					<Col md='4' className='text-center'>
						<h6>Sex: {userdata?.data?.data?.gender}</h6>
					</Col>
					<Col md='4' className='text-center'>
						<h6>Contact: {userdata?.data?.data?.mobilenumber}</h6>
					</Col>
				</Row>
			</Container>

			<Container className='header-center card mt-5'>
				<Row className='justify-content-center mb-4 mt-4'>
					<Col md='3' className='text-center'>
						<h6>Date: {todaysDate}</h6>
					</Col>
					<Col md='3' className='text-center'>
						<h6>
							Doctor:{" "}
							{doctorInfo !== undefined
								? doctorInfo?.data?.user?.name
								: location?.state?.docname}
						</h6>
					</Col>
					{Number(
						location.state?.date?.toString()?.split("")[6] +
							location.state?.date?.toString()?.split("")[7]
					) == curDate &&
						doctorInfo &&
						location?.state?.prescriptionstatus !== "already created" &&
						userdata?.data?.data?.isBooked && (
							<Col md='3' className='text-center'>
								<button
									className='btn btn-success'
									onClick={() => startPrescription()}>
									Start Case
								</button>
							</Col>
						)}
					<Col md='3' className='text-center'>
						<button className='btn btn-success' onClick={createHistory}>
							View/Create History
						</button>
					</Col>
				</Row>

				{scheduledObj?.message !== undefined && (
					<Row className='justify-content-center mb-4 mt-4'>
						<h6 className='text-danger'>
							You have requested the user for a scheduled appointment on
							{" " +
								scheduledObj?.date?.split("")[6] +
								scheduledObj?.date?.split("")[7] +
								"-" +
								scheduledObj?.date?.split("")[4] +
								scheduledObj?.date?.split("")[5] +
								"-" +
								scheduledObj?.date?.split("")[0] +
								scheduledObj?.date?.split("")[1] +
								scheduledObj?.date?.split("")[2] +
								scheduledObj?.date?.split("")[3]}{" "}
							for the reason :{" " + scheduledObj?.message}
						</h6>
					</Row>
				)}
			</Container>
			<Container>
				<Tabs
					defaultActiveKey='prescriptioninfo'
					id='uncontrolled-tab-example'
					className='mb-3 mt-3'>
					<Tab eventKey='prescriptioninfo' title='Prescription Info'>
						<Container className='header-center card mt-5'>
							<Row className='justify-content-center mt-4 mb-4'>
								<Col md='auto' className='text-center'>
									<h6>
										Choose Filter
										<span className='text-danger'>
											<b>*</b>
										</span>
									</h6>
								</Col>
								<Col md='auto' className='text-center'>
									<DropdownButton
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={filterOption}
										onSelect={handleFilterSelect}>
										<Dropdown.Item eventKey={"Date"} onClick={handleDateSelect}>
											{"Date"}
										</Dropdown.Item>
										<Dropdown.Item
											eventKey={"Category"}
											onClick={handleCategorySelect}>
											{"Category"}
										</Dropdown.Item>
									</DropdownButton>
								</Col>
								<Col md='auto' className='text-center'>
									<DropdownButton
										className={categoryDropdown}
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={categoryOption}
										onSelect={handleCategoryNameSelect}>
										{userdata?.data?.data?.category?.map(
											(categories, index) => (
												<Dropdown.Item eventKey={categories}>
													{categories}
												</Dropdown.Item>
											)
										)}
									</DropdownButton>
								</Col>
							</Row>
						</Container>
						<div className='card mt-2'>
							<h4 className='center'>Patients History</h4>
							<div className='row mt-5 mb-3 ml-2'>
								<h6 className='col-3 col-md-2'>Date</h6>
								<h6 className='col-3 col-md-2'>Doctor</h6>
								<h6 className='col-3 col-md-2'>Category</h6>
								<h6 className='col-3 col-md-5'>Issue</h6>
							</div>

							{filterOption === "Date" &&
								prescriptionByDate?.map((pres, index) => (
									<div className='row mt-2 mb-2'>
										<h6 className='col-3 col-md-2'>
											{pres?.bookedDate.split("-")[2] +
												"- " +
												pres?.bookedDate.split("-")[1] +
												" -" +
												pres?.bookedDate.split("-")[0]}
										</h6>
										<h6 className='col-3 col-md-2'>{pres?.doctorName}</h6>
										<h6 className='col-3 col-md-2'>{pres?.category}</h6>
										<h6 className='col-3 col-md-5 d-block d-md-none'>
											{" "}
											{pres?.title.slice(0, 20)}. . .
										</h6>
										<h6 className='col-3 col-md-5 d-none d-md-block'>
											{" "}
											{pres?.title.slice(0, 105)}. . .
										</h6>
										<button
											className='col-12 col-md-4 offset-md-4 btn btn-secondary'
											onClick={() =>
												viewBtn(
													pres?.prescriptionId,
													userdata?.data?.data?.age,
													userdata?.data?.data?.gender,
													BloodGroup,
													location.state?.name
												)
											}>
											View Case
										</button>
									</div>
								))}
							{filterOption === "Category" &&
								userdata?.data?.data?.prescCategory[ind]?.prescriptionIds?.map(
									(pres, index) => (
										<div className='row mt-2 mb-2'>
											<h6 className='col-3 col-md-2'>
												{pres?.bookedDate.split("-")[2] +
													"- " +
													pres?.bookedDate.split("-")[1] +
													" -" +
													pres?.bookedDate.split("-")[0]}
											</h6>
											<h6 className='col-3 col-md-2'>{pres?.doctorName}</h6>
											<h6 className='col-3 col-md-2'>
												{userdata?.data?.data?.prescCategory[ind]?.category}
											</h6>
											<h6 className='col-3 col-md-5 d-block d-md-none'>
												{" "}
												{pres?.title.slice(0, 20)}. . .
											</h6>
											<h6 className='col-3 col-md-5 d-none d-md-block'>
												{" "}
												{pres?.title.slice(0, 105)}. . .
											</h6>
											<button
												className='col-12 col-md-4 offset-md-4 btn btn-secondary'
												onClick={() =>
													viewBtn(
														pres?.prescriptionId,
														userdata?.data?.data?.age,
														userdata?.data?.data?.gender,
														BloodGroup,
														location.state?.name
													)
												}>
												View Case
											</button>
										</div>
									)
								)}
						</div>
					</Tab>
					<Tab eventKey='reports' title='Reports'>
						<Table striped bordered hover className='custom-table'>
							<thead>
								<tr>
									<th>S.No</th>
									<th>Department Name</th>
									<th>Test Name</th>
									<th>Report Date</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{userdata?.data?.data?.labReports?.map((test, index) => (
									<tr>
										<td>{index + 1}</td>
										<td>{test?.deptName}</td>
										<td>{test?.testName}</td>
										<td>{test?.reportDate}</td>
										<td>
											<button
												className='btn btn-success'
												onClick={() => viewReportHandler(test?.reportId)}>
												View Report
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Tab>
				</Tabs>
			</Container>
		</>
	);
};

export default GetUserPrescriptionsScreen;
