/** @format */

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Meta from "../../../Components/Meta";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	getLabDepts,
	getTestNameFromDept,
} from "../../../redux/adminRedux/adminApiCalls";
import {
	getTestTemplate,
	deleteTemplateInTest,
} from "../../../redux/labRedux/labTechApiCalls";
import Table from "react-bootstrap/Table";

const DeleteTestTemplateScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	useEffect(() => {
		dispatch(getLabDepts());
	}, []);
	const { getlabDeptAll, loading } = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	const [deptName, setDeptName] = useState("Choose Department");
	const [deptId, setDeptId] = useState("");
	const [testName, setTestName] = useState("Choose Test");
	const [deptTestNames, setdeptTestNames] = useState([]);
	const [testIdx, setTestIdx] = useState([]);

	const handleDeptSelect = (eventKey) => {
		setDeptName(eventKey.split("@@")[0]);
		setDeptId(eventKey.split("@@")[1]);
		setTestIdx(eventKey.split("@@")[2]);
		setdeptTestNames(
			getlabDeptAll?.data?.data[eventKey.split("@@")[2]]?.testDetails
		);
		setTestName("Choose Test");
	};

	const [selectedTestIdx, setSelectedTestIdx] = useState("");

	const handleTestSelect = (eventKey) => {
		setTestName(eventKey?.split("@@")[0]);
		setSelectedTestIdx(eventKey?.split("@@")[1]);
		dispatch(getTestTemplate(deptId, setSelectedTestIdx));
	};

	const deleteTemplate = (template) => {
		dispatch(deleteTemplateInTest(deptId, testName, template));
	};

	const deleteLoading = useSelector((state) => state.deleteTestTemplate);

	useEffect(() => {
		dispatch(getLabDepts());
	}, [deleteLoading?.loading]);

	useEffect(() => {
		if (testName !== "Choose Test")
			dispatch(getTestTemplate(deptId, setSelectedTestIdx));
	}, [loading]);

	const { getTestTemplateOfRep } = useSelector(
		(state) => state.getTemplateNamesOfReport
	);

	console.log(getTestTemplateOfRep);

	return (
		<>
			<Meta />
			<h2 className="header-center">Delete Test Template</h2>
			<Container className="header-center mt-5">
				<Row className="justify-content-center">
					<Col md="auto" className="text-center">
						<h6>
							Select the filters{" "}
							<span className="text-danger">
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md="auto" className="text-center">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={deptName}
							onSelect={handleDeptSelect}
						>
							{getlabDeptAll?.data?.data?.length === 0 ? (
								<Dropdown.Item>
									{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
								</Dropdown.Item>
							) : (
								getlabDeptAll?.data?.data?.map(
									(dept, index) =>
										dept.deptName !== "hospital" &&
										dept.status !== 0 && (
											<Dropdown.Item
												eventKey={
													dept.deptName + "@@" + dept._id + "@@" + index
												}
											>
												{dept.deptName}
											</Dropdown.Item>
										)
								)
							)}
						</DropdownButton>
					</Col>
					<Col md="auto" className="text-center">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={testName}
							onSelect={handleTestSelect}
						>
							{deptTestNames?.length === 0 ? (
								<Dropdown.Item>
									{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
								</Dropdown.Item>
							) : (
								deptTestNames?.map(
									(test, index) =>
										test.status !== 0 && (
											<Dropdown.Item eventKey={test.testName + "@@" + index}>
												{test.testName}
											</Dropdown.Item>
										)
								)
							)}
						</DropdownButton>
					</Col>
				</Row>
			</Container>
			<div className="col-12 offset-md-3 col-md-6 mt-5">
				{deptName !== "Choose Department" && testName !== "Choose Test" && (
					<Table striped bordered hover className="custom-table">
						<thead>
							<tr>
								<th>Sno</th>
								<th>Lab Test Template Name</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{getTestTemplateOfRep?.data?.data?.testDetails[
								selectedTestIdx
							]?.testTemplates?.map((template, index) => (
								<tr>
									{" "}
									<td>{index + 1}</td>
									<td>{template?.templateName}</td>
									<td>
										<button
											className="btn btn-danger"
											onClick={() => deleteTemplate(template?.templateName)}
										>
											<i className="fas fa-trash"></i>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				)}
			</div>
		</>
	);
};

export default DeleteTestTemplateScreen;
