/** @format */

import { SERVER_URL } from "../../App";
import { hospitalname } from "../../App";
import axios from "axios";

//-------------------------------Lab Redux -----------------------------------
import {
	getAllLabDepartmentStatisticsForParticularYearRequest,
	getAllLabDepartmentStatisticsForParticularYearSuccess,
	getAllLabDepartmentStatisticsForParticularYearFailure,
	getAllLabDepartmentStatisticsForParticularYearReset,
} from "./LabDeptRedux/getAllLabDepartmentStatisticsForParticularYear";

import {
	getDepartmentandTestStatisticsRequest,
	getDepartmentandTestStatisticsSuccess,
	getDepartmentandTestStatisticsFailure,
	getDepartmentandTestStatisticsReset,
} from "./LabDeptRedux/getDepartmentandTestStatistics";

import {
	getLabStatisticsRequest,
	getLabStatisticsSuccess,
	getLabStatisticsFailure,
	getLabStatisticsReset,
} from "./LabDeptRedux/getLabStatistics";

import {
	getStatisticsofParticularTestRequest,
	getStatisticsofParticularTestSuccess,
	getStatisticsofParticularTestFailure,
	getStatisticsofParticularTestReset,
} from "./LabDeptRedux/getStatisticsofParticularTest";

import {
	getTestStatisticsForParticularYearRequest,
	getTestStatisticsForParticularYearSuccess,
	getTestStatisticsForParticularYearFailure,
	getTestStatisticsForParticularYearReset,
} from "./LabDeptRedux/getTestStatisticsForParticularYear";

import {
	todaysLabPatientsStatisticsRequest,
	todaysLabPatientsStatisticsSuccess,
	todaysLabPatientsStatisticsFailure,
	todaysLabPatientsStatisticsReset,
} from "./LabDeptRedux/todaysLabPatientsStatistics";

//--------------------------------speciality statistics Redux ---------------------------------

import {
	getDoctorStatisticsForParticularYearRequest,
	getDoctorStatisticsForParticularYearSuccess,
	getDoctorStatisticsForParticularYearFailure,
	getDoctorStatisticsForParticularYearReset,
} from "./SpecialityRedux/getDoctorStatisticsForParticularYear";

import {
	getHospitalandSpecialityStatisticsRequest,
	getHospitalandSpecialityStatisticsSuccess,
	getHospitalandSpecialityStatisticsFailure,
	getHospitalandSpecialityStatisticsReset,
} from "./SpecialityRedux/getHospitalandSpecialityStatistics";

import {
	getHospitalStatisticsForParticularYearRequest,
	getHospitalStatisticsForParticularYearSuccess,
	getHospitalStatisticsForParticularYearFailure,
	getHospitalStatisticsForParticularYearReset,
} from "./SpecialityRedux/getHospitalStatisticsForParticularYear";

import {
	getSpecialityandDoctorStatisticsRequest,
	getSpecialityandDoctorStatisticsSuccess,
	getSpecialityandDoctorStatisticsFailure,
	getSpecialityandDoctorStatisticsReset,
} from "./SpecialityRedux/getSpecialityandDoctorStatistics";

import {
	getStatisticsofParticularDoctorRequest,
	getStatisticsofParticularDoctorSuccess,
	getStatisticsofParticularDoctorFailure,
	getStatisticsofParticularDoctorReset,
} from "./SpecialityRedux/getStatisticsofParticularDoctor";

import {
	todaysVisitedPatientsStatisticsRequest,
	todaysVisitedPatientsStatisticsSuccess,
	todaysVisitedPatientsStatisticsFailure,
	todaysVisitedPatientsStatisticsReset,
} from "./SpecialityRedux/todaysVisitedPatientsStatistics";

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";

//--------------------------------Referral doctor statistics Redux ---------------------------------

import {
	getReferralDoctorRevenueRequest,
	getReferralDoctorRevenueSuccess,
	getReferralDoctorRevenueFailure,
	getReferralDoctorRevenueReset,
} from "./getReferralDoctorRevenue";

import {
	getCustomRevenuesOftheHospitalRequest,
	getCustomRevenuesOftheHospitalSuccess,
	getCustomRevenuesOftheHospitalFailure,
	getCustomRevenuesOftheHospitalReset,
} from "./getCustomRevenuesOftheHospital";

//----------------------------------------------Lab Statistics API Calls --------------------------

//revenue for a full year revenue generated from lab
export const getAllLabDepartmentStatisticsForParticularYear =
	(year) => async (dispatch) => {
		try {
			dispatch(getAllLabDepartmentStatisticsForParticularYearRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/statistics/getAllLabDepartmentStatisticsForParticularYear/${year}`,

				config
			);
			console.log(data);

			dispatch(getAllLabDepartmentStatisticsForParticularYearSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error?.response);
			dispatch(
				getAllLabDepartmentStatisticsForParticularYearFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

//get revenue for all the tests in a department
export const getDepartmentandTestStatistics =
	(departmentId) => async (dispatch) => {
		try {
			dispatch(getDepartmentandTestStatisticsRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/statistics/getDepartmentandTestStatistics/${departmentId}`,

				config
			);
			console.log(data);

			dispatch(getDepartmentandTestStatisticsSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error?.response);
			dispatch(
				getDepartmentandTestStatisticsFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};
//department wise revenues(30days, 12moths, years)
export const getLabStatistics = () => async (dispatch) => {
	try {
		dispatch(getLabStatisticsRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};

		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/statistics/getLabStatistics`,
			config
		);
		console.log(data);

		dispatch(getLabStatisticsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		console.log(error?.response);
		dispatch(
			getLabStatisticsFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

//revenues of a test
export const getStatisticsofParticularTest =
	(departmentId, testIdx) => async (dispatch) => {
		try {
			dispatch(getStatisticsofParticularTestRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/statistics/getStatisticsofParticularTest/${departmentId}/${testIdx}`,
				config
			);
			console.log(data);

			dispatch(getStatisticsofParticularTestSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error?.response);
			dispatch(
				getStatisticsofParticularTestFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getTestStatisticsForParticularYear =
	(departmentId, testIdx, year) => async (dispatch) => {
		try {
			dispatch(getTestStatisticsForParticularYearRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/statistics/getTestStatisticsForParticularYear/${departmentId}/${testIdx}/${year}`,

				config
			);
			console.log(data);

			dispatch(getTestStatisticsForParticularYearSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error?.response);
			dispatch(
				getTestStatisticsForParticularYearFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const todaysLabPatientsStatistics = () => async (dispatch) => {
	try {
		dispatch(todaysLabPatientsStatisticsRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};

		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/statistics/todaysLabPatientsStatistics`,

			config
		);
		console.log(data);

		dispatch(todaysLabPatientsStatisticsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		console.log(error?.response);
		dispatch(
			todaysLabPatientsStatisticsFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

// --------------------------Speciality statistics api calls ------------------------------

//get particular doc statistics for a year
export const getDoctorStatisticsForParticularYear =
	(specialityId, doctorId, year) => async (dispatch) => {
		try {
			dispatch(getDoctorStatisticsForParticularYearRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/statistics/getDoctorStatisticsForParticularYear/${specialityId}/${doctorId}/${year}`,

				config
			);
			console.log(data);

			dispatch(getDoctorStatisticsForParticularYearSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error?.response);
			dispatch(
				getDoctorStatisticsForParticularYearFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getHospitalandSpecialityStatistics = () => async (dispatch) => {
	try {
		dispatch(getHospitalandSpecialityStatisticsRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
				hospitalname: hospitalname,
			},
		};

		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/statistics/getHospitalandSpecialityStatistics`,

			config
		);
		console.log(data);

		dispatch(getHospitalandSpecialityStatisticsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		console.log(error?.response);
		dispatch(
			getHospitalandSpecialityStatisticsFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getHospitalStatisticsForParticularYear =
	(year) => async (dispatch) => {
		try {
			dispatch(getHospitalStatisticsForParticularYearRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/statistics/getHospitalStatisticsForParticularYear/${year}`,

				config
			);
			console.log(data);

			dispatch(getHospitalStatisticsForParticularYearSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error?.response);
			dispatch(
				getHospitalStatisticsForParticularYearFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getSpecialityandDoctorStatistics =
	(specialityId) => async (dispatch) => {
		try {
			dispatch(getSpecialityandDoctorStatisticsRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/statistics/getSpecialityandDoctorStatistics/${specialityId}`,

				config
			);
			console.log(data);

			dispatch(getSpecialityandDoctorStatisticsSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error?.response);
			dispatch(
				getSpecialityandDoctorStatisticsFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getStatisticsofParticularDoctor =
	(specialityId, doctorId) => async (dispatch) => {
		try {
			dispatch(getStatisticsofParticularDoctorRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/statistics/getStatisticsofParticularDoctor/${specialityId}/${doctorId}`,

				config
			);
			console.log(data);

			dispatch(getStatisticsofParticularDoctorSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error?.response);
			dispatch(
				getStatisticsofParticularDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const todaysVisitedPatientsStatistics = () => async (dispatch) => {
	try {
		dispatch(todaysVisitedPatientsStatisticsRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
				hospitalname: hospitalname,
			},
		};

		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/statistics/todaysVisitedPatientsStatistics`,

			config
		);
		console.log(data);

		dispatch(todaysVisitedPatientsStatisticsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		console.log(error?.response);
		dispatch(
			todaysVisitedPatientsStatisticsFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getReferralDoctorRevenue =
	(doctorId, startDate, endDate) => async (dispatch) => {
		try {
			dispatch(getReferralDoctorRevenueRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/statistics/getReferralDoctorRevenuesForDates`,
				{ startDate, doctorId, endDate },

				config
			);
			console.log(data);

			dispatch(getReferralDoctorRevenueSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error?.response);
			dispatch(
				getReferralDoctorRevenueFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getCustomRevenuesOftheHospitalFunction =
	(startYear, endYear) => async (dispatch) => {
		try {
			dispatch(getCustomRevenuesOftheHospitalRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			console.log(hospitalname);
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/statistics/getCustomRevenuesOftheHospital/${startYear}/${endYear}`,
				config
			);
			console.log(data);

			dispatch(getCustomRevenuesOftheHospitalSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error?.response);
			dispatch(
				getCustomRevenuesOftheHospitalFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};
