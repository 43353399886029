/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Modal,
	Button,
	Row,
	Form,
	Col,
	DropdownButton,
	Dropdown,
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Meta from "../../../Components/Meta";
import { FaStar } from "react-icons/fa";
import {
	getInventoryCategory,
	createInventoryItem,
	getInventoryItemsForBilling,
	editInventoryItem,
	createInventoryCategory,
} from "../../../redux/inventoryRedux/inventoryApiCalls";
import { getInventoryInfo } from "../../../redux/adminRedux/adminApiCalls";
import ButtonLoader from "../../../Components/ButtonLoader";

const BatchTable = ({ selectedBatches }) => {
	// Define the column structure for AG Grid
	const columnDefs = [
		{ headerName: "Supplier Name", field: "supplierName" },
		{ headerName: "Quantity Left", field: "quantityLeft" },
		{
			headerName: "Expiry Date",
			field: "expiryDate",
			valueFormatter: (params) => formatDate(params.value), // Custom date formatter
		},
		{ headerName: "Batch", field: "batchNoFromUser" },
		{
			headerName: "Received Date",
			field: "receivedDate",
			valueFormatter: (params) => formatDate(params.value), // Custom date formatter
		},
		{ headerName: "Cost Price", field: "cost_price" },
		{ headerName: "Brand", field: "brand" },
		{ headerName: "Selling Price", field: "selling_price" },
	];

	// Date formatting function
	const formatDate = (dateString) => {
		if (dateString === 99999999) {
			return `Not available`;
		}
		const date = new Date(
			`${dateString.toString().slice(0, 4)}-${dateString
				.toString()
				.slice(4, 6)}-${dateString.toString().slice(6, 8)}`
		);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
		const year = date.getFullYear();

		return `${day}-${month}-${year}`;
	};

	// Default column properties
	const defaultColDef = {
		sortable: true,
		filter: true,
		resizable: true,
		floatingFilter: true,
	};

	return (
		<div className='ag-theme-alpine' style={{ height: 400, width: "100%" }}>
			<AgGridReact
				rowData={selectedBatches} // Data to display in the grid
				columnDefs={columnDefs} // Columns definition
				defaultColDef={defaultColDef} // Default column properties
				pagination={true} // Enable pagination
				paginationPageSize={10} // Number of rows per page
			/>
		</div>
	);
};

const EditInventoryItemForm = ({ product, setEditModalShow }) => {
	const dispatch = useDispatch();
	const [editedProduct, setEditedProduct] = useState({ ...product });
	const [selectedBatch, setSelectedBatch] = useState(-1);

	const [stockExpiringInOneWeekOld, setStockExpiringInOneWeekOld] = useState(
		[]
	);
	const [stockExpiringInOneMonthOld, setStockExpiringInOneMonthOld] = useState(
		[]
	);
	const [stockExpiringInThreeMonthsOld, setStockExpiringInThreeMonthsOld] =
		useState([]);
	const [stockExpiringInSixMonthsOld, setStockExpiringInSixMonthsOld] =
		useState([]);

	const [stockExpiringInOneWeekNew, setStockExpiringInOneWeekNew] = useState(
		[]
	);
	const [stockExpiringInOneMonthNew, setStockExpiringInOneMonthNew] = useState(
		[]
	);
	const [stockExpiringInThreeMonthsNew, setStockExpiringInThreeMonthsNew] =
		useState([]);
	const [stockExpiringInSixMonthsNew, setStockExpiringInSixMonthsNew] =
		useState([]);

	const { adminInfo } = useSelector((state) => state.adminLogin);

	const { inventoryInfo } = useSelector((state) => state.inventoryInfo);

	useEffect(() => {
		dispatch(getInventoryInfo());
	}, []);

	useEffect(() => {
		// Initialize arrays for old and new data
		let stock_expiring_in_one_weekold = [];
		let stock_expiring_in_one_monthold = [];
		let stock_expiring_in_three_monthsold = [];
		let stock_expiring_in_six_monthsold = [];

		let stock_expiring_in_one_weeknew = [];
		let stock_expiring_in_one_monthnew = [];
		let stock_expiring_in_three_monthsnew = [];
		let stock_expiring_in_six_monthsnew = [];

		// Helper function to calculate date difference
		const getDateDifference = (expiryDate) => {
			if (expiryDate === 99999999) return null;
			const today = new Date();
			const expiry = new Date(
				String(expiryDate).slice(0, 4),
				String(expiryDate).slice(4, 6) - 1,
				String(expiryDate).slice(6, 8)
			);
			const diffTime = expiry - today;
			return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // convert ms to days
		};

		// Process old data (from product)
		product.selling_prices.forEach((priceItem) => {
			const diffDays = getDateDifference(priceItem.expiryDate);
			if (diffDays === null) return; // Skip products with expiryDate 99999999
			const inventoryItem = {
				inventoryId: product._id,
				batchNo: priceItem.batchNo,
				batchNoFromUser: priceItem?.batchNoFromUser,
				brand: priceItem.brand,
				cost_price: priceItem.cost_price,
				expiryDate: priceItem.expiryDate,
				name: product.name,
				quantityLeft: priceItem.quantityLeft,
				receivedDate: priceItem.receivedDate,
				selling_price: priceItem.selling_price,
				supplierId: priceItem.supplierId,
				supplierName: priceItem.supplierName,
			};
			// Categorize by expiry date
			if (diffDays <= 7) stock_expiring_in_one_weekold.push(inventoryItem);
			else if (diffDays <= 30)
				stock_expiring_in_one_monthold.push(inventoryItem);
			else if (diffDays <= 90)
				stock_expiring_in_three_monthsold.push(inventoryItem);
			else if (diffDays <= 180)
				stock_expiring_in_six_monthsold.push(inventoryItem);
		});

		// Process new data (from editedProduct)
		editedProduct.selling_prices.forEach((priceItem) => {
			const diffDays = getDateDifference(priceItem.expiryDate);
			if (diffDays === null) return; // Skip products with expiryDate 99999999
			const inventoryItem = {
				inventoryId: editedProduct._id,
				batchNo: priceItem.batchNo,
				brand: priceItem.brand,
				cost_price: priceItem.cost_price,
				expiryDate: priceItem.expiryDate,
				name: editedProduct.name,
				quantityLeft: priceItem.quantityLeft,
				receivedDate: priceItem.receivedDate,
				selling_price: priceItem.selling_price,
				supplierId: priceItem.supplierId,
				supplierName: priceItem.supplierName,
			};
			// Categorize by expiry date
			if (diffDays <= 7) stock_expiring_in_one_weeknew.push(inventoryItem);
			else if (diffDays <= 30)
				stock_expiring_in_one_monthnew.push(inventoryItem);
			else if (diffDays <= 90)
				stock_expiring_in_three_monthsnew.push(inventoryItem);
			else if (diffDays <= 180)
				stock_expiring_in_six_monthsnew.push(inventoryItem);
		});

		console.log(
			stock_expiring_in_one_weekold,
			stock_expiring_in_one_monthold,
			stock_expiring_in_three_monthsold,
			stock_expiring_in_six_monthsold
		);
		console.log(
			stock_expiring_in_one_weeknew,
			stock_expiring_in_one_monthnew,
			stock_expiring_in_three_monthsnew,
			stock_expiring_in_six_monthsnew
		);
		setStockExpiringInOneWeekOld(stock_expiring_in_one_weekold);
		setStockExpiringInOneMonthOld(stock_expiring_in_one_monthold);
		setStockExpiringInThreeMonthsOld(stock_expiring_in_three_monthsold);
		setStockExpiringInSixMonthsOld(stock_expiring_in_six_monthsold);

		setStockExpiringInOneWeekNew(stock_expiring_in_one_weeknew);
		setStockExpiringInOneMonthNew(stock_expiring_in_one_monthnew);
		setStockExpiringInThreeMonthsNew(stock_expiring_in_three_monthsnew);
		setStockExpiringInSixMonthsNew(stock_expiring_in_six_monthsnew);
	}, [adminInfo, editedProduct]);

	useEffect(() => {
		const totalQuantity = editedProduct.selling_prices.reduce(
			(acc, item) => acc + (item.quantityLeft ? item?.quantityLeft : 0),
			0
		);

		// Calculate Lifetime_purchase_amount and Lifetime_quantity_purchased if selectedBatch is valid
		const lifetimePurchaseAmount =
			selectedBatch !== -1
				? product?.Lifetime_purchase_amount -
				  Number(product?.selling_prices[selectedBatch]?.cost_price || 0) *
						Number(product?.selling_prices[selectedBatch]?.quantityLeft || 0) +
				  Number(
						editedProduct?.selling_prices[selectedBatch]?.cost_price || 0
				  ) *
						Number(
							editedProduct?.selling_prices[selectedBatch]?.quantityLeft || 0
						)
				: product?.Lifetime_purchase_amount;

		const lifetimeQuantityPurchased =
			selectedBatch !== -1
				? product?.Lifetime_quantity_purchased -
				  Number(product?.selling_prices[selectedBatch]?.quantityLeft || 0) +
				  Number(
						editedProduct?.selling_prices[selectedBatch]?.quantityLeft || 0
				  )
				: product?.Lifetime_quantity_purchased;

		setEditedProduct((prevData) => ({
			...prevData,
			Totalquantity: totalQuantity,
			status: totalQuantity > 0 ? "in-stock" : "out-of-stock",
			Lifetime_purchase_amount: lifetimePurchaseAmount,
			Lifetime_quantity_purchased: lifetimeQuantityPurchased,
		}));
	}, [editedProduct.selling_prices, selectedBatch, product]);

	console.log(product, editedProduct);

	// Handle input changes for main fields
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			[name]: value,
			isNameChanged: name === "name" ? value !== product.name : false,
			isCategoryChanged: false,
			oldCategoryId: product.categoryId,
		}));
	};

	const handleCategoryChange = (e) => {
		let arr = e?.split("@@");
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			category: arr[0],
			categoryId: arr[1],
			isCategoryChanged: arr[0] !== product.category,
			oldCategoryId: product.categoryId,
		}));
	};

	// Handle changes in selling prices
	const handleSellingPricesChange = (index, field, value) => {
		const updatedSellingPrices = editedProduct.selling_prices.map((item, i) => {
			if (i === index) {
				const updatedItem = { ...item, [field]: value };
				setSelectedBatch(Number(i));
				// Update expiry index based on expiryDate
				if (field === "expiryDate") {
					const today = new Date();
					const expiryDate = new Date(value);

					// Calculate difference in days
					const diffInDays = (expiryDate - today) / (1000 * 60 * 60 * 24);
					let expiring_index = 0;

					if (diffInDays < 7) expiring_index = 1; // Less than 1 week
					else if (diffInDays < 30) expiring_index = 2; // 1 week to 1 month
					else if (diffInDays < 90) expiring_index = 3; // 1 month to 3 months
					else if (diffInDays < 180) expiring_index = 6; // 3 months to 6 months

					updatedItem.expiring_index = expiring_index;
				}
				return updatedItem;
			}
			return item;
		});
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			selling_prices: updatedSellingPrices,
		}));
	};

	const formatDateForInput = (dateNum) => {
		if (!dateNum) return "";
		const year = Math.floor(dateNum / 10000);
		const month = String(Math.floor((dateNum % 10000) / 100)).padStart(2, "0");
		const day = String(dateNum % 100).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	const formatDate = (dateNum) => {
		if (!dateNum) return "";
		const year = Math.floor(dateNum / 10000);
		const month = String(Math.floor((dateNum % 10000) / 100)).padStart(2, "0");
		const day = String(dateNum % 100).padStart(2, "0");
		return `${day}-${month}-${year}`;
	};

	const formatInputToDateNum = (dateStr) => {
		if (!dateStr) return null;
		const [year, month, day] = dateStr.split("-");
		return parseInt(`${year}${month}${day}`, 10);
	};

	const [updateInventoryLoading, setUpdateInventoryLoading] = useState(false);

	const UpdateInventoryItem = async () => {
		setUpdateInventoryLoading(true);

		// Get today's date in yyyymmdd format
		const today = new Date();
		const todayFormatted = parseInt(
			today.toISOString().split("T")[0].replace(/-/g, "")
		); // Converts to yyyymmdd format as a number

		// Filter out items where quantityLeft is 0 or expiryDate is in the past
		editedProduct.selling_prices = editedProduct.selling_prices.filter(
			(item) => {
				const { quantityLeft, expiryDate } = item;

				// Keep the item if quantityLeft is greater than 0 and expiryDate is in the future
				return quantityLeft > 0 && expiryDate >= todayFormatted;
			}
		);

		const expiryStockArrays = {
			stockExpiringInOneWeekOld: stockExpiringInOneWeekOld,
			stockExpiringInOneMonthOld: stockExpiringInOneMonthOld,
			stockExpiringInThreeMonthsOld: stockExpiringInThreeMonthsOld,
			stockExpiringInSixMonthsOld: stockExpiringInSixMonthsOld,
			stockExpiringInOneWeekNew: stockExpiringInOneWeekNew,
			stockExpiringInOneMonthNew: stockExpiringInOneMonthNew,
			stockExpiringInThreeMonthsNew: stockExpiringInThreeMonthsNew,
			stockExpiringInSixMonthsNew: stockExpiringInSixMonthsNew,
		};

		// Dispatch the editInventoryItem action with the updated product and expiry stock arrays
		await dispatch(editInventoryItem(editedProduct, expiryStockArrays));
		await dispatch(getInventoryItemsForBilling());
		await dispatch(getInventoryInfo());

		setEditModalShow(false);
		setUpdateInventoryLoading(false);
	};

	const [showCategoryModal, setShowCategoryModal] = useState(false);
	const [newCategory, setNewCategory] = useState("");
	const [newCategoryDescription, setNewCategoryDescription] = useState("");

	const handleAddCategory = () => {
		setShowCategoryModal(true);
	};

	const handleSaveCategory = async () => {
		if (newCategory.trim() === "") return;
		setShowCategoryModal(false);
		await dispatch(
			createInventoryCategory(newCategory, newCategoryDescription)
		);
		await dispatch(getInventoryCategory());
		setNewCategory("");
		setNewCategoryDescription("");
	};

	useEffect(() => {
		dispatch(getInventoryCategory());
	}, []);

	const getInventorycategory = useSelector(
		(state) => state?.getInventoryCategory
	);

	return (
		<>
			<Form>
				<Row>
					<Col md={6}>
						<Form.Group controlId='name'>
							<Form.Label>
								<b>Name</b>
							</Form.Label>
							<Form.Control
								type='text'
								name='name'
								value={editedProduct.name}
								onChange={handleInputChange}
							/>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId='categoryId'>
							<Form.Label>
								<b>Unit</b>
							</Form.Label>
							<Form.Control
								type='text'
								name='unit'
								value={editedProduct.unit}
								onChange={handleInputChange}
							/>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<Form.Group controlId='Totalquantity'>
							<Form.Label>
								<b>Total Quantity</b>
							</Form.Label>
							<Form.Control
								type='number'
								name='Totalquantity'
								value={
									editedProduct.Totalquantity ? editedProduct.Totalquantity : 0
								}
								disabled
							/>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId='description'>
							<Form.Label>
								<b>Description</b>
							</Form.Label>
							<Form.Control
								type='text'
								name='description'
								value={editedProduct.description}
								onChange={handleInputChange}
							/>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<Form.Group controlId='hsn_code'>
							<Form.Label>
								<b>HSN Code</b>
							</Form.Label>
							<Form.Control
								type='text'
								name='hsn_code'
								value={editedProduct.hsn_code}
								onChange={handleInputChange}
							/>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId='medical_composition'>
							<Form.Label>
								<b>Medical Composition</b>
							</Form.Label>
							<Form.Control
								type='text'
								name='medical_composition'
								value={editedProduct.medical_composition}
								onChange={handleInputChange}
							/>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<Form.Group controlId='minStockLevel'>
							<Form.Label>
								<b>Minimum Stock Level</b>
							</Form.Label>
							<Form.Control
								type='number'
								name='minStockLevel'
								value={editedProduct.minStockLevel}
								onChange={handleInputChange}
							/>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId='rackNo'>
							<Form.Label>
								<b>Rack No</b>
							</Form.Label>
							<Form.Control
								type='text'
								name='rackNo'
								value={editedProduct.rackNo}
								onChange={handleInputChange}
							/>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<Form.Group controlId='category'>
							<Form.Label>
								<b>Category</b>
							</Form.Label>
							<Row>
								<Col md='auto'>
									<DropdownButton
										variant='success'
										title={editedProduct.category || "Select Category"}
										name='category'
										onSelect={handleCategoryChange}>
										<div className='dropScroll position-relative'>
											{getInventorycategory?.getInventorycategory?.data?.data?.map(
												(editedProduct) => {
													if (editedProduct?.name !== "hospital") {
														return (
															<Dropdown.Item
																eventKey={
																	editedProduct?.name +
																	"@@" +
																	editedProduct?._id
																}>
																{editedProduct?.name}
															</Dropdown.Item>
														);
													}
													return null;
												}
											)}
										</div>
									</DropdownButton>
								</Col>
								<Col md='auto'>
									<Button onClick={handleAddCategory}>Add New Category</Button>
								</Col>
							</Row>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId='status'>
							<Form.Label>
								<b>Status</b>
							</Form.Label>
							<Form.Control
								type='text'
								name='status'
								value={editedProduct.status}
								disabled
							/>
						</Form.Group>
					</Col>
				</Row>

				{editedProduct.selling_prices?.length > 0 && (
					<h5 className='mt-5 mb-3'>Stock Batches</h5>
				)}
				{editedProduct.selling_prices?.map((item, index) => (
					<>
						<Row className='mt-3 mb-3'>
							<Col md='auto'>
								<h6>Supplier Name: {item?.supplierName}</h6>
							</Col>
							<Col md='auto'>
								<h6>Purchase Date: {formatDate(item?.receivedDate)}</h6>
							</Col>
						</Row>
						<Row key={index}>
							<Col md={4}>
								<Form.Group controlId={`brand-${index}`}>
									<Form.Label>
										<b>Brand</b>
									</Form.Label>
									<Form.Control
										type='text'
										value={item.brand}
										onChange={(e) =>
											handleSellingPricesChange(index, "brand", e.target.value)
										}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group controlId={`batchNoFromUser-${index}`}>
									<Form.Label>
										<b>Batch No</b>
									</Form.Label>
									<Form.Control
										type='text'
										value={item?.batchNoFromUser}
										onChange={(e) =>
											handleSellingPricesChange(
												index,
												"batchNoFromUser",
												e.target.value
											)
										}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group controlId={`cost_price-${index}`}>
									<Form.Label>
										<b>Cost Price</b>
									</Form.Label>
									<Form.Control
										type='number'
										value={item.cost_price}
										onChange={(e) =>
											handleSellingPricesChange(
												index,
												"cost_price",
												parseFloat(e.target.value)
											)
										}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group controlId={`expiryDate-${index}`}>
									<Form.Label>
										<b>Expiry Date</b>
									</Form.Label>
									<Form.Control
										type='date'
										value={formatDateForInput(item.expiryDate)} // Convert to YYYY-MM-DD for input
										onChange={(e) =>
											handleSellingPricesChange(
												index,
												"expiryDate",
												formatInputToDateNum(e.target.value)
											)
										}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group controlId={`quantityLeft-${index}`}>
									<Form.Label>
										<b>Quantity Left</b>
									</Form.Label>
									<Form.Control
										type='number'
										value={item.quantityLeft ? item.quantityLeft : 0}
										onChange={(e) =>
											handleSellingPricesChange(
												index,
												"quantityLeft",
												parseFloat(e.target.value)
											)
										}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group controlId={`selling_price-${index}`}>
									<Form.Label>
										<b>Selling Price</b>
									</Form.Label>
									<Form.Control
										type='number'
										value={item.selling_price}
										onChange={(e) =>
											handleSellingPricesChange(
												index,
												"selling_price",
												parseFloat(e.target.value)
											)
										}
									/>
								</Form.Group>
							</Col>
						</Row>
					</>
				))}
				<Row className='justify-content-center'>
					<Col md='auto'>
						<Button
							variant='success'
							className='mt-3 mb-3'
							onClick={UpdateInventoryItem}>
							Save Changes{" "}
							{updateInventoryLoading && <ButtonLoader></ButtonLoader>}
						</Button>
					</Col>
				</Row>
			</Form>
			{/* Add Category Modal */}
			<Modal
				show={showCategoryModal}
				onHide={() => setShowCategoryModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Add New Category</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className='mb-3'>
							<Form.Label>Category Name</Form.Label>
							<Form.Control
								type='text'
								value={newCategory}
								onChange={(e) => setNewCategory(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Description</Form.Label>
							<Form.Control
								type='text'
								value={newCategoryDescription}
								onChange={(e) => setNewCategoryDescription(e.target.value)}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => setShowCategoryModal(false)}>
						Close
					</Button>
					<Button variant='primary' onClick={handleSaveCategory}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

const CustomHeaderWithSearch = (props) => {
	const onSearchChange = (event) => {
		props.api.setQuickFilter(event.target.value);
	};

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<span>{props.displayName}</span>
			</div>
			<input
				type='text'
				placeholder='Search...'
				onChange={onSearchChange}
				style={{ marginTop: "5px", padding: "2px", width: "100%" }}
			/>
		</div>
	);
};

const AddInventoryPharmacyScreens = () => {
	const dispatch = useDispatch();
	const [modalShow, setModalShow] = useState(false);
	const [editModalShow, setEditModalShow] = useState(false);
	const [selectedBatches, setSelectedBatches] = useState([]);
	const [selectedInventoryItem, setSelectedInventoryItem] = useState(null);

	const formatDate = (date) => {
		// Convert date from 'yyyymmdd' to 'dd-mm-yyyy'
		const year = date.toString().substring(0, 4);
		const month = date.toString().substring(4, 6);
		const day = date.toString().substring(6, 8);
		return `${day}-${month}-${year}`;
	};

	useEffect(() => {
		dispatch(getInventoryCategory());
		dispatch(getInventoryItemsForBilling());
	}, []);

	const getInventorycategory = useSelector(
		(state) => state.getInventoryCategory
	);

	const getInventoryItemsForbilling = useSelector(
		(state) => state.getInventoryItemsForBilling
	);

	console.log(getInventoryItemsForbilling);

	console.log(getInventorycategory);

	const columns = [
		{
			headerName: "Action",
			field: "action",
			cellRendererFramework: (params) => (
				<>
					<button
						className='btn btn-primary btn-sm'
						title='View More'
						onClick={() => {
							setSelectedBatches(params?.data?.selling_prices);
							setModalShow(true);
						}}>
						<i className='fa fa-eye' />
					</button>{" "}
					<button
						className='btn btn-success btn-sm'
						title='Edit'
						onClick={() => {
							setSelectedInventoryItem(params?.data);
							setEditModalShow(true);
						}}>
						<i className='fa fa-edit' />
					</button>{" "}
					{/* <label>
						<input
							className='d-none'
							type='radio'
							name='rating'
							value={ratingValue}
							onClick={() => handleClick(ratingValue)}
						/>
						<FaStar
							className='star'
							color={params?.data?.favourite ? "gold" : "lightgray"}
							size={20}
							// onMouseEnter={() => setHover(ratingValue)}
							// onMouseLeave={() => setHover(null)}
							value={"Favorites "}
						/>
					</label> */}
				</>
			),
			width: 100,
			sortable: false,
		},
		{
			headerName: "S.No",
			valueGetter: (params) => params.node.rowIndex + 1,
			width: 90,
			sortable: false,
		},
		{ headerName: "Name", field: "name", floatingFilter: true, sortable: true },
		{
			headerName: "Description",
			field: "description",
			floatingFilter: true,
			sortable: true,
		},
		{ headerName: "Unit", field: "unit", floatingFilter: true, sortable: true },
		{
			headerName: "Total Quantity",
			field: "Totalquantity",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Status",
			field: "status",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Category",
			field: "category",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Min Stock Level",
			field: "minStockLevel",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Medical Composition",
			field: "medical_composition",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "HSN Code",
			field: "hsn_code",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Selling Prices",
			valueGetter: (params) =>
				params.data.selling_prices.map((sp) => sp.selling_price).join(", "),
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Lifetime Profit",
			field: "Lifetime_profit",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Lifetime Purchase Amount",
			field: "Lifetime_purchase_amount",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Lifetime Quantity Purchased",
			field: "Lifetime_quantity_purchased",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Lifetime Quantity Sold",
			field: "Lifetime_quantity_sold",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Lifetime Sale Amount",
			field: "Lifetime_sale_amount",
			floatingFilter: true,
			sortable: true,
		},

		{
			headerName: "Today's Discount",
			field: "todays_discount",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Today's Profit",
			field: "todays_profit",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Today's Quantity Sold",
			field: "todays_quantity_sold",
			floatingFilter: true,
			sortable: true,
		},
		{
			headerName: "Today's Sale Amount",
			field: "todays_sale_Amount",
			floatingFilter: true,
			sortable: true,
		},
	];

	return (
		<>
			<Meta />
			<h1 className='header-center mt-3'>Pharmacy Inventory</h1>
			<Row className='mt-5 justify-content-center'>
				<div
					className='ag-theme-alpine'
					style={{ height: "600px", width: "90%" }}>
					<AgGridReact
						columnDefs={columns}
						rowData={
							getInventoryItemsForbilling?.getInventoryItemsForbilling?.data
								?.data
						}
						defaultColDef={{
							sortable: true,
							filter: true,

							resizable: true,
						}}
						floatingFiltersHeight={35}
						frameworkComponents={{
							customHeader: CustomHeaderWithSearch,
						}}
						pagination={true}
						paginationPageSize={10}
					/>
				</div>
			</Row>

			<Modal show={modalShow} onHide={() => setModalShow(false)} size='xl'>
				<Modal.Header closeButton>
					<Modal.Title>Stock Batches</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<BatchTable selectedBatches={selectedBatches} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={() => setModalShow(false)}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={editModalShow}
				onHide={() => setEditModalShow(false)}
				size='xl'>
				<Modal.Header closeButton>
					<Modal.Title>Edit Inventory Item</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditInventoryItemForm
						product={selectedInventoryItem}
						setEditModalShow={setEditModalShow}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default AddInventoryPharmacyScreens;
