/** @format */

import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Table from "react-bootstrap/Table";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Message from "../../../Components/Message";
import { createReceptionistReset } from "../../../redux/adminRedux/Reception_Management/addReceptionist";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import axios from "axios";
import { SERVER_URL } from "../../../App";
import Button from "react-bootstrap/Button";
import { createReception } from "../../../redux/adminRedux/adminApiCalls";

const AddReceptionScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const [timerRunning, setTimerRunning] = useState(false);
	const [name, setName] = useState("");
	const [mobilenumber, setMobileNumber] = useState("");
	const [email, setEmail] = useState("");
	const [address, setAddress] = useState("");
	const [dob, setDob] = useState("");
	const [gender, setGender] = useState("");

	const [newUser, setNewUser] = useState({
		name: "",
		mobilenumber: "",
		email: "",
		gender: "",
		dob: "",
		address: "",
	});
	var getAllReceptionData = useSelector((state) => state.getAllReception);
	getAllReceptionData = getAllReceptionData?.getAllReception?.data;
	console.log(getAllReceptionData);

	// useEffect(() => {
	//   dispatch(getAllReception());
	// }, [dispatch]);
	const submitHandler = (e) => {
		// console.log(speciality)
		e.preventDefault();
		var letters = /^[A-Za-z\s]+$/;
		if (name === "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
			alert("Email address invalid");
		} else {
			let temp = window.confirm(
				"Are you sure you want to create \n" +
					"name : " +
					name +
					"\n" +
					"mobilenumber : " +
					mobilenumber +
					"\n" +
					"email: " +
					email +
					"\n" +
					"gender : " +
					gender +
					"\n" +
					"date of birth : " +
					dob +
					"\n" +
					"address : " +
					address
			);
			if (!temp) {
				alert("Please enter correct Doctor details");
			} else {
				console.log(name, mobilenumber, email, gender, dob, address);
				dispatch(
					createReception(name, mobilenumber, email, gender, dob, address)
				);
				setName("");
				setMobileNumber("");
				setEmail("");
				setGender("");
				setDob("");
				setAddress("");
			}
		}
	};

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	return (
		<>
			<Meta />

			<div>
				<h1 className="header-center">Add Receptionist </h1>
				<form
					className="mt-5"
					onSubmit={submitHandler}
					encType="multipart/form-data"
				>
					<div className="row col-md-6 offset-md-3 form-group">
						<label className="form-label col-md-3" htmlFor="name">
							<b>Name: </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<input
							className="form-control col-md-8"
							id="name"
							type="text"
							placeholder="Enter your name"
							required="required"
							value={name}
							onChange={(e) => {
								setName(e.target.value);
								setNewUser({ ...newUser, name: e.target.value });
							}}
						/>
					</div>
					<div className="row col-md-6 offset-md-3 form-group">
						<label className="form-label col-md-3" htmlFor="mobilenumber">
							<b>Mobile Number: </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<input
							className="form-control col-md-8"
							id="mobilenumber"
							type="text"
							placeholder="Enter your mobile number"
							required="required"
							value={mobilenumber}
							onChange={(e) => {
								setMobileNumber(e.target.value);
								setNewUser({ ...newUser, mobilenumber: e.target.value });
							}}
							minlength="10"
							maxlength="10"
							pattern="^[0-9]*$"
						/>
					</div>
					<div className="row col-md-6 offset-md-3 form-group">
						<label className="form-label col-md-3" htmlFor="email">
							<b>Email </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<input
							className="form-control col-md-8"
							id="email"
							type="text"
							placeholder="Enter your email"
							required="required"
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
						/>
					</div>
					<div className="row col-md-6 offset-md-3 form-group">
						<label className="form-label col-md-3" htmlFor="name">
							<b>Address: </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<input
							className="form-control col-md-8"
							id="name"
							type="text"
							placeholder="Enter your address"
							required="required"
							value={address}
							onChange={(e) => {
								setAddress(e.target.value);
								setNewUser({ ...newUser, address: e.target.value });
							}}
						/>
					</div>
					<div className="row col-md-6 offset-md-3 form-group">
						<label className="form-label col-md-3" htmlFor="name">
							<b>Date Of Birth:</b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<input
							className="form-control col-md-8"
							id="dob"
							type="date"
							required="required"
							value={dob}
							onChange={(e) => {
								setDob(e.target.value);
								setNewUser({ ...newUser, dob: e.target.value });
							}}
							max={today}
						/>
					</div>
					<div className="row col-md-6 offset-md-3 form-group">
						<label className="form-label col-md-3" htmlFor="name">
							<b>Gender: </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
						<div className="row ml-2">
							<Form.Check
								onChange={() => {
									setGender("Male");
								}}
								checked={gender === "Male"}
								className="form-check col-5 col-md-4"
								inline
								label="Male"
								type="radio"
								id={`inline-radio-1`}
							/>
							<Form.Check
								onChange={() => {
									setGender("Female");
								}}
								checked={gender === "Female"}
								className="form-check col-5 col-md-4"
								inline
								label="Female"
								type="radio"
								id={`inline-radio-1`}
							/>
						</div>
						<div className="col-md-6 offset-md-6 offset-4 form-group">
							<button className="btn btn-success mt-3">Submit</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};
export default AddReceptionScreen;
