/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getDoctorStatisticsForParticularYear = createSlice({
	name: 'getDoctorStatisticsForParticularYear',
	initialState: {},
	reducers: {
		getDoctorStatisticsForParticularYearRequest: (state) => {
			state.loading = true;
		},
		getDoctorStatisticsForParticularYearSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.getDoctorStatisticsForParticularYear = action.payload;
			state.error = false;
			state.success = true;
		},
		getDoctorStatisticsForParticularYearFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getDoctorStatisticsForParticularYearReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getDoctorStatisticsForParticularYearRequest,
	getDoctorStatisticsForParticularYearSuccess,
	getDoctorStatisticsForParticularYearFailure,
	getDoctorStatisticsForParticularYearReset,
} = getDoctorStatisticsForParticularYear.actions;
export default getDoctorStatisticsForParticularYear.reducer;
