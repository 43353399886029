/** @format */

import { React, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get_scheduleAppointments_To_Doctor_Reception } from "../../redux/prescriptionRedux/prescriptionApiCalls";
import Meta from "../../Components/Meta";
import { Col, Row, Container } from "react-bootstrap";

const ScheduledAppointments = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const [selectDate, setSelectDate] = useState("Choose Date");
	const [selectedOrNot, setSelectedOrNot] = useState(false);
	const handleSelection = (eventKey) => {
		setSelectDate(eventKey);
		setSelectedOrNot(false);
	};
	const searchForScheduled = () => {
		if (selectDate === "Choose Date") {
			alert("first choose date");
		} else {
			setSelectedOrNot(true);
			console.log(selectDate);
			const tempArr = selectDate.split("-");
			const dateModified = Number(
				tempArr[2].trim() + tempArr[1].trim() + tempArr[0].trim()
			);
			console.log(dateModified);
			dispatch(get_scheduleAppointments_To_Doctor_Reception(dateModified));
		}
	};
	const { get_scheduleAppointments_To_Doctor_Reception1 } = useSelector(
		(state) => state.getScheduleAppointmentsForNotificationToDoctorReception
	);
	console.log("line29");
	console.log(get_scheduleAppointments_To_Doctor_Reception1);
	const days = [];
	const currDate = new Date(); // Get current date
	for (let i = 0; i < 7; i++) {
		const currentDate = new Date(
			currDate.getFullYear(),
			currDate.getMonth(),
			currDate.getDate() + i
		);
		const date = currentDate.getDate();
		const month = currentDate.getMonth() + 1; // Month index starts from 0
		const year = currentDate.getFullYear();
		const formattedDate =
			(date?.toString()?.length === 1
				? "0" + date?.toString()
				: date?.toString()) +
			"-" +
			(month?.toString()?.length === 1 ? "0" + month?.toString() : month) +
			"-" +
			year?.toString();
		days.push(formattedDate);
	}
	return (
		<>
			<Meta />
			<h1 className="header-center">Scheduled Appointments</h1>
			<Container className="header-center mt-5">
				<Row className="justify-content-center">
					<Col md="auto" className="text-center mt-1">
						<h6>
							Select the filters{" "}
							<span className="text-danger">
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md="auto" className="text-center mt-1">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={selectDate}
							onSelect={handleSelection}
						>
							{days.map((day, index) => (
								<Dropdown.Item eventKey={day}>{day}</Dropdown.Item>
							))}
						</DropdownButton>
					</Col>
					<Col md="auto" className="text-center mt-1">
						<Button
							id="searchButton1"
							className="btn btn-primary"
							onClick={searchForScheduled}
						>
							Search
						</Button>
					</Col>
				</Row>
			</Container>
			<div className="container mt-5">
				{selectedOrNot && (
					<div className="scheduled-table">
						{get_scheduleAppointments_To_Doctor_Reception1 !==
						"No appointments" ? (
							<Table striped bordered hover className="custom-table">
								<thead>
									<tr>
										<th>S.no</th>
										<th>Patient name</th>
										<th>Mobile number</th>
										<th>Doctor name</th>
										<th>Message for visit</th>
									</tr>
								</thead>
								<tbody>
									{get_scheduleAppointments_To_Doctor_Reception1?.scheduledAppointments?.map(
										({ mobilenumber, doctorName, message, user }, sno) => (
											<tr>
												<td>{sno + 1}</td>
												<td>{user}</td>
												<td>{mobilenumber}</td>
												<td>{doctorName}</td>
												<td>{message}</td>
											</tr>
										)
									)}
								</tbody>
							</Table>
						) : (
							<h4 className="header-center text-danger">
								No scheduled appointments for the selected date
							</h4>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default ScheduledAppointments;
