/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import { editDoctor } from "../../../redux/doctorRedux/doctorApiCalls";

const MyProfileScreen = () => {
	const dispatch = useDispatch();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const [editClicked, setEditClicked] = useState(false);
	const [name, setName] = useState(doctorInfo?.data?.user?.name);
	const [mno, setMno] = useState(doctorInfo?.data?.user?.mobilenumber);
	const [email, setEmail] = useState(doctorInfo?.data?.user?.email);
	const [dob, setDob] = useState(doctorInfo?.data?.user?.dob);
	const [gender, setGender] = useState(doctorInfo?.data?.user?.gender);
	const [address, setAddress] = useState(doctorInfo?.data?.user?.address);
	const [designation, setDesignation] = useState(
		doctorInfo?.data?.user?.aboutMe?.designation
	);
	const [education, setEducation] = useState(
		doctorInfo?.data?.user?.aboutMe?.education
	);
	const [experience, setExperience] = useState(
		doctorInfo?.data?.user?.aboutMe?.experience
	);
	const [personality, setPersonality] = useState(
		doctorInfo?.data?.user?.aboutMe?.personality
	);
	const [opConsultationFee, setOpConsultationFee] = useState(
		doctorInfo?.data?.user?.opConsultationFee
	);
	const [ipConsultationFee, setIpConsultationFee] = useState(
		doctorInfo?.data?.user?.ipConsultationFee
	);
	const [ipSurgeryFee, setIpSurgeryFee] = useState(
		doctorInfo?.data?.user?.ipSurgeryFees
	);

	console.log(doctorInfo);

	const discardChanges = () => {
		setEditClicked(false);
		setName(doctorInfo?.data?.user?.name);
		setMno(doctorInfo?.data?.user?.mobilenumber);
		setEmail(doctorInfo?.data?.user?.email);
		setDob(doctorInfo?.data?.user?.dob);
		setGender(doctorInfo?.data?.user?.gender);
		setAddress(doctorInfo?.data?.user?.address);
		setDesignation(doctorInfo?.data?.user?.aboutMe?.designation);
		setEducation(doctorInfo?.data?.user?.aboutMe?.education);
		setExperience(doctorInfo?.data?.user?.aboutMe?.experience);
		setPersonality(doctorInfo?.data?.user?.aboutMe?.personality);
		setOpConsultationFee(doctorInfo?.data?.user?.ipConsultationFee);
		setIpConsultationFee(doctorInfo?.data?.user?.ipConsultationFee);
		setIpSurgeryFee(doctorInfo?.data?.user?.ipSurgeryFees);
	};

	const submitHandler = () => {
		dispatch(
			editDoctor(
				name,
				mno,
				email,
				doctorInfo?.data?.user?.speciality,
				dob,
				gender,
				designation,
				education,
				experience,
				personality,
				address,
				doctorInfo?.data?.user?._id,
				Number(opConsultationFee),
				Number(ipConsultationFee),
				Number(ipSurgeryFee)
			)
		);
		setEditClicked(false);
	};
	return (
		<>
			<Meta />

			<h3 className='header-center'>About Me</h3>
			<button
				className='btn btn-outline-success offset-md-10'
				onClick={() => setEditClicked(true)}>
				<i className='fa fa-edit'></i>
				{"  "}Edit
			</button>
			<div className='card col-12 col-md-10 offset-md-1 mt-2 mb-3'>
				<div class='card-body'>
					<div className='card-text'>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Name:</div>
							{editClicked ? (
								<input
									type='text'
									className='col-8 form-control'
									value={name}
									onChange={(e) => setName(e.target.value)}
									disabled
								/>
							) : (
								<div className='col-8'>{doctorInfo?.data?.user?.name}</div>
							)}
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Mobile No:</div>
							{editClicked ? (
								<input
									type='text'
									className='col-8 form-control'
									value={mno}
									onChange={(e) => setMno(e.target.value)}
								/>
							) : (
								<div className='col-8'>
									{doctorInfo?.data?.user?.mobilenumber}
								</div>
							)}
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Email Id:</div>
							{editClicked ? (
								<input
									type='text'
									className='col-8 form-control'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							) : (
								<div className='col-8'>{doctorInfo?.data?.user?.email}</div>
							)}
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Date Of Birth:</div>
							{editClicked ? (
								<input
									type='date'
									className='col-8 form-control'
									value={dob}
									onChange={(e) => setDob(e.target.value)}
								/>
							) : (
								<div className='col-8'>{doctorInfo?.data?.user?.dob}</div>
							)}
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Gender:</div>
							{editClicked ? (
								<div className='row mt-2'>
									<div className='col-6'>
										<Form.Check
											onChange={() => {
												setGender("Male");
											}}
											checked={gender === "Male"}
											className='form-control'
											inline
											label='Male'
											type='radio'
											id={`inline-radio-1`}
										/>
									</div>
									<div className='col-6'>
										<Form.Check
											onChange={() => {
												setGender("Female");
											}}
											checked={gender === "Female"}
											inline
											label='Female'
											className='form-control'
											type='radio'
											id={`inline-radio-2`}
										/>
									</div>
								</div>
							) : (
								<div className='col-8'>{doctorInfo?.data?.user?.gender}</div>
							)}
						</div>
						<div className='row mt-3'>
							<label className='col-4 col-md-3 cardHeading' htmlFor='email'>
								Speciality
							</label>

							<div className='col-8'>
								{Array.isArray(doctorInfo?.data?.user?.speciality) &&
									doctorInfo?.data?.user?.speciality?.map((dept) => (
										<button className='btn btn-secondary mr-1' disabled>
											{dept + " "}
										</button>
									))}
							</div>
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Designation:</div>
							{editClicked ? (
								<input
									type='text'
									className='col-8 form-control'
									value={designation}
									onChange={(e) => setDesignation(e.target.value)}
								/>
							) : (
								<div className='col-8'>
									{doctorInfo?.data?.user?.aboutMe?.designation}
								</div>
							)}
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Education:</div>
							{editClicked ? (
								<input
									type='text'
									className='col-8 form-control'
									value={education}
									onChange={(e) => setEducation(e.target.value)}
								/>
							) : (
								<div className='col-8'>
									{doctorInfo?.data?.user?.aboutMe?.education}
								</div>
							)}
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Experience:</div>
							{editClicked ? (
								<input
									type='date'
									className='col-8 form-control'
									value={experience}
									onChange={(e) => setExperience(e.target.value)}
								/>
							) : (
								<div className='col-8'>
									{doctorInfo?.data?.user?.aboutMe?.experience}
								</div>
							)}
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Personality:</div>
							{editClicked ? (
								<input
									type='text'
									className='col-8 form-control'
									value={personality}
									onChange={(e) => setPersonality(e.target.value)}
								/>
							) : (
								<div className='col-8'>
									{doctorInfo?.data?.user?.aboutMe?.personality}
								</div>
							)}
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>Address:</div>
							{editClicked ? (
								<textarea
									type='text'
									className='col-8 form-control'
									value={address}
									onChange={(e) => setAddress(e.target.value)}></textarea>
							) : (
								<div className='col-8'>{doctorInfo?.data?.user?.address}</div>
							)}
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>
								OP Consultation Fee:
							</div>
							{editClicked ? (
								<input
									type='text'
									className='col-8 form-control'
									value={opConsultationFee}
									onChange={(e) => setOpConsultationFee(e.target.value)}
								/>
							) : (
								<div className='col-8'>
									{doctorInfo?.data?.user?.opConsultationFee}
								</div>
							)}
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>
								IP Consultation Fee:
							</div>
							{editClicked ? (
								<input
									type='text'
									className='col-8 form-control'
									value={ipConsultationFee}
									onChange={(e) => setIpConsultationFee(e.target.value)}
								/>
							) : (
								<div className='col-8'>
									{doctorInfo?.data?.user?.ipConsultationFee}
								</div>
							)}
						</div>
						<div className='row mt-2'>
							<div className='col-4 col-md-3 cardHeading'>IP Surgery Fee:</div>
							{editClicked ? (
								<input
									type='text'
									className='col-8 form-control'
									value={ipSurgeryFee}
									onChange={(e) => setIpSurgeryFee(e.target.value)}
								/>
							) : (
								<div className='col-8'>
									{doctorInfo?.data?.user?.ipSurgeryFees}
								</div>
							)}
						</div>
					</div>
				</div>
				{editClicked && (
					<div className='row mt-2'>
						<button
							className='btn btn-danger col-4 offset-1 col-md-2 offset-md-4'
							onClick={discardChanges}>
							Discard Changes
						</button>
						<button
							className='btn btn-success col-4 col-md-2 offset-1'
							onClick={submitHandler}>
							Submit Changes
						</button>
					</div>
				)}
			</div>
		</>
	);
};

export default MyProfileScreen;
