import { createSlice } from "@reduxjs/toolkit";

const deleteLabTechReducer = createSlice({
	name: "deleteLabTech",
	initialState: {},
	reducers: {
		deleteLabTechRequest: (state) => {
			state.loading = true;
		},
		deleteLabTechSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.deleteLabTech = action.payload;
			state.error = false;
			state.success = true;
		},
		deleteLabTechFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		deleteLabTechReset: (state) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	deleteLabTechRequest,
	deleteLabTechSuccess,
	deleteLabTechFailure,
	deleteLabTechReset,
} = deleteLabTechReducer.actions;
export default deleteLabTechReducer.reducer;
