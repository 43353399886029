/** @format */
import Message from '../../../Components/Message';
import React, { useEffect, useState } from 'react';
import { Col, Row, Container, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loader from '../../../Components/Loader';
import Meta from '../../../Components/Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {
	getTemplate1,
	updateTemplate,
	getTemplateNames,
} from '../../../redux/doctorRedux/doctorApiCalls';
import Table from 'react-bootstrap/Table';
import { getTemplateReset } from '../../../redux/doctorRedux/Template_Management/getTemplate';
import { UpdateTemplateReset } from '../../../redux/doctorRedux/Template_Management/updateTemplate';
import { getTemplateNamesReset } from '../../../redux/doctorRedux/Template_Management/getTemplateNames';
import { allMessagesStart } from '../../../redux/allMessages';

const DynamicTemplateCreateRow = ({
	index,
	values,
	handleChange,
	handleDelete,
}) => {
	const [activeBox, setActiveBox] = useState(
		values[`dosage-${index}`]?.includes('O@@') ? false : true
	);
	const [Time, setTime] = useState(
		values[`dosage-${index}`]?.includes('B/F')
			? 'B/F'
			: values[`dosage-${index}`]?.includes('A/F')
			? 'A/F'
			: ''
	);
	const [M, setM] = useState(
		values[`dosage-${index}`]?.includes('M,') ? 'M' : ''
	);
	const [A, setA] = useState(
		values[`dosage-${index}`]?.includes('A,') ? 'A' : ''
	);
	const [N, setN] = useState(
		values[`dosage-${index}`]?.includes('N') ? 'N' : ''
	);
	const [quantity, setQuantity] = useState('');
	const handleFreq = (value) => {
		if (value === 'M') {
			if (M === 'M') setM('');
			else setM('M');
		} else if (value === 'A') {
			if (A === 'A') setA('');
			else setA('A');
		} else if (value === 'N') {
			if (N === 'N') setN('');
			else setN('N');
		}
	};
	useEffect(() => {
		if (Time !== '' && (M === 'M' || A === 'A' || N === 'N')) {
			handleChange(`dosage-${index}`, Time + ',' + M + ',' + A + ',' + N);
			debugger;
			let durationFromMedication =
				duration === 'Days'
					? 1
					: duration === 'Weeks'
					? 7
					: duration === 'Months'
					? 30
					: 365;
			let frequencyFromMedication = M === 'M' ? 1 : 0;
			frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
			frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
			frequencyFromMedication =
				frequencyFromMedication *
				Number(values[`duration-${index}`]?.split(' ')[0]) *
				durationFromMedication;
			setQuantity(frequencyFromMedication);
		} else {
			setQuantity(0);
		}
	}, [Time, M, A, N]);

	const activeBoxSetting = () => {
		setActiveBox(!activeBox);
		handleChange(`dosage-${index}`, '');
		setTime('');
		setM('');
		setA('');
		setN('');
	};

	useEffect(() => {
		if (!values[`duration-${index}`]) {
			handleChange(`duration-${index}`, '1 ' + duration + ' ' + repeat);
		}
	}, []);

	const [duration, setDuration] = useState(
		!values[`duration-${index}`]
			? 'Days'
			: values[`duration-${index}`]?.split(' ')[1]
	);
	const [repeat, setRepeat] = useState(
		!values[`duration-${index}`]
			? 'Daily'
			: values[`duration-${index}`]?.split(' ')[2]
	);
	const changeRepeat = (eventKey) => {
		setRepeat(eventKey);
		handleChange(`duration-${index}`, '1 ' + duration + ' ' + eventKey);
	};
	const changeDur = (eventKey) => {
		setDuration(eventKey);
		handleChange(`duration-${index}`, '1 ' + eventKey + ' ' + repeat);
		let durationFromMedication =
			eventKey === 'Days'
				? 1
				: eventKey === 'Weeks'
				? 7
				: eventKey === 'Months'
				? 30
				: 365;
		let frequencyFromMedication = M === 'M' ? 1 : 0;
		frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
		frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
		frequencyFromMedication =
			frequencyFromMedication * durationFromMedication * 1;
		setQuantity(frequencyFromMedication);
	};
	const incr = (quan) => {
		let quan1 = Number(quan);
		quan1++;
		console.log(quan, 'hi');
		handleChange(
			`duration-${index}`,
			quan1.toString() + ' ' + duration + ' ' + repeat
		);
		let durationFromMedication =
			duration === 'Days'
				? 1
				: duration === 'Weeks'
				? 7
				: duration === 'Months'
				? 30
				: 365;
		let frequencyFromMedication = M === 'M' ? 1 : 0;
		frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
		frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
		frequencyFromMedication =
			frequencyFromMedication * durationFromMedication * quan1;
		setQuantity(frequencyFromMedication);
	};
	const decr = (quan) => {
		quan = Number(quan);
		quan--;
		handleChange(
			`duration-${index}`,
			quan.toString() + ' ' + duration + ' Daily'
		);
		let durationFromMedication =
			duration === 'Days'
				? 1
				: duration === 'Weeks'
				? 7
				: duration === 'Months'
				? 30
				: 365;
		let frequencyFromMedication = M === 'M' ? 1 : 0;
		frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
		frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
		frequencyFromMedication =
			frequencyFromMedication * durationFromMedication * quan;
		setQuantity(frequencyFromMedication);
	};

	return (
		<Card style={{ width: '22rem', marginLeft: '1%', marginTop: '1%' }}>
			<Card.Body>
				<Card.Text>
					<Row className='justify-content-end'>
						<button
							className='btn btn-outline-danger'
							onClick={() => handleDelete(index)}>
							<i className='fa fa-trash'></i>
						</button>
					</Row>
					<Row className='mt-2'>
						<Col>
							<input
								className='form-control'
								name={`name-${index}`}
								value={values[`name-${index}`]}
								onChange={(e) => handleChange(`name-${index}`, e.target.value)}
								placeholder='Enter Medicine'
							/>
						</Col>
					</Row>
					<Row className='mt-2'>
						<Col>
							<button
								className='btn btn-primary'
								onClick={() => activeBoxSetting()}>
								<i class='fas fa-sync-alt'></i>
							</button>
							{activeBox ? (
								<>
									<ButtonGroup aria-label='Basic example'>
										<Button
											onClick={() => setTime('B/F')}
											// variant="secondary"
											variant={Time === 'B/F' ? 'success' : 'secondary'}>
											B/F
										</Button>
										<Button
											onClick={() => setTime('A/F')}
											variant={Time === 'A/F' ? 'success' : 'secondary'}>
											A/F
										</Button>
									</ButtonGroup>
									<Button
										onClick={() => handleFreq('M')}
										variant={M === 'M' ? 'success' : 'outline-success'}>
										M
									</Button>
									<Button
										onClick={() => handleFreq('A')}
										variant={A === 'A' ? 'success' : 'outline-success'}>
										A
									</Button>
									<Button
										onClick={() => handleFreq('N')}
										variant={N === 'N' ? 'success' : 'outline-success'}>
										N
									</Button>
									<DropdownButton
										className='mt-2'
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={repeat}
										onSelect={changeRepeat}>
										<Dropdown.Item eventKey={"Doesn't Repeat"}>
											{"Doesn't Repeat"}
										</Dropdown.Item>
										<Dropdown.Item eventKey={'Daily'}>{'Daily'}</Dropdown.Item>
										<Dropdown.Item eventKey={'Weekly'}>
											{'Weekly'}
										</Dropdown.Item>
										<Dropdown.Item eventKey={'Monthly'}>
											{'Monthly'}
										</Dropdown.Item>
										<Dropdown.Item eventKey={'Yearly'}>
											{'Yearly'}
										</Dropdown.Item>
									</DropdownButton>
								</>
							) : (
								<>
									<input
										type='text'
										name={`dosage-${index}`}
										value={values[`dosage-${index}`].split('@@')[1]}
										onChange={(e) =>
											handleChange(`dosage-${index}`, 'O@@' + e.target.value)
										}
									/>
								</>
							)}
						</Col>
					</Row>
					<Row className='mt-2'>
						<Col md='2'>
							<button
								className='btn btn-primary '
								onClick={() =>
									decr(values[`duration-${index}`]?.split(' ')[0])
								}>
								<i className='fas fa-minus'></i>
							</button>
						</Col>
						<Col md='4'>
							<input
								className='form-control'
								type='text'
								name={`duration-${index}`}
								value={values[`duration-${index}`]?.split(' ')[0]}
								onChange={(e) =>
									handleChange(
										`duration-${index}`,
										e.target.value + ' ' + duration + ' ' + repeat
									)
								}
							/>
						</Col>
						<Col md='2'>
							<button
								className='btn btn-primary '
								onClick={() =>
									incr(values[`duration-${index}`]?.split(' ')[0])
								}>
								<i className='fas fa-plus'></i>
							</button>
						</Col>
						<Col md='4'>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={duration}
								onSelect={changeDur}>
								<Dropdown.Item eventKey={'Days'}>{'Days'}</Dropdown.Item>
								<Dropdown.Item eventKey={'Weeks'}>{'Weeks'}</Dropdown.Item>
								<Dropdown.Item eventKey={'Months'}>{'Months'}</Dropdown.Item>
								<Dropdown.Item eventKey={'Years'}>{'Years'}</Dropdown.Item>
							</DropdownButton>
						</Col>
					</Row>
					{/* <Row className='mt-2'>
						<Col>
							<input
								className='form-control'
								type='text'
								value={`Quantity: ${quantity}`}
							/>
						</Col>
					</Row> */}
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

const EditTemplateScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	// success and failure Message
	const getTemplateNamesData = useSelector((state) => state.getTemplateNames);
	const templatenames = getTemplateNamesData.templatenames;
	const templatenamessuccess = getTemplateNamesData.success;
	const templatenameserror = getTemplateNamesData.error;
	const templatenamesloading = getTemplateNamesData.loading;

	const gettemplatesData = useSelector((state) => state.getTemplate);
	const templates = gettemplatesData;
	const getTemplatessuccess = gettemplatesData.success;
	const getTemplateserror = gettemplatesData.error;
	const getTemplatesloading = gettemplatesData.loading;

	const updateTemplateData = useSelector((state) => state.updateTemplate);
	const updateTemplatesuccess = updateTemplateData.success;
	const updateTemplateerror = updateTemplateData.error;
	const updateTemplateloading = updateTemplateData.loading;

	/////////////////////////////////////////////////////

	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { doctorInfo } = doctorLogin;

	console.log(doctorInfo?.data?.user?._id);

	const [templateName, setTemplateName] = useState('');

	useEffect(() => {
		dispatch(getTemplateNames(doctorInfo?.data?.user?._id));
	}, []);

	// Get Template
	console.log(templatenames);
	const [searchTerm, setSearchTerm] = useState('');

	const [chooseTemplate, setChooseTemplate] = useState('Choose Template');

	const handleTemplateSelect = (eventKey) => {
		setChooseTemplate(eventKey.split('@@')[0]);
		setChooseEditTemplateId(eventKey.split('@@')[1]);
		console.log(
			eventKey.split('@@')[0] +
				' ' +
				eventKey.split('@@')[1] +
				' ' +
				doctorInfo?.data?.user?._id
		);
		dispatch(
			getTemplate1(doctorInfo?.data?.user?._id, eventKey.split('@@')[1])
		);
	};

	//Edit Template

	const [chooseEditTemplateId, setChooseEditTemplateId] = useState('');

	console.log(templates?.getTemplate?.data?.data?.medicines);
	let editrow = [];
	let editvalue = [];
	const [editRows, setEditRows] = useState([]);
	const [editValues, setEditValues] = useState([]);
	const [activeBoxes, setActiveBoxes] = useState([]);

	useEffect(() => {
		if (chooseTemplate !== 'Choose Template') {
			for (
				let i = 0;
				i < templates?.getTemplate?.data?.data?.medicines?.length;
				i++
			) {
				editrow.push(i);
			}
			for (
				let i = 0;
				i < templates?.getTemplate?.data?.data?.medicines?.length;
				i++
			) {
				if (
					templates?.getTemplate?.data?.data?.medicines[i]?.dosage?.includes(
						'O@@'
					)
				) {
					var boolvals = activeBoxes;
					boolvals.push(false);
					setActiveBoxes(boolvals);
				} else {
					var boolvals = activeBoxes;
					boolvals.push(true);
					setActiveBoxes(boolvals);
				}
				editvalue.push({
					name: templates?.getTemplate?.data?.data?.medicines[i]?.name,
					dosage: templates?.getTemplate?.data?.data?.medicines[i]?.dosage,
					duration: templates?.getTemplate?.data?.data?.medicines[i]?.duration,
				});
			}
			setEditRows(editrow);
			setEditValues(editvalue);
		}
	}, [templates?.getTemplate?.data?.data?.medicines]);
	console.log(editValues);

	const handleEditChange = (value, index, key) => {
		const editData = [...editValues];
		if (key === 'name') editData[index].name = value;
		else if (key === 'dosagebox') {
			debugger;
			editData[index].dosage = 'O@@';
			let val = [...activeBoxes];
			val[index] = !value;
			setActiveBoxes(val);
		} else if (key === 'dosage') {
			if (value === 'M') {
				if (editData[index].dosage?.split(',')?.includes('M')) {
					let valls = editData[index].dosage?.split(',');
					let ind = valls.indexOf('M');
					valls[ind] = '';
					editData[index].dosage = valls?.join(',');
				} else {
					let valls = editData[index].dosage?.split(',');
					valls.splice(1, 0, 'M');
					editData[index].dosage = valls?.join(',');
				}
			} else if (value === 'A') {
				if (editData[index].dosage?.split(',')?.includes('A')) {
					let valls = editData[index].dosage?.split(',');
					let ind = valls.indexOf('A');
					valls[ind] = '';
					editData[index].dosage = valls?.join(',');
				} else {
					let valls = editData[index].dosage?.split(',');
					valls.splice(2, 0, 'A');
					editData[index].dosage = valls?.join(',');
				}
			} else if (value === 'N') {
				if (editData[index].dosage?.split(',')?.includes('N')) {
					let valls = editData[index].dosage?.split(',');
					let ind = valls.indexOf('N');
					valls[ind] = '';
					editData[index].dosage = valls?.join(',');
				} else {
					let valls = editData[index].dosage?.split(',');
					valls.splice(3, 0, 'N');
					editData[index].dosage = valls?.join(',');
				}
			} else {
				editData[index].dosage = value;
			}
		} else if (key === 'duration') editData[index].duration = value;
		console.log(editData);
		setEditValues(editData);
	};

	const handleEditDelete = (index) => {
		const editData = [...editValues];
		if (index > -1) {
			editData.splice(index, 1);
		}
		setEditValues(editData);
		console.log(editValues);
	};

	console.log(editValues);

	const [editrows, seteditRows] = useState([]);
	const [editvalues, seteditValues] = useState({});

	const handleChangeEdit = (name, value) => {
		// const { name, value } = event.target;
		seteditValues((prevValues) => ({ ...prevValues, [name]: value }));
	};

	const handleAddRowEdit = () => {
		seteditRows((prevRows) => [...prevRows, Date.now()]);
	};

	const handleDeleteRowEdit = (index) => {
		const updatedValues = { ...editvalues };
		Object.keys(updatedValues)
			.filter((key) => key.includes(index))
			.forEach((key) => delete updatedValues[key]);

		seteditValues(updatedValues);

		seteditRows((prevRows) =>
			prevRows.filter((rowIndex) => rowIndex !== index)
		);
	};

	console.log(editrows);
	console.log(editvalues);

	useEffect(() => {
		if (chooseEditTemplateId !== '')
			dispatch(getTemplate1(doctorInfo?.data?.user?._id, chooseEditTemplateId));
	}, [updateTemplateloading]);

	const editSubmitHandler = () => {
		// console.log(rows, values);

		let flag = false;
		Object.keys(editvalues).forEach((key) => {
			let id = key.replace(/-\d+$/, '');

			if (key.includes('dosage')) {
				let index = key.split('-')[1];
				let dosage = editvalues[`dosage-${index}`];
				if (!dosage?.includes('A/F') && !dosage?.includes('B/F')) {
					flag = true;
				}
			}
		});

		if (flag) {
			alert('Please Select B/F or A/F for the entered medicines.');
		} else {
			let extractedValues = [];
			Object.keys(editvalues).forEach((key) => {
				let id = key.replace(/-\d+$/, '');

				if (key.includes('name')) {
					let index = key.split('-')[1];
					let name = editvalues[`name-${index}`];
					let dosage = editvalues[`dosage-${index}`];
					let duration = editvalues[`duration-${index}`];

					extractedValues?.push({
						name: name,
						dosage: dosage,
						duration: duration,
					});
				}
			});

			console.log(extractedValues);
			const medicines = extractedValues;
			let updatedMed = editValues;
			for (let i = 0; i < medicines.length; i++) {
				updatedMed.push({
					name: medicines[i].name,
					dosage: medicines[i].dosage,
					duration: medicines[i].duration,
				});
			}

			setEditValues(updatedMed);
			dispatch(
				updateTemplate(
					doctorInfo?.data?.user?._id,
					chooseEditTemplateId,
					editValues
				)
			);
			let arr = [];
			setEditValues(arr);
			seteditRows(arr);
			seteditValues(arr);
			// setChooseTemplate("Choose Template");
			// setChooseEditTemplateId("");
			setEditClicked(false);
		}
	};

	const handleReset = () => {
		let arr = [];
		setEditValues(arr);
		seteditRows(arr);
		seteditValues(arr);
		for (
			let i = 0;
			i < templates?.getTemplate?.data?.data?.medicines?.length;
			i++
		) {
			editrow.push(i);
		}
		for (
			let i = 0;
			i < templates?.getTemplate?.data?.data?.medicines?.length;
			i++
		) {
			if (
				templates?.getTemplate?.data?.data?.medicines[i]?.dosage?.includes(
					'O@@'
				)
			) {
				var boolvals = activeBoxes;
				boolvals.push(false);
				setActiveBoxes(boolvals);
			} else {
				var boolvals = activeBoxes;
				boolvals.push(true);
				setActiveBoxes(boolvals);
			}
			editvalue.push({
				name: templates?.getTemplate?.data?.data?.medicines[i]?.name,
				dosage: templates?.getTemplate?.data?.data?.medicines[i]?.dosage,
				duration: templates?.getTemplate?.data?.data?.medicines[i]?.duration,
			});
		}
		setEditRows(editrow);
		setEditValues(editvalue);
		setEditClicked(false);
	};

	//ALerts//////////////////////////////////////////////////////////////////

	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);

	const [showPopup4, setShowPopup4] = React.useState(false);
	const toggleShowInfoPopup4 = (e) => {
		setShowPopup4(!showPopup4);
		e.preventDefault();
		console.log('error');
		dispatch(getTemplateReset());
	};
	const [showPopup5, setShowPopup5] = React.useState(false);
	const toggleShowInfoPopup5 = (e) => {
		setShowPopup5(!showPopup5);
		e.preventDefault();
		console.log('error');
		dispatch(getTemplateNamesReset());
	};

	const [showPopup6, setShowPopup6] = React.useState(false);
	const toggleShowInfoPopup6 = (e) => {
		setShowPopup6(!showPopup6);
		e.preventDefault();
		console.log('error');
		dispatch(UpdateTemplateReset());
	};

	useEffect(() => {
		if (updateTemplatesuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(UpdateTemplateReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [updateTemplatesuccess, dispatch]);

	const [editClicked, setEditClicked] = useState(false);

	const handleEditBtnClicked = () => {
		if (chooseTemplate !== 'Choose Template') setEditClicked(true);
		else alert('Please choose a template.');
	};

	return (
		<div className='pageSize'>
			<Meta />
			<h2 className='header-center'>Edit Medicine Template</h2>
			{getTemplateserror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup4}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{getTemplateserror}
				</Message>
			)}
			{templatenameserror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup5}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{templatenameserror}
				</Message>
			)}
			{updateTemplateerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup6}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{updateTemplateerror}
				</Message>
			)}

			{(templatenamesloading ||
				getTemplatesloading ||
				updateTemplateloading) && <Loader />}
			<Container className='header-center mt-5'>
				<Row>
					<Col md='auto' className='text-center'>
						<h5>Choose Template:</h5>
					</Col>
					<Col md='auto' className='text-center'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={chooseTemplate}
							onSelect={handleTemplateSelect}>
							<div className='dropButton'>
								{templatenames?.data?.data ? (
									templatenames?.data?.data.map((name, index) => (
										<Dropdown.Item eventKey={name + '@@' + index}>
											{name}
										</Dropdown.Item>
									))
								) : (
									<Dropdown.Item disabled>No items found</Dropdown.Item>
								)}
							</div>
						</DropdownButton>
					</Col>
				</Row>
			</Container>

			<Container className='header-center mt-3 mb-3'>
				<Row className='justify-content-end'>
					<button
						className='btn btn-outline-success'
						onClick={handleEditBtnClicked}>
						<i className='fa fa-edit'></i>
						{'  '}Edit
					</button>
				</Row>
			</Container>

			{!editClicked && (
				<div>
					<Container>
						{chooseTemplate !== 'Choose Template' ? (
							<>
								<Table bordered className='custom-table'>
									<thead>
										<tr>
											<th scope='col'>S.No</th>
											<th scope='col'>Medicine</th>
											<th scope='col'>Dosage</th>
											<th scope='col'>Duration</th>
										</tr>
									</thead>
									<tbody>
										{templates?.getTemplate?.data?.data?.medicines ? (
											templates?.getTemplate?.data?.data?.medicines.map(
												({ name, dosage, duration }, index) => (
													<tr>
														<td scope='row'>{index + 1}</td>
														<td>{name}</td>
														{dosage?.includes('O@@') ? (
															<td>{dosage?.split('O@@')[1]}</td>
														) : (
															<td>
																{dosage?.includes('O@@') ? (
																	dosage?.split('O@@')[1]
																) : (
																	<>
																		{dosage?.includes('A/F') ? (
																			<span className='medication-definition after-food'>
																				AF
																			</span>
																		) : (
																			<span className='medication-definition before-food'>
																				BF
																			</span>
																		)}
																		{dosage?.split(',')?.includes('M') && (
																			<span className='medication-definition morning'>
																				M
																			</span>
																		)}
																		{dosage?.split(',')?.includes('A') && (
																			<span className='medication-definition afternoon'>
																				AN
																			</span>
																		)}
																		{dosage?.split(',')?.includes('N') && (
																			<span className='medication-definition night'>
																				N
																			</span>
																		)}{' '}
																		{duration?.split(' ')[2]}
																	</>
																)}
															</td>
														)}
														<td>
															{duration?.split(' ')[0]}{' '}
															{duration.charAt(0) === '1'
																? duration?.split(' ')[1].replace('s', '')
																: duration?.split(' ')[1]}
														</td>
													</tr>
												)
											)
										) : (
											<div></div>
										)}
									</tbody>
								</Table>
								<div className='row mt-4 mb-2 section'>
									<div className='col-md-12'>
										<div className='medication-definition-box'>
											<span className='medication-definition before-food'>
												BF
											</span>
											<span className='mt-1'>Before Food</span>
											<span className='medication-definition after-food'>
												AF
											</span>
											<span className='mt-1'>After Food</span>
											<span className='medication-definition morning'>M</span>
											<span className='mt-1'>Morning</span>
											<span className='medication-definition afternoon'>
												AN
											</span>
											<span className='mt-1'>After Noon</span>
											<span className='medication-definition night'>N</span>
											<span className='mt-1'>Night</span>
										</div>
									</div>
								</div>
							</>
						) : (
							<h5 className='header-center text-danger'>
								Choose a Template to view the medicines
							</h5>
						)}
					</Container>
				</div>
			)}
			{editClicked && (
				<>
					<Container>
						<Row className='mt-2'>
							{editValues.map((medicine, index) => {
								let durationFromMedication =
									medicine?.duration?.split(' ')[1] === 'Days'
										? 1
										: medicine?.duration?.split(' ')[1] === 'Weeks'
										? 7
										: medicine?.duration?.split(' ')[1] === 'Months'
										? 30
										: 365;
								let frequencyFromMedication = medicine?.dosage
									?.split(',')
									?.includes('M')
									? 1
									: 0;

								frequencyFromMedication =
									frequencyFromMedication +
									(medicine?.dosage?.split(',')?.includes('A') ? 1 : 0);
								frequencyFromMedication =
									frequencyFromMedication +
									(medicine?.dosage?.split(',')?.includes('N') ? 1 : 0);

								let quantity =
									frequencyFromMedication *
									durationFromMedication *
									Number(medicine?.duration?.split(' ')[0]);
								return (
									<Card
										style={{
											width: '22rem',
											marginLeft: '1%',
											marginTop: '1%',
										}}>
										<Card.Body>
											<Card.Text>
												<Row className='justify-content-end'>
													<button
														className='btn btn-outline-danger'
														onClick={() => handleEditDelete(index)}>
														<i className='fa fa-trash'></i>
													</button>
												</Row>
												<Row className='mt-2'>
													<Col>
														<input
															className='form-control'
															// name={`name-${index}`}
															value={medicine?.name}
															onChange={(e) =>
																handleEditChange(e.target.value, index, 'name')
															}
															placeholder='Enter Medicine'
														/>
													</Col>
												</Row>
												<Row className='mt-2'>
													<Col>
														<button
															className='btn btn-primary'
															onClick={(e) =>
																handleEditChange(
																	activeBoxes[index],
																	index,
																	'dosagebox'
																)
															}>
															<i class='fas fa-sync-alt'></i>
														</button>
														{medicine?.dosage?.includes('O@@') ? (
															<input
																type='text'
																// name={`dosage-${index}`}
																value={medicine?.dosage?.split('@@')[1]}
																onChange={(e) =>
																	handleEditChange(
																		'O@@' + e.target.value,
																		index,
																		'dosage'
																	)
																}
															/>
														) : (
															<>
																<ButtonGroup aria-label='Basic example'>
																	<Button
																		onClick={() =>
																			handleEditChange(
																				'B/F,' +
																					medicine?.dosage
																						?.split(',')
																						?.slice(1)
																						?.join(','),
																				index,
																				'dosage'
																			)
																		}
																		variant={
																			medicine?.dosage?.includes('B/F')
																				? 'success'
																				: 'secondary'
																		}>
																		B/F
																	</Button>
																	<Button
																		onClick={() =>
																			handleEditChange(
																				'A/F,' +
																					medicine?.dosage
																						?.split(',')
																						?.slice(1)
																						?.join(','),
																				index,
																				'dosage'
																			)
																		}
																		variant={
																			medicine?.dosage?.includes('A/F')
																				? 'success'
																				: 'secondary'
																		}>
																		A/F
																	</Button>
																</ButtonGroup>
																<Button
																	onClick={() =>
																		handleEditChange('M', index, 'dosage')
																	}
																	variant={
																		medicine?.dosage?.split(',')?.includes('M')
																			? 'success'
																			: 'outline-success'
																	}>
																	M
																</Button>
																<Button
																	onClick={() =>
																		handleEditChange('A', index, 'dosage')
																	}
																	variant={
																		medicine?.dosage?.split(',')?.includes('A')
																			? 'success'
																			: 'outline-success'
																	}>
																	A
																</Button>
																<Button
																	onClick={() =>
																		handleEditChange('N', index, 'dosage')
																	}
																	variant={
																		medicine?.dosage?.split(',')?.includes('N')
																			? 'success'
																			: 'outline-success'
																	}>
																	N
																</Button>
																<DropdownButton
																	className='mt-2'
																	key='down-centered'
																	id={`dropdown-button-drop-down-centered`}
																	drop='down-centered'
																	variant='success'
																	title={medicine?.duration?.split(' ')[2]}>
																	<Dropdown.Item
																		eventKey={"Doesn't Repeat"}
																		onClick={() =>
																			handleEditChange(
																				medicine?.duration?.split(' ')[0] +
																					' ' +
																					medicine?.duration?.split(' ')[1] +
																					" Doesn't Repeat",
																				index,
																				'duration'
																			)
																		}>
																		{"Doesn't Repeat"}
																	</Dropdown.Item>
																	<Dropdown.Item
																		eventKey={'Daily'}
																		onClick={() =>
																			handleEditChange(
																				medicine?.duration?.split(' ')[0] +
																					' ' +
																					medicine?.duration?.split(' ')[1] +
																					' Daily',
																				index,
																				'duration'
																			)
																		}>
																		{'Daily'}
																	</Dropdown.Item>
																	<Dropdown.Item
																		eventKey={'Weekly'}
																		onClick={() =>
																			handleEditChange(
																				medicine?.duration?.split(' ')[0] +
																					' ' +
																					medicine?.duration?.split(' ')[1] +
																					' Weekly',
																				index,
																				'duration'
																			)
																		}>
																		{'Weekly'}
																	</Dropdown.Item>
																	<Dropdown.Item
																		eventKey={'Monthly'}
																		onClick={() =>
																			handleEditChange(
																				medicine?.duration?.split(' ')[0] +
																					' ' +
																					medicine?.duration?.split(' ')[1] +
																					' Monthly',
																				index,
																				'duration'
																			)
																		}>
																		{'Monthly'}
																	</Dropdown.Item>
																	<Dropdown.Item
																		eventKey={'Yearly'}
																		onClick={() =>
																			handleEditChange(
																				medicine?.duration?.split(' ')[0] +
																					' ' +
																					medicine?.duration?.split(' ')[1] +
																					' Yearly',
																				index,
																				'duration'
																			)
																		}>
																		{'Yearly'}
																	</Dropdown.Item>
																</DropdownButton>
															</>
														)}
													</Col>
												</Row>
												<Row className='mt-2'>
													<Col md='2'>
														<button
															className='btn btn-primary '
															onClick={() =>
																handleEditChange(
																	(
																		Number(medicine?.duration?.split(' ')[0]) -
																		1
																	).toString() +
																		' ' +
																		medicine?.duration?.split(' ')[1] +
																		' ' +
																		medicine?.duration?.split(' ')[2],
																	index,
																	'duration'
																)
															}>
															<i className='fas fa-minus'></i>
														</button>
													</Col>
													<Col md='4'>
														<input
															className='form-control'
															type='text'
															// name={`duration-${index}`}
															value={medicine?.duration?.split(' ')[0]}
															onChange={(e) =>
																handleEditChange(
																	e.target.value +
																		' ' +
																		medicine?.duration?.split(' ')[1] +
																		' ' +
																		medicine?.duration?.split(' ')[2],
																	index,
																	'duration'
																)
															}
														/>
													</Col>
													<Col md='2'>
														<button
															className='btn btn-primary '
															onClick={() =>
																handleEditChange(
																	(
																		Number(medicine?.duration?.split(' ')[0]) +
																		1
																	).toString() +
																		' ' +
																		medicine?.duration?.split(' ')[1] +
																		' ' +
																		medicine?.duration?.split(' ')[2],
																	index,
																	'duration'
																)
															}>
															<i className='fas fa-plus'></i>
														</button>
													</Col>
													<Col md='4'>
														<DropdownButton
															key='down-centered'
															id={`dropdown-button-drop-down-centered`}
															drop='down-centered'
															variant='success'
															title={medicine?.duration?.split(' ')[1]}>
															<Dropdown.Item
																eventKey={'Days'}
																onClick={() =>
																	handleEditChange(
																		medicine?.duration?.split(' ')[0] +
																			' ' +
																			'Days ' +
																			medicine?.duration?.split(' ')[2],
																		index,
																		'duration'
																	)
																}>
																{'Days'}
															</Dropdown.Item>
															<Dropdown.Item
																eventKey={'Weeks'}
																onClick={() =>
																	handleEditChange(
																		medicine?.duration?.split(' ')[0] +
																			' ' +
																			'Weeks ' +
																			medicine?.duration?.split(' ')[2],
																		index,
																		'duration'
																	)
																}>
																{'Weeks'}
															</Dropdown.Item>
															<Dropdown.Item
																eventKey={'Months'}
																onClick={() =>
																	handleEditChange(
																		medicine?.duration?.split(' ')[0] +
																			' ' +
																			'Months ' +
																			medicine?.duration?.split(' ')[2],
																		index,
																		'duration'
																	)
																}>
																{'Months'}
															</Dropdown.Item>
															<Dropdown.Item
																eventKey={'Years'}
																onClick={() =>
																	handleEditChange(
																		medicine?.duration?.split(' ')[0] +
																			' ' +
																			'Years ' +
																			medicine?.duration?.split(' ')[2],
																		index,
																		'duration'
																	)
																}>
																{'Years'}
															</Dropdown.Item>
														</DropdownButton>
													</Col>
												</Row>
												{/* <Row className='mt-2'>
													<Col>
														<input
															className='form-control'
															type='text'
															value={`Quantity: ${quantity ? quantity : 0}`}
														/>
													</Col>
												</Row> */}
											</Card.Text>
										</Card.Body>
									</Card>
								);
							})}

							{editrows.map((rowIndex) => (
								<DynamicTemplateCreateRow
									key={rowIndex}
									index={rowIndex}
									values={editvalues}
									handleChange={handleChangeEdit}
									handleDelete={handleDeleteRowEdit}
								/>
							))}
						</Row>

						{chooseTemplate !== 'Choose Template' ? (
							<Row className='justify-content-center mt-3'>
								<button
									className='btn btn-secondary'
									onClick={handleAddRowEdit}>
									+ Add more medicines
								</button>
							</Row>
						) : (
							<div> </div>
						)}
					</Container>
					<Container className='mt-4'>
						{chooseTemplate !== 'Choose Template' ? (
							<div className='row mt-4 mb-2 offset-md-8 section'>
								<button className='btn btn-success' onClick={editSubmitHandler}>
									Save changes
								</button>
								<button
									className='offset-md-1 btn btn-danger'
									onClick={handleReset}>
									Discard changes
								</button>
							</div>
						) : (
							<div> </div>
						)}
					</Container>
				</>
			)}
		</div>
	);
};

export default EditTemplateScreen;
