/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getCustomRevenuesOftheHospital = createSlice({
	name: "getCustomRevenuesOftheHospital",
	initialState: {},
	reducers: {
		getCustomRevenuesOftheHospitalRequest: (state) => {
			state.loading = true;
		},
		getCustomRevenuesOftheHospitalSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getCustomRevenuesOftheHospital = action.payload;
			state.error = false;
			state.success = true;
		},
		getCustomRevenuesOftheHospitalFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getCustomRevenuesOftheHospitalReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getCustomRevenuesOftheHospitalRequest,
	getCustomRevenuesOftheHospitalSuccess,
	getCustomRevenuesOftheHospitalFailure,
	getCustomRevenuesOftheHospitalReset,
} = getCustomRevenuesOftheHospital.actions;
export default getCustomRevenuesOftheHospital.reducer;
