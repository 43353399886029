/** @format */

import Message from "../../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Pagination from "react-bootstrap/Pagination";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import { blockSlot } from "../../../redux/doctorRedux/doctorApiCalls";
import { getTimeslots } from "../../../redux/userApiCalls";
import {
  allMessagesStart,
  allMessagesSuccess,
  allMessagesFailure,
  allMessagesReset,
} from "../../../redux/allMessages";

import { createLeaveReset } from "../../../redux/doctorRedux/Leave_Management/createLeave";
import { deleteLeavesReset } from "../../../redux/doctorRedux/Leave_Management/deleteLeave";
import { getLeavesReset } from "../../../redux/doctorRedux/Leave_Management/getLeaves";
import { blockSlotReset } from "../../../redux/doctorRedux/Slot_Management/blockSlots";
import { TimeSlotGetReset } from "../../../redux/getTimeSlots";

const DoctorBlockSlotsScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const doctorLogin = useSelector((state) => state.doctorLogin);
  const { doctorInfo } = doctorLogin;
  console.log(doctorInfo);

  let days = [];

  const formattedDates = doctorInfo?.data?.user?.appointmentDates?.map(
    (entry) => {
      const dateString = entry.date.toString();

      // Extract year, month, and day
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6);
      const day = ("0" + dateString.slice(6, 8)).slice(-2);

      // Create the formatted date string

      days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
    }
  );

  // success and failure Message
  const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
  const getTimeSlotssuccess = getTimeSlotsData.success;
  const getTimeSlotserror = getTimeSlotsData.error;
  const getTimeSlotsloading = getTimeSlotsData.loading;

  const blockSlotData = useSelector((state) => state.blockSlots);
  const blockSlotsuccess = blockSlotData.success;
  const blockSloterror = blockSlotData.error;
  const blockSlotloading = blockSlotData.loading;

  const { allTimeSlots } = useSelector((state) => state.getTimeSlots);
  console.log(allTimeSlots);
  const [dateOption, setDateOption] = useState("Choose Date");
  const [dateOptionNumeric, setDateOptionNumeric] = useState("");
  const [dayIdx, setDayIdx] = useState("");

  const [dateSelected, setDateSelected] = useState(false);
  const handleDateSelect = (eventKey) => {
    setDateOption(eventKey?.split("@@")[0]);
    setDateOptionNumeric(eventKey?.split("@@")[1]);
    setDayIdx(eventKey?.split("@@")[2]);
    setDateSelected(true);
    setSelectedCheckboxes([]);
    setSelectedCheckboxesIds([]);
    console.log(doctorInfo?.data?.user);
    dispatch(getTimeslots(doctorInfo?.data?.user?._id));
  };

  // Initialize state to hold the values of checked checkboxes
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedCheckboxesIds, setSelectedCheckboxesIds] = useState([]);

  // Create a handler function to update the selectedCheckboxes array
  const handleCheckboxChange = (value, checked) => {
    // const { value, checked } = event.target;

    if (checked) {
      // If the checkbox is checked, add its value to the array
      setSelectedCheckboxes([...selectedCheckboxes, value?.split("@@")[0]]);
      setSelectedCheckboxesIds([
        ...selectedCheckboxesIds,
        value?.split("@@")[1],
      ]);
    } else {
      // If the checkbox is unchecked, remove its value from the array
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== value?.split("@@")[0])
      );
      setSelectedCheckboxesIds(
        selectedCheckboxesIds.filter((item) => item !== value?.split("@@")[1])
      );
    }
  };

  const handleBlockSlot = () => {
    if (selectedCheckboxes.length == 0) {
      alert("No slots selected! please select the slots to be blocked");
    } else {
      selectedCheckboxes.sort(function (a, b) {
        return parseInt(a.split("-")[0]) - parseInt(b.split("-")[0]);
      });
      console.log(selectedCheckboxesIds);

      dispatch(
        blockSlot(
          doctorInfo?.data?.user?._id,
          selectedCheckboxes,
          dateOption,
          dayIdx,
          selectedCheckboxesIds
        )
      );
      setDateOption("Choose Date");
      setDateSelected(false);
      setSelectedCheckboxes([]);
    }
  };

  //ALerts//////////////////////////////////////////////////////////////////

  const [timerRunning, setTimerRunning] = useState(false);
  useEffect(() => {
    dispatch(allMessagesStart());
  }, []);
  const alertmsg = useSelector((state) => state.allMessages);

  useEffect(() => {
    if (blockSlotsuccess && !timerRunning) {
      setTimerRunning(true);

      const timer = setTimeout(() => {
        // Clear the alert and stop the timer after 3 seconds
        dispatch(blockSlotReset());

        setTimerRunning(false);
      }, 3000);

      // Clean up the timer if the component unmounts or the alert changes
    }
  }, [blockSlotsuccess, dispatch]);

  return (
    <>
      <Meta />

      {(getTimeSlotsloading || blockSlotloading) && <Loader />}

      <div>
        <h4 className="header-center">Block Slots</h4>
        <Container className="header-center mt-5">
          <Row>
            <Col md="auto" className="text-center">
              <label className="form-label" htmlFor="name">
                <b>Select Date: </b>
                <span className="text-danger">
                  <b>*</b>
                </span>
              </label>
            </Col>
            <Col md="auto" className="text-center">
              <DropdownButton
                key="down-centered"
                id={`dropdown-button-drop-down-centered`}
                drop="down-centered"
                variant="success"
                title={dateOption}
                onSelect={handleDateSelect}
              >
                <div className="dropScroll">
                  {days.map((day, index) => (
                    <Dropdown.Item
                      eventKey={day?.currDay + "@@" + day?.key + "@@" + index}
                    >
                      {day.currDay}
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            </Col>
          </Row>
        </Container>
        <Container className="header-center mt-5">
          <Row>
            <Col md="auto" className="text-center">
              <label className="form-label" htmlFor="name">
                {dateSelected ? (
                  <div>
                    <b>Choose Slot: </b>
                    <span className="text-danger">
                      <b>*</b>
                    </span>
                  </div>
                ) : (
                  <b> </b>
                )}
              </label>
            </Col>
          </Row>
          <Row>
            <Col md="auto">
              {dateSelected &&
                allTimeSlots?.appointmentDates?.map(
                  (slots, index) =>
                    slots?.date == dateOptionNumeric &&
                    slots?.timeSlots?.map((timeslots, i) =>
                      timeslots?.maxAppointmentsperhour !== 0 ? (
                        !timeslots?.isBlocked ? (
                          selectedCheckboxes.includes(timeslots.timeSlot) ? (
                            <button
                              className="btn btn-success col-md-3 m-1 col-10"
                              onClick={() =>
                                handleCheckboxChange(
                                  timeslots.timeSlot + "@@" + i,
                                  false
                                )
                              }
                            >
                              {timeslots.timeSlot}
                            </button>
                          ) : (
                            <button
                              className="btn btn-outline-success col-md-3 m-1 col-10"
                              onClick={() =>
                                handleCheckboxChange(
                                  timeslots.timeSlot + "@@" + i,
                                  true
                                )
                              }
                            >
                              {timeslots.timeSlot} Slot is unblocked
                            </button>
                          )
                        ) : (
                          <Button
                            className="btn btn-danger col-md-3 m-1 col-10"
                            disabled
                          >
                            {timeslots.timeSlot} - Slot is blocked
                          </Button>
                        )
                      ) : (
                        <Button
                          className="btn btn-danger col-md-3 m-1 col-10"
                          disabled
                        >
                          {timeslots.timeSlot} - Break Time
                        </Button>
                      )
                    )
                )}
            </Col>
          </Row>
          {dateSelected && (
            <Row className="justify-content-center mt-3">
              <Col md="auto" className="text-center">
                <button className="btn btn-success" onClick={handleBlockSlot}>
                  Block
                </button>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
};

export default DoctorBlockSlotsScreen;
