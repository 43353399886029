import { createSlice } from "@reduxjs/toolkit";

const deleteLabTestReducer = createSlice({
	name: "deleteLabTest",
	initialState: {},
	reducers: {
		deleteLabTestRequest: (state) => {
			state.loading = true;
		},
		deleteLabTestSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.deleteLabTest = action.payload;
			state.error = false;
			state.success = true;
		},
		deleteLabTestFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		deleteLabTestReset: (state) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	deleteLabTestRequest,
	deleteLabTestSuccess,
	deleteLabTestFailure,
	deleteLabTestReset,
} = deleteLabTestReducer.actions;
export default deleteLabTestReducer.reducer;
