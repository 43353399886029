/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../../../Components/Errortemplate.js";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "../../../Components/Loader.js";
import Meta from "../../../Components/Meta.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Col, Row, Container } from "react-bootstrap";
import { getCustomRevenuesOftheHospitalFunction } from "../../../redux/statisticsRedux/statisticApiCalls.js";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import PieChart from "../../../Components/PieChart.js";

const formatDateToYYYYMMDD = (date) => {
	const d = new Date(date);
	const year = d.getFullYear();
	const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
	const day = String(d.getDate()).padStart(2, "0");
	return `${year}${month}${day}`;
};

const extractData = (data, startDate, endDate) => {
	const IpResult = data.IpResult;
	const LabResult = data.LabResult;
	const opResult = data.opResult;
	const purchasesResult = data.purchasesResult;
	const salesResult = data.salesResult;
};

const CustomRevenueStatisticsScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	//success and failure messages
	const currentYear = new Date().getFullYear();
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
	const day = String(today.getDate()).padStart(2, "0");

	const formattedDate = Number(`${year}${month}${day}`);
	const [startDate, setstartDate] = useState("2024-01-01");
	const [endDate, setendDate] = useState(`${year}-${month}-${day}`);
	useEffect(() => {
		const startYear = new Date(startDate).getFullYear();
		const endYear = new Date(endDate).getFullYear();
		// const startYear = Math.floor(startDate / 10000);
		// const endYear = Math.floor(endDate / 10000);
		dispatch(getCustomRevenuesOftheHospitalFunction(startYear, endYear));
		//dispatch(getCustomRevenuesOftheHospital(startYear, endYear));
	}, [dispatch, startDate, endDate]);

	const { getCustomRevenuesOftheHospital, loading } = useSelector(
		(state) => state.getCustomRevenuesOftheHospital
	);

	console.log(getCustomRevenuesOftheHospital);

	const [todaysOPPatientsPie, setTodaysOPPatientsPie] = useState([]);
	const [todaysOPPatientsBar, setTodaysOPPatientsBar] = useState([]);
	const [todaysDoctorsInventoryPie, setTodaysDoctorInventoryRevenue] = useState(
		[]
	);

	// useEffect(() => {
	// 	let pieData = [];
	// 	todaysVisitedPatientsstatistics?.data?.data?.map((speciality, index) => {
	// 		let totalAmtForPie = 0;
	// 		speciality?.specialityName !== "referral" &&
	// 			speciality?.specialityName !== "hospital" &&
	// 			speciality?.doctors?.map((doctor, i) => {
	// 				totalAmtForPie += doctor?.doctorOpRevenueTodayCash;
	// 				totalAmtForPie += doctor?.doctorOpRevenueTodayOnline;
	// 			});
	// 		speciality?.specialityName !== "referral" &&
	// 			speciality?.specialityName !== "hospital" &&
	// 			pieData?.push({
	// 				id: speciality?.specialityName,
	// 				label: speciality?.specialityName,
	// 				value: totalAmtForPie,
	// 			});
	// 	});
	// 	setTodaysOPPatientsPie(pieData);
	// }, [loading]);

	// const [allSpecialities, setAllSpecialities] = useState([]);

	// useEffect(() => {
	// 	let arr = [];
	// 	let ind = 0;
	// 	todaysVisitedPatientsstatistics?.data?.data?.map((speciality, index) => {
	// 		if (
	// 			speciality?.specialityName !== "referral" &&
	// 			speciality?.specialityName !== "hospital"
	// 		) {
	// 			arr.push({
	// 				specialityInd: index,
	// 				specialityId: speciality?.specialityId,
	// 				speciality: speciality?.specialityName,
	// 			});
	// 			setSpecialityOption(speciality?.specialityName);
	// 			ind = index;
	// 		}
	// 		let barData = [];
	// 		todaysVisitedPatientsstatistics?.data?.data[ind]?.doctors?.map(
	// 			(doctor, index) => {
	// 				barData?.push({
	// 					id: doctor?.doctorName?.split(" ")[0],
	// 					label: doctor?.doctorName?.split(" ")[0],
	// 					value:
	// 						doctor?.doctorOpRevenueTodayCash +
	// 						doctor?.doctorOpRevenueTodayOnline,
	// 				});
	// 			}
	// 		);
	// 		setTodaysOPPatientsBar(barData);
	// 		let pieData = [];
	// 		todaysVisitedPatientsstatistics?.data?.data[ind]?.doctors?.map(
	// 			(doctor, index) => {
	// 				pieData?.push({
	// 					id: doctor?.doctorName?.split(" ")[0],
	// 					label: doctor?.doctorName?.split(" ")[0],
	// 					value: doctor?.doctorInventoryRevenues,
	// 				});
	// 			}
	// 		);
	// 		setTodaysDoctorInventoryRevenue(pieData);

	// 		setAllSpecialities(arr);
	// 	});
	// }, [loading]);

	// console.log(allSpecialities);

	// const [specialityOption, setSpecialityOption] = useState("Choose Speciality");

	// const handleSpecialitySelect = (eventKey) => {
	// 	setSpecialityOption(eventKey?.split("@@")[0]);
	// 	let barData = [];
	// 	todaysVisitedPatientsstatistics?.data?.data[
	// 		eventKey?.split("@@")[1]
	// 	]?.doctors?.map((doctor, index) => {
	// 		barData?.push({
	// 			id: doctor?.doctorName?.split(" ")[0],
	// 			label: doctor?.doctorName?.split(" ")[0],
	// 			value:
	// 				doctor?.doctorOpRevenueTodayCash + doctor?.doctorOpRevenueTodayOnline,
	// 		});
	// 	});
	// 	setTodaysOPPatientsBar(barData);
	// 	let pieData = [];
	// 	todaysVisitedPatientsstatistics?.data?.data[
	// 		eventKey?.split("@@")[1]
	// 	]?.doctors?.map((doctor, index) => {
	// 		pieData?.push({
	// 			id: doctor?.doctorName?.split(" ")[0],
	// 			label: doctor?.doctorName?.split(" ")[0],
	// 			value: doctor?.doctorInventoryRevenues,
	// 		});
	// 	});
	// 	setTodaysDoctorInventoryRevenue(pieData);
	// };

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href="/admin/hospitalstatistics">
					Total OP
				</Breadcrumb.Item>
				<Breadcrumb.Item href="/admin/specialitystatistics">
					Speciality
				</Breadcrumb.Item>
				<Breadcrumb.Item href="/admin/doctorstatistics">Doctor</Breadcrumb.Item>
				<Breadcrumb.Item href="/admin/todaysoppatients">
					Today's OP Patients
				</Breadcrumb.Item>
				<label>Start Date:</label>
				<input
					type="date"
					value={startDate}
					onChange={(e) => {
						console.log(e.target.value);
						setstartDate(e.target.value);
					}}
				/>

				<label>End Date:</label>
				<input
					type="date"
					value={endDate}
					onChange={(e) => setendDate(e.target.value)}
				/>
			</Breadcrumb>
			<h1 className="header-center">Today's OP Revenue</h1>
			<h4 className="header-center mt-5">Speciality Revenue</h4>
			<Container className="header-center mt-5">
				{<PieChart data={todaysOPPatientsPie} />}
			</Container>
			<h4 className="header-center mt-5">Doctor Revenue</h4>
			{/* <Container className="header-center mt-5">
				<Row className="justify-content-center">
					<Col md="auto" className="text-center">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={specialityOption}
							onSelect={handleSpecialitySelect}
						>
							{allSpecialities?.map((speciality, index) => (
								<Dropdown.Item
									eventKey={
										speciality.speciality +
										"@@" +
										speciality.specialityInd +
										"@@" +
										speciality.specialityId
									}
								>
									{speciality?.speciality}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md="6" className="text-center">
						<h4 className="header-center mt-3 mb-3">Doctor's OP Revenue</h4>
						{<PieChart data={todaysOPPatientsBar} />}
					</Col>
					<Col md="6" className="text-center">
						<h4 className="header-center mt-3 mb-3">
							Doctor's Pharmacy Revenue
						</h4>
						{<PieChart data={todaysDoctorsInventoryPie} />}
					</Col>
				</Row>
			</Container> */}
		</>
	);
};

export default CustomRevenueStatisticsScreen;
