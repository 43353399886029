/** @format */

import { createSlice } from '@reduxjs/toolkit';

const paymentStatusReducer = createSlice({
	name: 'paymentStatus',
	initialState: {},
	reducers: {
		paymentStatusRequest: (state) => {
			state.loading = true;
		},
		paymentStatusSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.billId = action.payload;
			state.error = false;
			state.success = true;
		},
		paymentStatusFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		paymentStatusReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	paymentStatusRequest,
	paymentStatusSuccess,
	paymentStatusFailure,
	paymentStatusReset,
} = paymentStatusReducer.actions;
export default paymentStatusReducer.reducer;
