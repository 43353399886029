import Fuse from "fuse.js";
import { useState } from "react";
import { Form, ListGroup, Button, Badge } from "react-bootstrap";
import data from "./medication.json";
import axios from "axios";

// Fuse.js configuration
const fuseOptions = {
	keys: ["0"], // We are targeting the first element of each array for searching
	threshold: 0.3, // Adjust fuzziness level, lower value is stricter
	minMatchCharLength: 2, // Minimum characters to trigger search
};

// Initialize Fuse.js
const fuse = new Fuse(data, fuseOptions);

function AutocompleteArray() {
	const [query, setQuery] = useState(""); // User query
	const [results, setResults] = useState([]); // Search suggestions
	const [selectedItems, setSelectedItems] = useState([]); // Selected words
	const [newWords, setNewWords] = useState([]); // Collect new words
	const [activeIndex, setActiveIndex] = useState(-1); // Index for keyboard navigation

	// Handle input change and search using Fuse.js
	const handleInputChange = (e) => {
		const query = e.target.value;
		setQuery(query);

		if (query.length >= 2) {
			const fuseResults = fuse.search(query); // Start searching
			const suggestions = fuseResults.map((result) => result.item); // Extract matched arrays

			// If the word isn't in the original data, add it as a suggestion
			const isNewWord = !data.some((dataItem) => dataItem[0] === query);
			if (isNewWord && !selectedItems.includes(query)) {
				suggestions.push([query]); // Add user input as a suggestion
			}

			setResults(suggestions);
			setActiveIndex(-1); // Reset index when new query is typed
		} else {
			setResults([]);
		}
	};
	console.log(fuse);

	// Handle selection of a suggestion
	const handleSelect = (item) => {
		const selectedWord = item[0];
		const newSelectedItems = [...selectedItems, selectedWord]; // Add selected word to array
		setSelectedItems(newSelectedItems);

		// If the word doesn't exist in the original data, add it to newWords
		const isNewWord = !data.some((dataItem) => dataItem[0] === selectedWord);
		if (isNewWord) {
			setNewWords((prevNewWords) => [...prevNewWords, selectedWord]);
		}

		setQuery(""); // Clear the input after selection
		setResults([]); // Clear suggestions
	};

	// Handle removing a selected word
	const handleRemove = (word) => {
		setSelectedItems(selectedItems.filter((item) => item !== word));
		setNewWords(newWords.filter((item) => item !== word)); // Remove from new words if applicable
	};

	// Handle key down for keyboard navigation and selection
	const handleKeyDown = (e) => {
		if (results.length > 0) {
			if (e.key === "ArrowDown") {
				// Move down in the list
				setActiveIndex((prevIndex) =>
					prevIndex === results.length - 1 ? 0 : prevIndex + 1
				);
			} else if (e.key === "ArrowUp") {
				// Move up in the list
				setActiveIndex((prevIndex) =>
					prevIndex <= 0 ? results.length - 1 : prevIndex - 1
				);
			} else if (e.key === "Enter" && activeIndex >= 0) {
				// Select item on Enter
				handleSelect(results[activeIndex]);
			}
		}
	};

	// Function to send new words to the backend
	const sendNewWordsToBackend = async () => {
		if (newWords.length === 0) {
			console.log("No new words to save.");
			return;
		}

		try {
			const response = await axios.post("/api/save-words", { words: newWords });
			console.log("New words saved:", response.data);
			setNewWords([]); // Clear the new words array after saving
		} catch (error) {
			console.error("Error saving new words:", error);
		}
	};

	return (
		<div>
			<Form.Group>
				{/* Display selected words as tags inside the search bar */}
				<div
					style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
				>
					{selectedItems.map((item, index) => (
						<Badge
							key={index}
							pill
							variant="secondary"
							style={{
								marginRight: "5px",
								marginBottom: "5px",
								display: "flex",
								alignItems: "center",
							}}
						>
							{/* Cross mark to remove word */}
							<span
								onClick={() => handleRemove(item)}
								style={{
									cursor: "pointer",
									marginRight: "8px",
									color: "red",
									fontWeight: "bold",
								}}
							>
								&times;
							</span>
							{item}
						</Badge>
					))}
					<Form.Control
						type="text"
						value={query}
						onChange={handleInputChange}
						onKeyDown={handleKeyDown} // Add keyboard navigation handler
						placeholder="Search..."
						style={{ flexGrow: 1, minWidth: "150px" }}
					/>
				</div>
			</Form.Group>

			{results.length > 0 && (
				<ListGroup>
					{results.map((item, index) => (
						<ListGroup.Item
							key={index}
							onClick={() => handleSelect(item)} // Handle mouse click selection
							active={index === activeIndex} // Highlight the active item
							style={{ cursor: "pointer" }}
						>
							{item[0]} {/* Display the first element of the array */}
						</ListGroup.Item>
					))}
				</ListGroup>
			)}

			{/* Button to send new words to the backend */}
			{newWords.length > 0 && (
				<div>
					<Button variant="primary" onClick={sendNewWordsToBackend}>
						Save New Words
					</Button>
				</div>
			)}
		</div>
	);
}

export default AutocompleteArray;
