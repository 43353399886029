/** @format */

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Image } from 'react-bootstrap';
import Loader from './Loader';

// import * as am5 from '@amcharts/amcharts5';
// import * as am5xy from '@amcharts/amcharts5/xy';
// import * as am5percent from '@amcharts/amcharts5/percent';
// import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { ResponsivePie } from '@nivo/pie';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic'; // Using a categorical color scheme from D3

const PieChart = ({ data }) => {
	return (
		<>
			<div style={{ height: '400px' }}>
				<ResponsivePie
					data={data}
					margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
					innerRadius={0.5}
					padAngle={0.7}
					cornerRadius={3}
					activeOuterRadiusOffset={8}
					colors={{ scheme: 'set1' }}
					borderWidth={1}
					borderColor={{
						from: 'color',
						modifiers: [['darker', 0.2]],
					}}
					arcLinkLabelsSkipAngle={10}
					arcLinkLabelsTextColor='#333333'
					arcLinkLabelsThickness={2}
					arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
					arcLabelsSkipAngle={10}
					arcLabelsTextColor={{
						from: 'color',
						modifiers: [['darker', 2]],
					}}
					defs={[
						{
							id: 'dots',
							type: 'patternDots',
							background: 'inherit',
							color: 'rgba(255, 255, 255, 0.3)',
							size: 4,
							padding: 1,
							stagger: true,
						},
						{
							id: 'lines',
							type: 'patternLines',
							background: 'inherit',
							color: 'rgba(255, 255, 255, 0.3)',
							rotation: -45,
							lineWidth: 6,
							spacing: 10,
						},
					]}
					fill={[
						{
							match: {
								id: 'ruby',
							},
							id: 'dots',
						},
						{
							match: {
								id: 'c',
							},
							id: 'dots',
						},
						{
							match: {
								id: 'go',
							},
							id: 'dots',
						},
						{
							match: {
								id: 'python',
							},
							id: 'dots',
						},
						{
							match: {
								id: 'scala',
							},
							id: 'lines',
						},
						{
							match: {
								id: 'lisp',
							},
							id: 'lines',
						},
						{
							match: {
								id: 'elixir',
							},
							id: 'lines',
						},
						{
							match: {
								id: 'javascript',
							},
							id: 'lines',
						},
					]}
					legends={[
						{
							anchor: 'bottom',
							direction: 'row',
							justify: false,
							translateX: 0,
							translateY: 56,
							itemsSpacing: 0,
							itemWidth: 100,
							itemHeight: 18,
							itemTextColor: '#999',
							itemDirection: 'left-to-right',
							itemOpacity: 1,
							symbolSize: 18,
							symbolShape: 'circle',
							effects: [
								{
									on: 'hover',
									style: {
										itemTextColor: '#000',
									},
								},
							],
						},
					]}
				/>
			</div>
		</>
	);
};

export default PieChart;
