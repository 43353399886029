/** @format */

import React, { useState, useEffect } from "react";
import {
	Container,
	Table,
	Button,
	Modal,
	Form,
	Row,
	Col,
} from "react-bootstrap";
import Meta from "../../../Components/Meta";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useDispatch, useSelector } from "react-redux";
import {
	createSupplier,
	editSupplier,
	getSuppliers,
	clearSupplierDueBill,
	getPurchaseById,
} from "../../../redux/inventoryRedux/inventoryApiCalls";
import { getInventoryInfo } from "../../../redux/adminRedux/adminApiCalls";

const SupplierManagementScreens = () => {
	const dispatch = useDispatch();
	const [showViewModal, setShowViewModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [currentSupplier, setCurrentSupplier] = useState(null);
	const [newSupplier, setNewSupplier] = useState({
		name: "",
		email: "",
		contactNumber: "",
		presentAddress: "",
		vendorBankDetails: {
			accountNumber: "",
			IFSC: "",
			branch: "",
		},
	});

	const { inventoryInfo } = useSelector((state) => state.inventoryInfo);

	useEffect(() => {
		dispatch(getInventoryInfo());
	}, []);

	const [suppliers, setSuppliers] = useState([]);

	const onAddSupplier = (newSupplier) => {
		setSuppliers([...suppliers, newSupplier]);
	};

	const onUpdateSupplier = async (updatedSupplier) => {
		// Email validation regex pattern
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		// Mobile number validation (for example, for 10 digits)
		const mobilePattern = /^\d{10}$/;

		if (updatedSupplier?.name === "") {
			alert("Please enter name");
			return;
		}

		if (!emailPattern.test(updatedSupplier?.email)) {
			alert("Please enter a valid email address.");
			return;
		}

		if (!mobilePattern.test(updatedSupplier?.contactNumber)) {
			alert("Please enter a valid 10-digit mobile number.");
			return;
		}

		setShowEditModal(false);

		await dispatch(
			editSupplier(
				updatedSupplier?.name,
				updatedSupplier?.email,
				updatedSupplier?.contactNumber,
				updatedSupplier?.presentAddress,
				updatedSupplier?._id,
				updatedSupplier?.vendorBankDetails
			)
		);

		await dispatch(getSuppliers());
	};

	const handleViewClick = (supplier) => {
		setCurrentSupplier(supplier);
		setShowViewModal(true);
	};

	const handleEditClick = (supplier) => {
		setCurrentSupplier(supplier);
		setShowEditModal(true);
	};

	useEffect(() => {
		dispatch(getSuppliers());
	}, []);

	const getsuppliers = useSelector((state) => state.getSuppliers);

	console.log(getsuppliers);

	useEffect(() => {
		setSuppliers(() =>
			getsuppliers?.getsuppliers?.data?.data?.length > 0
				? getsuppliers?.getsuppliers?.data?.data
				: []
		);
	}, [getsuppliers?.loading]);

	console.log(suppliers);

	const createsupplier = useSelector((state) => state.createSupplier);
	const createsupplierLoading = createsupplier?.loading;

	const validateForm = (supplier) => {
		const { name, email, contactNumber, presentAddress } = supplier;
		if (!name || !contactNumber) {
			alert("Please fill in all fields.");
			return false;
		} else if (contactNumber?.length !== 10) {
			alert("Contact Number should be exactly 10 digits");
			return false;
		}
		return true;
	};

	const handleAddSupplier = async () => {
		// Email validation regex pattern
		// Mobile number validation (for example, for 10 digits)
		const mobilePattern = /^\d{10}$/;

		if (newSupplier?.name === "") {
			alert("Please enter name");
			return;
		}

		if (!mobilePattern.test(newSupplier?.contactNumber)) {
			alert("Please enter a valid 10-digit mobile number.");
			return;
		}

		if (validateForm(newSupplier)) {
			await dispatch(
				createSupplier(
					newSupplier?.name,
					newSupplier?.email,
					newSupplier?.contactNumber,
					newSupplier?.presentAddress,
					newSupplier?.vendorBankDetails
				)
			);
			await dispatch(getSuppliers());
			setShowEditModal(false);
			setNewSupplier({
				name: "",
				email: "",
				contactNumber: "",
				presentAddress: "",
				vendorBankDetails: {
					accountNumber: "",
					IFSC: "",
					branch: "",
				},
			});
		}
	};

	const formatDate = (date) => {
		// Convert date from 'yyyymmdd' to 'dd-mm-yyyy'
		const year = date?.toString()?.substring(0, 4) ?? "";
		const month = date?.toString()?.substring(4, 6) ?? "";
		const day = date?.toString()?.substring(6, 8) ?? "";
		return `${day}-${month}-${year}`;
	};

	const columns = [
		{
			headerName: "Actions",
			cellRendererFramework: (params) => (
				<div>
					<Button
						variant="primary"
						size="sm"
						title="View More"
						onClick={() => handleViewClick(params.data)}
					>
						<i className="fa fa-eye"></i>
					</Button>{" "}
					<Button
						variant="success"
						size="sm"
						title="Edit"
						onClick={() => handleEditClick(params.data)}
					>
						<i className="fa fa-edit"></i>
					</Button>
				</div>
			),
			width: 90,
		},
		{
			headerName: "S.No",
			valueGetter: (params) => params.node.rowIndex + 1,
			width: 90,
			sortable: false,
		},
		{
			headerName: "Name",
			field: "name",
			sortable: true,
			filter: "agTextColumnFilter",
			floatingFilter: true, // Enable floating filter
		},
		{
			headerName: "Email",
			field: "email",
			sortable: true,
			filter: "agTextColumnFilter",
			floatingFilter: true, // Enable floating filter
		},
		{
			headerName: "Contact Number",
			field: "contactNumber",
			sortable: true,
			filter: "agTextColumnFilter",
			floatingFilter: true, // Enable floating filter
		},
		{
			headerName: "Present Address",
			field: "presentAddress",
			sortable: true,
			filter: "agTextColumnFilter",
			floatingFilter: true, // Enable floating filter
		},
		{
			headerName: "Total Profit Generated",
			field: "Total_profit_generated",
			sortable: true,
			filter: "agNumberColumnFilter",
			floatingFilter: true, // Enable floating filter
		},
	];
	const [modalShow, setModalShow] = useState(false);
	const [purchaseId, setPurchaseId] = useState("");
	const [purchaseAmount, setPurchaseAmount] = useState(0);
	const [dueAmount, setDueAmount] = useState(0);
	const [dueDate, setDueDate] = useState("");
	const [paidAmount, setPaidAmount] = useState(0);
	const [newDueDate, setNewDueDate] = useState("");
	const [discount, setDiscount] = useState(0);
	const [dueAmountLeft, setDueAmountLeft] = useState(0);
	const handleEditDue = (purchaseId, purchaseAmount, dueAmount, dueDate) => {
		dispatch(getPurchaseById(purchaseId));
		setModalShow(true);
		setPurchaseId(purchaseId);
		setPurchaseAmount(purchaseAmount);
		setDueAmount(dueAmount);
		setDueDate(dueDate);
	};
	//const [purchasedetails, setpurchasedetails] = useState({});
	const getPurchaseByIdDetails = useSelector((state) => state.getPurchaseById);
	//setpurchasedetails(getPurchaseByIdDetails?.data?.data);
	console.log(getPurchaseByIdDetails, "line229");
	const changeDueAmount = async () => {
		const now = new Date();

		const currentDate = now.toISOString().split("T")[0]; // Format: YYYY-MM-DD
		const currentTime = now.toTimeString().split(" ")[0].slice(0, 5); // Format: HH:MM

		await dispatch(
			clearSupplierDueBill(
				purchaseId,
				paidAmount,
				discount,
				dueAmountLeft,
				reminderDate,
				currentDate,
				currentSupplier._id
				// vendor_payment_reminders
			)
		);

		await dispatch(getSuppliers());
		await dispatch(getInventoryInfo());
		setModalShow(false);
		setPurchaseId("");
		setPurchaseAmount(0);
		setDueAmount(0);
		setDueDate("");
		setPaidAmount(0);
		setNewDueDate("");
		setDiscount(0);
		setDueAmountLeft(0);
		setModalShow(false);
		setShowViewModal(false);
	};
	const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState(0);
	const [Total_discount_amount, setTotal_discount_amount] = useState(0);
	const [TotalPurchaseAmount, setTotalPurchaseAmount] = useState(0);
	const [reminderDate, setReminderDate] = useState("");
	const [reminderAmount, setReminderAmount] = useState(0);
	const handleDiscountChange = (value) => {
		setDiscount(Number(value) > 0 ? Number(value) : 0);
		setTotalAmountAfterDiscount(
			TotalPurchaseAmount - (Number(value) > 0 ? Number(value) : 0)
		);
	};

	const getRowClass = (params) => {
		return params.data.due_amount > 0 ? "bg-danger text-white" : "";
	};

	return (
		<div>
			<Meta />
			<h1 className="header-center mt-3">Supplier Management</h1>
			<Container>
				<Row className="justify-content-end mt-5">
					<Button
						variant="primary"
						className="mb-3"
						onClick={() => {
							setShowEditModal(true);
							setCurrentSupplier(null);
						}}
					>
						Add New Supplier
					</Button>
				</Row>
			</Container>
			<Row className="justify-content-center mt-3">
				<div
					className="ag-theme-alpine"
					style={{ width: "90%", height: "600px" }}
				>
					<AgGridReact
						rowData={suppliers}
						columnDefs={columns}
						pagination={true}
						paginationPageSize={10}
						floatingFilter={true} // Enable floating filters for main table
						domLayout="autoHeight"
					/>
				</div>
			</Row>

			{/* View Supplier Modal */}
			{currentSupplier && (
				<Modal
					show={showViewModal}
					onHide={() => setShowViewModal(false)}
					size="xl"
				>
					<Modal.Header closeButton>
						<Modal.Title>View Supplier Details</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>
							<strong>Name:</strong> {currentSupplier.name}
						</p>
						<p>
							<strong>Total Sales Made:</strong>{" "}
							{currentSupplier.Total_sales_made_from_the_stock_purchased}
						</p>
						<p>
							<strong>Total Purchase Made:</strong>{" "}
							{currentSupplier.Total_purchase_made}
						</p>
						<p>
							<strong>Total Profit Generated:</strong>{" "}
							{currentSupplier.Total_profit_generated}
						</p>
						<p>
							<strong>Total Discounts Given:</strong>{" "}
							{currentSupplier.Total_discounts_given}
						</p>
						<p>
							<strong>Total GST Paid:</strong> {currentSupplier.Total_gst_paid}
						</p>
						<p>
							<strong>Bank Details:</strong>{" "}
							{currentSupplier?.vendorBankDetails?.accountNumber} -{" "}
							{currentSupplier?.vendorBankDetails?.IFSC} -
							{currentSupplier?.vendorBankDetails?.branch}
						</p>

						<h5>Purchases</h5>
						<div
							className="ag-theme-alpine"
							style={{ width: "100%", height: "400px" }}
						>
							<AgGridReact
								rowData={currentSupplier.purchases}
								columnDefs={[
									{
										headerName: "S.No",
										valueGetter: (params) => params.node.rowIndex + 1,
										width: 90,
										sortable: false,
									},
									{
										headerName: "Purchase Date",
										field: "purchaseDate",
										valueGetter: (params) =>
											formatDate(params.data.purchaseDate),
										sortable: true,
										filter: "agTextColumnFilter",
										floatingFilter: true, // Enable floating filter
									},
									{
										headerName: "Total Purchase Amount",
										field: "TotalPurchaseAmount",
										sortable: true,
										filter: "agTextColumnFilter",
										floatingFilter: true, // Enable floating filter
									},
									{
										headerName: "Product Count",
										field: "productCount",
										sortable: true,
										filter: "agTextColumnFilter",
										floatingFilter: true, // Enable floating filter
									},
									{
										headerName: "Discount",
										field: "Discount",
										sortable: true,
										filter: "agTextColumnFilter",
										floatingFilter: true,
									},
									{
										headerName: "Mode of Payment",
										field: "modeOfpayment",
										sortable: true,
										filter: "agTextColumnFilter",
										floatingFilter: true, // Enable floating filter
									},
									{
										headerName: "GST Amount",
										field: "gstAmount",
										sortable: true,
										filter: "agTextColumnFilter",
										floatingFilter: true, // Enable floating filter
									},
									{
										headerName: "Due Amount",
										field: "due_amount",
										sortable: true,
										filter: "agTextColumnFilter",
										floatingFilter: true, // Enable floating filter
									},
									{
										headerName: "Due Date",
										field: "due_date",
										valueGetter: (params) => formatDate(params.data.due_date),
										sortable: true,
										filter: "agTextColumnFilter",
										floatingFilter: true, // Enable floating filter
									},
									{
										headerName: "Action",
										field: "editBill",
										cellRendererFramework: (params) => (
											<>
												<button
													onClick={() =>
														handleEditDue(
															params.data.purchaseId,
															params.data.TotalPurchaseAmount,
															params.data.due_amount,
															params.data.due_date
														)
													}
													disabled={params.data.due_amount <= 0}
													className="btn btn-light text-danger btn-sm"
													title="Edit Bill"
												>
													<b>
														Clear/Edit Bill <i className="fa fa-edit"></i>{" "}
													</b>
												</button>
											</>
										),
									},
								]}
								pagination={true}
								paginationPageSize={10}
								floatingFilter={true} // Enable floating filters in modal
								domLayout="autoHeight"
								getRowClass={getRowClass}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => setShowViewModal(false)}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			)}
			<Modal
				size="lg"
				show={modalShow}
				onHide={() => setModalShow(false)}
				aria-labelledby="example-modal-sizes-title-lg"
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-modal-sizes-title-lg">
						Edit due amount
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form className="form-group">
						<div className="row mt-3">
							<label className="col-3 col-md-3" htmlFor="name">
								<b>Purchase Amount </b>{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<input
								className="form-control col-8 col-md-8"
								id="name"
								type="name"
								disabled
								required="required"
								value={purchaseAmount}
							/>
						</div>
						<div className="row mt-3">
							<label className="col-3 col-md-3" htmlFor="mno">
								<b>Due Amount</b>{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<input
								className="form-control col-8 col-md-8"
								id="dueamount"
								type="dueamount"
								required="required"
								value={dueAmount}
								disabled
							/>
						</div>
						<div className="row mt-3">
							<label className="col-3 col-md-3" htmlFor="mno">
								<b>Due date</b>{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<input
								className="form-control col-8 col-md-8"
								id="dueDate"
								type="dueDate"
								required="required"
								value={formatDate(dueDate)}
								disabled
							/>
						</div>
						<div className="row mt-3">
							<label className="col-3 col-md-3" htmlFor="mno">
								<b>Paid amount </b>{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</label>
							<input
								className="form-control col-8 col-md-8"
								id="paidAmount"
								type="number"
								required="required"
								value={paidAmount}
								onChange={(e) => {
									const paidValue = Number(e?.target?.value);

									if (paidValue + discount < dueAmount) {
										setPaidAmount(paidValue);
										const now = new Date();
										now.setDate(now.getDate() + 7); // Add 7 days to the current date
										const reminderDate = now.toISOString().split("T")[0]; // Format: YYYY-MM-DD

										setReminderDate(reminderDate);
										setDueAmountLeft(
											(Number(dueAmount) - paidValue - discount)?.toFixed(2)
										);
									} else if (paidValue + discount > dueAmount) {
										alert("Paid amount cannot be more than due amount");
										return;
									} else {
										setPaidAmount(paidValue);
										setReminderDate("");
										setDueAmountLeft("");
									}
								}}
							/>
						</div>
						<div className="row mt-2">
							<label className="col-3 col-md-3" htmlFor="discount">
								<b>Discount (Rs) </b>{" "}
							</label>
							<input
								type="number"
								className="form-control col-8 col-md-8"
								value={discount}
								onChange={(e) => {
									const paidValue = Number(e?.target?.value);
									//setPaidAmount(paidValue);

									console.log(totalAmountAfterDiscount, paidValue);
									if (paidValue + discount < dueAmount) {
										const now = new Date();
										now.setDate(now.getDate() + 7); // Add 7 days to the current date
										const reminderDate = now.toISOString().split("T")[0]; // Format: YYYY-MM-DD

										setReminderDate(reminderDate);
										setDueAmountLeft(
											Number(dueAmount) - paidValue - paidAmount
										);
										handleDiscountChange(e?.target?.value);
									} else if (paidValue + discount > dueAmount) {
										alert("Discount cannot be more than new due amount");
										return;
									} else {
										setReminderDate("");
										setDueAmountLeft("");
									}
								}}
							/>
						</div>
						<div className="row mt-3">
							<label className="col-3 col-md-3" htmlFor="mno">
								<b>New due Amount</b>{" "}
							</label>
							<input
								className="form-control col-8 col-md-8"
								id="dueAmountLeft"
								type="dueAmountLeft"
								required="required"
								value={dueAmountLeft}
								disabled
								onChange={(e) => setDueAmountLeft(e.target.value)}
							/>
						</div>
						<div className="row mt-3">
							<label className="col-3 col-md-3" htmlFor="reminder">
								<b>Reminder </b>{" "}
							</label>

							<input
								type="date"
								className="form-control col-8 col-md-8"
								value={reminderDate}
								onChange={(e) => setReminderDate(e?.target?.value)}
							/>
						</div>
						{/* <div className="row mt-3">
							<label className="col-3 col-md-3" htmlFor="mno">
								<b>Discount</b>{" "}
							</label>
							<input
								className="form-control col-8 col-md-8"
								id="discount"
								type="discount"
								required="required"
								value={discount}
								onChange={(e) => setDiscount(e.target.value)}
							/>
						</div> */}
						<div className="row justify-content-center">
							<Button
								className="col-auto mt-5"
								variant="success"
								onClick={changeDueAmount}
							>
								Submit
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* Edit Supplier Modal */}
			<Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{currentSupplier ? "Edit Supplier" : "Add New Supplier"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="supplierName">
							<Form.Label>
								Name{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</Form.Label>
							<Form.Control
								type="text"
								value={
									currentSupplier ? currentSupplier.name : newSupplier.name
								}
								onChange={(e) =>
									currentSupplier
										? setCurrentSupplier({
												...currentSupplier,
												name: e.target.value,
										  })
										: setNewSupplier({ ...newSupplier, name: e.target.value })
								}
								disabled={currentSupplier ? true : false}
							/>
						</Form.Group>
						<Form.Group controlId="supplierEmail">
							<Form.Label>Email </Form.Label>
							<Form.Control
								type="email"
								value={
									currentSupplier ? currentSupplier.email : newSupplier.email
								}
								onChange={(e) =>
									currentSupplier
										? setCurrentSupplier({
												...currentSupplier,
												email: e.target.value,
										  })
										: setNewSupplier({ ...newSupplier, email: e.target.value })
								}
							/>
						</Form.Group>
						<Form.Group controlId="supplierContactNumber">
							<Form.Label>
								Contact Number{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</Form.Label>
							<Form.Control
								type="number"
								value={
									currentSupplier
										? currentSupplier.contactNumber
										: newSupplier.contactNumber
								}
								onChange={(e) =>
									currentSupplier
										? setCurrentSupplier({
												...currentSupplier,
												contactNumber: e.target.value.split(", "),
										  })
										: setNewSupplier({
												...newSupplier,
												contactNumber: e.target.value,
										  })
								}
							/>
						</Form.Group>
						<Form.Group controlId="supplierPresentAddress">
							<Form.Label>Address</Form.Label>
							<Form.Control
								type="text"
								value={
									currentSupplier
										? currentSupplier.presentAddress
										: newSupplier.presentAddress
								}
								onChange={(e) =>
									currentSupplier
										? setCurrentSupplier({
												...currentSupplier,
												presentAddress: e.target.value,
										  })
										: setNewSupplier({
												...newSupplier,
												presentAddress: e.target.value,
										  })
								}
							/>
						</Form.Group>
						<Form.Group controlId="supplierAccNo">
							<Form.Label>Vendor A/c no</Form.Label>
							<Form.Control
								type="text"
								value={
									currentSupplier
										? currentSupplier.vendorBankDetails?.accountNumber
										: newSupplier.vendorBankDetails?.accountNumber
								}
								onChange={(e) =>
									currentSupplier
										? setCurrentSupplier({
												...currentSupplier,
												vendorBankDetails: {
													...currentSupplier.vendorBankDetails,
													accountNumber: e.target.value,
												},
										  })
										: setNewSupplier({
												...newSupplier,
												vendorBankDetails: {
													...newSupplier.vendorBankDetails,
													accountNumber: e.target.value,
												},
										  })
								}
							/>
						</Form.Group>
						<Form.Group controlId="supplierIfsc">
							<Form.Label>IFS Code</Form.Label>
							<Form.Control
								type="text"
								value={
									currentSupplier
										? currentSupplier.vendorBankDetails?.IFSC
										: newSupplier.vendorBankDetails?.IFSC
								}
								onChange={(e) =>
									currentSupplier
										? setCurrentSupplier({
												...currentSupplier,
												vendorBankDetails: {
													...currentSupplier.vendorBankDetails,
													IFSC: e.target.value,
												},
										  })
										: setNewSupplier({
												...newSupplier,
												vendorBankDetails: {
													...newSupplier.vendorBankDetails,
													IFSC: e.target.value,
												},
										  })
								}
							/>
						</Form.Group>
						<Form.Group controlId="supplierIfsc">
							<Form.Label>Branch</Form.Label>
							<Form.Control
								type="text"
								value={
									currentSupplier
										? currentSupplier.vendorBankDetails?.branch
										: newSupplier.vendorBankDetails?.branch
								}
								onChange={(e) =>
									currentSupplier
										? setCurrentSupplier({
												...currentSupplier,
												vendorBankDetails: {
													...currentSupplier.vendorBankDetails,
													branch: e.target.value,
												},
										  })
										: setNewSupplier({
												...newSupplier,
												vendorBankDetails: {
													...newSupplier.vendorBankDetails,
													branch: e.target.value,
												},
										  })
								}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowEditModal(false)}>
						Close
					</Button>
					<Button
						variant="primary"
						onClick={() =>
							currentSupplier
								? onUpdateSupplier(currentSupplier)
								: handleAddSupplier()
						}
					>
						{currentSupplier ? "Update" : "Add"}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default SupplierManagementScreens;
