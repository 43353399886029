/** @format */

import { React, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../redux/doctorRedux/doctorApiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { getLabReportId } from "../redux/adminRedux/adminApiCalls";
import ViewPrescriptionComponent from "./ViewPrescriptionComponent";
import { useReactToPrint } from "react-to-print";
import ViewReportComponent from "./ViewReportComponent";
import { Col, Row, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { getUserDataById } from "../redux/prescriptionRedux/prescriptionApiCalls";

const ViewReportScreen = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useNavigate();
	const reportTemplateRef = useRef(null);
	const [data, setData] = useState(null);
	// console.log(location?.state?.id);

	useEffect(() => {
		if (data?.id) {
			dispatch(getLabReportId(data?.id));
		}
	}, [data]);
	const { reportDetails, loading } = useSelector((state) => state.viewReport);
	console.log(reportDetails);

	useEffect(() => {
		console.log(
			reportDetails?.data?.data?.userId,
			reportDetails?.data?.data?.username,
			reportDetails?.data?.data?.userIdx
		);

		if (
			reportDetails?.data?.data?.userId &&
			reportDetails?.data?.data?.username &&
			reportDetails?.data?.data?.userIdx
		) {
			dispatch(
				getUserDataById(
					reportDetails?.data?.data?.userId,
					reportDetails?.data?.data?.username,
					reportDetails?.data?.data?.userIdx
				)
			);
		}
	}, [reportDetails, loading]);

	const userdata1 = useSelector((state) => state.getUserDataById);
	const userdata = userdata1.userdata;
	const userdataerror = userdata1.error;
	const userdatasuccess = userdata1.success;
	const userdataloading = userdata1.loading;

	console.log(userdata);

	const handleDownloadPdf = useReactToPrint({
		content: () => reportTemplateRef.current,
		documentTitle: "Visitor Pass",
		onBeforeGetContent: () => {
			// Apply styles to the content to add margins
			const content = reportTemplateRef.current;
			content.style.margin = "30mm 0 0 0"; // Adjust the margin as needed
			return content;
		},
		onAfterPrint: () => console.log("Printed PDF successfully!"),
	});

	const backButton = () => {
		history(-1);
	};

	useEffect(() => {
		console.log("Component mounted, adding event listener");
		const handleMessage = (event) => {
			// Ensure that the event is coming from the expected source
			if (
				event.origin !== window.location.origin ||
				event.source !== window.opener
			) {
				return;
			}

			setData(event.data);
			console.log(event.data);
		};

		window.addEventListener("message", handleMessage);

		// return () => {
		// 	console.log('Component unmounted, removing event listener');
		// 	window.removeEventListener('message', handleMessage);
		// };
	}, []);

	console.log(data);
	return (
		<>
			<Container className="header-center mt-5">
				<Row className="justify-content-end">
					<Col md="auto" className="text-center">
						<button className="btn btn-success" onClick={handleDownloadPdf}>
							Generate PDF <i className="fa fa-download"></i>
						</button>
					</Col>
				</Row>
			</Container>
			<div ref={reportTemplateRef}>
				<div className="mt-3 mb-3">
					<div className="d-flex justify-content-between align-items-center">
						<img
							className="ml-3"
							src="/logo3.png"
							alt="logo"
							width="100"
							height="100"
						/>
						<div className="text-end mr-3">
							<h6 className="text-muted">
								Dr. K. Anjal Reddy Memorial Hospital
								<br />
								Phone: +91 8688044440
								{/* <br />
							Email :  */}
								<br />
								Nethaji road, <br />
								Kamareddy, <br />
								Telangana 503111
							</h6>
						</div>
					</div>
				</div>
				<Container className="header-center mt-5 card">
					<Row className="justify-content-center mt-4 mb-2">
						<Col md="auto" className="text-center">
							<span className="cardHeading">Patient: </span>
							{reportDetails?.data?.data?.username}fdbdb
						</Col>
						{data?.age !== "" ? (
							<Col md="auto" className="text-center">
								<span className="cardHeading">Age: </span>
								{data?.age}
							</Col>
						) : (
							<Col md="auto" className="text-center">
								<span className="cardHeading">Age: </span>
								{userdata?.data?.data?.age}
							</Col>
						)}
						{data?.gender !== "" ? (
							<Col md="auto" className="text-center">
								<span className="cardHeading">Gender: </span>
								{data?.gender}
							</Col>
						) : (
							<Col md="auto" className="text-center">
								<span className="cardHeading">Gender: </span>
								{userdata?.data?.data?.gender}
							</Col>
						)}
					</Row>
					<Row className="justify-content-center mt-2 mb-4">
						<Col md="auto" className="text-center">
							<span className="cardHeading">Referred By: </span>
							{reportDetails?.data?.data?.doctorName}
						</Col>
						<Col md="auto" className="text-center">
							<span className="cardHeading">Date: </span>
							{reportDetails?.data?.data?.reportDate?.toString()?.split("")[6] +
								reportDetails?.data?.data?.reportDate
									?.toString()
									?.split("")[7] +
								"-" +
								reportDetails?.data?.data?.reportDate
									?.toString()
									?.split("")[4] +
								reportDetails?.data?.data?.reportDate
									?.toString()
									?.split("")[5] +
								"-" +
								reportDetails?.data?.data?.reportDate
									?.toString()
									?.split("")[0] +
								reportDetails?.data?.data?.reportDate
									?.toString()
									?.split("")[1] +
								reportDetails?.data?.data?.reportDate
									?.toString()
									?.split("")[2] +
								reportDetails?.data?.data?.reportDate?.toString()?.split("")[3]}
						</Col>
						<Col md="auto" className="text-center">
							<span className="cardHeading">Department: </span>
							{reportDetails?.data?.data?.departmentName}
						</Col>
					</Row>
				</Container>
				<Container className="header-center mt-5">
					<Table striped bordered hover className="custom-table">
						<thead>
							<tr>
								<th scope="col">Name</th>
								<th scope="col"> Value</th>
								<th scope="col">Units</th>
								<th scope="col">Reference Values</th>
							</tr>
						</thead>
						<tbody>
							{reportDetails?.data?.data?.report?.map((item, index) => (
								<tr>
									<td>{item?.labelName}</td>
									<td>{item?.labelValue}</td>
									<td>{item?.units}</td>
									<td>{item?.referenceValues}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Container>
				<Container className="header-center mt-5">
					<Row>
						<Col md="auto" className="text-center">
							<span className="cardHeading">Interpretation: </span>
							{reportDetails?.data?.data?.interpretations}
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default ViewReportScreen;
