/** @format */

import { createSlice } from '@reduxjs/toolkit';

const editTestStatusForPatientsInQueue = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		editTestStatusForPatientsInQueueStart: (state) => {
			state.loading = true;
		},
		editTestStatusForPatientsInQueueSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		editTestStatusForPatientsInQueueFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editTestStatusForPatientsInQueueReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editTestStatusForPatientsInQueueStart,
	editTestStatusForPatientsInQueueSuccess,
	editTestStatusForPatientsInQueueFailure,
	editTestStatusForPatientsInQueueReset,
} = editTestStatusForPatientsInQueue.actions;
export default editTestStatusForPatientsInQueue.reducer;
