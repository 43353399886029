/** @format */

import { createSlice } from "@reduxjs/toolkit";

const createConsultantDoctor = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    createConsultantDoctorStart: (state) => {
      state.loading = true;
    },
    createConsultantDoctorSuccess: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
      state.error = false;
      state.success = true;
    },
    createConsultantDoctorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createConsultantDoctorReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  createConsultantDoctorStart,
  createConsultantDoctorSuccess,
  createConsultantDoctorFailure,
  createConsultantDoctorReset,
} = createConsultantDoctor.actions;
export default createConsultantDoctor.reducer;
