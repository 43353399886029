/** @format */

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

const Footer = () => {
	const { userInfo } = useSelector((state) => state.userLogin);
	return (
		<div className="footer mt-5">
			<div className="container">
				<iframe
					className="col-12"
					width="1453"
					height="260"
					frameborder="0"
					scrolling="no"
					marginheight="0"
					marginwidth="0"
					id="gmap_canvas"
					src="https://maps.google.com/maps?width=1353&amp;height=260&amp;hl=en&amp;q=Anjhal%20Reddy%20Hospital%20Kamareddy+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
				></iframe>

				{/* Contact Details */}
				<div className="contact-details mt-4">
					<p>
						<i className="fas fa-phone"></i> +91 8688044440
					</p>
					{/* <p>
						<i className='fas fa-envelope'></i> contact@example.com
					</p> */}
				</div>
				{/* Address */}
				<div className="address mt-3">
					<p>
						<i className="fas fa-map-marker-alt"></i> Nethaji road, <br />
						Kamareddy, Telangana <br />
						ZIP 503111
					</p>
				</div>
				<div className="row justify-content-center">
					<div className="col-auto mt-3">
						<div className="text-center">
							{/* Social Media Icons */}
							<a
								className="btn btn-social-icon btn-google"
								href="http://google.com/+"
							>
								<i className="icon fab fa-google-plus-square fa-lg"></i>
							</a>
							<a
								className="btn btn-social-icon btn-facebook"
								href="http://www.facebook.com/profile.php?id="
							>
								<i className="icon fab fa-facebook-square fa-lg"></i>
							</a>
							<a
								className="btn btn-social-icon btn-linkedin"
								href="http://www.linkedin.com/in/"
							>
								<i className="icon fab fa-linkedin fa-lg"></i>
							</a>
							<a
								className="btn btn-social-icon btn-twitter"
								href="http://twitter.com/"
							>
								<i className="icon fab fa-twitter fa-lg"></i>
							</a>
							<a
								className="btn btn-social-icon btn-youtube"
								href="http://youtube.com/"
							>
								<i className="icon fab fa-youtube fa-lg"></i>
							</a>
							<a
								className="btn btn-social-icon"
								href="mailto:contact@example.com"
							>
								<i className="icon fas fa-envelope fa-lg"></i>
							</a>
						</div>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-auto text-dark" style={{ fontWeight: "bold" }}>
						<p>
							© Copyright 2024{" "}
							<a href="https://www.hextgen.com" target="_blank">
								HextGen
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
