/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getSpecialityandDoctorStatistics = createSlice({
	name: 'getSpecialityandDoctorStatistics',
	initialState: {},
	reducers: {
		getSpecialityandDoctorStatisticsRequest: (state) => {
			state.loading = true;
		},
		getSpecialityandDoctorStatisticsSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.getSpecialityandDoctorstatistics = action.payload;
			state.error = false;
			state.success = true;
		},
		getSpecialityandDoctorStatisticsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getSpecialityandDoctorStatisticsReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getSpecialityandDoctorStatisticsRequest,
	getSpecialityandDoctorStatisticsSuccess,
	getSpecialityandDoctorStatisticsFailure,
	getSpecialityandDoctorStatisticsReset,
} = getSpecialityandDoctorStatistics.actions;
export default getSpecialityandDoctorStatistics.reducer;
