/** @format */

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";

import { persistor } from "../store";

import axios from "axios";
import { SERVER_URL } from "../../App";
import { hospitalname } from "../../App";

import {
	createInventoryCategorySuccess,
	createInventoryCategoryFailure,
	createInventoryCategoryStart,
} from "./CategoryRedux/createInventoryCategory";

import {
	getInventoryCategorySuccess,
	getInventoryCategoryFailure,
	getInventoryCategoryStart,
} from "./CategoryRedux/getInventoryCategory";

import {
	editInventoryCategorySuccess,
	editInventoryCategoryFailure,
	editInventoryCategoryStart,
} from "./CategoryRedux/editInventoryCategory";

import {
	createSupplierSuccess,
	createSupplierFailure,
	createSupplierStart,
} from "./SupplierRedux/createSupplier";

import {
	editSupplierSuccess,
	editSupplierFailure,
	editSupplierStart,
} from "./SupplierRedux/editSupplier";

import {
	getSuppliersSuccess,
	getSuppliersFailure,
	getSuppliersStart,
} from "./SupplierRedux/getSuppliers";

import {
	createInventoryItemSuccess,
	createInventoryItemFailure,
	createInventoryItemStart,
} from "./InventoryItem/createInventoryItem";

import {
	editInventoryItemSuccess,
	editInventoryItemFailure,
	editInventoryItemStart,
} from "./InventoryItem/editInventoryItem";

import {
	getInventoryItemsForBillingSuccess,
	getInventoryItemsForBillingFailure,
	getInventoryItemsForBillingStart,
} from "./InventoryItem/getInventoryItemsForBilling";

import {
	getInventoryItemsFavouriteItemsSuccess,
	getInventoryItemsFavouriteItemsFailure,
	getInventoryItemsFavouriteItemsStart,
} from "./InventoryItem/getInventoryItemsFavouriteItems";

import {
	newPurchaseFromSupplierSuccess,
	newPurchaseFromSupplierFailure,
	newPurchaseFromSupplierStart,
} from "./SupplierRedux/newPurchaseFromSupplier";

import {
	getPurchaseByIdSuccess,
	getPurchaseByIdFailure,
	getPurchaseByIdStart,
} from "./SupplierRedux/getPurchaseById";

import {
	patientInventoryPurchaseSuccess,
	patientInventoryPurchaseFailure,
	patientInventoryPurchaseStart,
} from "./InventoryItem/patientInventoryPurchase";

import {
	clearDueBillSuccess,
	clearDueBillFailure,
	clearDueBillStart,
} from "./InventoryItem/clearDueBill";

import {
	clearSupplierDueBillSuccess,
	clearSupplierDueBillFailure,
	clearSupplierDueBillStart,
} from "./SupplierRedux/clearSupplierDueBill";
import {
	getAllPurchaseStart,
	getAllPurchaseSuccess,
	getAllPurchaseFailure,
	getAllPurchaseReset,
} from "./SupplierRedux/getAllPurchases";
import {
	getBillFailure,
	getBillReset,
	getBillStart,
	getBillSuccess,
} from "./InventoryItem/getBill";

/////////////////////////Inventory revenues////////////////////////////////////////
import {
	getTodaysRevenuesStart,
	getTodaysRevenuesSuccess,
	getTodaysRevenuesFailure,
	getTodaysRevenuesReset,
} from "./InventoryRevenues/getTodaysRevenues";

import {
	getPastCategoryRevenuesStart,
	getPastCategoryRevenuesSuccess,
	getPastCategoryRevenuesFailure,
	getPastCategoryRevenuesReset,
} from "./InventoryRevenues/getPastCategoryRevenues";

import {
	getIndividualInventoryItemRevenueStart,
	getIndividualInventoryItemRevenueSuccess,
	getIndividualInventoryItemRevenueFailure,
	getIndividualInventoryItemRevenueReset,
} from "./InventoryRevenues/getIndividualInventoryItemRevenue";

import {
	getIndividualCategoryRevenueStart,
	getIndividualCategoryRevenueSuccess,
	getIndividualCategoryRevenueFailure,
	getIndividualCategoryRevenueReset,
} from "./InventoryRevenues/getIndividualCategoryRevenue";
import { verifySuccess } from "../adminRedux/Admin_MyProfile/VerifyCode";

export const createInventoryCategory =
	(categoryName, description) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(createInventoryCategoryStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/createInventoryCategory`,
				{ categoryName, description },
				config
			);

			// console.log(data.user);
			dispatch(createInventoryCategorySuccess(data));
			dispatch(
				allMessagesSuccess(`Succesfully created category : ${categoryName}`)
			);
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				createInventoryCategoryFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getInventoryCategory = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getInventoryCategoryStart());
		const username = localStorage.getItem("username");
		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getInventoryCategoryNames`,
			config
		);

		// console.log(data.user);
		dispatch(getInventoryCategorySuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getInventoryCategoryFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const editInventoryCategory =
	(categoryId, UpdatedName, UpdatedDescription, Updateditems) =>
	async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(editInventoryCategoryStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/editInventoryCategory`,
				{ categoryId, UpdatedName, UpdatedDescription, Updateditems },
				config
			);

			// console.log(data.user);
			dispatch(editInventoryCategorySuccess(data));
			dispatch(allMessagesSuccess("Edited Successfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editInventoryCategoryFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const createSupplier =
	(name, email, contactNumber, presentAddress, vendorBankDetails) =>
	async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(createSupplierStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/createSupplier`,
				{ name, email, contactNumber, presentAddress, vendorBankDetails },
				config
			);

			// console.log(data.user);
			dispatch(createSupplierSuccess(data));
			dispatch(allMessagesSuccess("New Supplier Created Succesfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				createSupplierFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const editSupplier =
	(name, email, contactNumber, presentAddress, id, vendorBankDetails) =>
	async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(editSupplierStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/editSupplier`,
				{ name, email, contactNumber, presentAddress, id, vendorBankDetails },
				config
			);

			// console.log(data.user);
			dispatch(editSupplierSuccess(data));
			dispatch(allMessagesSuccess("Edited supplier details succesfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editSupplierFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getSuppliers = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getSuppliersStart());
		const username = localStorage.getItem("username");
		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getSuppliers`,

			config
		);

		// console.log(data.user);
		dispatch(getSuppliersSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getSuppliersFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const createInventoryItem =
	(inventoryItemsArray) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(createInventoryItemStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/createInventoryItem`,
				{
					inventoryItemsArray,
				},
				config
			);

			// console.log(data.user);
			dispatch(createInventoryItemSuccess(data));
			dispatch(allMessagesSuccess("Inventory item created succesfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				createInventoryItemFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const editInventoryItem =
	(editedProduct, expiryStockArrays) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(editInventoryItemStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/editInventoryItem`,
				{
					editedProduct,
					expiryStockArrays,
				},
				config
			);

			// console.log(data.user);
			dispatch(editInventoryItemSuccess(data));
			dispatch(allMessagesSuccess("Inventory item edited succesfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editInventoryItemFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getInventoryItemsForBilling = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getInventoryItemsForBillingStart());
		const username = localStorage.getItem("username");
		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getInventoryItemsForBilling`,

			config
		);

		// console.log(data.user);
		dispatch(getInventoryItemsForBillingSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getInventoryItemsForBillingFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getInventoryItemsFavouriteItems = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getInventoryItemsFavouriteItemsStart());
		const username = localStorage.getItem("username");
		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getInventoryItemsFavouriteItems`,

			config
		);

		// console.log(data.user);
		dispatch(getInventoryItemsFavouriteItemsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getInventoryItemsFavouriteItemsFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const newPurchaseFromSupplier =
	(
		products,
		PurchaseDate,
		PurchaseTime,
		TotalPurchaseAmount,
		partial_amount_paid,
		Due_index,
		Total_discount_amount,
		InvoiceId,
		partial_payments,
		reminders,
		taxes,
		supplierName,
		supplierId,
		modeOfpayment,
		due_date, // if we have more than 1 due payment then the due_date should be old due_date
		due_amount,
		gstAmount,
		updatedInventoryItems,
		stock_expiring_in_one_week,
		stock_expiring_in_one_month,
		stock_expiring_in_three_months,
		stock_expiring_in_six_months,
		newInventoryItems
	) =>
	async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(newPurchaseFromSupplierStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/newPurchaseFromSupplier`,
				{
					products,
					PurchaseDate,
					PurchaseTime,
					TotalPurchaseAmount,
					partial_amount_paid,
					Due_index,
					Total_discount_amount,
					InvoiceId,
					partial_payments,
					reminders,
					taxes,
					supplierName,
					supplierId,
					modeOfpayment,
					due_date, // if we have more than 1 due payment then the due_date should be old due_date
					due_amount,
					gstAmount,
					updatedInventoryItems,
					stock_expiring_in_one_week,
					stock_expiring_in_one_month,
					stock_expiring_in_three_months,
					stock_expiring_in_six_months,
					newInventoryItems,
				},
				config
			);

			// console.log(data.user);
			dispatch(newPurchaseFromSupplierSuccess(data));
			dispatch(dispatch(verifySuccess(data)));
			dispatch(allMessagesSuccess("Purchase created succesfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				newPurchaseFromSupplierFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getPurchaseById = (purchaseId) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getPurchaseByIdStart());
		const username = localStorage.getItem("username");
		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getPurchaseById/${purchaseId}`,

			config
		);

		// console.log(data.user);
		dispatch(getPurchaseByIdSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getPurchaseByIdFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getAllPurchases = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getAllPurchaseStart());
		const username = localStorage.getItem("username");
		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getAllPurchases`,

			config
		);

		console.log(data);
		dispatch(getAllPurchaseSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getAllPurchaseFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const patientInventoryPurchase =
	(
		products,
		billDetails,
		userId,
		userIdx,
		purchaseBasedonCategory,
		paidAmount,
		stock_expiring_in_one_week,
		stock_expiring_in_one_month,
		stock_expiring_in_three_months,
		stock_expiring_in_six_months,
		doctorInventoryRevenuesArray
	) =>
	async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(patientInventoryPurchaseStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/patientInventoryPurchase`,
				{
					products,
					billDetails,
					userId,
					userIdx,
					purchaseBasedonCategory,
					paidAmount,
					stock_expiring_in_one_week,
					stock_expiring_in_one_month,
					stock_expiring_in_three_months,
					stock_expiring_in_six_months,
					doctorInventoryRevenuesArray,
				},
				config
			);

			// console.log(data.user);
			dispatch(patientInventoryPurchaseSuccess(data));
			dispatch(allMessagesSuccess("Bill created succesfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				patientInventoryPurchaseFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const clearSupplierDueBill =
	(
		purchaseId,
		paidAmount,
		discount,
		dueAmountLeft,
		dueDate,
		currentDate,
		supplierId
	) =>
	async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(clearSupplierDueBillStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			console.log(
				purchaseId,
				paidAmount,
				discount,
				dueAmountLeft,
				dueDate,
				currentDate,
				supplierId
			);
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/clearSupplierDueBill`,
				{
					purchaseId,
					paidAmount,
					discount,
					dueAmountLeft,
					dueDate,
					currentDate,
					supplierId,
				},
				config
			);

			// console.log(data.user);
			dispatch(clearSupplierDueBillSuccess(data));
			dispatch(allMessagesSuccess("Due bill cleared succesfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				clearSupplierDueBillFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const clearDueBill =
	(type, billId, billIdx, userId, userIdx, AmountPaid, prescription_Amount) =>
	async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(clearDueBillStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/clearDueBill`,
				{
					type,
					billId,
					billIdx,
					userId,
					userIdx,
					AmountPaid,
					prescription_Amount,
				},
				config
			);

			// console.log(data.user);
			dispatch(clearDueBillSuccess(data));
			dispatch(allMessagesSuccess("Due bill cleared succesfully"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				clearDueBillFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getBill = (billId) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getBillStart());
		const username = localStorage.getItem("username");
		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getBill/${billId}`,

			config
		);

		console.log(data);
		dispatch(getBillSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getBillFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

// Today's Revenue
export const getTodaysRevenues = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getTodaysRevenuesStart());
		const username = localStorage.getItem("username");
		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getTodaysRevenues`,
			config
		);

		// console.log(data.user);
		dispatch(getTodaysRevenuesSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getTodaysRevenuesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

// Past Category Revenue
export const getPastCategoryRevenues = () => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(getPastCategoryRevenuesStart());
		const username = localStorage.getItem("username");
		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getPastCategoryRevenues`,
			config
		);

		// console.log(data.user);
		dispatch(getPastCategoryRevenuesSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getPastCategoryRevenuesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

// get Individual Category Revenue
export const getIndividualCategoryRevenue =
	(categoryId) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(getIndividualCategoryRevenueStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/inventory/getIndividualCategoryRevenue/${categoryId}`,
				config
			);

			// console.log(data.user);
			dispatch(getIndividualCategoryRevenueSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				getIndividualCategoryRevenueFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

// get Individual Inventory Item Revenue
export const getIndividualInventoryItemRevenue =
	(inventoryItemId) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(getIndividualInventoryItemRevenueStart());
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					username: username,
					Authorization: `Bearer ${token}`,
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/inventory/getIndividualInventoryItemRevenue/${inventoryItemId}`,
				config
			);

			// console.log(data.user);
			dispatch(getIndividualInventoryItemRevenueSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				getIndividualInventoryItemRevenueFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};
