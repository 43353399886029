/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../../../../Components/Errortemplate.js";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "../../../../Components/Loader.js";
import Meta from "../../../../Components/Meta.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Col, Row, Container } from "react-bootstrap";
import {
	getStatisticsofParticularDoctor,
	getSpecialityandDoctorStatistics,
} from "../../../../redux/statisticsRedux/statisticApiCalls.js";
import { getSpecialities } from "../../../../redux/userApiCalls.js";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BarChart from "../../../../Components/Barchart.js";
import PieChart from "../../../../Components/PieChart.js";
import { getLabDepts } from "../../../../redux/adminRedux/adminApiCalls.js";

const DoctorStatisticsScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	const [specialityOption1, setSpecialityOption1] =
		useState("Choose Speciality");
	const [specialityInd1, setSpecialityInd1] = useState();
	const [specialityId1, setSpecialityId1] = useState("");
	const [specialityId, setSpecialityId] = useState("");

	const { getSpecialityAll } = useSelector((state) => state.getSpeciality);

	let allSpecialities = [];
	if (getSpecialityAll) {
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}

	const handleSpecialitySelect1 = (eventKey) => {
		let arr = [];
		arr = eventKey.split("@@");
		setSpecialityOption1(arr[0]);
		setSpecialityId1(arr[1]);
		console.log(specialityId + "line55");
		dispatch(getSpecialityandDoctorStatistics(arr[1]));
	};

	const handleDoctorSelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("@@");
		console.log(arr);
		setDoctorId(arr[1]);
		setDoctorOption(arr[0]);
		if (specialityId === "") {
			dispatch(getStatisticsofParticularDoctor(arr[2], arr[1]));
		} else {
			console.log(specialityId + "@" + arr[1], "line177");
			dispatch(getStatisticsofParticularDoctor(specialityId, arr[1]));
		}
	};

	const handleSpecialitySelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("@@");
		setSpecialityOption(arr[0]);
		setSpecialityId(arr[1]);
		console.log(arr[1] + "line73");
		setDoctorOption("Choose Doctor");

		setDoctorFromSpecialityArray(
			getSpecialityAll.data.data[arr[2]]?.doctorsArray
		);
	};

	useEffect(() => {
		console.log(JSON.stringify(allSpecialities));
		if (allSpecialities?.length > 0) {
			for (let i = 0; i < allSpecialities?.length; i++) {
				if (allSpecialities[i]?.specialization !== "referral") {
					handleSpecialitySelect1(
						allSpecialities[i]?.specialization +
							"@@" +
							allSpecialities[i]?._id +
							"@@" +
							i
					);
					handleSpecialitySelect(
						allSpecialities[i]?.specialization +
							"@@" +
							allSpecialities[i]?._id +
							"@@" +
							i
					);
					break;
				}
			}
		}
		if (allSpecialities?.length > 0) {
			for (let i = 0; i < allSpecialities?.length; i++) {
				if (allSpecialities[i]?.specialization !== "referral") {
					if (
						getSpecialityAll.data.data[i]?.doctorsArray[0]?.name !== undefined
					)
						handleDoctorSelect(
							getSpecialityAll.data.data[i]?.doctorsArray[0]?.name +
								"@@" +
								getSpecialityAll.data.data[i]?.doctorsArray[0]?.doctorsinfo +
								"@@" +
								allSpecialities[i]?._id
						);
				}
			}
		}
	}, []);

	const docAndSpecStatistics = useSelector(
		(state) => state.getSpecialityandDoctorStatistics
	);
	const getSpecialityandDoctorstatistics =
		docAndSpecStatistics?.getSpecialityandDoctorstatistics;
	const getSpecialityandDoctorStatisticsLoading = docAndSpecStatistics?.loading;

	console.log(getSpecialityandDoctorstatistics);

	const [last30DaysPieDoc, setLast30DaysPieDoc] = useState([]);
	const [last12MonsPieDoc, setLast12MonsPieDoc] = useState([]);
	const [activeDocPie, setActiveDocPie] = useState("30days");

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	useEffect(() => {
		let last30dayspie = [];
		let last12monspie = [];

		getSpecialityandDoctorstatistics?.data?.doctorsInSpeciality[0]?.RevenueStatisticsForDoctor?.map(
			(doctor, index) => {
				let Amt30days = 0;
				let Amt12mons = 0;
				doctor?.last_thirtydays_doctor?.map((day, index) => {
					Amt30days += day?.opPatientsRevenueCash_doctor;
					Amt30days += day?.opPatientsRevenueOnline_doctor;
				});
				last30dayspie?.push({
					id: doctor?.doctorName?.split(" ")[0],
					label: doctor?.doctorName?.split(" ")[0],
					value: Amt30days,
				});
				doctor?.last_twelve_months_doctor?.map((mon, index) => {
					Amt12mons += mon?.opPatientsRevenueCash_doctor;
					Amt12mons += mon?.opPatientsRevenueOnline_doctor;
				});
				last12monspie?.push({
					id: doctor?.doctorName?.split(" ")[0],
					label: doctor?.doctorName?.split(" ")[0],
					value: Amt12mons,
				});
			}
		);
		setLast30DaysPieDoc(last30dayspie);
		setLast12MonsPieDoc(last12monspie);
	}, [getSpecialityandDoctorStatisticsLoading]);

	const [specialityOption, setSpecialityOption] = useState("Choose Speciality");
	const [specialityInd, setSpecialityInd] = useState();

	const [doctorOption, setDoctorOption] = useState("Choose doctor");
	const [doctorId, setDoctorId] = useState("Choose doctor");

	const [DoctorFromSpecialityArray, setDoctorFromSpecialityArray] = useState(
		[]
	);

	const { getStatisticsofParticulardoctor, loading } = useSelector(
		(state) => state.getStatisticsofParticularDoctor
	);

	console.log(getStatisticsofParticulardoctor);

	const [last30DaysBarDoc, setLast30DaysBarDoc] = useState([]);
	const [last12MonsBarDoc, setLast12MonsBarDoc] = useState([]);
	const [activeDocBar, setActiveDocBar] = useState("30days");

	useEffect(() => {
		let last30daysbar = [];
		let last12monsbar = [];

		getStatisticsofParticulardoctor?.data?.data?.RevenueStatisticsForDoctor[0]?.last_thirtydays_doctor?.map(
			(day, index) => {
				let datearr = day?.date?.toString()?.split("");
				let date =
					datearr[6] +
					datearr[7] +
					"-" +
					datearr[4] +
					datearr[5] +
					"-" +
					datearr[0] +
					datearr[1] +
					datearr[2] +
					datearr[3];
				last30daysbar?.push({
					category: date,
					Amount:
						day?.opPatientsRevenueCash_doctor +
						day?.opPatientsRevenueOnline_doctor,
				});
			}
		);

		getStatisticsofParticulardoctor?.data?.data?.RevenueStatisticsForDoctor[0]?.last_twelve_months_doctor?.map(
			(mon, index) => {
				last12monsbar?.push({
					category: months[mon?.monthNumber - 1],
					Amount:
						mon?.opPatientsRevenueCash_doctor +
						mon?.opPatientsRevenueOnline_doctor,
				});
			}
		);

		setLast30DaysBarDoc(last30daysbar);
		setLast12MonsBarDoc(last12monsbar);
	}, [loading]);

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href='/admin/hospitalstatistics'>
					Total OP
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/specialitystatistics'>
					Speciality
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/doctorstatistics'>Doctor</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/todaysoppatients'>
					Today's OP Patients
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className='header-center'>Doctor Revenue</h1>
			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={specialityOption1}
							onSelect={handleSpecialitySelect1}>
							{allSpecialities.map(
								(specialities, index) =>
									specialities.specialization !== "referral" && (
										<Dropdown.Item
											eventKey={
												specialities.specialization +
												"@@" +
												specialities._id +
												"@@" +
												index
											}>
											{specialities.specialization}
										</Dropdown.Item>
									)
							)}
						</DropdownButton>
					</Col>

					<Col md='auto' className='text-center'>
						<button
							className='btn btn-info m-1'
							onClick={() => setActiveDocPie("30days")}>
							Last 30 days
						</button>
					</Col>
					<Col md='auto' className='text-center'>
						<button
							className='btn btn-info m-1'
							onClick={() => setActiveDocPie("12mons")}>
							Last 12 Months
						</button>
					</Col>
				</Row>
				{activeDocPie === "30days" && <PieChart data={last30DaysPieDoc} />}
				{activeDocPie === "12mons" && <PieChart data={last12MonsPieDoc} />}
			</Container>

			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={specialityOption}
							onSelect={handleSpecialitySelect}>
							{allSpecialities.map(
								(specialities, index) =>
									specialities.specialization !== "referral" && (
										<Dropdown.Item
											eventKey={
												specialities.specialization +
												"@@" +
												specialities._id +
												"@@" +
												index
											}>
											{specialities.specialization}
										</Dropdown.Item>
									)
							)}
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={doctorOption}
							onSelect={handleDoctorSelect}>
							{DoctorFromSpecialityArray?.map((doctors, index) => (
								<Dropdown.Item
									eventKey={doctors.name + "@@" + doctors.doctorsinfo}>
									{doctors.name}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center'>
						<button
							className='btn btn-info m-1'
							onClick={() => setActiveDocBar("30days")}>
							Last 30 days
						</button>
					</Col>
					<Col md='auto' className='text-center'>
						<button
							className='btn btn-info m-1'
							onClick={() => setActiveDocBar("12mons")}>
							Last 12 Months
						</button>
					</Col>
				</Row>
				{activeDocBar === "30days" && <BarChart data={last30DaysBarDoc} />}
				{activeDocBar === "12mons" && <BarChart data={last12MonsBarDoc} />}
			</Container>
		</>
	);
};

export default DoctorStatisticsScreens;
