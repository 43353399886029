import { createSlice } from "@reduxjs/toolkit";

const deleteLabDeptReducer = createSlice({
	name: "deleteLabDept",
	initialState: {},
	reducers: {
		deleteLabDeptRequest: (state) => {
			state.loading = true;
		},
		deleteLabDeptSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.deleteLabDept = action.payload;
			state.error = false;
			state.success = true;
		},
		deleteLabDeptFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		deleteLabDeptReset: (state) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	deleteLabDeptRequest,
	deleteLabDeptSuccess,
	deleteLabDeptFailure,
	deleteLabDeptReset,
} = deleteLabDeptReducer.actions;
export default deleteLabDeptReducer.reducer;
