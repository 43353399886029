/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import Meta from "./Meta";
import Modal from "react-bootstrap/Modal";
import Message from "./Message";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import { getUserDataById } from "../redux/prescriptionRedux/prescriptionApiCalls";
import { getUserDataInSearchPatient } from "../redux/adminRedux/adminApiCalls";
import { getUserBooking, deleteBooking } from "../redux/userApiCalls";

import { getUserDataInSearchPatientsReset } from "../redux/adminRedux/Patient_Management/getUserDataInSearchPatients";
import { UserBookingGetReset } from "../redux/getPatientBooking";
import { getUserDataByIdReset } from "../redux/prescriptionRedux/getUserDataById";
import {
	getScheduledLabTests,
	addScheduledLabTestsToLabDept,
} from "../redux/receptionRedux/receptionApiCalls";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../redux/allMessages";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation } from "react-router-dom";
import { getPatientHistory } from "../redux/historyRedux/historyApiCalls";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";
import {
	getHistoryByIdxStart,
	getHistoryByIdxSuccess,
	getHistoryByIdxFailure,
	getHistoryByIdxReset,
} from "../redux/historyRedux/History_Management/getHistoryByIdx";
import { startPrescriptionToStartHistoryFailure } from "../redux/UINavigationRedux/StartPrescriptionToStartHistory";
import { getBill } from "../redux/inventoryRedux/inventoryApiCalls";

import {
	billStart,
	billSuccess,
	billFailure,
	billReset,
} from "../redux/adminRedux/Bill_Management/bill";

const SearchPatient = (usertype) => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();

	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const { adminInfo } = useSelector((state) => state.adminLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	//success and error //////////////////////////////
	const userbbokingsdata = useSelector((state) => state.getPatientBooking);
	const getBookingsall = userbbokingsdata.getBookingsall;
	const bookingerror = userbbokingsdata.error;
	const bookingsuccess = userbbokingsdata.success;
	const bookingloading = userbbokingsdata.loading;

	const data1 = useSelector((state) => state.getUserDataInSearchPatient);
	const { userData, loading, error, success } = data1;

	const userdata1 = useSelector((state) => state.getUserDataById);
	const userdata = userdata1.userdata;
	const userdataerror = userdata1.error;
	const userdatasuccess = userdata1.success;
	const userdataloading = userdata1.loading;

	console.log(userdata);

	useEffect(() => {
		if (userdata?.data?.data?.historyId) {
			dispatch(getHistoryByIdxReset());
			dispatch(getPatientHistory(userdata?.data?.data?.historyId));
		}
	}, [userdataloading]);

	const [bloodGroup, setBloodGroup] = useState("");

	useEffect(() => {
		userdata?.data?.data?.vitals?.map((vital, index) => {
			if (vital?.vitalName === "BloodGroup") {
				setBloodGroup(vital?.vitalValue);
			}
		});
	}, [userdataloading]);
	/////////////////////////////////////////////////////

	const [mno, setMno] = useState("");
	const [userOptionClicked, setUserOptionClicked] = useState(false);

	const [searchClicked, setSearchClicked] = useState(false);

	console.log(getBookingsall);

	console.log(userData);
	let users_array = [];

	useEffect(() => {
		// Clear the search term whenever the location changes
		setMno("");
	}, [location]);

	if (mno !== "") {
		users_array.push({
			name: userData?.data?.data?.name,
			id: userData?.data?.data?._id,
			bookingidarray: userData?.data?.data?.bookingIdArray,
			labReports: userData?.data?.data?.labReports,
		});
		for (
			let i = 0;
			i < userData?.data?.data?.registered_patients_array?.length;
			i++
		) {
			users_array.push({
				name: userData?.data?.data?.registered_patients_array[i]?.name,
				id: userData?.data?.data?._id,
				bookingidarray:
					userData?.data?.data?.registered_patients_array[i]?.bookingIdArray,
				labReports:
					userData?.data?.data?.registered_patients_array[i]?.labReports,
			});
		}
	}

	const [showPopup, setShowPopup] = React.useState(false);
	const handleSearchPatient = (e) => {
		e.preventDefault();
		setUserOptionClicked(false);
		setSearchClicked(true);
		dispatch(getUserDataInSearchPatient(mno));
		setUname("");
		setUid("");
		setUserIdx("");
		setCategoryOption("Choose Category");
		handleDateSelect();
		setFilterOption("Date");
		setSelectedItems([]);
		setSelectedItemsTestsIdx([]);
		setSelectedItemsTests([]);
		setSelectedItemsPrice([]);
		setSelectedItemsAfterPrice([]);
		setSelectedItemsPriceDiscounts([]);
		setSelectedItemsDescs([]);
		setSelectedItemsSpecialityIds([]);
		setSelectedItemsSpecialityName([]);
		setSelectedItemsDoctorIds([]);
		setSelectedItemsDoctorName([]);
		setSelectedItemsPresIds([]);
		setSelectedItemsDeptIds([]);
		setselectedItemsDeptName([]);

		setTotalAmount(0);
		setAmountAfterDiscount(0);
		setModeOfPayment("Cash");
	};
	const [uname, setUname] = useState("");
	const [uid, setUid] = useState("");
	const [userIdx, setUserIdx] = useState("");
	const [bookingIdArray, setBookingIdArray] = useState([]);
	const handelUserSelect = (id, bookingidarray, name, index) => {
		console.log(bookingidarray);
		setBookingIdArray(bookingidarray);
		setUname(name);
		setUid(id);
		setUserOptionClicked(true);
		setCategoryOption("Choose Category");
		setFilterOption("Date");
		setUserIdx(index);
		dispatch(getUserBooking(bookingidarray));
		dispatch(getUserDataById(id, name, index));
		handleDateSelect();
		dispatch(getScheduledLabTests(userData?.data?.data?._id, index));
		setSelectedItems([]);
		setSelectedItemsTestsIdx([]);
		setSelectedItemsTests([]);
		setSelectedItemsPrice([]);
		setSelectedItemsAfterPrice([]);
		setSelectedItemsPriceDiscounts([]);
		setSelectedItemsDescs([]);
		setSelectedItemsSpecialityIds([]);
		setSelectedItemsSpecialityName([]);
		setSelectedItemsDoctorIds([]);
		setSelectedItemsDoctorName([]);
		setSelectedItemsPresIds([]);
		setSelectedItemsDeptIds([]);
		setselectedItemsDeptName([]);

		setTotalAmount(0);
		setAmountAfterDiscount(0);
		setModeOfPayment("Cash");
	};
	useEffect(() => {
		//dispatch(getSpecialities());

		if (mno !== "") {
			dispatch(getUserDataInSearchPatient(mno));
		}
	}, [history, dispatch, bookingerror, bookingsuccess]);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();

		console.log("error");
		dispatch(getUserDataInSearchPatientsReset());
	};
	const [showPopup1, setShowPopup1] = React.useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log("error");
		dispatch(UserBookingGetReset());
	};
	console.log(searchClicked);

	// View Prescription
	const [filterOption, setFilterOption] = useState("Date");
	const handleFilterSelect = (eventKey) => {
		setFilterOption(eventKey);
	};

	const [categoryOption, setCategoryOption] = useState("Choose Category");
	const [categoryDropdown, setcategoryDropdown] = useState("d-none");
	const [ind, setInd] = useState(-1);

	const handleCategoryNameSelect = (eventKey) => {
		setCategoryOption(eventKey);
		for (let i = 0; i < userdata?.data?.data?.prescCategory.length; i++) {
			if (eventKey === userdata?.data?.data?.prescCategory[i]?.category) {
				setInd(i);
			}
		}
	};

	const handleCategorySelect = () => {
		setcategoryDropdown("d-block");
	};

	const handleDateSelect = () => {
		setcategoryDropdown("d-none");
	};
	const handleDelete = (id, billId) => {
		if (billId !== "") {
			alert("User already completed the payment");
		} else {
			dispatch(deleteBooking(id));
			dispatch(getUserDataInSearchPatient(mno));
			setUserOptionClicked(false);
			setSearchClicked(false);
			dispatch(getUserBooking(bookingIdArray));
		}
		// window.location.reload();
	};
	// Past Prescriptions

	console.log(userdata);

	const viewBtn = (id, age, gender, bgrp, name, activeTests) => {
		const prescriptionData = {
			id: id,
			age: age,
			gender: gender,
			bgrp: bgrp,
			name: name,
			activeTests: activeTests,
			index: userIdx,
			userId: uid,
		};
		const prescriptionDetails = {
			prescriptionData: prescriptionData,
		};
		const queryParams = new URLSearchParams();

		// Convert historyItems to a JSON string and encode it for the URL
		queryParams.append(
			"prescriptionItems",
			encodeURIComponent(JSON.stringify(prescriptionDetails))
		);

		// Open the new tab with the query parameters
		window.open(`/viewprescription?${queryParams.toString()}`, "_blank");
	};

	// const viewBtn = (id, age, gender, bgrp, name, activeTests) => {
	// 	 ;
	// 	if (usertype?.usertype === 'admin') {
	// 		history('/admin/searchPatients/viewpres', {
	// 			state: {
	// 				id: id,
	// 				age: age,
	// 				gender: gender,
	// 				bgrp: bgrp,
	// 				name: name,
	// 				activeTests: activeTests,
	// 				index: userIdx,
	// 				userId: uid,
	// 			},
	// 		});
	// 		localStorage.setItem('Userage', age);
	// 	} else if (usertype?.usertype === 'doctor') {
	// 		history('/doctor/viewpres', {
	// 			state: {
	// 				id: id,
	// 				age: age,
	// 				gender: gender,
	// 				bgrp: bgrp,
	// 				name: name,
	// 				activeTests: activeTests,
	// 				page: 'search',
	// 				index: userIdx,
	// 				userId: uid,
	// 			},
	// 		});
	// 	} else if (usertype?.usertype === 'reception') {
	// 		history('/reception/viewpres', {
	// 			state: {
	// 				id: id,
	// 				age: age,
	// 				gender: gender,
	// 				bgrp: bgrp,
	// 				name: name,
	// 				activeTests: activeTests,
	// 				index: userIdx,
	// 				userId: uid,
	// 			},
	// 		});
	// 	}
	// };

	const getData = (eventKey) => {
		console.log("111111");
		if (eventKey === "prescribedlabtests") {
			console.log("2222222222222");
			dispatch(getScheduledLabTests(userData?.data?.data?._id, userIdx));
		}
		if (eventKey === "history") {
			dispatch(getPatientHistory(userdata?.data?.data?.historyId));
		}
	};

	let { getHistoryByIdx } = useSelector((state) => state.getHistoryByIdx);

	const getPatientHistoryError = useSelector((state) => state.getHistoryByIdx);

	const Pasthistory = getHistoryByIdx?.data?.data?.history;
	const reversedHistory = Array.isArray(Pasthistory)
		? [...Pasthistory].reverse()
		: [];

	if (Array.isArray(Pasthistory)) {
		getHistoryByIdx = {
			...getHistoryByIdx,
			data: {
				...getHistoryByIdx.data,
				data: {
					...getHistoryByIdx.data.data,
					history: reversedHistory,
				},
			},
		};
	}

	console.log(getHistoryByIdx);

	const { getScheduledLabtests } = useSelector(
		(state) => state.getScheduledLabTest
	);

	console.log(getScheduledLabtests);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	const handleUpdatePaymentStatus = () => {
		setShow(true);
		setLabAmountPaidOnline("");
		setLabAmountPaidCash("");
	};

	const [selectedItems, setSelectedItems] = useState([]);
	const [selectedItemsTestsIdx, setSelectedItemsTestsIdx] = useState([]);
	const [selectedItemsTests, setSelectedItemsTests] = useState([]);
	const [selectedItemsPrice, setSelectedItemsPrice] = useState([]);
	const [selectedItemsAfterPrice, setSelectedItemsAfterPrice] = useState([]);
	const [selectedItemsPriceDiscounts, setSelectedItemsPriceDiscounts] =
		useState([]);
	const [selectedItemsDescs, setSelectedItemsDescs] = useState([]);
	const [selectedItemsSpecialityIds, setSelectedItemsSpecialityIds] = useState(
		[]
	);
	const [selectedItemsSpecialityName, setSelectedItemsSpecialityName] =
		useState([]);
	const [selectedItemsDoctorIds, setSelectedItemsDoctorIds] = useState([]);
	const [selectedItemsDoctorName, setSelectedItemsDoctorName] = useState([]);
	const [selectedItemsPresIds, setSelectedItemsPresIds] = useState([]);
	const [selectedItemsDeptIds, setSelectedItemsDeptIds] = useState([]);
	const [selectedItemsDeptName, setselectedItemsDeptName] = useState([]);

	const [totalAmount, setTotalAmount] = useState(0);
	const [amountAfterDiscount, setAmountAfterDiscount] = useState(0);
	const [modeOfPayment, setModeOfPayment] = useState("Cash");

	const toggleItemSelection = (
		index1,
		name,
		price,
		description,
		deptId,
		testIdx,
		specialityName,
		specialityId,
		doctorName,
		doctorId,
		presId,
		deptName
	) => {
		console.log(deptName + "line269");
		let index = deptId + testIdx;
		let amount = totalAmount;
		selectedItems?.includes(index) ? (amount -= price) : (amount += price);
		setTotalAmount(amount);
		setAmountAfterDiscount(amount);

		const newSelectedItems = selectedItems.includes(index)
			? selectedItems.filter((i) => i !== index)
			: [...selectedItems, index];
		setSelectedItems(newSelectedItems);

		const newSelectedItemsTestsIdx = selectedItems.includes(index)
			? selectedItemsTestsIdx.filter((i) => i !== testIdx)
			: [...selectedItemsTestsIdx, testIdx];
		setSelectedItemsTestsIdx(newSelectedItemsTestsIdx);

		const newSelectedItemsTests = selectedItems.includes(index)
			? selectedItemsTests.filter((i) => i !== name)
			: [...selectedItemsTests, name];
		setSelectedItemsTests(newSelectedItemsTests);

		const newSelectedItemsPrice = selectedItems.includes(index)
			? selectedItemsPrice.filter((i) => i !== price)
			: [...selectedItemsPrice, price];
		setSelectedItemsPrice(newSelectedItemsPrice);

		const newSelectedItemsDesc = selectedItems.includes(index)
			? selectedItemsDescs.filter((i) => i !== description)
			: [...selectedItemsDescs, description];
		setSelectedItemsDescs(newSelectedItemsDesc);

		const newSelectedItemsDeptIds = selectedItems.includes(index)
			? selectedItemsDeptIds.filter((i) => i !== deptId)
			: [...selectedItemsDeptIds, deptId];
		setSelectedItemsDeptIds(newSelectedItemsDeptIds);

		const newselectedItemsDeptName = selectedItems.includes(index)
			? selectedItemsDeptName.filter((i) => i !== deptName)
			: [...selectedItemsDeptName, deptName];
		setselectedItemsDeptName(newselectedItemsDeptName);

		const newSelectedItemsSpecialityName = selectedItems.includes(index)
			? selectedItemsSpecialityName.filter((i) => i !== specialityName)
			: [...selectedItemsSpecialityName, specialityName];
		setSelectedItemsSpecialityName(newSelectedItemsSpecialityName);

		const newSelectedItemsSpecialityIds = selectedItems.includes(index)
			? selectedItemsSpecialityIds.filter((i) => i !== specialityId)
			: [...selectedItemsSpecialityIds, specialityId];
		setSelectedItemsSpecialityIds(newSelectedItemsSpecialityIds);

		const newSelectedItemsDoctorName = selectedItems.includes(index)
			? selectedItemsDoctorName.filter((i) => i !== doctorName)
			: [...selectedItemsDoctorName, doctorName];
		setSelectedItemsDoctorName(newSelectedItemsDoctorName);

		const newSelectedItemsDoctorIds = selectedItems.includes(index)
			? selectedItemsDoctorIds.filter((i) => i !== doctorId)
			: [...selectedItemsDoctorIds, doctorId];
		setSelectedItemsDoctorIds(newSelectedItemsDoctorIds);

		const newSelectedItemsPresIds = selectedItems.includes(index)
			? selectedItemsPresIds.filter((i) => i !== presId)
			: [...selectedItemsPresIds, presId];
		setSelectedItemsPresIds(newSelectedItemsPresIds);
	};

	const [discount, setDiscount] = useState(0);

	const [discountReason, setDiscountReason] = useState("");

	const handleDiscountChange = (event) => {
		if (event.target.value !== 0) {
			if (event?.target?.value > 100) {
				alert("Discount cannot be greater than 100%");
			} else if (event?.target?.value < 0) {
				alert("Discount cannot be less than 0%");
			} else {
				setDiscount(event.target.value);
				const discountPercentage = parseFloat(event.target.value);
				console.log(discountPercentage);

				let testsamounts = selectedItemsPrice;
				let discamount = [];

				for (let i = 0; i < testsamounts?.length; i++) {
					let disc = parseFloat(
						testsamounts[i] * discountPercentage * 0.01
					).toFixed(2);
					discamount.push(disc);
					// testsamounts.push(testsamounts[i] - disc);
				}

				let finalamounts = [];

				for (let i = 0; i < testsamounts?.length; i++) {
					finalamounts.push(testsamounts[i] - discamount[i]);
				}

				setSelectedItemsAfterPrice(finalamounts);
				setSelectedItemsPriceDiscounts(discamount);

				let totalamo = 0;

				for (let i = 0; i < finalamounts?.length; i++) {
					totalamo += finalamounts[i];
				}

				setAmountAfterDiscount(totalamo);
			}
		} else {
			setAmountAfterDiscount(totalAmount);
		}
		setLabAmountPaidOnline("");
		setLabAmountPaidCash("");
	};

	const [labAmountPaidCash, setLabAmountPaidCash] = useState("");
	const [labAmountPaidOnline, setLabAmountPaidOnline] = useState("");

	const handleAmounts = (amount, type) => {
		if (amount > amountAfterDiscount) {
			alert(`Amount cannot exceed ${amountAfterDiscount}`);
		} else {
			if (type === "cash") {
				setLabAmountPaidOnline(0);
				setLabAmountPaidCash(Number(amount));
				setLabAmountPaidOnline(Number(amountAfterDiscount) - Number(amount));
			} else {
				setLabAmountPaidCash(0);
				setLabAmountPaidOnline(Number(amount));
				setLabAmountPaidCash(Number(amountAfterDiscount) - Number(amount));
			}
		}
	};

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	const handleSubmit = () => {
		if (modeOfPayment === "") {
			alert("Select Mode Of Payment");
		}
		if (
			Number(labAmountPaidOnline) + Number(labAmountPaidCash) >
			Number(amountAfterDiscount)
		) {
			alert(`Amount cannot exceed ${amountAfterDiscount}`);
		} else if (labAmountPaidOnline + labAmountPaidCash < amountAfterDiscount) {
			alert(`Amount cannot be less than ${amountAfterDiscount}`);
		} else {
			let tests = [];
			for (let i = 0; i < selectedItems?.length; i++) {
				tests.push({
					description: selectedItemsDescs[i],
					testName: selectedItemsTests[i],
					testIdx: selectedItemsTestsIdx[i],
					paidAmount:
						selectedItemsAfterPrice[i] === undefined
							? selectedItemsPrice[i]
							: selectedItemsAfterPrice[i],
					discount: {
						discountAmount:
							selectedItemsPriceDiscounts[i] === undefined
								? 0
								: Number(selectedItemsPriceDiscounts[i]),
						discountReason: discountReason,
					},
					specialityId: selectedItemsSpecialityIds[i],
					doctorId: selectedItemsDoctorIds[i],
					specialityName: selectedItemsSpecialityName[i],
					doctorName: selectedItemsDoctorName[i],
					prescriptionId: selectedItemsPresIds[i],
					deptId: selectedItemsDeptIds[i],
					deptName: selectedItemsDeptName[i],
				});
			}

			console.log(
				getScheduledLabtests?.data?.data[0]?.userId,
				getScheduledLabtests?.data?.data[0]?.mobileNumber,
				getScheduledLabtests?.data?.data[0]?.userName,
				getScheduledLabtests?.data?.data[0]?.userIdx,
				tests,
				selectedItemsDeptIds,
				modeOfPayment + "line396"
			);

			let totalAmount =
				selectedItemsPrice?.reduce((total, price) => {
					const amount = parseFloat(price);
					return total + (isNaN(amount) ? 0 : amount);
				}, 0) || 0;

			const AmountPaid =
				tests?.reduce((total, test) => {
					const paidAmount = parseFloat(test?.paidAmount);
					return total + (isNaN(paidAmount) ? 0 : paidAmount);
				}, 0) || 0;

			const discountAmount =
				tests?.reduce((total, test) => {
					const discount = parseFloat(test?.discount?.discountAmount);
					const referralDiscount = parseFloat(
						test?.referraldiscount?.discountAmount
					);
					return (
						total +
						(isNaN(discount) ? 0 : discount) +
						(isNaN(referralDiscount) ? 0 : referralDiscount)
					);
				}, 0) || 0;

			let labDetails = [];
			tests?.map((test, index) => {
				labDetails?.push({
					labDeptId: test?.deptId,
					labDeptName: test?.deptName,
					test: {
						name: test?.testName,
						amount: selectedItemsPrice[index],
					},
				});
			});

			let bill = {
				AmountToBePaid: totalAmount,
				AmountPaid: AmountPaid,
				AmountDue: 0,
				invoiceNumber: "INV-" + getCurrentDateIST() + "-" + mno + "-" + userIdx,
				prescription_charges: {},
				inventory_charges: {},
				lab_charges: {
					AmountToBePaid: totalAmount,
					AmountDue: 0,
					discount: {
						discountAmount: discountAmount,
						discountReason: discountReason,
					},
					labDetails: labDetails,
					amountPaidOnline: labAmountPaidCash,
					amountPaidCash: labAmountPaidOnline,
				},

				billDate: getCurrentDateIST(),
				billCreatedBy: adminInfo
					? adminInfo?.user?.name
					: receptionInfo
					? receptionInfo?.data?.user?.name
					: "",
				amountPaidOnline: labAmountPaidCash,
				amountPaidCash: labAmountPaidCash,
			};
			dispatch(
				addScheduledLabTestsToLabDept(
					getScheduledLabtests?.data?.data[0]?.userId,
					getScheduledLabtests?.data?.data[0]?.mobileNumber,
					getScheduledLabtests?.data?.data[0]?.userName,
					getScheduledLabtests?.data?.data[0]?.userIdx,
					tests,
					selectedItemsDeptIds,
					modeOfPayment,
					bill,
					labAmountPaidCash,
					labAmountPaidOnline
				)
			);
			setShow(false);
			setSelectedItems([]);
			setSelectedItemsTestsIdx([]);
			setSelectedItemsTests([]);
			setSelectedItemsPrice([]);
			setSelectedItemsAfterPrice([]);
			setSelectedItemsPriceDiscounts([]);
			setSelectedItemsDescs([]);
			setSelectedItemsSpecialityIds([]);
			setSelectedItemsSpecialityName([]);
			setSelectedItemsDoctorIds([]);
			setSelectedItemsDoctorName([]);
			setSelectedItemsPresIds([]);
			setSelectedItemsDeptIds([]);
			setselectedItemsDeptName([]);

			setTotalAmount(0);
			setAmountAfterDiscount(0);
			setModeOfPayment("");
			setLabAmountPaidOnline("");
			setLabAmountPaidCash("");
		}
	};

	const AddScheduledLabTestsToLabdept = useSelector(
		(state) => state.AddScheduledLabTestsToLabDept
	);
	const AddScheduledLabTestsToLabdeptLoading =
		AddScheduledLabTestsToLabdept?.loading;

	useEffect(() => {
		dispatch(getScheduledLabTests(userData?.data?.data?._id, userIdx));
	}, [AddScheduledLabTestsToLabdeptLoading]);

	const viewReportHandler = (id) => {
		// history('/viewreport', {
		// 	state: {
		// 		id: id,
		// 		age: userdata?.data?.data?.age,
		// 		gender: userdata?.data?.data?.gender,
		// 	},
		// });

		const data = {
			id: id,
			age: userdata?.data?.data?.age,
			gender: userdata?.data?.data?.gender,
		};
		let url = "/viewreport";

		const newWindow = window.open(url, "_blank");
		newWindow.onload = () => {
			// Pass both data and unique identifier to the new window
			newWindow.postMessage(data, window.location.origin);
		};
	};

	let count = 0;

	//Alert messages//////////////////////////
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const [open, setOpen] = useState([]);
	const toggle = (id) => {
		if (open.includes(id)) {
			setOpen(open.filter((key) => key !== id));
		} else {
			setOpen([...open, id]);
		}
	};

	function getFormattedDate() {
		const today = new Date();
		const day = String(today.getDate()).padStart(2, "0");
		const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
		const year = today.getFullYear();

		return `${day}-${month}-${year}`;
	}

	const startPrescription = () => {
		history("/startprescription", {
			state: {
				id: uid,
				name: userdata?.data?.data?.name,
				date: getFormattedDate(),
				specialityName: doctorInfo?.data?.user?.speciality[0],
				speciality: doctorInfo?.data?.user?.specialityId[0],
				bookingId: "Emergency Booking",
				activeTests: userdata?.data?.data?.activeTests,
				userIdx: userIdx,
			},
		});
	};

	const viewBill = (id) => {
		const sentuserdata = {
			userId: uid,
			name: userdata?.data?.data?.name,
			userIdx: userIdx,
		};
		const billUserDetails = {
			billId: id,
			userdata: sentuserdata,
		};
		const queryParams = new URLSearchParams();

		// Convert historyItems to a JSON string and encode it for the URL
		queryParams.append(
			"billItems",
			encodeURIComponent(JSON.stringify(billUserDetails))
		);

		// Open the new tab with the query parameters
		window.open(`/bill?${queryParams.toString()}`, "_blank");
	};

	return (
		<>
			{(loading || bookingloading || userdataloading) && <Loader />}

			<h1 className='header-center'>Search Patients </h1>
			<form className='row form-group' onSubmit={handleSearchPatient}>
				<Container className='header-center mt-5'>
					<Row className='justify-content-center'>
						<Col md='auto' className='text-center'>
							<h6>
								Enter Patient's Mobile Number{" "}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md='auto' className='text-center'>
							<input
								className='form-control'
								type='text'
								placeholder='9999999999'
								id='mobilenumber'
								required='required'
								onChange={(e) => {
									setMno(e.target.value);
									setSearchClicked(false);
								}}
								minlength='10'
								maxlength='10'
								pattern='^[0-9]*$'
							/>
						</Col>
						<Col md='auto' className='text-center'>
							<button type='submit' className='btn btn-primary'>
								Search
							</button>
						</Col>

						<Col md='auto' className='text-center'>
							{error && (
								<a
									role='button'
									href='/patientManagement/registerPatient'
									target='_blank'
									type='submit'
									className='btn btn-danger'>
									Register new Patient
								</a>
							)}
						</Col>
					</Row>
				</Container>
			</form>

			<div className='row offset-md-2'>
				{!error &&
					searchClicked &&
					userData &&
					users_array.map((users, index) =>
						users.name === uname ? (
							<button
								onClick={() =>
									handelUserSelect(
										users.id,
										users.bookingidarray,
										users.name,
										index
									)
								}
								className='btn btn-secondary col-md-3 m-1'>
								{users.name}
							</button>
						) : (
							<button
								onClick={() =>
									handelUserSelect(
										users.id,
										users.bookingidarray,
										users.name,
										index
									)
								}
								className='btn btn-outline-secondary col-md-3 m-1'>
								{users.name}
							</button>
						)
					)}
			</div>
			{!error && searchClicked && userData && userOptionClicked ? (
				<div>
					{doctorInfo !== undefined && (
						<div className='col-md-10 container'>
							<div className='card mt-2 container'>
								<div className='row mt-3 mb-3'>
									<h6 className='col'>Age: {userdata?.data?.data?.age}</h6>
									<h6 className='col'>Sex: {userdata?.data?.data?.gender}</h6>
									<h6 className='col'>Blood Group: {bloodGroup}</h6>
									<button
										type='submit'
										className='col btn btn-danger mr-2'
										onClick={startPrescription}>
										Emergency Appointment
									</button>
								</div>
							</div>
						</div>
					)}
					<Container className='mt-3'>
						<Tabs
							onSelect={getData}
							defaultActiveKey='bookinginfo'
							id='uncontrolled-tab-example'
							className='mb-3'>
							<Tab eventKey='bookinginfo' title='Booking Info'>
								<div className='row'>
									{getBookingsall?.data?.data?.length ? (
										getBookingsall?.data?.data.map(
											(bookings, index) =>
												bookings?.name === uname && (
													<div className='col-md-6 container'>
														<div className='card col-12 mt-2 mb-3'>
															<div class='card-body'>
																<div className='card-text'>
																	<div className='row'>
																		<div className='col-6 cardHeading'>
																			Name:
																		</div>
																		<div className='col-6'>{bookings.name}</div>
																	</div>
																	<div className='row'>
																		<div className='col-6 cardHeading'>
																			Doctor:
																		</div>
																		<div className='col-6'>
																			{bookings.doctorName}
																		</div>
																	</div>
																	<div className='row'>
																		<div className='col-6 cardHeading'>
																			Speciality:
																		</div>
																		<div className='col-6'>
																			{bookings.specialityName}
																		</div>
																	</div>
																	<div className='row'>
																		<div className='col-6 cardHeading'>
																			Scheduled Date:
																		</div>
																		<div className='col-6'>
																			{bookings.scheduledDate
																				?.toString()
																				?.split("")[6] +
																				bookings.scheduledDate
																					?.toString()
																					?.split("")[7] +
																				"-" +
																				bookings.scheduledDate
																					?.toString()
																					?.split("")[4] +
																				bookings.scheduledDate
																					?.toString()
																					?.split("")[5] +
																				"-" +
																				bookings.scheduledDate
																					?.toString()
																					?.split("")[0] +
																				bookings.scheduledDate
																					?.toString()
																					?.split("")[1] +
																				bookings.scheduledDate
																					?.toString()
																					?.split("")[2] +
																				bookings.scheduledDate
																					?.toString()
																					?.split("")[3]}
																		</div>
																	</div>
																	<div className='row'>
																		<div className='col-6 cardHeading'>
																			Slot:
																		</div>
																		<div className='col-6'>{bookings.slot}</div>
																	</div>
																	<div className='row'>
																		<div className='col-6 cardHeading'>
																			Slot Count:
																		</div>
																		<div className='col-6'>
																			{bookings.slotCount}
																		</div>
																	</div>
																	{usertype?.usertype !== "doctor" && (
																		<div className='row justify-content-center'>
																			<button
																				class='mt-2 btn btn-danger'
																				onClick={() =>
																					handleDelete(
																						bookings._id,
																						bookings?.billDetails?.billId
																					)
																				}>
																				<i className='fa fa-trash-o'></i>
																				{"   "}Delete
																			</button>
																		</div>
																	)}
																</div>
															</div>
														</div>
													</div>
												)
										)
									) : (
										<Container>
											<h5 className='header-center text-danger'>
												<b>No bookings for the selected patient</b>
											</h5>
										</Container>
									)}
								</div>
							</Tab>
							<Tab eventKey='prescriptioninfo' title='Prescription Info'>
								<Container className='card header-center mt-5'>
									<Row className='mt-4 mb-4'>
										<Col md='auto' className='text-center'>
											<h6>
												Choose Filter
												<span className='text-danger'>
													<b>*</b>
												</span>
											</h6>
										</Col>
										<Col md='auto' className='text-center'>
											<DropdownButton
												key='down-centered'
												id={`dropdown-button-drop-down-centered`}
												drop='down-centered'
												variant='success'
												title={filterOption}
												onSelect={handleFilterSelect}>
												<Dropdown.Item
													eventKey={"Date"}
													onClick={handleDateSelect}>
													{"Date"}
												</Dropdown.Item>
												<Dropdown.Item
													eventKey={"Category"}
													onClick={handleCategorySelect}>
													{"Category"}
												</Dropdown.Item>
											</DropdownButton>
										</Col>
										<Col md='auto' className='text-center'>
											<DropdownButton
												className={categoryDropdown}
												key='down-centered'
												id={`dropdown-button-drop-down-centered`}
												drop='down-centered'
												variant='success'
												title={categoryOption}
												onSelect={handleCategoryNameSelect}>
												{userdata?.data?.data?.category?.map(
													(categories, index) => (
														<Dropdown.Item eventKey={categories}>
															{categories}
														</Dropdown.Item>
													)
												)}
											</DropdownButton>
										</Col>
									</Row>
								</Container>
								<div className='card container mt-2'>
									<div className='row mt-5 mb-3'>
										<h6 className='col-3 col-md-2'>Date</h6>
										<h6 className='col-3 col-md-2'>Doctor</h6>
										<h6 className='col-3 col-md-2'>Category</h6>
										<h6 className='col-3 col-md-5'>Issue</h6>
									</div>
									{filterOption === "Date" &&
										userdata?.data?.data?.prescDate?.map((pres, index) => (
											<div className='row mt-2 mb-2'>
												<h6 className='col-3 col-md-2'>{pres?.bookedDate}</h6>
												<h6 className='col-3 col-md-2'>{pres?.doctorName}</h6>
												<h6 className='col-3 col-md-2'>{pres?.category}</h6>
												<h6 className='col-3 col-md-5 d-block d-md-none'>
													{" "}
													{pres?.title.slice(0, 20)}. . .
												</h6>
												<h6 className='col-3 col-md-5 d-none d-md-block'>
													{" "}
													{pres?.title.slice(0, 105)}. . .
												</h6>
												<button
													className='col-12 col-md-4 offset-md-4 btn btn-secondary'
													onClick={() =>
														viewBtn(
															pres?.prescriptionId,
															userdata?.data?.data?.age,
															userdata?.data?.data?.gender,
															bloodGroup,
															userdata?.data?.data?.name,
															userdata?.data?.data?.activeTests
														)
													}>
													View Case
												</button>
											</div>
										))}
									{filterOption === "Category" &&
										userdata?.data?.data?.prescCategory[
											ind
										]?.prescriptionIds?.map((pres, index) => (
											<div className='row mt-2 mb-2'>
												<h6 className='col-3 col-md-2'>{pres?.bookedDate}</h6>
												<h6 className='col-3 col-md-2'>{pres?.doctorName}</h6>
												<h6 className='col-3 col-md-2'>
													{userdata?.data?.data?.prescCategory[ind]?.category}
												</h6>
												<h6 className='col-3 col-md-5 d-block d-md-none'>
													{" "}
													{pres?.title.slice(0, 20)}. . .
												</h6>
												<h6 className='col-3 col-md-5 d-none d-md-block'>
													{" "}
													{pres?.title.slice(0, 105)}. . .
												</h6>
												<button
													className='col-12 col-md-4 offset-md-4 btn btn-secondary'
													onClick={() =>
														viewBtn(
															pres?.prescriptionId,
															userdata?.data?.data?.age,
															userdata?.data?.data?.gender,
															bloodGroup,
															userdata?.data?.data?.name,
															userdata?.data?.data?.activeTests
														)
													}>
													View Case
												</button>
											</div>
										))}
								</div>
							</Tab>
							<Tab eventKey='prescribedlabtests' title='Prescribed Lab Tests'>
								{selectedItems?.length > 0 && (
									<div className='row mb-3'>
										<div className='card offset-md-3 col-md-3 mr-1'>
											Total Selected : {selectedItems?.length}
										</div>
										<div className='card col-md-3 mr-1'>
											Total Amount : Rs {totalAmount}
										</div>
										<button
											className='btn btn-success col-md-2'
											onClick={() => handleUpdatePaymentStatus()}>
											Pay
										</button>
									</div>
								)}
								<Table striped bordered hover className='custom-table'>
									<thead>
										<tr>
											<th></th>
											<th>S.No</th>
											<th>Department Name</th>
											<th>Test Name</th>
											<th>Prescribed Doctor</th>
											<th>Test Price</th>
											<th>Description</th>
										</tr>
									</thead>
									<tbody>
										{getScheduledLabtests?.data?.data?.map((tests, index) => {
											return (
												tests?.suggestedTestsArray?.length > 0 &&
												tests?.suggestedTestsArray?.map((test, i) => {
													const sequentialNumber =
														index * tests.suggestedTestsArray.length + i + 1;
													count++;
													return (
														<tr
															key={sequentialNumber}
															onClick={() => {
																toggleItemSelection(
																	sequentialNumber - 1,
																	test?.testName,
																	test?.testPrice,
																	test?.description,
																	test?.deptId,
																	test?.testIdx,
																	tests?.specialityName,
																	tests?.specialityId,
																	tests?.doctorName,
																	tests?.doctorId,
																	tests?.prescriptionId,
																	test?.deptName
																);
															}}>
															<td>
																{selectedItems.includes(
																	test?.deptId + test?.testIdx
																)
																	? "✅"
																	: ""}
															</td>
															<td>{count}</td>
															<td>{test?.deptName}</td>
															<td>{test?.testName}</td>
															<td>{tests?.doctorName}</td>
															<td>{test?.testPrice}</td>
															<td>{test?.description}</td>
														</tr>
													);
												})
											);
										})}
									</tbody>
								</Table>
								<Modal show={show} onHide={handleClose} size='lg'>
									<Modal.Header closeButton>
										<Modal.Title>Update Payment Status</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<div className='row mt-2'>
											<div className='col-4 col-md-3 cardHeading'>Amount:</div>
											<div className='col-8'>Rs {totalAmount}</div>
										</div>
										<div className='row mt-2'>
											<div className='col-4 col-md-3 cardHeading'>
												Discount (%):
											</div>
											<input
												type='text'
												className='col-8 form-control'
												value={discount}
												onChange={handleDiscountChange}
											/>
										</div>
										<div className='row mt-2'>
											<div className='col-4 col-md-3 cardHeading'>
												Discount Reason:
											</div>
											<input
												type='text'
												className='col-8 form-control'
												value={discountReason}
												onChange={(e) => setDiscountReason(e.target.value)}
											/>
										</div>

										<div className='row mt-2'>
											<div className='col-4 col-md-3 cardHeading'>
												Amount after discount:
											</div>
											<div className='col-8'>Rs {amountAfterDiscount}</div>
										</div>
									</Modal.Body>
									<Modal.Footer>
										<input
											type='number'
											className='col-3 form-control'
											placeholder='Amount Paid Cash'
											value={labAmountPaidCash}
											onChange={(e) => handleAmounts(e.target.value, "cash")}
										/>
										<input
											type='number'
											className='col-3 form-control'
											placeholder='Amount Paid Online'
											value={labAmountPaidOnline}
											onChange={(e) => handleAmounts(e.target.value, "online")}
										/>
										<Button variant='secondary' onClick={handleClose}>
											Close
										</Button>
										<Button variant='primary' onClick={handleSubmit}>
											Complete Payment
										</Button>
									</Modal.Footer>
								</Modal>
							</Tab>

							<Tab eventKey='reports' title='Reports'>
								<Table striped bordered hover>
									<thead>
										<tr>
											<th>S.No</th>
											<th>Department Name</th>
											<th>Test Name</th>
											<th>Report Date</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{userIdx === 0
											? userData?.data?.data?.labReports?.map((test, index) => (
													<tr>
														<td>{index + 1}</td>
														<td>{test?.deptName}</td>
														<td>{test?.testName}</td>
														<td>
															{test?.reportDate?.toString()?.split("")[6] +
																test?.reportDate?.toString()?.split("")[7] +
																"-" +
																test?.reportDate?.toString()?.split("")[4] +
																test?.reportDate?.toString()?.split("")[5] +
																"-" +
																test?.reportDate?.toString()?.split("")[0] +
																test?.reportDate?.toString()?.split("")[1] +
																test?.reportDate?.toString()?.split("")[2] +
																test?.reportDate?.toString()?.split("")[3]}
														</td>
														<td>
															<button
																className='btn btn-success'
																onClick={() =>
																	viewReportHandler(test?.reportId)
																}>
																View Report
															</button>
														</td>
													</tr>
											  ))
											: userData?.data?.data?.registered_patients_array[
													userIdx - 1
											  ]?.labReports?.map((test, index) => (
													<tr>
														<td>{index + 1}</td>
														<td>{test?.deptName}</td>
														<td>{test?.testName}</td>
														<td>
															{test?.reportDate?.toString()?.split("")[6] +
																test?.reportDate?.toString()?.split("")[7] +
																"-" +
																test?.reportDate?.toString()?.split("")[4] +
																test?.reportDate?.toString()?.split("")[5] +
																"-" +
																test?.reportDate?.toString()?.split("")[0] +
																test?.reportDate?.toString()?.split("")[1] +
																test?.reportDate?.toString()?.split("")[2] +
																test?.reportDate?.toString()?.split("")[3]}
														</td>
														<td>
															<button
																className='btn btn-success'
																onClick={() =>
																	viewReportHandler(test?.reportId)
																}>
																View Report
															</button>
														</td>
													</tr>
											  ))}
									</tbody>
								</Table>
							</Tab>
							<Tab eventKey='history' title='History'>
								{getHistoryByIdx?.data?.data?.history?.map((history, i) => {
									let timestamp = Number(history?.createdAt);
									let date = new Date(timestamp);

									// Format the date to 12-hour format with AM/PM
									let options = {
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
										hour: "2-digit",
										minute: "2-digit",
										second: "2-digit",
										hour12: true,
									};
									let formattedDate = date.toLocaleString("en-US", options);
									var f = 1;
									return (
										<Container>
											<Accordion open={open} toggle={toggle} className='mt-1'>
												<AccordionItem>
													<AccordionHeader targetId={i}>
														{history?.doctorName} ({history?.specialityName}) -{" "}
														{formattedDate}
													</AccordionHeader>
													<AccordionBody accordionId={i}>
														<>
															{history?.historyData?.map((page, pageIndex) => (
																<Table
																	bordered
																	className='custom-table'
																	key={`table-${pageIndex}`}>
																	<tbody>
																		{page.map((item, idx) => {
																			if (f === 0) {
																				f = 1;
																			} else if (
																				item.label === "Header" ||
																				item.label === "Paragraph"
																			) {
																				return (
																					<tr key={`${item.label}-${idx}`}>
																						<td colSpan={4}>
																							{item.label === "Header" ? (
																								<h3>
																									{Array.isArray(item.value)
																										? item.value.join(", ")
																										: item.value}
																								</h3>
																							) : (
																								<p>
																									{Array.isArray(item.value)
																										? item.value.join(", ")
																										: item.value}
																								</p>
																							)}
																						</td>
																					</tr>
																				);
																			} else {
																				return (
																					<tr key={`${item.label}-${idx}`}>
																						<td>
																							<b>{item.label}: </b>
																						</td>
																						<td>
																							{item.type === "textarea" &&
																							item.value !== undefined
																								? item.value
																								: Array.isArray(item.value) &&
																								  item.type === "multiselect"
																								? Array.isArray(item.value) &&
																								  item.value
																										.map(
																											(option) => option.label
																										)
																										.join(", ")
																								: item.type === "checkbox"
																								? Array.isArray(item.value) &&
																								  item.value
																										.map((option) => option)
																										.join(", ")
																								: item.value}
																						</td>
																						{page[idx + 1] &&
																						page[idx + 1].label !== "Header" &&
																						page[idx + 1].label !==
																							"Paragraph" ? (
																							<>
																								<div className='d-none'>
																									{(f = 0)}
																								</div>
																								<td>
																									<b>{page[idx + 1].label}: </b>
																								</td>
																								<td>
																									{page[idx + 1].type ===
																										"textarea" &&
																									page[idx + 1].value !==
																										undefined
																										? page[idx + 1].value
																										: Array.isArray(
																												page[idx + 1].value
																										  ) &&
																										  page[idx + 1].type ===
																												"multiselect"
																										? Array.isArray(
																												page[idx + 1].value
																										  ) &&
																										  page[idx + 1].value
																												.map(
																													(option) =>
																														option.label
																												)
																												.join(", ")
																										: page[idx + 1].type ===
																										  "checkbox"
																										? Array.isArray(
																												page[idx + 1].value
																										  ) &&
																										  page[idx + 1].value
																												.map((option) => option)
																												.join(", ")
																										: page[idx + 1].value}
																								</td>
																							</>
																						) : null}
																					</tr>
																				);
																			}
																		})}
																	</tbody>
																</Table>
															))}
														</>
													</AccordionBody>
												</AccordionItem>
											</Accordion>
										</Container>
									);
								})}
							</Tab>
							<Tab eventKey='bills' title='Bills'>
								<Table bordered className='custom-table'>
									<thead>
										<tr>
											<th>S.No</th>
											<th>Bill Type</th>
											<th>Date</th>
											<th>Amount</th>
											<th>Due Amount</th>

											<th></th>
										</tr>
									</thead>
									<tbody>
										{userdata?.data?.data?.patient_bills?.map((bill, index) => (
											<tr>
												<td>{index + 1}</td>
												<td>{bill?.billType}</td>
												<td>{bill?.billDate}</td>
												<td>{bill?.paidAmount}</td>
												<td>{bill?.dueAmount}</td>
												<td>
													<button
														className='btn btn-success'
														onClick={() => viewBill(bill?.billId)}>
														<i class='fas fa-file-invoice-dollar'></i> View Bill
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</Tab>
						</Tabs>
					</Container>
				</div>
			) : (
				<div></div>
			)}
		</>
	);
};

export default SearchPatient;
