/** @format */

import { createSlice } from "@reduxjs/toolkit";

const createInventoryCategory = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		createInventoryCategoryStart: (state) => {
			state.loading = true;
		},
		createInventoryCategorySuccess: (state, action) => {
			state.loading = false;
			state.createInventorycategory = action.payload;
			state.error = false;
			state.success = true;
		},
		createInventoryCategoryFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createInventoryCategoryReset: (state, action) => {
			state.error = false;
			state.success = false;
			state.loading = false;
		},
	},
});

export const {
	createInventoryCategoryStart,
	createInventoryCategorySuccess,
	createInventoryCategoryFailure,
	createInventoryCategoryReset,
} = createInventoryCategory.actions;
export default createInventoryCategory.reducer;
