/** @format */

import { createSlice } from '@reduxjs/toolkit';
const userItemsFromStorage = JSON.parse(localStorage.getItem('adminInfo'))
	? JSON.parse(localStorage.getItem('adminInfo'))?.adminLogin
	: {};

const UpdatePasswordSlice = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		UpdatePasswordStart: (state) => {
			state.loading = true;
		},
		UpdatePasswordSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = null;
			state.success = true;
		},
		UpdatePasswordFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		UpdatePasswordReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	UpdatePasswordStart,
	UpdatePasswordSuccess,
	UpdatePasswordFailure,
	UpdatePasswordReset,
} = UpdatePasswordSlice.actions;
export default UpdatePasswordSlice.reducer;
