/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getTodaysRevenues = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		getTodaysRevenuesStart: (state) => {
			state.loading = true;
			state.getTodaysRevenues = [];
		},
		getTodaysRevenuesSuccess: (state, action) => {
			state.loading = false;
			state.getTodaysRevenues = action.payload;
			state.error = false;
			state.success = true;
		},
		getTodaysRevenuesFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getTodaysRevenuesReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getTodaysRevenuesStart,
	getTodaysRevenuesSuccess,
	getTodaysRevenuesFailure,
	getTodaysRevenuesReset,
} = getTodaysRevenues.actions;
export default getTodaysRevenues.reducer;
