/** @format */

import { createSlice } from '@reduxjs/toolkit';

const labDeptGetReducer = createSlice({
	name: 'labDeptGet',
	initialState: {},
	reducers: {
		labDeptGetRequest: (state) => {
			state.loading = true;
		},
		labDeptGetSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.getlabDeptAll = action.payload;
			state.error = false;
			state.success = true;
		},
		labDeptGetFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		labDeptGetReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	labDeptGetRequest,
	labDeptGetSuccess,
	labDeptGetFailure,
	labDeptGetReset,
} = labDeptGetReducer.actions;
export default labDeptGetReducer.reducer;
