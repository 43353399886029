/** @format */

import axios from "axios";
import { SERVER_URL } from "../../App";
import { hospitalname } from "../../App";

import {
	getPrescriptionIdsofCategoryFailure,
	getPrescriptionIdsofCategoryRequest,
	getPrescriptionIdsofCategorySuccess,
	getPrescriptionIdsofCategoryReset,
} from "./getPrescriptionIdsofCategory";

import {
	getPrescriptionByCategoryFailure,
	getPrescriptionByCategoryRequest,
	getPrescriptionByCategorySuccess,
	getPrescriptionByCategoryReset,
} from "./getPrescriptionByCategory";

import {
	getPrescriptionByDateFailure,
	getPrescriptionByDateRequest,
	getPrescriptionByDateSuccess,
} from "./getPrescriptionByDate";

import {
	getRegisteredPatientsFailure,
	getRegisteredPatientsSuccess,
	getRegisteredPatientsRequest,
} from "./getRegisteredPatients";

import {
	TimeSlotGetRequest,
	TimeSlotGetSuccess,
	TimeSlotGetFailure,
	TimeSlotGetReset,
} from "../getTimeSlots";

import {
	editBloodGroupFailure,
	editBloodGroupRequest,
	editBloodGroupSuccess,
} from "./editBloodGroup";

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";

export const getRegisteredPatients = (id) => async (dispatch) => {
	try {
		dispatch(getRegisteredPatientsRequest());
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/users/registeredPatients/${id}`,
			config
		);

		// console.log(data.user);
		dispatch(getRegisteredPatientsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		console.log(error);
		dispatch(
			getRegisteredPatientsFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getTimeSlotsForPatient = (id) => async (dispatch) => {
	try {
		dispatch(TimeSlotGetRequest());
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/users/getTimeSlotsForPatient/${id}`,
			config
		);

		// console.log(data.user);
		dispatch(TimeSlotGetSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		console.log(error);
		dispatch(
			TimeSlotGetFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const editBloodGroup =
	(userId, userIdx, bloodGroupValue) => async (dispatch) => {
		try {
			dispatch(editBloodGroupRequest());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/users/editBloodGroup`,
				{ userId, userIdx, bloodGroupValue },
				config
			);

			// console.log(data.user);
			dispatch(editBloodGroupSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			console.log(error);
			dispatch(
				editBloodGroupFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};
