/** @format */

import { createSlice } from "@reduxjs/toolkit";
const adminItemsFromStorage = JSON.parse(localStorage.getItem("persist:root"))
	? JSON.parse(localStorage.getItem("persist:root"))?.adminLogin
	: {};

const adminSlice = createSlice({
	name: "admin",
	initialState: adminItemsFromStorage,
	reducers: {
		loginStart: (state) => {
			state.loading = true;
		},
		loginSuccess: (state, action) => {
			state.loading = false;
			//state.adminInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		loginfailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.success = false;
			console.log(state.error);
		},
		loginReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
		logout: (state) => {
			state.adminInfo = {};
		},
	},
});

export const { loginStart, loginSuccess, loginfailure, logout, loginReset } =
	adminSlice.actions;
export default adminSlice.reducer;
