/** @format */

import { createSlice } from "@reduxjs/toolkit";

const closeIpBillReducer = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		closeIpBillStart: (state) => {
			state.loading = true;
		},
		closeIpBillSuccess: (state, action) => {
			state.loading = false;
			state.closeIpBillDetails = action.payload;
			state.error = false;
			state.success = true;
		},
		closeIpBillFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		closeIpBillReset: (state, action) => {
			state.success = false;
			state.error = false;
		},
	},
});

export const {
	closeIpBillStart,
	closeIpBillSuccess,
	closeIpBillFailure,
	closeIpBillReset,
} = closeIpBillReducer.actions;
export default closeIpBillReducer.reducer;
