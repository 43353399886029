/** @format */

import { createSlice } from "@reduxjs/toolkit";

const editReception = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    editReceptionStart: (state) => {
      state.loading = true;
    },
    editReceptionSuccess: (state, action) => {
      state.loading = false;
      state.editReception = action.payload;
      state.error = false;
      state.success = true;
    },
    editReceptionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editReceptionReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  editReceptionStart,
  editReceptionSuccess,
  editReceptionFailure,
  editReceptionReset,
} = editReception.actions;
export default editReception.reducer;
