/** @format */

import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, description, keywords }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keyword" content={keywords} />
		</Helmet>
	);
};

Meta.defaultProps = {
	title: "Dr. K. Anjal Reddy Memorial Hospital Official Website",
	description: "Hand in hand for a better life",
	keywords:
		"Dr. K. Anjal Reddy Memorial Hospital has become a pillar of healthcare excellence in the community",
};

export default Meta;
