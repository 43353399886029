/** @format */

import { createSlice } from "@reduxjs/toolkit";

const editInventoryCategory = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		editInventoryCategoryStart: (state) => {
			state.loading = true;
		},
		editInventoryCategorySuccess: (state, action) => {
			state.loading = false;
			state.editInventoryCategory = action.payload;
			state.error = false;
			state.success = true;
		},
		editInventoryCategoryFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editInventoryCategoryReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editInventoryCategoryStart,
	editInventoryCategorySuccess,
	editInventoryCategoryFailure,
	editInventoryCategoryReset,
} = editInventoryCategory.actions;
export default editInventoryCategory.reducer;
