/** @format */

import { createSlice } from '@reduxjs/toolkit';

const patientInventoryPurchase = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		patientInventoryPurchaseStart: (state) => {
			state.loading = true;
		},
		patientInventoryPurchaseSuccess: (state, action) => {
			state.loading = false;
			state.patientInventoryPurchase = action.payload;
			state.error = false;
			state.success = true;
		},
		patientInventoryPurchaseFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		patientInventoryPurchaseReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	patientInventoryPurchaseStart,
	patientInventoryPurchaseSuccess,
	patientInventoryPurchaseFailure,
	patientInventoryPurchaseReset,
} = patientInventoryPurchase.actions;
export default patientInventoryPurchase.reducer;
