/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../../../../Components/Errortemplate.js";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "../../../../Components/Loader.js";
import Meta from "../../../../Components/Meta.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Col, Row, Container } from "react-bootstrap";
import { getHospitalandSpecialityStatistics } from "../../../../redux/statisticsRedux/statisticApiCalls.js";
import { getSpecialities } from "../../../../redux/userApiCalls.js";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BarChart from "../../../../Components/Barchart.js";
import { getLabDepts } from "../../../../redux/adminRedux/adminApiCalls.js";

const HospitalStatisticsScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	//success and failure messages

	useEffect(() => {
		dispatch(getHospitalandSpecialityStatistics(2024));
	}, []);

	const { getHospitalandSpecialitystatistics, loading } = useSelector(
		(state) => state.getHospitalandSpecialityStatistics
	);

	console.log(getHospitalandSpecialitystatistics);

	const [last30DaysBarSpec, setLast30DaysBarSpec] = useState([]);
	const [last12MonsBarSpec, setLast12MonsBarSpec] = useState([]);
	const [activeSpecBar, setActiveSpecBar] = useState("30days");

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	useEffect(() => {
		let last30daysbar = [];
		let last12monsbar = [];
		getHospitalandSpecialitystatistics?.data?.data?.map((speciality, index) => {
			speciality?.specialityName === "hospital" &&
				speciality?.last_thirtydays_speciality?.map((day, index) => {
					let datearr = day?.date?.toString()?.split("");
					let date =
						datearr[6] +
						datearr[7] +
						"-" +
						datearr[4] +
						datearr[5] +
						"-" +
						datearr[0] +
						datearr[1] +
						datearr[2] +
						datearr[3];
					last30daysbar?.push({
						category: date,
						Amount:
							day?.opPatientsRevenueCash_speciality +
							day?.opPatientsRevenueOnline_speciality,
					});
				});
			speciality?.specialityName === "hospital" &&
				speciality?.last_twelve_months_speciality?.map((mon, index) => {
					last12monsbar?.push({
						category: months[mon?.monthNumber - 1],
						Amount:
							mon?.opPatientsRevenueCash_speciality +
							mon?.opPatientsRevenueOnline_speciality,
					});
				});
		});
		setLast30DaysBarSpec(last30daysbar);
		setLast12MonsBarSpec(last12monsbar);
	}, [loading]);

	console.log(last30DaysBarSpec);
	console.log(last12MonsBarSpec);

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href='/admin/hospitalstatistics'>
					Total OP
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/specialitystatistics'>
					Speciality
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/doctorstatistics'>Doctor</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/todaysoppatients'>
					Today's OP Patients
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className='header-center'>Hospital OP Revenue</h1>
			<Container className='header-center mt-5'>
				<button
					className='btn btn-info m-1'
					onClick={() => setActiveSpecBar("30days")}>
					Last 30 days
				</button>
				<button
					className='btn btn-info m-1'
					onClick={() => setActiveSpecBar("12mons")}>
					Last 12 Months
				</button>

				{activeSpecBar === "30days" && <BarChart data={last30DaysBarSpec} />}
				{activeSpecBar === "12mons" && <BarChart data={last12MonsBarSpec} />}
			</Container>
		</>
	);
};

export default HospitalStatisticsScreens;
