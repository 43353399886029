/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../../../../Components/Errortemplate.js";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "../../../../Components/Loader.js";
import Meta from "../../../../Components/Meta.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Col, Row, Container } from "react-bootstrap";
import {
	getReferralDoctorRevenue,
	getDepartmentandTestStatistics,
	getLabStatistics,
} from "../../../../redux/statisticsRedux/statisticApiCalls.js";
import { getSpecialities } from "../../../../redux/userApiCalls.js";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PieChart from "../../../../Components/PieChart.js";
import BarChart from "../../../../Components/Barchart.js";
import { getLabDepts } from "../../../../redux/adminRedux/adminApiCalls.js";

const DepartmentStatisticsScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	useEffect(() => {
		dispatch(getLabStatistics());
		dispatch(getLabDepts());
	}, []);

	const getLabStatistic = useSelector((state) => state.getLabStatistics);
	const getLabstatistics = getLabStatistic?.getLabstatistics;
	const getLabstatisticsLoading = getLabStatistic?.loading;
	console.log(getLabstatistics);

	const [last30DaysDeptPie, setLast30DaysDeptPie] = useState([]);
	const [last12MonsDeptPie, setLast12MonsDeptPie] = useState([]);
	const [activeDeptPie, setActiveDeptPie] = useState("30days");

	useEffect(() => {
		let days30DataDeptPie = [];
		let mons12DataDeptPie = [];
		getLabstatistics?.data?.data?.map((dept, index) => {
			let totalAmountForDept30Days = 0;
			let totalAmountForDept12Mons = 0;
			dept?.deptName !== "hospital" &&
				dept?.last_thirtydays_department?.map((day, index) => {
					totalAmountForDept30Days += day?.patientsRevenueCash_department;
					totalAmountForDept30Days += day?.patientsRevenueOnline_department;
				});
			dept?.deptName !== "hospital" &&
				dept?.last_twelve_months_department?.map((mon, index) => {
					totalAmountForDept12Mons += mon?.patientsRevenueCash_department;
					totalAmountForDept12Mons += mon?.patientsRevenueOnline_department;
				});
			dept?.deptName !== "hospital" &&
				days30DataDeptPie?.push({
					id: dept?.deptName,
					label: dept?.deptName,
					value: totalAmountForDept30Days,
				});
			dept?.deptName !== "hospital" &&
				mons12DataDeptPie?.push({
					id: dept?.deptName,
					label: dept?.deptName,
					value: totalAmountForDept12Mons,
				});
		});
		setLast30DaysDeptPie(days30DataDeptPie);
		setLast12MonsDeptPie(mons12DataDeptPie);
	}, [getLabstatisticsLoading]);

	console.log(last30DaysDeptPie);
	console.log(last12MonsDeptPie);

	const { getlabDeptAll, loading } = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	const [deptName, setDeptName] = useState();
	// getlabDeptAll?.data?.data[0]?.deptName
	const [deptId, setDeptId] = useState("");

	useEffect(() => {
		for (let i = 0; i < getlabDeptAll?.data?.data?.length; i++) {
			if (getlabDeptAll?.data?.data[i].deptName !== "hospital") {
				handleDeptSelect(
					getlabDeptAll?.data?.data[i]?.deptName +
						"@@" +
						getlabDeptAll?.data?.data[i]?._id
				);
				break;
			}
		}
	}, [loading]);

	const handleDeptSelect = (eventKey) => {
		setDeptName(eventKey?.split("@@")[0]);
		setDeptId(eventKey?.split("@@")[1]);
		dispatch(getDepartmentandTestStatistics(eventKey?.split("@@")[1]));
	};

	const getDepartmentandTeststatistic = useSelector(
		(state) => state.getDepartmentandTestStatistics
	);
	const getDepartmentandTeststatistics =
		getDepartmentandTeststatistic?.getDepartmentandTeststatistics;
	const getDepartmentandTeststatisticsLoading =
		getDepartmentandTeststatistic?.loading;

	console.log(getDepartmentandTeststatistics);

	const [last30DaysDeptBar, setLast30DaysDeptBar] = useState([]);
	const [last12MonsDeptBar, setLast12MonsDeptBar] = useState([]);
	const [activeDeptBar, setActiveDeptBar] = useState("30days");
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	useEffect(() => {
		let days30DataDeptBar = [];
		let mons12DataDeptBar = [];
		console.log(
			getDepartmentandTeststatistics?.data?.departmentInfo
				?.last_thirtydays_department
		);
		getDepartmentandTeststatistics?.data?.departmentInfo?.last_thirtydays_department?.map(
			(day, index) => {
				let datearr = day?.date?.toString()?.split("");
				let date =
					datearr[6] +
					datearr[7] +
					"-" +
					datearr[4] +
					datearr[5] +
					"-" +
					datearr[0] +
					datearr[1] +
					datearr[2] +
					datearr[3];
				days30DataDeptBar?.push({
					category: date,
					Amount:
						day?.patientsRevenueCash_department +
						day?.patientsRevenueOnline_department,
				});
			}
		);
		getDepartmentandTeststatistics?.data?.departmentInfo?.last_twelve_months_department?.map(
			(mon, index) => {
				mons12DataDeptBar?.push({
					category: months[mon?.monthNumber - 1],
					Amount:
						mon?.patientsRevenueCash_department +
						mon?.patientsRevenueOnline_department,
				});
			}
		);
		setLast30DaysDeptBar(days30DataDeptBar);
		setLast12MonsDeptBar(mons12DataDeptBar);
	}, [getDepartmentandTeststatisticsLoading]);

	console.log(last30DaysDeptBar);
	console.log(last12MonsDeptBar);

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href='/admin/labstatistics'>Total Lab</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/deptstatistics'>
					Department
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/teststatistics'>Test</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/todayslabpatients'>
					Today's lab patients
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className='header-center'>Department Revenue</h1>
			<Container className='header-center mt-5'>
				<button
					className='btn btn-info m-1'
					onClick={() => setActiveDeptPie("30days")}>
					Last 30 days
				</button>
				<button
					className='btn btn-info m-1'
					onClick={() => setActiveDeptPie("12mons")}>
					Last 12 Months
				</button>

				{activeDeptPie === "30days" && <PieChart data={last30DaysDeptPie} />}
				{activeDeptPie === "12mons" && <PieChart data={last12MonsDeptPie} />}
			</Container>
			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center'>
						<DropdownButton
							className='col-md-3'
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={deptName}
							onSelect={handleDeptSelect}>
							<div className='dropButton'>
								{getlabDeptAll?.data?.data?.length === 0 ? (
									<Dropdown.Item>
										{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
									</Dropdown.Item>
								) : (
									getlabDeptAll?.data?.data?.map(
										(dept, index) =>
											dept.deptName !== "hospital" &&
											dept.status !== 0 && (
												<Dropdown.Item
													eventKey={dept.deptName + "@@" + dept._id}>
													{dept.deptName}
												</Dropdown.Item>
											)
									)
								)}
							</div>
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center'>
						<button
							className='btn btn-info m-1'
							onClick={() => setActiveDeptBar("30days")}>
							Last 30 days
						</button>
					</Col>
					<Col md='auto' className='text-center'>
						<button
							className='btn btn-info m-1'
							onClick={() => setActiveDeptBar("12mons")}>
							Last 12 Months
						</button>
					</Col>
				</Row>
				{activeDeptBar === "30days" && <BarChart data={last30DaysDeptBar} />}
				{activeDeptBar === "12mons" && <BarChart data={last12MonsDeptBar} />}
			</Container>
		</>
	);
};

export default DepartmentStatisticsScreens;
