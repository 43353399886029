/** @format */

import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import LoaderGrow from "../../../Components/LoaderGrow";
import Table from "react-bootstrap/Table";
import Meta from "../../../Components/Meta";
import Form from "react-bootstrap/Form";
import {
	createDoctor,
	getLeaves,
	deleteDoctor,
} from "../../../redux/adminRedux/adminApiCalls";
import {
	getSpecialities,
	getDoctorFromSpeciality,
} from "../../../redux/userApiCalls";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Message from "../../../Components/Message";
import { createDoctorReset } from "../../../redux/adminRedux/Doctor_Management/createDoctor";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import axios from "axios";
import { SERVER_URL } from "../../../App";
import Button from "react-bootstrap/Button";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../../redux/allMessages";
import { deleteDoctorReset } from "../../../redux/adminRedux/Doctor_Management/deleteDoctor";
import { getLeavesReset } from "../../../redux/adminRedux/Doctor_Management/getLeaves";
import { specialityGetReset } from "../../../redux/getSpecialities";
import { getDoctorfromSpecialityReset } from "../../../redux/getDoctorfromSpeciality";

const DeleteDoctorScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const [timerRunning, setTimerRunning] = useState(false);

	// Delete Doctor
	const [specialityOptionDelete, setSpecialityOptionDelete] =
		useState("Choose Speciality");
	const [specialityIdDelete, setSpecialityIdDelete] = useState("");
	const [searchBtnClickDelete, setSearchBtnClickDelete] = useState(false);

	const handleSpecialitySelectDelete = (eventKey) => {
		console.log(eventKey);
		let arr = [];
		arr = eventKey.split("@@");
		setSpecialityOptionDelete(arr[0]);
		setSpecialityIdDelete(arr[1]);
		setSearchBtnClickDelete(false);
		submitHandlerDelete(arr[1]);
		setSpecialityIdx(arr[2]);
	};

	const { loading } = useSelector((state) => state.deleteDoctor);

	useEffect(() => {
		dispatch(getSpecialities());
	}, [loading]);

	const submitHandlerDelete = (specid) => {
		setSearchBtnClickDelete(true);
		dispatch(getDoctorFromSpeciality(specid));
	};
	// let allDoctorsDelete = [];
	const [allDoctorsDel, setAllDoctorsDel] = useState([]);

	const deleteDoctorHandle = (id, name) => {
		console.log(name);
		dispatch(deleteDoctor(id));

		//
	};

	useEffect(() => {
		dispatch(getSpecialities());
	}, []);
	const getSpecialityAllData = useSelector((state) => state.getSpeciality);
	const getSpecialityAll = getSpecialityAllData.getSpecialityAll;
	const getSpecialityAllsuccess = getSpecialityAllData.success;
	const getSpecialityAllerror = getSpecialityAllData.error;
	const getSpecialityAllloading = getSpecialityAllData.loading;
	let allSpecialities = [];
	const [specialityIdx, setSpecialityIdx] = useState(0);
	if (getSpecialityAll) {
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}
	console.log(allSpecialities);
	let allDoctors = [];

	const getDoctorfromSpecialityData = useSelector((state) => state.getDoctor);
	const getDoctorfromSpeciality =
		getDoctorfromSpecialityData.getDoctorfromSpeciality;
	const getDoctorfromSpecialitysuccess = getDoctorfromSpecialityData.success;
	const getDoctorfromSpecialityerror = getDoctorfromSpecialityData.error;
	const getDoctorfromSpecialityloading = getDoctorfromSpecialityData.loading;

	console.log(getDoctorfromSpeciality?.data[0]?.doctorsArray);

	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);
	const deletedoctor1 = useSelector((state) => state.deleteDoctor);
	const deleteDoctorloading = deletedoctor1.loading;
	const deleteDoctorerror = deletedoctor1.error;
	const deleteDoctorsuccess = deletedoctor1.success;
	const deleteDoctorMessage = deletedoctor1.deleteDoctor;
	const [loaderDelete, setLoaderDelete] = useState(false);
	useEffect(() => {
		if (deleteDoctorsuccess && !timerRunning) {
			setTimerRunning(true);
			setLoaderDelete(true);
			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(deleteDoctorReset());
				handleSpecialitySelectDelete(
					specialityOptionDelete +
						"@@" +
						specialityIdDelete +
						"@@" +
						specialityIdx
				);
				setTimerRunning(false);
				setLoaderDelete(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [deleteDoctorsuccess, dispatch]);

	return (
		<>
			<Meta />

			{/* {deleteDoctorloading && <Loader />} */}
			{(getSpecialityAllloading || getDoctorfromSpecialityloading) && (
				<Loader />
			)}

			<div>
				<h1 className="header-center">Delete Doctor</h1>
				<Container className="header-center mt-5">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center">
							<h6>
								Select the filters{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={specialityOptionDelete}
								onSelect={handleSpecialitySelectDelete}
							>
								{allSpecialities.map((specialities, index) => (
									<Dropdown.Item
										eventKey={
											specialities.specialization +
											"@@" +
											specialities._id +
											"@@" +
											index
										}
									>
										{specialities.specialization}
									</Dropdown.Item>
								))}
							</DropdownButton>
						</Col>
					</Row>
				</Container>
				{searchBtnClickDelete ? (
					<Container className="header-center mt-5 col-md-8">
						<Table striped bordered hover className="custom-table">
							<thead>
								<tr>
									<th>SNO</th>
									<th>Doctor Name</th>
									<th></th>
								</tr>
							</thead>

							<tbody>
								{loaderDelete ? (
									<Loader />
								) : (
									getSpecialityAll?.data?.data[
										specialityIdx
									]?.doctorsArray?.map((doctors, index) => (
										<tr>
											<td>{index + 1}</td>
											<td>{doctors?.name}</td>
											<td>
												<button
													className="btn btn-danger"
													onClick={() =>
														deleteDoctorHandle(doctors?.doctorsinfo)
													}
												>
													<i className="fa fa-trash"></i>
												</button>
											</td>
										</tr>
									))
								)}
							</tbody>
						</Table>
					</Container>
				) : (
					<div> </div>
				)}
			</div>
		</>
	);
};

export default DeleteDoctorScreens;
