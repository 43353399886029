/** @format */

import { ResponsiveBar } from "@nivo/bar";
import { getPastCategoryRevenues } from "../../../../redux/inventoryRedux/inventoryApiCalls.js";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Meta from "../../../../Components/Meta.js";
import Button from "react-bootstrap/Button";
import { Col, Row, Container, Card } from "react-bootstrap";
import { Breadcrumb } from "react-bootstrap";

// This is the main component
const PharmacyStatisticsScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	const [last30DaysBarSpec, setLast30DaysBarSpec] = useState([]);
	const [last12MonsBarSpec, setLast12MonsBarSpec] = useState([]);
	const [activeChart, setActiveChart] = useState("30days");

	useEffect(() => {
		dispatch(getPastCategoryRevenues());
	}, []);
	const getPastCategoryRevenuesDetails = useSelector(
		(state) => state.getPastCategoryRevenues
	);

	console.log(getPastCategoryRevenuesDetails);
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	useEffect(() => {
		let last30daysbar = [];
		let last12monsbar = [];
		console.log(getPastCategoryRevenuesDetails.getPastCategoryRevenues);
		getPastCategoryRevenuesDetails?.getPastCategoryRevenues?.data?.data?.map(
			(category, index) => {
				category?.name === "hospital" &&
					category?.category_sales?.last_thirtydays_sales?.map((day, index) => {
						console.log("day");
						const formattedDate = formatDate(day.date.toString());
						last30daysbar?.push({
							day: formattedDate,
							purchases:
								category?.category_purchases?.last_thirtydays_purchases[index]
									?.category_purchase_amount,
							sales:
								day.category_sales_amountCash + day.category_sales_amountOnline,
							discounts: day.category_sales_discount,
							profit: day.category_sales_profit,
						});
					});
			}
		);
		getPastCategoryRevenuesDetails?.getPastCategoryRevenues?.data?.data?.map(
			(category, index) => {
				category?.name === "hospital" &&
					category?.category_sales?.last_twelvemonths_sales?.map(
						(day, index) => {
							last12monsbar?.push({
								month: months[day.monthNumber - 1],
								purchases:
									category?.category_purchases?.last_twelvemonths_purchases[
										index
									]?.category_purchase_amount,
								sales:
									day.category_sales_amountCash +
									day.category_sales_amountOnline,
								discounts: day.category_sales_discount,
								profit: day.category_sales_profit,
							});
						}
					);
			}
		);

		console.log(last30daysbar);
		console.log(last12monsbar);
		setLast30DaysBarSpec(last30daysbar);
		setLast12MonsBarSpec(last12monsbar);
	}, [getPastCategoryRevenuesDetails?.loading]);

	const indexBy = activeChart === "30days" ? "day" : "month";
	const xLegend = activeChart === "30days" ? "Date" : "Month";

	const commonProps = {
		keys: ["profit", "sales", "purchases", "discounts"],

		padding: 0.3,
		groupMode: "stacked",
		margin: { top: 50, right: 130, bottom: 50, left: 100 },
		axisLeft: {
			tickSize: 5,
			tickPadding: 20,
			tickRotation: 0,
			legend: xLegend,
			legendPosition: "middle",
			legendOffset: -90,
		},
		axisBottom: {
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: "Amount",
			legendPosition: "middle",
			legendOffset: 32,
		},
		colors: { scheme: "nivo" },
		enableLabel: true,
	};
	function formatDate(dateString) {
		// Ensure the input is a string of the format "YYYYMMDD"
		console.log(dateString.length);
		if (dateString.length !== 8) {
			throw new Error("Invalid date format. Expected 'YYYYMMDD'.");
		}

		// Extract year, month, and day from the string
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = dateString.slice(6, 8);

		// Format the date as "YYYY/MM/DD"
		return `${year}/${month}/${day}`;
	}

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href="/admin/AllPharmacySales">
					Pharmacy
				</Breadcrumb.Item>
				<Breadcrumb.Item href="/admin/VendorLevelStatistics">
					Vendor Level
				</Breadcrumb.Item>
				<Breadcrumb.Item href="/admin/CategoryLevelStatistics">
					Category Level
				</Breadcrumb.Item>
				<Breadcrumb.Item href="/admin/todaysinventorystatistics">
					Today's Pharmacy
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className="header-center mt-3 mb-3">Pharmacy Revenue</h1>
			<Container className="header-center card">
				<Row className="justify-content-center mt-3 mb-3">
					{/* Total Amount */}
					<Col md="auto">
						<b>Total Amount: </b>Rs{" "}
						{getPastCategoryRevenuesDetails?.getPastCategoryRevenues?.data?.data
							?.filter((category) => category.name === "hospital")
							?.map((category, index) => (
								<span key={category?._id || index}>
									{category?.category_sales?.last_thirtydays_sales?.reduce(
										(acc, sale) => acc + sale?.category_sales_amount,
										0
									)}
								</span>
							))}
					</Col>

					{/* Total Cash */}
					<Col md="auto">
						<b>Total Cash: </b>Rs{" "}
						{getPastCategoryRevenuesDetails?.getPastCategoryRevenues?.data?.data
							?.filter((category) => category.name === "hospital")
							?.map((category, index) => (
								<span key={category?._id || index}>
									{category?.category_sales?.last_thirtydays_sales?.reduce(
										(acc, sale) => acc + sale?.category_sales_amountCash,
										0
									)}
								</span>
							))}
					</Col>

					{/* Total Online */}
					<Col md="auto">
						<b>Total Online: </b>Rs{" "}
						{getPastCategoryRevenuesDetails?.getPastCategoryRevenues?.data?.data
							?.filter((category) => category.name === "hospital")
							?.map((category, index) => (
								<span key={category?._id || index}>
									{category?.category_sales?.last_thirtydays_sales?.reduce(
										(acc, sale) => acc + sale?.category_sales_amountOnline,
										0
									)}
								</span>
							))}
					</Col>

					{/* Total Profit */}
					<Col md="auto">
						<b>Total Profit Amount: </b>Rs{" "}
						{getPastCategoryRevenuesDetails?.getPastCategoryRevenues?.data?.data
							?.filter((category) => category.name === "hospital")
							?.map((category, index) => (
								<span key={category?._id || index}>
									{category?.category_sales?.last_thirtydays_sales?.reduce(
										(acc, sale) => acc + sale?.category_sales_profit,
										0
									)}
								</span>
							))}
					</Col>

					{/* Total Discount */}
					<Col md="auto">
						<b>Total Discount Amount: </b>Rs{" "}
						{getPastCategoryRevenuesDetails?.getPastCategoryRevenues?.data?.data
							?.filter((category) => category.name === "hospital")
							?.map((category, index) => (
								<span key={category?._id || index}>
									{category?.category_sales?.last_thirtydays_sales?.reduce(
										(acc, sale) => acc + sale?.category_sales_discount,
										0
									)}
								</span>
							))}
					</Col>
				</Row>
			</Container>

			<Container className="header-center mt-3">
				<Button
					className="btn btn-info m-1"
					onClick={() => setActiveChart("30days")}
				>
					Last 30 Days
				</Button>
				<Button
					className="btn btn-info m-1"
					onClick={() => setActiveChart("12mons")}
				>
					Last 12 Months
				</Button>

				{/* Bar chart with the profit line */}
				<div style={{ height: "500px" }}>
					{activeChart === "30days" && (
						<ResponsiveBar
							data={last30DaysBarSpec}
							indexBy={indexBy}
							{...commonProps}
							layout="horizontal"
						/>
					)}
					{activeChart === "12mons" && (
						<ResponsiveBar
							data={last12MonsBarSpec}
							indexBy={indexBy}
							{...commonProps}
							layout="horizontal"
						/>
					)}
				</div>
			</Container>
		</>
	);
};

export default PharmacyStatisticsScreens;
