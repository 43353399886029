/** @format */

import { createSlice } from '@reduxjs/toolkit';

const editCaseInPatient = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		editCaseInPatientStart: (state) => {
			state.loading = true;
		},
		editCaseInPatientSuccess: (state, action) => {
			state.loading = false;
			state.editCaseInPatient = action.payload;
			state.error = false;
			state.success = true;
		},
		editCaseInPatientFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editCaseInPatientReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editCaseInPatientStart,
	editCaseInPatientSuccess,
	editCaseInPatientFailure,
	editCaseInPatientReset,
} = editCaseInPatient.actions;
export default editCaseInPatient.reducer;
