/** @format */

import React, { useState, useEffect } from "react";
import {
	Button,
	Form,
	FormControl,
	FormLabel,
	FormCheck,
	Row,
	Col,
	Container,
	Modal,
	Table,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEdit,
	faTrash,
	faPlus,
	faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { MultiSelect } from "react-multi-select-component";
import "../../../styles/FormBuilder.css"; // Import your CSS file for styling
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PrescriptionCreator } from "../../../redux/doctorRedux/doctorApiCalls";
import {
	GetPrescriptionTemplateNames,
	GetPrescriptionTemplate,
} from "../../../redux/doctorRedux/doctorApiCalls";

const PrescriptionCreatorScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const [templateName, setTemplateName] = useState("");
	const [formElementsCreate, setFormElementsCreate] = useState([]);
	const [selectedElementIndex, setSelectedElementIndex] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editLabel, setEditLabel] = useState("");
	const [editOptions, setEditOptions] = useState([]);
	const [isRequired, setIsRequired] = useState(false);
	const [editHeaderValue, setEditHeaderValue] = useState("");
	const [editParagraphValue, setEditParagraphValue] = useState("");
	const [draggingElement, setDraggingElement] = useState("");
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [editModalOptions, setEditModalOptions] = useState([]);
	const [newOption, setNewOption] = useState("");
	const [showAddOptionInput, setShowAddOptionInput] = useState(false);

	console.log(selectedOptions);

	useEffect(() => {
		setFormElementsCreate([]);
	}, []);

	const addFormElement = (elementType, label = "Label Name") => {
		let defaultOptions = [];
		if (
			elementType === "radio" ||
			elementType === "checkbox" ||
			elementType === "select" ||
			elementType === "multiselect"
		) {
			defaultOptions = ["Option 1", "Option 2", "Option 3"];
		}

		setFormElementsCreate([
			// If formElementsCreate has elements, spread them into the new array, otherwise start with an empty array
			...(formElementsCreate?.length > 0 ? formElementsCreate : []),

			// Create the new form element object
			{
				type: elementType, // Set the type to the current element type
				label:
					elementType === "header"
						? "Header"
						: elementType === "paragraph"
						? "Paragraph"
						: label, // Set the label based on the element type
				value:
					elementType === "header"
						? "Header"
						: elementType === "paragraph"
						? "Paragraph"
						: "", // Set the value based on the element type
				options: elementType === "header" ? [] : defaultOptions, // Set options, empty array for headers
				rows: 3,
				cols: 3,
				cellData: {},
				required: false, // Set required to false by default
			},
		]);
	};

	const handleDragStart = (event, elementType) => {
		event.dataTransfer.setData("elementType", elementType);
		setDraggingElement(elementType);
	};

	const handleValueChange = (event, index) => {
		const updatedElements = [...formElementsCreate];
		updatedElements[index].value = event.target.value;
		setFormElementsCreate(updatedElements);
	};

	const handleDrop = (event) => {
		event.preventDefault();

		const elementType = event.dataTransfer.getData("elementType");
		if (elementType === "header") {
			addFormElement("header");
		} else if (elementType === "paragraph") {
			addFormElement("paragraph");
			setDraggingElement(false);
		} else {
			addFormElement(elementType);
		}
		setDraggingElement("");
	};

	const handleItemClick = (elementType) => {
		addFormElement(elementType);
	};

	const handleFormLabelChange = (event) => {
		setTemplateName(event.target.value);
	};

	const handleLabelChange = (event, index) => {
		const updatedElements = [...formElementsCreate];
		if (updatedElements[index]) {
			updatedElements[index].label = event.target.value;
			setFormElementsCreate(updatedElements);
		}
	};

	const handleOptionChange = (event, index) => {
		const updatedOptions = [...editOptions];
		updatedOptions[index] = event.target.value;
		setEditOptions(updatedOptions);
	};

	const handleAddOption = () => {
		setShowAddOptionInput(true);
	};

	const handleDeleteOption = (index) => {
		const updatedOptions = [...editOptions];
		updatedOptions.splice(index, 1);
		setEditOptions(updatedOptions);
	};

	const handleDeleteElement = (index) => {
		const updatedElements = [...formElementsCreate];
		updatedElements.splice(index, 1);
		setFormElementsCreate(updatedElements);
		setSelectedElementIndex(null);
	};

	const handleEditModalClose = () => {
		setShowEditModal(false);
	};

	const handleEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElementsCreate];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].label = editLabel;
				if (
					updatedElements[selectedElementIndex].type !== "header" &&
					updatedElements[selectedElementIndex].type !== "paragraph"
				) {
					updatedElements[selectedElementIndex].options = editOptions;
					updatedElements[selectedElementIndex].required = isRequired;
				} else if (updatedElements[selectedElementIndex].type === "header") {
					updatedElements[selectedElementIndex].value = editHeaderValue;
				} else if (updatedElements[selectedElementIndex].type === "paragraph") {
					updatedElements[selectedElementIndex].value = editParagraphValue;
				}
				setFormElementsCreate(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const openEditModal = (index) => {
		setSelectedElementIndex(index);
		if (formElementsCreate[index]) {
			setEditLabel(formElementsCreate[index].label);
			setEditOptions(formElementsCreate[index].options);
			setIsRequired(formElementsCreate[index].required);
			setEditHeaderValue(formElementsCreate[index].value);
			setEditParagraphValue(formElementsCreate[index].value);
		}
		setShowEditModal(true);
	};

	const handleSubmitForm = () => {
		if (templateName === "") {
			alert("Please enter template name");
		} else {
			console.log({ templateName, formElementsCreate });
			// Submit form data here
			dispatch(
				PrescriptionCreator(
					templateName,
					formElementsCreate,
					doctorInfo?.data?.user?.specialityId[selectedSpeciality]
				)
			);
			// window?.location?.reload();
			setTemplateName("");
			setFormElementsCreate([]);
		}
	};

	const addHeaderToForm = () => {
		addFormElement("header");
	};

	const addParagraphToForm = () => {
		addFormElement("paragraph");
	};

	const handleHeaderEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElementsCreate];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].value = editHeaderValue;
				setFormElementsCreate(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const handleParagraphEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElementsCreate];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].value = editParagraphValue;
				setFormElementsCreate(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const [clonedTemplate, setClonedTemplate] = useState([]);
	const [selectedSpeciality, setSelectedSpeciality] = useState(0);

	const handleSpecSelect = (eventKey) => {
		setSelectedSpeciality(eventKey);
		dispatch(
			GetPrescriptionTemplateNames(
				doctorInfo?.data?.user?.specialityId[eventKey]
			)
		);
	};

	useEffect(() => {
		handleSpecSelect(0);
	}, []);

	const { getPrescriptionTemplateNames } = useSelector(
		(state) => state.GetPrescriptionTemplateNames
	);

	console.log(getPrescriptionTemplateNames);

	// Handle cell data updates
	const handleCellChange = (row, col, value, index) => {
		const updatedElements = [...formElementsCreate];
		if (updatedElements[index]) {
			updatedElements[index].cellData[`${row}-${col}`] = value;
			setFormElementsCreate(updatedElements);
		}
	};

	// Handle table resizing while preserving data
	const resizeTable = (newRows, newColumns, index) => {
		setFormElementsCreate((prevElements) => {
			const updatedElements = [...prevElements];

			if (updatedElements[index]) {
				// Create a copy of the specific element and update its properties
				const updatedElement = { ...updatedElements[index] };
				updatedElement.rows = newRows;
				updatedElement.cols = newColumns;

				// Replace the old element with the updated element
				updatedElements[index] = updatedElement;
			}

			return updatedElements;
		});
	};
	return (
		<div className='form-builder'>
			<h2 className='header-center'>Add Prescription Template</h2>
			<div className='offset-md-10'>
				<Button onClick={handleSubmitForm}>Submit Form</Button>
			</div>
			<Container className='header-center mt-5'>
				<Row className='mt-4 mb-4 justify-content-center'>
					<Col md='auto' className='text-center'>
						<h6>Choose Speciality:</h6>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={doctorInfo?.data?.user?.speciality[selectedSpeciality]}
							onSelect={handleSpecSelect}>
							{doctorInfo?.data?.user?.speciality?.map((speciality, index) => (
								<Dropdown.Item eventKey={index}>{speciality}</Dropdown.Item>
							))}
						</DropdownButton>
					</Col>
					{/* <Col md='3' className='text-center mt-1'>
						{getPrescriptionTemplateNames?.data?.data
							?.prescriptionTemplatesNamesArray && (
							<MultiSelect
								options={getPrescriptionTemplateNames?.data?.data?.prescriptionTemplatesNamesArray.map(
									(option) => ({
										label: option?.templateName,
										value: option?._id,
									})
								)}
								value={clonedTemplate}
								onChange={(selectedOptions) =>
									handleClonedTemplate(selectedOptions)
								}
								labelledBy='Select'
							/>
						)}
					</Col>
					<Col md='3' className='text-center mt-1'>
						<button
							className='btn btn-danger'
							onClick={() => setFormElementsCreate([])}>
							Clear Filter
						</button>
					</Col> */}
				</Row>
			</Container>
			<div className='mt-3 mb-3'>
				<FormLabel>Template Name:</FormLabel>
				<FormControl
					type='text'
					value={templateName}
					onChange={handleFormLabelChange}
				/>
			</div>
			<Row>
				<Col md={3} className='toolbox'>
					<h3>Toolbox</h3>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, "input")}>
						Input
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, "table")}>
						Table
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, "textarea")}>
						Textarea
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, "select")}>
						Select
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, "checkbox")}>
						Checkbox
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, "radio")}>
						Radio
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, "header")}>
						Header
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, "paragraph")}>
						Paragraph
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, "multiselect")}>
						MultiSelect
					</div>
				</Col>
				<Col md={9}>
					<div
						className={`form-canvas ${
							draggingElement === "paragraph" ? "dragged-paragraph" : ""
						}`}
						onDragOver={(e) => e.preventDefault()}
						onDrop={handleDrop}>
						<Form>
							{formElementsCreate?.map((element, index) => (
								<div
									key={index}
									className={`form-element ${
										selectedElementIndex === index ? "selected" : ""
									}`}>
									<div className='form-element-header'>
										<div className='label-edit-delete'>
											{element.type !== "header" &&
											element.type !== "paragraph" ? (
												<>
													<FormLabel className='label'>
														{element.label}
													</FormLabel>
												</>
											) : element.type !== "paragraph" ? (
												<h3>{element.value}</h3>
											) : (
												<p className='paragraph'>{element.value}</p>
											)}
											<div className='edit-delete-buttons'>
												<button
													type='button'
													className='edit-icon btn'
													onClick={() => openEditModal(index)}>
													<FontAwesomeIcon icon={faEdit} />
												</button>
												<button
													type='button'
													className='delete-icon btn'
													onClick={() => handleDeleteElement(index)}>
													<FontAwesomeIcon icon={faTrash} />
												</button>
											</div>
										</div>
									</div>
									<div className='form-element-input'>
										{element.type === "select" ? (
											<Form.Control as='select'>
												{element.options.map((option, optionIndex) => (
													<option key={optionIndex}>{option}</option>
												))}
											</Form.Control>
										) : element.type === "table" ? (
											<>
												<div className='row justify-content-center align-items-center mb-3'>
													<div className='col-auto'>
														Rows:{" "}
														<input
															type='number'
															className='form-control'
															value={element?.rows}
															onChange={(e) =>
																resizeTable(
																	Number(e.target.value),
																	Number(element?.cols),
																	index
																)
															}
														/>
													</div>
													<div className='col-auto'>
														Columns:{" "}
														<input
															type='number'
															className='form-control'
															value={element?.cols}
															onChange={(e) =>
																resizeTable(
																	Number(element?.rows),
																	Number(e.target.value),
																	index
																)
															}
														/>
													</div>
												</div>

												<div
													style={{
														position: "relative",
														width: "100%",
														overflowX: "scroll",
													}}>
													<Table
														bordered
														className='custom-table'
														style={{ width: "150%" }}>
														<thead className='bg-light'>
															<tr>
																{[...Array(element.cols)].map((_, colIndex) => (
																	<td key={colIndex}>
																		<Form.Control
																			type='text'
																			value={
																				element.cellData[`${0}-${colIndex}`] ||
																				""
																			}
																			onChange={(e) =>
																				handleCellChange(
																					0,
																					colIndex,
																					e.target.value,
																					index
																				)
																			}
																			placeholder='Header'
																		/>
																	</td>
																))}
															</tr>
														</thead>
														<tbody>
															{[...Array(element.rows)].map((_, rowIndex) => (
																<tr key={rowIndex + 1}>
																	{[...Array(element.cols)].map(
																		(_, colIndex) => (
																			<td key={colIndex}>
																				<Form.Control
																					type='text'
																					value={
																						element.cellData[
																							`${rowIndex + 1}-${colIndex}`
																						] || ""
																					}
																					onChange={(e) =>
																						handleCellChange(
																							rowIndex + 1,
																							colIndex,
																							e.target.value,
																							index
																						)
																					}
																				/>
																			</td>
																		)
																	)}
																</tr>
															))}
														</tbody>
													</Table>
												</div>
											</>
										) : element.type === "multiselect" ? (
											<MultiSelect
												options={element.options.map((option) => ({
													label: option,
													value: option,
												}))}
												value={selectedOptions}
												onChange={setSelectedOptions}
												labelledBy='Select'
											/>
										) : element.type === "checkbox" ? (
											<div>
												{element.options.map((option, optionIndex) => (
													<Form.Check
														key={optionIndex}
														type='checkbox'
														label={option}
														required={element.required}
													/>
												))}
											</div>
										) : element.type === "radio" ? (
											<div>
												{element.options.map((option, optionIndex) => (
													<Form.Check
														key={optionIndex}
														type='radio'
														label={option}
														required={element.required}
													/>
												))}
											</div>
										) : element.type !== "header" &&
										  element.type !== "paragraph" ? (
											element.type === "input" ? (
												<FormControl
													type={element.type}
													placeholder='Enter Value'
													value={element.value}
													onChange={(e) => handleValueChange(e, index)}
												/>
											) : (
												<textarea
													rows={2}
													cols={100}
													className='form-control'
													onChange={(e) => handleValueChange(e, index)}
													value={element.value}></textarea>
											)
										) : null}
									</div>
								</div>
							))}
						</Form>
					</div>
				</Col>
			</Row>

			<Modal show={showEditModal} onHide={handleEditModalClose}>
				<Modal.Header closeButton>
					<Modal.Title>Edit Form Element</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{formElementsCreate?.[selectedElementIndex]?.type === "header" ? (
						<>
							<FormLabel>Edit Value:</FormLabel>
							<FormControl
								type='text'
								value={editHeaderValue}
								onChange={(e) => setEditHeaderValue(e.target.value)}
							/>
						</>
					) : formElementsCreate?.[selectedElementIndex]?.type ===
					  "paragraph" ? (
						<>
							<FormLabel>Edit Value:</FormLabel>
							<FormControl
								type='text'
								value={editParagraphValue}
								onChange={(e) => setEditParagraphValue(e.target.value)}
							/>
						</>
					) : (
						<>
							<FormLabel>Edit Label:</FormLabel>
							<FormControl
								type='text'
								value={editLabel}
								onChange={(e) => setEditLabel(e.target.value)}
							/>
							{formElementsCreate?.[selectedElementIndex]?.type !== "header" &&
							formElementsCreate?.[selectedElementIndex]?.type !==
								"paragraph" &&
							(formElementsCreate?.[selectedElementIndex]?.type ===
								"checkbox" ||
								formElementsCreate?.[selectedElementIndex]?.type === "radio" ||
								formElementsCreate?.[selectedElementIndex]?.type === "select" ||
								formElementsCreate?.[selectedElementIndex]?.type ===
									"multiselect") ? (
								<>
									<FormLabel>Edit Options:</FormLabel>
									{editOptions.map((option, index) => (
										<div key={index} className='header-center'>
											<Row className='mt-1'>
												<Col md='10'>
													<FormControl
														type='text'
														value={option}
														onChange={(e) => handleOptionChange(e, index)}
													/>
												</Col>
												<Col md='1'>
													<Button
														variant='danger'
														onClick={() => handleDeleteOption(index)}>
														<FontAwesomeIcon icon={faTrash} />
													</Button>
												</Col>
											</Row>
											{index === editOptions.length - 1 && (
												<Button
													variant='primary'
													onClick={handleAddOption}
													className='mt-2 mb-2'>
													<FontAwesomeIcon icon={faPlus} /> Add
												</Button>
											)}
										</div>
									))}
									{showAddOptionInput && (
										<Row>
											<Col md='10'>
												<FormControl
													type='text'
													value={newOption}
													onChange={(e) => setNewOption(e.target.value)}
													placeholder='New Option'
												/>
											</Col>
											<Col md='1'>
												<Button
													variant='primary'
													onClick={() => {
														setEditOptions([...editOptions, newOption]);
														setNewOption("");
														setShowAddOptionInput(false);
													}}>
													<FontAwesomeIcon icon={faCheck} />
												</Button>
											</Col>
										</Row>
									)}
								</>
							) : null}
							{formElementsCreate?.[selectedElementIndex]?.type !== "header" &&
								formElementsCreate?.[selectedElementIndex]?.type !==
									"paragraph" && (
									<FormCheck
										type='checkbox'
										label='Required'
										checked={isRequired}
										onChange={() => setIsRequired(!isRequired)}
									/>
								)}
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleEditModalClose}>
						Close
					</Button>
					{formElementsCreate?.[selectedElementIndex]?.type === "header" ? (
						<Button variant='primary' onClick={handleHeaderEditModalSave}>
							Save Changes
						</Button>
					) : formElementsCreate?.[selectedElementIndex]?.type ===
					  "paragraph" ? (
						<Button variant='primary' onClick={handleParagraphEditModalSave}>
							Save Changes
						</Button>
					) : (
						<Button variant='primary' onClick={handleEditModalSave}>
							Save Changes
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default PrescriptionCreatorScreens;
