/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { editAdmin } from "../../../redux/adminRedux/adminApiCalls";
import Form from "react-bootstrap/Form";

const MyProfileScreenAdmin = () => {
  const dispatch = useDispatch();
  const { adminInfo } = useSelector((state) => state.adminLogin);
  const [editClicked, setEditClicked] = useState(false);
  const [name, setName] = useState(adminInfo?.user?.name);
  const [mno, setMno] = useState(adminInfo?.user?.mobilenumber);
  const [email, setEmail] = useState(adminInfo?.user?.email);
  const [dob, setDob] = useState(adminInfo?.user?.dob);
  const [gender, setGender] = useState(adminInfo?.user?.gender);
  const [address, setAddress] = useState(adminInfo?.user?.address);
  console.log(adminInfo);

  const submitHandler = () => {
    dispatch(
      editAdmin(name, mno, email, dob, gender, address, adminInfo?.user?._id)
    );
  };

  const discardChanges = () => {
    setEditClicked(false);
    setName(adminInfo?.user?.name);
    setMno(adminInfo?.user?.mobilenumber);
    setEmail(adminInfo?.user?.email);
    setDob(adminInfo?.user?.dob);
    setGender(adminInfo?.user?.gender);
    setAddress(adminInfo?.user?.address);
  };

  return (
    <>
      <Meta />

      <h3 className="header-center">About Me</h3>
      <button
        className="btn btn-outline-success offset-md-10"
        onClick={() => setEditClicked(true)}
      >
        <i className="fa fa-edit"></i>
        {"  "}Edit
      </button>
      <div className="card col-12 col-md-10 offset-md-1 mt-2 mb-3">
        <div class="card-body">
          <div className="card-text">
            <div className="row">
              <div className="col-4 col-md-2 cardHeading">Name:</div>
              {editClicked ? (
                <input
                  type="text"
                  className="col-8 form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled
                />
              ) : (
                <div className="col-8">{adminInfo?.user?.name}</div>
              )}
            </div>
            <div className="row">
              <div className="col-4 col-md-2 cardHeading">Mobile No:</div>
              {editClicked ? (
                <input
                  type="text"
                  className="col-8 form-control"
                  value={mno}
                  onChange={(e) => setMno(e.target.value)}
                />
              ) : (
                <div className="col-8">{adminInfo?.user?.mobilenumber}</div>
              )}
            </div>
            <div className="row">
              <div className="col-4 col-md-2 cardHeading">Email Id:</div>
              {editClicked ? (
                <input
                  type="text"
                  className="col-8 form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              ) : (
                <div className="col-8">{adminInfo?.user?.email}</div>
              )}
            </div>
            <div className="row">
              <div className="col-4 col-md-2 cardHeading">Date Of Birth:</div>
              {editClicked ? (
                <input
                  type="date"
                  className="col-8 form-control"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              ) : (
                <div className="col-8">{adminInfo?.user?.dob}</div>
              )}
            </div>
            <div className="row">
              <div className="col-4 col-md-2 cardHeading">Gender:</div>
              {editClicked ? (
                <div className="row">
                  <div className="col-6">
                    <Form.Check
                      onChange={() => {
                        setGender("Male");
                      }}
                      checked={gender === "Male"}
                      className="form-control"
                      inline
                      label="Male"
                      type="radio"
                      id={`inline-radio-1`}
                    />
                  </div>
                  <div className="col-6">
                    <Form.Check
                      onChange={() => {
                        setGender("Female");
                      }}
                      checked={gender === "Female"}
                      inline
                      label="Female"
                      className="form-control"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-8">{adminInfo?.user?.gender}</div>
              )}
            </div>
            <div className="row">
              <div className="col-4 col-md-2 cardHeading">Address:</div>
              {editClicked ? (
                <textarea
                  type="text"
                  className="col-8 form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              ) : (
                <div className="col-8">{adminInfo?.user?.address}</div>
              )}
            </div>
          </div>
        </div>
        {editClicked && (
          <div className="row">
            <button
              className="btn btn-danger col-4 offset-1 col-md-2 offset-md-4"
              onClick={discardChanges}
            >
              Discard Changes
            </button>
            <button
              className="btn btn-success col-4 col-md-2 offset-1"
              onClick={submitHandler}
            >
              Submit Changes
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default MyProfileScreenAdmin;
