/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getAllRecommendedWords = createSlice({
	name: "getAllRecommendedWords",
	initialState: {},
	reducers: {
		getAllRecommendedWordsStart: (state) => {
			state.loading = true;
			state.getAllRecommendedWords = [];
			state.error = false;
			state.success = false;
		},
		getAllRecommendedWordsSuccess: (state, action) => {
			state.loading = false;
			state.getAllRecommendedWords = action.payload;
			state.error = false;
			state.success = true;
		},
		getAllRecommendedWordsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getAllRecommendedWordsReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getAllRecommendedWordsStart,
	getAllRecommendedWordsSuccess,
	getAllRecommendedWordsFailure,
	getAllRecommendedWordsReset,
} = getAllRecommendedWords.actions;
export default getAllRecommendedWords.reducer;
