/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Meta from "../../../../Components/Meta.js";
import Button from "react-bootstrap/Button";
import { Col, Row, Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PieChart from "../../../../Components/PieChart.js";
import { getSuppliers } from "../../../../redux/inventoryRedux/inventoryApiCalls.js";
import { ResponsiveBar } from "@nivo/bar";

const VendorLevelStatistics = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getSuppliers());
	}, []);
	const getSuppliersDetails = useSelector((state) => state.getSuppliers);
	console.log(getSuppliersDetails);
	const [allVendorData, setallVendorData] = useState([]);
	const [vendorPieData, setVendorPieData] = useState([]);
	const [vendorName, setVendorName] = useState([]);
	useEffect(() => {
		let tempAllVendorData = [];
		getSuppliersDetails?.getsuppliers?.data?.data?.map((supplier, index) => {
			tempAllVendorData.push({
				Name: supplier.name,
				Purchase: supplier.Total_purchase_made,
				Sales: supplier.Total_sales_made_from_the_stock_purchased,
				Profit: supplier.Total_profit_generated,
				Discounts: supplier.Total_discounts_given,
				Gst: supplier.Total_gst_paid,
			});
		});
		let tempVendorPieData = [];
		if (tempAllVendorData.length > 0) {
			tempVendorPieData.push({
				id: "Purchase",
				label: "Purchase",
				value: tempAllVendorData[0].Purchase,
			});
			tempVendorPieData.push({
				id: "Sales",
				label: "Sales",
				value: tempAllVendorData[0].Sales,
			});
			tempVendorPieData.push({
				id: "Profit",
				label: "Profit",
				value: tempAllVendorData[0].Profit,
			});
			tempVendorPieData.push({
				id: "Discounts",
				label: "Discounts",
				value: tempAllVendorData[0].Discounts,
			});
			tempVendorPieData.push({
				id: "Gst",
				label: "Gst",
				value: tempAllVendorData[0].Gst,
			});
			setVendorName(tempAllVendorData[0].Name);
			setVendorPieData(tempVendorPieData);
		}
		setallVendorData(tempAllVendorData);
	}, [getSuppliersDetails?.loading]);

	const commonProps = {
		keys: ["Purchase", "Sales", "Profit", "Discounts", "Gst"],
		margin: { top: 50, right: 50, bottom: 50, left: 60 },
		padding: 0.3,
		groupMode: "stacked",
		margin: { top: 50, right: 130, bottom: 50, left: 100 },
		axisLeft: {
			tickSize: 5,
			tickPadding: 20,
			tickRotation: 0,
			legend: "Amount",
			legendPosition: "middle",
			legendOffset: -90,
		},
		axisBottom: {
			tickSize: 5,
			tickPawwdding: 5,
			tickRotation: 0,
			legend: "Vendors",
			legendPosition: "middle",
			legendOffset: 32,
		},
		colors: { scheme: "nivo" },
		enableLabel: true,
	};
	const handleVendorSelect = (eventKey) => {
		let tempVendorPieData = [];
		setVendorName(eventKey);
		allVendorData?.map((supplier, index) => {
			if (supplier?.Name === eventKey) {
				tempVendorPieData.push({
					id: "Purchase",
					label: "Purchase",
					value: supplier.Purchase,
				});
				tempVendorPieData.push({
					id: "Sales",
					label: "Sales",
					value: supplier.Sales,
				});
				tempVendorPieData.push({
					id: "Profit",
					label: "Profit",
					value: supplier.Profit,
				});
				tempVendorPieData.push({
					id: "Discounts",
					label: "Discounts",
					value: supplier.Discounts,
				});
				tempVendorPieData.push({
					id: "Gst",
					label: "Gst",
					value: supplier.Gst,
				});
			}
		});
		setVendorPieData(tempVendorPieData);
	};

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href='/admin/AllPharmacySales'>
					Pharmacy
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/VendorLevelStatistics'>
					Vendor Level
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/CategoryLevelStatistics'>
					Category Level
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/todaysinventorystatistics'>
					Today's Pharmacy
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className='header-center mt-3 mb-3'>Vendor Level Statistics</h1>
			<Container className='header-center mt-3'>
				<DropdownButton
					key='down-centered'
					id={`dropdown-button-drop-down-centered`}
					drop='down-centered'
					variant='success'
					title={vendorName}
					onSelect={handleVendorSelect}>
					{allVendorData?.map((supplier, index) => (
						<Dropdown.Item eventKey={supplier.Name}>
							{supplier.Name}
						</Dropdown.Item>
					))}
				</DropdownButton>
				<PieChart data={vendorPieData} />
			</Container>
			<div className='container' style={{ height: "500px" }}>
				<ResponsiveBar
					data={allVendorData}
					indexBy='Name'
					{...commonProps}
					// layout="horizontal"
				/>
			</div>
		</>
	);
};
export default VendorLevelStatistics;
