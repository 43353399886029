/** @format */

import { createSlice } from "@reduxjs/toolkit";

const saveIpBillReducer = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    saveIpBillStart: (state) => {
      state.loading = true;
    },
    saveIpBillSuccess: (state, action) => {
      state.loading = false;
      state.saveIpBillDetails = action.payload;
      state.error = false;
      state.success = true;
    },
    saveIpBillFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    saveIpBillReset: (state, action) => {
      state.success = false;
      state.error = false;
    },
  },
});

export const {
  saveIpBillStart,
  saveIpBillSuccess,
  saveIpBillFailure,
  saveIpBillReset,
} = saveIpBillReducer.actions;
export default saveIpBillReducer.reducer;
