/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getLabStatistics = createSlice({
	name: "getLabStatistics",
	initialState: {},
	reducers: {
		getLabStatisticsRequest: (state) => {
			state.loading = true;
		},
		getLabStatisticsSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getLabstatistics = action.payload;
			state.error = false;
			state.success = true;
		},
		getLabStatisticsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getLabStatisticsReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getLabStatisticsRequest,
	getLabStatisticsSuccess,
	getLabStatisticsFailure,
	getLabStatisticsReset,
} = getLabStatistics.actions;
export default getLabStatistics.reducer;
