/** @format */

import { createSlice } from "@reduxjs/toolkit";

const createIpBillReducer = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    createIpBillStart: (state) => {
      state.loading = true;
    },
    createIpBillSuccess: (state, action) => {
      state.loading = false;
      state.createIpBillDetails = action.payload;
      state.error = false;
      state.success = true;
    },
    createIpBillFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createIpBillReset: (state, action) => {
      state.success = false;
      state.error = false;
    },
  },
});

export const {
  createIpBillStart,
  createIpBillSuccess,
  createIpBillFailure,
  createIpBillReset,
} = createIpBillReducer.actions;
export default createIpBillReducer.reducer;
