/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../redux/allMessages";
import Message from "./Message";

const AlertMessageBar = () => {
	const dispatch = useDispatch();
	const alertmsg = useSelector((state) => state.allMessages);
	const { loading, success, alertMsg, error } = alertmsg;
	const [timerRunning, setTimerRunning] = useState(false);
	const [showPopup, setShowPopup] = React.useState(false);
	const [showPopup1, setShowPopup1] = React.useState(false);
	console.log(success);
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	console.log(doctorInfo);
	const [myProfile, setMyProfile] = useState(false);
	useEffect(() => {
		// If there's an alert message and the timer isn't running, start the timer
		if (success && !timerRunning) {
			setTimerRunning(true);
			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(allMessagesReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [success, dispatch, timerRunning]);
	console.log(success);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log("error");
		dispatch(allMessagesReset());
	};

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup(!showPopup1);
		e.preventDefault();
		console.log("error");
		dispatch(allMessagesReset());
	};
	useEffect(() => {
		if (
			doctorInfo?.data?.user?.address === "" ||
			doctorInfo?.data?.user?.dob === "" ||
			doctorInfo?.data?.user?.opConsultationFee === 0 ||
			doctorInfo?.data?.user?.aboutMe?.designation === "" ||
			doctorInfo?.data?.user?.aboutMe?.education === "" ||
			doctorInfo?.data?.user?.aboutMe?.personality === "" ||
			doctorInfo?.data?.user?.aboutMe?.experience === ""
		) {
			setMyProfile(false);
		} else {
			setMyProfile(true);
		}
	}, [doctorInfo]);
	const [editMySlots, setEditMySlots] = useState(false);
	useEffect(() => {
		let flag = 1;
		let count = 0;
		for (let i = 0; i < doctorInfo?.data?.user?.days?.length; i++) {
			if (doctorInfo?.data?.user?.days[i]?.isWorking) {
				count++;
			}
		}
		for (let i = 0; i < doctorInfo?.data?.user?.days?.length; i++) {
			if (doctorInfo?.data?.user?.days[i]?.isWorking) {
				if (
					doctorInfo?.data?.user?.days[i]?.breakTime === "" ||
					doctorInfo?.data?.user?.days[i]?.startTime === "" ||
					doctorInfo?.data?.user?.days[i]?.endTime === "" ||
					doctorInfo?.data?.user?.days[i]?.maxAppointmentsperhour === 0
				) {
					flag = 0;
				}
			}
		}
		if (flag === 1 && count > 0) {
			setEditMySlots(true);
		}
		console.log(editMySlots);
	}, [doctorInfo]);

	return (
		<div>
			{success && (
				<Message variant='success'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{alertMsg}
				</Message>
			)}
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup1}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{alertMsg}
				</Message>
			)}
			{window.location.pathname?.includes("/doctor/") &&
				!myProfile &&
				doctorInfo && (
					<Message variant='danger'>
						{"Please navigate to "}{" "}
						<a href='/doctor/SlotManagement/editBookingPeriod'>
							Edit Booking Period
						</a>{" "}
						{" and update the required fields."}
					</Message>
				)}
			{window.location.pathname?.includes("/doctor/") &&
				!editMySlots &&
				doctorInfo && (
					<Message variant='danger'>
						{"Please navigate to "}{" "}
						<a href='/doctor/SlotManagement/editMySlots'>Edit My Slots</a>{" "}
						{" and update the required fields."}
					</Message>
				)}
		</div>
	);
};

export default AlertMessageBar;
