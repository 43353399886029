/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createLabDept = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createLabDeptStart: (state) => {
			state.loading = true;
		},
		createLabDeptSuccess: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		createLabDeptFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createLabDeptReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createLabDeptStart,
	createLabDeptSuccess,
	createLabDeptFailure,
	createLabDeptReset,
} = createLabDept.actions;
export default createLabDept.reducer;
