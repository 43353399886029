import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "../styles/aboutus.css";
import Meta from "../Components/Meta";
const AboutUs = () => {
	return (
		<>
			{/* Welcome to [Hospital Name] – Where Compassion Meets Excellence At
			[Hospital Name], we redefine healthcare by placing your well-being at the
			core of our mission. With a legacy of excellence and a commitment to
			compassionate care, we are dedicated to providing exceptional medical
			services tailored to meet your unique needs. Our Specialized Services (X)
			At the heart of [Hospital Name] lies a commitment to excellence in
			specialized healthcare services. We offer a comprehensive range of
			specialties to address the diverse health needs of our community. From
			cutting-edge diagnostics to advanced treatment modalities, our expert
			teams ensure that you receive the highest standard of care. X: Specialties
			Offered Our hospital proudly boasts a diverse array of specialties,
			ensuring that we can address a wide spectrum of medical conditions.
			Whether you require routine check-ups or complex medical interventions,
			our dedicated specialists are here for you. Our specialties include but
			are not limited to [List of Specialties]. Meet Our Expert Team (Y) The
			backbone of [Hospital Name] is our team of highly qualified and
			experienced healthcare professionals. Committed to providing personalized
			care, our doctors, nurses, and support staff work collaboratively to
			ensure your journey to recovery is smooth and comfortable. Y: Doctors and
			Healthcare Professionals Our team comprises renowned physicians, surgeons,
			and healthcare professionals who bring a wealth of experience to the
			table. With a commitment to staying abreast of the latest medical
			advancements, our specialists are well-equipped to provide you with the
			best possible care. Learn more about our exceptional team on the "Meet Our
			Doctors" page. Experience the [Hospital Name] Difference (Z) [Z: Years of
			Experience] With over [Z] years of experience, [Hospital Name] has become
			a trusted healthcare institution in [Location]. Our commitment to
			excellence, patient-centric approach, and state-of-the-art facilities set
			us apart. We continuously strive to elevate the standard of healthcare in
			the region, ensuring that you receive nothing but the best. In addition to
			our commitment to clinical excellence, we prioritize the comfort and
			well-being of our patients. Our modern facilities are designed to create a
			healing environment, promoting a sense of calm and positivity throughout
			your healthcare journey. Your Journey to Wellness Starts Here At [Hospital
			Name], we believe that healthcare is not just a service – it's a
			relationship built on trust and care. From the moment you walk through our
			doors, you become a part of our extended family, and we are dedicated to
			supporting you on your journey to wellness. Explore our website to learn
			more about our services, meet our team, and discover why [Hospital Name]
			is the preferred choice for healthcare in [Location]. Your health is our
			priority, and we look forward to serving you with excellence and
			compassion. Choose [Hospital Name] – Your Partner in Health. */}
			<Meta />
			<Container>
				<div className="aboutus mt-5">
					<Row className="mt-4">
						<Col xs={12} md={6}>
							<h1 className="text-dark">
								Welcome to AMH - Your Trusted Medical Partner
							</h1>
							<p className="mt-4">
								Unfortunately, About Us pages are too often treated as an
								obligation rather than the valuable opportunity they are to
								connect with your customers by selling your story, your vision,
								your mission, and what makes you, YOU.
							</p>
							<button className="btn btn-primary">View All Services</button>
						</Col>
						<Col xs={12} md={6}>
							<img
								className="img-fluid"
								style={{ borderRadius: "6px" }}
								src="/hospital-CRM.jpg"
								alt="image"
							/>
						</Col>
					</Row>
					<div className="services">
						<h2>Our Services</h2>
						<Row>
							<Col xs={12} sm={6} md={4}>
								<Card style={{ width: "18rem" }}>
									<Card.Img variant="top" src="/doctors4.webp" />
									<Card.Body>
										<Card.Title>Lab Testing</Card.Title>
										<Card.Text>
											Some quick example text to build on the card title and
											make up the bulk of the card's content.
										</Card.Text>
										<Button variant="primary">Go somewhere</Button>
									</Card.Body>
								</Card>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Card style={{ width: "18rem" }}>
									<Card.Img variant="top" src="/doctors4.webp" />
									<Card.Body>
										<Card.Title>Doctor Directory</Card.Title>
										<Card.Text>
											Some quick example text to build on the card title and
											make up the bulk of the card's content.
										</Card.Text>
										<Button variant="primary">Go somewhere</Button>
									</Card.Body>
								</Card>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Card style={{ width: "18rem" }}>
									<Card.Img variant="top" src="/doctors4.webp" />
									<Card.Body>
										<Card.Title>Image Gallery</Card.Title>
										<Card.Text>
											Some quick example text to build on the card title and
											make up the bulk of the card's content.
										</Card.Text>
										<Button variant="primary">Go somewhere</Button>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</div>
					<div className="featuresandBene bg-info">
						<h2 className="homeheading">Features and Benefits</h2>
						<Row>
							<Col xs={12} md={4}>
								<h4 className="offset-md-3">Features</h4>
								<ul className="featlist">
									<li>State Of the Art Medical Facilities</li>
									<li>Experienced and Skilled Doctors</li>
									<li>Caring and Professional Support Staff</li>
									<li>Comprehensive Lab Testing Services</li>
									<li>On Going Support</li>
								</ul>
							</Col>
							<Col xs={12} md={4}>
								<h4 className="offset-md-3">Benefits</h4>
								<ul className="benlist">
									<li>Top-Quality Medical Care</li>
									<li>Peace Of Mind For Patients and Families</li>
									<li>Convenient and Comprehensive Lab Testing</li>
									<li>Knowledgeable and Friendly Doctors</li>
									<li>Sustained Partnership</li>
								</ul>
							</Col>
							<Col xs={12} md={4}>
								<img
									className="img-fluid"
									style={{ borderRadius: "6px" }}
									src="/best.jpg"
									alt="image"
								/>
							</Col>
						</Row>
					</div>
					<div className="doctorhead">
						<h2 className="doc-1">Our Doctors</h2>
						<div className="slider container">
							<div className="slide-tracker">
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
							</div>
							<div className="slide-tracker">
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
								<div className="card">
									<img src="/happy.jpg" alt="img" />
									<p>hi akshay</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

export default AboutUs;
