/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createCaseInPatient = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createCaseInPatientStart: (state) => {
			state.loading = true;
		},
		createCaseInPatientSuccess: (state, action) => {
			state.loading = false;
			state.createCaseInPatient = action.payload;
			state.error = false;
			state.success = true;
		},
		createCaseInPatientFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createCaseInPatientReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createCaseInPatientStart,
	createCaseInPatientSuccess,
	createCaseInPatientFailure,
	createCaseInPatientReset,
} = createCaseInPatient.actions;
export default createCaseInPatient.reducer;
