/** @format */

import { createSlice } from "@reduxjs/toolkit";

const GetrecommendationData = createSlice({
	name: "GetrecommendationData",
	initialState: {},
	reducers: {
		GetrecommendationDataStart: (state) => {
			state.loading = true;
			state.getrecommendationData = [];
			state.error = false;
			state.success = false;
		},
		GetrecommendationDataSuccess: (state, action) => {
			state.loading = false;
			state.getrecommendationData = action.payload;
			state.error = false;
			state.success = true;
		},
		GetrecommendationDataFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		GetrecommendationDataReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	GetrecommendationDataStart,
	GetrecommendationDataSuccess,
	GetrecommendationDataFailure,
	GetrecommendationDataReset,
} = GetrecommendationData.actions;
export default GetrecommendationData.reducer;
