/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../../../../Components/Errortemplate.js";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "../../../../Components/Loader.js";
import Meta from "../../../../Components/Meta.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Col, Row, Container } from "react-bootstrap";
import {
	getStatisticsofParticularTest,
	getDepartmentandTestStatistics,
} from "../../../../redux/statisticsRedux/statisticApiCalls.js";
import { getSpecialities } from "../../../../redux/userApiCalls.js";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PieChart from "../../../../Components/PieChart.js";
import BarChart from "../../../../Components/Barchart.js";
import LineGraph from "../../../../Components/LineGraph.js";
import { getLabDepts } from "../../../../redux/adminRedux/adminApiCalls.js";

const TestStatisticsScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	//success and failure messages

	const { getlabDeptAll, loading } = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	useEffect(() => {
		dispatch(getLabDepts());
	}, []);

	const [deptName1, setDeptName1] = useState("Choose Department");
	const [deptId1, setDeptId1] = useState("");
	const [deptInd, setDeptInd] = useState();

	useEffect(() => {
		for (let i = 0; i < getlabDeptAll?.data?.data?.length; i++) {
			if (getlabDeptAll?.data?.data[i].deptName !== "hospital") {
				setDeptName1(getlabDeptAll?.data?.data[i]?.deptName);
				setDeptId1(getlabDeptAll?.data?.data[i]?._id);
				dispatch(
					getDepartmentandTestStatistics(getlabDeptAll?.data?.data[i]?._id)
				);
				setDeptInd(i);

				setDeptName(getlabDeptAll?.data?.data[i]?.deptName);
				setDeptId(getlabDeptAll?.data?.data[i]?._id);
				setdeptTestNames(getlabDeptAll?.data?.data[i].testDetails);
				setTestName(getlabDeptAll?.data?.data[i].testDetails[0]?.testName);
				setTestIdx(0);
				dispatch(
					getStatisticsofParticularTest(
						getlabDeptAll?.data?.data[i]?._id,
						0,
						2024
					)
				);
				break;
			}
		}
	}, [loading]);

	const handleDeptSelect1 = (eventKey) => {
		let arr = eventKey?.split("@@");
		if (arr[3]?.length <= 0) {
			alert(`No tests are available in ${arr[0]}`);
		} else {
			setDeptName1(arr[0]);
			setDeptId1(arr[1]);
			dispatch(getDepartmentandTestStatistics(arr[1]));
			setDeptInd(arr[2]);
		}
	};

	const getDepartmentandteststatistics = useSelector(
		(state) => state.getDepartmentandTestStatistics
	);
	const getDepartmentandTeststatistics =
		getDepartmentandteststatistics?.getDepartmentandTeststatistics;
	const getDepartmentandTeststatisticsLoading =
		getDepartmentandteststatistics?.loading;

	console.log(getDepartmentandTeststatistics);

	const [last30DaysTestPie, setLast30DaysTestPie] = useState([]);
	const [last12MonsTestPie, setLast12MonsTestPie] = useState([]);
	const [activeTestPie, setActiveTestPie] = useState("30days");

	useEffect(() => {
		let days30DataTestPie = [];
		let mons12DataTestPie = [];
		getDepartmentandTeststatistics?.data?.testInDepartmentInfo?.testDetails?.map(
			(test, index) => {
				let totalAmountForDept30Days = 0;
				let totalAmountForDept12Mons = 0;
				test?.last_thirtydays_test?.map((day, index) => {
					totalAmountForDept30Days += day?.patientsRevenueCash_test;
					totalAmountForDept30Days += day?.patientsRevenueOnline_test;
				});
				test?.last_twelve_months_test?.map((mon, index) => {
					totalAmountForDept12Mons += mon?.patientsRevenueCash_test;
					totalAmountForDept12Mons += mon?.patientsRevenueOnline_test;
				});
				days30DataTestPie?.push({
					id: test?.testName,
					label: test?.testName,
					value: totalAmountForDept30Days,
				});
				mons12DataTestPie?.push({
					id: test?.testName,
					label: test?.testName,
					value: totalAmountForDept12Mons,
				});
				setLast30DaysTestPie(days30DataTestPie);
				setLast12MonsTestPie(mons12DataTestPie);
			}
		);
	}, [getDepartmentandTeststatisticsLoading]);

	console.log(last30DaysTestPie);
	console.log(last12MonsTestPie);

	const [deptName, setDeptName] = useState("Choose Department");
	const [deptId, setDeptId] = useState("");
	const [testName, setTestName] = useState("Choose Test");
	const [testIdx, setTestIdx] = useState();
	const [deptTestNames, setdeptTestNames] = useState([]);
	console.log(JSON.stringify(getlabDeptAll) + "line66");

	const handleDeptSelect = (eventKey) => {
		let arr = eventKey?.split("@@");
		if (arr[3]?.length <= 0) {
			alert(`No tests are available in ${arr[0]}`);
		} else {
			setDeptName(arr[0]);
			setDeptId(arr[1]);
			setTestName("Choose Test");
			setdeptTestNames(
				getlabDeptAll?.data?.data[eventKey.split("@@")[2]].testDetails
			);
		}
	};
	const handleTestSelect = (eventKey) => {
		let arr = eventKey?.split("@@");
		setTestName(arr[0]);
		setTestIdx(arr[1]);
		dispatch(getStatisticsofParticularTest(deptId, arr[1], 2024));
	};

	const getStatisticsofparticulartest = useSelector(
		(state) => state.getStatisticsofParticularTest
	);
	const getStatisticsofParticulartest =
		getStatisticsofparticulartest?.getStatisticsofParticulartest;
	const getStatisticsofParticulartestLoading =
		getStatisticsofparticulartest?.loading;

	console.log(getStatisticsofParticulartest);

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	const [last30DaysTestBar, setLast30DaysTestBar] = useState([]);
	const [last12MonsTestBar, setLast12MonsTestBar] = useState([]);
	const [activeTestBar, setActiveTestBar] = useState("30days");
	useEffect(() => {
		let days30DataTestBar = [];
		let mons12DataTestBar = [];
		getStatisticsofParticulartest?.data?.data?.testDetails[
			testIdx
		]?.last_thirtydays_test?.map((day, index) => {
			let datearr = day?.date?.toString()?.split("");
			let date =
				datearr[6] +
				datearr[7] +
				"-" +
				datearr[4] +
				datearr[5] +
				"-" +
				datearr[0] +
				datearr[1] +
				datearr[2] +
				datearr[3];
			days30DataTestBar?.push({
				Amount: day?.patientsRevenueCash_test + day?.patientsRevenueOnline_test,
				category: date,
			});
		});
		getStatisticsofParticulartest?.data?.data?.testDetails[
			testIdx
		]?.last_twelve_months_test?.map((mon, index) => {
			mons12DataTestBar?.push({
				category: months[mon?.monthNumber - 1],
				Amount: mon?.patientsRevenueCash_test + mon?.patientsRevenueOnline_test,
			});
		});
		setLast30DaysTestBar(days30DataTestBar);
		setLast12MonsTestBar(mons12DataTestBar);
	}, [getStatisticsofParticulartestLoading]);

	console.log(last30DaysTestBar);

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href='/admin/labstatistics'>Total Lab</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/deptstatistics'>
					Department
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/teststatistics'>Test</Breadcrumb.Item>
				<Breadcrumb.Item href='/admin/todayslabpatients'>
					Today's lab patients
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className='header-center'>Test Revenue</h1>
			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={deptName1}
							onSelect={handleDeptSelect1}>
							<div className='dropButton'>
								{getlabDeptAll?.data?.data?.length === 0 ? (
									<Dropdown.Item>
										{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
									</Dropdown.Item>
								) : (
									getlabDeptAll?.data?.data?.map(
										(dept, index) =>
											dept.deptName !== "hospital" &&
											dept.status !== 0 && (
												<Dropdown.Item
													eventKey={
														dept.deptName +
														"@@" +
														dept._id +
														"@@" +
														index +
														"@@" +
														dept?.testDetails
													}>
													{dept.deptName}
												</Dropdown.Item>
											)
									)
								)}
							</div>
						</DropdownButton>
					</Col>

					<Col md='auto' className='text-center'>
						<button
							className='btn btn-info m-1'
							onClick={() => setActiveTestPie("30days")}>
							Last 30 days
						</button>
					</Col>
					<Col md='auto' className='text-center'>
						<button
							className='btn btn-info m-1'
							onClick={() => setActiveTestPie("12mons")}>
							Last 12 Months
						</button>
					</Col>
				</Row>
				{deptName1 !== "Choose Department" && activeTestPie === "30days" && (
					<PieChart data={last30DaysTestPie} />
				)}
				{deptName1 !== "Choose Department" && activeTestPie === "12mons" && (
					<PieChart data={last12MonsTestPie} />
				)}
			</Container>
			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={deptName}
							onSelect={handleDeptSelect}>
							<div className='dropButton'>
								{getlabDeptAll?.data?.data?.length === 0 ? (
									<Dropdown.Item>
										{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
									</Dropdown.Item>
								) : (
									getlabDeptAll?.data?.data?.map(
										(dept, index) =>
											dept.deptName !== "hospital" &&
											dept.status !== 0 && (
												<Dropdown.Item
													eventKey={
														dept.deptName +
														"@@" +
														dept._id +
														"@@" +
														index +
														"@@" +
														dept?.testDetails
													}>
													{dept.deptName}
												</Dropdown.Item>
											)
									)
								)}
							</div>
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={testName}
							onSelect={handleTestSelect}>
							{deptTestNames?.length === 0 ? (
								<Dropdown.Item>
									{/* {getSpecialityAllloading && (
										<Spinner animation='border' role='status'></Spinner>
									)} */}
								</Dropdown.Item>
							) : (
								deptTestNames?.map(
									(test, index) =>
										test.status !== 0 && (
											<Dropdown.Item eventKey={test.testName + "@@" + index}>
												{test.testName}
											</Dropdown.Item>
										)
								)
							)}
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center'>
						<button
							className='btn btn-info m-1'
							onClick={() => setActiveTestBar("30days")}>
							Last 30 days
						</button>
					</Col>
					<Col md='auto' className='text-center'>
						<button
							className='btn btn-info m-1'
							onClick={() => setActiveTestBar("12mons")}>
							Last 12 Months
						</button>
					</Col>
				</Row>
				{activeTestBar === "30days" &&
					deptName !== "Choose Department" &&
					testName !== "Choose Test" && <BarChart data={last30DaysTestBar} />}
				{activeTestBar === "12mons" &&
					deptName !== "Choose Department" &&
					testName !== "Choose Test" && <BarChart data={last12MonsTestBar} />}
			</Container>
		</>
	);
};

export default TestStatisticsScreens;
