/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createCategory = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createCategoryStart: (state) => {
			state.loading = true;
		},
		createCategorySuccess: (state, action) => {
			state.loading = false;
			state.createCategoryData = action.payload;
			state.error = false;
			state.success = true;
		},
		createCategoryFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createCategoryReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createCategoryStart,
	createCategorySuccess,
	createCategoryFailure,
	createCategoryReset,
} = createCategory.actions;
export default createCategory.reducer;
