/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../../Components/Errortemplate";
// import { Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import {
	updateUserProfileByAdmin, //
	getSpecialities, //
	getTimeslots, //
} from "../../redux/userApiCalls";
import { Col, Row, Container } from "react-bootstrap";
import {
	getUserDataByCategory, //
	adminCreateBooking, //
} from "../../redux/adminRedux/adminApiCalls";
import Message from "../../Components/Message";
// import {bookingListScreen} from "../redux/userApiCalls";
import { getUserDataByCategoryReset } from "../../redux/adminRedux/Patient_Management/getUserDataByCategory";
import { userUpdateProfileReset } from "../../redux/userUpdate";
import { adminCreateBookingReset } from "../../redux/adminRedux/Booking_Management/adminCreateBooking";
import { specialityGetReset } from "../../redux/getSpecialities";
import { TimeSlotGetReset } from "../../redux/getTimeSlots";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";

const OnSpotBookingScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	//success and failure messages
	const data1 = useSelector((state) => state.getUserdataByCategory);
	const userData = data1.userData;
	const { error, loading, success } = data1;

	const getSpecialityAllData = useSelector((state) => state.getSpeciality);
	const getSpecialityAllsuccess = getSpecialityAllData.success;
	const getSpecialityAllerror = getSpecialityAllData.error;
	const getSpecialityAllloading = getSpecialityAllData.loading;

	const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
	const doctorAppointmentDates =
		getTimeSlotsData?.allTimeSlots?.appointmentDates;

	const getTimeSlotssuccess = getTimeSlotsData.success;
	const getTimeSlotserror = getTimeSlotsData.error;
	const getTimeSlotsloading = getTimeSlotsData.loading;

	console.log(getTimeSlotsData);
	const [dateTimeSlots, setDateTimeSlots] = useState([]);
	const adduserinfo = useSelector((state) => state.userUpdate);
	const adduserinfoerror = adduserinfo.error;
	const adduserinfosuccess = adduserinfo.success;
	const adduserinfoloading = adduserinfo.loading;

	const bookingallinfo = useSelector((state) => state.adminCreateBooking);
	const bookingerror = bookingallinfo.error;
	const createdbookingloading = bookingallinfo.loading;
	const bookinguserInfo = bookingallinfo.userInfo;
	const bookingsuccess = bookingallinfo.success;

	const [DoctorFromSpecialityArray, setDoctorFromSpecialityArray] = useState(
		[]
	);

	const [dayIdx, setdayIdx] = useState(-1);
	const [dateOptionNumeric, setDateOptionNumeric] = useState("Choose Date");

	const [bloodGroup, setBloodGroup] = useState("Select a blood group");
	const handleBloodGroup = (eventKey) => {
		setBloodGroup(eventKey);
	};
	const [lgShow, setLgShow] = useState(false);
	const [mno, setMno] = useState("");
	const handleSearchPatient = (e) => {
		e.preventDefault();
		setUserOption("Select a user");
		setUserIdx("");
		setSpecialityOption("Choose Speciality");
		setDoctorOption("Choose Doctor");
		setDateOption("Choose Date");
		setDateSelected(false);
		setDoctorSelected(false);
		setTextVis("d-none offset-md-3 mt-3 text-danger");
		dispatch(getUserDataByCategory(mno));
	};

	const [click, setClick] = useState(0);
	console.log(userData);
	const blood_groups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-", "NA"];
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [dob, setdob] = useState(new Date());

	const [
		patientPreviousBookingDoctorNames,
		setpatientPreviousBookingDoctorNames,
	] = useState({});
	function arrayToDictionary(arr) {
		let dictionary = {};
		for (let i = 0; i < arr.length; i++) {
			dictionary[arr[i]] = true; // Use array element as key
		}
		return dictionary;
	}

	function isElementPresent(dictionary, element) {
		return !!dictionary[element]; // Check if key exists
	}

	var days = [];
	const formattedDates = doctorAppointmentDates?.map((entry) => {
		const dateString = entry.date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ("0" + dateString.slice(6, 8)).slice(-2);

		// Create the formatted date string

		days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
	});
	console.log(days);
	const addUserOnSubmit = (e) => {
		e.preventDefault();
		console.log(name + " " + gender + " " + dob + " " + mno);
		var letters = /^[A-Za-z\s]+$/;
		if (name === "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (gender === "") {
			alert("Please select gender, gender cannot be empty");
		} else if (dob === "") {
			alert("Please select dob, dob cannot be empty");
		} else if (new Date(dob) >= new Date(new Date())) {
			alert("Please select proper dob, dob should not exceed todays date");
		} else {
			if (bloodGroup == "Select a blood group") {
				dispatch(
					updateUserProfileByAdmin(name.trim(), gender, dob, mno, "NA", "")
				);
			} else {
				dispatch(
					updateUserProfileByAdmin(
						name.trim(),
						gender,
						dob,
						mno,
						bloodGroup,
						""
					)
				);
			}
			setName("");
			setGender("");
			setdob("");
			setClick(1);
			setBloodGroup("Select a blood group");
		}
	};
	const users_array = [];
	users_array.push({
		name: userData?.data?.data?.name,
		id: userData?.data?.data?.bookingId,
		doctorBookings: userData?.data?.data?.bookingDoctorsArray,
	});
	for (
		let i = 0;
		i < userData?.data?.data?.registered_patients_array?.length;
		i++
	) {
		users_array.push({
			name: userData?.data?.data?.registered_patients_array[i]?.name,
			id: userData?.data?.data?.registered_patients_array[i]?.bookingId,
			doctorBookings:
				userData?.data?.data?.registered_patients_array[i]?.bookingDoctorsArray,
		});
	}
	console.log(users_array);
	const [userOption, setUserOption] = useState("Select a user");
	const [userIdx, setUserIdx] = useState("");
	const [doctorSelected, setDoctorSelected] = useState(false);
	let flag = false;
	const [textVis, setTextVis] = useState("d-none offset-md-3 mt-3 text-danger");
	const handleUserSelect = (eventKey) => {
		setTextVis("d-none offset-md-3 mt-3 text-danger");
		setUserOption(eventKey?.split("@@")[0]);
		setUserIdx(eventKey?.split("@@")[1]);
		flag = false;
		for (let i = 0; i < users_array.length; i++) {
			if (
				eventKey?.split("@@")[0] === users_array[i].name &&
				users_array[i].id !== ""
			) {
				setpatientPreviousBookingDoctorNames(
					arrayToDictionary(users_array[i].doctorBookings)
				);
				console.log(
					patientPreviousBookingDoctorNames +
						"line1111111111111111111111111111111"
				);
				flag = true;
				break;
			}
		}
		// if (flag) {
		// 	setTextVis("d-block offset-md-3 mt-3 text-danger");
		// }
		setSpecialityOption("Choose Specialty");
		setSpecialityId("");
		setDoctorOption("Choose Doctor");
		setDateOption("Choose Date");
		setDateSelected(false);
		setSlotOption();
		setSlotCount(0);
		setDoctorSelected(false);
	};
	const { getSpecialityAll } = useSelector((state) => state.getSpeciality);
	let allSpecialities = [];
	if (getSpecialityAll) {
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}

	console.log(allSpecialities);

	const [specialityOption, setSpecialityOption] = useState("Choose Specialty");
	const [specialityId, setSpecialityId] = useState("");
	const handleSpecialitySelect = (eventKey) => {
		if (userOption === "Select a user") {
			alert("User needs to be selected first, Please select user");
		} else {
			let arr = [];
			arr = eventKey.split("/");
			setSpecialityOption(arr[0]);
			setSpecialityId(arr[1]);
			setDoctorFromSpecialityArray(
				getSpecialityAll.data.data[arr[2]].doctorsArray
			);
			setDoctorOption("Choose Doctor");
			setDateOption("Choose Date");
			setDateSelected(false);
			setSlotOption();
			setSlotCount(0);
			setDoctorSelected(false);
		}
	};
	// const currDate = new Date(); // Get current date
	// const date = currDate.getDate();
	// const month = currDate.getMonth() + 1; // Month index starts from 0
	// const year = currDate.getFullYear();
	// const dateOption = date + " / " + month + " / " + year;
	let allDoctors = [];
	const [doctorOption, setDoctorOption] = useState("Choose Doctor");
	const [doctorId, setDoctorId] = useState("");
	const handleDoctorSelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");

		if (userOption === "Select a user") {
			alert("User needs to be selected first, Please select user");
		} else if (specialityOption === "Choose Specialty") {
			alert("Speciality needs to be selected first, Please select Speciality");
		} else if (isElementPresent(patientPreviousBookingDoctorNames, arr[0])) {
			alert("A booking already exists with this doctor");
		} else {
			setDoctorId(arr[1]);
			setDoctorOption(arr[0]);
			console.log(arr);
			console.log(allDoctors);
			setSlotOption();
			setSlotCount(0);
			setDoctorSelected(true);
			setDateOption("Choose Date");
			setDateSelected(false);
			dispatch(getTimeslots(arr[1]));
		}
	};

	console.log(allDoctors);

	const [slotOption, setSlotOption] = useState();
	const [slotCount, setSlotCount] = useState(0);
	const handleSlotSelect = (slot, slotcount) => {
		setSlotOption(slot);
		setSlotCount(slotcount + 1);
	};

	const submitHandler = async () => {
		if (userOption === "Select a user") {
			alert("User needs to be selected first, Please select user");
		} else if (specialityOption === "Choose Specialty") {
			alert("Speciality needs to be selected first, Please select Speciality");
		} else if (doctorOption === "Choose Doctor") {
			alert("Doctor needs to be selected first, Please select Doctor");
		} else if (dateOption == "Choose Date") {
			alert("Date needs to be selected first, Please select Date");
		} else if (slotOption === undefined) {
			alert("Please select a vaild slot");
		} else {
			console.log(
				userOption, //
				dateOptionNumeric,
				slotOption,
				slotCount,
				mno,
				doctorOption,
				doctorId,
				specialityOption,
				specialityId,
				userData?.data?.data?._id, //
				dayIdx,
				userIdx
			);
			dispatch(
				adminCreateBooking(
					userOption, //
					dateOptionNumeric,
					slotOption,
					slotCount,
					mno,
					doctorOption,
					doctorId,
					specialityOption,
					specialityId,
					userData?.data?.data?._id, //
					Number(dayIdx),
					Number(userIdx)
				)
			);
			setUserOption("Select a user");
			setUserIdx("");
			setSpecialityOption("Choose Specialty");
			setDoctorOption("Choose Doctor");
			setSpecialityId("");
			setDoctorId("");
			setSlotOption();
			setSlotCount(0);
			setDoctorSelected(false);
			setDateSelected(false);
			setDateOption("Choose Date");
		}
	};

	const [dateOption, setDateOption] = useState("Choose Date");
	const [dateSelected, setDateSelected] = useState(false);
	const handleDateSelect = (eventKey) => {
		if (userOption == "Select a user") {
			alert("User needs to be selected first, Please select user");
		} else {
			const dateString = doctorAppointmentDates[eventKey].date.toString();

			// Extract year, month, and day
			const year = dateString.slice(0, 4);
			const month = dateString.slice(4, 6);
			const day = ("0" + dateString.slice(6, 8)).slice(-2);
			setdayIdx(eventKey);
			// Create the formatted date string
			setDateOptionNumeric(doctorAppointmentDates[eventKey].date);
			//days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
			setDateOption(`${day}-${month}-${year}`);
			setDateSelected(true);
			setDateTimeSlots(doctorAppointmentDates[eventKey].timeSlots);
			setSlotOption();
			//settimeSlots(doctorAppointmentDates[eventKey].timeSlots);
		}
	};

	useEffect(() => {
		//dispatch(getSpecialities());
		if (mno !== "") dispatch(getUserDataByCategory(mno));
		// if(mno !== "") {
		//   dispatch(getUserDataByCategory(mno));
		// }
	}, [
		history,
		dispatch,
		click,
		adduserinfosuccess,
		adduserinfoerror,
		bookingsuccess,
		bookingerror,
	]);
	useEffect(() => {
		dispatch(getSpecialities());
		// if(mno !== "")
		// dispatch(getUserDataByCategory(mno));
		// if(mno !== "") {
		//   dispatch(getUserDataByCategory(mno));
		// }
	}, []);

	/////////////////////////////////Alert handling////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	useEffect(() => {
		if (adduserinfosuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(userUpdateProfileReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [adduserinfosuccess, dispatch]);
	useEffect(() => {
		if (bookingsuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(adminCreateBookingReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [bookingsuccess, dispatch]);

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split("T")[0];
	}

	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	return (
		<>
			<Meta />
			<div className="body">
				{(loading ||
					createdbookingloading ||
					adduserinfoloading ||
					getSpecialityAllloading ||
					getTimeSlotsloading) && <Loader />}
				<h1 className="header-center">Book Appointment</h1>
				<form className="form-group" onSubmit={handleSearchPatient}>
					<Container className="header-center mt-5">
						<Row className="justify-content-center">
							<Col md="auto" className="text-center mt-1">
								<h6>
									Enter Patient's Mobile Number{" "}
									<span className="text-danger">
										<b>*</b>
									</span>
								</h6>
							</Col>
							<Col md="auto" className="text-center mt-1">
								<input
									className="form-control"
									type="text"
									placeholder="9999999999"
									id="mobilenumber"
									required="required"
									onChange={(e) => setMno(e.target.value)}
									minlength="10"
									maxlength="10"
									pattern="^[0-9]*$"
								/>
							</Col>
							<Col md="auto" className="text-center mt-1">
								<Button type="submit" id="search" className="btn btn-primary">
									Search
								</Button>
							</Col>
						</Row>
					</Container>
				</form>
				<Container className="header-center mt-5 mb-5 card col-md-9">
					<Row className="mt-4 mb-4">
						<Col md="auto" className="text-center mt-1"></Col>
						<Col md="auto" className="text-center mt-1">
							<h6>
								Select a user{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={userOption}
								onSelect={handleUserSelect}
							>
								{error === "No patient exists with that mobile number" ? (
									<Dropdown.Item></Dropdown.Item>
								) : (
									users_array.map((users, index) => (
										<Dropdown.Item eventKey={users.name + "@@" + index}>
											{users.name}
										</Dropdown.Item>
									))
								)}
							</DropdownButton>
						</Col>
						<Col md="auto" className="text-center mt-1">
							{users_array.length >= 6 ? (
								<Button disabled className="btn btn-danger">
									Users limit reached
								</Button>
							) : (
								<Button
									className="btn btn-secondary"
									onClick={() => setLgShow(true)}
								>
									Add User
								</Button>
							)}
						</Col>
					</Row>
					<Modal
						size="lg"
						show={lgShow}
						onHide={() => setLgShow(false)}
						aria-labelledby="example-modal-sizes-title-lg"
					>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-lg">
								Add New User
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<form className="form-group" onSubmit={addUserOnSubmit}>
								<div className="row mt-3">
									<label className="col-3 col-md-3" htmlFor="name">
										<b>Name </b>{" "}
										<span className="text-danger">
											<b>*</b>
										</span>
									</label>
									<input
										className="form-control col-8 col-md-8"
										id="name"
										type="name"
										required="required"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</div>
								<div className="row mt-3">
									<label htmlFor="gender" className="col-4 col-md-3">
										<b>Gender </b>{" "}
										<span className="text-danger">
											<b>*</b>
										</span>
									</label>
									<div className="row">
										<b>
											<Form.Check
												onChange={() => {
													setGender("Male");
												}}
												checked={gender === "Male"}
												className="form-check col-5 col-md-4"
												inline
												label="Male"
												type="radio"
												id={`inline-radio-1`}
											/>
											<Form.Check
												onChange={() => {
													setGender("Female");
												}}
												checked={gender === "Female"}
												inline
												label="Female"
												className="form-check col-5 col-md-4"
												type="radio"
												id={`inline-radio-2`}
											/>
										</b>
									</div>
								</div>
								<div className="row mt-3">
									<label htmlFor="date" className="col-md-3">
										<b>Date of Birth </b>{" "}
										<span className="text-danger">
											<b>*</b>
										</span>
									</label>
									<input
										type="date"
										className="col-md-4 form-control"
										selected={dob}
										value={dob}
										onChange={(e) => setdob(e.target.value)}
										max={today}
									/>
								</div>
								<div className="row mt-3">
									<label for="bloodgroup" className="col-md-3">
										<b>Blood Group:</b>
									</label>
									<DropdownButton
										className="col-md-1 btn"
										key="down-centered"
										id={`dropdown-button-drop-down-centered`}
										drop="down-centered"
										variant="success"
										title={bloodGroup}
										onSelect={handleBloodGroup}
									>
										{blood_groups.map((blood) => (
											<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
										))}
									</DropdownButton>
								</div>
								<div>
									<Button
										className="offset-5 offset-md-6 mt-5 btn"
										type="submit"
										onClick={() => setLgShow(false)}
									>
										Add
									</Button>
								</div>
							</form>
						</Modal.Body>
					</Modal>
				</Container>

				{userData?.data?.data?.name === userOption &&
					userData?.data?.data?.scheduledAppointment?.length > 0 && (
						<Container className="header-center mt-5 mb-5 card col-md-9">
							<Row className="mt-4 mb-4">
								<Col md="auto">
									<h6 class="card-subtitle text-muted mt-1">
										Doctor{" "}
										{userData?.data?.data?.scheduledAppointment[0]?.doctorName}{" "}
										has scheduled your appointment on{" "}
										{userData?.data?.data?.scheduledAppointment[0]?.date?.split(
											""
										)[6] +
											userData?.data?.data?.scheduledAppointment[0]?.date?.split(
												""
											)[7] +
											"-" +
											userData?.data?.data?.scheduledAppointment[0]?.date?.split(
												""
											)[4] +
											userData?.data?.data?.scheduledAppointment[0]?.date?.split(
												""
											)[5] +
											"-" +
											userData?.data?.data?.scheduledAppointment[0]?.date?.split(
												""
											)[0] +
											userData?.data?.data?.scheduledAppointment[0]?.date?.split(
												""
											)[1] +
											userData?.data?.data?.scheduledAppointment[0]?.date?.split(
												""
											)[2] +
											userData?.data?.data?.scheduledAppointment[0]?.date?.split(
												""
											)[3]}
									</h6>
									<h6 class="card-subtitle text-muted mt-1">
										Message:
										{userData?.data?.data?.scheduledAppointment[0]?.message}
									</h6>
								</Col>
							</Row>
						</Container>
					)}
				{userData?.data?.data?.registered_patients_array?.map(
					({ name, scheduledAppointment }) =>
						name === userOption &&
						scheduledAppointment?.length > 0 && (
							<Container className="header-center mt-5  mt-5 mb-5 card col-md-9">
								<Row className="mt-4 mb-4">
									<Col md="auto">
										<>
											<h6 class="card-subtitle mb-2 text-muted mt-1">
												Doctor {scheduledAppointment[0]?.doctorName} has
												scheduled your appointment on{" "}
												{scheduledAppointment[0]?.date?.split("")[6] +
													scheduledAppointment[0]?.date?.split("")[7] +
													"-" +
													scheduledAppointment[0]?.date?.split("")[4] +
													scheduledAppointment[0]?.date?.split("")[5] +
													"-" +
													scheduledAppointment[0]?.date?.split("")[0] +
													scheduledAppointment[0]?.date?.split("")[1] +
													scheduledAppointment[0]?.date?.split("")[2] +
													scheduledAppointment[0]?.date?.split("")[3]}
											</h6>
											<h6 class="card-subtitle mb-2 text-muted mt-1">
												Message:{scheduledAppointment[0]?.message}
											</h6>
										</>
									</Col>
								</Row>
							</Container>
						)
				)}

				<Container className="header-center mt-5 mb-5 card col-md-9">
					<Row className="mt-4 mb-4">
						<Col md="auto" className="text-center mt-1"></Col>
						<Col md="auto" className="text-center mt-1">
							<h6>
								Select a doctor and speciality{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={specialityOption}
								onSelect={handleSpecialitySelect}
							>
								{allSpecialities.map(
									(specialities, index) =>
										specialities.specialization !== "referral" &&
										specialities.specialization !== "Consultant Doctor" && (
											<Dropdown.Item
												eventKey={
													specialities.specialization +
													"/" +
													specialities._id +
													"/" +
													index
												}
											>
												{specialities.specialization}
											</Dropdown.Item>
										)
								)}
							</DropdownButton>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={doctorOption}
								onSelect={handleDoctorSelect}
							>
								{DoctorFromSpecialityArray.map((doctors, index) => (
									<Dropdown.Item
										eventKey={doctors.name + "/" + doctors.doctorsinfo}
									>
										{doctors.name}
									</Dropdown.Item>
								))}
							</DropdownButton>
						</Col>
					</Row>
					<Row className=" mb-4">
						<Col md="auto" className="text-center mt-1"></Col>
						<Col md="auto" className="text-center mt-1">
							<h6>
								Select a date{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={dateOption}
								onSelect={handleDateSelect}
							>
								{" "}
								<div className="dropScroll">
									{days.map((day, index) => (
										<Dropdown.Item eventKey={index}>
											{day.currDay}
										</Dropdown.Item>
									))}
								</div>
							</DropdownButton>
						</Col>
					</Row>
					<Row className=" mb-4">
						<Col md="auto" className="text-center mt-1"></Col>
						<Col md="auto" className="text-center mt-1">
							<h6>
								Select a slot{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							{dateSelected &&
								dateTimeSlots?.map((timeslots, index) =>
									!timeslots.isEmpty || timeslots.isBlocked ? (
										<Button className="btn btn-danger m-1" disabled>
											<b>{timeslots.timeSlot} No slots avaliable</b>
										</Button>
									) : (
										<button
											className={
												slotOption === timeslots.timeSlot
													? "btn btn-success m-1"
													: "btn btn-outline-success m-1"
											}
											onClick={() =>
												handleSlotSelect(timeslots.timeSlot, timeslots.curCount)
											}
										>
											<b>{`${timeslots.timeSlot} available slots: ${
												timeslots.maxAppointmentsperhour - timeslots.curCount
											} `}</b>
										</button>
									)
								)}
						</Col>
					</Row>
				</Container>
				<Container className="header-center mt-3">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center mt-1">
							<Button className="btn-secondary" onClick={submitHandler}>
								Book Appointment
							</Button>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default OnSpotBookingScreens;
