/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getBill = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getBillStart: (state) => {
			state.loading = true;
		},
		getBillSuccess: (state, action) => {
			state.loading = false;
			state.getbill = action.payload;
			state.error = false;
			state.success = true;
		},
		getBillFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getBillReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const { getBillStart, getBillSuccess, getBillFailure, getBillReset } =
	getBill.actions;
export default getBill.reducer;
