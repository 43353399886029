/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getLabVisitedPatient = createSlice({
	name: "getLabVisitedPatient",
	initialState: {},
	reducers: {
		getLabVisitedPatientRequest: (state) => {
			state.loading = true;
		},
		getLabVisitedPatientSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getLabVisitedPatients = action.payload;
			state.error = false;
			state.success = true;
		},
		getLabVisitedPatientFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getLabVisitedPatientReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getLabVisitedPatientRequest,
	getLabVisitedPatientSuccess,
	getLabVisitedPatientFailure,
	getLabVisitedPatientReset,
} = getLabVisitedPatient.actions;
export default getLabVisitedPatient.reducer;
