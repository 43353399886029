/** @format */

import { createSlice } from "@reduxjs/toolkit";

const addServiceReducer = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    addServiceStart: (state) => {
      state.loading = true;
    },
    addServiceSuccess: (state, action) => {
      state.loading = false;
      state.addServiceDetails = action.payload;
      state.error = false;
      state.success = true;
    },
    addServiceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addServiceReset: (state, action) => {
      state.success = false;
      state.error = false;
    },
  },
});

export const {
  addServiceStart,
  addServiceSuccess,
  addServiceFailure,
  addServiceReset,
} = addServiceReducer.actions;
export default addServiceReducer.reducer;
