/** @format */

import axios from "axios";
import { SERVER_URL } from "../../App";
import { hospitalname } from "../../App";
import { loginStart, loginSuccess, loginFailure } from "./receptionLogin";

import { persistor } from "../store";
import {
	verifyFailure,
	verifyReset,
	verifySuccess,
	verifyStart,
	logout,
} from "./VerifyCode";

import {
	resendOtpStart,
	resendOtpReset,
	resendOtpFailure,
	resendOtpSuccess,
} from "./resendOtp";

import {
	forgotPasswordFailure,
	forgotPasswordStart,
	forgotPasswordSuccess,
} from "./forgotPassword";

import {
	resetPasswordFailure,
	resetPasswordStart,
	resetPasswordSuccess,
} from "./resetPassword";

import {
	UpdatePasswordFailure,
	UpdatePasswordSuccess,
	UpdatePasswordStart,
} from "./updatePassword";

import {
	getPatientsInBlockedSlotStart,
	getPatientsInBlockedSlotFailure,
	getPatientsInBlockedSlotSuccess,
} from "./getPatientsInBlockedSlot";

import {
	rescheduleAppointmentForPatientinBlockedSlotFailure,
	rescheduleAppointmentForPatientinBlockedSlotStart,
	rescheduleAppointmentForPatientinBlockedSlotSuccess,
} from "./rescheduleAppointmentForPatientinBlockedSlot";

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";

import {
	getScheduledLabTestsStart,
	getScheduledLabTestsSuccess,
	getScheduledLabTestsFailure,
	getScheduledLabTestsReset,
} from "./getScheduledLabTests";

import {
	getBlockedSlotsDatesStart,
	getBlockedSlotsDatesFailure,
	getBlockedSlotsDatesSuccess,
} from "./getBlockedSlotsDates";

import {
	addScheduledLabTestsToLabDeptStart,
	addScheduledLabTestsToLabDeptSuccess,
	addScheduledLabTestsToLabDeptFailure,
	addScheduledLabTestsToLabDeptReset,
} from "./AddScheduledLabTestsToLabDept";

import {
	getReferralDoctorStart,
	getReferralDoctorSuccess,
	getReferralDoctorFailure,
	getReferralDoctorReset,
} from "./getReferralDoctor";

import {
	addLabTestsToLabDeptForReferralDoctorStart,
	addLabTestsToLabDeptForReferralDoctorSuccess,
	addLabTestsToLabDeptForReferralDoctorFailure,
	addLabTestsToLabDeptForReferralDoctorReset,
} from "./AddLabTestsToLabDeptForReferralDoctor";

import {
	editReceptionStart,
	editReceptionSuccess,
	editReceptionFailure,
	editReceptionReset,
} from "./editReception";

export const receptionLogin = (username, password) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(loginStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/reception/login`,
			{ username, password },
			config
		);
		console.log(data);

		// console.log(data.user);
		dispatch(loginSuccess());
		dispatch(
			allMessagesSuccess(
				"Credentials verified, Please verify the otp sent to your registered email"
			)
		);
		localStorage.setItem("sessionStartTime", Date.now().toString());
	} catch (error) {
		dispatch(allMessagesFailure("Incorrect mobile number or password"));
		dispatch(
			loginFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const resendOtpReception = (username) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(resendOtpStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/reception/resendOtp`,
			{ username },
			config
		);
		console.log(data);

		dispatch(resendOtpSuccess());
		dispatch(allMessagesSuccess("OTP sent successfully"));
	} catch (error) {
		dispatch(
			resendOtpFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const verifyCodeReception = (mobileNo, otp) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(verifyStart());

		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "true",
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/reception/otpVerification`,
			{ mobilenumber: mobileNo, otp },
			config
		);
		console.log(data);
		localStorage.setItem("token-user", data.token);

		localStorage.setItem("receptionInfo", JSON.stringify(data));
		localStorage.setItem(
			"username",
			"d6d269952320c4fb5e50f278c94a098c".toString()
		);
		// console.log(data.user);
		dispatch(verifySuccess(data));
		dispatch(allMessagesSuccess(" OTP verified successfully"));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			verifyFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const logout4 = () => (dispatch) => {
	dispatch(logout());
	// dispatch(userUpdateProfileReset());
	// dispatch(bookingListMyReset());
	// dispatch(tourReviewCreateReset());
	localStorage.removeItem("userInfo");
	localStorage.removeItem("adminInfo");
	localStorage.removeItem("receptionInfo");
	localStorage.removeItem("labTechInfo");
	localStorage.removeItem("doctorInfo");
	localStorage.removeItem("username");
	localStorage.removeItem("token-user");
	localStorage.removeItem("persist:root");
	localStorage.removeItem("sessionStartTime");
	persistor.pause();
	persistor.flush().then(() => {
		return persistor.purge();
	});
};

export const updatePassword = (password) => async (dispatch, getState) => {
	try {
		dispatch(UpdatePasswordStart());
		console.log("getting3221");
		const {
			userLogin: { userInfo },
		} = getState();
		console.log(password);
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};

		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/users/updatePassword`,
			{ password },
			config
		);
		dispatch(UpdatePasswordSuccess(data));
		dispatch(
			allMessagesSuccess("Password updated Successfully. Please login again ")
		);
		dispatch(logout());
		localStorage.removeItem("receptionInfo");
		localStorage.removeItem("doctorInfo");
		localStorage.removeItem("token-user");
		localStorage.removeItem("persist:root");
		persistor.pause();
		persistor.flush().then(() => {
			return persistor.purge();
		});
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		const message =
			error.response && error.response.data.data.message
				? error.response.data.data.message
				: error.message;
		if (message === "Not authorized, token failed") {
			localStorage.removeItem("persist:root");
			dispatch(logout());
		}
		dispatch(UpdatePasswordFailure(message));
	}
};

export const resetPasswordforReception =
	(token, password) => async (dispatch) => {
		try {
			dispatch(resetPasswordStart());

			const data = await axios.post(
				`${SERVER_URL}/api/v1/reception/resetPassword`,
				{
					password: password,
					token: token,
				}
			);
			dispatch(resetPasswordSuccess());
			dispatch(allMessagesSuccess("Password reset Successfully "));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				resetPasswordFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getPatientsInBlockedSlot = (date) => async (dispatch) => {
	try {
		console.log(date);
		dispatch(getPatientsInBlockedSlotStart());
		// console.log(date)
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/reception/getPatientsInBlockedSlot/${date}`,
			config
		);
		console.log(data);
		dispatch(getPatientsInBlockedSlotSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getPatientsInBlockedSlotFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const updateMe =
	(name, mno, email, dob, gender, address, id) => async (dispatch) => {
		try {
			const token = localStorage.getItem("token-user");
			dispatch(editReceptionStart());
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/reception/editReception`,
				{
					name,
					mobilenumber: mno,
					email,
					dob,
					gender,
					address,
					id,
				},
				config
			);
			dispatch(verifySuccess(data));
			dispatch(editReceptionSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				editReceptionFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const rescheduleAppointmentForPatientinBlockedSlot =
	(
		userId,
		bookingId,
		date,
		dateIdx,
		timeslot,
		slotCount,
		day,
		doctorId,
		prevDate,
		prevDateIdx,
		prevTimeSlot,
		username
	) =>
	async (dispatch) => {
		try {
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			dispatch(rescheduleAppointmentForPatientinBlockedSlotStart());
			console.log(prevDate);
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			console.log(
				userId,
				bookingId,
				date,
				dateIdx,
				timeslot,
				slotCount,
				day,
				doctorId,
				prevDate,
				prevDateIdx,
				prevTimeSlot,
				username
			);
			const data = await axios.post(
				`${SERVER_URL}/api/v1/reception/rescheduleAppointmentForPatientinBlockedSlot`, //rescheduleAppointmentForPatientinBlockedSlot,
				{
					userId,
					bookingId,
					date,
					dateIdx,
					timeslot,
					slotCount,
					day,
					doctorId,
					prevDate,
					prevDateIdx,
					prevTimeSlot,
					username,
				},
				config
			);
			dispatch(rescheduleAppointmentForPatientinBlockedSlotSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				rescheduleAppointmentForPatientinBlockedSlotFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getScheduledLabTests = (userId, userIdx) => async (dispatch) => {
	try {
		dispatch(getScheduledLabTestsStart());
		// console.log(date)
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/reception/getScheduledLabTests`,
			{ userId, userIdx },
			config
		);
		console.log(data);
		dispatch(getScheduledLabTestsSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getScheduledLabTestsFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const addScheduledLabTestsToLabDept =
	(
		userId,
		mobileNumber,
		userName,
		userIdx,
		tests,
		deptArray,
		modeOfPayment,
		billDetails,
		labAmountPaidCash,
		labAmountPaidOnline
	) =>
	async (dispatch) => {
		try {
			console.log(tests);
			dispatch(addScheduledLabTestsToLabDeptStart());
			// console.log(date)
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/reception/addScheduledLabTestsToLabDept`,
				{
					userId,
					mobileNumber,
					userName,
					userIdx,
					tests,
					deptArray,
					modeOfPayment,
					billDetails,
					labAmountPaidCash,
					labAmountPaidOnline,
				},
				config
			);
			console.log(data);
			dispatch(addScheduledLabTestsToLabDeptSuccess(data));
			dispatch(allMessagesSuccess("Payment succesfull"));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				addScheduledLabTestsToLabDeptFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getBlockedSlotsDates = () => async (dispatch) => {
	try {
		dispatch(getBlockedSlotsDatesStart());
		// console.log(date)
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/reception/getBlockedSlotsDates`,
			{},
			config
		);
		console.log(data);
		dispatch(getBlockedSlotsDatesSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getBlockedSlotsDatesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const getReferralDoctor = () => async (dispatch) => {
	try {
		dispatch(getReferralDoctorStart());
		// console.log(date)
		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				hospitalname: hospitalname,
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				username: username,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/reception/getAllReferralDoctors`,
			{},
			config
		);
		console.log(data);
		dispatch(getReferralDoctorSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
		dispatch(
			getReferralDoctorFailure(
				error?.response && error?.response?.data?.data?.message
					? error?.response?.data?.data?.message
					: error?.message
			)
		);
	}
};

export const addLabTestsToLabDeptForReferralDoctor =
	(
		userId,
		mobileNumber,
		userName,
		userIdx,
		modeOfPayment,
		deptArray,
		tests,
		doctorId,
		doctorName,
		billDetails,
		referralAmountPaidCash,
		referralAmountPaidOnline,
		labAmountPaidCash,
		labAmountPaidOnline
	) =>
	async (dispatch) => {
		try {
			dispatch(addLabTestsToLabDeptForReferralDoctorStart());
			dispatch(addLabTestsToLabDeptForReferralDoctorReset());
			// console.log(date)
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					hospitalname: hospitalname,
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
				},
			};
			console.log(
				userId,
				mobileNumber,
				userName,
				userIdx,
				modeOfPayment,
				deptArray,
				tests,
				doctorId,
				doctorName + "line584"
			);
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/reception/addLabTestsToLabDeptForReferralDoctor`,
				{
					userId,
					mobileNumber,
					userName,
					userIdx,
					modeOfPayment,
					deptArray,
					tests,
					doctorId,
					doctorName,
					billDetails,
					referralAmountPaidCash,
					referralAmountPaidOnline,
					labAmountPaidCash,
					labAmountPaidOnline,
				},
				config
			);
			console.log(data);
			dispatch(addLabTestsToLabDeptForReferralDoctorSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
			dispatch(
				addLabTestsToLabDeptForReferralDoctorFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};
