/** @format */

import React, { useEffect, useState, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';
import { Container, Col, Row } from 'react-bootstrap';
import { getPatientsInQueueForLabTechnician } from '../../redux/labRedux/labTechApiCalls';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Meta from '../../Components/Meta';
import {
	editTestStatusForPatientsInQueue,
	editTestNotesForPatientsInQueue,
} from '../../redux/labRedux/labTechApiCalls';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getLabVisitedPatients } from '../../redux/labRedux/labTechApiCalls';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const LabVisitedScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	const { labTechInfo } = useSelector((state) => state.labTechLogin);
	console.log(labTechInfo);

	const [visitedDate, setVisitedDate] = useState('');
	const [visitedDateNumeric, setVisitedDateNumeric] = useState('');
	const [searchClicked, setSearchClicked] = useState(false);
	const { visitedPatients, loading } = useSelector(
		(state) => state.getLabVisitedPatients
	);

	const isToday = (inputDate) => {
		var today1 = new Date(); // Get today1's date
		var input = new Date(inputDate); // Convert the input date string to a Date object

		// Check if the input date matches today1's date
		return (
			input.getDate() === today1.getDate() &&
			input.getMonth() === today1.getMonth() &&
			input.getFullYear() === today1.getFullYear()
		);
	};
	const [allPatientsData, setAllPatientsData] = useState([]);
	const [allData, setAllData] = useState([]);

	useEffect(() => {
		if (
			visitedPatients &&
			visitedPatients.data &&
			visitedPatients.data.data &&
			todayOrNot
		) {
			// Use reduce to concatenate all the data into one array
			const allPatients = visitedPatients?.data?.data.reduce(
				(accumulator, tests) => {
					tests.tests?.forEach((test) => {
						test.paid_patients?.forEach((patient) => {
							accumulator.push({
								name: patient.name,
								mobileNumber: patient.mobilenumber,
								testName: test.testName,
								reportId: patient.reportId,
								userId: patient.userId,
							});
						});
					});
					return accumulator;
				},
				[]
			);

			// Set allPatientsData state variable with the concatenated data
			setAllPatientsData(allPatients);
		} else if (
			visitedPatients &&
			visitedPatients.data &&
			visitedPatients.data.data &&
			!todayOrNot
		) {
			// Use reduce to concatenate all the data into one array
			const allDataPast = visitedPatients?.data?.data.reduce(
				(accumulator, current) => {
					current.tests?.forEach((test) => {
						test.visited_patients?.forEach((patient) => {
							accumulator.push({
								testName: test.testName,
								name: patient.name,
								mobileNumber: patient.mobilenumber,
								reportId: patient.reportId,
								userId: patient.userId,
							});
						});
					});
					return accumulator;
				},
				[]
			);

			// Set allPatientsData state variable with the concatenated data
			setAllData(allDataPast);
		}
	}, [loading, visitedPatients]);

	const [todayOrNot, setTodayOrNot] = useState(false);

	const handleDateSelect1 = (eventKey) => {
		setVisitedDate(eventKey);
		let date = Number(eventKey?.split('-')?.join(''));
		setVisitedDateNumeric(date);
		setSearchClicked(false);
		var result = isToday(eventKey);
		setTodayOrNot(result);
	};

	const searchForVisited = () => {
		if (visitedDate === '') {
			alert('Select Date');
		} else {
			dispatch(getLabVisitedPatients(visitedDateNumeric));
			setSearchClicked(true);
		}
	};

	console.log(visitedPatients);

	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';
	const exportToExcel = async () => {
		let tempObj = todayOrNot ? allPatientsData : allData;
		console.log(tempObj);
		let tempArr = [];
		for (let i = 0; i < tempObj.length; i++) {
			const obj = {
				'Patient Name': tempObj[i].name,
				'Mobile No': tempObj[i].mobileNumber,
				'Test Name': tempObj[i].testName,
			};
			tempArr.push(obj);
		}
		console.log(tempArr + 'dataaaaaa');

		const ws = XLSX.utils.json_to_sheet(tempArr);

		// Set column widths (example: set the width of the first column to 20)
		ws['!cols'] = [
			{ wch: 20 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 20 },
		];

		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, visitedDate);
		console.log('saved');
	};

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split('T')[0];
	}

	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	const viewReportHandler = (reportId) => {
		// history("/viewreport", {
		// 	state: {
		// 		id: userId,
		// 		age: "",
		// 		gender: "",
		// 	},
		// });

		const data = {
			id: reportId,
			age: '',
			gender: '',
		};
		let url = '/viewreport';

		const newWindow = window.open(url, '_blank');
		newWindow.onload = () => {
			// Pass both data and unique identifier to the new window
			newWindow.postMessage(data, window.location.origin);
		};
	};

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href='/labTech/patientsinqueue'>
					Patients in queue
				</Breadcrumb.Item>
				<Breadcrumb.Item href='/labTech/labvisited'>
					Visited patients
				</Breadcrumb.Item>
			</Breadcrumb>
			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center'>
						<input
							type='date'
							className='form-control'
							onChange={(e) => handleDateSelect1(e.target.value)}
							value={visitedDate}
							max={today}
						/>
					</Col>
					<Col md='auto' className='text-center'>
						<Button
							id='searchButton1'
							className='btn-primary'
							onClick={searchForVisited}>
							Search
						</Button>
					</Col>
				</Row>
			</Container>
			{searchClicked && (
				<Container className='header-center mb-2'>
					<Row className='justify-content-end'>
						<Col md='auto' className='text-center'>
							<button
								className='btn btn-success'
								onClick={(e) => exportToExcel()}>
								<i className='fa fa-download'></i>&nbsp;Download
							</button>
						</Col>
					</Row>
				</Container>
			)}
			{searchClicked && todayOrNot && (
				<Container className='header-center mt-5'>
					<Table striped bordered hover className='custom-table'>
						<thead>
							<tr>
								<th>S.No</th>
								<th>Patient Name</th>
								<th>Mobile Number</th>
								<th>Test Name</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							{allPatientsData?.map((patient, index) => (
								<tr key={index}>
									<td>{index + 1}</td>
									<td>{patient.name}</td>
									<td>{patient.mobileNumber}</td>
									<td>{patient.testName}</td>
									{patient?.reportId !== '' ? (
										<td>
											<button
												className='btn btn-success'
												onClick={() => viewReportHandler(patient?.reportId)}>
												View Report
											</button>
										</td>
									) : (
										<td>Report Not Generated</td>
									)}
								</tr>
							))}
						</tbody>
					</Table>
				</Container>
			)}

			{searchClicked && !todayOrNot && (
				<div>
					<Container className='header-center mt-5'>
						<Table striped bordered hover className='custom-table'>
							<thead>
								<tr>
									<th>S.No</th>
									<th>Patient Name</th>
									<th>Mobile Number</th>
									<th>Test Name</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								{allData?.map((patient, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>{patient.name}</td>
										<td>{patient.mobileNumber}</td>
										<td>{patient.testName}</td>
										{patient?.reportId !== '' ? (
											<td>
												<button
													className='btn btn-success'
													onClick={() => viewReportHandler(patient?.reportId)}>
													View Report
												</button>
											</td>
										) : (
											<td>Report Not Generated</td>
										)}
									</tr>
								))}
							</tbody>
						</Table>
					</Container>
				</div>
			)}
		</>
	);
};

export default LabVisitedScreen;
