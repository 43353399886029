/** @format */

import { createSlice } from '@reduxjs/toolkit';

const getBlockedSlotsDates = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		getBlockedSlotsDatesStart: (state) => {
			state.loading = true;
		},
		getBlockedSlotsDatesSuccess: (state, action) => {
			state.loading = false;
			state.getBlockedPatientsDate = action.payload;
			state.error = false;
			state.success = true;
		},
		getBlockedSlotsDatesFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getBlockedSlotsDatesReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getBlockedSlotsDatesStart,
	getBlockedSlotsDatesSuccess,
	getBlockedSlotsDatesFailure,
	getBlockedSlotsDatesReset,
} = getBlockedSlotsDates.actions;
export default getBlockedSlotsDates.reducer;
