import { createSlice } from "@reduxjs/toolkit";

const getConsultantDoctorReducer = createSlice({
  name: "getConsultantDoctor",
  initialState: {},
  reducers: {
    getConsultantDoctorRequest: (state) => {
      state.loading = true;
    },
    getConsultantDoctorSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.getConsultantDoctor = action.payload;
      state.error = false;
      state.success = true;
    },
    getConsultantDoctorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getConsultantDoctorReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getConsultantDoctorRequest,
  getConsultantDoctorSuccess,
  getConsultantDoctorFailure,
  getConsultantDoctorReset,
} = getConsultantDoctorReducer.actions;
export default getConsultantDoctorReducer.reducer;
