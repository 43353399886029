/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Table from "react-bootstrap/Table";
import Meta from "../../../Components/Meta";
import Form from "react-bootstrap/Form";
import {
	createDoctor,
	getLeaves,
	deleteDoctor,
} from "../../../redux/adminRedux/adminApiCalls";
import { getSpecialities } from "../../../redux/userApiCalls";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Message from "../../../Components/Message";
import { createDoctorReset } from "../../../redux/adminRedux/Doctor_Management/createDoctor";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import axios from "axios";
import { SERVER_URL } from "../../../App";
import Button from "react-bootstrap/Button";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../../redux/allMessages";
import { deleteDoctorReset } from "../../../redux/adminRedux/Doctor_Management/deleteDoctor";
import { getLeavesReset } from "../../../redux/adminRedux/Doctor_Management/getLeaves";
import { specialityGetReset } from "../../../redux/getSpecialities";
import { getDoctorfromSpecialityReset } from "../../../redux/getDoctorfromSpeciality";
import Spinner from "react-bootstrap/Spinner";
import { Switch } from "react-switch-input";
import Collapse from "react-bootstrap/Collapse";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { hospitalname } from "../../../App";

const AddDoctorScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const dataDays = [
		{
			startTime: "09:30",
			breakTime: "11:30-12:30",
			endTime: "17:30",
			maxAppointmentsperhour: "5",
			isWorking: false,
		},
		{
			startTime: "09:30",
			breakTime: "11:30-12:30",
			endTime: "17:30",
			maxAppointmentsperhour: "5",
			isWorking: false,
		},
		{
			startTime: "09:30",
			breakTime: "11:30-12:30",
			endTime: "17:30",
			maxAppointmentsperhour: "5",
			isWorking: false,
		},
		{
			startTime: "09:30",
			breakTime: "11:30-12:30",
			endTime: "17:30",
			maxAppointmentsperhour: "5",
			isWorking: false,
		},
		{
			startTime: "09:30",
			breakTime: "11:30-12:30",
			endTime: "17:30",
			maxAppointmentsperhour: "5",
			isWorking: false,
		},
		{
			startTime: "09:30",
			breakTime: "11:30-12:30",
			endTime: "17:30",
			maxAppointmentsperhour: "5",
			isWorking: false,
		},
		{
			startTime: "09:30",
			breakTime: "11:30-12:30",
			endTime: "17:30",
			maxAppointmentsperhour: "5",
			isWorking: false,
		},
	];
	const [daysData, setDaysData] = useState(dataDays);
	const [timerRunning, setTimerRunning] = useState(false);
	//Add Doctor
	const getSpecialityAllData = useSelector((state) => state.getSpeciality);
	const getSpecialityAll = getSpecialityAllData.getSpecialityAll;
	const getSpecialityAllsuccess = getSpecialityAllData.success;
	const getSpecialityAllerror = getSpecialityAllData.error;
	const getSpecialityAllloading = getSpecialityAllData.loading;

	// console.log(getSpecialityAll);
	let allSpecialities = [];
	if (getSpecialityAll) {
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}
	console.log(allSpecialities);

	const [specialities, setSpecialities] = useState([]);
	const [specialitiesIds, setSpecialitiesIds] = useState([]);

	const [file, setFile] = useState(null);
	const [imageUrl, setImageUrl] = useState({});
	const [imageUploaded, setImageUploaded] = useState(false);

	const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];

		// Check if a file is selected
		if (selectedFile) {
			// Check if the selected file type is allowed
			if (allowedTypes.includes(selectedFile.type)) {
				// Set the file in state
				setFile(selectedFile);
				handleUpload(selectedFile);
				setImageUploaded(true);
			} else {
				// If the file type is not allowed, alert the user
				alert("Please select a valid image file (jpeg, jpg, png).");
				// Reset the file input field
				event.target.value = null;
				// Reset the file state
				setFile(null);
			}
		}
	};
	console.log(imageUrl);

	const handleSpecSelect = (spec, id) => {
		if (!specialities?.includes(spec)) {
			let specsarr = [...specialities];
			specsarr.push(spec);
			setSpecialities(specsarr);
			let specsarrIds = [...specialitiesIds];
			specsarrIds.push(id);
			setSpecialitiesIds(specsarrIds);
		}
	};

	const handleDeleteSpecialities = (spec) => {
		let specarr = [...specialities];
		let index = specarr.indexOf(spec);
		if (index > -1) specarr.splice(index, 1);
		setSpecialities(specarr);
		console.log(specarr);
		let specarrIds = [...specialitiesIds];
		// let index = specarr.indexOf(spec);
		if (index > -1) specarrIds.splice(index, 1);
		setSpecialitiesIds(specarrIds);
		console.log(specarrIds);
	};

	let allDoctors = [];

	const getDoctorfromSpecialityData = useSelector((state) => state.getDoctor);
	const getDoctorfromSpeciality =
		getDoctorfromSpecialityData.getDoctorfromSpeciality;
	const getDoctorfromSpecialitysuccess = getDoctorfromSpecialityData.success;
	const getDoctorfromSpecialityerror = getDoctorfromSpecialityData.error;
	const getDoctorfromSpecialityloading = getDoctorfromSpecialityData.loading;
	if (getDoctorfromSpeciality) {
		if (getDoctorfromSpeciality.data[0]) {
			console.log(getDoctorfromSpeciality.data);
			if (getDoctorfromSpeciality.data[0].doctorsArray) {
				for (
					let i = 0;
					i < getDoctorfromSpeciality.data[0].doctorsArray.length;
					i++
				) {
					allDoctors.push(getDoctorfromSpeciality.data[0].doctorsArray[i][0]);
				}
			}
		}
	}
	const [activeButton, setActiveButton] = useState(0);
	const [switche, setSwitch] = useState(false);
	const [switchActivated, setSwitchActivated] = useState(null);
	const days = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	const handleButtonClick = (index) => {
		setActiveButton(index);
	};
	const handleChange = (value, index, key) => {
		if (key === "isWorking") {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				isWorking: !value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === "maxAppointmentsperhour") {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				maxAppointmentsperhour: value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === "startTime") {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				startTime: value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === "endTime") {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				endTime: value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === "startBreakTime") {
			let modifiedDaysData = [...daysData];
			const newBreakTime = modifiedDaysData.map((item, i) => {
				if (index === i) {
					let oldBreakTime = item.breakTime.split("-");
					oldBreakTime[0] = value;
					let updatedBreakTime = oldBreakTime.join("-");

					return { ...item, breakTime: updatedBreakTime };
				}
				return item;
			});
			setDaysData(newBreakTime);
		} else if (key === "endBreakTime") {
			let modifiedDaysData = [...daysData];
			const newBreakTime = modifiedDaysData.map((item, i) => {
				if (index === i) {
					let oldBreakTime = item.breakTime.split("-");
					oldBreakTime[1] = value;
					let updatedBreakTime = oldBreakTime.join("-");

					return { ...item, breakTime: updatedBreakTime };
				}
				return item;
			});
			setDaysData(newBreakTime);
		}
	};

	const [singleSelectedDayswitch, setSingleSelectedDaySwitch] = useState([]);

	const handleSwitch = (e) => {
		console.log(activeButton, e?.target?.checked);
		let singleSelectedDayData = [];
		if (e?.target?.checked) {
			setSwitch(true);
			for (let i = 0; i < daysData?.length; i++) {
				singleSelectedDayData.push(daysData[activeButton]);
			}
			console.log(singleSelectedDayData);
			setSingleSelectedDaySwitch(singleSelectedDayData);
			setSwitchActivated(activeButton);
		} else {
			setSwitch(false);
			singleSelectedDayData = [];
			setSingleSelectedDaySwitch([]);
			setSwitchActivated(null);
		}
	};

	const handleChangeSwitch = (value, j, key) => {
		if (key === "isWorking") {
			let modifiedDaysData = [...singleSelectedDayswitch];
			for (let index = 0; index < 7; index++) {
				modifiedDaysData[index] = {
					...modifiedDaysData[index],
					isWorking: !value,
				};
			}
			setSingleSelectedDaySwitch(modifiedDaysData);
		} else if (key === "maxAppointmentsperhour") {
			let modifiedDaysData = [...singleSelectedDayswitch];
			for (let index = 0; index < 7; index++) {
				modifiedDaysData[index] = {
					...modifiedDaysData[index],
					maxAppointmentsperhour: value,
				};
			}
			setSingleSelectedDaySwitch(modifiedDaysData);
		} else if (key === "startTime") {
			let modifiedDaysData = [...singleSelectedDayswitch];
			for (let index = 0; index < 7; index++) {
				modifiedDaysData[index] = {
					...modifiedDaysData[index],
					startTime: value,
				};
			}
			setSingleSelectedDaySwitch(modifiedDaysData);
		} else if (key === "endTime") {
			let modifiedDaysData = [...singleSelectedDayswitch];
			for (let index = 0; index < 7; index++) {
				modifiedDaysData[index] = {
					...modifiedDaysData[index],
					endTime: value,
				};
			}
			setSingleSelectedDaySwitch(modifiedDaysData);
		} else if (key === "startBreakTime") {
			let modifiedDaysData = [...singleSelectedDayswitch];

			let newBreakTime = modifiedDaysData.map((item, i) => {
				if (1) {
					let oldBreakTime = item.breakTime.split("-");
					oldBreakTime[0] = value;
					let updatedBreakTime = oldBreakTime.join("-");

					return { ...item, breakTime: updatedBreakTime };
				}
				return item;
			});
			setSingleSelectedDaySwitch(newBreakTime);
		} else if (key === "endBreakTime") {
			let modifiedDaysData = [...singleSelectedDayswitch];

			const newBreakTime = modifiedDaysData.map((item, i) => {
				if (1) {
					let oldBreakTime = item.breakTime.split("-");
					oldBreakTime[1] = value;
					let updatedBreakTime = oldBreakTime.join("-");

					return { ...item, breakTime: updatedBreakTime };
				}
				return item;
			});

			setSingleSelectedDaySwitch(newBreakTime);
		}
	};

	const createDoctor1 = useSelector((state) => state.createDoctor);
	console.log(createDoctor1);
	const { loading, error, userInfo, success } = createDoctor1;

	console.log(userInfo);
	useEffect(() => {
		dispatch(getSpecialities());
	}, [userInfo, dispatch]);
	const [showPopup1, setShowPopup1] = React.useState(false);

	const [showPopup4, setShowPopup4] = React.useState(false);
	const [showPopup5, setShowPopup5] = React.useState(false);

	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);
	useEffect(() => {
		if (success && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(createDoctorReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [success, dispatch]);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log("error");
		dispatch(createDoctorReset());
	};

	const toggleShowInfoPopup4 = (e) => {
		setShowPopup4(!showPopup4);
		e.preventDefault();
		console.log("error");
		dispatch(specialityGetReset());
	};
	const toggleShowInfoPopup5 = (e) => {
		setShowPopup5(!showPopup5);
		e.preventDefault();
		console.log("error");
		dispatch(getDoctorfromSpeciality());
	};

	const ISTOffset = 330;
	const todayInIST = new Date(new Date().getTime() + ISTOffset * 60000)
		.toISOString()
		.split("T")[0];

	//multistep form
	const [step, setStep] = useState(1);

	const [formData, setFormData] = useState({
		Name: "",
		mobileNumber: "",
		email: "",
		dob: "",
		id1: "",
		address: "",
		designation: "",
		education: "",
		gender: "",
		// speciality: "",
		// specialityId: "",
		// maxCount: "",
		// startT: "",
		// endT: "",
		experience: "",
		personality: "",
		// startBreakTime1: "",
		// endBreakTime1: "",
		opConsultationFee: "",
		ipConsultationFee: "",
		ipSurgeryFees: "",
		bookingPeriod: "",
	});

	const handleChange1 = (e) => {
		const { name, value } = e.target;
		if (name !== "gender")
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
	};

	const nextStep = () => {
		setStep((prevStep) => prevStep + 1);
	};

	const prevStep = () => {
		setStep((prevStep) => prevStep - 1);
	};
	const [openItems, setOpenItems] = useState(false);
	const handleSubmit1 = (e) => {
		e.preventDefault();
		const isValidInput = /^\d+$/.test(formData.mobileNumber);
		const isValidInputOP = /^\d+$/.test(formData.opConsultationFee);
		const isValidInputIP = /^\d+$/.test(formData.ipConsultationFee);
		const isValidInputIPSurgery = /^\d+$/.test(formData.ipSurgeryFees);
		// Handle form submission logic
		console.log("Form submitted:", formData);
		var letters = /^[A-Za-z\s.]+$/;
		if (formData.Name === "") {
			alert("Username cannot be empty");
		} else if (formData.mobileNumber === "") {
			alert("Mobile Number cannot be empty");
		} else if (!isValidInput) {
			alert("Only digits are allowed in Mobile Number.");
		} else if (
			formData.mobileNumber.length > 10 ||
			formData.mobileNumber.length < 10
		) {
			alert("Mobile Number should be 10 digits only");
		} else if (formData.email === "") {
			alert("Enter Email Address");
		} else if (formData.dob === "") {
			alert("Date of birth cannot be empty");
		} else if (formData.designation === "") {
			alert("Designation not selected");
		} else if (formData.education === "") {
			alert("Education not selected");
		} else if (formData.personality === "") {
			alert("Please select personality");
		} else if (formData.opConsultationFee === "") {
			alert("Please enter OP Consultation Fee");
		} else if (formData.ipConsultationFee === "") {
			alert("Please enter IP Consultation Fee");
		} else if (formData.ipSurgeryFees === "") {
			alert("Only digits are allowed in IP surgery Fee.");
		} else if (!isValidInputIP) {
			alert("Only digits are allowed in IP Consultation Fee.");
		} else if (formData.startT === "") {
			alert("Please select start time");
		} else if (formData.endT === "") {
			alert("Please select end time");
		} else if (formData.startBreakTime === "") {
			alert("Please select start break time");
		} else if (formData.endBreakTime === "") {
			alert("Please select end break time");
		} else if (formData.bookingPeriod === "") {
			alert("Please Enter Booking Period");
		} else if (formData.startT >= formData.endT) {
			alert("Start time cannot exceed the end time");
		} else if (formData.startBreakTime1 >= formData.endBreakTime1) {
			alert(
				"Break end time can't be less than break start time, Please enter correct break end time"
			);
		} else if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)
		) {
			alert("Email address invalid");
		} else if (formData?.mobileNumber?.length > 10) {
			alert("Mobile number cannot be more than 10 numbers");
		} else {
			setFile(null);
			setImageUrl({});
			setImageUploaded(false);
			setSpecialitiesIds([]);
			setSpecialities([]);
			let temp = window.confirm(
				"Are you sure you want to create \n" +
					"name : " +
					formData?.Name +
					"\n" +
					"mobilenumber : " +
					formData?.mobileNumber +
					"\n" +
					"email: " +
					formData?.email +
					"\n" +
					"Date Of Birth: " +
					formData?.dob +
					"\n" +
					"Designation: " +
					formData?.designation +
					"\n" +
					"Education : " +
					formData?.education +
					"\n" +
					"Experience : " +
					formData?.experience +
					"\n" +
					"Personality : " +
					formData?.personality +
					"\n" +
					"Address : " +
					formData?.address +
					"\n" +
					"Gender : " +
					formData?.personality +
					"\n" +
					"Max appointments/hour : " +
					formData?.maxCount +
					"\n" +
					"speciality : " +
					specialities +
					"\n"
			);
			if (!temp) {
				alert("Please enter correct Doctor details");
			} else {
				console.log(
					formData?.Name,
					formData?.mobileNumber,
					specialities,
					// maxCount,
					// startTimeFormat1,
					// endTimeFormat1,
					// breakTime1,
					specialitiesIds,
					formData?.designation,
					formData?.education,
					formData?.experience,
					formData?.personality,
					formData?.email,
					formData?.address,
					formData?.dob,
					formData?.gender
				);
				dispatch(
					createDoctor(
						formData?.Name,
						formData?.mobileNumber.toString(),
						specialities,
						// maxCount,
						// startTimeFormat1,
						// endTimeFormat1,
						// breakTime1,
						specialitiesIds,
						formData?.designation,
						formData?.education,
						formData?.experience,
						formData?.personality,
						formData?.email,
						formData?.address,
						formData?.dob,
						formData?.gender,
						Number(formData?.ipConsultationFee),
						Number(formData?.opConsultationFee),
						Number(formData?.ipSurgeryFees),
						switche ? singleSelectedDayswitch : daysData,
						formData?.bookingPeriod,
						imageUrl
					)
				);
				setStep(1);
				setFormData({
					Name: "",
					mobileNumber: "",
					email: "",
					dob: new Date(),
					designation: "",
					education: "",
					experience: "",
					personality: "",
					address: "",
					gender: "",
					maxCount: "",
					startT: "",
					endT: "",
					startBreakTime1: "",
					endBreakTime1: "",
					bookingPeriod: "",
				});
				setDaysData(dataDays);
				setActiveButton(0);
				setSwitch(false);
				setSingleSelectedDaySwitch([]);
			}
		}
	};
	const handleUpload = async (file) => {
		const formData = new FormData();
		formData.append("file", file);

		try {
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
					hospitalname: hospitalname,
				},
			};
			const response = await axios.post(
				`${SERVER_URL}/api/v1/imageUpload/`,
				formData,
				config
			);
			console.log(response.data.data.data);
			setImageUrl(response.data.data.data);
		} catch (error) {
			console.error("Upload failed:", error);
			allMessagesFailure("Upload Failed");
		}
	};

	return (
		<>
			<Meta />

			{(loading || getDoctorfromSpecialityloading) && <Loader />}

			<div>
				<h1 className="header-center">Add Doctor </h1>
			</div>
			<div className="container mt-5">
				<div className="row justify-content-center">
					<div className="col-md-8">
						<form>
							{step === 1 && (
								<div>
									<h2>Personal Information</h2>
									{imageUrl && imageUploaded && <img src={imageUrl} />}
									<div className="form-group row">
										<label className="col-md-2 col-12">Profile:</label>
										<input
											type="file"
											name="file"
											onChange={handleFileChange}
										/>
									</div>
									<div className="form-group row">
										<label className="col-12">Name:</label>
										<input
											type="text"
											name="Name"
											value={formData?.Name}
											onChange={handleChange1}
											className="form-control"
										/>
									</div>
									<div className="form-group row ">
										<label className="col-md-2 co-12">Gender :</label>

										<Form.Check
											onChange={() =>
												setFormData((prevData) => ({
													...prevData,
													gender: "Male", // assuming 'gender' is the key in formData state
												}))
											}
											checked={formData.gender === "Male"}
											className="form-check"
											inline
											label="Male"
											type="radio"
											id="inline-radio-1"
											name="gender" // Added name attribute for grouping
										/>
										<Form.Check
											onChange={() =>
												setFormData((prevData) => ({
													...prevData,
													gender: "Female", // assuming 'gender' is the key in formData state
												}))
											}
											checked={formData.gender === "Female"}
											className="form-check "
											inline
											label="Female"
											type="radio"
											id="inline-radio-1"
											name="gender" // Added name attribute for grouping
										/>
									</div>
									<div className="form-group row">
										<label className="col-12">Mobile No:</label>
										<input
											type="number"
											name="mobileNumber"
											value={formData?.mobileNumber}
											onChange={handleChange1}
											className="form-control"
										/>
									</div>
									<div className="form-group row">
										<label className="col-12">Email:</label>
										<input
											type="email"
											name="email"
											value={formData?.email}
											onChange={handleChange1}
											className="form-control"
										/>
									</div>
									<div className="form-group row">
										<label className="col-12">Date Of Birth:</label>
										<input
											type="date"
											name="dob"
											value={formData?.dob}
											onChange={handleChange1}
											className="form-control"
										/>
									</div>
									<div className="form-group row">
										<label className="col-12">Address:</label>
										<textarea
											type="date"
											name="address"
											value={formData?.address}
											onChange={handleChange1}
											className="form-control"
										></textarea>
									</div>
									<button
										type="button"
										className="btn btn-primary"
										onClick={nextStep}
									>
										Next
									</button>
								</div>
							)}
							{step === 2 && (
								<div>
									<h2>Professional Information</h2>
									<div className="form-group row">
										<label className="col-12">Designation:</label>
										<input
											type="text"
											name="designation"
											value={formData?.designation}
											onChange={handleChange1}
											className="form-control"
										/>
									</div>
									<div className=" row">
										<label className="col-12">Speciality:</label>
										<button
											type="button"
											className="btn btn-secondary"
											onClick={() => setOpenItems(!openItems)}
											aria-controls={`example-collapse-text`}
											aria-expanded={openItems}
										>
											Choose Speciality <i className="fas fa-caret-down"></i>
										</button>
									</div>
									<div className="row mb-3">
										<Collapse
											className="dropdown-box dropScroll"
											in={openItems}
											id={`example-collapse-text`}
										>
											<div>
												{allSpecialities.map(
													(specialities, index) =>
														specialities.specialization !== "referral" &&
														specialities.specialization !==
															"Consultant Doctor" && (
															<Dropdown.Item
																onClick={() => {
																	handleSpecSelect(
																		specialities.specialization,
																		specialities._id
																	);
																}}
															>
																{specialities.specialization}
															</Dropdown.Item>
														)
												)}
											</div>
										</Collapse>
									</div>

									<div className="row ">
										<label className="form-label " htmlFor="email">
											<b>Selected Specialities: </b>
										</label>
										<div
											className="card col-12 divScroll"
											style={{ height: "100px" }}
										>
											<div className="row">
												{Array.isArray(specialities) &&
													specialities?.map((specialities) => (
														<ButtonGroup
															aria-label="Basic example"
															className="ml-2 mt-2"
														>
															<Button variant="light">{specialities}</Button>
															<Button
																variant="danger"
																onClick={() =>
																	handleDeleteSpecialities(specialities)
																}
															>
																<i className="fas fa-times"></i>
															</Button>
														</ButtonGroup>
													))}
											</div>
										</div>
									</div>
									<div className="form-group row">
										<label className="col-12">Education:</label>
										<input
											type="text"
											name="education"
											value={formData?.education}
											onChange={handleChange1}
											className="form-control"
										/>
									</div>
									<div className="form-group row">
										<label className="col-12">Experience:</label>
										<input
											type="date"
											name="experience"
											value={formData?.experience}
											onChange={handleChange1}
											className="form-control"
											max={todayInIST}
										/>
									</div>
									<div className="form-group row">
										<label className="col-12">Personality:</label>
										<input
											type="text"
											name="personality"
											value={formData?.personality}
											onChange={handleChange1}
											className="form-control"
										/>
									</div>
									<div className="form-group row">
										<label className="col-12">Op Consultation Fee:</label>
										<input
											type="text"
											name="opConsultationFee"
											value={formData?.opConsultationFee}
											onChange={handleChange1}
											className="form-control"
										/>
									</div>
									<div className="form-group row">
										<label className="col-12">Ip Consultation Fee:</label>
										<input
											type="text"
											name="ipConsultationFee"
											value={formData?.ipConsultationFee}
											onChange={handleChange1}
											className="form-control"
										/>
									</div>
									<div className="form-group row">
										<label className="col-12">Ip Surgery Fee:</label>
										<input
											type="text"
											name="ipSurgeryFees"
											value={formData?.ipSurgeryFees}
											onChange={handleChange1}
											className="form-control"
										/>
									</div>
									<button
										type="button"
										className="btn btn-secondary mr-2"
										onClick={prevStep}
									>
										Previous
									</button>
									<button
										type="button"
										className="btn btn-primary"
										onClick={nextStep}
									>
										Next
									</button>
								</div>
							)}
							{step === 3 && (
								<div>
									<h2>Appointment Information</h2>
									<div className="form-group row">
										<label className="col-12">Booking Period:</label>
										<input
											type="text"
											name="bookingPeriod"
											value={formData?.bookingPeriod}
											onChange={handleChange1}
											className="form-control"
										/>
									</div>
									<div>
										<div className="mt-3">
											{days.map((day, index) => (
												<button
													type="button"
													key={index}
													onClick={() => handleButtonClick(index)}
													style={{ borderRadius: "0px" }}
													className={
														activeButton === index
															? "btn btn-success"
															: "btn btn-secondary"
													}
												>
													{day}
												</button>
											))}
										</div>
										{!switche &&
											daysData?.map(
												(slotData, index) =>
													index === activeButton && (
														<div className="mt-4">
															<div className="row form-group">
																<Form.Check
																	type="checkbox"
																	className="offset-md-1"
																	onChange={(e) =>
																		handleChange(
																			slotData?.isWorking,
																			index,
																			"isWorking"
																		)
																	}
																	checked={slotData?.isWorking}
																/>
																<label
																	className="form-label col-md-8"
																	htmlFor="maxAppointmentsperhour"
																>
																	<b>is Working? </b>
																</label>
															</div>
															<div className="row form-group">
																<label
																	className="form-label col-md-3"
																	htmlFor="maxAppointmentsperhour"
																>
																	<b>Max appointments/hour: </b>
																	<span className="text-danger">
																		<b>*</b>
																	</span>
																</label>
																<input
																	className="form-control col-md-8"
																	id="maxAppointmentsperhour"
																	type="text"
																	required="required"
																	value={
																		!slotData?.isWorking
																			? ""
																			: slotData?.maxAppointmentsperhour
																	}
																	onChange={(e) => {
																		handleChange(
																			e.target.value,
																			index,
																			"maxAppointmentsperhour"
																		);
																	}}
																	disabled={!slotData?.isWorking}
																/>
															</div>
															<div className="row form-group">
																<label
																	className="form-label col-md-3"
																	htmlFor="startTime"
																>
																	<b>Start time: </b>
																	<span className="text-danger">
																		<b>*</b>
																	</span>
																</label>
																<input
																	className="form-control col-md-8"
																	type="time"
																	id="appt"
																	name="appt"
																	value={
																		slotData?.isWorking && slotData?.startTime
																	}
																	onChange={(e) => {
																		handleChange(
																			e.target.value,
																			index,
																			"startTime"
																		);
																	}}
																	disabled={!slotData?.isWorking}
																/>
															</div>
															<div className="row form-group">
																<label
																	className="form-label col-md-3"
																	htmlFor="endTime"
																>
																	<b>End time: </b>
																	<span className="text-danger">
																		<b>*</b>
																	</span>
																</label>
																<input
																	className="form-control col-md-8"
																	type="time"
																	id="appt"
																	name="appt"
																	value={
																		slotData?.isWorking && slotData?.endTime
																	}
																	onChange={(e) => {
																		handleChange(
																			e.target.value,
																			index,
																			"endTime"
																		);
																	}}
																	disabled={!slotData?.isWorking}
																/>
															</div>
															<div className="row form-group">
																<label
																	className="form-label col-md-3"
																	htmlFor="breakTime"
																>
																	<b>Break time: </b>
																	<span className="text-danger">
																		<b>*</b>
																	</span>
																</label>
																<input
																	className="form-control col-md-3"
																	type="time"
																	id="appt"
																	name="appt"
																	value={
																		slotData?.isWorking &&
																		slotData?.breakTime?.split("-")[0]
																	}
																	onChange={(e) =>
																		handleChange(
																			e.target.value,
																			index,
																			"startBreakTime"
																		)
																	}
																	disabled={!slotData?.isWorking}
																/>
																<input
																	className="form-control col-md-3 offset-md-1"
																	type="time"
																	id="appt"
																	name="appt"
																	value={
																		slotData?.isWorking &&
																		slotData?.breakTime?.split("-")[1]
																	}
																	onChange={(e) =>
																		handleChange(
																			e.target.value,
																			index,
																			"endBreakTime"
																		)
																	}
																	disabled={!slotData?.isWorking}
																/>
															</div>
														</div>
													)
											)}
										{switche &&
											singleSelectedDayswitch?.map(
												(slotData, index) =>
													index === activeButton && (
														<div className="mt-4">
															<div className="row form-group">
																<Form.Check
																	type="checkbox"
																	className="offset-md-1"
																	onChange={(e) =>
																		handleChangeSwitch(
																			slotData?.isWorking,
																			index,
																			"isWorking"
																		)
																	}
																	checked={slotData?.isWorking}
																/>
																<label
																	className="form-label col-md-8"
																	htmlFor="maxAppointmentsperhour"
																>
																	<b>is Working? </b>
																</label>
															</div>
															<div className="row form-group">
																<label
																	className="form-label col-md-3"
																	htmlFor="maxAppointmentsperhour"
																>
																	<b>Max appointments/hour: </b>
																	<span className="text-danger">
																		<b>*</b>
																	</span>
																</label>
																<input
																	className="form-control col-md-8"
																	id="maxAppointmentsperhour"
																	type="text"
																	required="required"
																	value={
																		!slotData?.isWorking ||
																		index !== switchActivated
																			? ""
																			: slotData?.maxAppointmentsperhour
																	}
																	onChange={(e) => {
																		handleChangeSwitch(
																			e.target.value,
																			index,
																			"maxAppointmentsperhour"
																		);
																	}}
																	disabled={
																		!slotData?.isWorking ||
																		index !== switchActivated
																	}
																/>
															</div>
															<div className="row form-group">
																<label
																	className="form-label col-md-3"
																	htmlFor="startTime"
																>
																	<b>Start time: </b>
																	<span className="text-danger">
																		<b>*</b>
																	</span>
																</label>
																<input
																	className="form-control col-md-8"
																	type="time"
																	id="appt"
																	name="appt"
																	value={
																		slotData?.isWorking && slotData?.startTime
																	}
																	onChange={(e) => {
																		handleChangeSwitch(
																			e.target.value,
																			index,
																			"startTime"
																		);
																	}}
																	disabled={
																		!slotData?.isWorking ||
																		index !== switchActivated
																	}
																/>
															</div>
															<div className="row form-group">
																<label
																	className="form-label col-md-3"
																	htmlFor="endTime"
																>
																	<b>End time: </b>
																	<span className="text-danger">
																		<b>*</b>
																	</span>
																</label>
																<input
																	className="form-control col-md-8"
																	type="time"
																	id="appt"
																	name="appt"
																	value={
																		slotData?.isWorking && slotData?.endTime
																	}
																	onChange={(e) => {
																		handleChangeSwitch(
																			e.target.value,
																			index,
																			"endTime"
																		);
																	}}
																	disabled={
																		!slotData?.isWorking ||
																		index !== switchActivated
																	}
																/>
															</div>
															<div className="row form-group">
																<label
																	className="form-label col-md-3"
																	htmlFor="breakTime"
																>
																	<b>Break time: </b>
																	<span className="text-danger">
																		<b>*</b>
																	</span>
																</label>
																<input
																	className="form-control col-md-3"
																	type="time"
																	id="appt"
																	name="appt"
																	value={
																		slotData?.isWorking &&
																		slotData?.breakTime?.split("-")[0]
																	}
																	onChange={(e) =>
																		handleChangeSwitch(
																			e.target.value,
																			index,
																			"startBreakTime"
																		)
																	}
																	disabled={
																		!slotData?.isWorking ||
																		index !== switchActivated
																	}
																/>
																<input
																	className="form-control col-md-3 offset-md-1"
																	type="time"
																	id="appt"
																	name="appt"
																	value={
																		slotData?.isWorking &&
																		slotData?.breakTime?.split("-")[1]
																	}
																	onChange={(e) =>
																		handleChangeSwitch(
																			e.target.value,
																			index,
																			"endBreakTime"
																		)
																	}
																	disabled={
																		!slotData?.isWorking ||
																		index !== switchActivated
																	}
																/>
															</div>
														</div>
													)
											)}

										<div className="row offset-md-2 form-group">
											{switchActivated === null ? (
												<div className="col-md-6 row">
													<Switch
														className="col-md-1"
														onChange={handleSwitch}
													/>{" "}
													<label className="form-label col-md-11">
														<b>
															Apply {days[activeButton]}'s configurations to all
															days
														</b>
													</label>
												</div>
											) : (
												<div className="col-md-6 row">
													<Switch
														className="col-md-1"
														id="switch"
														onChange={handleSwitch}
													/>{" "}
													<label className="form-label col-md-10">
														<b>
															Applied {days[switchActivated]}'s configurations
															to all days
														</b>
													</label>
												</div>
											)}
										</div>
									</div>
									<button
										type="button"
										className="btn btn-secondary mr-2"
										onClick={prevStep}
									>
										Previous
									</button>
									<button
										type="submit"
										className="btn btn-primary"
										onClick={handleSubmit1}
									>
										Submit
									</button>
								</div>
							)}
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddDoctorScreens;
