/** @format */

import React from 'react';
import { Spinner } from 'react-bootstrap';

const ButtonLoader = () => {
	return (
		<Spinner
			animation='border'
			role='status'
			style={{
				width: '20px',
				height: '20px',
				margin: 'auto',
			}}></Spinner>
	);
};

export default ButtonLoader;
