/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loader from "../Components/Loader";
import Meta from "../Components/Meta";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FaStar } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import { Input } from "reactstrap";
import Rating from "../Components/Rating";
// import {feedbackScreen} from "../redux/userApiCalls";
import Message from "../Components/Message";
import { getUserDataByCategoryForUser } from "../redux/adminRedux/adminApiCalls";
import {
  createReview,
  getReviews,
  // updateReview,
  getUserReview,
} from "../redux/userApiCalls";
//reset calls
import { ProductReviewCreateReset } from "../redux/userReview";
import { ProductReviewGetReset } from "../redux/getReviews";
import { UserReviewGetReset } from "../redux/getUserReview";
import { getUserDataByCategoryReset } from "../redux/adminRedux/Patient_Management/getUserDataByCategory";
import {
  allMessagesStart,
  allMessagesSuccess,
  allMessagesFailure,
  allMessagesReset,
} from "../redux/allMessages";

const commentsInitial = 5;

const FeedbackScreens = () => {
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const [reviews, setReviews] = useState("");
  // success and error
  const { getReviewsall, loading, error } = useSelector(
    (state) => state.userReviewsAll
  );
  const createdreview = useSelector((state) => state.userReviewCreate);
  const loading1 = createdreview.loading;
  const error1 = createdreview.error;
  const success1 = createdreview.success;
  console.log(success1);

  const getReviewData = useSelector((state) => state.userReview);
  const getReview = getReviewData.getReview;
  const getReviewloading = getReviewData.loading;
  const getReviewerror = getReviewData.error;
  const getReviewsuccess = getReviewData.success;

  const data1 = useSelector((state) => state.getUserdataByCategory);
  const getUserdataByCategoryerror = data1.error;
  const getUserdataByCategoryloading = data1.loading;
  const getUserdataByCategorysuccess = data1.success;
  ////////////////////////////////////////////////////////////////////////
  const [hover, setHover] = useState(null);
  const [next, setNext] = useState(commentsInitial);

  console.log(getReview);
  let allreviews = [];
  if (getReviewsall) allreviews = getReviewsall.docs;
  console.log(allreviews + "reviews345");
  const onChange = (e) => {
    setReviews(e.target.value);
  };
  console.log(allreviews);
  const loggedIn = useSelector((state) => state.userLogin);
  console.log(loggedIn.userInfo + "line74");
  const userinfo1 = loggedIn.userInfo;
  const mno = userinfo1?.data?.user?.mobilenumber;
  console.log(userinfo1?.data?.user + "line76");
  const userInfosuccess = loggedIn.success;
  //setReviews(getReview.data.data.review)
  // if (getReview){
  // 	console.log(getReview.data,getReview.data.data.review)
  // setRating(getReview.data.data.rating)
  //
  // }
  useEffect(() => {
    dispatch(getReviews());
    dispatch(getUserReview());
  }, [dispatch, loading1, error1]);
  useEffect(() => {
    console.log(mno + "line88");
    dispatch(getUserDataByCategoryForUser(mno));
    // if(mno !== "")
    // dispatch(getUserDataByCategory(mno));
    // if(mno !== "") {
    //   dispatch(getUserDataByCategory(mno));
    // }
  }, [mno, loading1, error1]);

  const userData = data1.userData;
  const reviewid = userData?.data?.data?.reviewId;
  const onSubmit = (e) => {
    e.preventDefault();
    if (rating == 0) {
      alert("Rating cannot be 0");
    } else if (reviews == "") {
      alert("Reviews cannot be empty");
    } else {
      dispatch(createReview(rating, reviews));
      setRating(0);
      setReviews("");
    }
  };
  const handleClick = (ratingValue) => {
    setRating(ratingValue);
  };
  const handleMoreComments = () => {
    setNext(Math.max(next + commentsInitial, allreviews.length));
  };

  // ALerts////////////////////////////////////////////////////////
  const [timerRunning, setTimerRunning] = useState(false);
  useEffect(() => {
    dispatch(allMessagesStart());
  }, []);

  const [showPopup, setShowPopup] = React.useState(false);

  const [showPopup1, setShowPopup1] = React.useState(false);

  useEffect(() => {
    if (success1 && !timerRunning) {
      setTimerRunning(true);

      const timer = setTimeout(() => {
        // Clear the alert and stop the timer after 3 seconds
        dispatch(ProductReviewCreateReset());

        setTimerRunning(false);
      }, 3000);

      // Clean up the timer if the component unmounts or the alert changes
    }
  }, [success1, dispatch]);

  const [showPopup2, setShowPopup2] = React.useState(false);

  const [showPopup3, setShowPopup3] = React.useState(false);

  console.log(next, allreviews.length);
  return (
    <>
      <Meta />
      {(loading ||
        loading1 ||
        getUserdataByCategoryloading ||
        getReviewloading) && <Loader />}

      <h1 className="header-center">Feedbacks Page</h1>
      <Col xs="12" md={{ span: 8, offset: 2 }}>
        {allreviews.length ? (
          allreviews.slice(0, next)?.map((review) => (
            <Card>
              <Card.Header>Review</Card.Header>
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <Rating
                    value={review.rating}
                    text={`${review.review} reviews`}
                  />
                  <p> {review.review} </p>
                  <footer className="blockquote-footer">
                    {review.user.name}
                  </footer>
                </blockquote>
              </Card.Body>
            </Card>
          ))
        ) : (
          <h2>No feedbacks</h2>
        )}
      </Col>
      {allreviews.length > 5 && allreviews.length > next && (
        <Col md={{ span: 3, offset: 6 }}>
          <Button variant="primary" onClick={handleMoreComments}>
            Load more
          </Button>
        </Col>
      )}
      <div className="form-container col-md-8 offset-md-2">
        {userinfo1 &&
          userInfosuccess &&
          !reviewid &&
          !getReview?.data?.data?.user && (
            <div>
              {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1;
                return (
                  <label key={i}>
                    <input
                      className="d-none"
                      type="radio"
                      name="rating"
                      value={ratingValue}
                      onClick={() => handleClick(ratingValue)}
                    />
                    <FaStar
                      className="star"
                      color={
                        ratingValue <= (hover || rating) ? "gold" : "lightgray"
                      }
                      size={20}
                      onMouseEnter={() => setHover(ratingValue)}
                      onMouseLeave={() => setHover(null)}
                      value={rating}
                    />
                  </label>
                );
              })}
            </div>
          )}
        {userinfo1 && !reviewid && !getReview?.data?.data?.user && (
          <Form onSubmit={onSubmit}>
            <Input
              className="form-control"
              type="text"
              placeholder="Enter your review"
              value={reviews}
              onChange={onChange}
              minLength="5"
              maxLength="500"
            />
            <br></br>
            {getReview?.data?.data?.user ? (
              <Button type="submit" className="btn btn-primary">
                Update
              </Button>
            ) : (
              <Button type="submit" className="btn btn-primary">
                Submit
              </Button>
            )}
          </Form>
        )}
      </div>
    </>
  );
};

export default FeedbackScreens;
