/** @format */

import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Table from "react-bootstrap/Table";
import Meta from "../../../Components/Meta";
import Form from "react-bootstrap/Form";
import {
	createDoctor,
	getLeaves,
	deleteDoctor,
} from "../../../redux/adminRedux/adminApiCalls";
import { getSpecialities } from "../../../redux/userApiCalls";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Message from "../../../Components/Message";
import { createDoctorReset } from "../../../redux/adminRedux/Doctor_Management/createDoctor";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import axios from "axios";
import { SERVER_URL } from "../../../App";
import Button from "react-bootstrap/Button";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../../redux/allMessages";
import { deleteDoctorReset } from "../../../redux/adminRedux/Doctor_Management/deleteDoctor";
import { getLeavesReset } from "../../../redux/adminRedux/Doctor_Management/getLeaves";
import { specialityGetReset } from "../../../redux/getSpecialities";

const DoctorLeavesScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const [timerRunning, setTimerRunning] = useState(false);

	const getSpecialityAllData = useSelector((state) => state.getSpeciality);
	const getSpecialityAll = getSpecialityAllData.getSpecialityAll;
	const getSpecialityAllsuccess = getSpecialityAllData.success;
	const getSpecialityAllerror = getSpecialityAllData.error;
	const getSpecialityAllloading = getSpecialityAllData.loading;

	const [DoctorFromSpecialityArray, setDoctorFromSpecialityArray] = useState(
		[]
	);
	// console.log(getSpecialityAll);
	let allSpecialities = [];
	if (getSpecialityAll) {
		if (getSpecialityAll.data) {
			if (getSpecialityAll.data.data) {
				for (let i = 0; i < getSpecialityAll.data.data.length; i++) {
					allSpecialities.push(getSpecialityAll.data.data[i]);
				}
			}
		}
	}
	console.log(allSpecialities);
	let allDoctors = [];

	//Doctor Leaves
	const [specialityOption, setSpecialityOption] = useState("Choose Speciality");
	const [specialityId, setSpecialityId] = useState("");
	const [doctorOption, setDoctorOption] = useState("Choose Doctor");
	const [doctorId, setDoctorId] = useState("");
	const [searchBtnClick, setSearchBtnClick] = useState(false);

	const handleSpecialitySelectLeaves = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		setSpecialityOption(arr[0]);
		setSpecialityId(arr[1]);
		setDoctorOption("Choose Doctor");
		setSearchBtnClick(false);
		setDoctorFromSpecialityArray(
			getSpecialityAll.data.data[arr[2]].doctorsArray
		);
	};

	const handleDoctorSelect = (eventKey) => {
		let arr = [];
		arr = eventKey.split("/");
		setDoctorId(arr[1]);
		setDoctorOption(arr[0]);
		setSearchBtnClick(false);
	};

	const submitHandlerLeaves = () => {
		setSearchBtnClick(true);
		console.log(doctorId + "line92");
		dispatch(getLeaves(doctorId));
	};

	const leavesData = useSelector((state) => state.getLeavesForAdmin);

	const getDoctorLeavesloading = leavesData.loading;
	const getDoctorLeavessuccess = leavesData.success;
	const getDoctorLeaveserror = leavesData.error;

	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);

	return (
		<>
			<Meta />

			{getDoctorLeavesloading && getSpecialityAllloading && <Loader />}

			<>
				<h1 className="header-center">Doctor Leaves</h1>
				<Container className="header-center mt-5">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center mt-1">
							<h6>
								Select the filters{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={specialityOption}
								onSelect={handleSpecialitySelectLeaves}
							>
								{allSpecialities.map(
									(specialities, index) =>
										specialities.specialization !== "referral" &&
										specialities.specialization !== "Consultant Doctor" && (
											<Dropdown.Item
												eventKey={
													specialities.specialization +
													"/" +
													specialities._id +
													"/" +
													index
												}
											>
												{specialities.specialization}
											</Dropdown.Item>
										)
								)}
							</DropdownButton>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={doctorOption}
								onSelect={handleDoctorSelect}
							>
								{DoctorFromSpecialityArray.map((doctors, index) => (
									<Dropdown.Item
										eventKey={doctors.name + "/" + doctors.doctorsinfo}
									>
										{doctors.name}
									</Dropdown.Item>
								))}
							</DropdownButton>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<Button
								id="searchButton"
								className="btn-primary"
								onClick={submitHandlerLeaves}
							>
								Search
							</Button>
						</Col>
					</Row>
				</Container>
				{searchBtnClick ? (
					<Container className="header-center mt-5 col-md-8">
						<Table striped bordered hover className="custom-table">
							<thead>
								<tr>
									<th>Description</th>
									<th>Date</th>
								</tr>
							</thead>
							<tbody>
								{leavesData?.getLeaves?.data?.data?.map(
									({ reason, date, _id }) => {
										date = date?.toString();
										let datearr = date.split("");
										date =
											datearr[6] +
											datearr[7] +
											"-" +
											datearr[4] +
											datearr[5] +
											"-" +
											datearr[0] +
											datearr[1] +
											datearr[2] +
											datearr[3];
										return (
											<tr>
												<td>{reason}</td>
												<td>{date}</td>
											</tr>
										);
									}
								)}
							</tbody>
						</Table>
					</Container>
				) : (
					<div> </div>
				)}
			</>
		</>
	);
};

export default DoctorLeavesScreens;
