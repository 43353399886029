/** @format */

import React, { useEffect, useState, useCallback, useRef } from "react";
import { MultiSelect } from "react-multi-select-component";
import {
	Button,
	Form,
	FormControl,
	FormLabel,
	FormCheck,
	Modal,
	Container,
	Row,
	Col,
	Card,
	Table,
	ListGroup,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEdit,
	faTrash,
	faPlus,
	faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
	GetPrescriptionTemplateNames,
	GetPrescriptionTemplate,
} from "../../../redux/doctorRedux/doctorApiCalls";
import { Pagination } from "react-bootstrap";
import { createCategory } from "../../../redux/userApiCalls";
import { getUserDataById } from "../../../redux/prescriptionRedux/prescriptionApiCalls";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
	getTemplate1,
	getTemplateNames, //
} from "../../../redux/doctorRedux/doctorApiCalls";
import { getLabDepts } from "../../../redux/adminRedux/adminApiCalls";
import {
	addVitalInSpeciality,
	getVitalInSpeciality,
} from "../../../redux/adminRedux/adminApiCalls";
import {
	getCategoriesVitals, //
} from "../../../redux/prescriptionRedux/prescriptionApiCalls";
import {
	createPrescription,
	onSpotPrescription, //
} from "../../../redux/prescriptionRedux/prescriptionApiCalls";
import {
	startPrescriptionToStartHistoryRequest,
	startPrescriptionToStartHistorySuccess,
	startPrescriptionToStartHistoryFailure,
	startPrescriptionToStartHistoryReset,
} from "../../../redux/UINavigationRedux/StartPrescriptionToStartHistory";
import { createPrescriptionReset } from "../../../redux/prescriptionRedux/createPrescription";
import { getTemplateRequest } from "../../../redux/doctorRedux/Template_Management/getTemplate";
import SectionNavigator from "../../../Components/SectionNavigator";
import { GetPrescriptionTemplateStart } from "../../../redux/doctorRedux/Prescription_Management/GetPrescriptionTemplate";
import { GetPrescriptionTemplateNamesStart } from "../../../redux/doctorRedux/Prescription_Management/GetPrescriptionTemplateNames";
import debounce from "lodash/debounce";
const StartPrescriptionScreens = ({ setNavigatorItems, fuseState }) => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	console.log(location?.state);
	const { getrecommendationData } = useSelector(
		(state) => state.recommendationData
	);
	const [results, setResults] = useState([]); // Search suggestions
	const [activeIndex, setActiveIndex] = useState(-1); // Index for keyboard navigation
	const handleFuseInputChange = (e) => {
		const query = e.target.value;
		// setQuery(query);
		console.log(fuseState, "line74");
		console.log(query);
		if (query.length >= 2) {
			const fuseResults = fuseState.search(query); // Start searching
			console.log(fuseResults);
			const suggestions = fuseResults.map((result) => result.item); // Extract matched arrays

			// If the word isn't in the original data, add it as a suggestion
			// const isNewWord = !data.some((dataItem) => dataItem[0] === query);
			// if (isNewWord && !selectedItems.includes(query)) {
			suggestions.push([query]); // Add user input as a suggestion
			// }

			setResults(suggestions);
			setActiveIndex(-1); // Reset index when new query is typed
		} else {
			setResults([]);
		}
	};
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	console.log(doctorInfo);
	useEffect(() => {
		dispatch(GetPrescriptionTemplateNames(location?.state?.speciality));
		dispatch(getVitalInSpeciality(location?.state?.speciality));
	}, []);

	let getPrescriptionTemplateNames = useSelector(
		(state) => state.GetPrescriptionTemplateNames
	);

	console.log(getPrescriptionTemplateNames);

	const [templateName, setTemplateName] = useState([]);
	const [navItemsTemplatesAndIdxs, setNavItemsTemplatesAndIdxs] = useState([]);
	const [tempName, setTempName] = useState("Choose Template");
	const [templateIdx, setTemplateIdx] = useState([]);
	const [formValues, setFormValues] = useState({}); // State variable to hold form values

	const createCategoryData = useSelector((state) => state.createCategory);
	const createCategorySuccess = createCategoryData.success;
	const createCategoryFailure = createCategoryData.error;
	const createCategoryloading = createCategoryData.loading;

	const createPrescriptionData = useSelector(
		(state) => state.createPrescription
	);
	const createPrescriptionSuccess = createPrescriptionData.success;
	const createPrescriptionError = createCategoryData.error;
	const createPrescriptionLoading = createPrescriptionData.loading;

	const onSpotPrescriptionData = useSelector(
		(state) => state.onSpotPrescription
	);
	const onSpotPrescriptionSuccess = onSpotPrescriptionData.success;
	const onSpotPrescriptionError = createCategoryData.error;
	const onSpotPrescriptionLoading = onSpotPrescriptionData.loading;

	let flag = false;

	const [getTemplateDataEdited, setGetTemplateDataEdited] = useState(false);

	const TemplateHandler = (temp) => {
		let arr = temp?.split("@@");
		if (arr?.length > 0) {
			if (templateName?.includes(arr[0]) && arr[0] === "Medication") {
				let index = templateName?.indexOf("Medication");
				alert(`Medication is already selected in ${index + 1} page.`);
			} else if (templateName?.includes(arr[0]) && arr[0] === "Lab Tests") {
				let index = templateName?.indexOf("Lab Tests");
				alert(`Lab Tests is already selected in ${index + 1} page.`);
			} else {
				setTempName(arr[0]);
				let templates = [...templateName];
				templates?.push(arr[0]);
				let templatesidx = [...templateIdx];
				templatesidx?.push(arr[1]);
				setTemplateName(templates);
				let navItemsTemplates = [...navItemsTemplatesAndIdxs];
				navItemsTemplates?.push({
					templateName: arr[0],
					templateIdx: navItemsTemplates?.length,
				});
				setNavItemsTemplatesAndIdxs(navItemsTemplates);
				setNavigatorItems(navItemsTemplates);
				setTemplateIdx(templatesidx);
				setGetTemplateDataEdited(false);
				dispatch(GetPrescriptionTemplate(location?.state?.speciality, arr[2]));
				handleCloseTemplateselectionModal();
				setTempName("Choose Template");
			}
		}
	};

	let { getPrescriptionTemplate, loading } = useSelector(
		(state) => state.GetPrescriptionTemplate
	);

	const [prescritpionTemplate, setPrescritpionTemplate] = useState([]);

	useEffect(() => {
		dispatch(GetPrescriptionTemplateStart());
		setPrescritpionTemplate([]);
	}, []);

	console.log(prescritpionTemplate);

	console.log(getPrescriptionTemplate);

	// Handler for input elements
	const handleInputChange = (
		event,
		label,
		index,
		eleindex,
		row = 0,
		col = 0,
		type = ""
	) => {
		if (type === "table") {
			const templateArray = prescritpionTemplate[index];

			const updatedFormData = templateArray.map((element, arrIndex) => {
				if (arrIndex === eleindex) {
					// Clone the element and update only if cellData key exists
					const updatedElement = { ...element };

					// Check if cellData object exists and contains the key
					if (
						updatedElement.cellData &&
						updatedElement.cellData.hasOwnProperty(`${row}-${col}`)
					) {
						updatedElement.cellData[`${row}-${col}`] = event?.target?.value;
					}

					return updatedElement;
				}
				return element;
			});

			let templates = [...prescritpionTemplate];
			templates[index] = updatedFormData;

			setPrescritpionTemplate(templates);
		} else {
			const templateArray = prescritpionTemplate[index];

			const updatedFormData = templateArray.map((element, arrIndex) => {
				if (arrIndex === eleindex) {
					return { ...element, value: event?.target?.value };
				}
				return element;
			});
			let templates = [...prescritpionTemplate];
			templates[index] = updatedFormData;

			setPrescritpionTemplate(templates);
		}
	};

	// Handler for checkbox and radio elements
	const handleCheckboxRadioChange = (event, label, option, index, eleindex) => {
		if (event.target.type === "checkbox") {
			// For checkboxes, toggle the selected option
			const selectedOptions =
				prescritpionTemplate[index][eleindex]?.value || [];
			const updatedOptions = event.target.checked
				? [...selectedOptions, option]
				: selectedOptions.filter((item) => item !== option);

			const templateArray = prescritpionTemplate[index];

			const updatedFormData = templateArray.map((element, arrIndex) => {
				if (arrIndex === eleindex) {
					return { ...element, value: updatedOptions };
				}
				return element;
			});
			let templates = [...prescritpionTemplate];
			templates[index] = updatedFormData;

			setPrescritpionTemplate(templates);
		} else if (event.target.type === "radio") {
			// For radio buttons, select only the clicked option
			const templateArray = prescritpionTemplate[index];

			const updatedFormData = templateArray.map((element, arrIndex) => {
				if (arrIndex === eleindex) {
					return { ...element, value: option };
				}
				return element;
			});
			let templates = [...prescritpionTemplate];
			templates[index] = updatedFormData;

			setPrescritpionTemplate(templates);
		}
	};

	const handleMultiselectChange = (selectedOptions, label, index, eleindex) => {
		// Extract labels and values from selectedOptions
		const selectedValues = selectedOptions.map((option) => ({
			label: option.label,
			value: option.value,
		}));

		const templateArray = prescritpionTemplate[index];

		const updatedFormData = templateArray.map((element, arrIndex) => {
			if (arrIndex === eleindex) {
				return { ...element, value: selectedValues };
			}
			return element;
		});
		let templates = [...prescritpionTemplate];
		templates[index] = updatedFormData;

		setPrescritpionTemplate(templates);
	};

	// Handler for select
	const handleSelectChange = (event, label, index, eleindex) => {
		const templateArray = prescritpionTemplate[index];

		const updatedFormData = templateArray.map((element, arrIndex) => {
			if (arrIndex === eleindex) {
				return { ...element, value: event?.target?.value };
			}
			return element;
		});
		let templates = [...prescritpionTemplate];
		templates[index] = updatedFormData;

		setPrescritpionTemplate(templates);
	};

	const listRef = useRef(null); // Reference to the ListGroup

	const [showList, setShowList] = useState(true);

	const handleCellChange = (row, col, value, index) => {
		setFormElements((prevElements) => {
			const updatedElements = [...prevElements];

			if (updatedElements[index]) {
				// Initialize cellData as an empty object if it doesn't exist
				updatedElements[index].cellData = updatedElements[index].cellData || {};
				updatedElements[index].cellData[`${row}-${col}`] = value;
			}

			return updatedElements;
		});
	};

	// Handle table resizing while preserving data
	const resizeTable = (newRows, newColumns, index) => {
		setFormElements((prevElements) => {
			const updatedElements = [...prevElements];

			if (updatedElements[index]) {
				// Create a copy of the specific element and update its properties
				const updatedElement = { ...updatedElements[index] };
				updatedElement.rows = newRows;
				updatedElement.cols = newColumns;

				// Replace the old element with the updated element
				updatedElements[index] = updatedElement;
			}

			return updatedElements;
		});
	};

	// Handle click outside to collapse the ListGroup
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (listRef.current && !listRef.current.contains(event.target)) {
				setShowList(false); // Hide the list if clicked outside
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSubmitForm = async () => {
		const repeatedIndex = Medication.findIndex((med) => med.repeated === true);

		if (repeatedIndex !== -1) {
			// Find the first invalid element (e.g., using the is-invalid class)
			const invalidElement = document.querySelector(".is-invalid");

			// Smooth scroll to the invalid element
			if (invalidElement) {
				invalidElement.scrollIntoView({
					behavior: "smooth",
					block: "center", // Scroll to center the invalid element
				});
			}
		} else {
			let updatedMed = Medication?.map((medicine, index) => {
				let dosage = "";
				for (let i = 0; i < newWords.length; i++) {
					if (newWords[i]) {
						if (newWords[i][0] === medicine?.name) {
							if (medicine.isBF === 1) {
								newWords[i][19] = "Before Food";
							} else if (medicine.isBF === 2) {
								newWords[i][19] = "After Food";
							}
							if (medicine.isMorining) {
								newWords[i][5] += true;
							}
							if (medicine.isAfterNoon) {
								newWords[i][6] += true;
							}
							if (medicine.isNight) {
								newWords[i][7] += true;
							}
							newWords[i][15] = medicine?.occuring;
						}
					}
				}
				if (medicine?.name !== "") {
					// If activeBox is false, assign customFreq
					if (!medicine.activeBox) {
						dosage = "O@@" + medicine.customFreq;
					} else {
						// If activeBox is true, check isBF and other conditions
						if (medicine.isBF === 1) {
							dosage = "B/F,";
						} else if (medicine.isBF === 2) {
							dosage = "A/F,";
						}

						// Add morning, afternoon, and night indicators based on flags
						if (medicine.isMorining) {
							dosage += "M,";
						}
						if (medicine.isAfterNoon) {
							dosage += "A,";
						}
						if (medicine.isNight) {
							dosage += "N,";
						}
						// Trim any trailing comma or spaces
						dosage = dosage.trim().replace(/,$/, "");
					}
					return {
						name: medicine?.name,
						dosage: dosage,
						duration:
							medicine?.duration +
							" " +
							medicine?.durationType +
							" " +
							medicine?.occuring,
					};
				}
			});
			console.log(newWords + "line344");
			let newWordsObj = [
				{
					_id: "66df448c7713bed172d8e7ec",
					newNamesArray: newWords,
				},
			];
			let extractedValuesTest = [];

			LabTestsRows?.map((test, index) => {
				extractedValuesTest.push({
					deptId: test?.deptId,
					deptName: test?.deptName,
					testName: test?.testName,
					testIdx: test?.testIndex,
					testPrice: test?.testPrice,
					description: test?.description,
					presentInHospital: Number(test?.presentInHospital),
				});
			});

			console.log(extractedValuesTest);

			console.log("Form submitted:", prescritpionTemplate);

			let categories = [];
			categories?.push(
				chooseCategory?.map((option, index) => {
					return option?.value;
				})
			);

			console.log(vitals);
			const filteredVitals = vitals.filter((vital) => vital.vitalValue !== "");

			console.log(categories[0]);

			const hasEmptyStringOrUndefinedInPrescriptionTemplate =
				prescritpionTemplate.some((item, index) => {
					if (
						templateName[index] === "Medication" ||
						templateName[index] === "Lab Tests"
					) {
						return false; // Skip this iteration
					} else if (item === "" || item === undefined) {
						return true;
					}
					return false; // Ensure false is returned for all other cases
				});

			if (title === "") {
				alert("Enter the Title");
			} else if (categories[0]?.length <= 0) {
				alert("Enter the categories");
			} else if (hasEmptyStringOrUndefinedInPrescriptionTemplate) {
				alert("Empty page cannot be submitted");
			} else {
				if (location?.state?.bookingId === "Emergency Booking") {
					await dispatch(
						onSpotPrescription(
							extractedValuesTest?.length === 1 &&
								extractedValuesTest[0]?.deptId === ""
								? []
								: extractedValuesTest,
							filteredVitals,
							categories[0],
							title,
							doctorInfo !== undefined
								? doctorInfo?.data?.user?._id
								: location?.state?.doctorId,
							doctorInfo !== undefined
								? doctorInfo?.data?.user?.name
								: location?.state?.docname,
							updatedMed?.length === 1 && updatedMed[0]?.name === ""
								? []
								: updatedMed,
							location.state.name,
							location.state.id,
							location?.state?.date,
							location.state.speciality,
							location.state.specialityName,
							location?.state?.userIdx,
							doctorInfo !== undefined
								? doctorInfo?.data?.user?.opConsultationFee
								: "",
							prescritpionTemplate,
							templateName,
							newWordsObj
						)
					);
					setShow(true);
				} else {
					await dispatch(
						createPrescription(
							filteredVitals,
							categories[0],
							title,
							doctorInfo !== undefined
								? doctorInfo?.data?.user?._id
								: location?.state?.doctorId,
							doctorInfo !== undefined
								? doctorInfo?.data?.user?.name
								: location?.state?.docname,
							updatedMed?.length === 1 && updatedMed[0]?.name === ""
								? []
								: updatedMed,
							location.state.name,
							location.state.id,
							location?.state?.date,
							location.state.speciality,
							location.state.specialityName,
							extractedValuesTest?.length === 1 &&
								extractedValuesTest[0]?.deptId === ""
								? []
								: extractedValuesTest,
							location.state.bookingId,
							location?.state?.userIdx,
							prescritpionTemplate,
							templateName,
							newWordsObj
						)
					);
					setShow(true);
				}
			}
		}
	};

	const allVitals = useSelector((state) => state.getVitalInSpeciality);

	console.log(allVitals);
	const [lgShowVitals, setLgShowVitals] = useState(false);
	const [vitals, setVitals] = useState([]);

	useEffect(() => {
		if (!allVitals?.loading) {
			let vitalsarr = [];
			allVitals?.allVitals?.data?.data?.vitals?.forEach((vital) => {
				vitalsarr.push({
					vitalName: vital,
					vitalValue: "",
				});
			});
			setVitals(vitalsarr); // Set vitals with the new array
		}
	}, [allVitals?.loading]);

	console.log(vitals);

	const vitalChange = (value, index) => {
		const newVitals = [...vitals];
		newVitals[index].vitalValue = value;
		setVitals(newVitals);
	};

	console.log(vitals);

	const { allCategoryVitals, error, success } = useSelector(
		(state) => state.getCategoriesVitals
	);

	useEffect(() => {
		dispatch(
			getCategoriesVitals(
				location.state.id,
				location?.state?.name,
				location?.state?.userIdx
			)
		);
	}, [
		history,
		dispatch,
		error,
		success,
		createCategorySuccess,
		createCategoryFailure,
		createCategoryloading,
	]);

	function addVitalOnSubmit(event) {
		event.preventDefault();
		const vitalName = document.getElementById("vitalName").value;
		const vitalUnits = document.getElementById("vitalUnits").value;
		console.log("Vital Name:", vitalName);
		console.log("Vital Units:", vitalUnits);
		setLgShowVitals(false);
		const newVitals = [...vitals];
		newVitals?.push({
			vitalName: vitalName + " (" + vitalUnits + ")",
			vitalValue: "",
		});
		setVitals(newVitals);
		dispatch(addVitalInSpeciality(vitalName + " (" + vitalUnits + ")"));
	}

	const [Category, setCategory] = useState("");
	const [lgShow, setLgShow] = useState(false);
	const [chooseCategory, setChooseCategory] = useState([]);
	const [title, setTitle] = useState("");
	const { userdata } = useSelector((state) => state.getUserDataById);
	console.log(userdata);
	useEffect(() => {
		dispatch(
			getUserDataById(
				location.state.id,
				location.state.name,
				location?.state?.userIdx
			)
		);
	}, []);

	console.log(chooseCategory);
	const addCategoryOnSubmit = (e) => {
		e.preventDefault();
		let flag = false;
		console.log(Category);
		for (let i = 0; i < allCategoryVitals?.data?.data?.category?.length; i++) {
			if (
				allCategoryVitals?.data?.data?.category[i].toLowerCase() ===
				Category?.toLowerCase()
			) {
				flag = true;
			}
		}
		if (flag) {
			alert(Category + " already exists");
		} else {
			var letters = /^[A-Za-z\s]+$/;
			if (Category === "") {
				alert("Category cannot be empty");
			} else {
				console.log(Category, userdata?.data?.data?.name);
				dispatch(
					createCategory(
						location.state.id,
						Category,
						location?.state?.name,
						location?.state?.userIdx
					)
				); // add category dispatch function
				let cates = [...chooseCategory];
				cates?.push({
					label: Category,
					value: Category,
				});
				setChooseCategory(cates);
				setLgShow(false);
			}
		}
	};

	const [Medication, setMedication] = useState([
		{
			activeBox: true,
			customFreq: "",
			isBF: 0,
			isMorining: "",
			isAfterNoon: "",
			isNight: "",
			quantity: "",
			durationType: "Days",
			duration: 0,
			name: "",
			occuring: "Daily",
			templateName: "Manual",
			repeated: false,
		},
	]);

	const addNewMedicationRow = () => {
		const newMedicationRow = {
			activeBox: true,
			customFreq: "",
			isBF: 0,
			isMorining: "",
			isAfterNoon: "",
			isNight: "",
			quantity: "",
			durationType: "Days",
			duration: 0,
			name: "",
			occuring: "Daily",
			templateName: "Manual",
			repeated: false,
		};
		setMedication([...Medication, newMedicationRow]);
	};
	const [newWords, setNewWords] = useState([]); // Collect new words

	const editMedicationFromRecommendation = (index, item) => {
		let updatedMedicinesData = [...Medication];

		if (item.length == 1) {
			console.log("true");
			let updatedNewWords = [...newWords];
			let newWordArr = Array(23).fill("");
			newWordArr.unshift(item[0]);
			newWordArr[15] = "Daily";
			updatedNewWords[index] = newWordArr;
			setNewWords(updatedNewWords);
			item = newWordArr;
		}
		console.log();
		{
			let flag = 0;
			if (item[19] && item[19].includes("After")) {
				flag = 2;
			} else if (item[19] && item[19].includes("Before")) {
				flag = 1;
			}
			updatedMedicinesData[index] = {
				...updatedMedicinesData[index],
				name: item[0],
				occuring: item[15] === "" ? "Daily" : item[15],
				isBF: flag,
				isMorining: item[5] === "1" ? true : false,
				isAfterNoon: item[6] === "1" ? true : false,
				isNight: item[7] === "1" ? true : false,
			};
		}
		setMedication(updatedMedicinesData);
		setResults([]); // Clear suggestions
	};
	const [selectedItems, setSelectedItems] = useState([]); // Selected words
	// const editMedicationRow = (index, name, value) => {
	// 	let updatedMedicinesData = [...Medication];
	// 	updatedMedicinesData[index] = {
	// 		...updatedMedicinesData[index],
	// 		[name]: value,
	// 	};
	// 	setMedication(updatedMedicinesData);
	// 	if (name === "name" && value.length >= 2) {
	// 		const fuseResults = fuseState.search(value); // Start searching
	// 		const suggestions = fuseResults.map((result) => result.item); // Extract matched arrays
	// 		const isNewWord = !suggestions.some(
	// 			(result) => result[0].toLowerCase() === value?.toLowerCase()
	// 		);
	// 		// !data.some((dataItem) => dataItem[0] === value);
	// 		if (isNewWord) {
	// 			let newWordArr = Array(23).fill("");
	// 			newWordArr.unshift(value);
	// 			console.log(newWordArr);
	// 			let updatedNewWords = [...newWords];
	// 			updatedNewWords[index] = newWordArr;
	// 			setNewWords(updatedNewWords);
	// 			suggestions.unshift(newWordArr);
	// 		}
	// 		let updatedResults = [...results];
	// 		updatedResults[index] = suggestions;
	// 		setResults(updatedResults);
	// 		setActiveIndex(-1); // Reset index when new query is typed
	// 	} else {
	// 		setResults([]);
	// 	}
	// };
	const editMedicationRow = (index, name, value) => {
		let updatedMedicinesData = [...Medication];
		updatedMedicinesData[index] = {
			...updatedMedicinesData[index],
			[name]: value,
		};
		const nameCount = updatedMedicinesData.reduce((acc, item) => {
			acc[item?.name?.toLowerCase()] =
				(acc[item?.name?.toLowerCase()] || 0) + 1;
			return acc;
		}, {});
		updatedMedicinesData = updatedMedicinesData.map((item) => ({
			...item,
			repeated: nameCount[item?.name?.toLowerCase()] > 1, // Set 'repeated' to true if name repeats
		}));
		setMedication(updatedMedicinesData);

		if (name === "name" && value.length >= 2) {
			debounceSearch(index, value); // Call debounced search function
		} else {
			setResults([]); // Clear results if less than 2 characters
		}
	};

	// Debounced search function to limit the number of searches when typing fast
	const debounceSearch = useCallback(
		debounce((index, value) => {
			const fuseResults = fuseState?.search(value); // Start searching
			const suggestions = fuseResults?.map((result) => result.item); // Extract matched arrays

			const isNewWord = !suggestions?.some(
				(result) =>
					result[0]?.toString()?.toLowerCase() === value?.toLowerCase()
			);

			if (isNewWord) {
				let newWordArr = [value];
				//  Array(23).fill("");
				// newWordArr.unshift(value);
				suggestions?.unshift(newWordArr); // Add new word to suggestions
			}

			let updatedResults = [...results];
			updatedResults[index] = suggestions;
			setResults(updatedResults);
			setActiveIndex(-1); // Reset index when new query is typed
		}, 300), // 300ms debounce delay
		[fuseState, newWords, results]
	);

	const deleteMedicationRow = (index) => {
		let updatedMedcinesData = Medication.filter((_, i) => i !== index);
		const nameCount = updatedMedcinesData.reduce((acc, item) => {
			acc[item?.name?.toLowerCase()] =
				(acc[item?.name?.toLowerCase()] || 0) + 1;
			return acc;
		}, {});
		updatedMedcinesData = updatedMedcinesData.map((item) => ({
			...item,
			repeated: nameCount[item?.name?.toLowerCase()] > 1, // Set 'repeated' to true if name repeats
		}));
		setMedication(updatedMedcinesData);
	};

	const [selectedTemplates, setSelectedTemplates] = useState([]);
	const { templatenames } = useSelector((state) => state.getTemplateNames);
	console.log(templatenames);
	const handleEditTemplateSelect = (eventKey) => {
		if (eventKey?.length < selectedTemplates?.length && eventKey?.length >= 1) {
			const filteredTemplates = selectedTemplates.filter((template) =>
				eventKey.some((event) => event.value === template.value)
			);
			const removedItems = selectedTemplates.filter(
				(template) => !filteredTemplates.includes(template)
			);
			const removedLabels = removedItems.map((item) => item.label);
			let updatedMedicinesData = Medication?.filter((medicine) =>
				removedLabels?.some(
					(templateName) => medicine?.templateName !== templateName
				)
			);
			setSelectedTemplates(filteredTemplates);
			updatedMedicinesData?.filter((med) => med?.name === "");
			setMedication(updatedMedicinesData);
		} else if (eventKey?.length === 0) {
			setSelectedTemplates([]);
			let updatedMedcinesData = Medication?.filter(
				(medicine) => medicine?.templateName === "Manual"
			);
			updatedMedcinesData?.filter((med) => med?.name === "");
			setMedication(updatedMedcinesData);
		} else {
			setSelectedTemplates(eventKey);
			dispatch(
				getTemplate1(
					doctorInfo?.data?.user?._id,
					eventKey[eventKey?.length - 1]?.value
				)
			);
		}
	};

	const templates = useSelector((state) => state.getTemplate);

	console.log(templates?.getTemplate?.data?.data?.medicines);

	const [editValues, setEditValues] = useState([]);

	useEffect(() => {
		dispatch(getTemplateRequest());
	}, []);

	useEffect(() => {
		if (
			templates?.getTemplate?.data?.data?.medicines?.length > 0 &&
			!templates?.loading
		) {
			const medicinesFromTemplate =
				templates?.getTemplate?.data?.data?.medicines?.map(
					(medicine, index) => {
						let duration = medicine?.duration?.split(" ");
						let isBFValue = "";
						if (!medicine?.dosage?.includes("O@@")) {
							if (medicine?.dosage?.includes("B/F")) {
								isBFValue = 1; // Assign 1 if B/F
							} else if (medicine?.dosage?.includes("A/F")) {
								isBFValue = 2; // Assign 2 if A/F
							}
						}
						return {
							activeBox: !medicine?.dosage?.includes("O@@"),
							customFreq: medicine?.dosage?.includes("O@@")
								? medicine?.dosage?.split("@@")[1]
								: "",

							isBF: isBFValue,
							isMorining: !medicine?.dosage?.includes("O@@")
								? medicine?.dosage?.includes("M,")
								: "",
							isAfterNoon: !medicine?.dosage?.includes("O@@")
								? medicine?.dosage?.includes("A,")
								: "",
							isNight: !medicine?.dosage?.includes("O@@")
								? medicine?.dosage?.includes(",N")
								: "",
							quantity: "",
							durationType: duration[1],
							duration: Number(duration[0]),

							name: medicine?.name,
							occuring: duration[2],
							templateName: templates?.getTemplate?.data?.data?.name,
							repeated: false,
						};
					}
				);

			const combinedMedicines = (prevMedication) => [
				...prevMedication.filter((med) => med.name !== ""),
				...medicinesFromTemplate,
			];

			const checkForRepeatedNames = (medications) => {
				const nameCount = medications.reduce((acc, item) => {
					acc[item?.name?.toLowerCase()] =
						(acc[item?.name?.toLowerCase()] || 0) + 1;
					return acc;
				}, {});

				return medications.map((item) => ({
					...item,
					repeated: nameCount[item?.name?.toLowerCase()] > 1,
				}));
			};

			setMedication((prevMedication) => {
				const updatedMedicines = combinedMedicines(prevMedication);
				return checkForRepeatedNames(updatedMedicines);
			});
		}
	}, [templates?.loading]);

	console.log(Medication);

	useEffect(() => {
		dispatch(getLabDepts());
		dispatch(
			getTemplateNames(
				doctorInfo !== undefined
					? doctorInfo?.data?.user?._id
					: location?.state?.doctorId
			)
		);
	}, []);

	const getlabDeptAll = useSelector((state) => state.getLabDept);
	console.log(getlabDeptAll);

	const [LabTestsRows, setLabTestsRows] = useState([
		{
			deptId: "",
			deptName: "",
			testName: "Choose Test",
			testPrice: "",
			testIndex: "",
			description: "",
			presentInHospital: "1",
		},
	]);
	const [LabDeptTests, setLabDeptTests] = useState([]);

	useEffect(() => {
		const testDetails = getlabDeptAll?.getlabDeptAll?.data?.data
			.filter((item) => item.deptName !== "hospital") // Filter out 'hospital' dept
			.flatMap((item) =>
				item.testDetails.map((test, index) => ({
					deptId: item._id, // deptId
					deptName: item.deptName, // deptName
					testName: test.testName, // testName
					testPrice: test.testPrice, // testPrice
					testIndex: index, // Index of the test in this department
				}))
			);

		setLabDeptTests(testDetails);
	}, [getlabDeptAll?.loading]);

	const editLabTestRow = (index, field, value) => {
		setLabTestsRows((prevRows) =>
			prevRows.map((row, rowIndex) =>
				rowIndex === index ? { ...row, [field]: value } : row
			)
		);
	};

	const AddLabTestRow = (index) => {
		const newLabTestRow = {
			deptId: "",
			deptName: "",
			testName: "Choose Test",
			testPrice: "",
			testIndex: "",
			description: "",
			presentInHospital: "1",
		};
		setLabTestsRows([...LabTestsRows, newLabTestRow]);
	};

	const DeleteLabTestRow = (index) => {
		const LabTestsData = LabTestsRows.filter((_, i) => i !== index);

		setLabTestsRows(LabTestsData);
	};

	const [rowsTest, setRowsTest] = useState([]);
	const [valuesTest, setValuesTest] = useState({});
	console.log(rowsTest);
	console.log(valuesTest);
	const handleChangeTest = (name, value) => {
		// const { name, value } = event.target;
		setValuesTest((prevValues) => ({ ...prevValues, [name]: value }));
	};

	const handleAddRowTest = () => {
		setRowsTest((prevRows) => [...prevRows, Date.now()]);
	};

	const handleDeleteRowTest = (index) => {
		console.log("OKKKK");
		const updatedValues = { ...valuesTest };
		Object.keys(updatedValues)
			.filter((key) => key.includes(index))
			.forEach((key) => delete updatedValues[key]);

		setValuesTest(updatedValues);

		setRowsTest((prevRows) =>
			prevRows.filter((rowIndex) => rowIndex !== index)
		);
	};

	const [editClicked, setEditClicked] = useState([]);

	const editBtnClicked = (index) => {
		let editbuttons = [...editClicked];
		editbuttons[index] = true;
		setEditClicked(editbuttons);
		setFormElements(prescritpionTemplate[index]);
	};

	const handleEditDisable = (index) => {
		let editbuttons = [...editClicked];
		editbuttons[index] = false;
		setEditClicked(editbuttons);
	};

	console.log(editClicked);

	// Form Builder Code

	const [formElements, setFormElements] = useState([]);
	const [selectedElementIndex, setSelectedElementIndex] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editLabel, setEditLabel] = useState("");
	const [editOptions, setEditOptions] = useState([]);
	const [isRequired, setIsRequired] = useState(false);
	const [editHeaderValue, setEditHeaderValue] = useState("");
	const [editParagraphValue, setEditParagraphValue] = useState("");
	const [draggingElement, setDraggingElement] = useState("");
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [editModalOptions, setEditModalOptions] = useState([]);
	const [newOption, setNewOption] = useState("");
	const [showAddOptionInput, setShowAddOptionInput] = useState(false);

	console.log(selectedOptions);

	const addFormElement = (elementType, label = "Label Name") => {
		let defaultOptions = [];
		if (
			elementType === "radio" ||
			elementType === "checkbox" ||
			elementType === "select" ||
			elementType === "multiselect"
		) {
			defaultOptions = ["Option 1", "Option 2", "Option 3"];
		}
		setFormElements([
			// If formElements has elements, spread them into the new array, otherwise start with an empty array
			...(formElements?.length > 0 ? formElements : []),
			// Create the new form element object
			{
				type: elementType, // Set the type to the current element type
				label:
					elementType === "header"
						? "Header"
						: elementType === "paragraph"
						? "Paragraph"
						: label, // Set the label based on the element type
				value:
					elementType === "header"
						? "Header"
						: elementType === "paragraph"
						? "Paragraph"
						: "", // Set the value based on the element type
				options: elementType === "header" ? [] : defaultOptions, // Set options, empty array for headers
				rows: 3,
				cols: 3,
				cellData: {},
				required: false, // Set required to false by default
			},
		]);
	};

	const handleDragStart = (event, elementType) => {
		event.dataTransfer.setData("elementType", elementType);
		setDraggingElement(elementType);
	};

	const handleValueChange = (event, index) => {
		const updatedElements = [...formElements];
		updatedElements[index].value = event.target.value;
		setFormElements(updatedElements);
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const elementType = event.dataTransfer.getData("elementType");
		if (elementType === "header") {
			addFormElement("header");
		} else if (elementType === "paragraph") {
			addFormElement("paragraph");
			setDraggingElement(false);
		} else {
			addFormElement(elementType);
		}
		setDraggingElement("");
	};

	const handleItemClick = (elementType) => {
		addFormElement(elementType);
	};

	const handleLabelChange = (event, index) => {
		const updatedElements = [...formElements];
		if (updatedElements[index]) {
			updatedElements[index].label = event.target.value;
			setFormElements(updatedElements);
		}
	};

	const handleOptionChange = (event, index) => {
		const updatedOptions = [...editOptions];
		updatedOptions[index] = event.target.value;
		setEditOptions(updatedOptions);
	};

	const handleAddOption = () => {
		setShowAddOptionInput(true);
	};

	const handleDeleteOption = (index) => {
		const updatedOptions = [...editOptions];
		updatedOptions.splice(index, 1);
		setEditOptions(updatedOptions);
	};

	const handleDeleteElement = (index) => {
		const updatedElements = [...formElements];
		updatedElements.splice(index, 1);
		setFormElements(updatedElements);
		setSelectedElementIndex(null);
	};

	const handleEditModalClose = () => {
		setShowEditModal(false);
	};

	const handleEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].label = editLabel;
				if (
					updatedElements[selectedElementIndex].type !== "header" &&
					updatedElements[selectedElementIndex].type !== "paragraph"
				) {
					updatedElements[selectedElementIndex].options = editOptions;
					updatedElements[selectedElementIndex].required = isRequired;
				} else if (updatedElements[selectedElementIndex].type === "header") {
					updatedElements[selectedElementIndex].value = editHeaderValue;
				} else if (updatedElements[selectedElementIndex].type === "paragraph") {
					updatedElements[selectedElementIndex].value = editParagraphValue;
				}
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const openEditModal = (index) => {
		setSelectedElementIndex(index);
		if (formElements[index]) {
			setEditLabel(formElements[index].label);
			setEditOptions(formElements[index].options);
			setIsRequired(formElements[index].required);
			setEditHeaderValue(formElements[index].value);
			setEditParagraphValue(formElements[index].value);
		}
		setShowEditModal(true);
	};

	const handleSubmitFormBuilder = (index) => {
		console.log({ formElements });
		let prestemp = [...prescritpionTemplate];
		prestemp[index] = formElements;
		setPrescritpionTemplate(prestemp);
		let editbuttons = [...editClicked];
		editbuttons[index] = false;
		setEditClicked(editbuttons);
	};

	const addHeaderToForm = () => {
		addFormElement("header");
	};

	const addParagraphToForm = () => {
		addFormElement("paragraph");
	};

	const handleHeaderEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].value = editHeaderValue;
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const handleParagraphEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].value = editParagraphValue;
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const createHistory = () => {
		// dispatch(startPrescriptionToStartHistoryReset());
		// dispatch(startPrescriptionToStartHistoryRequest());
		// dispatch(startPrescriptionToStartHistorySuccess(location?.state));
		// window.open("/history", "_blank");
		const queryParams = new URLSearchParams();

		// Convert historyItems to a JSON string and encode it for the URL
		queryParams.append(
			"historyItems",
			encodeURIComponent(JSON.stringify(location?.state))
		);

		// Open the new tab with the query parameters
		window.open(`/history?${queryParams.toString()}`, "_blank");
	};

	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		dispatch(createPrescriptionReset());
		history("/patientstobevisited/userappointment", {
			state: {
				id: location.state.id,
				name: location.state.name,
				date: location.state.date,
				userIdx: location.state.userIdx,
				prescriptionstatus: "already created",
			},
		});
	};

	const handleNextSteps = () => {
		var user_bookingIdArray = [];
		var user_bookingDoctorsArray = [];
		user_bookingIdArray = userdata?.data?.data?.bookingIdArray;
		user_bookingDoctorsArray = userdata?.data?.data?.bookingDoctorsArray;

		history("/doctor/nextappointment", {
			state: {
				id: location.state.id,
				name: location.state.name,
				mno: userdata?.data?.data?.mobilenumber,
				userIdx: location.state.userIdx,
				user_bookingIdArray: user_bookingIdArray,
				user_bookingDoctorsArray: user_bookingDoctorsArray,
			},
		});
		dispatch(createPrescriptionReset());
	};

	const deletePage = (index) => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm("Do you really want to delete this section?")) {
			let templatesSelect = [...templateName];
			templatesSelect?.splice(index, 1);
			setTemplateName(templatesSelect);

			let templatesIdxSelect = [...templateIdx];
			templatesIdxSelect?.splice(index, 1);
			setTemplateIdx(templatesIdxSelect);

			let prescriptionTemplatesSelect = [...prescritpionTemplate];
			prescriptionTemplatesSelect?.splice(index, 1);
			setPrescritpionTemplate(prescriptionTemplatesSelect);

			let editbuttons = [...editClicked];
			editbuttons?.splice(index, 1);
			setEditClicked(editbuttons);

			let updatedNavItems = templatesSelect.map((templateName, i) => ({
				templateName: templateName,
				templateIdx: i,
			}));
			setNavItemsTemplatesAndIdxs(updatedNavItems);
			setNavigatorItems(updatedNavItems);
		}
	};

	useEffect(() => {
		setNavigatorItems([]);
		dispatch(GetPrescriptionTemplateNamesStart());
		getPrescriptionTemplateNames = [];
	}, []);

	const [defaultTemplatesLoaded, setDefaultTemplatesLoaded] = useState(false);

	const [restrictTemplates, setRestrictTemplates] = useState([]);

	useEffect(() => {
		if (
			getPrescriptionTemplateNames &&
			getPrescriptionTemplateNames?.getPrescriptionTemplateNames?.data?.data
				?.prescriptionTemplatesNamesArray?.length > 0 &&
			!getPrescriptionTemplateNames?.loading &&
			!defaultTemplatesLoaded
		) {
			setDefaultTemplatesLoaded(true);
			const requiredTemplates = [
				"Complaints",
				"Examination",
				"Differential diagnosis",
				"Provisional diagnosis",
				"Advice's",
				"Lab Tests", // Will handle specially
				"Medication", // Will handle specially
			];
			setRestrictTemplates(requiredTemplates);

			const templatesToHandle =
				getPrescriptionTemplateNames?.getPrescriptionTemplateNames?.data?.data?.prescriptionTemplatesNamesArray
					?.filter((template) =>
						requiredTemplates.includes(template?.templateName)
					)
					?.sort(
						(a, b) =>
							requiredTemplates.indexOf(a.templateName) -
							requiredTemplates.indexOf(b.templateName)
					)
					?.map((template) => {
						const originalIndex =
							getPrescriptionTemplateNames?.getPrescriptionTemplateNames?.data?.data?.prescriptionTemplatesNamesArray?.findIndex(
								(t) => t.templateName === template.templateName
							);
						return `${template?.templateName}@@${template?._id}@@${originalIndex}`;
					});

			// Function to handle each template sequentially
			const handleTemplates = async (templates) => {
				for (const template of templates) {
					const arr = template?.split("@@");
					const templateName = arr[0];
					const templateId = arr[1];
					const originalIndex = arr[2];

					if (templateName !== "Medication" && templateName !== "Lab Tests") {
						// Handle standard templates

						// Update tempName
						setTempName(templateName);

						// Update templateName
						setTemplateName((prevTemplateName) => [
							...prevTemplateName,
							templateName,
						]);

						// Update templateIdx
						setTemplateIdx((prevTemplateIdx) => [
							...prevTemplateIdx,
							templateId,
						]);

						// Update navItemsTemplatesAndIdxs and navigatorItems
						setNavItemsTemplatesAndIdxs((prevNavItemsTemplates) => {
							const updatedNavItemsTemplates = [
								...prevNavItemsTemplates,
								{
									templateName,
									templateIdx: prevNavItemsTemplates.length,
								},
							];
							setNavigatorItems(updatedNavItemsTemplates);
							return updatedNavItemsTemplates;
						});

						await dispatch(
							GetPrescriptionTemplate(
								location?.state?.speciality,
								originalIndex
							)
						);

						// Close modal and reset template name
						handleCloseTemplateselectionModal();
						setTempName("Choose Template");

						// Optional: Add a delay between processing each template
						await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust delay as needed
					} else {
						// Handle 'Medication' and 'Lab Tests' templates specially

						// Update tempName
						setTempName(templateName);

						// Update templateName
						setTemplateName((prevTemplateName) => [
							...prevTemplateName,
							templateName,
						]);

						// Update templateIdx
						setTemplateIdx((prevTemplateIdx) => [
							...prevTemplateIdx,
							templateId,
						]);

						// Update navItemsTemplatesAndIdxs and navigatorItems
						setNavItemsTemplatesAndIdxs((prevNavItemsTemplates) => {
							const updatedNavItemsTemplates = [
								...prevNavItemsTemplates,
								{
									templateName,
									templateIdx: prevNavItemsTemplates.length,
								},
							];
							setNavigatorItems(updatedNavItemsTemplates);
							return updatedNavItemsTemplates;
						});

						// **Add an empty array to prescriptionTemplate for 'Medication' or 'Lab Tests'**
						setPrescritpionTemplate((prevTemplates) => [
							...prevTemplates,
							[], // Add empty array
						]);

						// Close modal and reset template name
						handleCloseTemplateselectionModal();
						setTempName("Choose Template");

						// **Do not dispatch the API call for these templates**
						// This prevents the third useEffect from being triggered
					}
				}
			};

			// Call the function to handle templates
			handleTemplates(templatesToHandle);
		}
	}, [getPrescriptionTemplateNames?.loading, getPrescriptionTemplateNames]);

	// Second useEffect to initialize getPrescriptionTemplate

	useEffect(() => {
		getPrescriptionTemplate = [];
	}, []);

	// Second useEffect to handle the template data once the API call finishes
	useEffect(() => {
		if (!loading) {
			let templates = [...prescritpionTemplate];

			// Check if API response has data before pushing
			const newTemplate =
				getPrescriptionTemplate?.data?.data?.prescriptionTemplates[0]?.template;

			if (newTemplate) {
				templates.push(newTemplate);
				setPrescritpionTemplate(templates);
			}
		}
	}, [loading]);

	const [showTemplateSelectionModal, setShowTemplateSelectionModal] =
		useState(false);
	const handleCloseTemplateselectionModal = () => {
		setShowTemplateSelectionModal(false);
	};
	return (
		<div className='page-content'>
			<div className='header-center card container mt-3'>
				<Row className='justify-content-center mb-4 mt-4'>
					<Col md='3' className='text-center'>
						<h6>Patient: {userdata?.data?.data?.name}</h6>
					</Col>
					<Col md='2' className='text-center'>
						<h6>Age: {userdata?.data?.data?.age}</h6>
					</Col>
					<Col md='2' className='text-center'>
						<h6>Gender: {userdata?.data?.data?.gender}</h6>
					</Col>
					<Col md='2' className='text-center'>
						<button
							className='btn btn-success'
							onClick={() => setShowTemplateSelectionModal(true)}>
							Add Section
						</button>
					</Col>
					<Col md='3' className='text-center'>
						<Button variant='primary' onClick={createHistory}>
							View/Create History
						</Button>
					</Col>
				</Row>
			</div>

			<Container>
				{
					<>
						<Container className='header-center mt-4 mb-4 card'>
							<Row className='mt-2 mb-2 justify-content-center'>
								<Col md='auto'>
									<h3>Vitals</h3>
								</Col>
							</Row>
							<Row className='mt-2 mb-2'>
								{vitals?.map((vital, index) => (
									<Col md='3'>
										<Col md='12'>{vital?.vitalName}</Col>
										<Col md='12'>
											<input
												type='text'
												className='form-control '
												value={vital?.vitalValue}
												onChange={(e) => vitalChange(e.target.value, index)}
											/>
										</Col>
									</Col>
								))}
							</Row>
							<Row className='mt-2 mb-4 justify-content-center'>
								<Button
									variant='secondary'
									onClick={() => setLgShowVitals(true)}>
									Add Vitals
								</Button>
							</Row>
						</Container>
						<Modal
							size='lg'
							aria-labelledby='example-modal-sizes-title-lg'
							show={lgShowVitals}
							onHide={() => setLgShowVitals(false)}>
							<Modal.Header closeButton>
								<Modal.Title id='example-modal-sizes-title-lg'>
									Add Vital
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<form className='form-group' onSubmit={addVitalOnSubmit}>
									<Container className='header-center mt-4 mb-5'>
										<Row className='justify-content-center'>
											<Col md='auto' className='text-center mt-1'>
												<label htmlFor='vitalName'>
													Vital Name <span className='text-danger'>*</span>
												</label>
											</Col>
											<Col md='auto' className='text-center mt-1'>
												<input
													className='form-control'
													id='vitalName'
													type='text' // Change input type
													required='required'
													name='vitalName'
												/>
											</Col>

											<Col md='auto' className='text-center mt-1'>
												<label htmlFor='vitalUnits'>
													Vital units <span className='text-danger'>*</span>
												</label>
											</Col>
											<Col md='auto' className='text-center mt-1'>
												<input
													className='form-control'
													id='vitalUnits'
													type='text' // Change input type
													required='required'
													name='vitalUnits'
												/>
											</Col>
										</Row>
										<Row className='justify-content-center mt-3'>
											<Col md='auto' className='text-center mt-1'>
												<Button className='btn' type='submit'>
													Add
												</Button>
											</Col>
										</Row>
									</Container>
								</form>
							</Modal.Body>
						</Modal>
						<Container className='header-center mt-4 mb-4 card'>
							<Row className='mt-4 mb-2'>
								<Col md='auto' className='text-center mt-1'>
									<div>Category:</div>
								</Col>
								<Col md='3' className='text-center mt-1'>
									<MultiSelect
										options={
											allCategoryVitals?.data?.data?.category
												? allCategoryVitals?.data?.data?.category?.map(
														(option) => ({
															label: option,
															value: option,
														})
												  )
												: [{ label: "", value: "" }]
										}
										value={chooseCategory}
										onChange={setChooseCategory}
										labelledBy='Select'
									/>
								</Col>
								<Col md='auto' className='text-center mt-1'>
									<Button variant='secondary' onClick={() => setLgShow(true)}>
										Add Category
									</Button>
								</Col>
							</Row>

							<Row className='mt-2 mb-4'>
								<Col md='auto' className='text-center mt-1'>
									<div>Title:</div>
								</Col>
								<Col md='auto' className='text-center mt-1'>
									<textarea
										rows={1}
										cols={100}
										className='form-control'
										onChange={(e) => setTitle(e.target.value)}
										value={title}></textarea>
								</Col>
							</Row>
						</Container>
						<Modal
							size='lg'
							aria-labelledby='example-modal-sizes-title-lg'
							show={lgShow}
							onHide={() => setLgShow(false)}>
							<Modal.Header closeButton>
								<Modal.Title id='example-modal-sizes-title-lg'>
									Add Category
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<form className='form-group' onSubmit={addCategoryOnSubmit}>
									<Container className='header-center mt-4 mb-5'>
										<Row className='justify-content-center'>
											<Col md='auto' className='text-center mt-1'>
												<label htmlFor='Category'>
													Category <span className='text-danger'>*</span>
												</label>
											</Col>
											<Col md='auto' className='text-center mt-1'>
												<input
													className='form-control'
													id='Category'
													type='Category'
													required='required'
													value={Category}
													onChange={(e) => setCategory(e.target.value)}
												/>
											</Col>
											<Col md='auto' className='text-center mt-1'>
												<Button className='btn' type='submit'>
													Add
												</Button>
											</Col>
										</Row>
									</Container>
								</form>
							</Modal.Body>
						</Modal>
					</>
				}

				{templateName?.map((template, index) => (
					<>
						{/* Selected Template Required */}
						{template !== "Medication" &&
							template !== "Lab Tests" &&
							!editClicked[index] && (
								<Container className='mt-4 mb-4 card' id={template}>
									<Row className='justify-content-between m-3'>
										<h3>{template}</h3>
										<div>
											<Button
												variant='success'
												title='Edit Section'
												onClick={() => editBtnClicked(index)}>
												<i className='fa fa-edit'></i>
											</Button>
											<button
												className='btn btn-danger ml-1'
												onClick={() => deletePage(index)}
												title='Delete Section'>
												<i className='fa fa-trash'></i>
											</button>
										</div>
									</Row>

									{template !== "Medication" &&
										template !== "Lab Tests" &&
										!editClicked[index] &&
										Array?.isArray(prescritpionTemplate[index]) &&
										prescritpionTemplate[index]?.map((element, index1) => {
											return (
												<div key={index1} className='form-element'>
													{element.type === "header" ||
													element.type === "paragraph" ? (
														element?.type === "header" ? (
															<h5 className='header-center'>{element.value}</h5>
														) : (
															<p>{element.value}</p>
														)
													) : (
														<>
															<Form.Label>{element.label}</Form.Label>
															{element.type === "select" ? (
																<Form.Control
																	as='select'
																	onChange={(e) =>
																		handleSelectChange(
																			e,
																			element.label,
																			index,
																			index1
																		)
																	}>
																	{element.options.map(
																		(option, optionIndex) => (
																			<option key={optionIndex}>
																				{option}
																			</option>
																		)
																	)}
																</Form.Control>
															) : element.type === "multiselect" &&
															  element.options ? (
																<MultiSelect
																	options={element.options.map((option) => ({
																		label: option,
																		value: option,
																	}))}
																	onChange={(selectedOptions) =>
																		handleMultiselectChange(
																			selectedOptions,
																			element.label,
																			index,
																			index1
																		)
																	}
																	value={element.value || []} // Ensure that formValues[element.label] is an array of objects with label and value properties
																	labelledBy='Select'
																/>
															) : element.type === "checkbox" ? (
																<div>
																	{element.options.map(
																		(option, optionIndex) => (
																			<Form.Check
																				key={optionIndex}
																				type='checkbox'
																				label={option}
																				required={element.required}
																				onChange={(e) =>
																					handleCheckboxRadioChange(
																						e,
																						element.label,
																						option,
																						index,
																						index1
																					)
																				}
																				checked={prescritpionTemplate[index][
																					index1
																				]?.value?.includes(option)}
																				inline
																			/>
																		)
																	)}
																</div>
															) : // Inside the return statement of your component

															element.type === "radio" ? (
																<div>
																	{element.options.map(
																		(option, optionIndex) => (
																			<Form.Check
																				key={optionIndex}
																				type='radio'
																				label={option}
																				required={element.required}
																				onChange={(e) =>
																					handleCheckboxRadioChange(
																						e,
																						element.label,
																						option,
																						index,
																						index1
																					)
																				}
																				checked={element?.value === option} // Check if the option is selected
																				inline
																			/>
																		)
																	)}
																</div>
															) : element.type === "file" ? (
																<input
																	type='file'
																	onChange={(e) =>
																		handleInputChange(
																			e,
																			element.label,
																			index,
																			index1
																		)
																	}
																	className='form-control'
																/>
															) : element.type === "table" ? (
																(() => {
																	const { rows, cols, cellData } = element;
																	return (
																		<Table bordered className='custom-table'>
																			<thead>
																				<tr>
																					{[...Array(cols)].map(
																						(_, colIndex) => (
																							<th key={colIndex}>
																								<Form.Control
																									type='text'
																									value={
																										cellData
																											? cellData[
																													`0-${colIndex}`
																											  ]
																											: ""
																									}
																									onChange={(e) =>
																										handleInputChange(
																											e,
																											element.label,
																											index,
																											index1,
																											0,
																											colIndex,
																											"table"
																										)
																									}
																									placeholder={`Header ${
																										colIndex + 1
																									}`}
																								/>
																							</th>
																						)
																					)}
																				</tr>
																			</thead>
																			<tbody>
																				{[...Array(rows)].map((_, rowIndex) => (
																					<tr key={rowIndex + 1}>
																						{[...Array(cols)].map(
																							(_, colIndex) => (
																								<td key={colIndex}>
																									<Form.Control
																										type='text'
																										value={
																											cellData
																												? cellData[
																														`${
																															rowIndex + 1
																														}-${colIndex}`
																												  ]
																												: ""
																										}
																										onChange={(e) =>
																											handleInputChange(
																												e,
																												element.label,
																												index,
																												index1,
																												rowIndex,
																												colIndex,
																												"table"
																											)
																										}
																										placeholder={`Row ${
																											rowIndex + 1
																										}, Col ${colIndex + 1}`}
																									/>
																								</td>
																							)
																						)}
																					</tr>
																				))}
																			</tbody>
																		</Table>
																	);
																})()
															) : element.type !== "header" &&
															  element.type !== "paragraph" ? (
																element.type === "input" ? (
																	<FormControl
																		type={element.type}
																		placeholder='Enter Value'
																		value={element?.value || ""}
																		onChange={(e) =>
																			handleInputChange(
																				e,
																				element.label,
																				index,
																				index1
																			)
																		}
																	/>
																) : (
																	<textarea
																		className='form-control'
																		placeholder='Enter Value'
																		value={element?.value || ""}
																		onChange={(e) =>
																			handleInputChange(
																				e,
																				element.label,
																				index,
																				index1
																			)
																		}></textarea>
																)
															) : null}
														</>
													)}
												</div>
											);
										})}
								</Container>
							)}

						{/* Edit Template Required */}
						{template !== "Medication" &&
							template !== "Lab Tests" &&
							editClicked[index] &&
							Array?.isArray(prescritpionTemplate[index]) && (
								<div className='form-builder'>
									<Row>
										<Col md={3} className='toolbox'>
											<h3>Toolbox</h3>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "input")}>
												Input
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "table")}>
												Table
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "textarea")}>
												Textarea
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "select")}>
												Select
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "checkbox")}>
												Checkbox
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "radio")}>
												Radio
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "header")}>
												Header
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "paragraph")}>
												Paragraph
											</div>
											<div
												className='toolbox-item'
												draggable
												onDragStart={(e) => handleDragStart(e, "multiselect")}>
												MultiSelect
											</div>
										</Col>
										<Col md={9}>
											<div
												className={`form-canvas ${
													draggingElement === "paragraph"
														? "dragged-paragraph"
														: ""
												}`}
												onDragOver={(e) => e.preventDefault()}
												onDrop={handleDrop}>
												<Form>
													{formElements?.map((element, index) => (
														<div
															key={index}
															className={`form-element ${
																selectedElementIndex === index ? "selected" : ""
															}`}>
															<div className='form-element-header'>
																<div className='label-edit-delete'>
																	{element.type !== "header" &&
																	element.type !== "paragraph" ? (
																		<>
																			<FormLabel className='label'>
																				{element.label}
																			</FormLabel>
																		</>
																	) : element.type !== "paragraph" ? (
																		<h3>{element.value}</h3>
																	) : (
																		<p className='paragraph'>{element.value}</p>
																	)}
																	<div className='edit-delete-buttons'>
																		<button
																			type='button'
																			className='edit-icon btn'
																			onClick={() => openEditModal(index)}>
																			<FontAwesomeIcon icon={faEdit} />
																		</button>
																		<button
																			type='button'
																			className='delete-icon btn'
																			onClick={() =>
																				handleDeleteElement(index)
																			}>
																			<FontAwesomeIcon icon={faTrash} />
																		</button>
																	</div>
																</div>
															</div>
															<div className='form-element-input'>
																{element.type === "select" ? (
																	<Form.Control as='select'>
																		{element.options.map(
																			(option, optionIndex) => (
																				<option key={optionIndex}>
																					{option}
																				</option>
																			)
																		)}
																	</Form.Control>
																) : element.type === "table" ? (
																	<>
																		<div className='row justify-content-center align-items-center mb-3'>
																			<div className='col-auto'>
																				Rows:{" "}
																				<input
																					type='number'
																					className='form-control'
																					value={element?.rows}
																					onChange={(e) =>
																						resizeTable(
																							Number(e.target.value),
																							Number(element?.cols),
																							index
																						)
																					}
																				/>
																			</div>
																			<div className='col-auto'>
																				Columns:{" "}
																				<input
																					type='number'
																					className='form-control'
																					value={element?.cols}
																					onChange={(e) =>
																						resizeTable(
																							Number(element?.rows),
																							Number(e.target.value),
																							index
																						)
																					}
																				/>
																			</div>
																		</div>

																		<div
																			style={{
																				position: "relative",
																				width: "100%",
																				overflowX: "scroll",
																			}}>
																			<Table
																				bordered
																				className='custom-table'
																				style={{ width: "150%" }}>
																				<thead className='bg-light'>
																					<tr>
																						{[...Array(element.cols)].map(
																							(_, colIndex) => (
																								<td key={colIndex}>
																									<Form.Control
																										type='text'
																										value={
																											element?.cellData[
																												`${0}-${colIndex}`
																											] || ""
																										}
																										placeholder='Heading'
																										onChange={(e) =>
																											handleCellChange(
																												0,
																												colIndex,
																												e.target.value,
																												index
																											)
																										}
																									/>
																								</td>
																							)
																						)}
																					</tr>
																				</thead>
																				<tbody>
																					{[...Array(element.rows)].map(
																						(_, rowIndex) => (
																							<tr key={rowIndex + 1}>
																								{[...Array(element.cols)].map(
																									(_, colIndex) => (
																										<td key={colIndex}>
																											<Form.Control
																												type='text'
																												value={
																													element.cellData[
																														`${
																															rowIndex + 1
																														}-${colIndex}`
																													] || ""
																												}
																												onChange={(e) =>
																													handleCellChange(
																														rowIndex + 1,
																														colIndex,
																														e.target.value,
																														index
																													)
																												}
																											/>
																										</td>
																									)
																								)}
																							</tr>
																						)
																					)}
																				</tbody>
																			</Table>
																		</div>
																	</>
																) : element.type === "multiselect" ? (
																	<MultiSelect
																		options={element.options.map((option) => ({
																			label: option,
																			value: option,
																		}))}
																		value={selectedOptions}
																		onChange={setSelectedOptions}
																		labelledBy='Select'
																	/>
																) : element.type === "checkbox" ? (
																	<div>
																		{element.options.map(
																			(option, optionIndex) => (
																				<Form.Check
																					key={optionIndex}
																					type='checkbox'
																					label={option}
																					required={element.required}
																				/>
																			)
																		)}
																	</div>
																) : element.type === "radio" ? (
																	<div>
																		{element.options.map(
																			(option, optionIndex) => (
																				<Form.Check
																					key={optionIndex}
																					type='radio'
																					label={option}
																					required={element.required}
																				/>
																			)
																		)}
																	</div>
																) : element.type !== "header" &&
																  element.type !== "paragraph" ? (
																	element.type === "input" ? (
																		<FormControl
																			type={element.type}
																			placeholder='Enter Value'
																			value={element.value}
																			onChange={(e) =>
																				handleValueChange(e, index)
																			}
																		/>
																	) : (
																		<textarea
																			rows={2}
																			cols={100}
																			className='form-control'
																			onChange={(e) =>
																				handleValueChange(e, index)
																			}
																			value={element.value}></textarea>
																	)
																) : null}
															</div>
														</div>
													))}
												</Form>
											</div>
										</Col>
									</Row>

									<Modal show={showEditModal} onHide={handleEditModalClose}>
										<Modal.Header closeButton>
											<Modal.Title>Edit Form Element</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											{formElements[selectedElementIndex]?.type === "header" ? (
												<>
													<FormLabel>Edit Value:</FormLabel>
													<FormControl
														type='text'
														value={editHeaderValue}
														onChange={(e) => setEditHeaderValue(e.target.value)}
													/>
												</>
											) : formElements[selectedElementIndex]?.type ===
											  "paragraph" ? (
												<>
													<FormLabel>Edit Value:</FormLabel>
													<FormControl
														type='text'
														value={editParagraphValue}
														onChange={(e) =>
															setEditParagraphValue(e.target.value)
														}
													/>
												</>
											) : (
												<>
													<FormLabel>Edit Label:</FormLabel>
													<FormControl
														type='text'
														value={editLabel}
														onChange={(e) => setEditLabel(e.target.value)}
													/>
													{formElements[selectedElementIndex]?.type !==
														"header" &&
													formElements[selectedElementIndex]?.type !==
														"paragraph" &&
													(formElements[selectedElementIndex]?.type ===
														"checkbox" ||
														formElements[selectedElementIndex]?.type ===
															"radio" ||
														formElements[selectedElementIndex]?.type ===
															"select" ||
														formElements[selectedElementIndex]?.type ===
															"multiselect") ? (
														<>
															<FormLabel>Edit Options:</FormLabel>
															{editOptions.map((option, index) => (
																<div key={index} className='header-center'>
																	<Row className='mt-1'>
																		<Col md='10'>
																			<FormControl
																				type='text'
																				value={option}
																				onChange={(e) =>
																					handleOptionChange(e, index)
																				}
																			/>
																		</Col>
																		<Col md='1'>
																			<Button
																				variant='danger'
																				onClick={() =>
																					handleDeleteOption(index)
																				}>
																				<FontAwesomeIcon icon={faTrash} />
																			</Button>
																		</Col>
																	</Row>
																	{index === editOptions?.length - 1 && (
																		<Button
																			variant='primary'
																			onClick={handleAddOption}
																			className='mt-2 mb-2'>
																			<FontAwesomeIcon icon={faPlus} /> Add
																		</Button>
																	)}
																</div>
															))}
															{showAddOptionInput && (
																<Row>
																	<Col md='10'>
																		<FormControl
																			type='text'
																			value={newOption}
																			onChange={(e) =>
																				setNewOption(e.target.value)
																			}
																			placeholder='New Option'
																		/>
																	</Col>
																	<Col md='1'>
																		<Button
																			variant='primary'
																			onClick={() => {
																				setEditOptions([
																					...editOptions,
																					newOption,
																				]);
																				setNewOption("");
																				setShowAddOptionInput(false);
																			}}>
																			<FontAwesomeIcon icon={faCheck} />
																		</Button>
																	</Col>
																</Row>
															)}
														</>
													) : null}
													{formElements[selectedElementIndex]?.type !==
														"header" &&
														formElements[selectedElementIndex]?.type !==
															"paragraph" && (
															<FormCheck
																type='checkbox'
																label='Required'
																checked={isRequired}
																onChange={() => setIsRequired(!isRequired)}
															/>
														)}
												</>
											)}
										</Modal.Body>
										<Modal.Footer>
											<Button
												variant='secondary'
												onClick={handleEditModalClose}>
												Close
											</Button>
											{formElements[selectedElementIndex]?.type === "header" ? (
												<Button
													variant='primary'
													onClick={handleHeaderEditModalSave}>
													Save Changes
												</Button>
											) : formElements[selectedElementIndex]?.type ===
											  "paragraph" ? (
												<Button
													variant='primary'
													onClick={handleParagraphEditModalSave}>
													Save Changes
												</Button>
											) : (
												<Button variant='primary' onClick={handleEditModalSave}>
													Save Changes
												</Button>
											)}
										</Modal.Footer>
									</Modal>
								</div>
							)}

						{/* Change to table */}
						{template === "Lab Tests" && (
							<Container
								className='header-center mt-4 mb-4 card'
								id='Lab Tests'>
								<Row className='justify-content-between m-3'>
									<h3>Lab Tests</h3>
									<button
										className='btn btn-danger'
										onClick={() => deletePage(index)}
										title='Delete Section'>
										<i className='fa fa-trash'></i>
									</button>
								</Row>
								<Table bordered className='custom-table mt-5'>
									<thead>
										<tr>
											<th style={{ width: "50%" }}>Test Name</th>
											<th>Description</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{LabTestsRows?.map((testRow, index) => {
											return (
												<tr>
													<td>
														<DropdownButton
															key='down-centered'
															id={`dropdown-button-drop-down-centered`}
															drop='down-centered'
															variant='success'
															title={LabTestsRows[index]?.testName} // Use the updated testRow.testName
															onSelect={(eventKey) => {
																// Split the eventKey string into an array
																const [
																	testName,
																	testPrice,
																	deptId,
																	deptName,
																	testIndex,
																] = eventKey.split("@@");

																const isTestNameInActiveTests =
																	location?.state?.activeTests?.some(
																		(test) => test.testName === testName
																	);

																const isAlreadySelected = LabTestsRows?.some(
																	(test) => test.testName === testName
																);

																// Only proceed if the testName is not in activeTests
																if (
																	!isTestNameInActiveTests &&
																	!isAlreadySelected
																) {
																	editLabTestRow(index, "deptId", deptId);
																	editLabTestRow(index, "deptName", deptName);
																	editLabTestRow(index, "testName", testName);
																	editLabTestRow(index, "testPrice", testPrice);
																	editLabTestRow(index, "testIndex", testIndex);
																} else if (isTestNameInActiveTests) {
																	alert(testName + " is already in progress.");
																} else {
																	alert(testName + " is already selected");
																}
															}}>
															{LabDeptTests?.map(
																(test) =>
																	test.status !== 0 && (
																		<Dropdown.Item
																			eventKey={
																				test.testName +
																				"@@" +
																				test.testPrice +
																				"@@" +
																				test.deptId +
																				"@@" +
																				test.deptName +
																				"@@" +
																				test.testIndex
																			}>
																			{test.testName}
																		</Dropdown.Item>
																	)
															)}
														</DropdownButton>
													</td>
													<td>
														<textarea
															className='form-control'
															rows={1}
															value={testRow.description}
															onChange={(e) =>
																editLabTestRow(
																	index,
																	"description",
																	e?.target?.value
																)
															}></textarea>
													</td>
													<td>
														<button
															className='btn btn-danger'
															onClick={() => DeleteLabTestRow(index)}>
															<i className='fa fa-trash'></i>
														</button>
													</td>
												</tr>
											);
										})}
									</tbody>
									<tfoot className='text-center' style={{ height: "50px" }}>
										<tr>
											<td
												colSpan='6'
												style={{
													textAlign: "center",
													verticalAlign: "middle",
												}}>
												<button
													className='btn btn-secondary'
													onClick={AddLabTestRow}>
													+ Add more tests
												</button>
											</td>
										</tr>
									</tfoot>
								</Table>
							</Container>
						)}

						{/* Required */}
						{template === "Medication" && (
							<Container className='card mt-4' id='Medication'>
								<Row className='justify-content-between m-3'>
									<h3>Medication</h3>
									<button
										className='btn btn-danger'
										onClick={() => deletePage(index)}
										title='Delete Section'>
										<i className='fa fa-trash'></i>
									</button>
								</Row>
								<div className='row mt-4 mb-2'>
									<Col md='auto' className='text-center mt-1'>
										<h6>Choose Template</h6>
									</Col>
									<Col md='4' className='text-center mt-1'>
										<MultiSelect
											hasSelectAll={false}
											options={
												templatenames?.data?.data
													? templatenames?.data?.data?.map((name, index) => ({
															label: name,
															value: index,
													  }))
													: [{ label: "", value: "" }]
											}
											value={selectedTemplates}
											onChange={(selectedValues) =>
												handleEditTemplateSelect(selectedValues)
											}
											labelledBy='Select'
										/>
									</Col>
								</div>
								<Table bordered className='custom-table mt-5'>
									<thead>
										<tr>
											<th style={{ width: "35%" }}>Name</th>
											<th style={{ width: "35%" }}>Frequency</th>
											<th style={{ width: "25%" }}>Duration</th>
											<th style={{ width: "5%" }}>Action</th>
										</tr>
									</thead>
									<tbody>
										{Medication?.map((medicine, index) => {
											return (
												<tr>
													<td>
														<input
															className={`form-control ${
																medicine?.repeated ? "is-invalid" : ""
															}`}
															type='text'
															value={medicine?.name}
															onChange={(e) => {
																editMedicationRow(
																	index,
																	"name",
																	e?.target?.value
																);
																setShowList(true);
															}}
															placeholder='Enter Medicine'
														/>
														{showList && (
															<ListGroup className='dropScroll' ref={listRef}>
																{results[index]?.map((item, index1) => (
																	<ListGroup.Item
																		key={index1}
																		onClick={() =>
																			editMedicationFromRecommendation(
																				index,
																				item
																			)
																		} // Handle mouse click selection
																		active={index === activeIndex} // Highlight the active item
																		style={{ cursor: "pointer" }}>
																		{item[0]}{" "}
																		{/* Display the first element of the array */}
																	</ListGroup.Item>
																))}
															</ListGroup>
														)}
														{medicine?.repeated && (
															<h6 className='text-danger text-center'>
																Medicine is repeated
															</h6>
														)}
													</td>

													<td>
														<div className='d-flex justify-content-center'>
															<button
																className='btn btn-primary mr-1'
																onClick={() =>
																	editMedicationRow(
																		index,
																		"activeBox",
																		!medicine?.activeBox
																	)
																}>
																<i class='fas fa-sync-alt'></i>
															</button>
															{medicine?.activeBox ? (
																<>
																	<ButtonGroup
																		aria-label='Basic example'
																		className='mr-1'>
																		<Button
																			onClick={() =>
																				editMedicationRow(index, "isBF", 1)
																			}
																			variant={
																				medicine?.isBF === 1
																					? "success"
																					: "secondary"
																			}>
																			B/F
																		</Button>
																		<Button
																			onClick={() =>
																				editMedicationRow(index, "isBF", 2)
																			}
																			variant={
																				medicine?.isBF === 2
																					? "success"
																					: "secondary"
																			}>
																			A/F
																		</Button>
																	</ButtonGroup>
																	<Button
																		onClick={() =>
																			editMedicationRow(
																				index,
																				"isMorining",
																				!medicine?.isMorining
																			)
																		}
																		variant={
																			medicine?.isMorining
																				? "success"
																				: "outline-success"
																		}>
																		M
																	</Button>
																	<Button
																		onClick={() =>
																			editMedicationRow(
																				index,
																				"isAfterNoon",
																				!medicine?.isAfterNoon
																			)
																		}
																		variant={
																			medicine?.isAfterNoon
																				? "success"
																				: "outline-success"
																		}>
																		A
																	</Button>
																	<Button
																		onClick={() =>
																			editMedicationRow(
																				index,
																				"isNight",
																				!medicine?.isNight
																			)
																		}
																		variant={
																			medicine?.isNight
																				? "success"
																				: "outline-success"
																		}>
																		N
																	</Button>
																</>
															) : (
																<input
																	type='text'
																	className='form-control'
																	value={medicine?.customFreq}
																	onChange={(e) =>
																		editMedicationRow(
																			index,
																			"customFreq",
																			e?.target?.value
																		)
																	}
																/>
															)}
															<DropdownButton
																className='ml-1'
																key='down-centered'
																id={`dropdown-button-drop-down-centered`}
																drop='down-centered'
																variant='success'
																title={medicine?.occuring}
																onSelect={(eventKey) =>
																	editMedicationRow(index, "occuring", eventKey)
																}>
																<Dropdown.Item eventKey={"Doesn't Repeat"}>
																	{"Doesn't Repeat"}
																</Dropdown.Item>
																<Dropdown.Item eventKey={"Daily"}>
																	{"Daily"}
																</Dropdown.Item>
																<Dropdown.Item eventKey={"Weekly"}>
																	{"Weekly"}
																</Dropdown.Item>
																<Dropdown.Item eventKey={"Monthly"}>
																	{"Monthly"}
																</Dropdown.Item>
																<Dropdown.Item eventKey={"Yearly"}>
																	{"Yearly"}
																</Dropdown.Item>
															</DropdownButton>
														</div>
													</td>
													<td>
														<div className='d-flex'>
															<button
																className='btn btn-primary mr-1'
																onClick={() =>
																	editMedicationRow(
																		index,
																		"duration",
																		Number(medicine?.duration) - 1
																	)
																}>
																<i className='fas fa-minus'></i>
															</button>
															<input
																type='text'
																value={medicine?.duration}
																className='form-control mr-1'
																onChange={(e) =>
																	editMedicationRow(
																		index,
																		"duration",
																		e?.target?.value
																	)
																}
															/>
															<button
																className='btn btn-primary mr-1'
																onClick={() =>
																	editMedicationRow(
																		index,
																		"duration",
																		Number(medicine?.duration) + 1
																	)
																}>
																<i className='fas fa-plus'></i>
															</button>
															<DropdownButton
																key='down-centered'
																id={`dropdown-button-drop-down-centered`}
																drop='down-centered'
																variant='success'
																title={medicine?.durationType}
																onSelect={(eventKey) =>
																	editMedicationRow(
																		index,
																		"durationType",
																		eventKey
																	)
																}>
																<Dropdown.Item eventKey={"Days"}>
																	{"Days"}
																</Dropdown.Item>
																<Dropdown.Item eventKey={"Weeks"}>
																	{"Weeks"}
																</Dropdown.Item>
																<Dropdown.Item eventKey={"Months"}>
																	{"Months"}
																</Dropdown.Item>
																<Dropdown.Item eventKey={"Years"}>
																	{"Years"}
																</Dropdown.Item>
															</DropdownButton>
														</div>
													</td>
													<td>
														<button
															className='btn btn-danger'
															onClick={() => deleteMedicationRow(index)}>
															<i className='fa fa-trash'></i>
														</button>
													</td>
												</tr>
											);
										})}
									</tbody>
									<tfoot className='text-center' style={{ height: "50px" }}>
										<tr>
											<td
												colSpan='6'
												style={{
													textAlign: "center",
													verticalAlign: "middle",
												}}>
												<button
													className='btn btn-secondary'
													onClick={addNewMedicationRow}>
													+ Add more medicines
												</button>
											</td>
										</tr>
									</tfoot>
								</Table>
							</Container>
						)}
						{editClicked[index] && (
							<Row className='justify-content-center'>
								<Button
									variant='primary'
									onClick={() => handleSubmitFormBuilder(index)}>
									Save Changes
								</Button>
								<Button
									variant='danger'
									className='ml-3'
									onClick={handleEditDisable}>
									Discard Changes
								</Button>
							</Row>
						)}
						{index !== templateName?.length - 1 && <hr />}
					</>
				))}
				{!editClicked?.some((value, index) => value === true) && (
					<Row className='justify-content-center mt-3'>
						<Col md='auto'>
							<button
								className='btn btn-success'
								onClick={() => setShowTemplateSelectionModal(true)}>
								Add Section
							</button>
						</Col>
						<Col md='auto'>
							<Button variant='primary' onClick={handleSubmitForm}>
								Complete Case
							</Button>
						</Col>
					</Row>
				)}
			</Container>

			{/* Required */}
			<Modal
				show={showTemplateSelectionModal}
				onHide={handleCloseTemplateselectionModal}>
				<Modal.Header closeButton>
					<Modal.Title>Choose Template</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container className='header-center mb-3 mt-3' id='ChooseTemplate'>
						<Row className='justify-content-center'>
							<Col md='auto' className='text-center '>
								<h6>
									Select the filters{" "}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</h6>
							</Col>
							<Col md='auto' className='text-center '>
								<DropdownButton
									key='down-centered'
									id={`dropdown-button-drop-down-centered`}
									drop='down-centered'
									variant='success'
									title={tempName}
									onSelect={TemplateHandler}>
									<div className='dropScroll'>
										{getPrescriptionTemplateNames?.getPrescriptionTemplateNames?.data?.data?.prescriptionTemplatesNamesArray.map(
											(template, index) =>
												!restrictTemplates?.includes(
													template?.templateName
												) && (
													<Dropdown.Item
														eventKey={
															template.templateName +
															"@@" +
															template._id +
															"@@" +
															index
														}>
														{template.templateName}
													</Dropdown.Item>
												)
										)}
									</div>
								</DropdownButton>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={handleCloseTemplateselectionModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Prescription created succesfully !</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Do you want plan a next visit/ remainder or refer to another doctor.
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Skip
					</Button>
					<Button variant='primary' onClick={handleNextSteps}>
						Continue
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default StartPrescriptionScreens;
