/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getIndividualInventoryItemRevenue = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		getIndividualInventoryItemRevenueStart: (state) => {
			state.loading = true;
		},
		getIndividualInventoryItemRevenueSuccess: (state, action) => {
			state.loading = false;
			state.getIndividualInventoryItemRevenue = action.payload;
			state.error = false;
			state.success = true;
		},
		getIndividualInventoryItemRevenueFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getIndividualInventoryItemRevenueReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	getIndividualInventoryItemRevenueStart,
	getIndividualInventoryItemRevenueSuccess,
	getIndividualInventoryItemRevenueFailure,
	getIndividualInventoryItemRevenueReset,
} = getIndividualInventoryItemRevenue.actions;
export default getIndividualInventoryItemRevenue.reducer;
